import { ThemeOptions } from '@mui/material';
import { grey } from '@mui/material/colors';

export const darkPalette: ThemeOptions['palette'] = {
  mode: 'dark',
  divider: 'rgba(255, 255, 255, 0.3)',
  primary: {
    dark: '#2763CE',
    main: '#4297ff',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#FB662A',
    contrastText: '#ffffff',
  },
  success: {
    main: '#66D165',
    dark: '#01A249',
    light: '#E8F8E8',
  },
  text: {
    primary: '#ffffff',
    badge: grey[300],
    title: '#ffffff',
    neutral: 'rgba(255, 255, 255, 0.85)',
  },
  error: {
    main: '#FF0000',
  },
  background: {
    default: '#1E1E1E',
    base: '#1E1E1E',
    paper: '#2B2B2B',
    card: '#363636',
    cardHover: '#434343',
    // @TODO: Darken this color and make text white
    info: '#DEEAFF',
    warning: '#FFE8D4',
    success: '#DCFFDC',
    error: '#FFFBFA',
    tooltip: '#3D455A',
  },
};
