import { ReactNode } from 'react';

import { DocumentType } from '@usgm/inbox-api-types';

// Icons
import DiverIcon from '../assets/document-icons/driverLicense.svg?react';
import GenericDocumentIcon from '../assets/document-icons/generic.svg?react';
import PassportIcon from '../assets/document-icons/passport.svg?react';
import VoterCardIcon from '../assets/document-icons/voterCard.svg?react';
import VehicleRegistrationCardIcon from '../assets/document-icons/vehicleRegistrationCard.svg?react';
import HomeOrVehicleInsuranceIcon from '../assets/document-icons/homeOrVehicle.svg?react';
import LeaseIcon from '../assets/document-icons/lease.svg?react';
import UniversityIcon from '../assets/document-icons/university.svg?react';
import NexusIcon from '../assets/document-icons/nexus.svg?react';
import DeadOfTrustIcon from '../assets/document-icons/deadOfTrust.svg?react';

export const DOCUMENT_TYPE_OPTIONS_MAPPER: Record<string, { icon: ReactNode; label: string }> = {
  [DocumentType.DriverLicense]: {
    icon: <DiverIcon />,
    label: `US ID: Driver's, State, Territory or Tribal`,
  },
  [DocumentType.GovernmentIdentificationCard]: {
    icon: <GenericDocumentIcon />,
    label: 'Government Identification Card',
  },
  [DocumentType.AlienRegistrationCard]: {
    icon: <GenericDocumentIcon />,
    label: 'Alien Registration Card',
  },
  [DocumentType.CertificateOfNaturalization]: {
    icon: <GenericDocumentIcon />,
    label: 'Certificate Of Naturalization',
  },
  [DocumentType.Passport]: {
    icon: <PassportIcon />,
    label: 'Passport',
  },
  [DocumentType.StateIdentificationCard]: {
    icon: <GenericDocumentIcon />,
    label: 'State Identification Card',
  },
  [DocumentType.VoterCard]: {
    icon: <VoterCardIcon />,
    label: 'Voter Card',
  },
  [DocumentType.VehicleRegistrationCard]: {
    icon: <VehicleRegistrationCardIcon />,
    label: 'Vehicle Registration Card',
  },

  [DocumentType.CurrentLease]: {
    icon: <LeaseIcon />,
    label: 'Current Lease',
  },
  [DocumentType.UniformedServiceId]: {
    icon: <GenericDocumentIcon />,
    label: 'Uniformed Service ID',
  },
  [DocumentType.UsAccessCard]: {
    icon: <GenericDocumentIcon />,
    label: 'US Access Card',
  },
  [DocumentType.UsUniversityIdCard]: {
    icon: <UniversityIcon />,
    label: 'US University ID Card',
  },
  [DocumentType.MatriculaConsular]: {
    icon: <NexusIcon />,
    label: 'Matricula Consular',
  },
  [DocumentType.NexusCard]: {
    icon: <NexusIcon />,
    label: 'Nexus Card',
  },
  [DocumentType.UsPermanentResidentCard]: {
    icon: <GenericDocumentIcon />,
    label: 'US Permanent Resident Card',
  },
  [DocumentType.MortgageOrDeedOfTrust]: {
    icon: <DeadOfTrustIcon />,
    label: 'Mortgage Or Deed Of Trust',
  },
  [DocumentType.HomeOrVehicleInsurancePolicy]: {
    icon: <HomeOrVehicleInsuranceIcon />,
    label: 'Home Or Vehicle Insurance Policy',
  },
};

export const PRIMARY_DOCUMENT_OPTIONS = [
  DocumentType.DriverLicense,
  DocumentType.UniformedServiceId,
  DocumentType.UsAccessCard,
  DocumentType.UsUniversityIdCard,
  DocumentType.Passport,
  DocumentType.MatriculaConsular,
  DocumentType.NexusCard,
  DocumentType.CertificateOfNaturalization,
  DocumentType.UsPermanentResidentCard,
].map((type) => ({
  label: DOCUMENT_TYPE_OPTIONS_MAPPER[type as DocumentType].label,
  value: type,
}));

export const SECONDARY_DOCUMENT_OPTIONS = [
  DocumentType.DriverLicense,
  DocumentType.CurrentLease,
  DocumentType.MortgageOrDeedOfTrust,
  DocumentType.HomeOrVehicleInsurancePolicy,
  DocumentType.VehicleRegistrationCard,
  DocumentType.VoterCard,
].map((type) => ({
  label: DOCUMENT_TYPE_OPTIONS_MAPPER[type as DocumentType].label,
  value: type,
}));
