import { SHIPMENT_AVAILABLE_BEFORE_HOUR } from '@inbox/constants';
import { DateTime } from 'luxon';
import { isHoliday } from './isHoliday';

export const adjustDateForShipmentCutoffOrNonBusiness = (date: DateTime<boolean>): DateTime => {
  let warehouseDate = date.setZone('America/Chicago');

  if (warehouseDate.hour >= SHIPMENT_AVAILABLE_BEFORE_HOUR) {
    warehouseDate = warehouseDate.plus({ days: 1 }).startOf('day');
  } else {
    warehouseDate = warehouseDate.startOf('day');
  }

  // Update the date while it's a weekend or a holiday
  while (warehouseDate.weekday > 5 || isHoliday(warehouseDate)) {
    warehouseDate = warehouseDate.plus({ days: 1 }).startOf('day');
  }

  return warehouseDate;
};
