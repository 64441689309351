import { createApi } from '@reduxjs/toolkit/query/react';
import { type CalculatePackingDimensionsParams, type DtoPackingDimensions } from '@usgm/inbox-api-types';
import { ENVIRONMENT } from '../env';
import { axiosBaseQuery } from './axios/baseQuery';

export const geolocationApi = createApi({
  reducerPath: 'geolocationApi',
  baseQuery: axiosBaseQuery(ENVIRONMENT.GEOLOCATION_API_URL),
  endpoints: (builder) => ({
    calculatePackingDimensions: builder.mutation<DtoPackingDimensions, CalculatePackingDimensionsParams>({
      query: (data) => ({
        url: '/packing/dimensions',
        method: 'post',
        data,
      }),
    }),
  }),
});

export const { useCalculatePackingDimensionsMutation } = geolocationApi;
