import { Alert, Typography } from '@mui/material';
import { DtoDocument, UserType } from '@usgm/inbox-api-types';
import { FlexBox, PureLink } from '@usgm/shared-ui';
import HelpPopover from '../../../../components/HelpPopover';
import { pascalCase } from '@usgm/utils';
import { webSiteUrl } from '../../../../../../helpers/urlHelper';

export type DocumentRejectionInfoProps = {
  document: DtoDocument;
  userType: UserType;
};
function DocumentRejectionInfo({ document, userType }: DocumentRejectionInfoProps) {
  return (
    <>
      <Alert sx={{ width: '100%' }} severity="error">
        <Typography color="error">{pascalCase(userType)} ID is rejected</Typography>
        <Typography>Note: {document.notes}</Typography>
      </Alert>
      <FlexBox width="100%" mt={2} gap={2} justifyContent="flex-start">
        <PureLink
          target="_blank"
          sx={{ fontSize: '0.875rem', fontWeight: 600 }}
          href={webSiteUrl('help_category/required-documents')}
        >
          View ID Submission Guidelines
        </PureLink>
        <HelpPopover />
      </FlexBox>
    </>
  );
}

export default DocumentRejectionInfo;
