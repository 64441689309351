import { Card, styled } from '@mui/material';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  background: theme.palette.background.card,
  '&:hover': {
    background: theme.palette.background.cardHover,
  },
}));

export default StyledCard;
