import { Box, Button, Typography, styled } from '@mui/material';
import { FlexBox } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectIsSessionExpired } from '../../../app/appSlice';
import { useLoginRedirection } from '../../../app/hooks/useLoginRedirection';
import { LoadingCard } from '../../../components/LoadingCard';
import { useAppDispatch } from '../../../store';
import { processLogout } from '../../auth/authSlice';

const ScreenContainer = styled(Box)(({ theme }) => ({
  left: 0,
  top: 0,
  zIndex: theme.zIndex.modal,
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
}));

function SessionExpiredScreen() {
  const { storeLoginRedirect } = useLoginRedirection();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isSessionExpired = useSelector(selectIsSessionExpired);

  if (!isSessionExpired) {
    return null;
  }
  const handleSignInClick = () => {
    storeLoginRedirect();
    dispatch(
      processLogout({
        callback: () => {
          navigate(inboxHelpers.MAIN_PATHS.LOGIN);
        },
      }),
    );
  };
  return (
    <ScreenContainer>
      <LoadingCard logoWidth="80%" maxWidth={400} largeText hasLoading={false}>
        <FlexBox flexDirection="column" gap={3}>
          <Typography maxWidth="80%" textAlign="center" variant="h5">
            Your session has been expired. Please sign in again.
          </Typography>
          <Box>
            <Button variant="contained" fullWidth onClick={handleSignInClick}>
              Sign in
            </Button>
          </Box>
        </FlexBox>
      </LoadingCard>
    </ScreenContainer>
  );
}

export default SessionExpiredScreen;
