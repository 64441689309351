import { styled } from '@mui/material';

const HeaderWrapper = styled('header')(({ theme }) => ({
  boxShadow: `inset 0px -1px 0px 0px ${theme.palette.divider}`,
  position: 'sticky',
  zIndex: theme.zIndex.appBar,
  backgroundColor: theme.palette.background.paper,
  top: 0,
  left: 0,
}));

export default HeaderWrapper;
