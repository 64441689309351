import { Box, Button, Card, CircularProgress, Typography } from '@mui/material';
import { DtoInvoice } from '@usgm/inbox-api-types';
import { FlexBox, Toast, cellHelpers } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import { useMemo } from 'react';
import { CbPortalSessionType, useChargebeePortal } from '../../../../../hooks/useChargebeePortal';
import { useAuth } from '../../../../auth/hooks/useAuth';
import { useCollectInvoicePaymentMutation } from '../api';
import EntityStatus from './EntityStatus';
import SummaryRow from './SummaryRow';
import { DetailRow } from './styled/DetailRow';

export type InvoiceDetailsProps = {
  data: DtoInvoice;
};

const FAILED_STATUSES = ['not_paid', 'payment_due'];

function InvoiceDetails({ data }: InvoiceDetailsProps) {
  const { user } = useAuth();
  const [collectInvoicePayment, collectInvoiceQuery] = useCollectInvoicePaymentMutation();
  const { openPortal } = useChargebeePortal();

  const topDetails = useMemo(() => {
    return [
      ...(data.cancelReason
        ? [
            {
              title: 'Failure Reason',
              value: data.cancelReason,
            },
          ]
        : []),
      {
        title: 'Status',
        value: (
          <Box display="inline-flex">
            <EntityStatus label={data.statusLabel} statusCategory={data.statusCategory} />
          </Box>
        ),
      },
      {
        title: 'Created On',
        // @TODO: need toi check what is actual date here
        value: cellHelpers.formatDateString(data.dueDate),
      },
    ];
  }, [data.cancelReason, data.dueDate, data.statusCategory, data.statusLabel]);

  const summaries = useMemo(() => {
    return [
      {
        label: 'Total',
        summary: inboxHelpers.formatPrice(data.amountTotal),
      },
      {
        label: 'Amount Paid',
        summary: inboxHelpers.formatPrice(data.amountPaid),
      },
      ...(data.vendorInvoice.creditsApplied
        ? [
            {
              label: 'Credits',
              summary: inboxHelpers.formatPrice(data.vendorInvoice.creditsApplied / 100.0),
            },
          ]
        : []),
      {
        label: 'Amount Due (USD)',
        summary: inboxHelpers.formatPrice(data.amountDue),
      },
    ];
  }, [data.amountDue, data.amountPaid, data.amountTotal, data.vendorInvoice.creditsApplied]);

  const handlePayNowClick = () => {
    if (user?.userUUID) {
      collectInvoicePayment({ vendorInvoiceId: data.vendorInvoiceId, userId: user?.userUUID });
    }
  };

  const handleUpdatePaymentClick = () => {
    openPortal(CbPortalSessionType.PaymentSources);
  };

  const showPaymentButtons = FAILED_STATUSES.includes(data.vendorInvoice.status);

  return (
    <>
      {topDetails.map(({ title, value }, index) => {
        return (
          <DetailRow key={index}>
            <Typography component="div">{title}</Typography>
            <Typography component="div">{value}</Typography>
          </DetailRow>
        );
      })}

      {showPaymentButtons && (
        <FlexBox mt={2} gap={2}>
          <Button onClick={handlePayNowClick} sx={{ paddingX: 1 }} fullWidth variant="outlined" color="primary">
            {collectInvoiceQuery.isLoading ? <CircularProgress size={20} /> : 'Pay Now'}
          </Button>
          <Button onClick={handleUpdatePaymentClick} sx={{ paddingX: 1 }} variant="contained" fullWidth>
            Update Payment Method
          </Button>
        </FlexBox>
      )}

      <DetailRow mt={2}>
        <Typography fontWeight={600}>Items</Typography>
        <Typography fontWeight={600}>Amount(USD)</Typography>
      </DetailRow>
      {data.invoiceLineItems?.map(({ quantity, description, amount }, index) => (
        <DetailRow key={index}>
          <Typography maxWidth="70%">{description}</Typography>
          <Typography>
            {quantity} x {inboxHelpers.formatPrice(amount)}
          </Typography>
        </DetailRow>
      ))}
      {summaries.map(({ label, summary }, index) => {
        return <SummaryRow key={index} label={label} summary={summary} />;
      })}
      {data.vendorInvoice.notes && (
        <>
          <Typography mt={6} variant="h5">
            Notes:
          </Typography>
          {data.vendorInvoice.notes?.map(({ note }, index) => (
            <Card key={index} variant="outlined" sx={{ p: 2, mt: 2 }}>
              <Typography>{note}</Typography>
            </Card>
          ))}
        </>
      )}
      {(collectInvoiceQuery.isSuccess || collectInvoiceQuery.isError) && (
        <Toast
          title={
            collectInvoiceQuery.isError
              ? collectInvoiceQuery.error?.message || inboxHelpers.GENERIC_ERROR_MESSAGE
              : 'Payment collected successfully'
          }
          severity={collectInvoiceQuery.isSuccess ? 'success' : 'error'}
        />
      )}
    </>
  );
}

export default InvoiceDetails;
