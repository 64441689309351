import { Box, styled, Typography } from '@mui/material';
import { ShippingDeclaration } from '@usgm/inbox-api-types';
import { ConfirmationDialog, ConfirmationDialogProps } from '@usgm/shared-ui';

import ShippingDeclarationsManager from '../features/mails/components/MailInfo/ShippingDeclarationsManager';

const Root = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.card,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1, 2),
  },

  '&:last-child': {
    borderBottom: 'none',
  },
}));

const StyledSubheader = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  marginBottom: theme.spacing(4),
  fontSize: '1.0rem',
}));

export type DeclareMailItemDialogProps = Omit<ConfirmationDialogProps, 'content'> & {
  items: Array<{ mailId: number; declarations: ShippingDeclaration[] }>;
};
function DeclareMailItemDialog({ items, ...dialogProps }: DeclareMailItemDialogProps) {
  const plural = items?.length > 1 ? 's' : '';
  const title = `Declare mail item${plural}`;

  return (
    dialogProps.open && (
      <ConfirmationDialog
        {...dialogProps}
        open={dialogProps.open}
        title={title}
        hideButtons
        content={
          <Box>
            {items?.map((it) => (
              <Root key={it.mailId}>
                <StyledSubheader>Item #{it.mailId}</StyledSubheader>
                <ShippingDeclarationsManager mailId={it.mailId} declarations={it.declarations} />
              </Root>
            ))}
          </Box>
        }
      />
    )
  );
}

export default DeclareMailItemDialog;
