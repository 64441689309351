import { Box } from '@mui/material';

import { type BaseCreditNote } from '@usgm/inbox-api-types';
import { inboxHelpers, pascalCase } from '@usgm/utils';
import { EntityCell, cellHelpers } from '@usgm/shared-ui';

import { DOWNLOAD_BUTTON_PROPS } from './InvoiceInfoCell';
import DownloadBillingPdf from '../DownloadBillingPdf';
import EntityStatus from '../EntityStatus';

const constructCreditNoteInfoList = (data: BaseCreditNote) => {
  return [
    {
      title: 'Credit Number',
      value: `#${data.vendorId}`,
    },
    {
      title: 'Status',
      value: <EntityStatus label={data.statusLabel} statusCategory={data.statusCategory} />,
    },
    {
      title: 'Reason',
      value: pascalCase(data.reason),
    },
    {
      title: 'Amount',
      value: inboxHelpers.formatPrice(data.amountTotal),
    },
    {
      title: 'Issued On',
      value: cellHelpers.formatDateString(data.date, true),
    },
  ];
};

export type CreditNoteInfoCellProps = {
  data: BaseCreditNote;
};
function CreditNoteInfoCell({ data }: CreditNoteInfoCellProps) {
  return (
    <Box display="flex" flexDirection="column">
      <EntityCell infoList={constructCreditNoteInfoList(data)} />
      <Box mb={2} display="flex" width="100%" mt={2}>
        <DownloadBillingPdf
          buttonProps={DOWNLOAD_BUTTON_PROPS}
          itemId={data.vendorId}
          invoiceId={data.invoiceId}
          type="credit"
        />
      </Box>
    </Box>
  );
}

export default CreditNoteInfoCell;
