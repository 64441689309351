import { AddressType, UserInfo } from '@usgm/inbox-api-types';
import { useGetBoxNumberByUserUuidQuery } from '../../../api/accountApi';
import { useGetUserAddressesQuery } from '../inboxAccountsApi';
import { inboxHelpers } from '@usgm/utils';

export function useUserWarehouseDetails(userUUID?: UserInfo['userUUID']) {
  const boxNumberQuery = useGetBoxNumberByUserUuidQuery(userUUID || '', { skip: !userUUID });
  const userAddressQuery = useGetUserAddressesQuery(
    { addressType: AddressType.Warehouse, userUUID },
    { skip: !userUUID },
  );
  const warehouseAddress = userAddressQuery.data?.addresses[0];
  const boxNumber = boxNumberQuery.data?.box.warehouseBoxNumber;
  return {
    isLoading: userAddressQuery.isLoading || boxNumberQuery.isLoading,
    isUninitialized: userAddressQuery.isUninitialized || boxNumberQuery.isUninitialized,
    data:
      warehouseAddress && boxNumber && userAddressQuery.data
        ? {
            ...userAddressQuery.data,
            warehouseAddress,
            boxNumber,
            stringifiedAddress: inboxHelpers.stringifyAddress({ addr: warehouseAddress, boxNumber }),
          }
        : null,
  };
}
