import { Box } from '@mui/material';
import { DtoAccountName } from '@usgm/inbox-api-types';
import { BoldText, EntityCell, EntityCellProps } from '@usgm/shared-ui';
import { inboxHelpers, pascalCase } from '@usgm/utils';
import EntityStatus from '../../../../billingHistory/components/EntityStatus';

export type AccountNameInfoCellProps = {
  data: DtoAccountName;
};

const constructAccountNameInfo = (data: DtoAccountName): EntityCellProps['infoList'] => {
  const statusDetails = inboxHelpers.getAccountStatusDetails(data.accountStatus);
  return [
    {
      title: 'Name',
      value: data.user?.name,
    },
    {
      title: 'Type',
      value: <BoldText>{pascalCase(data.relation?.relationType)}</BoldText>,
    },
    {
      title: 'Status',
      value: <EntityStatus statusCategory={statusDetails.category} label={statusDetails.label} />,
    },
  ];
};

function AccountNameInfoCell({ data }: AccountNameInfoCellProps) {
  return (
    <Box width="100%">
      <EntityCell headingWidth="20%" hideHeadingBelowPx={540} infoList={constructAccountNameInfo(data)} />
    </Box>
  );
}

export default AccountNameInfoCell;
