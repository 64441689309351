import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './axios/baseQuery';
import { ENVIRONMENT } from '../env';
import {
  DtoBoxNumber,
  DtoEmailSubscription,
  DtoUnderMaintenance,
  EmailSubscriptionParams,
  UserInfo,
} from '@usgm/inbox-api-types';

export const accountApi = createApi({
  reducerPath: 'accountApi',
  baseQuery: axiosBaseQuery(ENVIRONMENT.ACCOUNT_API_URL),
  endpoints: (builder) => ({
    updateEmailSubscription: builder.mutation<DtoEmailSubscription, EmailSubscriptionParams>({
      query: ({ action, ...params }) => ({
        url: `/user/email/${action}`,
        method: 'put',
        data: params,
      }),
    }),
    environment: builder.query<DtoUnderMaintenance, void | null>({
      query: () => ({
        url: `/environment`,
        method: 'put',
      }),
    }),
    getBoxNumberByUserUuid: builder.query<DtoBoxNumber, UserInfo['userUUID']>({
      query: (userUuid) => ({
        url: `/box/user/${userUuid}`,
        method: 'get',
      }),
    }),
  }),
});

export const { useUpdateEmailSubscriptionMutation, useEnvironmentQuery, useGetBoxNumberByUserUuidQuery } = accountApi;
