import { styled } from '@mui/material';
import { FlexBox } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';

const FormRow = styled(FlexBox)(({ theme }) => ({
  width: '100%',
  gap: theme.spacing(3.25),
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
}));

export default FormRow;
