import { Box, type ButtonProps } from '@mui/material';

import { inboxHelpers } from '@usgm/utils';
import { type BaseInvoice } from '@usgm/inbox-api-types';
import { EntityCell, cellHelpers } from '@usgm/shared-ui';

import { SingleBillingItemLink } from '../../gridConfig/invoices';
import DownloadBillingPdf from '../DownloadBillingPdf';
import EntityStatus from '../EntityStatus';

const constructInvoiceInfoList = (data: BaseInvoice) => {
  return [
    {
      title: 'Invoice Number',
      value: <SingleBillingItemLink label={`#${data.vendorId}`} id={data.vendorId} />,
    },
    {
      title: 'Status',
      value: <EntityStatus label={data.statusLabel} statusCategory={data.statusCategory} />,
    },
    {
      title: 'Total',
      value: inboxHelpers.formatPrice(data.amountTotal),
    },
    {
      title: 'Amount Paid',
      value: inboxHelpers.formatPrice(data.amountPaid),
    },
    {
      title: 'Due Date',
      value: cellHelpers.formatDateString(data.dueDate, true),
    },
    {
      title: 'Paid At',
      value: cellHelpers.formatDateString(data.paidDate, true),
    },
  ];
};

export const DOWNLOAD_BUTTON_PROPS: ButtonProps = { variant: 'outlined', sx: { width: '100%' } };

export type InvoiceInfoCellProps = {
  data: BaseInvoice;
};
function InvoiceInfoCell({ data }: InvoiceInfoCellProps) {
  return (
    <Box display="flex" flexDirection="column">
      <EntityCell infoList={constructInvoiceInfoList(data)} />
      <Box display="flex" width="100%" mt={2} mb={2}>
        <DownloadBillingPdf buttonProps={DOWNLOAD_BUTTON_PROPS} type="invoice" itemId={data.vendorId} />
      </Box>
    </Box>
  );
}

export default InvoiceInfoCell;
