import { Table, TableBody, TableCell, TableRow, styled } from '@mui/material';
import { ReactNode } from 'react';

const StyledTable = styled(Table, { shouldForwardProp: (prop) => prop !== 'disableOuterBorder' })<{
  disableOuterBorder: boolean;
}>(({ theme, disableOuterBorder }) => ({
  borderTop: disableOuterBorder ? 'none' : `1px solid ${theme.palette.divider}`,
  '& td': {
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  '& tr > td:first-of-type': {
    fontSize: '0.875rem',
    color: theme.customColors.dark[300],
  },
}));

export type DetailsTableProps = {
  items: Array<{ title: string; value: ReactNode; color?: string }>;
  disableOuterBorder?: boolean;
};

function DetailsTable({ items, disableOuterBorder = false }: DetailsTableProps) {
  return (
    <StyledTable disableOuterBorder={disableOuterBorder} cellPadding={0.5}>
      <TableBody>
        {items.map((item, index) => (
          <TableRow key={index}>
            <TableCell>{item.title}</TableCell>
            <TableCell sx={{ color: item.color }}>{item.value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </StyledTable>
  );
}

export default DetailsTable;
