import { CircularProgress } from '@mui/material';
import FlexBox from './FlexBox';

export type PreloaderProps = {
  minHeight?: number | string;
};

export function Preloader({ minHeight = 400 }: PreloaderProps) {
  return (
    <FlexBox minHeight={minHeight} justifyContent="center">
      <CircularProgress />
    </FlexBox>
  );
}
