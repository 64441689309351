import { createApi } from '@reduxjs/toolkit/query/react';
import { ReportAnIssueParams, ResponseInfo } from '@usgm/inbox-api-types';
import { ENVIRONMENT } from '../env';
import { axiosBaseQuery } from './axios/baseQuery';

export const mailsApi = createApi({
  reducerPath: 'mailsApi',
  baseQuery: axiosBaseQuery(ENVIRONMENT.MAILS_API_URL),
  endpoints: (builder) => ({
    reportAnIssue: builder.mutation<ResponseInfo, ReportAnIssueParams>({
      query: ({ requestedOn, ...rest }) => ({
        url: `/report/issue/v2`,
        method: 'post',
        data: {
          ...rest,
          requestedOn: !requestedOn ? new Date().toISOString() : requestedOn,
        },
      }),
    }),
  }),
});

export const { useReportAnIssueMutation } = mailsApi;
