import { QUARANTINE_READ_MORE_URL } from '@inbox/constants';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Typography, styled, Link, useTheme } from '@mui/material';

import { FlexBox, Tooltip } from '@usgm/shared-ui';

const IconContainer = styled(FlexBox)(({ theme }) => ({
  svg: {
    color: theme.customColors.dark[400],
  },
}));

function QuarantineHelp() {
  const theme = useTheme();
  return (
    <Tooltip
      arrow
      title={
        <Typography>
          An item is in quarantine in two cases: Under a name which is not already approved on your account Delivered by
          USPS while you have not submitted form 1583.
          <Link sx={{ fontWeight: theme.typography.fontWeightBold }} href={QUARANTINE_READ_MORE_URL} target="_blank">
            {' '}
            Read more
          </Link>
        </Typography>
      }
    >
      <IconContainer>
        <HelpOutlineOutlinedIcon fontSize="small" />
      </IconContainer>
    </Tooltip>
  );
}

export default QuarantineHelp;
