import { type GridColDef, type GridRenderCellParams } from '@mui/x-data-grid';
import { type DtoUnbilledCharge } from '@usgm/inbox-api-types';
import { cellHelpers, dataTableConfig } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import UnbilledChargeInfoCell from '../components/gridCells/UnbilledChargeInfoCell';

export const UNBILLED_CHARGES_COLUMNS: GridColDef[] = [
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'vendorId',
    headerName: 'Charge ID',
    valueGetter: (value: string) => `#${value}`,
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'description',
    headerName: 'Charge Description',
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'quantity',
    headerName: 'Quantity',
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'amountTotal',
    headerName: 'Total Amount',
    valueGetter: inboxHelpers.formatPrice,
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'date',
    headerName: 'Created date',
    valueGetter: cellHelpers.formatDateString,
  },
];

export const UNBILLED_CHARGES_MOBILE_COLUMNS: GridColDef[] = [
  {
    field: 'unbilledChargeInfo',
    headerName: 'info',
    flex: 1,
    renderCell: (params: GridRenderCellParams<DtoUnbilledCharge>) => {
      return <UnbilledChargeInfoCell data={params.row} />;
    },
  },
];
