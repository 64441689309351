import { saveAs } from 'file-saver';

import {
  type BaseMail,
  type BaseScan,
  type DtoScan,
  type DtoScansList,
  type EntityFilter,
  type PaginationParams,
  type ResponseInfo,
  type TScanFilters,
} from '@usgm/inbox-api-types';
import { inboxHelpers } from '@usgm/utils';
import { mailsApi as baseMailsApi } from '../../../../api/mailsApi';
import { inboxAccountsApi } from '../../inboxAccountsApi';
import { mailsApi } from '../mails/api';
import { SCAN_TABS, ScansPathParams } from './paths';

const TAG_TYPES = {
  SCAN: 'Scan',
  SCAN_LIST: 'ScanList',
} as const;

export const scansApi = baseMailsApi
  .enhanceEndpoints({
    addTagTypes: [TAG_TYPES.SCAN, TAG_TYPES.SCAN_LIST],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getScansList: builder.query<
        DtoScansList,
        PaginationParams & Omit<ScansPathParams, 'itemId'> & { filter?: EntityFilter<TScanFilters, string[]> }
      >({
        query: ({ tab, folderId, limit = inboxHelpers.DEFAULT_PAGINATION_LIMIT, skip = 0, filter }) => {
          const filterQueryString = inboxHelpers.constructGenericFilters({
            currentTab: tab,
            tabs: [...SCAN_TABS],
            folderId,
            filter,
          });
          return {
            url: `/scans/v2${filterQueryString ? `?${filterQueryString}` : ''}`,
            method: 'get',
            params: {
              skip,
              limit,
            },
          };
        },
        providesTags: (scans) =>
          scans?.list
            ? [...scans.list.map(({ uuid }) => ({ type: TAG_TYPES.SCAN, uuid })), TAG_TYPES.SCAN_LIST]
            : [TAG_TYPES.SCAN_LIST],
      }),
      getScanPdfFileById: builder.query<string, { id: string | null }>({
        query: ({ id }) => ({
          url: `/view/scan/${id}`,
          method: 'get',
          responseType: 'blob',
          transformResponse: (response) => {
            if (response instanceof Blob) {
              const blob = new Blob([response], { type: response.type });
              return URL.createObjectURL(blob);
            }
            return null;
          },
        }),
      }),
      downloadScanFileById: builder.query<boolean, { id: string; fileName?: string }>({
        query: ({ id, fileName }) => ({
          url: `/download/scan/${id}`,
          method: 'get',
          responseType: 'blob',
          transformResponse: (response) => {
            if (response instanceof Blob) {
              const blob = new Blob([response], { type: response.type });
              saveAs(blob, fileName || id);
              return true;
            }
            return false;
          },
        }),
      }),
      markScanAsRead: builder.mutation<
        ResponseInfo,
        { id: string } & PaginationParams &
          Omit<ScansPathParams, 'itemId'> & { filter?: EntityFilter<TScanFilters, string[]> }
      >({
        query: ({ id }) => ({
          url: `/scan/mark/read`,
          method: 'put',
          data: {
            scanId: id,
          },
        }),
        onQueryStarted: async ({ id, tab, folderId, limit, skip, filter }, { dispatch, queryFulfilled }) => {
          const ids = [id];
          const patchList = dispatch(
            scansApi.util.updateQueryData('getScansList', { tab, folderId, limit, skip, filter }, (draft) => {
              draft.list = inboxHelpers.updateIsReadStatus({
                data: draft,
                ids,
                isRead: true,
              });
            }),
          );
          const patchNavigationCounter = dispatch(
            inboxAccountsApi.util.updateQueryData('getNavigation', null, (draft) => {
              inboxHelpers.updateNavigationUnreadCounts({
                count: ids.length,
                navigation: draft,
                type: 'scan',
                folderId,
              });
            }),
          );
          const patchFolderCounts = dispatch(
            inboxAccountsApi.util.updateQueryData('getUnreadScansInfo', null, (draft) => {
              draft.forEach((item) => {
                if (Number(folderId) === item.folderId || (!folderId && item.folderId === null)) {
                  item.unreadCount = item.unreadCount - ids.length;
                }
              });
            }),
          );
          try {
            await queryFulfilled;
          } catch (error) {
            patchList.undo();
            patchNavigationCounter.undo();
            patchFolderCounts.undo();
          }
        },
      }),
      deleteScanById: builder.mutation<ResponseInfo, { id: string }>({
        query: ({ id }) => ({
          url: `/scan/delete/${id}`,
          // @TODO: #REST API - DELETE
          method: 'put',
        }),
        invalidatesTags: (_, error, { id }) => (!error ? [{ type: TAG_TYPES.SCAN, id }, TAG_TYPES.SCAN_LIST] : []),
      }),

      getScanById: builder.query<DtoScan, { id: string }>({
        query: ({ id }) => ({
          url: `/scans/v2/${id}`,
          method: 'get',
        }),
      }),
      cancelScanRequest: builder.mutation<ResponseInfo, { id: BaseScan['uuid']; mailId: BaseMail['id'] }>({
        query: ({ id }) => ({
          url: `/scan/cancel/${id}`,
          method: 'put',
        }),
        onQueryStarted: async ({ mailId }, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled;
            dispatch(mailsApi.util.invalidateTags(['MailsList', { type: 'MailItem', id: mailId }]));
          } catch (error) {
            console.log('Failed to cancel scan request.', error);
          }
        },
        invalidatesTags: (_, error) => (!error ? [TAG_TYPES.SCAN_LIST] : []),
      }),
      bulkDeleteScans: builder.mutation<ResponseInfo, { scanIds: BaseScan['uuid'][] | 'all' }>({
        query: ({ scanIds }) => ({
          url: `/scan/multiple/delete`,
          method: 'post',
          data: {
            scanIds,
          },
        }),
        invalidatesTags: (_, error) => (!error ? [TAG_TYPES.SCAN_LIST] : []),
      }),
    }),
  });

export const {
  useGetScansListQuery,
  useGetScanPdfFileByIdQuery,
  useDownloadScanFileByIdQuery,
  useDeleteScanByIdMutation,
  useMarkScanAsReadMutation,
  useGetScanByIdQuery,
  useCancelScanRequestMutation,
  useBulkDeleteScansMutation,
} = scansApi;
