import { camelCase, isObject } from 'lodash-es';

export type Camelize<T extends string> = T extends `${infer A}_${infer B}` ? `${A}${Capitalize<B>}` : T;

export type CamelizeKeys<T> = T extends object
  ? T extends Array<infer U>
    ? Array<CamelizeKeys<U>>
    : {
        [key in keyof T as key extends string ? Camelize<Uncapitalize<key>> : key]: CamelizeKeys<T[key]>;
      }
  : T;
export function camelizeKeys<T>(obj: T): CamelizeKeys<T> {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v)) as unknown as CamelizeKeys<T>;
  } else if (isObject(obj)) {
    const newObj = obj as Record<string, unknown>;
    return Object.keys(obj).reduce((acc: Record<string, unknown>, key: string) => {
      const camelKey = !key.includes('-') && !key.includes(' ') ? camelCase(key) : key;
      if (!acc[camelKey]) {
        acc[camelKey] = camelizeKeys(newObj[key]);
      }

      return acc;
    }, {} as Record<string, unknown>) as CamelizeKeys<T>;
  }
  return obj as CamelizeKeys<T>;
}
