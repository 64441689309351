import { Box, styled } from '@mui/material';

export const CardItemWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(3, 0, 2, 0),
  backgroundColor: theme.palette.background.paper,

  '&:last-child': {
    borderBottom: 'none',
  },
}));
