import { Box, styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Inbox, Preloader, useMobileMode } from '@usgm/shared-ui';

import { useCreateOrUpdateUserSettingsMutation, useGetUserSettingsQuery, UserSettingType } from '../../api';

import SettingFeatureSwitcher from '../../components/SettingFeatureSwitcher';
import { SettingsFormBox } from '../../components/SettingsFormBox';

import ShipmentSchedulingForm from '../../components/ShipmentScheduling/ShipmentSchedulingForm';
import { ShipmentSchedulingFormSchemaType } from '../../components/ShipmentScheduling/shipmentSchedulingFormSchema';

import { useAppDispatch } from '../../../../../../store';
import { apiMessagesSlice } from '../../../../../apiMessages/apiMessagesSlice';
import { CardHeader } from '../../components/Card';

const Root = styled(Box)(({ theme }) => ({
  maxWidth: theme.spacing(120),
  margin: '0 auto',
}));

const MainToggleContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0, 4, 0),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

function ShipmentSchedulingPage() {
  const isMobile = useMobileMode();
  const dispatch = useAppDispatch();
  const [mainToggleEnabled, setMainToggleEnabled] = useState(false);

  const { data: userSettings, isLoading: isLoadingUserSettings } = useGetUserSettingsQuery({
    settingType: UserSettingType.ShippingPreferences,
  });
  const [saveUserSettings, saveUserSettingsQuery] = useCreateOrUpdateUserSettingsMutation();

  const defaultPreferences = useMemo(
    () => userSettings?.shippingPreferences?.[0] || { doShipmentScheduling: false },
    [userSettings?.shippingPreferences],
  );

  const { doShipmentScheduling } = defaultPreferences;

  useEffect(() => {
    setMainToggleEnabled(!!doShipmentScheduling);
  }, [setMainToggleEnabled, doShipmentScheduling]);

  const onMainToggleChange = async (value: boolean) => {
    setMainToggleEnabled(value);
    if (value === false && doShipmentScheduling) {
      const response = await saveUserSettings({
        uuid: userSettings?.uuid,
        payload: { shippingPreferences: [{ ...defaultPreferences, doShipmentScheduling: false }] },
      });
      const hasError = 'error' in response;
      dispatch(
        apiMessagesSlice.actions.createMessage({
          severity: hasError ? 'error' : 'success',
          text: hasError ? 'Failed disabling shipment scheduling. Please try again.' : 'Shipment scheduling disabled.',
        }),
      );
    }
  };

  const onFormSubmit = useCallback(
    async (data: ShipmentSchedulingFormSchemaType) => {
      const response = await saveUserSettings({
        uuid: userSettings?.uuid,
        payload: {
          shippingPreferences: [
            {
              ...defaultPreferences,
              doShipmentScheduling: true,
              ...data,
            },
          ],
        },
      });
      const hasError = 'error' in response;
      dispatch(
        apiMessagesSlice.actions.createMessage({
          severity: hasError ? 'error' : 'success',
          text: hasError
            ? 'Failed saving shipment scheduling. Please try again.'
            : 'Shipment scheduling preferences saved.',
        }),
      );
    },
    [defaultPreferences, dispatch, saveUserSettings, userSettings?.uuid],
  );

  const subHeaderText = isMobile
    ? 'Configure your mail to be shipped on a dedicated schedule.'
    : 'Your account allows shipment scheduling to simplify mail management! You can choose to have your mail shipped on a dedicated schedule.';

  return (
    <Inbox.PageContainer>
      <Inbox.Card noSpacings>
        <CardHeader title="Configure Shipment Scheduling" subTitle={subHeaderText} />
        <Inbox.CardContent>
          {isLoadingUserSettings ? (
            <Preloader />
          ) : (
            <Root>
              <MainToggleContainer>
                <SettingFeatureSwitcher
                  title={'Shipment Scheduling'}
                  description={'I would like to enable scheduled shipments.'}
                  defaultChecked={userSettings?.shippingPreferences?.[0]?.doShipmentScheduling}
                  onChange={onMainToggleChange}
                  isLoading={saveUserSettingsQuery.isLoading}
                />
              </MainToggleContainer>
              {mainToggleEnabled && (
                <SettingsFormBox>
                  <ShipmentSchedulingForm defaultValues={defaultPreferences} onSubmit={onFormSubmit} />
                </SettingsFormBox>
              )}
            </Root>
          )}
        </Inbox.CardContent>
      </Inbox.Card>
    </Inbox.PageContainer>
  );
}

export default ShipmentSchedulingPage;
