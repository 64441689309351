import { createRoot } from 'react-dom/client';

import App from './app/app';
import Providers from './Providers';
import initSentry from './sentry';

initSentry();

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Providers>
    <App />
  </Providers>,
);
