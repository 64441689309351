import { ReactNode } from 'react';

import { AccountVerificationStepKey } from '@usgm/inbox-api-types';

import { StepText } from '../components/VerifyAccountSteps';
import AddIdsStepItemContent from '../components/stepItems/addIds/AddIdsStepItemContent';
import { AddNamesStepItemActions } from '../components/stepItems/addNames/AddNamesStepItemActions';
import { RedirectMailStepItemActions } from '../components/stepItems/redirectMail/RedirectMailStepItemActions';
import { RedirectMailStepItemContent } from '../components/stepItems/redirectMail/RedirectMailStepItemContent';
import { NotarizationStepItemContent } from '../components/stepItems/notarization/NotarizationStepItemContent';
import { AddIdsStepActions } from '../components/stepItems/addIds/AddIdsStepActions';
import NotarizationStepItemActions from '../components/stepItems/notarization/NotarizationStepItemActions';
import { NotarizationTitle } from '../components/stepItems/notarization/NotarizationTitle';

export type OnboardingStepKey = AccountVerificationStepKey | 'signedUp';

export const ONBOARDING_STEPS_MAPPER: Record<
  OnboardingStepKey,
  {
    stepKey: OnboardingStepKey;
    getContent: (args: { skipped?: boolean; completed?: boolean }) => ReactNode;
    title?: string | ReactNode;

    getActions?: (args: { skipped?: boolean; completed?: boolean }) => ReactNode;
  }
> = {
  signedUp: {
    stepKey: 'signedUp',
    getContent: ({ completed }) => (
      <StepText completed={completed}>Great! You’ve completed the sign up process...</StepText>
    ),
    title: 'Signed up',
  },
  mailRedirection: {
    stepKey: 'mailRedirection',
    getContent: ({ completed, skipped }) => (completed ? null : <RedirectMailStepItemContent skipped={skipped} />),
    getActions: ({ skipped, completed }) =>
      completed && !skipped ? undefined : <RedirectMailStepItemActions skipped={skipped} completed={completed} />,
    title: 'Redirect mail from your current US address to your USGM mailbox.',
  },
  accountNames: {
    stepKey: 'accountNames',
    getContent: () => 'List names of family members who will receive mail at this mailbox',
    title: 'Add Name(s)',
    getActions: ({ completed }) => <AddNamesStepItemActions completed={completed} />,
  },
  identifications: {
    stepKey: 'identifications',
    getContent: () => <AddIdsStepItemContent />,
    getActions: () => <AddIdsStepActions />,
    title: 'Add IDs',
  },
  notarization: {
    stepKey: 'notarization',
    getContent: () => <NotarizationStepItemContent />,
    title: <NotarizationTitle />,
    getActions: () => <NotarizationStepItemActions />,
  },
};

export const ONBOARDING_STEPS = [
  ONBOARDING_STEPS_MAPPER.signedUp,
  ONBOARDING_STEPS_MAPPER.mailRedirection,
  ONBOARDING_STEPS_MAPPER.accountNames,
  ONBOARDING_STEPS_MAPPER.identifications,
  ONBOARDING_STEPS_MAPPER.notarization,
] as const;
