import { GridEventListener } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';

import { SCANS_FILTERS, type BaseScan, type TScanFilters } from '@usgm/inbox-api-types';
import {
  DataTable,
  RenderBatchActionsParams,
  useGetImageRowHeight,
  useMobileMode,
  usePaginationParams,
} from '@usgm/shared-ui';

import { useGetScansListQuery, useMarkScanAsReadMutation } from '../api';
import { DEFAULT_SCAN_TAB, ScansPathParams, createScansPath } from '../paths';
import ScansCategoryFilter from './ScansCategoryFilter';
import { COMPLETED_SCANS_COLUMNS } from './gridConfig/completed';
import { PENDING_SCANS_COLUMNS } from './gridConfig/pending';

import { useCallback, useMemo } from 'react';
import NoScansIcon from '../../../assets/noScansIcon.svg?react';
import { useGenericFilters } from '../../../components/filters/useGenericFilters';
import { useNavigatePreservingParams } from '../../../hooks/useNavigatePreservingParams';
import FolderName from '../../mails/mailFolders/FolderName';
import { getMobileColumns } from './gridConfig/getMobileColumns';
import ScansBulkActions from './actions/ScansBulkActions';

function ScansList() {
  const isMobile = useMobileMode();
  const navigate = useNavigatePreservingParams();
  const { folderId, tab = DEFAULT_SCAN_TAB } = useParams<ScansPathParams>();
  const { limit, skip } = usePaginationParams();
  const [markAsReadMutation] = useMarkScanAsReadMutation();
  const { getAllFilters } = useGenericFilters<TScanFilters>(SCANS_FILTERS);
  const filter = getAllFilters();

  const { data, isLoading, isFetching, refetch } = useGetScansListQuery({
    tab,
    folderId: folderId,
    limit,
    skip,
    filter,
  });

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    if ('isRead' in params.row && !params.row.isRead) {
      markAsReadMutation({ id: params.row.uuid, tab, folderId, limit, skip, filter });
    }

    navigate(createScansPath({ tab, folderId, itemId: params.row.uuid }), {
      paramsToAdd: {
        mailId: params.row.mailId,
      },
    });
  };

  const scanColumns = useMemo(() => {
    if (isMobile) {
      return getMobileColumns(tab === 'pending');
    }
    return tab === 'completed' ? COMPLETED_SCANS_COLUMNS : PENDING_SCANS_COLUMNS;
  }, [isMobile, tab]);

  const getRowHeight = useGetImageRowHeight(isMobile);

  const renderBatchActions = useCallback(
    ({ ids, rows, totalSelected, clearSelection }: RenderBatchActionsParams<BaseScan>) => {
      return ids.length > 0 ? (
        <ScansBulkActions clearSelection={clearSelection} allScans={totalSelected} scans={rows} />
      ) : null;
    },
    [],
  );

  return (
    <>
      {!isMobile && <FolderName />}
      <DataTable
        enableTotalSelection
        renderBatchActions={renderBatchActions}
        disableHeaderCheckbox
        getRowHeight={getRowHeight}
        headerLeft={<ScansCategoryFilter />}
        onRowClick={handleRowClick}
        disableColumnSelector={tab === 'pending'}
        onRefresh={refetch}
        entityName="scans"
        multiSelect={tab === 'completed'}
        getRowId={(row: BaseScan) => row.uuid}
        placeholderIcon={<NoScansIcon />}
        totalRows={data?.count}
        loading={isFetching}
        enableUnread={tab === 'completed'}
        columns={scanColumns}
        rows={data?.list ?? []}
        isLoading={isLoading}
        checkboxSelection={tab === 'completed' ? true : false}
      />
    </>
  );
}

export default ScansList;
