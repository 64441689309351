import { Box, Grid, Typography, styled } from '@mui/material';
import parse from 'autosuggest-highlight/parse';

import PlaceIcon from '../../../../assets/placeIcon.svg?react';

const Item = styled('li')(({ theme }) => ({
  padding: theme.spacing(1, 1.25),
  fontSize: '1rem',
}));

type AutocompletePlaceProps = {
  attributes: React.HTMLAttributes<HTMLLIElement>;
  option: google.maps.places.AutocompletePrediction;
};
export default function AutocompletePlace({ attributes, option }: AutocompletePlaceProps) {
  const matches = option.structured_formatting.main_text_matched_substrings || [];

  const parts = parse(
    option.structured_formatting.main_text,
    matches.map((match) => [match.offset, match.offset + match.length]),
  );
  return (
    <Item {...attributes}>
      <Grid container alignItems="center">
        <Grid item sx={{ display: 'flex', width: 40 }}>
          <PlaceIcon />
        </Grid>
        <Grid item sx={{ width: 'calc(100% - 40px)', wordWrap: 'break-word' }}>
          {parts.map((part, index) => (
            <Box key={index} component="span" sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
              {part.text}
            </Box>
          ))}
          {option.structured_formatting.secondary_text ? ', ' : ''}
          <Typography component="span" color="text.secondary">
            {option.structured_formatting.secondary_text}
          </Typography>
        </Grid>
      </Grid>
    </Item>
  );
}
