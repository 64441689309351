import { PRODUCTION_URL } from '@inbox/constants';
import { Helmet } from 'react-helmet-async';

const APP_NAME = 'US Global Mail';

type HTMLHeadMetaTagsProps = {
  title: string;
  description?: string;
  imageUrl?: string;
  canonicalUrl?: string;
};
export function HTMLHeadMetaTags({ title, description, imageUrl, canonicalUrl }: HTMLHeadMetaTagsProps) {
  return (
    <Helmet titleTemplate={`%s | ${APP_NAME}`} defaultTitle={APP_NAME}>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
      <link rel="canonical" href={canonicalUrl || PRODUCTION_URL} />
    </Helmet>
  );
}
