import { Box, Typography, styled } from '@mui/material';
import React, { useId, useMemo, useRef } from 'react';

import { AccountRelation, AccountStatus } from '@usgm/inbox-api-types';
import { ConfirmationDialog, MoreMenu, useDialog } from '@usgm/shared-ui';
import { getKeys, inboxHelpers } from '@usgm/utils';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../../../store';
import { SETTINGS_PATHS } from '../../paths';
import { useDeleteSecondaryAccountByIdMutation } from '../../../onboarding/api';
import { apiMessagesSlice } from '../../../../../apiMessages/apiMessagesSlice';

export type AccountNameActionsProps = {
  accountStatus: AccountStatus;
  relationType: AccountRelation;
  uuid: string;
};

const StyledMoreButtonCell = styled(Box)(({ theme }) => ({
  textAlign: 'right',
  width: '100%',
  marginRight: theme.spacing(8),
}));

export function AccountNameActions({ accountStatus, relationType, uuid }: AccountNameActionsProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const moreButton = useRef<HTMLHtmlElement>();
  const [deleteAccountMutation, deletionQuery] = useDeleteSecondaryAccountByIdMutation();

  const popperId = useId();

  // Dialogs
  const deleteDialog = useDialog();

  const handleDeleteAccountName = async () => {
    const response = await deleteAccountMutation({ userId: uuid });
    const hasError = 'error' in response;
    const apiMessage = 'message' in response || '';
    dispatch(
      apiMessagesSlice.actions.createMessage({
        severity: hasError ? 'error' : 'success',
        text:
          apiMessage || hasError
            ? 'Unable to delete account. Please try again later'
            : 'Account has been deleted successfully',
      }),
    );
  };

  const allActions = useMemo(() => {
    const actions: { [Key in inboxHelpers.TAccountNameAction]: () => void } = {
      editName: () => {
        navigate(SETTINGS_PATHS.EDIT_ACCOUNT_NAME(uuid));
      },
      editDocuments: () => {
        navigate(SETTINGS_PATHS.EDIT_ACCOUNT_NAME_DOCUMENTS(uuid));
      },
      delete: () => !deletionQuery.isLoading && deleteDialog.openDialog(),
    };

    return actions;
  }, [navigate, uuid, deletionQuery.isLoading, deleteDialog]);

  const actionsList = useMemo(() => {
    return getKeys<typeof inboxHelpers.ACCOUNT_NAME_ACTIONS>(inboxHelpers.ACCOUNT_NAME_ACTIONS)
      .filter((action) => inboxHelpers.isAccountNameActionAllowed(relationType, action))
      .map((action) => ({
        label: inboxHelpers.ACCOUNT_NAME_ACTIONS[action].label,
        onClick: allActions[action],
        dangerous: inboxHelpers.ACCOUNT_NAME_ACTIONS[action].color === 'dangerous',
      }));
  }, [allActions, relationType]);

  const modifyAllowed = inboxHelpers.canModifyAccount(accountStatus, relationType);

  return (
    <>
      <StyledMoreButtonCell>
        <Box ref={moreButton}>
          {modifyAllowed && <MoreMenu containerProps={{ 'aria-describedby': popperId }} items={actionsList} />}
        </Box>
      </StyledMoreButtonCell>

      <ConfirmationDialog
        onClose={deleteDialog.closeDialog}
        onConfirm={handleDeleteAccountName}
        confirmText="Confirm"
        cancelText="Cancel"
        open={deleteDialog.open}
        content={
          <Box>
            <Typography textAlign="center" variant="h5">
              Are you sure you want to delete this account name?
            </Typography>
            <Typography textAlign="center">
              You are going to delete this account name and all associated resources (e.g. forms, ids).
            </Typography>
          </Box>
        }
      />
    </>
  );
}

export default React.memo(AccountNameActions);
