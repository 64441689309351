import { styled } from '@mui/material';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import DrawerMenu from './components/DrawerMenu';
import Footer from './components/Footer';
import Header from './components/Header';
import { FOOTER_SECTIONS, MAIN_MOBILE_NAVIGATION, MAIN_NAVIGATION, SOCIAL_LINKS } from './config';

const DRAWER_WIDTH = 300;

const Main = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: 0,
  transition: theme.transitions.create('left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  left: 0,
  ...(open && {
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    left: DRAWER_WIDTH,
  }),
  position: 'relative',
}));

export type PublicLayoutProps = {
  disableHeader?: boolean;
  disableFooter?: boolean;
  disableHeaderItems?: boolean;
};

export default function PublicLayout({
  disableHeader = false,
  disableFooter = false,
  disableHeaderItems = false,
}: PublicLayoutProps) {
  const [drawerMenuOpened, setDrawerMenuOpened] = useState(false);
  const toggleDrawerMenu = () => setDrawerMenuOpened((prev) => !prev);

  return (
    <>
      <Main open={drawerMenuOpened}>
        {disableHeader ? null : (
          <Header
            disableHeaderItems={disableHeaderItems}
            menuItems={MAIN_NAVIGATION}
            openDrawer={toggleDrawerMenu}
            isDrawerOpen={drawerMenuOpened}
          />
        )}
        <Outlet />
        {disableFooter ? null : <Footer sections={FOOTER_SECTIONS} socialLinks={SOCIAL_LINKS} />}
      </Main>
      <DrawerMenu
        menuItems={MAIN_MOBILE_NAVIGATION}
        onClose={toggleDrawerMenu}
        onOpen={toggleDrawerMenu}
        open={drawerMenuOpened}
        anchor={'left'}
      />
    </>
  );
}
