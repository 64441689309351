import { zodResolver } from '@hookform/resolvers/zod';
import { EXPEDITE_FEE, LETTER_SCAN_FEE, SCAN_PAGE_FEE } from '@inbox/constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { DtoUserScanBundle } from '@usgm/inbox-api-types';

import { Button, FlexBox, ImageCell, PureLink, Textarea, Tooltip } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import { useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useActiveSubscription } from '../../../../../../hooks/useActiveSubscription';

import { useAppSelector } from '../../../../../../store';
import { SETTINGS_PATHS } from '../../../settings/paths';

import { selectSelectedMails } from '../../mailsSlice';
import { MailActionRequest } from '../../paths';
import { mailActionRequestSchema, TMailActionRequestsSchemaType } from './mailActionRequestSchema';

const RequestedItemContainer = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(3),
}));

export type RequestedItemsProps = {
  actionRequest: MailActionRequest;
  hideInstruction?: boolean;
  onFormSubmit: (data: TMailActionRequestsSchemaType) => void;
  isSubmitting?: boolean;
  allowExpedite?: boolean;
  activeScanBundle: DtoUserScanBundle | null;
};
function RequestedItems({
  actionRequest,
  hideInstruction = false,
  onFormSubmit,
  isSubmitting,
  allowExpedite = false,
  activeScanBundle,
}: RequestedItemsProps) {
  const theme = useTheme();
  const { activeSubscription } = useActiveSubscription();
  const selectedMails = useAppSelector(selectSelectedMails);

  const defaultValues: TMailActionRequestsSchemaType = useMemo(
    () => ({
      requestedItems: selectedMails.map((mail) => ({
        mailId: mail.id,
        instruction: '',
        isExpedite: false,
        imageUrl: mail.imageUrl,
      })),
    }),
    [selectedMails],
  );

  const { control, register, handleSubmit } = useForm<TMailActionRequestsSchemaType>({
    mode: 'onChange',
    resolver: zodResolver(mailActionRequestSchema),
    defaultValues,
  });

  const { fields } = useFieldArray({ control, name: 'requestedItems' });
  const submitButtonLabel = actionRequest === MailActionRequest.Open ? 'Submit Request' : 'Confirm Scan';
  const showScanBundleSuggestion = useMemo(() => {
    return (
      actionRequest === MailActionRequest.Scan &&
      activeSubscription?.plan.otherOptions.isScanBundlesSupported &&
      !activeScanBundle
    );
  }, [actionRequest, activeSubscription, activeScanBundle]);

  return (
    <Box onSubmit={handleSubmit(onFormSubmit)} component="form" noValidate>
      {fields.map((mail, index) => (
        <RequestedItemContainer key={mail.mailId}>
          <Typography mb={2} color={theme.palette.primary.main}>
            Item #{mail.mailId}
          </Typography>
          <ImageCell enablePreview height={250} imageUrl={mail.imageUrl} />
          {!hideInstruction && (
            <Box mt={2}>
              <FlexBox justifyContent="flex-start" mb={1}>
                <InputLabel htmlFor={`requestedItems.${index}.instruction`}>
                  {actionRequest === MailActionRequest.Open ? 'Opening instructions' : 'Scan instructions'}
                </InputLabel>
                <Tooltip
                  maxWidth={306}
                  title={
                    <Typography fontSize="0.875rem">
                      Tell us the number of pages, what pages you'd like to scan ...
                    </Typography>
                  }
                >
                  <InfoOutlinedIcon fontSize="small" sx={{ color: (theme) => theme.customColors.dark[400], ml: 1 }} />
                </Tooltip>
              </FlexBox>
              <Controller
                control={control}
                name={`requestedItems.${index}.instruction`}
                render={({ field }) => <Textarea {...field} id={`requestedItems.${index}.instruction`} />}
              />
            </Box>
          )}
          {allowExpedite && (
            <FormControl>
              <FormControlLabel
                control={<Checkbox {...register(`requestedItems.${index}.isExpedite`)} />}
                label={<Typography>Expedite request</Typography>}
              />
            </FormControl>
          )}
          <Typography mt={1}>
            <Typography
              color={theme.customColors.dark[400]}
              fontWeight={theme.typography.fontWeightBold}
              component="span"
            >
              Expedited Request:
            </Typography>
            <Typography ml={1} fontWeight={theme.typography.fontWeightBold} component="span">
              {actionRequest === MailActionRequest.Open
                ? `Available in 30 mins. Additional Fee: ${inboxHelpers.formatPrice(EXPEDITE_FEE)}.`
                : `Available in 30 mins. Fee: ${inboxHelpers.formatPrice(
                    EXPEDITE_FEE,
                  )} expedite + ${inboxHelpers.formatPrice(LETTER_SCAN_FEE)}/letter + ${inboxHelpers.formatPrice(
                    SCAN_PAGE_FEE,
                  )}/page`}
            </Typography>
          </Typography>
        </RequestedItemContainer>
      ))}
      {showScanBundleSuggestion && (
        <FlexBox gap={1} justifyContent="flex-start" mb={4}>
          <Typography fontWeight={theme.typography.fontWeightBold}>Consider a scan bundle?</Typography>
          <Tooltip
            maxWidth={306}
            title={
              <>
                <Typography fontSize="0.875rem" mb={2}>
                  Pre-order scans & save 30% on mail scan pricing
                </Typography>
                <Typography textAlign="right" fontSize="0.875rem">
                  <PureLink component={Link} to={SETTINGS_PATHS.SCAN_BUNDLES}>
                    Yes, take me there
                  </PureLink>
                </Typography>
              </>
            }
          >
            <InfoOutlinedIcon fontSize="small" sx={{ color: (theme) => theme.customColors.dark[400] }} />
          </Tooltip>
        </FlexBox>
      )}
      <Button type="submit" fullWidth variant="contained" color="primary">
        {isSubmitting ? <CircularProgress size={20} /> : submitButtonLabel}
      </Button>
    </Box>
  );
}

export default RequestedItems;
