import { Box } from '@mui/material';
import { DtoTeamMember } from '@usgm/inbox-api-types';
import { EntityCell, EntityCellProps } from '@usgm/shared-ui';
import EntityStatus from '../../../../billingHistory/components/EntityStatus';

export type TeamMemberInfoCellProps = {
  data: DtoTeamMember;
};

const constructTeamMemberInfo = (data: DtoTeamMember): EntityCellProps['infoList'] => {
  return [
    {
      title: 'Email',
      value: data.email,
    },
    {
      title: 'Name',
      value: data.name || 'N/A',
    },
    {
      title: 'Role',
      value: data.orgRoleLabel,
    },
    {
      title: 'Status',
      value: <EntityStatus label={data.orgStatusLabel} statusCategory={data.orgStatusCategory} />,
    },
  ];
};

function TeamMemberInfoCell({ data }: TeamMemberInfoCellProps) {
  return (
    <Box width="100%">
      <EntityCell headingWidth="20%" hideHeadingBelowPx={540} infoList={constructTeamMemberInfo(data)} />
    </Box>
  );
}

export default TeamMemberInfoCell;
