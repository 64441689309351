import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { addressPickerSlice } from '../features/addressPicker/addressPickerSlice';
import { apiMessagesSlice } from '../features/apiMessages/apiMessagesSlice';
import { authSlice } from '../features/auth/authSlice';
import { mailFolderSlice } from '../features/inbox/features/mails/mailFolders/folderSlice';
import { mailsSlice } from '../features/inbox/features/mails/mailsSlice';
import { planPickerSlice } from '../features/planPicker/planPickerSlice';
import { preferencesSlice } from '../features/preferences/preferencesSlice';

import { appSlice } from '../app/appSlice';

import { accountApi } from '../api/accountApi';

import { notificationsApi } from '../api/notificationsApi';
import { paymentApi } from '../api/paymentApi';

import { mailsApi } from '../api/mailsApi';

import { countriesSlice } from '../features/inbox/features/countries/countriesSlice';
import { postmenApi } from '../api/postmenApi';
import { mailsShipmentSlice } from '../features/inbox/features/mails/components/mailsActionRequests/shipment/mailsShipmentSlice';
import { geolocationApi } from '../api/geolocationApi';
import { accountsNameSlice } from '../features/inbox/features/settings/pages/AccountSettings/AccountNames/AddEditAccountNamePage/accountNamesSlice';
import { companyAddressesSlice } from '../features/inbox/features/onboarding/components/AddNamesForm/companyAddressSlice';

const combinedReducer = combineReducers({
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [addressPickerSlice.name]: addressPickerSlice.reducer,
  [planPickerSlice.name]: planPickerSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [mailsApi.reducerPath]: mailsApi.reducer,
  [preferencesSlice.name]: preferencesSlice.reducer,
  [apiMessagesSlice.name]: apiMessagesSlice.reducer,
  [appSlice.name]: appSlice.reducer,
  [mailFolderSlice.name]: mailFolderSlice.reducer,
  [mailsSlice.name]: mailsSlice.reducer,
  [countriesSlice.name]: countriesSlice.reducer,
  [postmenApi.reducerPath]: postmenApi.reducer,
  [mailsShipmentSlice.name]: mailsShipmentSlice.reducer,
  [geolocationApi.reducerPath]: geolocationApi.reducer,
  [accountsNameSlice.name]: accountsNameSlice.reducer,
  [companyAddressesSlice.name]: companyAddressesSlice.reducer,
});

export const store = configureStore({
  reducer: (state, action) => {
    if (authSlice.actions.logout.match(action)) {
      state = undefined;
    }
    return combinedReducer(state, action);
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      accountApi.middleware,
      paymentApi.middleware,
      notificationsApi.middleware,
      mailsApi.middleware,
      postmenApi.middleware,
      geolocationApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof combinedReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
