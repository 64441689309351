import { Box, Button, Typography, styled, useMediaQuery } from '@mui/material';
import { CloseButton, FlexBox, StyledDialog } from '@usgm/shared-ui';

import { DialogProps } from './DialogProps';
import GlobeIcon from '../../../../assets/globeWithPins.svg?react';
import { StyledDialogContent } from '../../styled/StyledDialogContent';
import { inboxHelpers } from '@usgm/utils';

const DescriptionBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.base,
  padding: theme.spacing(3, 3, 5.75, 2),
  borderRadius: '0 0 10px 10px',
  marginTop: theme.spacing(3),
}));

const GlobContainer = styled(FlexBox)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  justifyContent: 'center',
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    svg: {
      width: '120px',
      height: '120px',
    },
  },
}));

export function SkipDialog({ open, onClose, fullScreen, onConfirm }: DialogProps) {
  const isSmallScreen = useMediaQuery(inboxHelpers.DOWN_MOBILE_LANDSCAPE);

  const handleConfirm = () => {
    onConfirm?.();
    onClose();
  };
  return (
    <StyledDialog fullScreen={fullScreen} open={open} onClose={onClose}>
      <CloseButton size="large" onClick={onClose} />

      <StyledDialogContent>
        <GlobContainer justifyContent="center" mb={2}>
          <GlobeIcon />
        </GlobContainer>

        <Typography textAlign="center" variant={isSmallScreen ? 'h5' : 'h4'}>
          Don't have a US address?
        </Typography>
        <DescriptionBox mt={3}>
          <Typography>
            For <strong>individuals with an existing mailing address within the United States,</strong> it's important
            to note that for your mail to be correctly routed to a US Global Mail address, you must{' '}
            <strong>submit a change of address with USPS.</strong> Failure to do so will result in your mail continuing
            to be delivered to your previous address.
          </Typography>
          <Typography mt={2}>
            If you <strong>do not have a US mailing address</strong>, this action does not pertain to you. Please select
            'Skip this action' to proceed.
          </Typography>
        </DescriptionBox>
        <FlexBox flexDirection="column" gap={2} paddingX={1} mt={isSmallScreen ? 4 : 6}>
          <Button onClick={handleConfirm} color="primary" variant="contained" fullWidth>
            Skip this action
          </Button>
          <Button onClick={onClose} fullWidth>
            Cancel
          </Button>
        </FlexBox>
      </StyledDialogContent>
    </StyledDialog>
  );
}
