import { Box, styled } from '@mui/material';
import { BaseMail } from '@usgm/inbox-api-types';
import { FlexBox, TruncateTextWithTooltip } from '@usgm/shared-ui';
import { Link, useParams } from 'react-router-dom';
import FolderIcon from '../../../assets/icons/folder.svg?react';
import { DEFAULT_MAIL_TAB, MailsPathParams, createMailsPath } from '../paths';

const Root = styled(FlexBox)(({ theme }) => ({
  svg: {
    color: theme.customColors.dark[400],
  },
}));

function FolderLink({ folder }: { folder?: BaseMail['mailFolder'] }) {
  const { tab = DEFAULT_MAIL_TAB } = useParams<MailsPathParams>();
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  if (!folder) {
    return 'N/A';
  }
  return (
    <Root gap={1} onClick={handleClick}>
      <Box>
        <FolderIcon />
      </Box>
      <TruncateTextWithTooltip
        lines={2}
        text={<Link to={createMailsPath({ tab, folderId: folder.id.toString() })}>{folder.name}</Link>}
      />
    </Root>
  );
}

export default FolderLink;
