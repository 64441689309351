import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, IconButton, styled } from '@mui/material';
import { DtoNote, type BaseMail } from '@usgm/inbox-api-types';
import SendNote from './SendNote';

const Root = styled(Box)(({ theme }) => ({
  bottom: 0,
  position: 'sticky',
  right: 0,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2.5),
  bottom: theme.spacing(5),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export type AddMailNoteBoxProps = {
  id: BaseMail['id'];
  noteData?: DtoNote;
  toggleMode: () => void;
  isOpen?: boolean;
};
function EditMailNoteBox({ id, noteData, toggleMode, isOpen = false }: AddMailNoteBoxProps) {
  return (
    <Root>
      {isOpen ? (
        <SendNote noteData={noteData} onSend={toggleMode} id={id} onCancel={toggleMode} />
      ) : (
        <StyledIconButton onClick={toggleMode}>
          <EditOutlinedIcon />
        </StyledIconButton>
      )}
    </Root>
  );
}

export default EditMailNoteBox;
