import { Box, Button, DialogActions, Typography, styled } from '@mui/material';
import { CloseButton, FlexBox, StyledDialog } from '@usgm/shared-ui';
import QuestionMarkIcon from '../../../../../../assets/icons/questionMark.svg?react';
import { StyledDialogContent } from '../../styled/StyledDialogContent';
import { DialogProps } from './DialogProps';

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(3),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

export function MarkAsDoneDialog({ onClose, open, fullScreen, onConfirm }: DialogProps) {
  const handleConfirm = () => {
    onClose();
    onConfirm?.();
  };
  return (
    <StyledDialog fullScreen={fullScreen} open={open} onClose={onClose}>
      <CloseButton size="large" onClick={onClose} />
      <StyledDialogContent>
        <FlexBox justifyContent="center" mb={2}>
          <QuestionMarkIcon />
        </FlexBox>
        <Box maxWidth={456} margin="auto">
          <Typography mb={7.25} textAlign="center" fontWeight="bold" variant="subtitle2">
            Can you confirm that you have completed your mail forwarding with USPS?
          </Typography>
        </Box>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={onClose} fullWidth variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} fullWidth variant="contained" color="primary">
          Yes, mark it Done
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
