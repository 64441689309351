import { useLocation, useNavigate } from 'react-router-dom';
import { Link, styled, Typography } from '@mui/material';
import { ConfirmationDialog, ConfirmationDialogProps } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';

export type AccountSuspendedDialogProps = Omit<ConfirmationDialogProps, 'title' | 'content'>;

const StyledLink = styled(Link)(() => ({
  cursor: 'pointer',
}));

function AccountSuspendedDialog(dialogProps: AccountSuspendedDialogProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const handlePayInvoicesClick = () => {
    dialogProps.onClose?.();
    if (!location.pathname.includes(inboxHelpers.APP_PATHS.BILLING_HISTORY)) {
      navigate(`${inboxHelpers.APP_PATHS.BILLING_HISTORY}/invoices`);
    }
  };
  return (
    <ConfirmationDialog
      {...dialogProps}
      open={dialogProps.open}
      onClose={dialogProps.onClose}
      title={'Your account is under a Payment Hold'}
      hideButtons={true}
      confirmText="Ok"
      content={
        <Typography>
          To unlock the account, please{' '}
          <StyledLink onClick={handlePayInvoicesClick}>pay outstanding invoices </StyledLink>
          or{' '}
          <StyledLink target="_blank" href="https://www.usglobalmail.com/contact-us/">
            contact support
          </StyledLink>
          .
        </Typography>
      }
    />
  );
}

export default AccountSuspendedDialog;
