import { inboxHelpers } from '@usgm/utils';

import { Navigate, Outlet } from 'react-router-dom';

export function ResetPasswordTokenRequired() {
  const resetPasswordToken = inboxHelpers.getSessionStorageManager().getItem('resetPasswordToken');
  if (!resetPasswordToken) {
    return <Navigate to={inboxHelpers.MAIN_PATHS.LOGIN} replace />;
  }
  return <Outlet />;
}

export default ResetPasswordTokenRequired;
