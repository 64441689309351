import { Popover as MuiPopover, PopoverProps as MuiPopoverProps, PopoverOrigin, styled } from '@mui/material';
import { forwardRef } from 'react';

type StyledProps = {
  spacings: {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  };
};

const DEFAULT_ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const DEFAULT_TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const StyledPopover = styled(MuiPopover, {
  shouldForwardProp: (propName) => propName !== 'spacings',
})<StyledProps>(({ theme, spacings: { bottom, left, right, top } }) => ({
  '& .MuiPaper-root': {
    backgroundColor: 'transparent',
    marginTop: top ? theme.spacing(top) : undefined,
    marginBottom: bottom ? theme.spacing(bottom) : undefined,
    marginLeft: left ? theme.spacing(left) : undefined,
    marginRight: right ? theme.spacing(right) : undefined,
  },
}));

export interface PopoverProps extends MuiPopoverProps, StyledProps {}

export const Popover = forwardRef<HTMLDivElement, PopoverProps>((props, ref) => {
  const spacings = props.spacings || {};
  return (
    <StyledPopover
      ref={ref}
      {...props}
      spacings={spacings}
      anchorOrigin={props.anchorOrigin || DEFAULT_ANCHOR_ORIGIN}
      transformOrigin={props.transformOrigin || DEFAULT_TRANSFORM_ORIGIN}
    />
  );
});
