import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function useNavigatePreservingParams() {
  const defaultNavigate = useNavigate();

  const [searchParams] = useSearchParams();

  const navigate = useCallback(
    (pathName: string, options: { paramsToAdd?: Record<string, string>; paramsToRemove?: string[] } = {}) => {
      const newSearchParams = new URLSearchParams(searchParams);
      if (options.paramsToAdd) {
        Object.keys(options.paramsToAdd).forEach((key) => {
          if (options?.paramsToAdd?.[key]) {
            newSearchParams.set(key, options?.paramsToAdd?.[key]);
          }
        });
      }
      if (options.paramsToRemove) {
        options.paramsToRemove.forEach((key) => {
          newSearchParams.delete(key);
        });
      }
      defaultNavigate({
        pathname: pathName,
        search: newSearchParams.toString(),
      });
    },
    [defaultNavigate, searchParams],
  );

  return navigate;
}
