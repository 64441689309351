import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const FEATURE_NAME = 'APP';

type TAppState = {
  underMaintenanceMode: boolean;
  sessionExpired: boolean;
  loginRedirect: {
    pathname: string;
    search?: string;
    hash?: string;
  } | null;
};

const initialState: TAppState = {
  underMaintenanceMode: false,
  sessionExpired: false,
  loginRedirect: null,
};

export const appSlice = createSlice({
  name: FEATURE_NAME,
  initialState,
  reducers: {
    setMaintenanceMode: (state, action: PayloadAction<TAppState['underMaintenanceMode']>) => {
      state.underMaintenanceMode = action.payload;
    },
    setSessionExpired: (state, action: PayloadAction<TAppState['sessionExpired']>) => {
      state.sessionExpired = action.payload;
    },
    setLoginRedirect: (state, action: PayloadAction<TAppState['loginRedirect']>) => {
      state.loginRedirect = action.payload;
    },
  },
});

export const processSetMaintenanceMode = createAsyncThunk<void, boolean, { state: RootState }>(
  `${FEATURE_NAME}/PROCESS_SET_MAINTENANCE_MODE`,
  async (isUnderMaintenanceMode, { dispatch }) => {
    dispatch(appSlice.actions.setMaintenanceMode(isUnderMaintenanceMode));
  },
);

// selectors

const selectState = (state: RootState) => state[FEATURE_NAME];

export const selectIsUnderMaintenanceMode = createSelector(selectState, (state) => state.underMaintenanceMode);
export const selectIsSessionExpired = createSelector(selectState, (state) => state.sessionExpired);
export const selectLoginRedirect = createSelector(selectState, (state) => state.loginRedirect);
