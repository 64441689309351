import { PayloadAction, createAsyncThunk, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  AccountRelation,
  AccountVerificationStatus,
  AccountVerificationStepStatus,
  DtoAccountVerificationState,
  RecordStatus,
  type DtoAuthData,
  type DtoCoupon,
  type DtoReferral,
  type TokenData,
  type UserInfo,
  OrgUserRole,
  AccountStatus,
} from '@usgm/inbox-api-types';
import { inboxHelpers } from '@usgm/utils';
import { jwtDecode } from 'jwt-decode';
import { RootState } from '../../store';
import { onboardingApi } from '../inbox/features/onboarding/api';
import { companyAddressesSlice } from '../inbox/features/onboarding/components/AddNamesForm/companyAddressSlice';
import { authApi } from './api';
import { appSlice } from '../../app/appSlice';

const FEATURE_NAME = 'AUTH';

const auth = inboxHelpers.getStorageManager().getItem('authData') || null;

const initialState: {
  auth: DtoAuthData | null;
  isAuthenticating: boolean;
  coupon: null | DtoCoupon;
  referral: null | DtoReferral;
} = {
  auth,
  isAuthenticating: !!auth,
  coupon: null,
  referral: null,
};

export const authSlice = createSlice({
  name: FEATURE_NAME,
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<Omit<DtoAuthData, 'data'>>) => {
      inboxHelpers.getStorageManager().removeItem('selectedWarehouse');
      const tokenData = jwtDecode<TokenData>(action.payload.token);
      const data = { ...action.payload, data: tokenData };

      inboxHelpers.getStorageManager().setItem('authData', data);
      state.isAuthenticating = true;
      state.auth = data;
    },
    updateAuthData: (state, action: PayloadAction<Partial<TokenData>>) => {
      if (state.auth) {
        state.auth.data = {
          ...state.auth.data,
          ...action.payload,
        };
        inboxHelpers.getStorageManager().setItem('authData', state.auth);
      }
    },
    logout: (state) => {
      inboxHelpers.getStorageManager().removeItem('authData');
      state.auth = null;
      state.isAuthenticating = false;
    },
    setCoupon: (state, action: PayloadAction<DtoCoupon | null>) => {
      state.coupon = action.payload;
    },
    setReferral: (state, action: PayloadAction<DtoReferral | null>) => {
      state.referral = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isAnyOf(authApi.endpoints.verifyResetPasswordToken.matchFulfilled), (state, action) => {
        inboxHelpers.getSessionStorageManager().setItem('resetPasswordToken', action.payload);
      })
      .addMatcher(isAnyOf(authApi.endpoints.login.matchFulfilled), (state) => {
        inboxHelpers.getSessionStorageManager().removeItem('resetPasswordToken');
      })
      .addMatcher(isAnyOf(onboardingApi.endpoints.getOnboardingState.matchFulfilled), (state, action) => {
        if (state.auth) {
          state.auth.accountVerificationState = action.payload;
          inboxHelpers.getStorageManager().setItem('authData', state.auth);
        }
      })
      // ToDo Add this extra reducer to avoid window.location.reload() from NameUpdateDialog and EmailUpdateDialog
      // .addMatcher(isAnyOf(settingsApi.endpoints.updateUserData.matchFulfilled), (state, action) => {
      //   if (state.auth) {
      //     state.auth.data.name = action.payload;
      //     inboxHelpers.getStorageManager().setItem('authData', state.auth);
      //   }
      // })
      .addMatcher(isAnyOf(onboardingApi.endpoints.getMeetLink.matchFulfilled), (state, action) => {
        if (state.auth && state.auth.accountVerificationState?.steps.notarization?.onlineNotarizationDetails) {
          state.auth.accountVerificationState.steps.notarization.onlineNotarizationDetails.meetLink =
            action.payload.meetLink;
        }
      });
  },
});

export const processLogout = createAsyncThunk<void, { callback?: () => void }, { state: RootState }>(
  `${FEATURE_NAME}/PROCESS_LOGOUT`,
  async ({ callback }, { dispatch, getState }) => {
    const isAuthenticated = selectIsAuthenticated(getState());
    if (isAuthenticated) {
      dispatch(authApi.endpoints.logout.initiate());
      dispatch(authSlice.actions.logout());
      dispatch(appSlice.actions.setSessionExpired(false));
      dispatch(companyAddressesSlice.actions.clearCompanyData());
      callback?.();
    }
  },
);

// selectors

const selectState = (state: RootState) => state[FEATURE_NAME];

export const selectUserData = createSelector(selectState, (state): UserInfo | null => state.auth?.data || null);

export const selectIsAuthenticated = createSelector(selectState, (state) => state.isAuthenticating);
export const selectIsTeamMember = createSelector(selectState, (state) => !!state.auth?.data?.orgParentId);
export const selectIsRegularTeamMember = createSelector(
  selectState,
  (state) => state.auth?.data?.orgRole === OrgUserRole.Regular,
);

export const selectToken = createSelector(selectState, (state) => state?.auth?.token);
export const selectRefreshToken = createSelector(selectState, (state) => state?.auth?.refreshToken);
export const selectCoupon = createSelector(selectState, (state) => state.coupon);
export const selectReferral = createSelector(selectState, (state) => state.referral);
export const selectAccountStatus = createSelector(selectState, (state) => state.auth?.accountStatus);
export const selectIsAccountUnderPaymentHold = createSelector(
  selectState,
  (state) =>
    !!state.auth?.accountStatus && [AccountStatus.Suspended, AccountStatus.Closed].includes(state.auth.accountStatus),
);

export const selectAccountVerificationState = createSelector(
  [(state) => state, (_, params) => params],
  (state, params): DtoAccountVerificationState | undefined => {
    return onboardingApi.endpoints.getOnboardingState.select(params)(state).data;
  },
);

const hasMissingDocuments = (state: DtoAccountVerificationState | undefined) => {
  if (!state) {
    return false;
  }

  return (
    state.steps.accountNames.accountNames.length === 0 ||
    state.steps.accountNames.accountNames.some((account) => {
      if (![AccountRelation.Adult, AccountRelation.Primary].includes(account.relation)) {
        return false;
      }

      const accountDocuments = state.steps.identifications.documentIds.find((document) => document.id === account.id);
      if (!accountDocuments) {
        return true;
      }
      const files = accountDocuments.files.filter((file) => !!file.id);
      return files.length !== 2;
    })
  );
};

export const selectHasRejectedDocuments = createSelector(selectAccountVerificationState, (state) => {
  if (!state) {
    return false;
  }
  return state.steps.identifications.documentIds.some((document) => {
    return document.files.some((file) => file.status === RecordStatus.Rejected);
  });
});

export const selectRejectedNotarizations = createSelector(selectAccountVerificationState, (state) => {
  if (!state) {
    return [];
  }
  return state.steps.notarization?.accountNames
    ?.filter((it) => it.rejectReason)
    ?.map((it) => ({
      reason: it.rejectReason,
      id: it.id,
      name: it.name,
    }));
});

export const selectIsPrimaryAccountStepsCompleted = createSelector(selectAccountVerificationState, (state) => {
  if (!state) {
    return false;
  }
  const primaryAccount = state.steps.accountNames.accountNames?.find((it) => it.relation === AccountRelation.Primary);
  const documentsAccount =
    primaryAccount && state.steps.identifications?.documentIds?.find((it) => it.id === primaryAccount.id);
  const notarizationAccount =
    primaryAccount && state.steps.notarization?.accountNames?.find((it) => it.id === primaryAccount.id);
  // There are some extreme cases when account may have only one document submitted and approved (legacy users).
  // To handle such cases, we consider documents as completed if the status is set as such, even though there is only one document.
  const documentsSubmitted =
    state.steps.identifications?.status === AccountVerificationStepStatus.Completed ||
    (documentsAccount?.files?.length && documentsAccount.files.length >= 2);
  return primaryAccount && documentsSubmitted && notarizationAccount;
});

export const selectIsDocumentsUploaded = (state: RootState) => {
  const accountVerificationState = selectAccountVerificationState(state, null);
  if (!accountVerificationState) {
    return false;
  }

  const missingDocuments = hasMissingDocuments(accountVerificationState);

  return !missingDocuments;
};

export const selectHasMissingDocuments = createSelector(selectAccountVerificationState, (state) => {
  return hasMissingDocuments(state);
});

export const selectIsVerified = createSelector(selectAccountVerificationState, (state) => {
  return state?.status === AccountVerificationStatus.Completed;
});

export const selectIsAccountNamesAdded = createSelector(selectAccountVerificationState, (state) => {
  if (!state) {
    return false;
  }

  return state?.steps.accountNames.status === AccountVerificationStepStatus.Completed;
});

export const selectIsNotarizationNeeded = createSelector(selectAccountVerificationState, (state) => {
  if (!state) {
    return false;
  }

  return state.steps?.accountNames?.accountNames?.some((account) => {
    // @TODO: pay tech debt by using AccountRelation.MinorChild here
    if (account.relation === ('MINOR_CHILD' as AccountRelation)) {
      return false;
    }
    const notarizationAccount = state.steps.notarization?.accountNames?.find((it) => it.id === account.id);
    return !notarizationAccount || notarizationAccount.status === AccountVerificationStepStatus.Rejected;
  });
});
