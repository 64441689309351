import { Box } from '@mui/material';
import { DtoBankAccount } from '@usgm/inbox-api-types';
import { EntityCell, EntityCellProps } from '@usgm/shared-ui';

export type BankAccountInfoCellProps = {
  data: DtoBankAccount;
};

const constructBankAccountInfo = (data: DtoBankAccount): EntityCellProps['infoList'] => {
  return [
    {
      title: 'Account Number',
      value: data.accountNumber,
    },
    {
      title: 'Bank Name',
      value: data.bankName,
    },
    {
      title: 'Bank State',
      value: data.bankState,
    },
  ];
};

function BankAccountInfoCell({ data }: BankAccountInfoCellProps) {
  return (
    <Box width="100%">
      <EntityCell headingWidth="20%" hideHeadingBelowPx={540} infoList={constructBankAccountInfo(data)} />
    </Box>
  );
}

export default BankAccountInfoCell;
