import { USETIFUL_SCRIPT_ID, USETIFUL_SCRIPT_URL, USETIFUL_TOKEN } from '@inbox/constants';
import { useScript } from '@usgm/shared-ui';

const useUsetifulScript = (options?: { skip?: boolean }) => {
  useScript({
    url: USETIFUL_SCRIPT_URL,
    anchor: 'body',
    token: USETIFUL_TOKEN,
    id: USETIFUL_SCRIPT_ID,
    skip: options?.skip,
  });
};

export default useUsetifulScript;
