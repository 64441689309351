import { CalculateShipmentRatesParams, Parcel } from '@usgm/inbox-api-types';
import { mailsShipmentUtils } from '@usgm/utils';
import { useEffect, useMemo } from 'react';
import { useCalculatePackingDimensionsMutation } from '../../../../../../../../api/geolocationApi';
import { useCalculateShipmentRatesMutation } from '../../../../../../../../api/postmenApi';
import { useGetAppSettingsQuery } from '../../../../../../../../app/api';
import { useAppSelector } from '../../../../../../../../store';
import { useAuth } from '../../../../../../../auth/hooks/useAuth';
import { useGetShipInfoQuery } from '../../../../../shipments/api';
import {
  mailsToShipSelectors,
  selectDestinationAddress,
  selectInsuranceAmount,
  selectInsureShipment,
  selectPackingDimensions,
  selectSelectedRate,
} from '../mailsShipmentSlice';
import { useActiveSubscriptionPlanDetails } from './useActiveSubscriptionPlanDetails';

export function useInitMailsShipment() {
  const { user } = useAuth();

  const destinationAddress = useAppSelector(selectDestinationAddress);
  const selectedRate = useAppSelector(selectSelectedRate);

  // fetch app settings to check enableExpediteShipping
  useGetAppSettingsQuery();

  // Pre-fetch ship info and plan details
  useGetShipInfoQuery();

  const mailsToShip = useAppSelector(mailsToShipSelectors.selectAll);

  const insureShipment = useAppSelector(selectInsureShipment);
  const insuranceAmount = useAppSelector(selectInsuranceAmount);
  const packingDimensionsInfo = useAppSelector(selectPackingDimensions);

  const packingDimensions = useMemo(() => {
    return packingDimensionsInfo?.data
      ? {
          length: packingDimensionsInfo.data.length,
          width: packingDimensionsInfo.data.width,
          height: packingDimensionsInfo.data.height,
          weight: packingDimensionsInfo.data.weight,
        }
      : null;
  }, [packingDimensionsInfo?.data]);

  const [calculatePackingDimensions, calculatePackingDimensionsQuery] = useCalculatePackingDimensionsMutation();

  const { activeSubscription } = useActiveSubscriptionPlanDetails();

  const [calculateShipmentRatesMutation, calculateShipmentRatesQuery] = useCalculateShipmentRatesMutation();

  const calculateShipmentRatesParams: CalculateShipmentRatesParams | null = useMemo(() => {
    if (!packingDimensions || !activeSubscription || !destinationAddress || !user || !mailsToShip.length) {
      return null;
    }

    const destinationCountryCode = destinationAddress.country.toLowerCase().indexOf('in') !== -1 ? 'IND' : 'USA';

    const parcelData: Parcel = mailsShipmentUtils.constructMailsParcel({
      mails: mailsToShip,
      originCountry: destinationCountryCode,
      packingDimensions,
    });

    return {
      insuredAmount: insureShipment ? insuranceAmount : 0,
      parcels: [parcelData],
      planId: activeSubscription.plan.id,
      shipTo: mailsShipmentUtils.constructShipTo({ destinationAddress, email: user.email }),
    };
  }, [activeSubscription, destinationAddress, insuranceAmount, insureShipment, mailsToShip, packingDimensions, user]);

  useEffect(() => {
    let request = null;
    // TODO: This causes lot of unnecessary request to get rates, as calculateShipmentRatesParams depends on too many variables.
    //  We should refactor this to make sure only one request is done for a single change. Maybe use debouncing?
    if (calculateShipmentRatesParams) {
      request = calculateShipmentRatesMutation(calculateShipmentRatesParams);
    }

    return () => {
      // Cancel the request if the calculation params are changed
      request?.abort();
    };
  }, [calculateShipmentRatesMutation, calculateShipmentRatesParams]);

  useEffect(() => {
    if (mailsToShip.length && !packingDimensionsInfo.data && !calculatePackingDimensionsQuery.isLoading) {
      calculatePackingDimensions(
        mailsToShip.map((mail) => ({
          length: mail.measurement.length,
          width: mail.measurement.width,
          height: mail.measurement.height,
          weight: mail.weight,
          type: mail.mailType,
        })),
      );
    }
  }, [calculatePackingDimensions, calculatePackingDimensionsQuery.isLoading, mailsToShip, packingDimensionsInfo.data]);
  const hasError = [calculatePackingDimensionsQuery, calculateShipmentRatesQuery].some((query) => {
    if (query.error && 'name' in query.error) {
      if (query.error.name === 'AbortError') {
        return false;
      }
    }
    return query.isError;
  });
  if (hasError) {
    throw new mailsShipmentUtils.ShipmentError({
      code: calculatePackingDimensionsQuery.isError
        ? mailsShipmentUtils.ShipmentErrorCode.RatesError
        : mailsShipmentUtils.ShipmentErrorCode.DimensionsError,
    });
  }

  return {
    packingDimensions,
    destinationAddress,
    selectedRate,
    calculatePackingDimensionsQuery,
    calculateShipmentRatesQuery,
  };
}
