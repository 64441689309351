import LogoutIcon from '@mui/icons-material/Logout';
import { Box, styled } from '@mui/material';
import { Button } from '@usgm/shared-ui';
import React from 'react';
import ThemeSwitcher from '../../../../preferences/components/ThemeSwitcher';
import HeaderActions from '../../HeaderActions';
import Sidebar, { SidebarProps } from './Sidebar';
import { useAuth } from '../../../../auth/hooks/useAuth';

const LogoutButtonContainer = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  position: 'absolute',
  width: '100%',
  left: 0,
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
}));

export function AccountSidebar(props: SidebarProps) {
  const { handleLogout } = useAuth();
  return (
    <Sidebar {...props}>
      <HeaderActions asNavigationItem={true} />
      <Box sx={{ paddingBottom: 8 }}>
        <ThemeSwitcher variant="raw" />
        <LogoutButtonContainer>
          <Button onClick={handleLogout} startIcon={<LogoutIcon />} fullWidth color="primary" variant="contained">
            Logout
          </Button>
        </LogoutButtonContainer>
      </Box>
    </Sidebar>
  );
}

export default React.memo(AccountSidebar);
