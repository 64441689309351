import { MouseEvent, useCallback } from 'react';
import { PureLink } from './';
import { getTrackingUrl } from '@usgm/utils';

export type ShipmentTrackingLinkProps = {
  serviceName: string;
  trackingNumber: string;
  stopClickPropagation?: boolean;
};

export function ShipmentTrackingLink({
  serviceName,
  trackingNumber,
  stopClickPropagation = true,
}: ShipmentTrackingLinkProps) {
  const trackingUrl = getTrackingUrl({ serviceName, trackingNumber });

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (stopClickPropagation) {
        event.stopPropagation();
      }
    },
    [stopClickPropagation],
  );

  if (!trackingUrl) {
    return 'N/A';
  }

  return (
    <PureLink target="_blank" href={trackingUrl} onClick={handleClick}>
      {trackingNumber}
    </PureLink>
  );
}
