import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { isString } from 'lodash-es';
import * as React from 'react';
import { CloseButton, FlexBox, Button, createScrollingStyles } from '../index';
import { inboxHelpers } from '@usgm/utils';

export type ConfirmationDialogProps = {
  disabled?: boolean;
  title?: string;
  content?: string | React.ReactNode;
  open: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  onClose?: () => void;
  confirmText?: string;
  confirmStartIcon?: React.ReactNode;
  cancelText?: string;
  hideCancelButton?: boolean;
  hideButtons?: boolean;
  large?: boolean;
  isLoading?: boolean;
  autoCloseOnConfirm?: boolean;
  backgroundColor?: string;
  height?: number | string;
};

const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) => !['hasTitle', 'large', 'backgroundColor', 'height'].includes(prop as string),
})<{
  hasTitle: boolean;
  large?: boolean;
  backgroundColor?: string;
  height?: number | string;
}>(({ theme, hasTitle, large = false, backgroundColor, height }) => ({
  '& .MuiDialog-paper': {
    ...(backgroundColor ? { backgroundColor, backgroundImage: 'none' } : {}),
    ...(height ? { height } : {}),
    paddingTop: hasTitle ? 0 : theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      width: large ? 960 : 592,
    },
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  paddingRight: theme.spacing(8),
  paddingTop: theme.spacing(2.5),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  ...createScrollingStyles({ theme, thumbSize: 10, thumbColor: theme.palette.grey[400] }),
  padding: theme.spacing(3, 4, 4, 4),
  color: theme.palette.text.primary,
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    padding: theme.spacing(3),
  },
}));

export function ConfirmationDialog({
  disabled = false,
  open,
  title,
  content,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  confirmStartIcon,
  onCancel,
  onClose,
  onConfirm,
  hideCancelButton = false,
  hideButtons = false,
  large = false,
  autoCloseOnConfirm = true,
  isLoading,
  backgroundColor,
  height,
}: ConfirmationDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleCancel = () => {
    onClose?.();
    onCancel?.();
  };
  const handleConfirm = () => {
    if (autoCloseOnConfirm) {
      onClose?.();
    }
    onConfirm?.();
  };
  return (
    <StyledDialog
      large={large}
      hasTitle={!!title}
      backgroundColor={backgroundColor}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      height={height}
    >
      <CloseButton aria-label="close" onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      {title && <StyledDialogTitle>{title}</StyledDialogTitle>}
      {content && (
        <StyledDialogContent dividers={!!title}>
          {isString(content) ? (
            <DialogContentText textAlign="center" fontSize="0.875rem">
              {content}
            </DialogContentText>
          ) : (
            content
          )}
        </StyledDialogContent>
      )}
      {!title ? <Divider /> : null}
      {content && !hideButtons && (
        <DialogActions sx={{ padding: theme.spacing(2.5, 3) }}>
          <FlexBox width="100%" gap={1.5}>
            {!hideCancelButton && (
              <Button variant="outlined" fullWidth onClick={handleCancel}>
                {cancelText}
              </Button>
            )}
            <Button
              disabled={disabled}
              isLoading={isLoading}
              variant="contained"
              autoFocus
              fullWidth
              onClick={handleConfirm}
              startIcon={confirmStartIcon}
            >
              {confirmText}
            </Button>
          </FlexBox>
        </DialogActions>
      )}{' '}
    </StyledDialog>
  );
}
