import { CircularProgress, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AccountVerificationStepStatus, NotarizationStep, RecordStatus } from '@usgm/inbox-api-types';
import { FlexBox, InfoTitle, Tooltip } from '@usgm/shared-ui';
import { pascalCase } from '@usgm/utils';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../../../../../store';
import { selectAccountVerificationState } from '../../../../../../auth/authSlice';
import CheckCircle from '../../../../../assets/checkCircle.svg?react';
import { useGetMeetLinkQuery } from '../../../api';
import { IdStatusBadge } from '../addIds/AccountIdStatusRow';

const NotarizationStatusToRecordStatusMap: Record<
  Exclude<AccountVerificationStepStatus, AccountVerificationStepStatus.Skipped>,
  RecordStatus
> = {
  [AccountVerificationStepStatus.Completed]: RecordStatus.Approved,
  [AccountVerificationStepStatus.Rejected]: RecordStatus.Rejected,
  [AccountVerificationStepStatus.InProgress]: RecordStatus.InReview,
  [AccountVerificationStepStatus.InReview]: RecordStatus.InReview,
};

const NOTARIZATION_INSTRUCTIONS = [
  {
    icon: <CheckCircle />,
    text: 'Make sure you have a working webcam on your computer',
  },
  {
    icon: <CheckCircle />,
    text: 'Gather your Primary ID and Secondary ID',
  },
];

function NotarizationDetails({ details }: { details: NotarizationStep['onlineNotarizationDetails'] }) {
  const theme = useTheme();
  const [shouldAttemptFetchingMeetLink, setShouldAttemptFetchingMeetLink] = useState(!details?.meetLink);

  const { isLoading, data } = useGetMeetLinkQuery(null, {
    skip: !shouldAttemptFetchingMeetLink,
    pollingInterval: 2000,
  });
  const meetLink = useMemo(() => details?.meetLink || data?.meetLink, [details?.meetLink, data?.meetLink]);

  useEffect(() => {
    setShouldAttemptFetchingMeetLink(!meetLink);
  }, [meetLink]);

  if (!details) return null;

  const scheduledDate = DateTime.fromISO(details.scheduledDate).toLocal();

  return (
    <>
      <Typography>Your notarization is scheduled on:</Typography>
      <Typography my={0.5} fontWeight={theme.typography.fontWeightBold} color={theme.palette.text.title}>
        {scheduledDate.toFormat('cccc, MMMM d, HH:mm')}
      </Typography>

      {meetLink ? (
        <Link sx={{ fontWeight: theme.typography.fontWeightBold }} href={meetLink} target="_blank">
          Zoom link to access
        </Link>
      ) : isLoading ? (
        <FlexBox justifyContent="flex-start">
          <CircularProgress size={16} />
          <Typography ml={2}>Setting up Zoom meeting......</Typography>
        </FlexBox>
      ) : (
        <Typography>You'll soon receive an email with the Zoom link included.</Typography>
      )}
      <Typography mt={1} mb={0.5}>
        Before your notarization, please remember to
      </Typography>
      {NOTARIZATION_INSTRUCTIONS.map(({ icon, text }, index) => (
        <FlexBox mb={0.5} key={index} gap={1} alignItems="center" justifyContent="flex-start">
          {icon}
          <Typography color={theme.customColors.dark[200]}>{text}</Typography>
        </FlexBox>
      ))}
    </>
  );
}

export function NotarizationStepItemContent() {
  const theme = useTheme();
  const accountVerificationState = useAppSelector((state) => selectAccountVerificationState(state, null));
  const showAsRows = useMediaQuery('(min-width: 600px)');

  if (!accountVerificationState) return null;
  const stepData = accountVerificationState.steps.notarization;
  const showDefaultMessage = !stepData.onlineNotarizationDetails && !stepData.accountNames.length;

  if (showDefaultMessage) {
    return (
      <InfoTitle
        color={theme.customColors.dark[300]}
        description={
          <>
            <Typography fontSize="0.875rem" mb={2}>
              Form 1583 is a legal document that allows us to receive your mail. To ensure the safety of your mail, US
              postal regulations require us to receive your explicit permission via this form.
            </Typography>
            <Typography fontSize="0.875rem">
              The form must be notarized by an official notary. We offer an online notary service to set up an
              appointment and complete it online in 15 minutes.
            </Typography>
          </>
        }
        title="What is form 1583 and why do I need it?"
      />
    );
  }

  return (
    <>
      {stepData.onlineNotarizationDetails && <NotarizationDetails details={stepData.onlineNotarizationDetails} />}
      {stepData.accountNames.map(({ id, name, rejectReason, status }) => {
        return (
          <FlexBox
            mt={2}
            key={id}
            width="100%"
            alignItems={showAsRows ? 'center' : 'flex-start'}
            flexDirection={showAsRows ? 'row' : 'column'}
            gap={1}
          >
            <Typography flex={'1 1 auto'} lineHeight={1.5} minWidth={120}>
              {name}
            </Typography>
            <Tooltip arrow title={<Typography>{rejectReason}</Typography>}>
              <IdStatusBadge
                text={`Form 1583 ${pascalCase(NotarizationStatusToRecordStatusMap[status])}`}
                status={NotarizationStatusToRecordStatusMap[status]}
              />
            </Tooltip>
          </FlexBox>
        );
      })}
    </>
  );
}
