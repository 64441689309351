import { DtoPlan, PlanTerm } from '@usgm/inbox-api-types';
import { inboxHelpers } from '@usgm/utils';
import { calculateSavingInMonths } from './calculateSavingInMonths';

export const getAnnualTermSavingsInfo = ({ plan, term }: { plan: DtoPlan; term: PlanTerm }) => {
  const savingsInMonths = calculateSavingInMonths(plan?.membership);
  const monthlyTerm = plan?.membership.items.find((it) => it.months === 1);
  const savingsInPrice = savingsInMonths && monthlyTerm?.price ? savingsInMonths * monthlyTerm.price : 0;
  const formattedSavingsInPrice = inboxHelpers.formatPrice(Math.ceil(savingsInPrice));
  const badgeText = term === PlanTerm.Annually ? `Save ${formattedSavingsInPrice}` : undefined;

  return { savingsInMonths, savingsInPrice: formattedSavingsInPrice, badgeText, hasSavings: savingsInPrice > 0 };
};
