import { z } from 'zod';

export const ADDRESS_SCHEMA_FIELDS = {
  ADDRESS_LINE: z.string(),
  ADDRESS_LINE_ADDITIONAL: z.string().optional().default(''),
  COUNTRY: z
    .object(
      {
        code: z.string(),
        name: z.string(),
        id: z.string(),
      },
      {
        errorMap: () => {
          return { message: 'Country is required' };
        },
      },
    )
    .transform((val) => val as { code: string; name: string; id: string } | null)
    .nullable()
    .refine((val) => val !== null, { message: 'Country is required' }),
  CITY: z.string().min(1, 'City is required.').max(255, 'City is too long.'),
  STATE: z.string().min(1, 'State is required.').max(255, 'State is too long.'),
  ZIP: z.string().min(1, 'Zip code is required.').max(255, 'Zip Code is too long.'),
};
