import { Dialog, styled } from '@mui/material';
import { inboxHelpers } from '@usgm/utils';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      width: 592,
    },
    [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
      paddingTop: theme.spacing(2),
    },
  },
}));
