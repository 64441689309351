import { GridColDef } from '@mui/x-data-grid';
import { cellHelpers, dataTableConfig } from '@usgm/shared-ui';
import { COMMON_SHIPMENTS_COLUMNS } from './common';
import { BaseShipment } from '@usgm/inbox-api-types';

export const PENDING_SHIPMENTS_COLUMNS: GridColDef[] = [
  ...COMMON_SHIPMENTS_COLUMNS,
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'requestedShipDate',
    headerName: 'Request Date',
    valueGetter: (value: BaseShipment['requestedShipDate']) => cellHelpers.formatDateString(value),
  },
] as const;
