import { Palette, Shadows, createTheme } from '@mui/material/styles';
import { createComponents, lightPalette, shape, createTypography, breakpoints } from '../options';
import { createCustomPalette } from '../options/palettes/helpers';

export let lightTheme = createTheme({
  palette: lightPalette,
  typography: createTypography(lightPalette as Palette),
  components: createComponents(lightPalette as Palette),
  shape,
  breakpoints,

  shadows: [
    'none',
    '0px 0px 40px 0px rgba(85, 85, 85, 0.15), 0px 0px 10px 0px rgba(51, 51, 51, 0.10)',
    '0px 24px 48px 0px rgba(0, 0, 0, 0.04)',
    '0 0px 6px rgba(60, 64, 67, .2)',
    ...Array(25).fill('none'),
  ] as Shadows,
  customColors: {
    dark: {
      100: '#00000033',
      200: '#4C4C4C',
      300: '#757B83',
      400: '#9BA6B4',
      500: '#787878',
    },
    menuBorder: '#EAECF0',
  },
});

lightTheme = createTheme(lightTheme, {
  palette: createCustomPalette(lightTheme),
});
