import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import { FlexBox } from '@usgm/shared-ui';
import SuccessIcon from '../../../assets/successIcon.svg?react';

export type SuccessBoxProps = BoxProps & {
  heading: string;
  subHeading?: string;
  maxWidth?: string;
};
function SuccessBox({ maxWidth = '100%', heading, subHeading, ...boxProps }: SuccessBoxProps) {
  const theme = useTheme();
  return (
    <Box p={4} {...boxProps} maxWidth={maxWidth}>
      <Typography textAlign="center" color={theme.palette.text.title} mb={2} variant="h4">
        {heading}
      </Typography>
      <FlexBox mb={2} justifyContent="center">
        <SuccessIcon />
      </FlexBox>
      {subHeading && <Typography textAlign="center">{subHeading}</Typography>}
    </Box>
  );
}

export default SuccessBox;
