import { ShipmentMailItem } from '@usgm/inbox-api-types';
import { formatNumber } from './formatNumber';

const DEFAULT_SEPARATOR = ' x ';

export const DEFAULT_DIMENSION_ORDER = ['width', 'height', 'length'] as const;

export const DIMENSION_DETAILS_LABEL = DEFAULT_DIMENSION_ORDER.map((key) => key.charAt(0).toUpperCase()).join(
  DEFAULT_SEPARATOR,
);

export function stringifyDimensions({
  dimensions,
  separator = DEFAULT_SEPARATOR,
  formatter = formatNumber,
  measurementUnit = 'in',
}: {
  dimensions: ShipmentMailItem['dimensions'];
  separator?: string;
  formatter?: (value: unknown) => string;
  measurementUnit?: string;
}): string {
  const dimensionsParts = DEFAULT_DIMENSION_ORDER.map((key) => dimensions[key]);

  return `${dimensionsParts.map((d) => formatter(d)).join(separator)} ${measurementUnit}`;
}
