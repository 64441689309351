import { styled } from '@mui/material';
import Container from '../Container';

export const PageContainer = styled(Container, { shouldForwardProp: (prop) => prop !== 'enableMobileSpacings' })<{
  enableMobileSpacings?: boolean;
}>(({ theme, enableMobileSpacings }) => ({
  padding: theme.spacing(6),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(0, 4, 4, 4),
  },
  [theme.breakpoints.down('sm')]: {
    padding: enableMobileSpacings ? theme.spacing(4, 2.5, 4, 2.5) : 0,
  },
}));
