import { BaseMail } from '@usgm/inbox-api-types';
import { createContext, ReactNode, useCallback, useMemo, useState } from 'react';
import { type ShipmentContextType } from './config';

export const ShipmentContext = createContext<ShipmentContextType | undefined>(undefined);

export const ShipmentProvider = ({
  children,
  defaultSteps,
  defaultActiveStep = 0,
  mails,
}: {
  mails: BaseMail[];
  children: ReactNode;
  defaultSteps: ShipmentContextType['steps'];
  defaultActiveStep?: number;
}) => {
  const [activeStepIndex, setActiveStepIndex] = useState<ShipmentContextType['activeStepIndex']>(defaultActiveStep);
  const steps = defaultSteps;

  const stepsList = useMemo(() => Array.from(steps.values()), [steps]);
  const activeStep = stepsList[activeStepIndex];

  const goToPreviousStep = useCallback(() => {
    if (activeStepIndex > 0) {
      setActiveStepIndex(activeStepIndex - 1);
    }
  }, [activeStepIndex, setActiveStepIndex]);

  const goToNextStep = useCallback(() => {
    if (activeStepIndex < stepsList.length - 1) {
      setActiveStepIndex(activeStepIndex + 1);
    }
  }, [activeStepIndex, setActiveStepIndex, stepsList.length]);

  return (
    <ShipmentContext.Provider
      value={{
        steps,
        activeStepIndex,
        activeStep,
        setActiveStepIndex,
        stepsList,
        goToPreviousStep,
        goToNextStep,
      }}
    >
      {children}
    </ShipmentContext.Provider>
  );
};
