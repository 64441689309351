import BillingIcon from './assets/icons/billing.svg?react';
import EnvelopeIcon from './assets/icons/envelope.svg?react';
import GearIcon from './assets/icons/gear.svg?react';
import VerifiedIcon from './assets/icons/verified.svg?react';

import FolderIcon from './assets/icons/folder.svg?react';
import ScanIcon from './assets/icons/scan.svg?react';
import ShipmentIcon from './assets/icons/shipment.svg?react';

import TrashIcon from './assets/icons/trash.svg?react';

import {
  NavigationEntityKey,
  NavigationItem,
  NavigationItemType,
  SettingsNavigationEntityKey,
} from '@usgm/inbox-api-types';
import { inboxHelpers } from '@usgm/utils';
import { ReactNode } from 'react';
import MailsList from './features/mails/components/MailsList';
import { DEFAULT_MAIL_TAB, MAILS_ROUTES, createMailsPath } from './features/mails/paths';
import WelcomePage from './features/onboarding/pages/WelcomePage';
import ScansList from './features/scans/components/ScansList';
import { DEFAULT_SCAN_TAB, SCAN_ROUTES, createScansPath } from './features/scans/paths';
import ShipmentsList from './features/shipments/components/ShipmentsList';
import { DEFAULT_SHIPMENT_TAB, SHIPMENT_ROUTES, createShipmentsPath } from './features/shipments/paths';
import BillingHistoryPage from './pages/BillingHistoryPage';
import DiscardsPage from './pages/DiscardsPage';
import InboxPage from './pages/InboxPage';
import ScansPage from './pages/ScansPage';
import SettingsPage from './pages/SettingsPage';
import ShipmentsPage from './pages/ShipmentsPage';
import { BILLING_ROUTES, DEFAULT_BILLING_TAB, createBillingsPath } from './features/billingHistory/paths';
import BillingList from './features/billingHistory/components/BillingList';

import { SETTINGS_PATHS } from './features/settings/paths';

import AccountSettingsIcon from './features/settings/assets/accountSettingsIcon.svg?react';
import AddOnsIcon from './features/settings/assets/addOnsIcon.svg?react';
import AutomationsIcon from './features/settings/assets/automationIcon.svg?react';
import ExportsIcon from './features/settings/assets/exportIcon.svg?react';

type TSubItem = Omit<NavigationItem, 'key'> & {
  key: `${NavigationEntityKey}` | `${SettingsNavigationEntityKey}`;
  icon: ReactNode;
};

type TNavigationEntity = {
  icon: ReactNode;
  element: ReactNode;
  path?: string;
  subPaths?: { element: ReactNode; path: string }[];
  subItems?: Array<TSubItem>;
  defaultPath?: string;
  domId?: string;
};

export const NAVIGATION_ENTITY_MAP: Record<NavigationEntityKey, TNavigationEntity> = {
  [NavigationEntityKey.VerifyAccount]: {
    icon: <VerifiedIcon />,
    element: <WelcomePage />,
    path: inboxHelpers.APP_PATHS.VERIFY_ACCOUNT,
  },
  [NavigationEntityKey.Mailbox]: {
    icon: <EnvelopeIcon />,
    element: <InboxPage />,
    path: inboxHelpers.APP_PATHS.INBOX,
    defaultPath: createMailsPath({ tab: DEFAULT_MAIL_TAB }),
    subPaths: [
      {
        element: <MailsList />,
        path: MAILS_ROUTES.MAILS,
      },
      {
        element: <MailsList />,
        path: MAILS_ROUTES.FOLDER_MAILS,
      },
    ],
    domId: 'usetiful-mailbox',
  },
  [NavigationEntityKey.Scan]: {
    icon: <ScanIcon />,
    element: <ScansPage />,
    path: inboxHelpers.APP_PATHS.SCANS,
    defaultPath: createScansPath({ tab: DEFAULT_SCAN_TAB }),

    subPaths: [
      {
        element: <ScansList />,
        path: SCAN_ROUTES.SCANS,
      },
      {
        element: <ScansList />,
        path: SCAN_ROUTES.FOLDER_SCANS,
      },
    ],
    domId: 'usetiful-scans',
  },
  [NavigationEntityKey.Shipment]: {
    icon: <ShipmentIcon />,
    element: <ShipmentsPage />,
    path: inboxHelpers.APP_PATHS.SHIPMENTS,
    defaultPath: createShipmentsPath({ tab: DEFAULT_SHIPMENT_TAB }),
    subPaths: [
      {
        element: <ShipmentsList />,
        path: SHIPMENT_ROUTES.SHIPMENTS,
      },
    ],
    domId: 'usetiful-shipments',
  },
  [NavigationEntityKey.Billing]: {
    icon: <BillingIcon />,
    element: <BillingHistoryPage />,
    path: inboxHelpers.APP_PATHS.BILLING_HISTORY,
    defaultPath: createBillingsPath({ tab: DEFAULT_BILLING_TAB }),
    subPaths: [
      {
        element: <BillingList />,
        path: BILLING_ROUTES.BILLINGS,
      },
    ],
    domId: 'usetiful-billing-history',
  },
  [NavigationEntityKey.Discard]: {
    icon: <TrashIcon />,
    element: <DiscardsPage />,
    path: inboxHelpers.APP_PATHS.DISCARDS,
    subPaths: [
      {
        path: ':itemId?',
        element: <DiscardsPage />,
      },
    ],
    domId: 'usetiful-trash',
  },
  [NavigationEntityKey.Setting]: {
    icon: <GearIcon />,
    element: <SettingsPage />,
    path: inboxHelpers.APP_PATHS.SETTINGS,
    defaultPath: SETTINGS_PATHS.ACCOUNT_DETAILS,

    subItems: [
      {
        label: 'Account Settings',
        key: SettingsNavigationEntityKey.AccountSettings,
        path: SETTINGS_PATHS.ACCOUNT_SETTINGS_ROOT,
        type: NavigationItemType.SubPage,
        icon: <AccountSettingsIcon />,
        supportsRegularTeamMember: true,
      },
      {
        label: 'Add-ons',
        key: SettingsNavigationEntityKey.AddOns,
        path: SETTINGS_PATHS.ADD_ONS_ROOT,
        type: NavigationItemType.SubPage,
        icon: <AddOnsIcon />,
      },
      {
        label: 'Automations',
        key: SettingsNavigationEntityKey.Automations,
        path: SETTINGS_PATHS.AUTOMATIONS_ROOT,
        type: NavigationItemType.SubPage,
        icon: <AutomationsIcon />,
      },
      {
        label: 'Exports',
        key: SettingsNavigationEntityKey.Exports,
        path: SETTINGS_PATHS.EXPORTS,
        type: NavigationItemType.SubPage,
        icon: <ExportsIcon />,
      },
    ],
    domId: 'usetiful-settings',
  },
  [NavigationEntityKey.MailFolder]: {
    icon: <FolderIcon />,
    element: <div>Mail Folder</div>,
  },
  [NavigationEntityKey.ScanFolder]: {
    icon: <FolderIcon />,
    element: <div>Scans Folder</div>,
  },
};

export const DEFAULT_NAVIGATION = [
  NAVIGATION_ENTITY_MAP.mailbox,
  NAVIGATION_ENTITY_MAP.scan,
  NAVIGATION_ENTITY_MAP.shipment,
  NAVIGATION_ENTITY_MAP.billing,
  NAVIGATION_ENTITY_MAP.discard,
  NAVIGATION_ENTITY_MAP.setting,
];

export const DRAWER_WIDTH = 280;

export const RESEND_COUNTDOWN = 15; // 15 seconds
