import { IconButton, IconButtonProps, styled } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { forwardRef } from 'react';

const StyledButton = styled((props: ExpandButtonProps) => {
  const { expanded, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expanded }) => ({
  transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface ExpandButtonProps extends IconButtonProps {
  expanded: boolean;
}
export default forwardRef<HTMLButtonElement, ExpandButtonProps>(function ExpandButton(props, ref) {
  return (
    <StyledButton {...props} ref={ref} aria-expanded={props.expanded} aria-label="Show More">
      {props.children || <ExpandMoreIcon />}
    </StyledButton>
  );
});
