import { Outlet, useSearchParams } from 'react-router-dom';
import { decryptLoginAsUserDataSafe } from '../../../../helpers/decryptLoginAsUserDataSafe';
import LoginAsUserPage from '../../pages/LoginAsUserPage';

function LoginRoute() {
  const [searchParams] = useSearchParams();

  const isLoginAsUser = searchParams.get('isOTP') === 'true';
  const loginAsUserRawData = searchParams.get('data');

  if (isLoginAsUser) {
    const loginAsUserData = decryptLoginAsUserDataSafe<{ email: string; token: string }>(loginAsUserRawData);
    if (!loginAsUserData) {
      return <Outlet />;
    }
    return <LoginAsUserPage email={loginAsUserData.email} token={loginAsUserData.token} />;
  }
  return <Outlet />;
}

export default LoginRoute;
