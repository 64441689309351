import { Box, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { type BaseShipment, EntityStatusCategory } from '@usgm/inbox-api-types';
import { dataTableConfig, FlexBox, TextCell, ItemImageWithWarning } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import EntityStatus from '../../billingHistory/components/EntityStatus';
import { CategoryBadge } from '../components/CategoryBadge';
import { SHIPMENT_TYPES_OPTIONS } from '../components/ShipmentsCategoryFilter';

export const COMMON_SHIPMENTS_COLUMNS: GridColDef[] = [
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    ...dataTableConfig.DEFAULT_IMAGE_CELL_PARAMS,
    field: 'imageUrl',
    headerName: 'Item Image',
    renderCell: (params: GridRenderCellParams<BaseShipment>) => {
      const shipment = params.row;
      return (
        <ItemImageWithWarning
          imageUrl={shipment.mailImageUrl}
          showWarning={shipment.hasIssue}
          tooltipText={`${shipment.categoryName} rejected`}
        />
      );
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'id',
    headerName: 'Shipment ID',
    renderCell: (params: GridRenderCellParams<BaseShipment>) => {
      return (
        <TextCell>
          <FlexBox flexDirection="column" alignItems="flex-start">
            <Typography>{params.row.uuid}</Typography>
            <CategoryBadge
              category={params.row.categoryType}
              name={SHIPMENT_TYPES_OPTIONS.get(params.row.categoryType) || ''}
            />
          </FlexBox>
        </TextCell>
      );
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'serviceName',
    headerName: 'Service',
    renderCell: (params: GridRenderCellParams<BaseShipment>) => {
      return (
        <TextCell>
          <FlexBox flexDirection="column" alignItems="flex-start" gap={2}>
            <Typography>{params.row.serviceName}</Typography>
            {params.row.isAutoForward && (
              <Box minWidth={128}>
                <EntityStatus label="Auto Forwarding" statusCategory={EntityStatusCategory.Info} />
              </Box>
            )}
          </FlexBox>
        </TextCell>
      );
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    minWidth: 150,
    field: 'destination',
    headerName: 'Destination',
    valueGetter: (value: BaseShipment['destination']) => {
      if (!value) {
        return 'N/A';
      }
      return inboxHelpers.stringifyAddress({ addr: value });
    },
  },
] as const;
