import { ScanStatus } from '@usgm/inbox-api-types';
import { inboxHelpers, routeHelpers } from '@usgm/utils';

const ROOT_PATH = `${inboxHelpers.MAIN_PATHS.APP}/${inboxHelpers.APP_PATHS.SCANS}`;

export const SCAN_TABS = [
  {
    label: 'Pending',
    value: 'pending',
    filterKey: ScanStatus.InProcess,
    supportsTeamMembers: true,
    supportsRegularTeamMember: true,
  },
  {
    label: 'Completed',
    value: 'completed',
    filterKey: ScanStatus.Completed,
    supportsTeamMembers: true,
    supportsRegularTeamMember: true,
  },
] as const;

export const DEFAULT_SCAN_TAB = SCAN_TABS[0].value;

type ScanTab = (typeof SCAN_TABS)[number]['value'];

export const SCAN_ROUTES = {
  SCANS: `:tab/:itemId?`,
  FOLDER_SCANS: `folder/:folderId/:tab/:itemId?`,
};

export type ScansPathParams = Omit<routeHelpers.TabsPathParams<ScanTab>, 'params'>;

export const createScansPath = routeHelpers.createPathGenerator<ScanTab>(ROOT_PATH);
