import { styled } from '@mui/material';
import { ShipmentCategory } from '@usgm/inbox-api-types';
import { FlexBox } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';

import DepositIcon from '../../../assets/icons/shipments/depositCheck.svg?react';
import PickupIcon from '../../../assets/icons/shipments/pickup.svg?react';
import ReturnIcon from '../../../assets/icons/shipments/return.svg?react';
import ShipmentIcon from '../../../assets/icons/shipments/shipment.svg?react';

const CategoryName = styled(FlexBox)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 2 * theme.shape.borderRadius,
  gap: theme.spacing(0.5),
  marginTop: theme.spacing(1),
  fontWeight: theme.typography.fontWeightMedium,
  svg: {
    color: theme.palette.primary.main,
    width: 24,
    height: 24,
  },
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    marginTop: theme.spacing(2),
    fontSize: '0.75rem',
  },
}));

const CATEGORY_TO_ICON_MAP = {
  [ShipmentCategory.ShipmentRequest]: <ShipmentIcon />,
  [ShipmentCategory.CustomerPickupRequest]: <PickupIcon />,
  [ShipmentCategory.ReturnToSenderRequest]: <ReturnIcon />,
  [ShipmentCategory.CheckDepositRequest]: <DepositIcon />,
};

export function CategoryBadge({ category, name }: { category: ShipmentCategory; name: string }) {
  return (
    <CategoryName>
      {CATEGORY_TO_ICON_MAP[category]}
      {name}
    </CategoryName>
  );
}
