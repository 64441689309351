import {
  AccountRelation,
  AccountVerificationStepStatus,
  CaptchaParams,
  ResponseInfo,
  type DtoAccountNamesResult,
  type DtoAccountVerificationState,
  type DtoUpsertAccountNamesResponse,
  type DtoUserDocuments,
  type GetUserDocumentsParams,
  type UpsertAccountNamesParams,
} from '@usgm/inbox-api-types';

import { accountApi } from '../../../../api/accountApi';

export const ONBOARDING_TAG_TYPES = {
  ONBOARDING_STATE: 'OnboardingState',
  ACCOUNT_NAMES: 'AccountNames',
  ACCOUNT_DOCUMENTS: 'AccountDocuments',
} as const;

export const onboardingApi = accountApi
  .enhanceEndpoints({
    addTagTypes: [
      ONBOARDING_TAG_TYPES.ONBOARDING_STATE,
      ONBOARDING_TAG_TYPES.ACCOUNT_NAMES,
      ONBOARDING_TAG_TYPES.ACCOUNT_DOCUMENTS,
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAccountNamesWithStatus: builder.query<DtoAccountNamesResult, void | null>({
        query: () => ({
          url: '/account/users/all/status',
          method: 'get',
        }),
        providesTags: () => [ONBOARDING_TAG_TYPES.ACCOUNT_NAMES],
      }),
      getBusinessAccountNames: builder.query<DtoAccountNamesResult, void | null>({
        query: () => ({
          url: `/account/users/all/status?relation=${[
            AccountRelation.Company,
            AccountRelation.Trust,
            AccountRelation.Primary,
          ].join(',')}`,
          method: 'get',
        }),
        providesTags: () => [ONBOARDING_TAG_TYPES.ACCOUNT_NAMES],
      }),
      getPersonalAccountNames: builder.query<DtoAccountNamesResult, void | null>({
        query: () => ({
          url: `/account/users/all/status?relation=${[
            AccountRelation.Adult,
            AccountRelation.Deceased,
            AccountRelation.MinorChild,
          ].join(',')}`,
          method: 'get',
        }),
        providesTags: () => [ONBOARDING_TAG_TYPES.ACCOUNT_NAMES],
      }),
      updateAccountNames: builder.mutation<DtoUpsertAccountNamesResponse, UpsertAccountNamesParams>({
        query: (data) => ({
          url: '/user/accounts/name',
          data,
          method: 'post',
        }),
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          const patchAccountNamesStatus = dispatch(
            onboardingApi.util.updateQueryData('getOnboardingState', null, (draft) => {
              draft.steps.accountNames.status = AccountVerificationStepStatus.Completed;
            }),
          );
          try {
            await queryFulfilled;
          } catch {
            patchAccountNamesStatus.undo();
          }
        },
        invalidatesTags: [ONBOARDING_TAG_TYPES.ACCOUNT_NAMES],
      }),
      getOnboardingState: builder.query<DtoAccountVerificationState, void | null>({
        query: () => ({
          url: '/onboarding/state',
          method: 'get',
        }),
        providesTags: [ONBOARDING_TAG_TYPES.ONBOARDING_STATE],
      }),
      getUserDocuments: builder.query<DtoUserDocuments, GetUserDocumentsParams>({
        query: ({ captchaVersion, token, includeFileUrl }) => ({
          url: '/onboarding/user/documents',
          params: { token, cap_version: captchaVersion, include_file_url: includeFileUrl },
          method: 'get',
        }),
        providesTags: [ONBOARDING_TAG_TYPES.ACCOUNT_DOCUMENTS],
      }),

      deleteSecondaryAccountById: builder.mutation<ResponseInfo, { userId: string }>({
        query: ({ userId }) => ({
          url: `/onboarding/user/secondary/account/delete/${userId}`,
          method: 'delete',
        }),
        onQueryStarted: async ({ userId }, { dispatch, queryFulfilled }) => {
          const patchAccountNames = dispatch(
            onboardingApi.util.updateQueryData('getAccountNamesWithStatus', null, (draft) => {
              draft.data.accountNames = draft.data.accountNames.filter((account) => account.user.uuid !== userId);
            }),
          );

          try {
            await queryFulfilled;
          } catch {
            patchAccountNames.undo();
          }
        },
        invalidatesTags: [ONBOARDING_TAG_TYPES.ACCOUNT_DOCUMENTS],
      }),
      patchMailRedirectionStep: builder.mutation<void, { status: AccountVerificationStepStatus }>({
        query: ({ status }) => ({
          url: '/onboarding/step/mail-redirection',
          data: { status },
          method: 'patch',
        }),
        onQueryStarted: async ({ status }, { dispatch, queryFulfilled }) => {
          const patchRedirectionStep = dispatch(
            onboardingApi.util.updateQueryData('getOnboardingState', undefined, (draft) => {
              draft.steps.mailRedirection.status = status;
            }),
          );

          try {
            await queryFulfilled;
          } catch {
            patchRedirectionStep.undo();
          }
        },
        invalidatesTags: [ONBOARDING_TAG_TYPES.ONBOARDING_STATE],
      }),
      deleteDocumentById: builder.mutation<ResponseInfo, { documentId: string } & Partial<CaptchaParams>>({
        query: ({ documentId }) => ({
          url: `/onboarding/document/${documentId}`,
          method: 'delete',
        }),
        onQueryStarted: async ({ documentId, captchaVersion, token }, { dispatch, queryFulfilled }) => {
          if (token && captchaVersion) {
            const patchDocuments = dispatch(
              onboardingApi.util.updateQueryData(
                'getUserDocuments',
                { captchaVersion, token, includeFileUrl: true },
                (draft) => {
                  draft.documents = draft.documents.map((account) => {
                    account.documents = account.documents.filter((document) => document.id !== documentId);
                    return account;
                  });
                },
              ),
            );
            const patchVerificationState = dispatch(
              onboardingApi.util.updateQueryData('getOnboardingState', null, (draft) => {
                draft.steps.identifications.documentIds = draft.steps.identifications.documentIds.map((document) => {
                  document.files = document.files.filter((file) => file.id !== documentId);
                  return document;
                });
              }),
            );
            try {
              await queryFulfilled;
            } catch {
              patchDocuments.undo();
              patchVerificationState.undo();
            }
          }
        },
        invalidatesTags: [ONBOARDING_TAG_TYPES.ONBOARDING_STATE],
      }),
      scheduleOnlineNotarization: builder.mutation<ResponseInfo, { scheduledEventUrl: string }>({
        query: (data) => ({
          url: '/onboarding/form1583/notarize/online',
          method: 'post',
          data,
        }),
        invalidatesTags: [ONBOARDING_TAG_TYPES.ONBOARDING_STATE, ONBOARDING_TAG_TYPES.ACCOUNT_NAMES],
      }),
      getMeetLink: builder.query<{ meetLink: null | string }, void | null>({
        query: () => ({
          url: '/onboarding/form1583/notarize/online/meet-link',
          method: 'get',
        }),
      }),
      uploadOfflineNotarization: builder.mutation<ResponseInfo, { formFile: File; accountUUID: string }>({
        query: (data) => ({
          url: '/onboarding/form1583/notarize/offline',
          method: 'post',
          data,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }),
        onQueryStarted: async ({ accountUUID }, { dispatch, queryFulfilled }) => {
          const patchVerificationState = dispatch(
            onboardingApi.util.updateQueryData('getOnboardingState', null, (draft) => {
              let existingOfflineNotarization = false;
              draft.steps.notarization.accountNames.forEach((account) => {
                if (account.id === accountUUID) {
                  existingOfflineNotarization = true;
                  account.status = AccountVerificationStepStatus.InReview;
                }
              });
              if (!existingOfflineNotarization) {
                const account = draft.steps.accountNames.accountNames.find((account) => account.id === accountUUID);
                if (account) {
                  draft.steps.notarization.accountNames.push({
                    id: accountUUID,
                    name: account.name,
                    status: AccountVerificationStepStatus.InReview,
                    relation: account.relation,
                  });
                }
              }
            }),
          );
          try {
            await queryFulfilled;
          } catch {
            patchVerificationState.undo();
          }
        },
        invalidatesTags: [ONBOARDING_TAG_TYPES.ACCOUNT_NAMES],
      }),
      batchDeleteChildAccounts: builder.mutation<ResponseInfo, { userIds: string[] }>({
        query: ({ userIds }) => ({
          url: '/user/names/update',
          method: 'post',
          data: {
            delete_child_ids: userIds,
          },
        }),
        invalidatesTags: [ONBOARDING_TAG_TYPES.ACCOUNT_NAMES],
      }),
    }),
  });

export const {
  useGetBusinessAccountNamesQuery,
  useGetPersonalAccountNamesQuery,
  useGetAccountNamesWithStatusQuery,
  useUpdateAccountNamesMutation,
  useGetOnboardingStateQuery,
  usePatchMailRedirectionStepMutation,
  useGetUserDocumentsQuery,
  useDeleteSecondaryAccountByIdMutation,
  useDeleteDocumentByIdMutation,
  useScheduleOnlineNotarizationMutation,
  useGetMeetLinkQuery,
  useUploadOfflineNotarizationMutation,
  useBatchDeleteChildAccountsMutation,
} = onboardingApi;
