import { BaseScan, DtoMailV2, ScanStatus } from '@usgm/inbox-api-types';
import { cellHelpers } from '@usgm/shared-ui';
import { pascalCase } from '@usgm/utils';
import { useMemo } from 'react';
import DetailsTable from '../../../components/DetailsTable';

export type ScanDetailsProps = {
  scan: BaseScan;
  mail: DtoMailV2;
};
function ScanDetails({ scan, mail }: ScanDetailsProps) {
  const list = useMemo(
    () => [
      {
        title: 'Type',
        value: mail.mailTypeLabel,
      },
      {
        title: 'Request Type',
        value: pascalCase(scan.categoryType),
      },
      {
        title: 'Arrived',
        value: cellHelpers.formatDateString(mail.arrivalDate),
      },
      {
        title: 'Size',
        value: cellHelpers.formatMeasurement(mail.measurement),
      },
      {
        title: 'Weight',
        value: cellHelpers.formatWeight(mail.weight),
      },
      {
        title: 'Request Date',
        value: cellHelpers.formatDateString(
          scan.statusTransition.find((transition) => transition.status === ScanStatus.InProcess)?.updatedOn,
        ),
      },
      {
        title: 'Expedite',
        value: scan.isExpedite ? 'Yes' : 'No',
      },
      {
        title: 'User Instruction',
        value: scan.instructions || 'N/A',
      },
      {
        title: 'Notes',
        value: scan.rejectReason || 'N/A',
      },
    ],
    [
      mail.arrivalDate,
      mail.mailTypeLabel,
      mail.measurement,
      mail.weight,
      scan.categoryType,
      scan.instructions,
      scan.isExpedite,
      scan.rejectReason,
      scan.statusTransition,
    ],
  );
  return <DetailsTable items={list} />;
}

export default ScanDetails;
