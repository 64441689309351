import { inboxHelpers } from '@usgm/utils';
import { Route } from 'react-router-dom';
import RegularTeamMembersNotSupportedRoute from '../../../../auth/components/protectedRoutes/RegularTeamMembersNotSupportedRoute';
import TeamMembersNotSupportedRoute from '../../../../auth/components/protectedRoutes/TeamMembersNotSupportedRoute';
import { TabsLayout } from '../../../components/layout/TabsLayout';
import SettingsPage from '../../../pages/SettingsPage';
import AccountDetailsPage from '../pages/AccountSettings/AccountDetailsPage';
import AccountNamesPage from '../pages/AccountSettings/AccountNames/AccountNamesPage';
import { default as AccountNameLayout } from '../pages/AccountSettings/AccountNames/AddEditAccountNamePage/Layout';
import { default as AddEditNameStep } from '../pages/AccountSettings/AccountNames/AddEditAccountNamePage/steps/AddEditName';
import { default as GetNotarized } from '../pages/AccountSettings/AccountNames/AddEditAccountNamePage/steps/GetNotarized';
import { default as UploadIdsStep } from '../pages/AccountSettings/AccountNames/AddEditAccountNamePage/steps/UploadIds';
import AddAddressPage from '../pages/AccountSettings/AddressBook/AddAddressPage';
import AddressBookPage from '../pages/AccountSettings/AddressBook/AddressBookPage';
import EditAddressPage from '../pages/AccountSettings/AddressBook/EditAddressPage';
import PlanDetailsPage from '../pages/AccountSettings/PlanDetailsPage';
import ScanBundlesPage from '../pages/add-ons/ScanBundles/ScanBundlesPage';
import InviteTeamMembersPage from '../pages/add-ons/TeamMembers/InviteTeamMembersPage';
import TeamMembersPage from '../pages/add-ons/TeamMembers/TeamMembersPage';
import AutoScanPage from '../pages/automations/AutoScanPage';
import ShipmentSchedulingPage from '../pages/automations/ShipmentSchedulingPage';
import ExportsPage from '../pages/exports/ExportsPage';
import { SETTINGS_PATHS, SETTINGS_ROUTES } from '../paths';

// @TODO: Break down tabs configuration into smaller pieces

const ACCOUNT_SETTINGS_TABS = [
  {
    label: 'Account Details',
    path: SETTINGS_PATHS.ACCOUNT_DETAILS,
    isDefault: true,
    supportsTeamMembers: true,
    supportsRegularTeamMember: true,
  },
  {
    label: 'Account Names',
    path: SETTINGS_PATHS.ACCOUNT_NAMES,
  },
  {
    label: 'Address Book',
    path: SETTINGS_PATHS.ADDRESS_BOOK,
    supportsTeamMembers: true,
    supportsRegularTeamMember: true,
  },
  {
    label: 'Plan & Billing Details',
    path: SETTINGS_PATHS.PLAN_DETAILS,
  },
];

const ADD_ONS_TABS = [
  {
    label: 'Scan Bundles',
    path: SETTINGS_PATHS.SCAN_BUNDLES,
    isDefault: true,
    supportsTeamMembers: true,
  },
  {
    label: 'Team Members',
    path: SETTINGS_PATHS.TEAM_MEMBERS,
    supportsTeamMembers: true,
  },
];

const EXPORT_TABS = [
  {
    label: 'Exports',
    path: SETTINGS_PATHS.EXPORTS,
  },
];

const AUTOMATION_TABS = [
  {
    label: 'Auto Scan',
    path: SETTINGS_PATHS.AUTO_SCANS,
    isDefault: true,
    supportsTeamMembers: true,
  },
  {
    label: 'Shipment Scheduling',
    path: SETTINGS_PATHS.SHIPMENT_SCHEDULING,
    supportsTeamMembers: true,
  },
];

export function useSettingsRoutes() {
  return (
    <Route path={inboxHelpers.APP_PATHS.SETTINGS}>
      <Route index element={<SettingsPage />} />
      <Route
        path={SETTINGS_ROUTES.ACCOUNT_SETTINGS_ROUTE}
        element={<TabsLayout tabs={ACCOUNT_SETTINGS_TABS} autoScroll rootPath={SETTINGS_PATHS.ACCOUNT_SETTINGS_ROOT} />}
      >
        <Route element={<AccountNameLayout />}>
          <Route
            path={`${SETTINGS_ROUTES.ACCOUNT_NAMES_ROUTE}/${SETTINGS_ROUTES.ADD_ACCOUNT_NAME}`}
            element={<AddEditNameStep />}
          />
          <Route
            path={`${SETTINGS_ROUTES.ACCOUNT_NAMES_ROUTE}/${SETTINGS_ROUTES.EDIT_ACCOUNT_NAME}/:accountNameUuid`}
            element={<AddEditNameStep />}
          />
          <Route
            path={`${SETTINGS_ROUTES.ACCOUNT_NAMES_ROUTE}/${SETTINGS_ROUTES.EDIT_ACCOUNT_NAME_DOCUMENTS}/:accountNameUuid`}
            element={<UploadIdsStep />}
          />
          <Route
            path={`${SETTINGS_ROUTES.ACCOUNT_NAMES_ROUTE}/${SETTINGS_ROUTES.NOTARIZE_ACCOUNT_NAME}/:accountNameUuid`}
            element={<GetNotarized />}
          />
        </Route>
        <Route
          path={`${SETTINGS_ROUTES.ADDRESS_BOOK_ROUTE}/${SETTINGS_ROUTES.ADD_ADDRESS}`}
          element={<AddAddressPage />}
        />
        <Route
          path={`${SETTINGS_ROUTES.ADDRESS_BOOK_ROUTE}/${SETTINGS_ROUTES.EDIT_ADDRESS}/:addressUuid`}
          element={<EditAddressPage />}
        />
      </Route>
      <Route
        path={SETTINGS_ROUTES.ACCOUNT_SETTINGS_ROUTE}
        element={<TabsLayout tabs={ACCOUNT_SETTINGS_TABS} autoScroll rootPath={SETTINGS_PATHS.ACCOUNT_SETTINGS_ROOT} />}
      >
        <Route index path={SETTINGS_ROUTES.ACCOUNT_DETAILS_ROUTE} element={<AccountDetailsPage />} />

        <Route path={SETTINGS_ROUTES.ADDRESS_BOOK_ROUTE} element={<AddressBookPage />} />
        <Route element={<TeamMembersNotSupportedRoute />}>
          <Route path={SETTINGS_ROUTES.ACCOUNT_NAMES_ROUTE} element={<AccountNamesPage />} />
          <Route path={SETTINGS_ROUTES.PLAN_DETAILS_ROUTE} element={<PlanDetailsPage />} />
        </Route>
      </Route>
      <Route element={<RegularTeamMembersNotSupportedRoute />}>
        <Route
          path={SETTINGS_ROUTES.ADD_ONS}
          element={<TabsLayout tabs={ADD_ONS_TABS} autoScroll rootPath={SETTINGS_PATHS.ADD_ONS_ROOT} />}
        >
          <Route path={SETTINGS_ROUTES.SCAN_BUNDLES} element={<ScanBundlesPage />} />
          <Route
            path={`${SETTINGS_ROUTES.TEAM_MEMBERS}/${SETTINGS_ROUTES.INVITE_TEAM_MEMBERS}`}
            element={<InviteTeamMembersPage />}
          />
        </Route>
        <Route
          path={SETTINGS_ROUTES.ADD_ONS}
          element={<TabsLayout tabs={ADD_ONS_TABS} autoScroll rootPath={SETTINGS_PATHS.ADD_ONS_ROOT} />}
        >
          <Route path={SETTINGS_ROUTES.TEAM_MEMBERS} element={<TeamMembersPage />} />
        </Route>

        <Route element={<TabsLayout autoScroll tabs={EXPORT_TABS} />}>
          <Route path={SETTINGS_ROUTES.EXPORTS} element={<ExportsPage />} />
        </Route>

        <Route
          path={SETTINGS_ROUTES.AUTOMATIONS}
          element={<TabsLayout tabs={AUTOMATION_TABS} autoScroll rootPath={SETTINGS_PATHS.AUTOMATIONS_ROOT} />}
        >
          <Route path={SETTINGS_ROUTES.AUTO_SCAN} element={<AutoScanPage />} />
          <Route path={SETTINGS_ROUTES.SHIPMENT_SCHEDULING} element={<ShipmentSchedulingPage />} />
        </Route>
      </Route>
    </Route>
  );
}
