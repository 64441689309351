import { AxiosResponse } from 'axios';
import { camelizeKeys } from '@usgm/utils';

export const camelizeResponseInterceptor = (response: AxiosResponse) => {
  if (response.data && response.headers['content-type'] === 'application/json; charset=utf-8') {
    response.data = camelizeKeys(response.data);
  }

  return response;
};
