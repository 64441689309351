import { CaptchaType } from '@inbox/constants';
import React, { createContext, useCallback, ReactNode, useState, useMemo } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ENVIRONMENT } from '../env';
import { RecaptchaContextType, RecaptchaV2State, TCallback } from './config';

export const RecaptchaContext = createContext<RecaptchaContextType | undefined>(undefined);
const recaptchaV2IdMap: Map<string, RecaptchaV2State> = new Map();

export const RecaptchaProvider = ({ children }: { children: ReactNode }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [renderedRecaptchaV2Ids, setRenderedRecaptchaV2Ids] = useState('');
  const siteKeyV2 = ENVIRONMENT.RECAPTCHA_SITE_KEY_V2;

  const updateRenderedRecaptchaV2Ids = useCallback(() => {
    const renderedIds = Array.from(recaptchaV2IdMap.keys()).join();
    setRenderedRecaptchaV2Ids(renderedIds);
  }, []);

  const isReadyToUse = useMemo(() => !!executeRecaptcha, [executeRecaptcha]);

  const executeRecaptchaV3 = useCallback(
    async (action: CaptchaType) => {
      return new Promise<string | null>((resolve) => {
        if (!executeRecaptcha) {
          console.warn('executeRecaptcha not yet available');
          return resolve(null);
        }

        executeRecaptcha(action)
          .then((token: string | null) => resolve(token))
          .catch((error: Error) => {
            console.error('reCAPTCHA v3 execution failed', error);
            return resolve(null);
          });
      });
    },
    [executeRecaptcha],
  );

  const executeRecaptchaV2 = useCallback(
    ({ requestId, onComplete }: { requestId: string; onComplete: TCallback }) => {
      recaptchaV2IdMap.set(requestId, {
        isRendered: true,
        onSuccess: (token: string) => {
          onComplete(token);
          recaptchaV2IdMap.delete(requestId);
          updateRenderedRecaptchaV2Ids();
        },
      });
      updateRenderedRecaptchaV2Ids();
    },
    [updateRenderedRecaptchaV2Ids],
  );

  const getRecaptchaV2State = useCallback((requestId: string) => {
    return recaptchaV2IdMap.get(requestId);
  }, []);

  return (
    <RecaptchaContext.Provider
      value={{
        executeRecaptchaV3,
        executeRecaptchaV2,
        siteKeyV2,
        getRecaptchaV2State,
        renderedRecaptchaV2Ids,
        isReadyToUse,
      }}
    >
      {children}
    </RecaptchaContext.Provider>
  );
};
