import { useMemo } from 'react';
import { Button, List, ListItem, styled, Theme, useMediaQuery, useTheme } from '@mui/material';

import { ScrollableArea } from '@usgm/shared-ui';
import { type DtoWarehouse } from '@usgm/inbox-api-types';

import { useAppSelector } from '../../../../store/index';
import { selectFilteredWarehousesIds, warehousesSelectors } from '../../addressPickerSlice';

import { usePaginateWarehouses } from '../../hooks/usePaginateWarehouses';
import WarehouseInnerCard from '../WarehouseCard/WarehouseInnerCard';
import { useSetWarehouse } from '../../hooks/useSetWarehouse';
import { useAuth } from '../../../auth/hooks/useAuth';

const StyledList = styled(List)(({ theme }) => ({
  padding: '8px 8px 0px 0px',
  '& li': {
    padding: theme.spacing(2, 0),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& li::first-of-type': {
    paddingTop: 0,
  },
}));

const WarehouseItem = ({ id, handleSelection }: { id: DtoWarehouse['id']; handleSelection?: () => void }) => {
  const warehouse = useAppSelector((state) => warehousesSelectors.selectById(state, id));
  const onWarehouseSelect = useSetWarehouse(handleSelection);
  return warehouse ? (
    <ListItem>
      <WarehouseInnerCard {...warehouse} onSelect={onWarehouseSelect} />
    </ListItem>
  ) : null;
};

export default function ScrollableWarehouseList({
  handleSelection,
  changeWarehouseMode,
}: {
  handleSelection?: (step?: number) => void;
  changeWarehouseMode?: boolean;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const warehouseIds = useAppSelector(selectFilteredWarehousesIds);
  const { user } = useAuth();
  const warehouseId = user?.warehouseId;

  const { loadMore, canLoadMore } = usePaginateWarehouses();

  const containerStyles = useMemo(() => {
    if (isMobile) {
      return { height: 'auto' };
    } else {
      return { overflowY: 'auto', height: '100%' };
    }
  }, [isMobile]);
  return (
    <ScrollableArea thumbSize={9} thumbColor={theme.palette.grey[300]} sx={containerStyles}>
      <StyledList>
        {warehouseIds.map((id) => {
          const isCurrent = id === warehouseId && changeWarehouseMode;
          if (isCurrent) {
            return false;
          }
          return <WarehouseItem key={id} id={id} handleSelection={handleSelection} />;
        })}
      </StyledList>
      {canLoadMore && (
        <Button sx={{ mt: 3 }} variant="outlined" fullWidth onClick={loadMore}>
          Load More
        </Button>
      )}
    </ScrollableArea>
  );
}
