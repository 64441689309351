import { ShipmentStatus } from '@usgm/inbox-api-types';
import { inboxHelpers, routeHelpers } from '@usgm/utils';

const ROOT_PATH = `${inboxHelpers.MAIN_PATHS.APP}/${inboxHelpers.APP_PATHS.SHIPMENTS}`;

export const SHIPMENT_TABS = [
  {
    label: 'Pending',
    value: 'pending',
    filterKey: ShipmentStatus.Queue,
    supportsTeamMembers: true,
    supportsRegularTeamMember: true,
  },
  {
    label: 'Completed',
    value: 'completed',
    filterKey: ShipmentStatus.Processed,
    supportsTeamMembers: true,
    supportsRegularTeamMember: true,
  },
] as const;

type ShipmentTab = (typeof SHIPMENT_TABS)[number]['value'];

export const DEFAULT_SHIPMENT_TAB = SHIPMENT_TABS[0].value;

export const SHIPMENT_ROUTES = {
  SHIPMENTS: `:tab/:itemId?`,
};

export type ShipmentPathParams = Omit<routeHelpers.TabsPathParams<ShipmentTab>, 'params'>;

export const createShipmentsPath = routeHelpers.createPathGenerator<ShipmentTab>(ROOT_PATH);
