import { FnCreateTypography } from '../types';

export const createTypography: FnCreateTypography = (palette) => ({
  fontFamily: '"Fira Sans", sans-serif',
  h1: {
    color: palette?.text?.title,
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '3.5rem',
    lineHeight: 1.2,
    '@media (max-width:600px)': {
      fontSize: '2rem',
    },
  },
  h2: {
    color: palette?.text?.title,
    textAlign: 'center',
    fontSize: '3rem',
    lineHeight: 1.08,
    fontWeight: 600,
  },
  h3: {
    color: palette?.text?.title,
    textAlign: 'center',
    fontSize: '2.5rem',
    fontWeight: 600,
    lineHeight: 1.1,
  },
  h4: {
    color: palette?.text?.title,
    fontWeight: 600,
    fontSize: '2rem',
    lineHeight: 1.2,
  },
  h5: {
    fontSize: '1.25rem',
    color: palette?.text?.title,
    fontWeight: 600,
    lineHeight: 1.4,
  },
  subtitle1: {
    color: palette?.text?.title,
    fontSize: '2rem',
    lineHeight: 1,
  },
  subtitle2: {
    color: palette?.text?.title,
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.4,
  },
  body1: {
    lineHeight: 1.4,
  },
  button: {
    fontSize: '1rem',
    lineHeight: 1.4,
  },
  caption: {
    fontWeight: 400,
    lineHeight: 1.68,
    fontSize: '0.9rem',
  },
});
