import { AxiosRequestConfig, AxiosError } from 'axios';
import { createInstance } from './createInstance';
import { processSetMaintenanceMode, selectIsSessionExpired, selectIsUnderMaintenanceMode } from '../../app/appSlice';
import { BaseQueryApi } from '@reduxjs/toolkit/src/query/baseQueryTypes';

const mutationMethods = ['POST', 'PATCH', 'PUT'];
export const axiosBaseQuery =
  (BASE_URL: string) =>
  async (
    params: AxiosRequestConfig & { body?: Record<string, unknown> },
    { getState, dispatch }: { getState: BaseQueryApi['getState']; dispatch: BaseQueryApi['dispatch'] },
  ) => {
    if (params.method && mutationMethods.includes(params.method) && params.body) {
      params.data = params.body;
    }

    try {
      const axiosInstance = createInstance(BASE_URL);
      const isSessionExpired = selectIsSessionExpired(getState());
      if (isSessionExpired) {
        return { error: { message: 'Session expired' } };
      }
      const result = await axiosInstance(params);

      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError<{ message?: string }>;
      const isMaintenanceMode = selectIsUnderMaintenanceMode(getState());

      if (err.response?.status === 503) {
        dispatch(processSetMaintenanceMode(true));
      } else if (isMaintenanceMode) {
        dispatch(processSetMaintenanceMode(false));
      }

      return {
        error: {
          code: err.code,
          status: err.response?.status,
          data: err.response?.data || err.message,
          statusText: err.response?.statusText,
          message: err.response?.data?.message || err.message,
        },
      };
    }
  };
