import { ThemeOptions } from '@mui/material';
import { grey } from '@mui/material/colors';

export const lightPalette: ThemeOptions['palette'] = {
  mode: 'light',
  divider: '#DBE2EB',
  grey: {
    ...grey,
    '50': '#F9FAFB',
  },
  primary: {
    dark: '#2763CE',
    main: '#4386fc',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#FB662A',
    contrastText: '#ffffff',
  },
  success: {
    main: '#66D165',
    dark: '#01A249',
    light: '#E8F8E8',
  },
  error: {
    main: '#FF0000',
  },
  warning: {
    main: '#FB662A',
  },
  text: {
    primary: '#3D3D3D',
    secondary: '#4C4C4C',
    disabled: '#B7B7B7',
    badge: '#5A7184',
    title: '#21242C',
    neutral: '#0F3051',
  },
  background: {
    default: '#F9FBFE',
    base: '#F6F6F6',
    paper: '#ffffff',
    card: '#ffffff',
    cardHover: '#edf3fe',
    info: '#DEEAFF',
    warning: '#FFE8D4',
    success: '#DCFFDC',
    error: '#FFD9D9',
    tooltip: '#3D455A',
  },
} as const;
