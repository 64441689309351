import { Box, styled } from '@mui/material';
import { getCardContentStyles } from './helpers';

export const Card = styled(Box, { shouldForwardProp: (prop) => prop !== 'noSpacings' })<{ noSpacings?: boolean }>(
  ({ theme, noSpacings }) => ({
    ...(!noSpacings && getCardContentStyles(theme)),
    borderRadius: theme.shape.borderRadius * 2.5,
    backgroundColor: theme.palette.background.paper,
    maxWidth: '100vw',
  }),
);
