import { zodResolver } from '@hookform/resolvers/zod';
import { SHIPMENT_SCHEDULING_CONSOLIDATE_FEE } from '@inbox/constants';
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { AddressType } from '@usgm/inbox-api-types';
import { Button, Checkbox, FlexBox, MessageBox } from '@usgm/shared-ui';
import { inboxHelpers, mailsShipmentUtils } from '@usgm/utils';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, ControllerRenderProps, FormProvider, useForm } from 'react-hook-form';
import { useAuth } from '../../../../../auth/hooks/useAuth';
import ControlledInput from '../../../../components/ControlledInput';
import { useGetShippingMethodsQuery, useGetUserAddressesQuery } from '../../../../inboxAccountsApi';
import ShipmentScheduleFrequencyFields from './ShipmentScheduleFrequencyFields';
import { shipmentSchedulingFormSchema, ShipmentSchedulingFormSchemaType } from './shipmentSchedulingFormSchema';

const StyledMessageBoxContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(5),
}));

export type ShipmentSchedulingFormProps = {
  defaultValues?: Partial<ShipmentSchedulingFormSchemaType>;
  onSubmit: (data: ShipmentSchedulingFormSchemaType) => void;
};

function ShipmentSchedulingForm({ defaultValues, onSubmit }: ShipmentSchedulingFormProps) {
  const { user } = useAuth();
  const theme = useTheme();

  const { data: shippingAddresses } = useGetUserAddressesQuery({
    addressType: AddressType.Shipping,
    userUUID: user?.userUUID,
  });
  const { data: shippingMethods } = useGetShippingMethodsQuery(null);

  const formMethods = useForm<ShipmentSchedulingFormSchemaType>({
    resolver: zodResolver(shipmentSchedulingFormSchema),
    mode: 'onChange',
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    formState: { errors, isValid, isSubmitting },
  } = formMethods;

  const selectedAddressUuId = watch('scheduleAddressId');

  const isInternationalAddressSelected = useMemo(() => {
    const selectedAddress = shippingAddresses?.addresses?.find((it) => it.uuid === selectedAddressUuId);
    return mailsShipmentUtils.isInternationalAddress(selectedAddress);
  }, [selectedAddressUuId, shippingAddresses?.addresses]);

  const filteredShippingMethods = useMemo(() => {
    return shippingMethods?.data?.filter((it) => !!it.isInternational === isInternationalAddressSelected);
  }, [shippingMethods?.data, isInternationalAddressSelected]);

  const renderShippingMethodOptions = useCallback(
    (field: ControllerRenderProps<ShipmentSchedulingFormSchemaType>) => {
      return filteredShippingMethods?.map((it) => (
        <MenuItem selected={field.value === it.uuid} key={it.uuid} value={it.uuid}>
          {it.methodName}
        </MenuItem>
      ));
    },
    [filteredShippingMethods],
  );

  useEffect(() => {
    if (selectedAddressUuId && filteredShippingMethods?.some((it) => it.uuid === selectedAddressUuId)) {
      setValue('lettersService', '');
      setValue('smallPackagesService', '');
      setValue('largePackagesService', '');
    }
  }, [selectedAddressUuId, filteredShippingMethods, setValue]);

  const renderAddressOptions = useCallback(
    (field: ControllerRenderProps<ShipmentSchedulingFormSchemaType>) =>
      shippingAddresses?.addresses?.map((it) => (
        <MenuItem selected={field.value === it.uuid} key={it.uuid} value={it.uuid}>
          {inboxHelpers.stringifyAddress({ addr: it })} - {it.name}
        </MenuItem>
      )),
    [shippingAddresses?.addresses],
  );

  const isSubmitAllowed = useMemo(() => {
    return isValid && !isSubmitting;
  }, [isValid, isSubmitting]);

  return (
    <FormProvider {...formMethods}>
      {isInternationalAddressSelected && (
        <StyledMessageBoxContainer>
          <MessageBox
            severity="info"
            title="Please note"
            description="All items other than envelopes will require declarations before they may be shipped internationally."
          />
        </StyledMessageBoxContainer>
      )}
      <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <FlexBox flexDirection={'column'} gap={2}>
          <ShipmentScheduleFrequencyFields />
          <ControlledInput<ShipmentSchedulingFormSchemaType>
            name="scheduleAddressId"
            control={control}
            label="Shipment Address"
            errors={errors}
            renderOptions={renderAddressOptions}
          />
          <ControlledInput<ShipmentSchedulingFormSchemaType>
            name="lettersService"
            control={control}
            label="Letters"
            errors={errors}
            renderOptions={renderShippingMethodOptions}
          />
          <ControlledInput<ShipmentSchedulingFormSchemaType>
            name="smallPackagesService"
            control={control}
            label="Small Packages (Under 4lbs)"
            errors={errors}
            renderOptions={renderShippingMethodOptions}
          />
          <ControlledInput<ShipmentSchedulingFormSchemaType>
            name="largePackagesService"
            control={control}
            label="Large Packages (4lbs and up)"
            errors={errors}
            renderOptions={renderShippingMethodOptions}
          />
          <ControlledInput<ShipmentSchedulingFormSchemaType>
            name="scheduleDefaultPackingInstructions"
            control={control}
            label="Instructions"
            errors={errors}
          />
          <Controller
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={<Checkbox defaultChecked={!!field.value} {...register('scheduleOpenAndRepack')} />}
                  label={
                    <Typography>
                      I would like to have my shipments opened and consolidated for maximum savings{' '}
                      <Typography fontWeight={theme.typography.fontWeightBold} component={'span'}>
                        ({inboxHelpers.formatPrice(SHIPMENT_SCHEDULING_CONSOLIDATE_FEE)} will be applied)
                      </Typography>
                    </Typography>
                  }
                />
              </FormControl>
            )}
            name="scheduleOpenAndRepack"
            control={control}
          />
          <Box mt={2}>
            <Button type="submit" variant="contained" disabled={!isSubmitAllowed}>
              {isSubmitting ? <CircularProgress size={14} /> : 'Save'}
            </Button>
          </Box>
        </FlexBox>
      </Box>
    </FormProvider>
  );
}

export default ShipmentSchedulingForm;
