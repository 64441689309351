import { PopperProps, Theme, useMediaQuery } from '@mui/material';
import { Popper } from '@usgm/shared-ui';

export default function AutocompletePopper(props: PopperProps) {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const offset = isMobile ? [0, 8] : [0, 12];
  return (
    <Popper
      {...props}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset,
          },
        },
      ]}
      placement="bottom"
    />
  );
}
