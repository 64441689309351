import { AccountStatus, UserType } from '@usgm/inbox-api-types';
import { camelizeKeys } from '@usgm/utils';
import { useAppDispatch } from '../../../store';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { authSlice } from '../authSlice';
import { useEffect } from 'react';

const authParamsSchema = z.object({
  method: z.nativeEnum(UserType),
  success: z.coerce.boolean().optional(),
  accountStatus: z.nativeEnum(AccountStatus),
  token: z.string(),
  refreshToken: z.string(),
});

export function SocialAuthHandler() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [urlSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  // @TODO: this needs to be corrected at some point sending data as urlSearchParams is not the best way to handle this
  useEffect(() => {
    if (urlSearchParams.get('success') === 'true') {
      const searchParamData: Record<string, unknown> = {};
      for (const [key, value] of urlSearchParams) {
        searchParamData[key] = value;
      }
      try {
        const { method: userType, ...rest } = authParamsSchema.parse(camelizeKeys(searchParamData));

        dispatch(
          authSlice.actions.setAuth({
            ...rest,
            userType,
            accountVerificationState: null,
          }),
        );
      } catch (e) {
        // @TODO: Handle error
        console.error(e);
      }
      navigate({
        pathname: pathname,
        search: '',
      });
    }
  }, [dispatch, navigate, pathname, urlSearchParams]);

  return <Outlet />;
}
