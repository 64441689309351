import { LOGO_URL, PRODUCTION_URL } from '@inbox/constants';
import { APP_PATHS, MAIN_PATHS } from './paths';

export type GenericMetaInfo = {
  title: string;
  description?: string;
  imageUrl?: string;
  canonicalUrl?: string;
};

export type PathMetaInfoMapItem = GenericMetaInfo & {
  prefixMatch?: boolean;
};

function generateMetaTagInfo({
  useDefaultImage,
  path,
}: {
  useDefaultImage?: boolean;
  path?: string;
}): Partial<GenericMetaInfo> {
  return {
    ...(useDefaultImage ? { imageUrl: LOGO_URL } : {}),
    ...(path ? { canonicalUrl: `${PRODUCTION_URL}${path}` } : {}),
  };
}

export const PathMetaInfoMap: { [key: string]: PathMetaInfoMapItem } = {
  [MAIN_PATHS.SELECT_VIRTUAL_ADDRESS]: {
    ...generateMetaTagInfo({ useDefaultImage: true, path: MAIN_PATHS.SELECT_VIRTUAL_ADDRESS }),
    title: 'Pick an Address',
    description:
      'Choose from a wide range of US warehouse addresses with US Global Mail and start virtualizing your mailbox today.',
  },
  [MAIN_PATHS.SELECT_PLAN]: {
    ...generateMetaTagInfo({ useDefaultImage: true, path: MAIN_PATHS.SELECT_PLAN }),
    title: 'Select Plan',
    description:
      'Choose the perfect US Global Mail plan that fits your needs. Enjoy flexible options for virtual mailbox services, mail forwarding, and package management. Start today!',
    prefixMatch: true,
  },
  [MAIN_PATHS.LOGIN]: {
    ...generateMetaTagInfo({ useDefaultImage: true, path: MAIN_PATHS.LOGIN }),
    title: 'Login',
    description:
      'Securely access your US Global Mail account to manage your virtual mailbox, view received mail, and handle package forwarding services with ease. Log in now.',
  },
  [MAIN_PATHS.FORGOT_PASSWORD]: {
    ...generateMetaTagInfo({ useDefaultImage: true, path: MAIN_PATHS.FORGOT_PASSWORD }),
    title: 'Forgot Password',
    description:
      'Recover access to your US Global Mail account quickly and securely. Follow the steps to reset your password and regain control of your virtual mailbox.',
  },
  [MAIN_PATHS.CREATE_ACCOUNT]: { title: 'Create Account' },
  [MAIN_PATHS.ACCEPT_TEAM_MEMBER_INVITATION]: { title: 'Accept Invitation' },
  [MAIN_PATHS.CHECKOUT]: { title: 'Checkout' },
  [MAIN_PATHS.MAINTENANCE]: { title: 'Maintenance' },
  [MAIN_PATHS.UNSUBSCRIBE]: { title: 'Unsubscribe' },
  [MAIN_PATHS.WELCOME]: { title: 'Welcome' },
  [MAIN_PATHS.RESET_PASSWORD]: { title: 'Reset Password' },
  [MAIN_PATHS.VERIFY_RESET_PASSWORD_TOKEN]: { title: 'Verify Token' },
  [`${MAIN_PATHS.APP}/${APP_PATHS.VERIFY_ACCOUNT}`]: { title: 'Verify Account', prefixMatch: true },
  [`${MAIN_PATHS.APP}/${APP_PATHS.INBOX}`]: { title: 'Inbox', prefixMatch: true },
  [`${MAIN_PATHS.APP}/${APP_PATHS.SCANS}`]: { title: 'Scans', prefixMatch: true },
  [`${MAIN_PATHS.APP}/${APP_PATHS.SHIPMENTS}`]: { title: 'Shipments', prefixMatch: true },
  [`${MAIN_PATHS.APP}/${APP_PATHS.BILLING_HISTORY}`]: { title: 'Billing History', prefixMatch: true },
  [`${MAIN_PATHS.APP}/${APP_PATHS.DISCARDS}`]: { title: 'Discards' },
  [`${MAIN_PATHS.APP}/${APP_PATHS.SETTINGS}`]: { title: 'Settings', prefixMatch: true },
};
