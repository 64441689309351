import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Box } from '@mui/material';

import { PASSWORD_REQUIREMENTS } from '@inbox/constants';
import { PasswordInput, PasswordStrengthIndicator, SimpleDialog, Toast } from '@usgm/shared-ui';
import { useUpdateUserDataMutation } from '../api';

const schema = z
  .object({
    oldPassword: z.string().min(1, 'Old Password is required.'),
    newPassword: z
      .string()
      .min(4, 'New Password is required.')
      .refine(
        (value) => {
          return PASSWORD_REQUIREMENTS.RULES.every((rule) => rule.test(value));
        },
        {
          message: 'Invalid Password',
        },
      ),
    newPasswordConfirmation: z
      .string()
      .min(4, 'Confirmation Password is required.')
      .refine(
        (value) => {
          return PASSWORD_REQUIREMENTS.RULES.every((rule) => rule.test(value));
        },
        {
          message: 'Invalid Password',
        },
      ),
  })
  .refine((data) => data.newPassword === data.newPasswordConfirmation, {
    message: 'Passwords must match',
    path: ['newPasswordConfirmation'],
  });

type PasswordUpdateFormType = z.infer<typeof schema>;

export function PasswordUpdateDialog({
  userId,
  fullName,
  email,
  open,
  closeHandler,
}: {
  open: boolean;
  closeHandler: () => void;
  userId?: string;
  fullName?: string;
  email?: string;
}) {
  const [submitUpdateUserDataMutation, { isLoading, error }] = useUpdateUserDataMutation();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<PasswordUpdateFormType>({
    resolver: zodResolver(schema),
    mode: 'onChange',
  });

  const password = watch('newPassword');

  const onFormSubmit = async (data: PasswordUpdateFormType) => {
    const results = await submitUpdateUserDataMutation({ ...data, name: fullName, uuid: userId, email });
    const hasError = 'error' in results;

    if (!hasError) {
      closeHandler();
    }
  };

  const isSubmitDisabled = !isValid || isLoading;
  return (
    <SimpleDialog
      title="Update Password"
      open={open}
      closeHandler={closeHandler}
      primaryButtonText="Save"
      isSubmitDisabled={isSubmitDisabled}
      isForm={true}
      onSubmit={handleSubmit(onFormSubmit)}
      isSubmitting={isLoading}
    >
      <Box sx={{ mt: 1 }}>
        <PasswordInput
          {...register('oldPassword')}
          sx={{ mb: 1 }}
          disabled={isLoading}
          error={!!errors.oldPassword}
          helperText={errors.oldPassword?.message}
          InputLabelProps={{ shrink: true }}
          label="Old Password"
          placeholder="Enter your old password"
          fullWidth
        />
        <PasswordInput
          {...register('newPassword')}
          sx={{ mb: 1 }}
          disabled={isLoading}
          error={!!errors.newPassword}
          helperText={errors.newPassword?.message}
          InputLabelProps={{ shrink: true }}
          label="New Password"
          placeholder="Enter your new password"
          fullWidth
        />
        <PasswordInput
          {...register('newPasswordConfirmation')}
          disabled={isLoading}
          error={!!errors.newPasswordConfirmation}
          helperText={errors.newPasswordConfirmation?.message}
          InputLabelProps={{ shrink: true }}
          label="Confirm New Password"
          placeholder="Enter your new password"
          fullWidth
        />
        <PasswordStrengthIndicator
          value={password}
          labels={PASSWORD_REQUIREMENTS.LABELS}
          tooltipText={PASSWORD_REQUIREMENTS.MESSAGE}
          minLength={PASSWORD_REQUIREMENTS.MIN_LENGTH}
          passwordRules={PASSWORD_REQUIREMENTS.RULES}
        />
      </Box>
      {error && <Toast title={error.message} severity="error" />}
    </SimpleDialog>
  );
}

export default React.memo(PasswordUpdateDialog);
