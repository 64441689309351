import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './axios/baseQuery';
import { ENVIRONMENT } from '../env';
import { Announcement, DtoAnnouncements } from '@usgm/inbox-api-types';

const TAG_TYPES = {
  ANNOUNCEMENTS: 'Announcements',
} as const;

const DEFAULT_PAGINATION_PARAMS = { page: 0, pageSize: 20 };

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: axiosBaseQuery(ENVIRONMENT.NOTIFICATION_API_URL),
  tagTypes: [TAG_TYPES.ANNOUNCEMENTS],
  endpoints: (builder) => ({
    getAnnouncements: builder.query<DtoAnnouncements, null>({
      query: () => ({
        url: `/announcements`,
        method: 'get',
        params: DEFAULT_PAGINATION_PARAMS,
      }),
      providesTags: () => [TAG_TYPES.ANNOUNCEMENTS],
    }),
    markAnnouncementAsRead: builder.mutation<null, { uuid: Announcement['id'] }>({
      query: ({ uuid }) => ({
        url: `/user/read/announcement/${uuid}`,
        method: 'put',
      }),
      onQueryStarted: async ({ uuid }, { dispatch, queryFulfilled }) => {
        const patchList = dispatch(
          notificationsApi.util.updateQueryData('getAnnouncements', null, (draft) => {
            const announcement = draft.announcements?.find((it) => it.id === uuid);
            if (!announcement) return;
            announcement.isRead = true;
            draft.unreadCount = Math.max(0, draft.unreadCount - 1);
          }),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchList.undo();
        }
      },
    }),
  }),
});

export const { useGetAnnouncementsQuery, useMarkAnnouncementAsReadMutation } = notificationsApi;
