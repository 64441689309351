import { Theme, ThemeOptions } from '@mui/material';

export const createCustomPalette: (theme: Theme) => ThemeOptions['palette'] = (theme: Theme) => ({
  yellow: theme.palette.augmentColor({
    color: {
      main: '#F9D71C',
      contrastText: theme.palette.getContrastText('#F9D71C'),
    },
    name: 'yellow',
  }),
});
