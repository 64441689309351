export const ADDRESSES_ACTIONS = {
  edit: {
    label: 'Edit',
    color: undefined,
  },
  delete: {
    label: 'Delete',
    color: 'dangerous',
  },
} as const;

export type TAddressesAction = keyof typeof ADDRESSES_ACTIONS;
