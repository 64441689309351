import { List, ListProps } from '@mui/material';
import NavItemProps from '../../../types/TPublicNavItemProps';
import NavMenuItem from './NavMenuItem';
import styled from '@emotion/styled';

const StyledList = styled(List)(() => ({
  display: 'inline-flex',
  padding: 0,
  position: 'initial',
})) as typeof List;

type NavMenuProps = Omit<ListProps, 'component'> & { items: NavItemProps[] };

export default function NavMenu({ items, ...props }: NavMenuProps) {
  return (
    <nav>
      <StyledList {...props}>
        {items.map((item, index) => (
          <NavMenuItem expandOnHover {...item} key={index} />
        ))}
      </StyledList>
    </nav>
  );
}
