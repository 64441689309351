export const ENVIRONMENT = {
  WEBSITE_URL: import.meta.env.VITE_WEBSITE_URL,
  ACCOUNT_API_URL: import.meta.env.VITE_ACCOUNT_API_URL,
  RECAPTCHA_SITE_KEY_V2: import.meta.env.VITE_RECAPTCHA_SITE_KEY_V2,
  RECAPTCHA_SITE_KEY_V3: import.meta.env.VITE_RECAPTCHA_SITE_KEY_V3,
  PAYMENT_API_URL: import.meta.env.VITE_PAYMENT_API_URL,
  NOTIFICATION_API_URL: import.meta.env.VITE_NOTIFICATION_API_URL,
  GOOGLE_MAPS_API_KEY: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  STRIPE_KEY: import.meta.env.VITE_STRIPE_KEY,
  CHARGE_BEE_SITE: import.meta.env.VITE_CHARGE_BEE_SITE,
  CALENDLY_URL: import.meta.env.VITE_CALENDLY_URL,
  AES_KEY: import.meta.env.VITE_AES_KEY,
  MOCK_API_URL: import.meta.env.VITE_MOCK_API_URL,
  MAILS_API_URL: import.meta.env.VITE_MAILS_API_URL,
  LOGIN_AS_USER_ENCRYPTION_KEY: import.meta.env.VITE_LOGIN_AS_USER_ENCRYPTION_KEY,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  ENV: import.meta.env.VITE_ENV,
  STABLE_INBOX_URL: import.meta.env.VITE_STABLE_INBOX_URL,
  POSTMEN_API_URL: import.meta.env.VITE_POSTMEN_API_URL,
  GEOLOCATION_API_URL: import.meta.env.VITE_GEOLOCATION_API_URL,
};
