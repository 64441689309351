import { FormControlLabel, Typography, useTheme } from '@mui/material';

import { Checkbox } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import { SIGNATURE_COST } from '@inbox/constants';

import { StyledFormControl } from './styled/StyledFormControl';
import { mailsShipmentSlice, selectSignatureRequired } from '../../mailsShipmentSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../../../../store';

function SignatureRequiredControl() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const signatureRequired = useAppSelector(selectSignatureRequired);

  const handleChange = () => {
    dispatch(mailsShipmentSlice.actions.setSignatureRequired(!signatureRequired));
  };

  return (
    <StyledFormControl>
      <FormControlLabel
        value={signatureRequired}
        onChange={handleChange}
        defaultChecked={signatureRequired}
        control={<Checkbox />}
        label={
          <>
            <Typography mr={1} fontWeight={theme.typography.fontWeightBold} component="span">
              Require a signature
            </Typography>
            <Typography color={theme.customColors.dark[300]} component="span">
              ({inboxHelpers.formatPrice(SIGNATURE_COST)} add on)
            </Typography>
          </>
        }
      />
    </StyledFormControl>
  );
}

export default SignatureRequiredControl;
