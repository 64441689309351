import {
  DtoWarehousesResults,
  GetWarehousesParams,
  DtoUpdateWarehouse,
  UpdateWarehouseParams,
} from '@usgm/inbox-api-types';
import { accountApi } from '../../api/accountApi';

export const addressPickerApi = accountApi.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    getWarehouses: builder.query<DtoWarehousesResults, GetWarehousesParams | void>({
      query: (params) => {
        const term = params?.term || '';
        return {
          url: '/warehouse/by/address',
          params: { term },
          method: 'get',
        };
      },
    }),
    updateWarehouse: builder.mutation<DtoUpdateWarehouse, UpdateWarehouseParams>({
      query: ({ userId, planId, termId, warehouseId }) => ({
        url: '/customer/warehouse/switch',
        method: 'post',
        data: { userId, planId, termId, warehouseId },
      }),
    }),
  }),
});

export const { useGetWarehousesQuery, useUpdateWarehouseMutation } = addressPickerApi;
