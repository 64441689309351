import { DtoPlan } from '@usgm/inbox-api-types';

export const calculateSavingInMonths = (memberships?: DtoPlan['membership']) => {
  if (!memberships) {
    return 0;
  }
  const monthlyPrice = memberships.items.find((item) => item.months === 1)?.price;
  const annualPrice = memberships.items.find((item) => item.months === 12)?.price;

  return monthlyPrice && annualPrice ? Math.max(0, Math.round(((monthlyPrice - annualPrice) / monthlyPrice) * 12)) : 0;
};
