import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

import { IconButton, styled } from '@mui/material';
import { BaseMail, SCAN_CATEGORY_NAMES, ScanCategory, ScanStatus } from '@usgm/inbox-api-types';
import { Tooltip } from '@usgm/shared-ui';

import { MouseEvent } from 'react';
import { useViewScanRequest } from '../../scans/hooks/useViewScanRequest';

const StyledIconButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'isCompleted' })<{
  isCompleted: boolean;
}>(({ theme, isCompleted }) => ({
  color: isCompleted ? theme.palette.primary.main : theme.palette.text.disabled,
  cursor: isCompleted ? 'pointer' : 'not-allowed',
}));

const STATUS_TOOLTIPS_MAP: { [key in ScanStatus]?: (categoryType: ScanCategory) => string } = {
  [ScanStatus.InProcess]: (categoryType: ScanCategory) => `${SCAN_CATEGORY_NAMES[categoryType]} in process`,
  [ScanStatus.Deleted]: () => 'File is deleted',
  [ScanStatus.Completed]: (categoryType: ScanCategory) =>
    `View ${categoryType === ScanCategory.ScanRequest ? 'scan' : 'item'}`,
};

export type ScanInfoIndicatorProps = Required<Pick<BaseMail, 'scanInfo'>>;
function ScanInfoIndicator({ scanInfo }: ScanInfoIndicatorProps) {
  const { dialog, openDialog } = useViewScanRequest({ id: scanInfo.uuid, categoryType: scanInfo.categoryType });
  const isCompleted = scanInfo.status === ScanStatus.Completed;

  const tooltipText = STATUS_TOOLTIPS_MAP[scanInfo.status]?.(scanInfo.categoryType);

  const handleViewScan = (ev: MouseEvent) => {
    if (!isCompleted) {
      return;
    }
    ev.stopPropagation();
    openDialog();
  };

  if (!tooltipText) {
    return null;
  }

  return (
    <>
      <Tooltip arrow placement="bottom" title={tooltipText}>
        <StyledIconButton isCompleted={isCompleted} onClick={handleViewScan} color="primary">
          {scanInfo.categoryType === ScanCategory.ScanRequest ? <DocumentScannerIcon /> : <InsertPhotoIcon />}
        </StyledIconButton>
      </Tooltip>
      {dialog}
    </>
  );
}

export default ScanInfoIndicator;
