import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { BaseScan } from '@usgm/inbox-api-types';
import { dataTableConfig, ItemImageWithWarning, TextCell } from '@usgm/shared-ui';

import ActionsCell from '../tableCells/ActionsCell';
import UserInstructionCell from '../tableCells/UserInstructionCell';

export const PENDING_SCANS_COLUMNS: GridColDef[] = [
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    ...dataTableConfig.DEFAULT_IMAGE_CELL_PARAMS,
    field: 'imageUrl',
    headerName: 'Item Image',
    renderCell: (params: GridRenderCellParams<BaseScan>) => {
      const scan = params.row;
      return (
        <ItemImageWithWarning
          imageUrl={scan.mailImageUrl}
          showWarning={!!scan.rejectReason}
          tooltipText={`Request rejected: ${scan.rejectReason}`}
        />
      );
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'item',
    headerName: 'Item #',
    maxWidth: 200,
    renderCell: (params: GridRenderCellParams<BaseScan>) => {
      return <TextCell>{params.row.mailId}</TextCell>;
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'isExpedite',
    headerName: 'Expedite',
    maxWidth: 150,
    valueGetter: (value: boolean | null) => {
      return value ? 'Yes' : 'No';
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'instructions',
    headerName: 'User Instruction / Notes',
    renderCell: (params: GridRenderCellParams<BaseScan>) => {
      return <UserInstructionCell data={params.row} />;
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'createdAt',
    headerName: 'Request Date',
    minWidth: 380,
    renderCell: (params: GridRenderCellParams<BaseScan>) => {
      return <ActionsCell data={params.row} />;
    },
  },
] as const;
