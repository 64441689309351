import { Box } from '@mui/material';
import { DtoExportItem, EntityStatusCategory } from '@usgm/inbox-api-types';
import { Button } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import EntityStatus from '../../billingHistory/components/EntityStatus';

type ExportActionCellProps = {
  row: DtoExportItem;
};

function ExportActionCell({ row }: ExportActionCellProps) {
  const statusLabel = row.expiresOn?.expired ? 'Expired' : row.statusLabel;
  const statusCategory = row.expiresOn?.expired ? EntityStatusCategory.Neutral : row.statusCategory;
  const availableToDownload = row.zipUrl && !row.expiresOn?.expired;

  const handleDownload = () => {
    if (row.zipUrl) {
      inboxHelpers.downloadFile({ url: row.zipUrl, fileName: 'scans_export.zip', opeInNewTab: true });
    }
  };

  return (
    <Box>
      {availableToDownload ? (
        <Button onClick={handleDownload} variant="contained" size={'small'}>
          Download
        </Button>
      ) : (
        <EntityStatus label={statusLabel} statusCategory={statusCategory} />
      )}
    </Box>
  );
}

export default ExportActionCell;
