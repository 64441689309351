import { styled } from '@mui/material';
import { ArrowBadge } from '../../ArrowBadge';

const StyledArrowBadge = styled(ArrowBadge)(() => ({
  padding: `0.5625rem 1rem 0.5rem  1.75rem`,
  position: 'absolute',
  top: 0,
  right: 0,
}));

export default StyledArrowBadge;
