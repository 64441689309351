import { Box, IconButton, Theme, styled, useMediaQuery } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';

import { Container, FlexBox } from '@usgm/shared-ui';
import { useAuth } from '../../../../../features/auth/hooks/useAuth';
import { ThemeSwitcher } from '../../../../../features/preferences/components/ThemeSwitcher';
import Logo from '../../../../Logo';
import TPublicNavigationItem from '../../types/TPublicNavigationItem';
import HeaderAccountInfo from '../HeaderAccountInfo';
import NavMenu from './NavMenu';
import HeaderWrapper from './styled/HeaderWrapper';

const HeaderContainer = styled(Container)(({ theme }) => ({
  maxWidth: 1720,
  minHeight: 77,
  display: 'flex',
  alignItems: 'center',
}));

type HeaderProps = {
  isDrawerOpen?: boolean;
  disableHeaderItems?: boolean;
  openDrawer?: () => void;
  menuItems: TPublicNavigationItem[];
};

export function Header({ isDrawerOpen, openDrawer, menuItems, disableHeaderItems }: HeaderProps) {
  const { user } = useAuth();
  const isMenuHidden = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const onMenuIconClick = () => openDrawer?.();
  const showThemeSwitcherLabel = useMediaQuery('(min-width: 1280px)');
  return (
    <HeaderWrapper>
      <HeaderContainer>
        <FlexBox position="relative" alignSelf="stretch" width="100%">
          {isMenuHidden && !disableHeaderItems && (
            <Box>
              <IconButton
                aria-label="Open Main Menu"
                edge="end"
                onClick={onMenuIconClick}
                sx={{ ...(isDrawerOpen && { display: 'none' }) }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          )}

          <Logo />

          {!disableHeaderItems && (
            <>
              {!isMenuHidden && <NavMenu items={menuItems} />}
              <FlexBox alignSelf="stretch">
                {!isMenuHidden ? (
                  <>
                    <Box mr={1}>
                      <ThemeSwitcher showLabel={showThemeSwitcherLabel} variant="raw" />
                    </Box>
                    <HeaderAccountInfo hideSignup />
                  </>
                ) : (
                  user && <HeaderAccountInfo hideUserDetails />
                )}
              </FlexBox>
            </>
          )}
        </FlexBox>
      </HeaderContainer>
    </HeaderWrapper>
  );
}

export default Header;
