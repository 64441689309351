import { GridCellParams, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { BaseCreditNote, DtoCredit } from '@usgm/inbox-api-types';
import { TextCell, cellHelpers, dataTableConfig } from '@usgm/shared-ui';
import { inboxHelpers, pascalCase } from '@usgm/utils';
import { SingleBillingItemLink } from './invoices';

import DownloadBillingPdf from '../components/DownloadBillingPdf';
import EntityStatus from '../components/EntityStatus';
import CreditNoteInfoCell from '../components/gridCells/CreditNoteInfoCell';

export const CREDITS_COLUMNS: GridColDef[] = [
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'vendorId',
    headerName: 'Credit Number',
    renderCell: (params) => {
      return (
        <TextCell>
          <SingleBillingItemLink label={`#${params.row.vendorId}`} id={params.row.vendorId} />
        </TextCell>
      );
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'status',
    headerName: 'Status',
    renderCell: (params: GridRenderCellParams<BaseCreditNote>) => {
      return <EntityStatus label={params.row.statusLabel} statusCategory={params.row.statusCategory} />;
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'reason',
    headerName: 'Reason',
    valueGetter: (value: DtoCredit['reason']) => {
      return pascalCase(value);
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'amountTotal',
    headerName: 'Amount',
    valueGetter: inboxHelpers.formatPrice,
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'date',
    headerName: 'Issued On',
    valueGetter: cellHelpers.formatDateString,
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'actions',
    minWidth: 200,
    headerName: '',
    renderCell: (params: GridCellParams<BaseCreditNote>) => {
      const creditNote = params.row;
      return (
        <TextCell>
          <DownloadBillingPdf itemId={creditNote.vendorId} invoiceId={creditNote.invoiceId} type="credit" />
        </TextCell>
      );
    },
  },
];

export const CREDITS_MOBILE_COLUMNS: GridColDef[] = [
  {
    field: 'creditInfo',
    headerName: 'info',
    flex: 1,
    renderCell: (params: GridRenderCellParams<BaseCreditNote>) => {
      return <CreditNoteInfoCell data={params.row} />;
    },
  },
];
