import { styled, Theme, useTheme } from '@mui/material';

import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import DoneIcon from '@mui/icons-material/Done';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { EntityStatusCategory } from '@usgm/inbox-api-types';
import { LabelBadge } from '@usgm/shared-ui';

const STATUS_COLORS_MAP = {
  [EntityStatusCategory.Success]: (theme: Theme) => ({
    backgroundColor: theme.palette.background.success,
    color: theme.palette.success.main,
    icon: <DoneIcon />,
  }),
  [EntityStatusCategory.Info]: (theme: Theme) => ({
    backgroundColor: theme.palette.background.info,
    color: theme.palette.primary.main,
    icon: <RotateLeftIcon />,
  }),
  [EntityStatusCategory.Neutral]: (theme: Theme) => ({
    backgroundColor: theme.palette.divider,
    color: theme.palette.text.neutral,
    icon: <CancelOutlinedIcon />,
  }),
  [EntityStatusCategory.Error]: (theme: Theme) => ({
    backgroundColor: theme.palette.background.error,
    color: theme.palette.error.dark,
    icon: <ErrorOutlineOutlinedIcon />,
  }),
};

const StyledLabelBadge = styled(LabelBadge)(({ theme }) => ({
  maxHeight: theme.spacing(4),
}));

export type EntityStatusProps = {
  label: string;
  statusCategory: EntityStatusCategory;
  hideIcon?: boolean;
};
function EntityStatus({ label, statusCategory, hideIcon }: EntityStatusProps) {
  const theme = useTheme();
  const data = STATUS_COLORS_MAP[statusCategory]?.(theme);
  const borderRadius = `${theme.shape.borderRadius * 2}px`;
  if (data) {
    const { backgroundColor, color, icon } = data;
    return (
      <StyledLabelBadge
        iconSize="0.75em"
        label={label}
        borderRadius={borderRadius}
        iconPosition="left"
        color={color}
        backgroundColor={backgroundColor}
        icon={hideIcon ? undefined : icon}
      />
    );
  }
  return <StyledLabelBadge label={label} />;
}

export default EntityStatus;
