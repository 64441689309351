import { DtoNavigation, NavigationEntityKey, NavigationItemType } from '@usgm/inbox-api-types';

export function updateNavigationUnreadCounts({
  count,
  navigation,
  type,
  folderId,
  isRead = true,
}: {
  folderId?: string;
  navigation: DtoNavigation;
  count: number;
  type: 'mail' | 'scan';
  isRead?: boolean;
}) {
  navigation.forEach((item) => {
    const rootItemKey = type === 'mail' ? NavigationEntityKey.Mailbox : NavigationEntityKey.Scan;
    const folderType = type === 'mail' ? NavigationItemType.MailFolder : NavigationItemType.ScanFolder;

    if (item.key === rootItemKey) {
      const totalCount = item?.counterBadge || 0;
      item.counterBadge = isRead ? Math.max(totalCount - count, 0) : totalCount + count;

      if (folderId) {
        item?.subItems?.forEach((subItem) => {
          if (subItem.resourceId === Number(folderId) && subItem.type === folderType) {
            const folderCount = subItem?.counterBadge || 0;
            subItem.counterBadge = isRead ? Math.max(folderCount - count, 0) : folderCount + count;
          }
        });
      }
    }
  });
}
