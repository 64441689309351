import { Box, IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import { MouseEvent, forwardRef, useCallback, useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { TextInput } from './TextInput';

export type PasswordInputProps = Omit<TextFieldProps, 'type' | 'InputProps'>;

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>((textInputProps, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Box>
      <TextInput
        ref={ref}
        {...textInputProps}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                tabIndex={-1}
                aria-label="toggle password visibility"
                onClick={togglePasswordVisibility}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
});
