import { Box, BoxProps, styled } from '@mui/material';
import { FlexBox, ImagePopup, useDialog } from '@usgm/shared-ui';
import React from 'react';

import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';

export type FilePreviewProps = {
  file?: File;
  containerProps?: BoxProps;
  url?: string;
  enablePopupView?: boolean;
};

const IconContainer = styled(FlexBox)(({ theme }) => ({
  justifyContent: 'center',
  svg: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    color: theme.palette.primary.dark,
  },
}));

const PreviewContainer = styled(Box)(({ theme }) => ({
  width: 210,
  height: 132,
  padding: theme.spacing(1.25, 2),
  background: theme.palette.background.paper,
}));

export function FilePreview({ file, containerProps, url, enablePopupView }: FilePreviewProps) {
  const isPdf = file?.type === 'application/pdf' || url?.includes('.pdf');
  const { open, openDialog, closeDialog } = useDialog();
  if (!url && !file) {
    return null;
  }
  const imgPreview = !isPdf || file?.type.includes('image') ? true : false;
  const fileUrl = file ? URL.createObjectURL(file) : null;
  const fileSrc = fileUrl || url;
  if (imgPreview) {
    return (
      <PreviewContainer {...containerProps}>
        <img
          onClick={enablePopupView ? openDialog : undefined}
          onLoad={() => {
            if (imgPreview && fileUrl) {
              URL.revokeObjectURL(fileUrl);
            }
          }}
          src={fileSrc}
          alt="Preview"
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />

        {enablePopupView && <ImagePopup open={open} onClose={closeDialog} imageUrl={fileSrc} />}
      </PreviewContainer>
    );
  }
  return (
    <IconContainer justifyContent="center" width="100%" height="100%">
      <PictureAsPdfOutlinedIcon />
    </IconContainer>
  );
}

export default React.memo(FilePreview);
