import { zodResolver } from '@hookform/resolvers/zod';
import WestIcon from '@mui/icons-material/West';
import { Box } from '@mui/material';
import { AddressType } from '@usgm/inbox-api-types';
import { Button, FlexBox, Inbox, TextButton, useMobileMode } from '@usgm/shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../../../../store';
import { apiMessagesSlice } from '../../../../../../apiMessages/apiMessagesSlice';
import { useAuth } from '../../../../../../auth/hooks/useAuth';
import { StyledCardHeaderTitle } from '../../../../../components/styled/StyledCardHeaderTitle';
import { useAddAddressMutation } from '../../../../../inboxAccountsApi';
import ShippingAddressForm, {
  shippingAddressFormSchema,
  ShippingAddressFormSchemaType,
} from '../../../components/AddressBook/ShippingAddressForm';
import { CardHeader } from '../../../components/Card';
import { SettingsFormBox } from '../../../components/SettingsFormBox';
import { SETTINGS_PATHS } from '../../../paths';

function AddAddressPage() {
  const isMobile = useMobileMode();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const [addAddress, { isLoading: isSubmitting }] = useAddAddressMutation();

  const formMethods = useForm<ShippingAddressFormSchemaType>({
    resolver: zodResolver(shippingAddressFormSchema),
    mode: 'onChange',
    defaultValues: {
      address_type: AddressType.Shipping,
      country: { code: '', name: '', id: '' },
    },
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const handleBackClick = () => {
    navigate(SETTINGS_PATHS.ADDRESS_BOOK);
  };

  const onSubmit = async (data: ShippingAddressFormSchemaType) => {
    const response = await addAddress({ userUuid: user?.userUUID || '', addr: data });
    const hasError = 'error' in response;
    dispatch(
      apiMessagesSlice.actions.createMessage({
        severity: hasError ? 'error' : 'success',
        text: hasError
          ? 'Failed saving address. Please make sure all required fields are correctly entered and try again.'
          : 'Address saved successfully.',
      }),
    );
    if (!hasError) {
      navigate(SETTINGS_PATHS.ADDRESS_BOOK);
    }
  };

  const isFormSubmitDisabled = !isValid || isSubmitting;

  const addButton = (
    <Button isLoading={isSubmitting} disabled={isFormSubmitDisabled} type="submit" variant="contained">
      Add
    </Button>
  );

  return (
    <Inbox.PageContainer>
      <Inbox.Card noSpacings>
        <FormProvider {...formMethods}>
          <Box height={'100%'} onSubmit={handleSubmit(onSubmit)} component="form" noValidate>
            <CardHeader
              title={
                <>
                  <TextButton onClick={handleBackClick} sx={{ minWidth: 'auto', marginRight: 2 }}>
                    <WestIcon />
                  </TextButton>
                  <StyledCardHeaderTitle variant={isMobile ? 'body1' : 'h6'}>Add Address</StyledCardHeaderTitle>
                </>
              }
            >
              {!isMobile && (
                <FlexBox gap={2} justifyContent={'flex-end'}>
                  <Button variant="outlined" onClick={handleBackClick}>
                    Back
                  </Button>

                  {addButton}
                </FlexBox>
              )}
            </CardHeader>

            <Inbox.CardContent height="100%">
              <SettingsFormBox>
                <FormProvider {...formMethods}>
                  <ShippingAddressForm />
                  {isMobile && (
                    <FlexBox mb={3} mt={2} justifyContent="center">
                      {addButton}
                    </FlexBox>
                  )}
                </FormProvider>
              </SettingsFormBox>
            </Inbox.CardContent>
          </Box>
        </FormProvider>
      </Inbox.Card>
    </Inbox.PageContainer>
  );
}

export default AddAddressPage;
