import { DEFAULT_TIMEZONE, SHIPMENT_AVAILABLE_BEFORE_HOUR } from '@inbox/constants';
import { DateTime } from 'luxon';
import { isHoliday } from './isHoliday';

export function isShipmentAvailableDate(d: DateTime<boolean>): boolean {
  const now = DateTime.now();

  if (now.toLocaleString(DateTime.DATE_SHORT) === d.toLocaleString(DateTime.DATE_SHORT)) {
    return d.setZone(DEFAULT_TIMEZONE).set({ hour: SHIPMENT_AVAILABLE_BEFORE_HOUR, minute: 0, second: 0 }) > now;
  }

  const warehouseDate = d.setZone(DEFAULT_TIMEZONE);

  const day = warehouseDate.weekday;

  return day !== 7 && day !== 6 && !isHoliday(warehouseDate);
}
