import React, { useMemo } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Box } from '@mui/material';

import { SimpleDialog, TextInput, Toast } from '@usgm/shared-ui';
import { useUpdateUserDataMutation } from '../api';
import { useDispatch } from 'react-redux';
import { authSlice } from '../../../../auth/authSlice';

const schema = z.object({
  name: z.string().min(1, 'Full name is required.'),
});

type NameUpdateFormType = z.infer<typeof schema>;

export function NameUpdateDialog({
  userId,
  open,
  email,
  name,
  closeHandler,
}: {
  open: boolean;
  closeHandler: () => void;
  userId?: string;
  email?: string;
  name?: string;
}) {
  const [submitUpdateUserDataMutation, { isLoading, error }] = useUpdateUserDataMutation();
  const dispatch = useDispatch();
  const defaultValues = useMemo(() => ({ name: name || '' }), [name]);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<NameUpdateFormType>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const onFormSubmit = async (data: NameUpdateFormType) => {
    const results = await submitUpdateUserDataMutation({ ...data, email, uuid: userId });
    const hasError = 'error' in results;

    if (!hasError) {
      dispatch(authSlice.actions.updateAuthData({ name: data.name }));
      closeHandler();
    }
  };

  const isSubmitDisabled = !isValid || isLoading;
  return (
    <SimpleDialog
      title="Update Full Name"
      open={open}
      closeHandler={closeHandler}
      primaryButtonText="Save"
      isSubmitDisabled={isSubmitDisabled}
      isForm={true}
      onSubmit={handleSubmit(onFormSubmit)}
      isSubmitting={isLoading}
    >
      <Box sx={{ mt: 1 }}>
        <TextInput
          {...register('name')}
          disabled={isLoading}
          error={!!errors.name}
          label="Full Name"
          helperText={errors.name?.message}
          InputLabelProps={{ shrink: true }}
          required
          placeholder="Enter your full name"
          fullWidth
        />
      </Box>
      {error && <Toast title={error.message} severity="error" />}
    </SimpleDialog>
  );
}

export default React.memo(NameUpdateDialog);
