import { useMediaQuery, useTheme } from '@mui/material';
import { lazy, Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCurrentUser } from '../../../../../auth/hooks/useCurrentUser';
import { DynamicSidebar } from '../../../../components/DynamicSidebar';
import { DynamicSidebarPath, useDynamicSidebar } from '../../../../components/DynamicSidebar/useDynamicSidebar';
import { useUserWarehouseDetails } from '../../../../hooks/useUserWarehouseDetails';
import ChangeAddressContent from '../../../changeAddress/components/ChangeAddressContent';
import { Preloader } from '@usgm/shared-ui';
const AddressChangePage = lazy(() => import('../../../../features/changeAddress/components/AddressChangePage'));

export default function AddressPage() {
  const { user } = useCurrentUser();
  const location = useLocation();

  const { data } = useUserWarehouseDetails(user.userUUID);
  const { isSelected, toggleSidebar } = useDynamicSidebar(DynamicSidebarPath.ChangeAddress);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (location.state?.changeAddress) {
      toggleSidebar();
    }
  }, [location, toggleSidebar]);

  if (!data) return null;
  const { warehouseAddress } = data;

  return (
    isSelected &&
    (isDesktop ? (
      <Suspense fallback={<Preloader />}>
        <AddressChangePage toggleSidebar={toggleSidebar} warehouseAddress={warehouseAddress} />
      </Suspense>
    ) : (
      <DynamicSidebar
        open={isSelected}
        handleClose={toggleSidebar}
        path={DynamicSidebarPath.ChangeAddress}
        title="Change Address"
      >
        <Suspense fallback={<Preloader />}>
          <ChangeAddressContent warehouseAddress={warehouseAddress} handleClose={toggleSidebar} />
        </Suspense>
      </DynamicSidebar>
    ))
  );
}
