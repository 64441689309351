import { ComponentType, forwardRef } from 'react';
import { CircularProgress, Button as MuiButton, ButtonProps as MuiButtonProps, styled } from '@mui/material';

type StyledButtonProps = {
  square?: boolean;
  minWidth?: number;
};

export type ButtonProps = MuiButtonProps & StyledButtonProps & { isLoading?: boolean };

const StyledButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'minWidth' && prop !== 'square',
})<StyledButtonProps>(({ theme, variant, disabled, minWidth = 120, square }) => ({
  minWidth,
  '&.MuiButton-root.MuiButton-disabled': {
    backgroundColor: theme.palette.grey[300],
  },
}));

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((buttonProps, ref) => {
  const { isLoading, ...rest } = buttonProps;

  return (
    <StyledButton ref={ref} {...rest}>
      {isLoading ? <CircularProgress size={16} color="inherit" /> : buttonProps.children}
    </StyledButton>
  );
}) as ComponentType<ButtonProps>;
