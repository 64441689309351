import { Box, styled } from '@mui/material';

export type RoundedBadgeProps = {
  size: string;
  bg: string;
  color?: string;
  svgcolor?: string;
};

export const RoundedBadge = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'backgroundColor' && prop !== 'color' && prop !== 'svgcolor',
})<RoundedBadgeProps>(({ size, bg, color, svgcolor }) => ({
  color,
  minWidth: size,
  minHeight: size,
  width: size,
  height: size,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: bg,
  borderRadius: '100%',

  '& svg': {
    fill: svgcolor,
  },
}));

export default RoundedBadge;
