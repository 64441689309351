import { useMemo } from 'react';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { CircularProgress, Typography, useTheme } from '@mui/material';

import { BaseScan, ReportAnIssueType, SCAN_CATEGORY_NAMES } from '@usgm/inbox-api-types';
import { Button, ConfirmationDialog, SelectMenu, Toast, useDialog } from '@usgm/shared-ui';
import { useAuth } from '../../../../../auth/hooks/useAuth';

import ReportAnIssueDialog from '../../../../components/ReportAnIssueDialog';
import { useDeleteScanByIdMutation } from '../../api';
import { useScanDownload } from '../../hooks/useScanDownload';
import { useViewScanRequest } from '../../hooks/useViewScanRequest';

export type CompletedScansActionsProps = {
  id: BaseScan['uuid'];
  mailId: BaseScan['mailId'];
  createdAt: BaseScan['createdAt'];
  categoryType: BaseScan['categoryType'];
};
export function CompletedScansActions({
  id,
  mailId,
  createdAt: requestedOn,
  categoryType,
}: CompletedScansActionsProps) {
  const reportDialog = useDialog<string>({ open: false, value: '' });
  const deletionDialog = useDialog<string>({ open: false, value: '' });

  const scanRequestDialog = useViewScanRequest({ id, categoryType });

  const [deleteScan, deletionQuery] = useDeleteScanByIdMutation();

  const theme = useTheme();
  const { isAccountUnderPaymentHold } = useAuth();
  const secondaryActions = useMemo(() => {
    return [
      {
        label: 'View',
        icon: <VisibilityOutlinedIcon />,
        onClick: () => {
          scanRequestDialog.openDialog();
        },
      },
      {
        label: 'Delete',
        icon: <DeleteOutlinedIcon />,
        onClick: () => {
          deletionDialog.openDialog(id);
        },
      },
      {
        label: 'Report An Issue',
        icon: <ReportGmailerrorredOutlinedIcon />,
        onClick: () => {
          reportDialog.openDialog(id);
        },
      },
    ];
  }, [deletionDialog, id, reportDialog, scanRequestDialog]);

  const { handleDownloadClick, isLoading: isDownloading } = useScanDownload({ id });

  const handleScanDeletion = () => {
    if (deletionQuery.isLoading || !deletionDialog.value) {
      return;
    }
    deleteScan({ id: deletionDialog.value });
  };

  return (
    <>
      <Button
        fullWidth
        disabled={isAccountUnderPaymentHold || isDownloading}
        onClick={handleDownloadClick}
        color="primary"
        variant="contained"
      >
        {isDownloading ? <CircularProgress size={16} /> : 'Download'}
      </Button>
      <SelectMenu
        disabled={isAccountUnderPaymentHold}
        containerProps={{ width: '100%' }}
        buttonProps={{
          sx: [
            {
              width: '100%',
              backgroundColor: theme.palette.background.paper,
              '&:hover': { backgroundColor: theme.palette.background.paper },
            },
          ],
        }}
        display="Button"
        options={secondaryActions}
        label="More"
      />

      <ReportAnIssueDialog
        reportAnIssueType={ReportAnIssueType.ScanRequest}
        requestID={id}
        requestedOn={requestedOn}
        mailItemId={mailId}
        open={reportDialog.open}
        onClose={reportDialog.closeDialog}
      />
      {deletionDialog.value ? (
        <ConfirmationDialog
          open={deletionDialog.open}
          onClose={deletionDialog.closeDialog}
          title={`Deleting ${SCAN_CATEGORY_NAMES[categoryType]} #${id}`}
          confirmText="Delete"
          onConfirm={handleScanDeletion}
          content={
            <Typography variant="h6">
              Are you sure you want to delete selected scan. This action cannot be undone.
            </Typography>
          }
        />
      ) : null}
      {scanRequestDialog.dialog}
      {(deletionQuery.data || deletionQuery.error) && (
        <Toast
          title={deletionQuery.data?.message || deletionQuery.error?.message}
          severity={deletionQuery.error?.message ? 'error' : 'success'}
        />
      )}
    </>
  );
}

export default CompletedScansActions;
