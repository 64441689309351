import { Typography } from '@mui/material';
import { BaseMail } from '@usgm/inbox-api-types';
import { ConfirmationDialogProps, ConfirmationDialog } from '@usgm/shared-ui';
import { useDiscardMailsMutation } from '../api';
import { useAppDispatch } from '../../../../../store';
import { apiMessagesSlice } from '../../../../apiMessages/apiMessagesSlice';

export type ConfirmDiscardsDialogProps = Omit<ConfirmationDialogProps, 'content'> & {
  ids: BaseMail['id'][];
  message?: string;
};
function ConfirmDiscardsDialog({ ids, message, ...dialogProps }: ConfirmDiscardsDialogProps) {
  const dispatch = useAppDispatch();
  const [discardMails] = useDiscardMailsMutation();
  const plural = ids.length > 1 ? 's' : '';
  const modalMessage = message || `Are you sure you want to discard ${ids.length > 1 ? 'selected ' : ''}item${plural}?`;

  const handleConfirm = async () => {
    const response = await discardMails({ ids });
    const hasError = 'error' in response;
    const plural = ids.length > 1 ? 's' : '';
    const message = hasError
      ? `Failed to discard item${plural}. Please try again later.`
      : `Item${plural} discarded successfully`;

    dispatch(
      apiMessagesSlice.actions.createMessage({
        text: message,
        severity: hasError ? 'error' : 'success',
      }),
    );
  };

  return (
    <ConfirmationDialog
      {...dialogProps}
      onConfirm={handleConfirm}
      content={
        <Typography textAlign="center" variant="h5">
          {modalMessage}
        </Typography>
      }
      confirmText="Yes"
      cancelText="No"
    />
  );
}

export default ConfirmDiscardsDialog;
