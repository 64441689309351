import React, { ReactNode, useCallback, useState } from 'react';
import TruncateMarkup from 'react-truncate-markup';
import { Tooltip, TooltipProps } from './Tooltip';

export type ITruncateTextWithTooltipProps = {
  lines?: number;
  text: ReactNode;
  placement?: TooltipProps['placement'];
};

export function TruncateTextWithTooltip({ text, lines = 2, placement = 'bottom' }: ITruncateTextWithTooltipProps) {
  const [isTruncated, setIsTruncated] = useState(false);

  const onTruncate = useCallback((wasTruncated: boolean) => setIsTruncated(wasTruncated), []);

  return (
    <Tooltip
      disableBottomSheet={true}
      disableHoverListener={!isTruncated}
      disableTouchListener={!isTruncated}
      placement={placement}
      title={text}
    >
      <div>
        <TruncateMarkup lines={lines} onTruncate={onTruncate}>
          {text}
        </TruncateMarkup>
      </div>
    </Tooltip>
  );
}
