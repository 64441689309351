import { styled } from '@mui/material';

type StyledProps = {
  responsive?: boolean;
};

const StyledImg = styled('img', { shouldForwardProp: (prop) => prop !== 'responsive' })<StyledProps>(
  ({ responsive = true }) => ({
    maxWidth: responsive ? '100%' : 'none',
    height: 'auto',
  }),
);

export type ImageProps = StyledProps & {
  src: string;
  alt: string;
};
export function Image({ alt, src, ...styleProps }: ImageProps) {
  return <StyledImg alt={alt} src={src} {...styleProps} />;
}
