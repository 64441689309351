import { CircularProgress } from '@mui/material';
import { BaseMail } from '@usgm/inbox-api-types';
import { Button } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import { MouseEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../../../store';
import { apiMessagesSlice } from '../../../../../apiMessages/apiMessagesSlice';
import { useAuth } from '../../../../../auth/hooks/useAuth';
import { useRestoreDiscardedMailsMutation } from '../../api';
import { GridRowParams } from '@mui/x-data-grid';

export type DiscardActionsProps = {
  mails: Omit<GridRowParams<BaseMail>, 'columns'>[];
  listView?: boolean;
  clearSelection?: () => void;
};
function DiscardActions({ mails, listView, clearSelection }: DiscardActionsProps) {
  const { isAccountUnderPaymentHold } = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [restoreDiscards, { isLoading }] = useRestoreDiscardedMailsMutation();

  const handleRestoreClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const response = await restoreDiscards({ ids: mails.map(({ id }) => id as number) });
      const hasError = 'error' in response;
      const message = hasError ? 'Failed to restore item. Please try again later.' : 'Item restored successfully';
      dispatch(
        apiMessagesSlice.actions.createMessage({
          text: message,
          severity: hasError ? 'error' : 'success',
        }),
      );
      clearSelection?.();
      if (!listView) {
        navigate(`${inboxHelpers.MAIN_PATHS.APP}/${inboxHelpers.APP_PATHS.DISCARDS}`);
      }
    },
    [clearSelection, dispatch, listView, mails, navigate, restoreDiscards],
  );

  return (
    <Button
      disabled={isAccountUnderPaymentHold || isLoading}
      onClick={handleRestoreClick}
      variant={listView ? 'outlined' : 'contained'}
      fullWidth={listView ? false : true}
    >
      {isLoading ? <CircularProgress size={16} /> : 'Restore'}
    </Button>
  );
}

export default DiscardActions;
