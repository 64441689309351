import { AccountRelation } from '@usgm/inbox-api-types';
import { onboardingUtils } from '@usgm/utils';

export const DEFAULT_SELECTED_VALUE = 'Select Value';

export const DESCRIPTION_OPTIONS_PERSONAL = [
  DEFAULT_SELECTED_VALUE,
  AccountRelation.Adult,
  AccountRelation.MinorChild,
  AccountRelation.Deceased,
] as const;

export const DESCRIPTION_OPTIONS_BUSINESS = [
  DEFAULT_SELECTED_VALUE,
  ...onboardingUtils.BUSINESS_ACCOUNT_RELATIONS,
] as const;
