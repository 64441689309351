import { DateTime, WeekdayNumbers } from 'luxon';

export function createWeekDaysArray(
  format = 'EEE',
  excludeWeekend = false,
): { value: WeekdayNumbers; label: string }[] {
  return (Array.from({ length: excludeWeekend ? 5 : 7 }, (_, i) => i + 1) as WeekdayNumbers[]).map((day) => ({
    value: day,
    label: DateTime.fromObject({ weekday: day }).toFormat(format),
  }));
}
