import LocationOffOutlinedIcon from '@mui/icons-material/LocationOffOutlined';
import { Box, FormControl, LinearProgress, Radio, RadioGroup, styled } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridSlots } from '@mui/x-data-grid';
import { AddressType, DtoAddresses } from '@usgm/inbox-api-types';
import {
  BoldText,
  Button,
  DataGrid,
  dataTableConfig,
  FlexBox,
  GridCardContainer,
  MobileFooter,
  NO_ROWS_CLASS,
  NoRowPlaceholder,
  TextCell,
  useMobileMode,
} from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../../../../store';
import { apiMessagesSlice } from '../../../../../../apiMessages/apiMessagesSlice';
import { useAuth } from '../../../../../../auth/hooks/useAuth';
import { useGetUserAddressesQuery, useSetDefaultAddressMutation } from '../../../../../inboxAccountsApi';
import AddressActions from '../../../components/AddressBook/AddressActions';
import AddressInfoCell from '../../../components/AddressBook/tableCells/AddressInfoCell';
import { CardContent, CardHeader } from '../../../components/Card';
import { useGetRowHeight } from '../../../hooks/useGetRowHeight';
import { useGetRowSpacing } from '../../../hooks/useGetRowSpacing';
import { SETTINGS_PATHS } from '../../../paths';

const DESKTOP_COLUMNS: GridColDef[] = [
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'name',
    headerName: 'Contact',
    renderCell: (params: GridRenderCellParams<DtoAddresses['addresses'][number]>) => {
      return (
        <TextCell>
          <BoldText>{params.row.name}</BoldText>
        </TextCell>
      );
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'address',
    headerName: 'Address',
    flex: 2,
    renderCell: (params: GridRenderCellParams<DtoAddresses['addresses'][number]>) => {
      return <TextCell>{inboxHelpers.stringifyAddress({ addr: params.row })}</TextCell>;
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'addressTypeLabel',
    headerName: 'Type',
    renderCell: (params: GridRenderCellParams<DtoAddresses['addresses'][number]>) => {
      return <TextCell>{params.row.addressTypeLabel}</TextCell>;
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'isDefault',
    headerName: 'Default',
    flex: 0,
    renderCell: (params: GridRenderCellParams<DtoAddresses['addresses'][number]>) => {
      return <Radio value={params.row.uuid} checked={params.row.isDefault} />;
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'actions',
    flex: 0,
    renderHeader: () => '',
    renderCell: (params) => {
      return <AddressActions addressUuid={params.row.uuid} />;
    },
  },
];

const MOBILE_COLUMNS: GridColDef[] = [
  {
    field: 'addressInfo',
    headerName: 'Info',
    flex: 1,
    renderCell: (params: GridRenderCellParams<DtoAddresses['addresses'][number]>) => {
      return <AddressInfoCell data={params.row} />;
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'actions',
    flex: 0,
    maxWidth: 24,
    renderCell: (params) => {
      return <AddressActions addressUuid={params.row.uuid} />;
    },
  },
];

const StyledHeaderButton = styled(Button)(({ theme }) => ({
  minWidth: 160,
  marginLeft: theme.spacing(2.5),
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    width: '100%',
  },
}));

function AddressBookPage() {
  const isMobile = useMobileMode();
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data, isFetching } = useGetUserAddressesQuery({
    addressType: [AddressType.CheckDeposit, AddressType.Shipping].join(),
    userUUID: user?.userUUID,
  });
  const [setDefaultAddressQuery, { data: setAddressResponse, error: errorSetDefaultAddress }] =
    useSetDefaultAddressMutation();

  const handleAddAddressClick = useCallback(() => {
    navigate(SETTINGS_PATHS.ADD_ADDRESS);
  }, [navigate]);

  const addAddressButton = useMemo(
    () => (
      <StyledHeaderButton variant="contained" onClick={handleAddAddressClick}>
        Add Address
      </StyledHeaderButton>
    ),
    [handleAddAddressClick],
  );

  const slots = useMemo(
    () => ({
      loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
      noRowsOverlay: () => (
        <NoRowPlaceholder
          icon={<LocationOffOutlinedIcon color="primary" />}
          text={`No addresses yet. Add Shipping or Check deposit address.`}
        />
      ),
      footer: () => (isMobile ? <MobileFooter>{addAddressButton}</MobileFooter> : null),
    }),
    [addAddressButton, isMobile],
  );

  const addressesColumns = useMemo(() => {
    if (isMobile) {
      return MOBILE_COLUMNS;
    }
    return DESKTOP_COLUMNS;
  }, [isMobile]);

  const getRowSpacing = useGetRowSpacing();
  const getRowHeight = useGetRowHeight(isMobile);

  const handleDefaultAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDefaultAddressQuery({ addressUuid: event.target.value, userUuid: user?.userUUID || '' });
  };

  if (setAddressResponse || errorSetDefaultAddress) {
    dispatch(
      apiMessagesSlice.actions.createMessage({
        text: errorSetDefaultAddress
          ? 'Something went wrong while marking the address as default. Please try again.'
          : 'Address marked as default.',
        severity: errorSetDefaultAddress ? 'error' : 'success',
      }),
    );
  }

  return (
    <GridCardContainer>
      <CardHeader
        subTitle=" Organize your Address Book to maintain up-to-date shipping and check deposit addresses. Add, edit, delete, and designate default addresses for smooth processing of your mail and packages."
        title="Manage Addresses"
      >
        <FlexBox width="100%">
          <Box />
          {!isMobile && addAddressButton}
        </FlexBox>
      </CardHeader>
      <CardContent>
        <FormControl fullWidth sx={{ width: '100%', height: '100%', flexGrow: 1 }}>
          <RadioGroup onChange={handleDefaultAddressChange} sx={{ width: '100%', height: '100%', flexGrow: 1 }}>
            <DataGrid
              className={!data?.addresses.length ? NO_ROWS_CLASS : undefined}
              autoHeight={isMobile}
              disableColumnResize
              disableColumnMenu
              hideFooter={!isMobile || isFetching}
              hideColumns={isMobile}
              disableRowSelectionOnClick
              getRowHeight={getRowHeight}
              rows={data?.addresses ?? []}
              slots={slots}
              columns={addressesColumns}
              loading={isFetching}
              getRowSpacing={getRowSpacing}
            />
          </RadioGroup>
        </FormControl>
      </CardContent>
    </GridCardContainer>
  );
}

export default AddressBookPage;
