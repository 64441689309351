import { UserType } from '@usgm/inbox-api-types';
import { dateUtils, pascalCase } from '@usgm/utils';

import { z } from 'zod';

const createDocumentSchema = (userType: UserType) => {
  return z.object(
    {
      value: z.string(),
      label: z.string(),
    },
    {
      errorMap: () => {
        return { message: `${pascalCase(userType)} ID type is required` };
      },
    },
  );
};

export const primaryDocumentsFieldSchema = z.object({
  first_name: z.string().optional().describe('First Name'),
  last_name: z.string().optional().describe('Last Name'),
  middle_name: z.string().optional().describe('Middle Name'),
  id_number: z.string().min(1, 'ID number is required.').describe('ID Number'),
  issuing_entity: z.string().min(1, 'Issuing entity is required.').describe('Issuing Entity'),
  expiration_date: z
    .string()
    .min(1, 'Expiration date must be in the future.')
    .describe('Expiration Date')
    .refine((d) => {
      if (dateUtils.isValidDate(d)) {
        return new Date(d) > new Date();
      }
      return true;
    }),
  name: z.string().min(1, 'Name is required.').describe('Full Name'),
});

export type PrimaryDocumentsFieldSchemaType = z.infer<typeof primaryDocumentsFieldSchema>;

export const secondaryDocumentFieldsSchema = z.object({
  full_name: z.string().min(1, 'Name is required.').describe('Full Name'),
  street_address: z.string().min(1, 'Address is required.').describe('Address Line'),
  city: z.string().min(1, 'City is required.').describe('City'),
  state: z.string().min(1, 'State is required.').describe('State'),
  postal_code: z.string().min(1, 'Zip is required.').describe('Zip'),
  country: z.string().min(1, 'Country is required.').describe('Country'),
});

export type SecondaryDocumentFieldsSchemaType = z.infer<typeof secondaryDocumentFieldsSchema>;

const fileSchema = z.object({
  file: z.instanceof(File).optional(),
  url: z.string().optional(),
  id: z.string().optional(),
});

export const documentsSchema = z.object({
  primaryDocumentType: createDocumentSchema(UserType.Primary),
  secondaryDocumentType: createDocumentSchema(UserType.Secondary),
  primaryDocumentFields: primaryDocumentsFieldSchema,
  secondaryDocumentFields: secondaryDocumentFieldsSchema,
  secondaryFile: fileSchema,
  primaryFile: fileSchema,
});

export type DocumentsSchemaType = Partial<z.infer<typeof documentsSchema>>;
