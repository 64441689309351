import { Box, styled } from '@mui/material';

export const GridCardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  minHeight: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100vw',
}));
