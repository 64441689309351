import { Box } from '@mui/material';
import { cellHelpers, TextCell, Tooltip } from '@usgm/shared-ui';

type ScanAutoDeletionDateProps = {
  dateValue: string;
};
function ScanAutoDeletionDate({ dateValue }: ScanAutoDeletionDateProps) {
  return (
    <Tooltip title={cellHelpers.formatDateString(dateValue)}>
      <Box>
        <TextCell mt={0}>{cellHelpers.relativeDate({ value: dateValue, unit: 'days' })}</TextCell>
      </Box>
    </Tooltip>
  );
}

export default ScanAutoDeletionDate;
