export const RECOMMENDED_WAREHOUSES = [1, 2, 107];

export const WAREHOUSE_BADGES = new Map<
  number,
  Array<{ label: string; color?: 'success' | 'secondary'; tooltip?: string }>
>([
  [
    1,
    [
      {
        label: 'Best Choice',
        color: 'secondary',
        tooltip: `These are USGM's own warehouses and ensure faster, superior service.`,
      },
      {
        label: 'Fast response',
      },
      {
        label: 'Superior service',
      },
    ],
  ],
  [
    2,
    [
      {
        label: 'Best Choice',
        color: 'secondary',
        tooltip: `These are USGM's own warehouses and ensure faster, superior service.`,
      },
      {
        label: 'Fast response',
      },
      {
        label: 'Superior service',
      },
    ],
  ],
  [
    107,
    [
      {
        label: 'Free Month',
        color: 'success',
      },
      {
        label: 'Fast response',
      },
      {
        label: 'Superior service',
      },
    ],
  ],
]);
