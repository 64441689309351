import { alpha, styled } from '@mui/material';
import { FlexBox } from '@usgm/shared-ui';

const FooterBottom = styled(FlexBox)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  borderTop: `1px solid ${alpha(theme.palette.common.white, 0.3)}`,
  padding: `${theme.spacing(4)} 10.4% 0 10.4%`,
  [theme.breakpoints.down('lg')]: {
    padding: `${theme.spacing(4, 4, 0, 4)}`,
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3.75, 2.5, 0, 2.5),
  },
}));

export default FooterBottom;
