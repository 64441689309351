import { ReactNode } from 'react';

import { Box, Typography, styled, useTheme } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const BackButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  marginRight: 'auto',
  flex: '1 1 auto',
}));

const BackButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  marginRight: theme.spacing(2.75),
  cursor: 'pointer',
  svg: {
    color: theme.palette.primary.main,
  },
}));

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0, 2.5),
  height: '68px',
  position: 'fixed',
  top: 64,
  left: 0,
  background: theme.palette.background.paper,
  zIndex: theme.zIndex.drawer - 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export type MobileActionProps = {
  children: ReactNode;
  selectedCount?: number;
  onClearSelection?: () => void;
};
function MobileAction({ children, selectedCount, onClearSelection }: MobileActionProps) {
  const theme = useTheme();
  if (!selectedCount) {
    return null;
  }
  return (
    <Container>
      <BackButtonContainer>
        <BackButton onClick={onClearSelection}>
          <KeyboardBackspaceIcon />
        </BackButton>
        <Typography fontWeight={theme.typography.fontWeightBold} color={theme.palette.primary.main}>
          {selectedCount}
        </Typography>
      </BackButtonContainer>
      <Box maxWidth={280}>{children}</Box>
    </Container>
  );
}

export default MobileAction;
