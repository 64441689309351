import { Box, Typography, useTheme } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid';
import { type DtoBankAccount } from '@usgm/inbox-api-types';
import { DataGrid, dataTableConfig, Preloader, TextCell, useMobileMode } from '@usgm/shared-ui';
import { useMemo } from 'react';

import { useGetBankAccountsQuery } from '../../../../../../../inboxAccountsApi';
import { useGetRowHeight } from '../../../../../../settings/hooks/useGetRowHeight';
import { useGetRowSpacing } from '../../../../../../settings/hooks/useGetRowSpacing';
import { BankAccountActions } from './BankAccountActions';
import BankAccountInfoCell from './tableCells/BankAccountInfoCell';

type BankAccountsTableProps = {
  onEditClick: (bankAccount: DtoBankAccount) => void;
  onDeleteClick: (bankAccount: DtoBankAccount) => void;
};

function BankAccountsTable({ onDeleteClick, onEditClick }: BankAccountsTableProps) {
  const theme = useTheme();
  const isMobile = useMobileMode();
  const { data, isLoading, isFetching } = useGetBankAccountsQuery();
  const getRowHeight = useGetRowHeight(isMobile);
  const getRowSpacing = useGetRowSpacing();

  const bankAccounts = data?.list || [];

  const DESKTOP_COLUMNS: GridColDef[] = useMemo(() => {
    return [
      {
        ...dataTableConfig.DEFAULT_COL_DEF,
        field: 'accountNumber',
        headerName: 'Account Number',
        renderCell: (params: GridRenderCellParams<DtoBankAccount>) => {
          return <TextCell>{params.row.accountNumber}</TextCell>;
        },
      },
      {
        ...dataTableConfig.DEFAULT_COL_DEF,
        field: 'bankName',
        headerName: 'Bank Name',
        renderCell: (params: GridRenderCellParams<DtoBankAccount>) => {
          return <TextCell>{params.row.bankName}</TextCell>;
        },
      },
      {
        ...dataTableConfig.DEFAULT_COL_DEF,
        field: 'bankState',
        headerName: 'Bank State',
        renderCell: (params: GridRenderCellParams<DtoBankAccount>) => {
          return <TextCell>{params.row.bankState}</TextCell>;
        },
      },
      {
        ...dataTableConfig.DEFAULT_COL_DEF,
        field: 'actions',
        flex: 0,
        renderHeader: () => '',
        renderCell: (params: GridRenderCellParams<DtoBankAccount>) => {
          return <BankAccountActions item={params.row} onEditClick={onEditClick} onDeleteClick={onDeleteClick} />;
        },
      },
    ];
  }, [onEditClick, onDeleteClick]);

  const MOBILE_COLUMNS: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'bankAccountInfo',
        headerName: 'Info',
        flex: 1,
        renderCell: (params: GridRenderCellParams<DtoBankAccount>) => {
          return <BankAccountInfoCell data={params.row} />;
        },
      },
      {
        ...dataTableConfig.DEFAULT_COL_DEF,
        field: 'actions',
        flex: 0,
        maxWidth: 24,
        renderCell: (params: GridRenderCellParams<DtoBankAccount>) => {
          return <BankAccountActions item={params.row} onEditClick={onEditClick} onDeleteClick={onDeleteClick} />;
        },
      },
    ];
  }, [onEditClick, onDeleteClick]);

  const accountNameColumns = useMemo(() => {
    if (isMobile) {
      return MOBILE_COLUMNS;
    }
    return DESKTOP_COLUMNS;
  }, [isMobile, DESKTOP_COLUMNS, MOBILE_COLUMNS]);

  if (isLoading) {
    return <Preloader minHeight={200} />;
  }

  if (bankAccounts?.length === 0 && !isLoading) {
    return (
      <Box textAlign="center" py={5} px={2}>
        <Typography mb={3} color={theme.customColors.dark[400]} textAlign="center">
          No bank accounts added yet. Click the Add button to add one.
        </Typography>
      </Box>
    );
  }
  return (
    <DataGrid
      disableColumnResize
      disableColumnMenu
      hideFooter
      hideColumns={isMobile}
      disableRowSelectionOnClick
      getRowHeight={getRowHeight}
      rows={data?.list ?? []}
      columns={accountNameColumns}
      loading={isFetching}
      getRowSpacing={getRowSpacing}
      getRowId={(row: GridValidRowModel) => row.uuid}
    />
  );
}

export default BankAccountsTable;
