import React, { useMemo } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { Button, FlexBox, Inbox, PageStepper, PageStepperProps, Preloader, useMobileMode } from '@usgm/shared-ui';

import { StyledCardHeaderTitle } from '../../../../../../components/styled/StyledCardHeaderTitle';
import { useGetAccountNamesWithStatusQuery } from '../../../../../onboarding/api';
import { CardContent, CardHeader } from '../../../../components/Card';
import { SETTINGS_PATHS } from '../../../../paths';
import AccountNotFound from './components/AccountNotFound';

function Layout() {
  const navigate = useNavigate();
  const isMobile = useMobileMode();
  const { accountNameUuid } = useParams<{ accountNameUuid: string }>();

  const accountsQuery = useGetAccountNamesWithStatusQuery(null);

  const actionName = accountNameUuid ? 'Edit' : 'Add';
  const steps = useMemo(() => {
    const steps: PageStepperProps['steps'] = [
      {
        label: `${actionName} Name`,
        path: !accountNameUuid ? SETTINGS_PATHS.ADD_ACCOUNT_NAME : SETTINGS_PATHS.EDIT_ACCOUNT_NAME(accountNameUuid),
      },
      {
        label: `Upload ID(s)`,
        path: accountNameUuid ? SETTINGS_PATHS.EDIT_ACCOUNT_NAME_DOCUMENTS(accountNameUuid) : undefined,
      },
      {
        label: 'Notarize',
        path: accountNameUuid ? SETTINGS_PATHS.NOTARIZE_ACCOUNT_NAME(accountNameUuid) : undefined,
      },
    ];
    return steps;
  }, [accountNameUuid, actionName]);

  const handleBackClick = () => {
    navigate(SETTINGS_PATHS.ACCOUNT_NAMES);
  };

  const accountToEdit = accountsQuery.data?.data.accountNames.find((account) => account.user.uuid === accountNameUuid);
  const notFound = accountsQuery.data?.data && accountNameUuid && !accountToEdit;

  return (
    <Inbox.PageContainer>
      <Inbox.Card noSpacings>
        <CardHeader
          onBackButtonClick={handleBackClick}
          title={
            <StyledCardHeaderTitle variant={isMobile ? 'body1' : 'h6'}>{actionName} Account Name</StyledCardHeaderTitle>
          }
        >
          <FlexBox gap={2} justifyContent={'flex-end'}>
            {!isMobile && (
              <Button variant="outlined" onClick={handleBackClick}>
                Back
              </Button>
            )}
          </FlexBox>
        </CardHeader>

        <CardContent sx={{ height: '100%' }}>
          <PageStepper steps={steps} />

          {accountsQuery.isLoading || accountsQuery.isFetching ? (
            <Preloader />
          ) : notFound ? (
            <AccountNotFound accountId={accountNameUuid} />
          ) : (
            <Outlet />
          )}
        </CardContent>
      </Inbox.Card>
    </Inbox.PageContainer>
  );
}

export default Layout;
