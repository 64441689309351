import { type DtoPlanResults, type GetWarehousePlansParams } from '@usgm/inbox-api-types';
import { accountApi } from '../../api/accountApi';

export const plansPickerApi = accountApi.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    getWarehousePlans: builder.query<DtoPlanResults, GetWarehousePlansParams>({
      query: (params) => ({
        url: '/warehouse/plans',
        params,
        method: 'get',
      }),
    }),
  }),
});

export const { useGetWarehousePlansQuery } = plansPickerApi;
