import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { loadMoreWarehouses, selectCanLoadMore } from '../addressPickerSlice';

export const usePaginateWarehouses = () => {
  const dispatch = useAppDispatch();
  const canLoadMore = useAppSelector(selectCanLoadMore);

  const loadMore = useCallback(() => {
    dispatch(loadMoreWarehouses());
  }, [dispatch]);

  return { canLoadMore, loadMore };
};
