import React, { useMemo } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Box } from '@mui/material';

import { SimpleDialog, TextInput, Toast } from '@usgm/shared-ui';
import { useUpdateUserDataMutation } from '../api';
import { authSlice } from '../../../../auth/authSlice';
import { useDispatch } from 'react-redux';

const schema = z.object({
  email: z.string().min(1, 'Email is required.').email(),
});

type EmailUpdateFormType = z.infer<typeof schema>;

export function EmailUpdateDialog({
  userId,
  fullName,
  open,
  closeHandler,
  email,
}: {
  open: boolean;
  closeHandler: () => void;
  userId: string;
  fullName: string;
  email?: string;
}) {
  const [submitUpdateUserDataMutation, { isLoading, error }] = useUpdateUserDataMutation();
  const dispatch = useDispatch();

  const defaultValues = useMemo(() => ({ email: email || '' }), [email]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<EmailUpdateFormType>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const onFormSubmit = async (data: EmailUpdateFormType) => {
    const results = await submitUpdateUserDataMutation({ ...data, name: fullName, uuid: userId });
    const hasError = 'error' in results;

    if (!hasError) {
      dispatch(authSlice.actions.updateAuthData({ email: data.email }));
      closeHandler();
    }
  };

  const isSubmitDisabled = !isValid || isLoading;
  return (
    <SimpleDialog
      title="Update Email"
      open={open}
      closeHandler={closeHandler}
      primaryButtonText="Save"
      isSubmitDisabled={isSubmitDisabled}
      isForm={true}
      onSubmit={handleSubmit(onFormSubmit)}
      isSubmitting={isLoading}
    >
      <Box sx={{ mt: 1 }}>
        <TextInput
          {...register('email')}
          disabled={isLoading}
          error={!!errors.email}
          label="Email"
          helperText={errors.email?.message}
          InputLabelProps={{ shrink: true }}
          required
          placeholder="Enter your new email address"
          fullWidth
        />
      </Box>
      {error && <Toast title={error.message} severity="error" />}
    </SimpleDialog>
  );
}

export default React.memo(EmailUpdateDialog);
