import { ADDRESS_SCHEMA_FIELDS } from '@inbox/constants';
import { AccountRelation } from '@usgm/inbox-api-types';
import { Except } from 'type-fest';
import { z } from 'zod';

export const AccountSchema = z.object({
  name: z.string().min(1, 'Name is required.').max(255, 'Name is too long.'),
  relationType: z.nativeEnum(AccountRelation, {
    errorMap: () => {
      return { message: 'Description is required.' };
    },
  }),
  uuid: z.string().optional(),
});

export type TAccountData = z.infer<typeof AccountSchema>;
export type TCompanyData = z.infer<typeof CompanyInfoSchema>;

export const CompanyInfoSchema = z
  .object({
    typeOfBusiness: z.string().min(1, 'Type of Business is required.').max(255, 'Type of Business is too long.'),
    placeOfRegistration: z
      .string()
      .min(1, 'Place Of Registration is required.')
      .max(255, 'Place Of Registration is too long.'),
    streetAddress: ADDRESS_SCHEMA_FIELDS.ADDRESS_LINE.min(1, 'Street Address is required.').max(
      255,
      'Street Address is too long.',
    ),
    country: ADDRESS_SCHEMA_FIELDS.COUNTRY,
    state: ADDRESS_SCHEMA_FIELDS.STATE,
    city: ADDRESS_SCHEMA_FIELDS.CITY,
    zipCode: ADDRESS_SCHEMA_FIELDS.ZIP,
    phoneNumber: z.string().min(1, 'Phone Number is required.').max(255, 'Phone Number is too long.'),
  })
  .merge(AccountSchema);

export const addNamesFormSchema = z.object({
  accountNames: z.array(AccountSchema),
});

export const addNamesCompanyInfoFormSchema = z.object({
  accountNames: z.array(CompanyInfoSchema),
});

export type CompanyInfoFormSchema = z.infer<typeof addNamesCompanyInfoFormSchema>;

export type AccountNamesFormSchemaType = {
  accountNames: Array<AccountSchemaType>;
};
export type AccountSchemaType = Except<z.infer<typeof AccountSchema>, 'relationType'> & {
  relationType: AccountRelation;
};
