import { useAuth } from '../../../../auth/hooks/useAuth';
import { AddNamesBusiness } from '../components/addNames/AddNamesBusiness';
import { AddNamesStandard } from '../components/addNames/AddNamesStandard';
import { useOnboardingData } from '../hooks/useOnboardingData';

export default function AddNamesPage() {
  const { activeSubscription } = useOnboardingData();
  const isBusiness = activeSubscription?.plan?.options?.isBusiness;
  const { user } = useAuth();

  if (!user || !activeSubscription) {
    return null;
  }

  const Layout = isBusiness ? AddNamesBusiness : AddNamesStandard;

  return <Layout plan={activeSubscription.plan} user={user} />;
}
