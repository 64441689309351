export const convertResultToPrediction = ({
  formatted_address,
  place_id,
  types,
}: google.maps.GeocoderResult): google.maps.places.AutocompletePrediction => ({
  description: formatted_address,
  place_id,
  matched_substrings: [],
  structured_formatting: {
    main_text: formatted_address,
    secondary_text: '',
    main_text_matched_substrings: [],
  },
  terms: [{ offset: 0, value: formatted_address }],
  types: types,
  distance_meters: 0,
});

export const formatGeoResultAddress = (result: google.maps.GeocoderResult) => {
  const { address_components } = result;
  const addressParts: string[] = [];
  const types = ['locality', 'administrative_area_level_1', 'country'];
  address_components.forEach((component) => {
    if (types.some((t) => component.types.includes(t)) && !addressParts.includes(component.long_name)) {
      addressParts.push(component.long_name);
    }
  });
  return addressParts.join(', ');
};
