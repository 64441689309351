import { DtoAppSettings } from '@usgm/inbox-api-types';
import { accountApi } from '../api/accountApi';

export const appApi = accountApi
  .enhanceEndpoints({
    addTagTypes: [],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAppSettings: builder.query<DtoAppSettings, void | null>({
        query: () => ({
          url: '/application/settings',
          method: 'get',
        }),
      }),
    }),
  });

export const { useGetAppSettingsQuery } = appApi;
