import { MailStatus, StatusTransition } from '@usgm/inbox-api-types';
import { findLatestStatusTransition } from './findLatestStatusTransition';
import { MAILS_DISCARD_DELETION_DAYS_COUNT } from './config';
import { DateTime } from 'luxon';

export function calculateDaysUntilDiscard({
  statusTransition: transitions,
}: {
  statusTransition: StatusTransition[];
}): number | null {
  const latestTransition = findLatestStatusTransition(transitions, MailStatus.DiscardRequest);
  if (!latestTransition) {
    return null;
  }

  const today = DateTime.local();
  const statusTransition = DateTime.fromISO(latestTransition.updatedOn).plus({
    days: MAILS_DISCARD_DELETION_DAYS_COUNT,
  });
  const diff = Math.ceil(statusTransition.diff(today, 'days').days);
  return diff;
}
