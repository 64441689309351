import { USA_TERRITORIES } from '@inbox/constants';
import { DtoShipment, US_COUNTRY_NAMES, type UserWarehouseAddress } from '@usgm/inbox-api-types';

export function shipmentIsNotBoundForUSMilitaryBase(address: DtoShipment['destination'] | UserWarehouseAddress) {
  if ([`APO`, `DPO`, `FPO`].map((i) => i.toLowerCase()).includes(address.city.trim().toLowerCase())) {
    return true;
  }
  if (US_COUNTRY_NAMES.includes(address.country)) {
    return USA_TERRITORIES.map((i) => i.toLowerCase()).includes(address.state.trim().toLowerCase());
  } else {
    return true;
  }
}
