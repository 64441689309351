import { RouterTabsProps } from '@usgm/shared-ui';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DynamicSidebar from '../components/DynamicSidebar';
import { TabsLayout } from '../components/layout/TabsLayout';
import {
  BILLINGS_TABS,
  BillingPathParams,
  DEFAULT_BILLING_TAB,
  createBillingsPath,
} from '../features/billingHistory/paths';
import InvoiceInfo from '../features/billingHistory/components/InvoiceInfo';
import CreditNoteInfo from '../features/billingHistory/components/CreditNoteInfo';

const SIDEBAR_TITLE_MAP: Record<BillingPathParams['tab'], string> = {
  'credit-notes': 'Credit Note',
  invoices: 'Invoice',
  'unbilled-charges': 'Unbilled Charge',
};

export default function BillingHistoryPage() {
  const navigate = useNavigate();
  const { folderId, itemId, tab = DEFAULT_BILLING_TAB } = useParams<BillingPathParams>();

  const handleClose = useCallback(() => {
    navigate(createBillingsPath({ tab }));
  }, [navigate, tab]);
  const tabs: RouterTabsProps['tabs'] = useMemo(() => {
    return BILLINGS_TABS.map(({ label, value }) => {
      return {
        path: createBillingsPath({ tab: value, folderId, itemId }),
        label: label,
        subText: value === 'unbilled-charges' ? 'this month' : undefined,
      };
    });
  }, [folderId, itemId]);

  const sidebarTitle = `${SIDEBAR_TITLE_MAP[tab]} #${itemId}`;

  return (
    <>
      <TabsLayout tabs={tabs} />
      <DynamicSidebar disableSpacings open={!!itemId} handleClose={handleClose} title={sidebarTitle}>
        {itemId && (tab === 'invoices' ? <InvoiceInfo id={itemId} /> : <CreditNoteInfo id={itemId} />)}
      </DynamicSidebar>
    </>
  );
}
