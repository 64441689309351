import { alpha } from '@mui/material';
import { FnCreateComponents } from '../types';

const APP_BAR_HEIGHT = 64;

const BUTTON_SMALL = {
  borderRadius: 2,
  fontSize: '0.75rem',
  lineHeight: 1.4,
};

export const createComponents: FnCreateComponents = (palette) => ({
  MuiAutocomplete: {
    defaultProps: {
      blurOnSelect: true,
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: palette?.divider,
      },
      input: {
        padding: '1.0625rem 1rem',
        '&::placeholder': {
          color: palette?.text?.disabled,
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: '0.875rem',
        lineHeight: 1.4,
        marginLeft: 0,
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        height: APP_BAR_HEIGHT,
        minHeight: APP_BAR_HEIGHT,
      },
    },
  },
  MuiTooltip: {
    defaultProps: {
      arrow: true,
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: palette?.background?.paper,
        borderBottom: `1px solid ${palette?.divider}`,
        height: APP_BAR_HEIGHT,
      },
    },
  },

  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${palette?.divider}`,
      },
    },
  },

  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        borderRadius: 4,
        lineHeight: 1,
        fontSize: '1rem',
        fontWeight: 600,
        '&.MuiButton-contained.Mui-disabled': {
          ...(palette?.primary?.main && { backgroundColor: alpha(palette?.primary.main, 0.5) }),
          color: '#ffffff',
        },
      },
      outlinedSizeLarge: {
        height: 54,
        padding: '1.125rem 1.5rem',
      },
      outlinedSizeMedium: {
        height: 46,
        padding: '0.875rem 1.5rem',
      },
      outlinedSizeSmall: {
        height: 32,
        padding: '0.3125rem 1rem',
      },
      sizeLarge: {
        height: 54,
        padding: '1.1875rem 1.5rem',
      },
      sizeMedium: {
        height: 46,
        padding: '0.9375rem 1.5rem',
      },
      sizeSmall: {
        ...BUTTON_SMALL,
        height: 32,
        padding: '0.375rem 1rem',
      },
    },
  },
});
