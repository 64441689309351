export type CreateArrowStyleParams = {
  direction: 'up' | 'down' | 'left' | 'right';
  width: string;
  height: string;
  color: string;
};
export const createArrowStyles = ({ direction, width, height, color }: CreateArrowStyleParams) => {
  const styles = {
    up: {
      borderLeft: `${width} solid transparent`,
      borderRight: `${width} solid transparent`,
      borderBottom: `${height} solid ${color}`,
    },
    down: {
      borderLeft: `${width} solid transparent`,
      borderRight: `${width} solid transparent`,
      borderTop: `${height} solid ${color}`,
    },
    left: {
      borderTop: `${width} solid transparent`,
      borderBottom: `${width} solid transparent`,
      borderRight: `${height} solid ${color}`,
    },
    right: {
      borderTop: `${width} solid transparent`,
      borderBottom: `${width} solid transparent`,
      borderLeft: `${height} solid ${color}`,
    },
  };
  return { ...styles[direction], width: 0, height: 0 };
};
