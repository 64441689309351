import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { FlexBox, InfoTitle } from '@usgm/shared-ui';

import LockIcon from '../../../assets/lock.svg?react';

export function SelectPlanSubHeader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const textColor = theme.palette.text.secondary;
  return (
    <FlexBox mt={1} justifyContent="center" alignItems={isMobile ? 'flex-start' : 'center'}>
      <FlexBox mr={0.5}>
        <LockIcon color={textColor} />
      </FlexBox>
      <FlexBox
        display={isMobile ? 'block' : 'flex'}
        flexWrap={isMobile ? 'wrap' : 'nowrap'}
        justifyContent={isMobile ? 'flex-start' : 'initial'}
      >
        <InfoTitle
          maxWidth={314}
          color={textColor}
          title="Bank grade encryption"
          description="With bank grade encryption and the latest technology, we are committed to your data security and safe handling of mail. All data that moves between our warehouse and servers is encrypted. We use Google Cloud and AWS – two industry leading platforms on security to house your data."
        />
        <Typography color="text.secondary" component="span">
          &nbsp;to keep your mail private... for
        </Typography>
        <Typography
          fontWeight="bold"
          color={theme.palette.mode === 'dark' ? 'common.white' : 'common.black'}
          component="span"
        >
          &nbsp;less than 33 cents/day
        </Typography>
      </FlexBox>
    </FlexBox>
  );
}
