export const DEFAULT_WAREHOUSE_ID = 1;

// All fees are in USD
export const TEAM_MEMBER_MONTHLY_FEE = 7.25;
export const LETTER_SCAN_FEE = 3;
export const SCAN_PAGE_FEE = 0.5;
export const EXPEDITE_FEE = 5;
export const PICKUP_NO_SHOW_FEE = 5;

export const SHIPMENT_SCHEDULING_CONSOLIDATE_FEE = 5;

export const DEFAULT_CURRENCY = 'USD';
export const DEFAULT_WEIGHT_UNIT = 'lb';
export const DEFAULT_DIMENSION_UNIT = 'in';
export const NO_DECLARATION_ITEM_DESCRIPTION = '0 Item';

export const SIGNATURE_COST = 8; // USD

export const SHIPMENT_AVAILABLE_BEFORE_HOUR = 15; // 3 PM
export const SHIPMENT_EXPEDITE_AVAILABLE_AFTER_HOUR = 13; // 1 PM
export const HOLIDAYS = [
  { month: 7, day: 4 }, // July 4th
] as const;

export const DEFAULT_TIMEZONE = 'America/Chicago';

export const PICKUP_SERVICE_TYPE = 'pickup_warehouse';

export const PICKUP_MAX_DAYS_TO_HOLD = 5;

export const QUARANTINE_READ_MORE_URL =
  'https://support.usglobalmail.com/hc/en-us/articles/360014652253-Items-in-Quarantine';

export const USGM_NOTARIZATION_FEE = 15;

export const LOGO_URL = 'https://usgm-static-prod.s3.us-east-1.amazonaws.com/usgm_open_graph_logo.jpg';
export const PRODUCTION_URL = 'https://inbox.usglobalmail.com';
