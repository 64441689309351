import { inboxHelpers } from '@usgm/utils';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import DynamicSidebar from '../components/DynamicSidebar';
import { ContentContainer, TabsLayout } from '../components/layout/TabsLayout';
import DiscardsList from '../features/mails/components/DiscardsList';
import MailInfo from '../features/mails/components/MailInfo';
import { MailsPathParams } from '../features/mails/paths';
import { useNavigatePreservingParams } from '../hooks/useNavigatePreservingParams';

export default function DiscardsPage() {
  const navigate = useNavigatePreservingParams();
  const { itemId } = useParams<MailsPathParams>();

  const handleClose = useCallback(() => {
    navigate(`/app/${inboxHelpers.APP_PATHS.DISCARDS}`);
  }, [navigate]);
  return (
    <>
      <TabsLayout>
        <ContentContainer height="100%">
          <DiscardsList />
        </ContentContainer>
      </TabsLayout>

      <DynamicSidebar disableSpacings open={!!itemId} handleClose={handleClose} title={`Item Details`}>
        {itemId && <MailInfo id={Number(itemId)} />}
      </DynamicSidebar>
    </>
  );
}
