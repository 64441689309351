import { Badge as MuiBadge, styled } from '@mui/material';
export const Badge = styled(MuiBadge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    borderRadius: theme.spacing(2),
    minWidth: theme.spacing(3),
    height: theme.spacing(3),
    position: 'relative',
    transform: 'none',
  },
}));
