import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { FlexBox, SimpleFAQAccordion } from '@usgm/shared-ui';
import { useCallback } from 'react';
import NotarizationStep from '../../../../../../onboarding/components/notarization/NotarizationStep';
import { StepContentContainer } from '../components/StepContentContainer';
import { useAccountNamesMap } from '../../../../../../onboarding/hooks/useAccountNamesMap';
import { onboardingUtils } from '@usgm/utils';
import StepNotRequiredMessage from '../components/StepNotRequiredMessage';
import { SETTINGS_PATHS } from '../../../../../paths';

function GetNotarized() {
  const navigate = useNavigate();
  const { accountNameUuid: accountId } = useParams<{ accountNameUuid: string }>();
  const { accountsList } = useAccountNamesMap();

  const accountData = accountsList?.find((account) => account.user.uuid === accountId);

  const onContinue = useCallback(() => {
    navigate(SETTINGS_PATHS.ACCOUNT_NAMES);
  }, [navigate]);

  if (
    accountData &&
    onboardingUtils.NOTARIZATION_CAN_BE_SKIPPED_RELATIONS.includes(accountData.relation.relationType)
  ) {
    return (
      <StepNotRequiredMessage
        actionName="get notarized"
        onContinue={onContinue}
        userId={accountId}
        relationType={accountData.relation.relationType}
      />
    );
  }
  return (
    <StepContentContainer flexDirection="column">
      <FlexBox gap={4}>
        <NotarizationStep onSuccess={onContinue} accountId={accountId} />
      </FlexBox>
      <Box mt={6}>
        <SimpleFAQAccordion items={onboardingUtils.FAQ_DATA} />
      </Box>
    </StepContentContainer>
  );
}

export default GetNotarized;
