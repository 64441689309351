import { styled } from '@mui/material';

export const StyledList = styled('ul')(({ theme }) => ({
  paddingLeft: 0,
  color: theme.customColors.dark[300],
  boxSizing: 'border-box',
  margin: 0,
  li: {
    display: 'flex',
    color: theme.customColors.dark[300],
    fontSize: '0.875rem',
    marginBottom: theme.spacing(1),
    'p:first-of-type:before': {
      content: '"\\2022"',
      display: 'inline-block',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    'span:last-of-type': {
      display: 'inline-block',
      maxWidth: `calc(100% - ${theme.spacing(17.75)})`,
      color: theme.palette.text.primary,
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
