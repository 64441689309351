import { ButtonProps, CircularProgress } from '@mui/material';
import { GetBillingPdfDetailsParams } from '@usgm/inbox-api-types';
import { Button } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import { MouseEvent, useEffect } from 'react';
import { billingsApi } from './../api';

export type DownloadBillingPdfProps = GetBillingPdfDetailsParams & {
  buttonProps?: ButtonProps;
  buttonLabel?: string;
};

function DownloadBillingPdf({
  itemId,
  type,
  invoiceId,
  buttonLabel = 'Download',
  buttonProps = { size: 'small', variant: 'outlined' },
}: DownloadBillingPdfProps) {
  const [trigger, { data, isLoading, isFetching }] = billingsApi.endpoints.getBillingPdfDetails.useLazyQuery();
  const handleDownload = (ev: MouseEvent) => {
    ev.stopPropagation();
    trigger({ itemId, type, invoiceId });
  };
  useEffect(() => {
    if (data?.downloadUrl) {
      inboxHelpers.downloadFile({ url: data.downloadUrl });
    }
  }, [data]);
  return (
    <Button {...buttonProps} onClick={handleDownload}>
      {isLoading || isFetching ? <CircularProgress size={16} /> : buttonLabel}
    </Button>
  );
}

export default DownloadBillingPdf;
