import { Box, Theme, styled } from '@mui/material';

export type ScrollableAreaProps = {
  thumbSize?: number;
  thumbColor?: string;
  hideOnBlur?: boolean;
  borderRadius?: number;
};

const visibleCss = {
  '&:active::-webkit-scrollbar-thumb': {
    visibility: 'visible',
  },
  '&:focus::-webkit-scrollbar-thumb': {
    visibility: 'visible',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    visibility: 'visible',
  },
};

export const createScrollingStyles = ({
  theme,
  thumbColor,
  thumbSize = 6,
  hideOnBlur = false,
  borderRadius,
}: ScrollableAreaProps & { theme: Theme }) => ({
  ...(hideOnBlur && visibleCss),
  '&::-webkit-scrollbar': {
    width: `${thumbSize}px`,
    height: `${thumbSize}px`,
    background: 'transparent',
  },
  '&::-webkit-scrollbar-button': {
    display: 'none',
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: `${borderRadius || thumbSize}px`,
    background: thumbColor || theme.palette.grey[500],
    visibility: hideOnBlur ? 'hidden' : 'visible',
  },
  '&::-webkit-scrollbar-track-piece': {
    background: 'transparent',
  },
});

export const ScrollableArea = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'thumbSize' && prop !== 'thumbColor' && prop !== 'hideOnBlur',
})<ScrollableAreaProps>(({ theme, thumbColor, thumbSize = 6, hideOnBlur = false, borderRadius }) => ({
  ...createScrollingStyles({ theme, thumbColor, thumbSize, hideOnBlur, borderRadius }),
  overflowY: 'auto',
}));
