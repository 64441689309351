import { Box, Table, TableBody, TableCell, TableRow, styled, useMediaQuery } from '@mui/material';
import FlexBox from '../../FlexBox';
import { ReactNode } from 'react';
import ItemImageWithWarning from '../../ItemImageWithWarning';

type TInfoList = {
  title: string;
  value: ReactNode;
};

const StyledTable = styled(Table)(({ theme }) => ({
  'tbody tr:last-child td': {
    border: 'none',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '0.875rem',
  padding: theme.spacing(0.75, 0),
  whiteSpace: 'break-spaces',
  width: '50%',
}));

const TableHeading = styled(TableCell)(({ theme }) => ({
  color: theme.customColors.dark[300],
  fontWeight: theme.typography.fontWeightMedium,
  padding: theme.spacing(0.75, 0),
  fontSize: '0.875rem',
  maxWidth: 120,
  paddingRight: theme.spacing(2),
  whiteSpace: 'break-spaces',
}));

const StyledImageContainer = styled(FlexBox)(({ theme }) => ({
  flexDirection: 'column',
  padding: theme.spacing(0, 2, 0.75, 0),
  maxWidth: '46%',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flex: '1 1 auto',
}));

export type EntityCellProps = {
  hasIssue?: boolean;
  imageUrl?: string;
  issueTooltip?: string;
  infoList: TInfoList[];
  hideHeadingBelowPx?: number;
  headingWidth?: string;
  badgeContent?: ReactNode;
};

export function EntityCell({
  imageUrl,
  hasIssue,
  issueTooltip,
  infoList,
  hideHeadingBelowPx = 374.95,
  headingWidth = '50%',
  badgeContent,
}: EntityCellProps) {
  const extraSmallScreen = useMediaQuery(() => `(max-width: ${hideHeadingBelowPx}px)`);
  return (
    <Box pb={1} display="flex" maxWidth="100%" maxHeight="100%">
      {(imageUrl || badgeContent) && (
        <StyledImageContainer>
          {imageUrl && <ItemImageWithWarning imageUrl={imageUrl} showWarning={hasIssue} tooltipText={issueTooltip} />}
          {badgeContent ? badgeContent : null}
        </StyledImageContainer>
      )}
      <Box flex={1}>
        <StyledTable>
          <TableBody>
            {infoList.map((item) => (
              <TableRow key={item.title}>
                {!extraSmallScreen && <TableHeading width={headingWidth}>{item.title}</TableHeading>}
                <StyledTableCell>
                  <Box display="inline-flex" component="span">
                    {item.value}
                  </Box>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </Box>
    </Box>
  );
}
