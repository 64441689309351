import { BaseScan } from '@usgm/inbox-api-types';
import ScanInfoCell from '../tableCells/ScanInfoCell';
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { FlexBox } from '@usgm/shared-ui';

export const getMobileColumns = (isPending?: boolean): GridColDef[] => {
  return [
    ...(!isPending
      ? [
          {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            renderCell: (params: GridRenderCellParams<BaseScan>) => {
              return (
                <FlexBox flexDirection="column">
                  {GRID_CHECKBOX_SELECTION_COL_DEF.renderCell && GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(params)}
                </FlexBox>
              );
            },
          },
        ]
      : []),
    {
      field: 'scanInfo',
      headerName: 'Info',
      flex: 1,
      renderCell: (params: GridRenderCellParams<BaseScan>) => {
        return <ScanInfoCell data={params.row} />;
      },
    },
  ];
};
