import { MailType } from './types/MailType';

export const MAILS_FILTERS = ['folder', 'status', 'isRead'] as const;

export type TMailFilters = (typeof MAILS_FILTERS)[number];

export const MAIL_ITEM_TYPE_MAPPER: { [key in MailType]: string } = {
  [MailType.Catalog]: 'Catalog',
  [MailType.Letter]: 'Letter',
  [MailType.LargeLetter]: 'Large Letter',
  [MailType.Magazine]: 'Magazine',
  [MailType.Distribution]: 'Distribution',
  [MailType.Fulfillment]: 'Fulfillment',
  [MailType.Inventory]: 'Inventory',
  [MailType.Package]: 'Package',
  [MailType.Softpak]: 'Softpak',
};

export const UNBOXING_MAIL_TYPES = [MailType.Softpak, MailType.Package] as const;
export const SCAN_MAIL_TYPES = [MailType.Catalog, MailType.Letter, MailType.LargeLetter, MailType.Magazine] as const;

export type UnboxingMailType = (typeof UNBOXING_MAIL_TYPES)[number];
export type ScanMailType = (typeof SCAN_MAIL_TYPES)[number];

export const isUnboxingMailType = (mailType: MailType): boolean =>
  UNBOXING_MAIL_TYPES.includes(mailType as UnboxingMailType);
