import * as React from 'react';
import { Typography, useTheme } from '@mui/material';
import { PopoverMenu, TextButton, useAnchorEl } from '@usgm/shared-ui';
import Email from '../assets/icons/email.svg?react';
import Chat from '../assets/icons/chat.svg?react';
import Phone from '../assets/icons/phone.svg?react';

const helpItems = [
  {
    label: (
      <Typography>
        Email us:{' '}
        <Typography component="span" color={(theme) => theme.palette.primary.main}>
          support@usglobalmail.com
        </Typography>
      </Typography>
    ),
    icon: <Email />,
    clickHandler: () => {
      window.open('mailto:support@usglobalmail.com?subject=Help With Uploading ID');
    },
  },
  {
    label: <Typography>Chat with our Support Team</Typography>,
    clickHandler: () => {
      if (typeof window.zE !== 'undefined') {
        window.zE.activate();
      }
    },
    icon: <Chat />,
  },
  {
    label: (
      <Typography>
        Call{' '}
        <Typography component="span" color={(theme) => theme.palette.primary.main}>
          1-281-596-8965
        </Typography>
      </Typography>
    ),
    icon: <Phone />,
    clickHandler: () => {
      window.open('tel:1-281-596-8965');
    },
  },
];

type HelpPopoverProps = {
  neutralMode?: boolean;
};
export default function HelpPopover({ neutralMode }: HelpPopoverProps) {
  const { anchorEl, handleClick, handleClose, open } = useAnchorEl<HTMLElement>();
  const theme = useTheme();

  return (
    <>
      <TextButton
        sx={
          neutralMode
            ? {}
            : {
                fontSize: '0.875rem',
                color: theme.palette.success.dark,
              }
        }
        onClick={handleClick}
      >
        Get Help
      </TextButton>
      <PopoverMenu anchorEl={anchorEl} handleClose={handleClose} open={open} itemsList={helpItems} />
    </>
  );
}
