import { ScanCategory } from '@usgm/inbox-api-types';
import { useCallback } from 'react';

import { Box, Typography } from '@mui/material';

import { Toast } from '@usgm/shared-ui';

import { TMailActionRequestsSchemaType } from './mailActionRequestSchema';
import RequestedItems from './RequestedItem';

import { useMailsActionRequestMutation } from '../../api';
import { selectSelectedMails } from '../../mailsSlice';
import { MailActionRequest } from '../../paths';

import { useAppSelector } from '../../../../../../store';
import { ContentContainer } from '../../../../components/DynamicSidebar/ContentContainer';
import RequestSuccessMessage from './RequestSuccessMessage';

type MailsOpenRequestProps = {
  allowExpedite?: boolean;
};

function MailsOpenRequest({ allowExpedite }: MailsOpenRequestProps) {
  const selectedMails = useAppSelector(selectSelectedMails);

  const [openMailsRequestMutation, openMailsRequestMutationQuery] = useMailsActionRequestMutation();

  const title = `${selectedMails.length} Item${selectedMails.length > 1 ? 's' : ''}`;

  const onFormSubmit = useCallback(
    (data: TMailActionRequestsSchemaType) => {
      openMailsRequestMutation(
        data.requestedItems.map((request) => ({
          id: request.mailId,
          instruction: request.instruction,
          isExpedite: request.isExpedite,
          categoryType: ScanCategory.UnboxingRequest,
        })),
      );
    },
    [openMailsRequestMutation],
  );

  if (openMailsRequestMutationQuery.isSuccess) {
    return <RequestSuccessMessage actionRequest={MailActionRequest.Open} />;
  }

  return (
    <Box minHeight="100%">
      <ContentContainer>
        <Typography variant="h5">{title}</Typography>
        <Typography mb={6}>
          We will open these items and take a photo of the contents. You will receive an email with an image of the
          contents, and the item will be placed back in your inbox. There is a $5 charge for your request.
        </Typography>
        <RequestedItems
          activeScanBundle={null}
          isSubmitting={openMailsRequestMutationQuery.isLoading}
          onFormSubmit={onFormSubmit}
          actionRequest={MailActionRequest.Open}
          allowExpedite={allowExpedite}
        />
      </ContentContainer>

      {openMailsRequestMutationQuery.error && (
        <Toast title={openMailsRequestMutationQuery.error.message} severity="error" />
      )}
    </Box>
  );
}

export default MailsOpenRequest;
