import { Typography } from '@mui/material';
import { DetailsHeader } from './styled/DetailsHeader';
import { StyledList } from './styled/StyledList';
import { DetailLabel } from './styled/DetailLabel';
import { DtoShipInfo } from '@usgm/inbox-api-types';

function ShipmentSchedule({ shipmentInfo }: { shipmentInfo: DtoShipInfo }) {
  const businessHours = shipmentInfo.shippingInfo.businessHours.split(':');

  return (
    <>
      <DetailsHeader>Shipment schedule</DetailsHeader>
      <StyledList>
        <li>
          <DetailLabel>{businessHours[0]}:</DetailLabel>
          <span>{businessHours[1]}</span>
        </li>
        {shipmentInfo.shippingInfo.shipmentSchedule.map((schedule, index) => {
          const scheduleParts = schedule.split(':');
          return (
            <li key={index}>
              <DetailLabel>{scheduleParts[0]}:</DetailLabel>
              <span>{scheduleParts[1]}</span>
            </li>
          );
        })}
      </StyledList>
      <Typography mt={2}>All time is US Central (GMT-6:00)</Typography>
    </>
  );
}

export default ShipmentSchedule;
