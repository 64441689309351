import CheckIcon from '@mui/icons-material/Check';
import { Box, List, ListItemIcon, ListItemText, styled, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { type DtoBankAccount } from '@usgm/inbox-api-types';

import { inboxHelpers } from '@usgm/utils';
import { FlexBox, IconLinkButton, InlineIconButton, Preloader } from '@usgm/shared-ui';

import { useGetBankAccountsQuery } from '../../../../../../../inboxAccountsApi';
import PenIcon from '../../../../../../../assets/icons/pen.svg?react';
import { StyledListItemButton } from '../styled/StyledListItemButton';

export type BankAccountsSelectionListProps = {
  selectedId?: string;
  onAddClick?: () => void;
  onSelect: (account: DtoBankAccount) => void;
  onManageClick?: () => void;
};

const NoBankAccountsText = styled(Typography)(({ theme }) => ({
  color: theme.customColors.dark[400],
  fontWeight: theme.typography.fontWeightBold,
  textAlign: 'center',
}));

function BankAccountsSelectionList({
  selectedId,
  onSelect,
  onAddClick,
  onManageClick,
}: BankAccountsSelectionListProps) {
  const { data: bankAccounts, isLoading, isSuccess } = useGetBankAccountsQuery();

  const handleSelect = (account: DtoBankAccount) => () => {
    onSelect(account);
  };

  if (isLoading) {
    return <Preloader minHeight={200} />;
  }

  if (!bankAccounts?.list?.length && isSuccess) {
    return (
      <FlexBox flexDirection="column" gap={2}>
        <NoBankAccountsText>
          You don't have bank account details added yet. Please add one to continue.
        </NoBankAccountsText>
        {onAddClick && (
          <IconLinkButton onClick={onAddClick} icon={<AddIcon fontSize="small" />} value="Add bank account" />
        )}
      </FlexBox>
    );
  }

  return (
    <>
      <List>
        {bankAccounts?.list?.map((account: DtoBankAccount) => (
          <StyledListItemButton key={account.uuid} onClick={handleSelect(account)}>
            <ListItemText primary={account.accountNumber} secondary={inboxHelpers.stringifyBankAccount(account)} />
            <ListItemIcon>{selectedId === account.uuid ? <CheckIcon color="success" /> : null}</ListItemIcon>
          </StyledListItemButton>
        ))}
      </List>
      {onManageClick && bankAccounts?.list?.length && (
        <Box mt={2} mb={4}>
          <InlineIconButton
            onClick={onManageClick}
            icon={<PenIcon fontSize="small" />}
            label="Manage bank accounts"
            iconBackgroundColor="transparent"
          />
        </Box>
      )}
    </>
  );
}

export default BankAccountsSelectionList;
