import { DtoShipment } from '@usgm/inbox-api-types';
import DetailsTable, { DetailsTableProps } from '../../../components/DetailsTable';
import { useMemo } from 'react';
import { inboxHelpers } from '@usgm/utils';
import { cellHelpers } from '@usgm/shared-ui';
import { useTheme } from '@mui/material';

export type ShipmentDetailsProps = {
  data: DtoShipment;
};
function ShipmentDetails({ data }: ShipmentDetailsProps) {
  const theme = useTheme();
  const list = useMemo(() => {
    const listItems: DetailsTableProps['items'] = [
      {
        title: 'Address',
        value: inboxHelpers.stringifyAddress({ addr: data.destination }),
      },
      {
        title: 'Service',
        value: data.serviceName,
      },
      {
        title: 'Scheduled Date',
        value: cellHelpers.formatDateString(data.requestedShipDate),
      },
      {
        title: 'Declare Value',
        value: data.declaredValue,
      },
      {
        title: 'Instructions',
        value: data.instructions,
      },
    ];

    if (data.rejectReason) {
      listItems.push({
        title: 'Reject Reason',
        value: `${data.categoryName} rejected: ${data.rejectReason}`,
        color: theme.palette.error.main,
      });
    }
    return listItems.filter((item) => !!item.value);
  }, [
    data.categoryName,
    data.declaredValue,
    data.destination,
    data.instructions,
    data.rejectReason,
    data.requestedShipDate,
    data.serviceName,
    theme.palette.error.main,
  ]);

  return <DetailsTable items={list} />;
}

export default ShipmentDetails;
