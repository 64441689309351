import { EntityState, PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { DtoPlan, PlanTerm } from '@usgm/inbox-api-types';
import { inboxHelpers } from '@usgm/utils';
import { RootState } from '../../store';

type TState = EntityState<DtoPlan, string> & {
  term: PlanTerm;
  pickedPlan: DtoPlan | null;
};

const FEATURE_NAME = 'PLAN_PICKER';
const plansAdapter = createEntityAdapter<DtoPlan>();
const initialState: TState = plansAdapter.getInitialState({
  term: PlanTerm.Annually,
  pickedPlan: inboxHelpers.getStorageManager().getItem('selectedPlan') || null,
});

export const planPickerSlice = createSlice({
  name: FEATURE_NAME,
  initialState,
  reducers: {
    setPlans: plansAdapter.setAll,
    setTerm: (state, action: PayloadAction<PlanTerm>) => {
      state.term = action.payload;
    },
    setPickedPlan: (state, action: PayloadAction<DtoPlan>) => {
      state.pickedPlan = action.payload;
    },
  },
});

export const { setTerm } = planPickerSlice.actions;

// selectors

const selectState = (state: RootState) => state[FEATURE_NAME];
export const plansSelectors = plansAdapter.getSelectors(selectState);

export const selectPlanTerm = (state: RootState) => selectState(state).term;
export const selectPickedPlan = (state: RootState) => selectState(state).pickedPlan;
