import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { DynamicSidebarPath, useDynamicSidebar } from '../../../components/DynamicSidebar/useDynamicSidebar';
import HeaderActionButton, { THeaderActionButton } from '../../../components/HeaderActions/HeaderActionButton';
import { DynamicSidebar } from '../../../components/DynamicSidebar';
import { HelpSidebarContent } from './HelpSidebarContent';
import { useAuth } from '../../../../auth/hooks/useAuth';

export function HelpAction({ asNavigationItem = false }: THeaderActionButton) {
  const { user } = useAuth();
  const { isSelected, toggleSidebar } = useDynamicSidebar(DynamicSidebarPath.Help);
  const handleClose = () => toggleSidebar();
  return (
    <>
      <HeaderActionButton
        asNavigationItem={asNavigationItem}
        icon={<HelpOutlineIcon />}
        label="Need Help?"
        selected={isSelected}
        onClick={toggleSidebar}
      />

      <DynamicSidebar
        open={isSelected}
        handleClose={handleClose}
        path={DynamicSidebarPath.Announcements}
        title="Need Help?"
      >
        {user && <HelpSidebarContent userUUID={user.userUUID} handleCloseSidebar={toggleSidebar} />}
      </DynamicSidebar>
    </>
  );
}

export default HelpAction;
