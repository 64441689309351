import { useEffect, useRef, useCallback } from 'react';

interface UseSubscribeToDocumentEventParams {
  eventName: string;
  handler: (event: Event) => void;
  options?: boolean | AddEventListenerOptions;
}

export function useSubscribeToDocumentEvent({
  eventName,
  handler,
  options = false,
}: UseSubscribeToDocumentEventParams) {
  const savedHandler = useRef<(event: Event) => void>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    function eventListener(event: Event) {
      if (savedHandler.current) {
        savedHandler.current(event);
      }
    }

    document.addEventListener(eventName, eventListener, options);

    return () => {
      document.removeEventListener(eventName, eventListener, options);
    };
  }, [eventName, options]);

  const unsubscribe = useCallback(() => {
    if (savedHandler.current) {
      document.removeEventListener(eventName, savedHandler.current as EventListenerOrEventListenerObject, options);
    }
  }, [eventName, options]);

  return unsubscribe;
}
