import { useGetPlanDetailsQuery } from '../../../../../../../../api/paymentApi';
import { useActiveSubscription } from '../../../../../../../../hooks/useActiveSubscription';

// @TODO: This hook doesn't belong here, it should be moved to the payment feature

export function useActiveSubscriptionPlanDetails() {
  const { activeSubscription } = useActiveSubscription();
  const activeSubscriptionPlanQuery = useGetPlanDetailsQuery(
    { planId: activeSubscription?.plan?.id || '' },
    { skip: !activeSubscription },
  );

  return {
    activeSubscriptionPlanQuery,
    activeSubscription,
  };
}
