import { PICKUP_SERVICE_TYPE } from '@inbox/constants';
import { MailType, Rate, US_COUNTRY_NAMES } from '@usgm/inbox-api-types';

// @TODO: we need to implement this in the backend postmen api /rates endpoint
export const NON_TRACKABLE_SERVICE_TYPES = [`endicia_first_class_mail_international`, `endicia_first_class_mail`];
export const NON_TRACKABLE_MAIL_TYPE_USPS_DOMESTIC = ['LARGELETTER', 'LETTER'];
export const NON_TRACKABLE_MAIL_TYPE_USPS_INTERNATIONAL = ['LARGELETTER', 'LETTER', 'PACKAGE'];

type IsTrackableParams = {
  country: string;
  mailTypes: MailType[];
  shipmentRate: Rate;
};

export function isTrackableRate({ country, mailTypes, shipmentRate }: IsTrackableParams) {
  if (!NON_TRACKABLE_SERVICE_TYPES.includes(shipmentRate.serviceType)) {
    return true;
  }
  if (country === '' || shipmentRate.serviceType === PICKUP_SERVICE_TYPE) {
    return false;
  }

  const noneTrackableMailTypes = US_COUNTRY_NAMES.includes(country)
    ? NON_TRACKABLE_MAIL_TYPE_USPS_DOMESTIC
    : NON_TRACKABLE_MAIL_TYPE_USPS_INTERNATIONAL;

  return !mailTypes.every((mailType) => noneTrackableMailTypes.includes(mailType));
}
