export const COST_OF_EXTRA_ACCOUNT_NAME_PER_MONTH = 5;

export const MAX_ACCOUNTS_LIMIT = 20;

export const MAX_FILE_UPLOAD_SIZE = 10 * 1024 * 1024;
export const DEFAULT_FILE_ACCEPT = {
  'image/jpeg': ['.jpg'],
  'application/pdf': ['.pdf'],
  'image/tiff': ['.tif'],
  'image/tif': ['.tif'],
  'image/png': ['.png'],
};

export const GENERIC_ERROR_MESSAGE = 'Something went wrong. Please try again later.';

export const DEFAULT_PAGINATION_LIMIT = 10;

export const MAILS_DISCARD_DELETION_DAYS_COUNT = 7;

export const OUTDATE_MAIL_SCAN_IN_MONTHS = 6;
