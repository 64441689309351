import { FlexBox } from '@usgm/shared-ui';
import { DetailRow } from './styled/DetailRow';
import { Typography, useTheme } from '@mui/material';

export type SummaryRowProps = {
  label: string;
  summary: string;
  makeBold?: boolean;
};
function SummaryRow({ label, summary, makeBold }: SummaryRowProps) {
  const theme = useTheme();
  const fontWeight = makeBold ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular;

  return (
    <DetailRow justifyContent="flex-end">
      <FlexBox>
        <Typography fontWeight={fontWeight} mr={4}>
          {label}
        </Typography>
        <Typography fontWeight={fontWeight}>{summary}</Typography>
      </FlexBox>
    </DetailRow>
  );
}

export default SummaryRow;
