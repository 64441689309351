import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid';
import { ToRelativeUnit } from 'luxon/src/datetime';
import { TextCell } from './TextCell';
import { Measurement } from '@usgm/inbox-api-types';
import { DateTime } from 'luxon';

export function renderTextCell<T extends GridValidRowModel>(params: GridRenderCellParams<T>) {
  return <TextCell>{params.value || 'N/A'}</TextCell>;
}

export function formatMeasurement<T extends Measurement>(value: T) {
  return `${value.width} x ${value.length} x ${value.height} in`;
}

export function formatWeight(value: number | string) {
  return `${value} lbs`;
}

export function formatDateString(value?: string, includeTime?: boolean) {
  if (!value) {
    return 'N/A';
  }
  const date = DateTime.fromISO(value);

  if (date.isValid === false) {
    return 'N/A';
  }
  return date.toLocaleString(includeTime ? DateTime.DATETIME_MED : DateTime.DATE_MED);
}

export function formatDateTime(dateTime?: DateTime, includeTime?: boolean) {
  if (!dateTime?.isValid) {
    return 'N/A';
  }
  return dateTime.toLocaleString(includeTime ? DateTime.DATETIME_MED : DateTime.DATE_MED);
}

export function relativeDate({ value, unit }: { value?: string; unit?: ToRelativeUnit }) {
  if (!value) {
    return 'N/A';
  }
  const date = DateTime.fromISO(value);

  if (date.isValid === false) {
    return 'N/A';
  }

  return date.toRelativeCalendar({ unit });
}

export function getImageRowHeight(isMobile: boolean) {
  return isMobile ? 'auto' : 192;
}
