import { USGM_NOTARIZATION_FEE } from '@inbox/constants';
import { Alert, Box, styled, Typography } from '@mui/material';
import { FlexBox, useMobileMode } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import { useState } from 'react';
import { PopupModal } from 'react-calendly';
import { ENVIRONMENT } from '../../../../../../env';
import { useAppSelector } from '../../../../../../store';
import { selectRejectedNotarizations } from '../../../../../auth/authSlice';
import { useAuth } from '../../../../../auth/hooks/useAuth';
import NotaryActions from './NotaryActions';

const ActionsContainer = styled(FlexBox)(({ theme }) => ({
  gap: theme.spacing(1),
  flexDirection: 'column',
  alignItems: 'flex-end',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    alignItems: 'flex-start',
  },
}));

export type NotarizationStepProps = {
  accountId?: string;
  onSuccess?: () => void;
};

function NotarizationStep({ accountId, onSuccess }: NotarizationStepProps) {
  const isMobile = useMobileMode();
  const { user } = useAuth();
  const rejectedNotarizations = useAppSelector((state) => selectRejectedNotarizations(state, null));
  const appRootElement = document.getElementById('root');
  const [calendlyPopupOpen, setCalendlyPopupOpen] = useState(false);

  const rejectReasons = accountId ? rejectedNotarizations?.filter((it) => it.id === accountId) : rejectedNotarizations;

  return (
    <FlexBox width="100%" flexDirection={isMobile ? 'column' : 'row'} gap={2}>
      <Box>
        <Typography mb={0.5} fontWeight={600}>
          Schedule your online notary
        </Typography>
        <Typography>
          Our online notary service offers a quick and hassle-free appointment to complete the process in under 5
          minutes. Cost: {inboxHelpers.formatPrice(USGM_NOTARIZATION_FEE)}
        </Typography>
        {rejectReasons?.map((rejectedNotarization) => (
          <Alert sx={{ marginTop: 2 }} severity="error">
            <Typography color="error">Notarized form of {rejectedNotarization.name} is rejected</Typography>
            <Typography>Note: {rejectedNotarization.reason}</Typography>
          </Alert>
        ))}
      </Box>
      <ActionsContainer>
        {user && appRootElement && (
          <PopupModal
            url={ENVIRONMENT.CALENDLY_URL}
            pageSettings={{
              hideEventTypeDetails: false,
            }}
            prefill={{
              name: user.name,
              email: user.email,
            }}
            onModalClose={() => setCalendlyPopupOpen(false)}
            open={calendlyPopupOpen}
            rootElement={appRootElement}
          />
        )}
        <NotaryActions onSuccess={onSuccess} accountId={accountId} />
      </ActionsContainer>
    </FlexBox>
  );
}

export default NotarizationStep;
