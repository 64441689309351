import { alpha, darken, styled } from '@mui/material';

import { DataGrid as MuiDataGrid, DataGridProps as MuiDataGridProps, gridClasses } from '@mui/x-data-grid';
import { createScrollingStyles } from '../../ScrollableArea';

export const DATA_TABLE_CLASSES = {
  UNREAD_ROW: 'unread-entity-row',
  FLOATING_ACTIONS: 'floating-actions',
} as const;

export const NO_ROWS_CLASS = 'no-rows-found';

type StyledProps = {
  hideColumns?: boolean;
};

export type DataGridProps = MuiDataGridProps & StyledProps;

export const DataGrid = styled(MuiDataGrid, { shouldForwardProp: (prop) => prop !== 'hideColumns' })<StyledProps>(
  ({ theme, hideColumns }) => ({
    [`. ${gridClasses.columnSeparator}`]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiDataGrid-overlayWrapper': {
        top: 0,
      },
    },
    // Placeholder styles for no rows found
    [`&.${NO_ROWS_CLASS}`]: {
      '& .MuiDataGrid-main': {
        display: 'flex',
        flexGrow: 1,
      },
      [`& .${gridClasses.virtualScroller}`]: {
        flexGrow: 1,
        alignSelf: 'stretch',
        position: 'absolute',
        '& .no-row-placeholder': {
          position: 'absolute',
          width: '100%',
        },
        [`& .${gridClasses.overlayWrapper}`]: {
          position: 'initial',
        },
      },
    },
    [`& .${gridClasses.columnHeaders}`]: {
      backgroundColor: theme.palette.background.base,
      borderRadius: 0,
      padding: theme.spacing(0, 3.25),
      display: hideColumns ? 'none' : 'flex',
      maxWidth: '100%',
      overflow: 'hidden',
      [`& .${gridClasses.columnHeader}:focus-within`]: {
        outline: 'none',
      },
      '& > [role="row"]': {
        backgroundColor: theme.palette.background.base,
      },
      [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(0, 2),
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    [`& .${gridClasses.row}`]: {
      borderRadius: theme.shape.borderRadius * 1,
      borderBottom: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.paper,
      width: `calc(100% - ${theme.spacing(6.5)})`,
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
      overflow: 'hidden',
      [theme.breakpoints.down('lg')]: {
        width: `calc(100% - ${theme.spacing(4)})`,
      },
      [theme.breakpoints.down('sm')]: {
        borderBottom: 'none',
        width: `100%`,
      },

      [`& .${gridClasses.cell}:nth-of-type(2)`]: {
        [theme.breakpoints.down('sm')]: {
          paddingLeft: theme.spacing(2.5),
        },
      },
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
      [`&.Mui-selected, &.${DATA_TABLE_CLASSES.UNREAD_ROW}`]: {
        '&:after': {
          position: 'absolute',
          content: "''",
          display: 'block',
          right: 0,
          top: 0,
          height: '100%',
          width: theme.spacing(0.5),
          backgroundColor: theme.palette.primary.main,
        },
      },
      [`&.${DATA_TABLE_CLASSES.UNREAD_ROW}`]: {
        // @TODO: correct colors after getting answer regarding colors consolidation

        backgroundColor: theme.palette.mode === 'dark' ? alpha('#FFB422', 0.4) : alpha('#FFB422', 0.1),
        '& .cell-text': {
          fontWeight: theme.typography.fontWeightBold,
        },
        '&:hover': {
          backgroundColor: theme.palette.mode === 'dark' ? darken('#FFB422', 0.2) : alpha('#FFB422', 0.2),
        },
        '&:after': {
          backgroundColor: '#FFB422',
        },
      },
    },

    [`& .${gridClasses.columnHeaderTitle} , & .${gridClasses.columnHeaderTitleContainerContent} p`]: {
      fontSize: '0.875rem',
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.customColors.dark[400],
    },
    [`& .${DATA_TABLE_CLASSES.FLOATING_ACTIONS}`]: {
      visibility: 'hidden',
    },
    [`& .${gridClasses.row}:hover`]: {
      backgroundColor: 'transparent',
      [`& .${DATA_TABLE_CLASSES.FLOATING_ACTIONS}`]: {
        visibility: 'visible',
      },
      [theme.breakpoints.down('sm')]: {
        backgroundColor: theme.palette.background.paper,
      },
    },
    [`&.${gridClasses.root}`]: {
      border: 'none',
      borderRadius: 0,
    },
    [`& .${gridClasses.cell}`]: {
      border: 'none',
      fontWeight: theme.typography.fontWeightBold,
      paddingTop: theme.spacing(1.5),
      whiteSpace: 'normal',
      '&:focus, &:focus-within': {
        outline: 'none',
      },
      '& a': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        fontWeight: theme.typography.fontWeightMedium,
      },
      [`&.${gridClasses['cell--flex']}`]: {
        alignItems: 'flex-start',
      },
    },
    [`& .${gridClasses.scrollbar}`]: {
      ...createScrollingStyles({ theme }),
    },
    [`& .${gridClasses.virtualScroller}`]: {
      borderRadius: 0,
    },
  }),
);
