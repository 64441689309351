import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { BaseInvoice, DtoInvoice } from '@usgm/inbox-api-types';
import { TextCell, cellHelpers, dataTableConfig } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import { Link, useParams } from 'react-router-dom';
import EntityStatus from '../components/EntityStatus';
import DownloadBillingPdf from '../components/DownloadBillingPdf';
import { BillingPathParams, DEFAULT_BILLING_TAB, createBillingsPath } from '../paths';

import InvoiceInfoCell from '../components/gridCells/InvoiceInfoCell';

export function SingleBillingItemLink({ id, label }: { id: DtoInvoice['vendorId']; label?: string }) {
  const { tab = DEFAULT_BILLING_TAB } = useParams<BillingPathParams>();
  return <Link to={createBillingsPath({ tab, itemId: id.toString() })}>{label || id}</Link>;
}

export const INVOICES_COLUMNS: GridColDef<DtoInvoice>[] = [
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'vendorId',
    headerName: 'Invoice Number',
    renderCell: (params) => {
      return (
        <TextCell>
          <SingleBillingItemLink label={`#${params.row.vendorId}`} id={params.row.vendorId} />
        </TextCell>
      );
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'status',
    headerName: 'Status',
    renderCell: (params) => {
      return (
        <TextCell>
          <EntityStatus label={params.row.statusLabel} statusCategory={params.row.statusCategory} />
        </TextCell>
      );
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'amountTotal',
    headerName: 'Total',
    valueGetter: inboxHelpers.formatPrice,
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'amountPaid',
    headerName: 'Amount Paid',
    valueGetter: inboxHelpers.formatPrice,
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'dueDate',
    headerName: 'Due Date',
    valueGetter: (value) => cellHelpers.formatDateString(value),
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'paidDate',
    headerName: 'Paid At',
    valueGetter: (value) => cellHelpers.formatDateString(value),
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'actions',
    minWidth: 200,
    headerName: '',
    renderCell: (params) => {
      return (
        <TextCell>
          <DownloadBillingPdf type="invoice" itemId={params.row.vendorId} />
        </TextCell>
      );
    },
  },
];

export const INVOICES_MOBILE_COLUMNS: GridColDef<DtoInvoice>[] = [
  {
    field: 'invoiceInfo',
    headerName: 'info',
    flex: 1,
    renderCell: (params: GridRenderCellParams<BaseInvoice>) => {
      return <InvoiceInfoCell data={params.row} />;
    },
  },
];
