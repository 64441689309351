import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button } from '@mui/material';
import HelpPopover from '../../../../../components/HelpPopover';
import { ONBOARDING_PATHS } from '../../../paths';
import React from 'react';
import { StepText } from '../../VerifyAccountSteps';
import { StepItemProps } from '../StepItemProps';
import { useNavigate } from 'react-router-dom';

export function AddNamesStepItemActions({ completed }: StepItemProps) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(ONBOARDING_PATHS.ADD_NAMES);
  };

  return (
    <>
      <Button onClick={onClick} endIcon={<ArrowForwardIosIcon />} variant="contained" color="primary">
        {completed ? 'Edit' : 'Add'} Names
      </Button>
      <Box mt={1}>
        <StepText mr={1} component="span">
          Questions?
        </StepText>
        <HelpPopover neutralMode />
      </Box>
    </>
  );
}

export default React.memo(AddNamesStepItemActions);
