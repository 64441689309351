import { type Theme } from '@mui/material';

export const PASSWORD_REQUIREMENTS = {
  MIN_LENGTH: 8,
  RULES: [/.*[a-z].*/, /.*[A-Z].*/, /.*[0-9].*/, /.*[!@#$%^&*].*/],
  MESSAGE: `Your password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one of the following symbols: !@#$%^&*.`,
  LABELS: [
    { label: 'Very Weak', getColor: (theme: Theme) => theme.palette.error.main },
    { label: 'Weak', getColor: (theme: Theme) => theme.palette.warning.main },
    { label: 'Good', getColor: (theme: Theme) => theme.palette.info.main },
    { label: 'Strong', getColor: (theme: Theme) => theme.palette.success.main },
    { label: 'Very Strong', getColor: (theme: Theme) => theme.palette.success.dark },
  ],
};
