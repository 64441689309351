import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export enum DynamicSidebarPath {
  Help = 'help',
  ReferAFriend = 'refer-a-friend',
  Announcements = 'announcements',
  ChangeAddress = 'change-address',
  SingleMail = 'single-mail',
  SingleScan = 'single-scan',
  Filters = 'filters',
}

export function useDynamicSidebar(path: DynamicSidebarPath, isOpen = false) {
  const [searchParams, setSearchParams] = useSearchParams(isOpen ? { activeSidebar: path } : undefined);
  const activeSidebar = searchParams.get('activeSidebar');
  const toggleSidebar = useCallback(() => {
    if (activeSidebar !== path) {
      searchParams.set('activeSidebar', path);
    } else {
      searchParams.delete('activeSidebar');
    }
    setSearchParams(searchParams);
  }, [activeSidebar, path, searchParams, setSearchParams]);
  const isSelected = activeSidebar === path;

  return {
    isSelected,
    toggleSidebar,
  };
}
