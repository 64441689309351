import MoreIcon from '@mui/icons-material/MoreVert';
import { Box, BoxProps, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, styled } from '@mui/material';
import { ReactNode } from 'react';
import { useAnchorEl } from '../../hooks';

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    borderRadius: theme.shape.borderRadius * 2,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.customColors.menuBorder,
  },
  '& .MuiList-root': {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

type StyledMenuItemProps = {
  dangerous?: boolean;
};

const StyledMenuItem = styled(MenuItem)<StyledMenuItemProps>(({ theme, dangerous }) => ({
  height: theme.spacing(5),
  color: dangerous ? theme.palette.error.main : 'unset',
}));

const StyledMoreButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5, 0.75),
}));

export type MoreMenuProps = {
  containerProps?: BoxProps;
  hostData?: {
    rowId?: string;
  };
  items: Array<{
    id?: string;
    label: string;
    onClick: (rowId?: string) => void;
    iconComponent?: ReactNode;
    disabled?: boolean;
    dangerous?: boolean;
  }>;
};

export function MoreMenu({ items, hostData, containerProps }: MoreMenuProps) {
  const { anchorEl, handleClose, open, handleClick } = useAnchorEl<HTMLButtonElement>();

  const handleItemClick = (item: MoreMenuProps['items'][number]) => {
    item.onClick(hostData?.rowId);
    handleClose();
  };
  return (
    <Box {...containerProps}>
      <StyledMoreButton onClick={handleClick} edge="end" size="small" aria-label="open more actions">
        <MoreIcon />
      </StyledMoreButton>
      <StyledMenu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {items.map((item, index) => (
          <StyledMenuItem
            key={`more-menu-item-${index}`}
            onClick={() => handleItemClick(item)}
            dangerous={item.dangerous}
          >
            {item.iconComponent && <ListItemIcon>{item.iconComponent}</ListItemIcon>}
            <ListItemText>{item.label}</ListItemText>
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </Box>
  );
}
