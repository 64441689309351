/* eslint-disable @typescript-eslint/no-explicit-any */
import { CAPTCHA_VERSION_V2, CAPTCHA_VERSION_V3, CaptchaType } from '@inbox/constants';
import { useCallback, useId, useState } from 'react';
import { CAPTCHA_VERIFICATION_FAILED_STATUS } from '../captcha/config';
import { useRecaptchaContext } from '../captcha/useRecaptchaContext';

type ExecutionState<TData = never> = {
  isLoading: boolean;
  error?: any;
  data?: TData | null;
  token?: string | null;
  version?: number;
};

const DEFAULT_EXECUTION_STATE: ExecutionState = {
  isLoading: false,
};

export function useRecaptchaRequest<TData, TResponse = never>({
  action,
  apiRequest,
}: {
  action: CaptchaType;
  apiRequest: any;
}) {
  const { executeRecaptchaV3, executeRecaptchaV2, isReadyToUse } = useRecaptchaContext();
  const [executionState, setExecutionState] = useState<ExecutionState<TData>>(DEFAULT_EXECUTION_STATE);
  const requestId = useId();

  const makeRequest = useCallback(
    (args?: unknown): Promise<TResponse> => {
      return new Promise((resolve) => {
        setExecutionState((prev) => ({
          ...prev,
          isLoading: true,
          error: null,
          data: null,
        }));

        const resolveRequest = ({
          result,
          error,
          token,
          version,
        }: {
          result?: any;
          error?: any;
          token: string | null;
          version: number;
        }) => {
          setExecutionState((prev) => ({
            ...prev,
            isLoading: false,
            ...(error ? { error } : {}),
            ...(!error ? { data: result?.data } : {}),
            token,
            version,
          }));
          resolve(result);
        };

        const attemptWithCaptchaV2 = () => {
          executeRecaptchaV2({
            requestId,
            onComplete: async (recaptchaV2Token: string) => {
              const result = await apiRequest({
                ...(args || {}),
                token: recaptchaV2Token,
                captchaVersion: CAPTCHA_VERSION_V2,
              });
              if (result?.error) {
                resolveRequest({ error: result.error, token: recaptchaV2Token, version: CAPTCHA_VERSION_V2 });
              } else {
                resolveRequest({ result, token: recaptchaV2Token, version: CAPTCHA_VERSION_V2 });
              }
            },
          });
        };

        const handleError = (error: any) => {
          if (error.response?.status === CAPTCHA_VERIFICATION_FAILED_STATUS) {
            attemptWithCaptchaV2();
          } else {
            resolveRequest({ error, token: null, version: CAPTCHA_VERSION_V3 });
          }
        };

        executeRecaptchaV3(action)
          .then((recaptchaV3Token: string | null) => {
            apiRequest({
              ...(args || {}),
              token: recaptchaV3Token,
              captchaVersion: CAPTCHA_VERSION_V3,
            })
              .then((result: any) => {
                if (result?.error?.status === CAPTCHA_VERIFICATION_FAILED_STATUS) {
                  attemptWithCaptchaV2();
                } else if (result?.error) {
                  resolveRequest({ error: result.error, token: recaptchaV3Token, version: CAPTCHA_VERSION_V3 });
                } else {
                  resolveRequest({ result, token: recaptchaV3Token, version: CAPTCHA_VERSION_V3 });
                }
              })
              .catch((error: any) => {
                handleError(error);
              });
          })
          .catch((error: any) => {
            handleError(error);
          });
      });
    },
    [action, apiRequest, executeRecaptchaV2, executeRecaptchaV3, requestId],
  );

  return {
    makeRequest,
    isLoading: executionState.isLoading,
    error: executionState.error,
    requestId,
    data: executionState.data,
    token: executionState.token,
    version: executionState.version,
    isReadyToUse,
  };
}
