import {
  AddressType,
  type BaseScan,
  type CaptchaParams,
  type DtoAddresses,
  DtoBankAccount,
  type DtoNavigation,
  type GetUserAddressParams,
  type ResponseInfo,
  ShippingMethod,
  UserWarehouseAddress,
} from '@usgm/inbox-api-types';
import { snakeCaseKeys } from '@usgm/utils';
import { accountApi } from '../../api/accountApi';
import { ShippingAddressFormSchemaType } from './features/settings/components/AddressBook/ShippingAddressForm';

export const INBOX_TAG_TYPES = {
  NAVIGATION_SETTING: 'NavigationSetting',
  UNREAD_MAILS_INFO: 'UnreadMailsInfo',
  ADDRESS_LIST: 'AddressList',
  ADDRESS: 'Address',
  BANK_ACCOUNT_LIST: 'BankAccountList',
  BANK_ACCOUNT: 'BankAccount',
} as const;

export const inboxAccountsApi = accountApi
  .enhanceEndpoints({
    addTagTypes: [
      INBOX_TAG_TYPES.NAVIGATION_SETTING,
      INBOX_TAG_TYPES.UNREAD_MAILS_INFO,
      INBOX_TAG_TYPES.ADDRESS_LIST,
      INBOX_TAG_TYPES.ADDRESS,
      INBOX_TAG_TYPES.BANK_ACCOUNT_LIST,
      INBOX_TAG_TYPES.BANK_ACCOUNT,
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUserAddresses: builder.query<DtoAddresses, GetUserAddressParams>({
        query: ({ userUUID, ...params }) => ({
          url: `/user/${userUUID}/addresses`,
          params: snakeCaseKeys(params),
          method: 'get',
        }),
        providesTags: (_, error) => (!error ? [INBOX_TAG_TYPES.ADDRESS_LIST] : []),
      }),
      getNavigation: builder.query<DtoNavigation, void | null>({
        query: () => ({
          url: '/settings/navigation',
          method: 'get',
        }),
        providesTags: [INBOX_TAG_TYPES.NAVIGATION_SETTING],
      }),
      getUnreadMailsInfo: builder.query<
        Array<{
          folderId: number | null;
          unreadCount: number;
          unreadQuarantineCount: number;
        }>,
        void | null
      >({
        query: () => ({
          url: '/settings/navigation/count/mails',
          method: 'get',
        }),
        providesTags: [INBOX_TAG_TYPES.UNREAD_MAILS_INFO],
      }),
      getUnreadScansInfo: builder.query<
        Array<{
          folderId: number | null;
          unreadCount: number;
        }>,
        void | null
      >({
        query: () => ({
          url: '/settings/navigation/count/scans',
          method: 'get',
        }),
      }),
      // This doesn't belong here, endpoint should've been implemented in mails service and defined accordingly in the scans api in the FE
      batchExportScans: builder.mutation<ResponseInfo, { ids: BaseScan['uuid'][] | 'all' } & CaptchaParams>({
        query: ({ ids, captchaVersion, token }) => ({
          url: `/scan/batch/export`,
          method: 'post',
          params: {
            token,
            cap_version: captchaVersion,
          },
          data: {
            scans: Array.isArray(ids) ? ids.join(',') : ids,
          },
        }),
      }),
      setDefaultAddress: builder.mutation<ResponseInfo, { userUuid: string; addressUuid: string }>({
        query: ({ userUuid, addressUuid }) => ({
          url: `/user/${userUuid}/address/${addressUuid}/default`,
          method: 'put',
        }),
        onQueryStarted: async ({ addressUuid }, { dispatch, queryFulfilled, getState }) => {
          const patchListCollection = [];
          for (const { originalArgs } of inboxAccountsApi.util.selectInvalidatedBy(getState(), [
            INBOX_TAG_TYPES.ADDRESS_LIST,
            INBOX_TAG_TYPES.ADDRESS,
          ])) {
            patchListCollection.push(
              dispatch(
                inboxAccountsApi.util.updateQueryData('getUserAddresses', originalArgs, (draft) => {
                  draft.addresses = draft.addresses?.map((it) => {
                    return {
                      ...it,
                      isDefault: it.uuid === addressUuid,
                    };
                  });
                }),
              ),
            );
            patchListCollection.push(
              dispatch(
                inboxAccountsApi.util.updateQueryData('getAddressByUuid', originalArgs, (draft) => {
                  draft.isDefault = draft.uuid === addressUuid;
                }),
              ),
            );
          }

          try {
            await queryFulfilled;
          } catch (error) {
            patchListCollection.forEach((patch) => patch.undo());
          }
        },
      }),
      deleteAddress: builder.mutation<ResponseInfo, { addressUuid: string; userUuid: string }>({
        query: ({ addressUuid }) => ({
          url: `/user/address/${addressUuid}/delete`,
          method: 'delete',
        }),
        onQueryStarted: async ({ userUuid, addressUuid }, { dispatch, queryFulfilled }) => {
          const patchList = dispatch(
            inboxAccountsApi.util.updateQueryData(
              'getUserAddresses',
              { userUUID: userUuid, addressType: [AddressType.CheckDeposit, AddressType.Shipping].join() },
              (draft) => {
                draft.addresses = draft.addresses?.filter((it) => it.uuid !== addressUuid);
              },
            ),
          );

          const patchDefaultAddress = dispatch(
            inboxAccountsApi.util.updateQueryData('getAddressByUuid', { addressUuid }, () => undefined),
          );
          try {
            await queryFulfilled;
          } catch (error) {
            patchList.undo();
            patchDefaultAddress.undo();
          }
        },
      }),
      addAddress: builder.mutation<
        ResponseInfo & { id: string },
        { userUuid: string; addr: ShippingAddressFormSchemaType }
      >({
        query: ({ userUuid, addr }) => ({
          url: `/user/${userUuid}/address`,
          method: 'post',
          data: snakeCaseKeys({
            ...addr,
            postalCode: addr.zip,
            userId: userUuid,
            country: addr.country?.name,
            countryCode: addr.country?.code,
            isValidated: true,
          }),
        }),
        invalidatesTags: (_, error) => (!error ? [INBOX_TAG_TYPES.ADDRESS_LIST] : []),
      }),
      getAddressByUuid: builder.query<UserWarehouseAddress, { addressUuid: string }>({
        query: ({ addressUuid }) => ({
          url: `/address/${addressUuid}`,
          method: 'get',
        }),
        providesTags: (result) => (result?.uuid ? [{ type: INBOX_TAG_TYPES.ADDRESS, id: result.uuid }] : []),
      }),
      updateAddress: builder.mutation<
        ResponseInfo,
        { userUuid: string; addressUuid: string; addr: ShippingAddressFormSchemaType }
      >({
        query: ({ userUuid, addressUuid, addr }) => ({
          url: `/user/${userUuid}/address/${addressUuid}`,
          method: 'put',
          data: snakeCaseKeys({
            ...addr,
            postalCode: addr.zip,
            userId: userUuid,
            country: addr.country?.name,
            countryCode: addr.country?.code,
          }),
        }),
        invalidatesTags: (_, error, { addressUuid }) =>
          !error ? [INBOX_TAG_TYPES.ADDRESS_LIST, { type: INBOX_TAG_TYPES.ADDRESS, id: addressUuid }] : [],
      }),
      getShippingMethods: builder.query<{ data: ShippingMethod[] }, null>({
        query: () => ({
          url: `/shipping/methods`,
          method: 'get',
        }),
      }),
      getBankAccounts: builder.query<{ list: DtoBankAccount[] }, null | void>({
        query: () => ({
          url: '/bank-informations',
          method: 'get',
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.list.map((account) => ({ type: INBOX_TAG_TYPES.BANK_ACCOUNT, id: account.uuid })),
                { type: INBOX_TAG_TYPES.BANK_ACCOUNT_LIST },
              ]
            : [],
      }),
      // @TODO: switch to optimistic updates instead of invalidating tags for better user experience
      createBankAccount: builder.mutation<DtoBankAccount, Omit<DtoBankAccount, 'uuid'>>({
        query: (data) => ({
          url: '/bank-informations',
          method: 'post',
          data,
        }),
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          try {
            const response = await queryFulfilled;
            if (response.data.uuid) {
              dispatch(
                inboxAccountsApi.util.updateQueryData('getBankAccounts', undefined, (draft) => {
                  draft.list.push(response.data);
                }),
              );
            }
          } catch (error) {
            console.error(error);
          }
        },
      }),
      updateBankAccount: builder.mutation<DtoBankAccount, DtoBankAccount>({
        query: ({ uuid, ...data }) => ({
          url: `/bank-informations/${uuid}`,
          method: 'put',
          data,
        }),

        onQueryStarted: async ({ uuid, ...data }, { dispatch, queryFulfilled }) => {
          const patchList = dispatch(
            inboxAccountsApi.util.updateQueryData('getBankAccounts', undefined, (draft) => {
              draft.list = draft.list.map((it) => (it.uuid === uuid ? { ...it, ...data } : it));
            }),
          );

          try {
            await queryFulfilled;
          } catch (error) {
            patchList.undo();
          }
        },
      }),
      deleteBankAccount: builder.mutation<ResponseInfo, { id: DtoBankAccount['uuid'] }>({
        query: ({ id }) => ({
          url: `/bank-informations/${id}`,
          method: 'delete',
        }),

        onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
          const patchList = dispatch(
            inboxAccountsApi.util.updateQueryData('getBankAccounts', undefined, (draft) => {
              draft.list = draft.list.filter((it) => it.uuid !== id);
            }),
          );

          try {
            await queryFulfilled;
          } catch (error) {
            patchList.undo();
          }
        },
      }),
    }),
  });

export const {
  useGetUserAddressesQuery,
  useGetNavigationQuery,
  useGetUnreadMailsInfoQuery,
  useGetUnreadScansInfoQuery,
  useBatchExportScansMutation,
  useSetDefaultAddressMutation,
  useDeleteAddressMutation,
  useAddAddressMutation,
  useGetAddressByUuidQuery,
  useUpdateAddressMutation,
  useGetShippingMethodsQuery,
  useCreateBankAccountMutation,
  useUpdateBankAccountMutation,
  useDeleteBankAccountMutation,
  useGetBankAccountsQuery,
} = inboxAccountsApi;
