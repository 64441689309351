export enum MailStatus {
  Inbound = 'INBOUND',
  Inbox = 'INBOX',
  Quarantine = 'QUARANTINE',
  LookupRequired = 'LOOKUP_REQUIRED',
  InspectRequest = 'INSPECT_REQUEST',
  InspectReported = 'INSPECT_REPORTED',
  DiscardRequest = 'DISCARD_REQUEST',
  DiscardPending = 'DISCARD_PENDING',
  DiscardDone = 'DISCARD_DONE',
  DiscardMissing = 'DISCARD_MISSING',
  DiscardMistake = 'DISCARD_MISTAKE',
  ReturnedToSender = 'RETURNED_TO_SENDER',
  ShredRequest = 'SHRED_REQUEST',
  ShredQueue = 'SHRED_QUEUE',
  ShredDone = 'SHRED_DONE',
  OpenInventoryRequest = 'OPEN_INVENTORY_REQUEST',
  OpenInventoryDone = 'OPEN_INVENTORY_DONE',
  ShipmentRequest = 'SHIPMENT_REQUEST',
  Picked = 'PICKED',
  Shipment = 'SHIPMENT',
  Processed = 'PROCESSED',
  LookupAccepted = 'LOOKUP_ACCEPTED',
  Packed = 'PACKED',
  PickedFromWarehouse = 'PICKED_FROM_WAREHOUSE',
  CustomerPickup = 'CUSTOMER_PICKUP',
  ShipmentProcessed = 'SHIPMENT_PROCESSED',
}
