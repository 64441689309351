import { styled } from '@mui/material';

const StyledHeader = styled('header')(({ theme }) => ({
  position: 'relative',
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(4, 0, 4, 0),
  minHeight: '230px',
  '& h2': {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  '& .plan-info': {
    width: '60%',
    marginRight: '10%',
  },
  '& .pan-icon-container': {
    position: 'relative',
    width: '30%',
    alignSelf: 'stretch',
    display: 'flex',
    '& svg': {
      position: 'absolute',
      right: 0,
      bottom: 0,
    },
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2.5, 0, 2.5, 0),
  },
}));

export default StyledHeader;
