import { PlanTerm } from '@usgm/inbox-api-types';
import { inboxHelpers } from '@usgm/utils';
import * as React from 'react';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import PageLoader from '../../../../components/PageLoader';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { addressPickerSlice, selectPickedWarehouse } from '../../../addressPicker/addressPickerSlice';
import { useGetWarehousePlansQuery } from '../../../planPicker/api';
import { planPickerSlice, selectPickedPlan } from '../../../planPicker/planPickerSlice';

export function PlanSelectionRequiredRoute() {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const pickedPlan = useAppSelector(selectPickedPlan);
  const pickedWarehouseId = useAppSelector(selectPickedWarehouse);
  const queryParamWarehouseId = +(searchParams.get('warehouse_id') || '');
  const queryParamPlanId = searchParams.get('plan_id');
  const queryParamTermId = searchParams.get('term_id');

  const { data: plans, isLoading } = useGetWarehousePlansQuery(
    { warehouse: queryParamWarehouseId },
    { skip: !queryParamWarehouseId },
  );

  if (isLoading) {
    return <PageLoader />;
  }

  if (queryParamWarehouseId && queryParamPlanId && plans) {
    const selectedPlan = plans.find((plan) => plan.id === queryParamPlanId);
    if (selectedPlan) {
      dispatch(addressPickerSlice.actions.setWarehouse(queryParamWarehouseId));
      dispatch(planPickerSlice.actions.setPickedPlan(selectedPlan));
      dispatch(planPickerSlice.actions.setTerm(queryParamTermId === '1' ? PlanTerm.Monthly : PlanTerm.Annually));
      inboxHelpers.getStorageManager().setItem('selectedWarehouse', queryParamWarehouseId);
      inboxHelpers.getStorageManager().setItem('selectedPlan', selectedPlan);
      return <Outlet />;
    }
  }

  return pickedWarehouseId && pickedPlan ? (
    <Outlet />
  ) : (
    <Navigate
      to={!pickedWarehouseId ? inboxHelpers.MAIN_PATHS.SELECT_VIRTUAL_ADDRESS : inboxHelpers.MAIN_PATHS.SELECT_PLAN}
      replace
    />
  );
}

export default PlanSelectionRequiredRoute;
