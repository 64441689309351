import React, { useCallback, useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import { MailType, ShipmentCategory } from '@usgm/inbox-api-types';
import { Preloader } from '@usgm/shared-ui';

import { useAppSelector } from '../../../../../../../../../store';
import { ContentContainer } from '../../../../../../../components/DynamicSidebar/ContentContainer';

import { useGetShipInfoQuery } from '../../../../../../shipments/api';
import ActionButtons from '../../ActionButtons';
import { useUpsertShippingRequest } from '../../hooks/useUpsertShippingRequest';
import {
  mailsToShipSelectors,
  selectDepositCheckInstructions,
  selectPackingDimensions,
  selectSelectedRate,
  selectShipmentType,
  selectShippingDeclarationsRequired,
} from '../../mailsShipmentSlice';
import RatesList from '../../rates/RatesList';
import StepHeader from '../StepHeader';
import InsureShipment from './InsureShipment';
import PackageDetails from './PackageDetails';
import ShipmentSchedule from './ShipmentSchedule';

function ShipperStep() {
  const { isLoading: isLoadingShipInfo, data: shipmentInfo } = useGetShipInfoQuery();
  const { data: packingDimensions, isLoading: isCalculatingPackingDimensions } =
    useAppSelector(selectPackingDimensions);
  const mailsToShip = useAppSelector(mailsToShipSelectors.selectAll);

  const shipmentDeclarationsRequired = useAppSelector(selectShippingDeclarationsRequired);
  const instructions = useAppSelector(selectDepositCheckInstructions);

  const { upsertQuery, upsertMailsShippingRequest } = useUpsertShippingRequest(instructions || '');

  const hasNoneLetterItem = useMemo(() => {
    return mailsToShip.some((mail) => mail.mailType !== MailType.Letter);
  }, [mailsToShip]);

  const selectedRate = useAppSelector(selectSelectedRate);
  const shipmentType = useAppSelector(selectShipmentType);

  const handleDepositCheckSubmit = useCallback(() => {
    upsertMailsShippingRequest({});
  }, [upsertMailsShippingRequest]);

  const actionButtonsConfig = useMemo(
    () => ({
      canProceed: !!selectedRate && !upsertQuery.isLoading,
      errorMessage: 'Please select a shipper to continue',
      ...(shipmentType === ShipmentCategory.CheckDepositRequest
        ? {
            nextLabel: 'Submit',
            onNextClick: handleDepositCheckSubmit,
            isLoading: upsertQuery.isLoading,
          }
        : {}),
    }),
    [selectedRate, shipmentType, upsertQuery.isLoading, handleDepositCheckSubmit],
  );

  return (
    <>
      <ContentContainer pb={0} pt={0}>
        <StepHeader />
        <Typography>
          Select a shipping method or pick-up option. {shipmentInfo?.shippingInfo?.promotionalMessage || ''}
        </Typography>
      </ContentContainer>
      {isLoadingShipInfo || isCalculatingPackingDimensions ? (
        <Preloader />
      ) : (
        <>
          <ContentContainer pb={0}>
            {packingDimensions && (
              <Box mb={6}>
                <PackageDetails packingDimensions={packingDimensions} />
              </Box>
            )}

            {shipmentInfo && (
              <Box mb={6}>
                <ShipmentSchedule shipmentInfo={shipmentInfo} />
              </Box>
            )}
            {hasNoneLetterItem && !shipmentDeclarationsRequired && (
              <Box mb={6}>
                <InsureShipment />
              </Box>
            )}
          </ContentContainer>
          <RatesList />
          {shipmentType === ShipmentCategory.ShipmentRequest ? (
            <ActionButtons canProceed={!!selectedRate} errorMessage="Please select a shipper to continue" />
          ) : (
            <ActionButtons {...actionButtonsConfig} />
          )}
        </>
      )}
    </>
  );
}

export default React.memo(ShipperStep);
