import { useCallback, useMemo, useReducer } from 'react';

enum DialogActionType {
  Open = 'open',
  Close = 'close',
}

type TDialogAction<TValue> = {
  type: DialogActionType;
  payload?: TValue | null;
};

function dialogReducer<TValue>(state: TDialogState<TValue>, { type, payload }: TDialogAction<TValue>) {
  switch (type) {
    case 'open':
      return { ...state, open: true, value: payload || null };
    case 'close':
      return { ...state, open: false, value: null };
    default:
      return state;
  }
}

type TDialogState<TValue> = {
  value: TValue | null;
  open: boolean;
};

export function useDialog<TValue>(state: TDialogState<TValue> = { value: null as TValue | null, open: false }) {
  const [dialogState, dispatch] = useReducer(dialogReducer<TValue>, state);

  const openDialog = useCallback((value: TValue | null = null) => {
    dispatch({ type: DialogActionType.Open, payload: value });
  }, []);

  const closeDialog = useCallback(() => {
    dispatch({ type: DialogActionType.Close });
  }, []);

  const dialogData = useMemo(
    () => ({ openDialog, closeDialog, ...dialogState }),
    [closeDialog, dialogState, openDialog],
  );

  return dialogData;
}
