import { useMediaQuery } from '@mui/material';
import { useMobileMode } from './useMobileMode';

export function useResponsiveModes() {
  const isMobile = useMobileMode();
  const isDownMobileLandscape = useMediaQuery('(max-width: 480px)');
  return {
    isMobile,
    isDownMobileLandscape,
  };
}
