import { Typography } from '@mui/material';
import { MessageBox } from '@usgm/shared-ui';

function ScanBundleNotAvailableWarningBox() {
  return (
    <MessageBox
      severity="warning"
      description={
        <Typography>
          Scan bundles are not available on this plan. You can use the existing scan bundle until it expires.
        </Typography>
      }
    />
  );
}

export default ScanBundleNotAvailableWarningBox;
