import CheckIcon from '@mui/icons-material/Check';
import { Box, List, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { AddressType, DtoShipment, ShipmentCategory, UserWarehouseAddress } from '@usgm/inbox-api-types';
import { AddButton, Preloader, useDialog } from '@usgm/shared-ui';
import { camelizeKeys, inboxHelpers, mailsShipmentUtils } from '@usgm/utils';
import React, { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../../store';
import { apiMessagesSlice } from '../../../../../../../../apiMessages/apiMessagesSlice';
import { useAuth } from '../../../../../../../../auth/hooks/useAuth';
import { ContentContainer } from '../../../../../../../components/DynamicSidebar/ContentContainer';

import { useGetUserAddressesQuery } from '../../../../../../../inboxAccountsApi';
import { ShippingAddressFormSchemaType } from '../../../../../../settings/components/AddressBook/ShippingAddressForm';
import { useMailRequestActionParam } from '../../../../../hooks/useMailRequestActionParam';
import ActionButtons from '../../ActionButtons';
import {
  mailsShipmentSlice,
  selectDestinationAddress,
  selectDestinationAddressId,
  selectShipmentType,
} from '../../mailsShipmentSlice';
import StepHeader from '../StepHeader';
import { StyledListItemButton } from '../styled/StyledListItemButton';
import { useShipmentContext } from '../useShipmentContext';
import AddAddressDialog from './AddAddressDialog';

function DestinationStep() {
  const dispatch = useAppDispatch();
  const { user } = useAuth();

  const { cancelRequest } = useMailRequestActionParam();
  const addAddressDialog = useDialog();

  const destinationAddress = useAppSelector(selectDestinationAddress);
  const destinationAddressId = useAppSelector(selectDestinationAddressId);

  const shipmentType = useAppSelector(selectShipmentType);
  const addressType =
    shipmentType === ShipmentCategory.ShipmentRequest ? AddressType.Shipping : AddressType.CheckDeposit;

  const { isLoading, data, isError } = useGetUserAddressesQuery({
    addressType,
    userUUID: user?.userUUID,
  });

  const { goToNextStep } = useShipmentContext();

  useEffect(() => {
    if (isError) {
      dispatch(
        apiMessagesSlice.actions.createMessage({
          severity: 'error',
          text: 'Failed to load your addresses. Please try again.',
        }),
      );
      throw new mailsShipmentUtils.ShipmentError({
        code: mailsShipmentUtils.ShipmentErrorCode.AddressError,
      });
    }
  }, [dispatch, isError]);

  useEffect(() => {
    if (data && data.addresses.length > 0 && !destinationAddressId) {
      const defaultAddress = data.addresses.find((address) => address.isDefault) || null;

      dispatch(mailsShipmentSlice.actions.setDestinationAddress(defaultAddress));
    }
  }, [destinationAddress, data, dispatch, destinationAddressId]);

  const onAddressSelect = (address: UserWarehouseAddress) => () => {
    dispatch(mailsShipmentSlice.actions.setDestinationAddress(address));
  };

  const onAddAddressClick = () => {
    addAddressDialog.openDialog();
  };

  const onAddressAdded = useCallback(
    (address: ShippingAddressFormSchemaType, uuid: string, useNewAddress?: boolean) => {
      if (useNewAddress) {
        const { country, zip, address_line_2, address_line_3, ...restAddress } = address;
        const destinationAddress: DtoShipment['destination'] & { uuid: string; countryCode: string } = {
          ...camelizeKeys<typeof restAddress>(restAddress),
          addressLine2: address_line_2,
          addressLine3: address_line_3,
          postalCode: zip,
          country: country?.name || '',
          uuid,
          countryCode: country?.code || '',
        };
        dispatch(mailsShipmentSlice.actions.setDestinationAddress(destinationAddress));
        goToNextStep();
      }
      addAddressDialog.closeDialog();
    },
    [dispatch, goToNextStep, addAddressDialog],
  );

  const handleCancelShipment = () => {
    dispatch(mailsShipmentSlice.actions.resetState());
    cancelRequest();
  };

  return (
    <>
      <ContentContainer pt={0} pb={11.75}>
        <StepHeader />
        <Typography mb={2}>Please select one from your address book or add a new address.</Typography>
        {isLoading ? (
          <Preloader />
        ) : (
          <>
            <List>
              {data?.addresses?.map((address) => (
                <StyledListItemButton key={address.uuid} onClick={onAddressSelect(address)}>
                  <ListItemText primary={address.name} secondary={inboxHelpers.stringifyAddress({ addr: address })} />
                  <ListItemIcon>
                    {destinationAddressId === address.uuid ? <CheckIcon color="success" /> : null}
                  </ListItemIcon>
                </StyledListItemButton>
              ))}
            </List>
            <Box mt={2}>
              <AddButton label="Add a new address" onClick={onAddAddressClick} />
            </Box>
          </>
        )}
      </ContentContainer>
      <ActionButtons
        onBackClick={shipmentType === ShipmentCategory.CheckDepositRequest ? undefined : handleCancelShipment}
        backLabel={shipmentType === ShipmentCategory.CheckDepositRequest ? 'Back' : 'Cancel'}
        canProceed={!!destinationAddress}
        errorMessage="Please select address to continue"
      />
      <AddAddressDialog
        open={addAddressDialog.open}
        onCancel={addAddressDialog.closeDialog}
        onAddressAdded={onAddressAdded}
      />
    </>
  );
}

export default React.memo(DestinationStep);
