import { Tabs as MuiTabs, styled, tabsClasses } from '@mui/material';

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  [`&.${tabsClasses.root}`]: {
    [`.${tabsClasses.indicator}`]: {
      height: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.main,
    },
    [`.${tabsClasses.flexContainer}`]: {
      gap: theme.spacing(2),
    },
  },
}));
