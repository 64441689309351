import { Typography, styled } from '@mui/material';
import { FlexBox, Image } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import underMaintenanceImgSrc from '../../../../assets/underMaintenance.png';

const CardWrapper = styled(FlexBox)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  maxWidth: 600,
  padding: theme.spacing(6),
  borderRadius: 4 * theme.shape.borderRadius,
  flexDirection: 'column',
  justifyContent: 'center',
  border: `1px solid ${theme.palette.divider}`,
  '& > img': {
    maxWidth: '70%',
    margin: '0 auto',
  },
}));

function ErrorBoundaryFallback() {
  return (
    <FlexBox p={2.5} justifyContent="center" width="100%" height="100%">
      <CardWrapper>
        <Typography textAlign="center" mb={4} variant="h4" color="primary.dark">
          Oops something went wrong
        </Typography>
        <Image responsive src={underMaintenanceImgSrc} alt={inboxHelpers.GENERIC_ERROR_MESSAGE} />
        <Typography textAlign="center" mt={4} variant="h6">
          Our team already notified about this issue and will fix it ASAP!
        </Typography>
      </CardWrapper>
    </FlexBox>
  );
}

export default ErrorBoundaryFallback;
