import { useAppSelector } from '../../../../../../../store';
import { selectHasMissingDocuments, selectIsDocumentsUploaded } from '../../../../../../auth/authSlice';
import NotaryActions from '../../notarization/NotaryActions';

function NotarizationStepItemActions() {
  const isDocumentsUploaded = useAppSelector(selectIsDocumentsUploaded);
  const hasMissingDocuments = useAppSelector((state) => selectHasMissingDocuments(state, null));
  if (!isDocumentsUploaded || hasMissingDocuments) {
    return null;
  }

  return <NotaryActions />;
}

export default NotarizationStepItemActions;
