import { z } from 'zod';

export const depositSlipSchema = z.object({
  boxNumber: z.number(),
  items: z.array(z.number()),
  instructions: z.string(),
});

export type DepositSlipSchemaType = z.infer<typeof depositSlipSchema>;

export const bankAccountSchema = z.object({
  accountNumber: z.string(),
  bankName: z.string(),
  bankState: z.string(),
  uuid: z.string().optional(),
});

export type BankAccountFormSchemaType = z.infer<typeof bankAccountSchema>;
