import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import { appSlice, selectLoginRedirect } from '../appSlice';
import { inboxHelpers } from '@usgm/utils';

export function useLoginRedirection() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loginRedirect = useAppSelector(selectLoginRedirect);

  const { hash, pathname, search } = useLocation();

  const storeLoginRedirect = useCallback(() => {
    dispatch(appSlice.actions.setLoginRedirect({ pathname, search, hash }));
  }, [dispatch, hash, pathname, search]);

  const processLoginRedirect = useCallback(() => {
    if (loginRedirect) {
      navigate(loginRedirect);
      dispatch(appSlice.actions.setLoginRedirect(null));
    } else {
      navigate(inboxHelpers.MAIN_PATHS.INBOX);
    }
  }, [dispatch, loginRedirect, navigate]);

  return { storeLoginRedirect, processLoginRedirect, loginRedirect };
}
