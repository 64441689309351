const APP_ROOT = '/app';

export const MAIN_PATHS = {
  HOME: '/',
  SIGN_UP: '/sign-up',
  SELECT_VIRTUAL_ADDRESS: '/select-virtual-address',
  SELECT_PLAN: '/select-plan',
  CREATE_ACCOUNT: '/create-account',
  ACCEPT_TEAM_MEMBER_INVITATION: '/accept-org-invite',
  CHECKOUT: '/checkout',
  SELECT_PLAN_BY_WAREHOUSE_ID: (id?: number) => `/select-plan${id ? `/${id}` : '/:id'}`,
  LOGIN: '/login',
  INBOX: `${APP_ROOT}/inbox/mails`,
  WELCOME: '/welcome',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  VERIFY_RESET_PASSWORD_TOKEN: '/recover',
  UNSUBSCRIBE: '/unsubscribe',
  MAINTENANCE: 'maintenance',
  APP: APP_ROOT,
};

export const APP_PATHS = {
  VERIFY_ACCOUNT: 'verify-account',
  INBOX: 'inbox',
  SCANS: 'scans',
  SHIPMENTS: 'shipments',
  BILLING_HISTORY: 'billing-history',
  DISCARDS: 'discards',
  SETTINGS: 'settings',
};
