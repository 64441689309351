import CheckIcon from '@mui/icons-material/Check';

import { FlexBox, LabelBadge, Tooltip } from '@usgm/shared-ui';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DtoWarehouse } from '@usgm/inbox-api-types';
import { useTheme } from '@mui/material';
import { warehousesConfig } from '@inbox/constants';
import React, { Fragment } from 'react';

function RecommendedBadges({ warehouseId }: { warehouseId: DtoWarehouse['id'] }) {
  const {
    palette,
    customColors: { dark },
  } = useTheme();

  const info = warehousesConfig.WAREHOUSE_BADGES.get(warehouseId);
  if (!info) return null;

  return (
    <FlexBox gap={0.75} flexWrap="wrap" justifyContent="normal">
      {info.map((badge, index) => {
        const content = (
          <LabelBadge
            iconPosition="right"
            backgroundColor={badge.color ? palette[badge.color].main : undefined}
            color={
              !badge.color
                ? dark['300']
                : badge.color === 'secondary'
                ? palette.background.warning
                : palette.common.white
            }
            icon={badge.color ? <InfoOutlinedIcon /> : <CheckIcon />}
            label={badge.label}
          />
        );

        return (
          <Fragment key={index}>
            {badge.tooltip ? (
              <Tooltip title={badge.tooltip} arrow>
                {content}
              </Tooltip>
            ) : (
              content
            )}
          </Fragment>
        );
      })}
    </FlexBox>
  );
}

export default React.memo(RecommendedBadges);
