import { DtoPlanDetails, MailType } from '@usgm/inbox-api-types';

export function getConsolidationCost({
  settings,
  isShipmentIncludesPackage,
}: {
  settings?: DtoPlanDetails['shipment'];
  isShipmentIncludesPackage: boolean;
}): number {
  return (
    settings?.shipmentSetting.consolidationCharges.find((charge) =>
      isShipmentIncludesPackage ? charge.mailType === MailType.Package : charge.mailType === MailType.Letter,
    )?.amount || 0
  );
}
