import { useCallback, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Box, Typography, useTheme } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import { DtoBankAccount } from '@usgm/inbox-api-types';
import { inboxHelpers } from '@usgm/utils';
import { ConfirmationDialog, ConfirmationDialogProps, Preloader, useMobileMode } from '@usgm/shared-ui';

import { useAppDispatch } from '../../../../../../../../../store';
import { apiMessagesSlice } from '../../../../../../../../apiMessages/apiMessagesSlice';

import {
  useCreateBankAccountMutation,
  useDeleteBankAccountMutation,
  useGetBankAccountsQuery,
  useUpdateBankAccountMutation,
} from '../../../../../../../inboxAccountsApi';
import BankAccountFormFields from './BankAccountFormFields';
import BankAccountsTable from './BankAccountsTable';
import { BankAccountFormSchemaType, bankAccountSchema } from './schemas';

export type BankAccountManagerDialogProps = ConfirmationDialogProps;

function BankAccountManagerDialog({
  title = 'Manage bank accounts',
  onCancel,
  onClose,
  ...dialogProps
}: Omit<BankAccountManagerDialogProps, 'content'>) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isMobile = useMobileMode();
  const [bankAccount, setBankAccount] = useState<BankAccountFormSchemaType | undefined>(undefined);
  const [isMutationMode, setIsMutationMode] = useState(false);

  const getBankAccountsQuery = useGetBankAccountsQuery();
  const [createMutation, createQuery] = useCreateBankAccountMutation();
  const [updateMutation, updateQuery] = useUpdateBankAccountMutation();
  const [deleteMutation, deleteAddressQuery] = useDeleteBankAccountMutation();

  const formMethods = useForm<BankAccountFormSchemaType>({
    mode: 'onChange',
    defaultValues: bankAccount,
    resolver: zodResolver(bankAccountSchema),
  });

  const handleAddClick = useCallback(() => {
    setIsMutationMode(true);
    setBankAccount(undefined);
    formMethods.reset();
  }, [formMethods]);

  const handleEditClick = useCallback(
    (bankAccount: DtoBankAccount) => {
      setIsMutationMode(true);
      setBankAccount(bankAccount);
      formMethods.setValue('accountNumber', bankAccount.accountNumber);
      formMethods.setValue('bankName', bankAccount.bankName);
      formMethods.setValue('bankState', bankAccount.bankState);
      formMethods.setValue('uuid', bankAccount.uuid);
    },
    [formMethods],
  );

  const handleDeleteClick = useCallback(
    async (bankAccount: DtoBankAccount) => {
      const response = await deleteMutation({ id: bankAccount.uuid });
      const hasError = response && 'error' in response;

      dispatch(
        apiMessagesSlice.actions.createMessage({
          severity: hasError ? 'error' : 'success',
          text: !hasError ? 'Bank account has been deleted successfully' : inboxHelpers.GENERIC_ERROR_MESSAGE,
        }),
      );
    },
    [deleteMutation, dispatch],
  );

  const confirmButtonLabel = isMutationMode ? (bankAccount?.uuid ? 'Update' : 'Save') : dialogProps.confirmText;
  const cancelButtonLabel = isMutationMode ? 'Back' : dialogProps.cancelText;

  const handleClose = isMutationMode ? () => setIsMutationMode(false) : onCancel;

  const dialogTitle = isMutationMode ? (bankAccount?.uuid ? 'Edit bank account' : 'Add bank account') : title;

  const disabled =
    (isMutationMode && !formMethods.formState.isValid) ||
    createQuery.isLoading ||
    updateQuery.isLoading ||
    deleteAddressQuery.isLoading;

  const isSubmitting = isMutationMode ? createQuery.isLoading || updateQuery.isLoading : false;

  const handleMutation = async () => {
    if (isMutationMode) {
      const method = bankAccount?.uuid ? updateMutation : createMutation;
      const response = await method(formMethods.getValues() as DtoBankAccount);
      const hasError = response && 'error' in response;

      dispatch(
        apiMessagesSlice.actions.createMessage({
          severity: hasError ? 'error' : 'success',
          text: !hasError
            ? `Bank account ${bankAccount?.uuid ? 'updated' : 'added'}`
            : 'Something went wrong. Please try again.',
        }),
      );

      if (!hasError) {
        setIsMutationMode(false);
        setBankAccount(undefined);
        formMethods.reset();
      }
    }
  };

  const handleConfirm = isMutationMode ? handleMutation : handleAddClick;

  return (
    <ConfirmationDialog
      {...dialogProps}
      title={dialogTitle}
      disabled={disabled}
      onCancel={handleClose}
      onClose={handleClose}
      onConfirm={handleConfirm}
      isLoading={isSubmitting}
      confirmText={confirmButtonLabel}
      cancelText={cancelButtonLabel}
      autoCloseOnConfirm={false}
      backgroundColor={theme.palette.background.paper}
      large
      height={isMobile ? '100%' : 600}
      confirmStartIcon={isMutationMode ? null : <AddCircleOutlineOutlinedIcon />}
      content={
        isMutationMode ? (
          <FormProvider {...formMethods}>
            <BankAccountFormFields />
          </FormProvider>
        ) : getBankAccountsQuery.isError ? (
          <Box py={5} px={2}>
            <Typography textAlign="center">{inboxHelpers.GENERIC_ERROR_MESSAGE}</Typography>
          </Box>
        ) : getBankAccountsQuery.isLoading || getBankAccountsQuery.isFetching ? (
          <Preloader minHeight={200} />
        ) : (
          getBankAccountsQuery.data && (
            <BankAccountsTable onEditClick={handleEditClick} onDeleteClick={handleDeleteClick} />
          )
        )
      }
    />
  );
}

export default BankAccountManagerDialog;
