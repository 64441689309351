import { ShipmentCategory } from './types/ShipmentCategory';

export const SHIPMENTS_FILTERS = ['category', 'status'] as const;

export type TShipmentFilters = (typeof SHIPMENTS_FILTERS)[number];

export const EDITABLE_SHIPMENT_CATEGORIES: Partial<ShipmentCategory>[] = [
  ShipmentCategory.ShipmentRequest,
  ShipmentCategory.CheckDepositRequest,
];

export enum ShipmentSignature {
  Required = 2,
  NotRequired = 1,
}

export const US_COUNTRY_NAMES = ['US', 'USA', 'United States', 'United States of America'];
