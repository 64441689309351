import { BaseScan, ScanStatus } from '@usgm/inbox-api-types';
import { EntityCell, EntityCellProps, cellHelpers } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import ScanAutoDeletionDate from '../ScanAutoDeletionDate';

export type ScanInfoCellProps = {
  data: BaseScan;
  isPending?: boolean;
};

const constructScanInfo = (data: BaseScan, isPending?: boolean): EntityCellProps['infoList'] => {
  const statusTransition =
    typeof data.statusTransition === 'string'
      ? inboxHelpers.parseJsonSafe<BaseScan['statusTransition']>(data.statusTransition)
      : data.statusTransition;

  return isPending
    ? [
        {
          title: 'Item #',
          value: data.mailId,
        },
        {
          title: 'Expedite',
          value: data.isExpedite ? 'Yes' : 'No',
        },
        {
          title: 'Notes',
          value: data.instructions || 'N/A',
        },
        {
          title: 'Request Date',
          value: cellHelpers.formatDateString(data.createdAt),
        },
      ]
    : [
        {
          title: 'Item #',
          value: data.mailId,
        },
        {
          title: 'Scanned On',
          value: cellHelpers.formatDateString(
            statusTransition?.find((transition) => transition.status === ScanStatus.Completed)?.updatedOn,
          ),
        },
        {
          title: 'Deleting',
          value: data.autoDeleteOn ? <ScanAutoDeletionDate dateValue={data.autoDeleteOn} /> : 'N/A',
        },
      ];
};

function ScanInfoCell({ data, isPending }: ScanInfoCellProps) {
  return (
    <EntityCell
      hasIssue={!!data.rejectReason}
      issueTooltip={`Request rejected: ${data.rejectReason}`}
      imageUrl={data.mailImageUrl}
      infoList={constructScanInfo(data, isPending)}
    />
  );
}

export default ScanInfoCell;
