import { Box, BoxProps, styled } from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import { forwardRef } from 'react';

const ButtonContainer = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  '& svg': {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.customColors.dark[400],
  },
})) as typeof Box;

export type RemoveButtonProps = Omit<BoxProps, 'children'> & {
  onClick?: () => void;
};

export default forwardRef<HTMLDivElement, RemoveButtonProps>(function RemoveButton(
  { onClick, 'aria-label': ariaLabel = 'Remove', ...boxProps }: RemoveButtonProps,
  ref,
) {
  return (
    <ButtonContainer ref={ref} aria-label="ariaLabel" {...boxProps} onClick={onClick}>
      <CancelIcon />
    </ButtonContainer>
  );
});
