import {
  NavigationEntityKey,
  NavigationItem,
  NavigationItemType,
  type DtoMailFolder,
  type ResponseInfo,
} from '@usgm/inbox-api-types';
import { mailsApi as baseMailsApi } from '../../../../../api/mailsApi';

import { inboxAccountsApi } from '../../../inboxAccountsApi';
import { folderSelectors, mailFolderSlice } from './folderSlice';
import { RootState } from '../../../../../store';

const ROOT_PATH = '/mail-folders';

export const mailFoldersApi = baseMailsApi.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    addMailFolder: builder.mutation<ResponseInfo & { item: DtoMailFolder }, { label: string }>({
      query: ({ label }) => ({
        url: ROOT_PATH,
        method: 'post',
        data: { name: label, color: '' },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const {
            data: {
              item: { id: resourceId, name: folderName, uuid: resourceUuid },
            },
          } = await queryFulfilled;
          dispatch(
            inboxAccountsApi.util.updateQueryData('getNavigation', null, (draft) => {
              draft.forEach((item) => {
                if (item.key === NavigationEntityKey.Mailbox || item.key === NavigationEntityKey.Scan) {
                  const subItemData: NavigationItem = {
                    resourceId,
                    resourceUuid,
                    label: folderName,
                    editable: item.key === NavigationEntityKey.Mailbox,
                    type:
                      item.key === NavigationEntityKey.Mailbox
                        ? NavigationItemType.MailFolder
                        : NavigationItemType.ScanFolder,
                    key:
                      item.key === NavigationEntityKey.Mailbox
                        ? NavigationEntityKey.MailFolder
                        : NavigationEntityKey.ScanFolder,
                    counterBadge: 0,
                    path: '',
                  };

                  item.subItems?.push(subItemData);
                }
              });
            }),
          );
          dispatch(mailFolderSlice.actions.addFolder({ id: resourceId, label: folderName, uuid: resourceUuid }));
        } catch (e) {
          console.error(e);
        }
      },
    }),
    deleteMailFolder: builder.mutation<ResponseInfo, { id: number }>({
      query: ({ id }) => ({
        url: `${ROOT_PATH}/${id}`,
        method: 'delete',
      }),
      onQueryStarted: async ({ id }, { dispatch, queryFulfilled, getState }) => {
        const folder = folderSelectors.selectById(getState() as RootState, id);

        const patchNavigation = dispatch(
          inboxAccountsApi.util.updateQueryData('getNavigation', null, (draft) => {
            draft.forEach((item) => {
              if (item.key === NavigationEntityKey.Mailbox || item.key === NavigationEntityKey.Scan) {
                item.subItems = item.subItems?.filter((subItem) => subItem.resourceId !== id);
              }
            });
          }),
        );
        dispatch(mailFolderSlice.actions.deleteFolder(id));
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(mailFolderSlice.actions.addFolder(folder));
          patchNavigation.undo();
        }
      },
    }),
    editMailFolder: builder.mutation<ResponseInfo & { item: DtoMailFolder }, { id: number; label: string }>({
      query: ({ id, label }) => ({
        url: `${ROOT_PATH}/${id}`,
        method: 'put',
        data: { name: label },
      }),
      onQueryStarted: async ({ id, label }, { dispatch, queryFulfilled, getState }) => {
        const folder = folderSelectors.selectById(getState() as RootState, id);
        const patchNavigation = dispatch(
          inboxAccountsApi.util.updateQueryData('getNavigation', null, (draft) => {
            draft.forEach((item) => {
              if (item.key === NavigationEntityKey.Mailbox || item.key === NavigationEntityKey.Scan) {
                item.subItems?.forEach((subItem) => {
                  if (subItem.resourceId === id) {
                    subItem.label = label;
                  }
                });
              }
            });
          }),
        );
        dispatch(mailFolderSlice.actions.updateFolder({ id, changes: { label } }));
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(mailFolderSlice.actions.updateFolder({ id, changes: folder }));
          patchNavigation.undo();
        }
      },
    }),
  }),
});

export const { useAddMailFolderMutation, useDeleteMailFolderMutation, useEditMailFolderMutation } = mailFoldersApi;
