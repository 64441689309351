import React, { useCallback, useMemo, useState } from 'react';

import { Box, CircularProgress, MenuItem, Select, SelectChangeEvent, Typography, useTheme } from '@mui/material';

import { DtoPlan, PlanMembershipItem, PlanOtherOptions } from '@usgm/inbox-api-types';
import { FlexBox, MessageBox, SimpleDialog, SimpleDialogProps, Toast, useMobileMode } from '@usgm/shared-ui';
import { inboxHelpers, pascalCase } from '@usgm/utils';
import { useGetAllPlansQuery } from '../../../../../api/paymentApi';
import { useActiveSubscription } from '../../../../../hooks/useActiveSubscription';
import { useAppDispatch } from '../../../../../store';
import { apiMessagesSlice } from '../../../../apiMessages/apiMessagesSlice';
import ScanBundleNotAvailableWarningBox from '../../../components/ScanBundleNotAvailableWarningBox';
import { useBatchDeleteChildAccountsMutation, useGetAccountNamesWithStatusQuery } from '../../onboarding/api';
import { useUpdateUserSubscriptionMutation } from '../api';
import { useUserScanBundleData } from '../hooks/useUserScanBundleData';
import ManageAdditionalNames from './ManageAdditionalNames';

type PlanListItem = {
  id: string;
  name: string;
  planId: string;
  termId: number;
  price: number;
  months: number;
  totalPrice: string;
  otherOptions: PlanOtherOptions;
  originalPlan: DtoPlan;
};

export function PlanChangeDialog({ open, closeHandler }: { open: boolean; closeHandler: () => void }) {
  const theme = useTheme();
  const isMobile = useMobileMode();
  const dispatch = useAppDispatch();
  const { activeScanBundle } = useUserScanBundleData();
  const accountsQuery = useGetAccountNamesWithStatusQuery();

  const [updateSubscriptionMutation, { isLoading, error }] = useUpdateUserSubscriptionMutation();
  const [batchDeleteAccounts, batchDeleteAccountsQuery] = useBatchDeleteChildAccountsMutation();
  const subscriptionsQuery = useActiveSubscription();

  const { isLoading: isPlansLoading, data: plans } = useGetAllPlansQuery({ initials: false });

  const [isManagingAccounts, setIsManagingAccounts] = useState(false);
  const [manageAccountsData, setManageAccountsData] = useState<ReturnType<
    typeof inboxHelpers.requiresAccountDeletion
  > | null>(null);
  const [currentTerm, setCurrentTerm] = useState<PlanMembershipItem | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<PlanListItem | null>(null);

  const planSelectionOptions = useMemo(() => {
    const planSelectionOptionsList: Array<PlanListItem> = [];
    plans?.forEach((plan: DtoPlan) => {
      plan.membership.items.forEach((membership) => {
        const planItem: PlanListItem = {
          id: `${plan.id}-${membership.termId}`,
          name: `${pascalCase(plan.name)} (${inboxHelpers.getNameOfTerm(membership.termId)})`,
          price: membership.price,
          months: membership.months,
          totalPrice: inboxHelpers.getPlanTotalPrice(membership),
          planId: plan.id,
          termId: membership.termId,
          otherOptions: plan.otherOptions,
          originalPlan: plan,
        };
        if (membership.termId !== 3) {
          planSelectionOptionsList.push(planItem);
        }
        if (
          plan.id === subscriptionsQuery.activeSubscription?.planId &&
          membership.termId === subscriptionsQuery.activeSubscription?.termId
        ) {
          setCurrentTerm(membership);
          setSelectedPlan(planItem || null);
        }
      });
    });
    return planSelectionOptionsList;
  }, [plans, subscriptionsQuery.activeSubscription?.planId, subscriptionsQuery.activeSubscription?.termId]);

  const handleConfirm = async () => {
    if (!selectedPlan || !subscriptionsQuery.activeSubscription?.plan || !accountsQuery.data) return;

    const info = inboxHelpers.requiresAccountDeletion(selectedPlan.originalPlan, accountsQuery.data.data.accountNames);

    if (info.deletion) {
      setManageAccountsData({
        ...info,
      });
      return;
    } else {
      const results = await updateSubscriptionMutation({ planId: selectedPlan?.planId, termId: selectedPlan?.termId });
      const hasError = 'error' in results;
      // ToDo handle this in the store by using according matcher in the extraReducers
      if (!hasError) {
        closeHandler();
        window.location.reload();
      }
    }
  };

  const handleAccountSelection = useCallback((selected: string[]) => {
    setManageAccountsData((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        selected,
      };
    });
  }, []);

  const handleDeletion = async () => {
    if (manageAccountsData && manageAccountsData?.selected.length > 0) {
      const results = await batchDeleteAccounts({
        userIds: manageAccountsData.selected,
      });

      const hasError = 'error' in results;

      dispatch(
        apiMessagesSlice.actions.createMessage({
          severity: hasError ? 'error' : 'success',
          text: hasError
            ? `Error deleting account${manageAccountsData.selected.length > 1 ? 's' : ''}`
            : 'Accounts deleted successfully',
        }),
      );
      if (!hasError) {
        setManageAccountsData(null);
      }
    }
  };

  const handleSelectionChange = (e: SelectChangeEvent<string>) => {
    const selectedItem = planSelectionOptions.find((i) => i.id === e.target.value);
    if (selectedItem && subscriptionsQuery.activeSubscription?.plan && accountsQuery.data) {
      const info = inboxHelpers.requiresAccountDeletion(
        selectedItem.originalPlan,
        accountsQuery.data.data.accountNames,
      );

      setManageAccountsData({
        ...info,
      });

      setSelectedPlan(selectedItem || null);
    }
  };

  const toggleIsManagingAccount = () => {
    setIsManagingAccounts((prev) => !prev);
  };

  const shouldShowScanBundleWarning = () => {
    return selectedPlan && !selectedPlan.otherOptions?.isScanBundlesSupported && activeScanBundle;
  };

  const generatePrimaryButtonText = () => {
    if (manageAccountsData?.deletion) {
      if (batchDeleteAccountsQuery.isLoading) {
        return <CircularProgress size={16} color="inherit" />;
      }
      return isManagingAccounts ? 'Delete Account(s)' : 'Manage Account(s)';
    }

    return 'Confirm Plan Change';
  };

  const isCurrentPlanSelected = useMemo(() => {
    return (
      selectedPlan?.planId === subscriptionsQuery.activeSubscription?.planId &&
      selectedPlan?.termId === subscriptionsQuery.activeSubscription?.termId
    );
  }, [selectedPlan, subscriptionsQuery.activeSubscription]);

  const shouldManageAccounts = manageAccountsData?.deletion;

  const dialogProps: SimpleDialogProps = {
    title: shouldManageAccounts ? 'Account Deletion Manager' : 'Change Plan',
    open,
    closeHandler,
    primaryButtonText: generatePrimaryButtonText(),
    primaryButtonHandler: shouldManageAccounts
      ? isManagingAccounts
        ? handleDeletion
        : toggleIsManagingAccount
      : handleConfirm,
    isSubmitDisabled:
      isLoading ||
      !selectedPlan ||
      batchDeleteAccountsQuery.isLoading ||
      isCurrentPlanSelected ||
      (isManagingAccounts && shouldManageAccounts && !manageAccountsData.selected.length),
    secondaryButtonHandler: shouldManageAccounts ? toggleIsManagingAccount : closeHandler,
    secondaryButtonText: shouldManageAccounts && isManagingAccounts ? 'Back' : 'Cancel',
    avoidBottomSheet: true,
    minWidth: !isMobile ? 600 : 0,
  };

  return (
    <SimpleDialog {...dialogProps}>
      {isManagingAccounts && shouldManageAccounts ? (
        <ManageAdditionalNames
          {...manageAccountsData}
          accountNames={accountsQuery.data?.data.accountNames}
          isError={accountsQuery.isError}
          onSelectionChange={handleAccountSelection}
        />
      ) : subscriptionsQuery.isLoading || isPlansLoading || isLoading || accountsQuery.isLoading ? (
        <FlexBox minHeight={100} justifyContent="center">
          <CircularProgress />
        </FlexBox>
      ) : (
        <FlexBox flexDirection="column">
          <FlexBox alignItems="flex-start" width="100%" mb={2}>
            <Typography flex={1} color={theme.customColors.dark[300]} fontSize={14}>
              Current Plan
            </Typography>
            <Box flex={2}>
              <Typography fontSize={14} fontWeight={600}>
                {subscriptionsQuery.activeSubscription && pascalCase(subscriptionsQuery.activeSubscription.plan.name)} (
                {subscriptionsQuery.activeSubscription &&
                  inboxHelpers.getNameOfTerm(subscriptionsQuery.activeSubscription.termId)}
                )
              </Typography>
              {currentTerm && (
                <Typography color={theme.customColors.dark[300]} fontSize={14}>
                  {inboxHelpers.formatPrice(currentTerm.price)}/mo billed{' '}
                  {inboxHelpers.getNameOfTerm(currentTerm.termId)}
                </Typography>
              )}
            </Box>
          </FlexBox>
          <FlexBox alignItems="flex-start" width="100%">
            <Typography flex={1} color={theme.customColors.dark[300]} fontSize={14}>
              Select New Plan
            </Typography>
            <Box flex={2}>
              <Select fullWidth value={selectedPlan?.id || ''} onChange={handleSelectionChange}>
                {planSelectionOptions?.map((plan) => (
                  <MenuItem key={plan.id} value={plan.id}>
                    {plan.name}
                  </MenuItem>
                ))}
              </Select>
              {selectedPlan && (
                <Typography color={theme.customColors.dark[300]} fontSize={14} mt={1}>
                  {inboxHelpers.formatPrice(selectedPlan.price)}/mo billed{' '}
                  {inboxHelpers.getNameOfTerm(selectedPlan.termId)}
                </Typography>
              )}
            </Box>
          </FlexBox>
          {selectedPlan && (
            <FlexBox alignItems="flex-start" width="100%" mt={2}>
              <Typography flex={1} color={theme.customColors.dark[300]} fontSize={14}>
                Total charges:
              </Typography>
              <Box flex={2}>
                {selectedPlan?.totalPrice}
                <Typography pl={0.5} color={theme.customColors.dark[300]} fontSize={14} component="i">
                  (current plan charges will be prorated)
                </Typography>
              </Box>
            </FlexBox>
          )}

          {shouldShowScanBundleWarning() && (
            <Box mt={3}>
              <ScanBundleNotAvailableWarningBox />
            </Box>
          )}
          {manageAccountsData?.deletion && (
            <FlexBox flexDirection="column" gap={2} mt={2}>
              {manageAccountsData.extraNames > 0 && (
                <MessageBox
                  severity="info"
                  description={
                    <Typography>
                      Your new plan allows for{' '}
                      <Typography component="span" fontWeight={theme.typography.fontWeightBold}>
                        {manageAccountsData.maxCount}
                      </Typography>{' '}
                      name . Please remove the{' '}
                      <Typography component="span" fontWeight={theme.typography.fontWeightBold}>
                        {manageAccountsData.extraNames}
                      </Typography>{' '}
                      extra names on your account before switching plan.
                    </Typography>
                  }
                />
              )}
              {manageAccountsData.relationsRequiredToDelete.length > 0 && (
                <MessageBox
                  severity="info"
                  description={
                    <Typography>
                      Your new plan does not allow{' '}
                      <Typography component="span" fontWeight={theme.typography.fontWeightBold}>
                        Company
                      </Typography>{' '}
                      or{' '}
                      <Typography component="span" fontWeight={theme.typography.fontWeightBold}>
                        Estate/Trust
                      </Typography>{' '}
                      account names. Please remove all such account names before switching plan.
                    </Typography>
                  }
                />
              )}
            </FlexBox>
          )}
        </FlexBox>
      )}
      {error && <Toast title={error.message} severity="error" />}
    </SimpleDialog>
  );
}

export default React.memo(PlanChangeDialog);
