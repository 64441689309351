import { Box, styled } from '@mui/material';

export const CardWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius,
  paddingBottom: theme.spacing(1),
}));
