import crypto from 'crypto-js';
import { ENVIRONMENT } from '../env';

export function decryptLoginAsUserDataSafe<T>(rawValue: string | null): T | null {
  if (!rawValue) return null;
  try {
    const decrypted = crypto.AES.decrypt(
      rawValue.replace(/xMl3Jk/g, '+'),
      ENVIRONMENT.LOGIN_AS_USER_ENCRYPTION_KEY,
    ).toString(crypto.enc.Utf8);
    return decrypted ? (JSON.parse(decrypted) as T) : null;
  } catch (error) {
    return null;
  }
}
