import { OrgUserRole } from '@usgm/inbox-api-types';

export const TEAM_MEMBERS_ACTIONS = {
  setMailFolders: {
    label: 'Set Mail Folders',
    color: undefined,
  },
  grantManager: {
    label: 'Grant Manager',
    color: undefined,
  },
  revokeManager: {
    label: 'Revoke Manager',
    color: undefined,
  },
  resendInvitation: {
    label: 'Resend Invitation',
    color: undefined,
  },
  delete: {
    label: 'Delete',
    color: 'dangerous',
  },
} as const;

export type TTeamMemberAction = keyof typeof TEAM_MEMBERS_ACTIONS;

export const ORG_ROLE_TO_ACTION_MAP: { [Key in OrgUserRole]: TTeamMemberAction[] } = {
  MANAGER: ['revokeManager', 'resendInvitation', 'delete'],
  REGULAR: ['setMailFolders', 'grantManager', 'resendInvitation', 'delete'],
  OWNER: ['revokeManager', 'resendInvitation', 'delete'],
} as const;
