import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, CircularProgress, Typography, styled } from '@mui/material';
import { AccountRelation, DtoAccountName, UserInfo } from '@usgm/inbox-api-types';
import { Container, FlexBox, Toast } from '@usgm/shared-ui';
import { inboxHelpers, onboardingUtils } from '@usgm/utils';
import { FormEvent, useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../../store';
import { countrySelectors } from '../../../countries/countriesSlice';
import { useGetAccountNamesWithStatusQuery, useUpdateAccountNamesMutation } from '../../api';
import { ONBOARDING_PATHS } from '../../paths';
import { AddNamesForm, AddNamesFormLayout } from '../AddNamesForm';

import FormActions from '../AddNamesForm/styled/FormActions';
import BusinessCardHeader from '../BusinessCardHeader';
import { CollapsibleCard } from '../CollapsibleCard';
import PageHeading from '../PageHeading';
import { AddNamesLayoutProps } from './AddNamesLayoutProps';
import createAccountNamesMapper from './createAccountNamesMapper';
import { useAccountPurchaseConfirmation } from './hooks/useAccountPurchaseConfirmaion';

const CardContent = styled(Box)(({ theme }) => ({
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    padding: theme.spacing(3, 2.5),
  },
  padding: theme.spacing(3, 4),
  borderTop: `1px solid ${theme.palette.divider}}`,
}));

function CardSection({
  contentSecondaryTitle,
  contentTitle,
  layout,
  plan,
  showBusinessOptions,
  showAddButton = true,
  hideUpgradeMessage,
  currentTotal,
}: {
  contentTitle: string;
  contentSecondaryTitle: string;
  showBusinessOptions: boolean;
  layout: AddNamesFormLayout;
  plan: AddNamesLayoutProps['plan'];
  showAddButton?: boolean;
  hideUpgradeMessage?: boolean;
  currentTotal?: number;
}) {
  return (
    <>
      <Typography mb={0.75} fontWeight="600">
        {contentTitle}
      </Typography>
      <Typography mb={3} color="text.secondary">
        {contentSecondaryTitle}
      </Typography>
      <AddNamesForm
        hideUpgradeMessage={hideUpgradeMessage}
        showAddButton={showAddButton}
        showBusinessOptions={showBusinessOptions}
        layout={layout}
        activePlan={plan}
        currentTotal={currentTotal}
      />
    </>
  );
}

function CollapsibleCardFormCard({
  children,
  header,
}: {
  children: React.ReactNode;

  header: React.ReactNode;
}) {
  const [isCardExpanded, setIsCardExpanded] = useState(false);
  const toggleCard = useCallback(() => {
    setIsCardExpanded((prev) => !prev);
  }, []);

  return (
    <Box mt={3}>
      <CollapsibleCard expanded={isCardExpanded} header={header} onHeaderClick={toggleCard}>
        <CardContent>{children}</CardContent>
      </CollapsibleCard>
    </Box>
  );
}

function AddNamesBusinessForm({
  userUUID,
  accountNames,
  plan,
}: {
  userUUID: UserInfo['userUUID'];
  accountNames: DtoAccountName[];
  plan: AddNamesLayoutProps['plan'];
}) {
  const navigate = useNavigate();
  const [updateMutation, { isLoading: isSubmitting, error }] = useUpdateAccountNamesMutation();

  const countryEntities = useAppSelector(countrySelectors.selectEntities);

  const defaultPrimaryAccount = useMemo(() => {
    return {
      accountNames: accountNames.filter(({ user: { uuid } }) => userUUID === uuid).map(createAccountNamesMapper()),
    };
  }, [accountNames, userUUID]);
  const defaultBusinessNames = useMemo(() => {
    return {
      accountNames: accountNames
        .filter(({ relation: { relationType } }) => onboardingUtils.BUSINESS_ACCOUNT_RELATIONS.includes(relationType))

        .map(createAccountNamesMapper(countryEntities)),
    };
  }, [accountNames, countryEntities]);

  const defaultPersonalNames = useMemo(() => {
    return {
      accountNames: accountNames
        .filter(
          ({ relation: { relationType }, user: { uuid } }) =>
            [
              AccountRelation.MinorChild,
              AccountRelation.Adult,
              AccountRelation.Deceased,
              AccountRelation.Primary,
            ].includes(relationType) && userUUID !== uuid,
        )
        .map(createAccountNamesMapper(countryEntities)),
    };
  }, [accountNames, countryEntities, userUUID]);

  const companyFormMethods = useForm({
    resolver: zodResolver(onboardingUtils.addNamesCompanyInfoFormSchema),

    defaultValues: defaultBusinessNames,
    mode: 'all',
  });

  const personalFormMethods = useForm({
    resolver: zodResolver(onboardingUtils.addNamesFormSchema),
    defaultValues: defaultPersonalNames,
    mode: 'all',
  });

  const primaryFormMethods = useForm({
    resolver: zodResolver(onboardingUtils.addNamesFormSchema),
    defaultValues: defaultPrimaryAccount,
    mode: 'all',
  });

  const currentTotal =
    companyFormMethods.getValues().accountNames.length +
    personalFormMethods.getValues().accountNames.length +
    primaryFormMethods.getValues().accountNames.length;

  const {
    formState: { isValid: isPrimaryValid },
  } = personalFormMethods;
  const {
    formState: { isValid: isCompanyValid },
  } = companyFormMethods;

  const isValid = isPrimaryValid && isCompanyValid;
  const canSubmit = isValid && !isSubmitting;

  const updateAccountNames = useCallback(
    async (accountNames: Array<onboardingUtils.TAccountData & onboardingUtils.TCompanyData>) => {
      const response = await updateMutation(
        accountNames.map(({ city, country, state, ...account }) =>
          onboardingUtils.prepareCompanyInfo({
            ...account,
            city: city || '',
            state: state || '',
            country: country || undefined,
          }),
        ),
      );

      if (!('error' in response)) {
        navigate(ONBOARDING_PATHS.ADD_IDS);
      }
    },
    [navigate, updateMutation],
  );

  const { checkIsPurchaseRequired, dialogComponent } = useAccountPurchaseConfirmation<
    onboardingUtils.TAccountData & onboardingUtils.TCompanyData
  >({
    accountNamesCount: accountNames.length,
    onConfirmPurchase: updateAccountNames,
  });

  const handleSubmit = async (ev: FormEvent) => {
    ev.preventDefault();
    if (!canSubmit) return;
    const companyData = companyFormMethods.getValues();
    const personalData = personalFormMethods.getValues();
    const primaryData = primaryFormMethods.getValues();

    const newAccounts = [...companyData.accountNames, ...personalData.accountNames, ...primaryData.accountNames];

    const purchaseRequired = checkIsPurchaseRequired(newAccounts);
    if (!purchaseRequired) {
      updateAccountNames(newAccounts);
    }
  };

  return (
    <>
      <Box onSubmit={handleSubmit} component="form">
        <CollapsibleCardFormCard header={<BusinessCardHeader isPersonal={false} />}>
          <FormProvider {...primaryFormMethods}>
            <CardSection
              hideUpgradeMessage
              showAddButton={false}
              contentTitle="Company Officer"
              contentSecondaryTitle="Designate one individual to handle your business mail. This person will be the primary account holder and will need to provide ID verification documents later, per USPS regulations."
              layout={AddNamesFormLayout.Business}
              plan={plan}
              showBusinessOptions={false}
              currentTotal={currentTotal}
            />
          </FormProvider>
          <FormProvider {...companyFormMethods}>
            <CardSection
              contentTitle="Company Names"
              contentSecondaryTitle="List the names of the companies you want to associate with this account. You can add up to 10 companies."
              layout={AddNamesFormLayout.Business}
              plan={plan}
              showBusinessOptions={true}
              currentTotal={currentTotal}
            />
          </FormProvider>
        </CollapsibleCardFormCard>

        <CollapsibleCardFormCard header={<BusinessCardHeader isPersonal={true} />}>
          <FormProvider {...personalFormMethods}>
            <CardSection
              contentTitle="Personal Names"
              contentSecondaryTitle="If you're also using this account for personal mail, please list the individuals' names below. You can add the names of family members, or anyone else you wish to share the account with. You can add up to 9 names."
              layout={AddNamesFormLayout.Business}
              plan={plan}
              showBusinessOptions={false}
              currentTotal={currentTotal}
            />
          </FormProvider>
        </CollapsibleCardFormCard>

        <FormActions noBorder justifyContent="center" display="flex" paddingTop={4}>
          <span>
            <Button sx={{ minWidth: 140 }} disabled={!canSubmit} type="submit" variant="contained" fullWidth>
              {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Continue'}
            </Button>
          </span>
        </FormActions>
      </Box>
      {dialogComponent}
      {error && <Toast severity="error" description={error.message || inboxHelpers.GENERIC_ERROR_MESSAGE} />}
    </>
  );
}

export function AddNamesBusiness({ plan, user }: AddNamesLayoutProps) {
  const { isLoading, data } = useGetAccountNamesWithStatusQuery(null);

  return (
    <Container>
      <PageHeading title="Add Name (s)" subtitle="Add companies and individual names associated with your account." />
      {isLoading && (
        <FlexBox justifyContent="center" minHeight={200}>
          <CircularProgress />
        </FlexBox>
      )}
      {data?.data.accountNames && (
        <AddNamesBusinessForm userUUID={user.userUUID} accountNames={data?.data.accountNames} plan={plan} />
      )}
    </Container>
  );
}
