import { Box, Typography, styled } from '@mui/material';
import { TFolder, folderSelectors } from './folderSlice';

import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../store';
import FolderIcon from '../../../assets/icons/folder.svg?react';
import { MailsPathParams } from '../paths';

const StyledText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 4),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 2.5),
  },
}));

export type FolderNameProps = {
  id?: TFolder['id'];
};

function FolderName({ id }: FolderNameProps) {
  const { folderId } = useParams<MailsPathParams>();
  const currentFolder = useAppSelector((state) => folderSelectors.selectById(state, id || Number(folderId)));
  if (!currentFolder) {
    return null;
  }
  return (
    <StyledText variant="h4">
      <Box component="span" mr={2}>
        <FolderIcon />
      </Box>
      {currentFolder.label}
    </StyledText>
  );
}

export default FolderName;
