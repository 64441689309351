import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AccountRelation } from '@usgm/inbox-api-types';
import { FlexBox } from '@usgm/shared-ui';
import { inboxHelpers, pascalCase } from '@usgm/utils';
import React, { useMemo } from 'react';
import TruncateMarkup from 'react-truncate-markup';
import { getCardStatus } from '../../helpers';
import BusinessCardHeader, { BusinessCardHeaderProps, PersonalBadge } from '../BusinessCardHeader';
import { IdStatusBadge } from '../stepItems/addIds/AccountIdStatusRow';

export type DocumentsCardHeaderProps = {
  name: string;
  relation: AccountRelation;
  isBusiness?: boolean;
  cardStatus: ReturnType<typeof getCardStatus>;
  expanded?: boolean;
};

function DocumentsCardHeader({ name, relation, isBusiness, cardStatus, expanded }: DocumentsCardHeaderProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(inboxHelpers.DOWN_MOBILE_LANDSCAPE);
  const status = cardStatus ? (
    <Box component="span" mr={1}>
      <IdStatusBadge
        width="auto"
        status={cardStatus}
        text={cardStatus === 'Missing' ? 'Documents Needed' : `Documents Are ${pascalCase(cardStatus)}`}
        hideText={isSmallScreen}
      />
    </Box>
  ) : null;

  const titleInfo: BusinessCardHeaderProps['info'] = useMemo(() => {
    if (isBusiness && relation === AccountRelation.Primary) {
      return {
        name: `${name}`,
        relation: pascalCase(AccountRelation.Primary),
      };
    }
  }, [isBusiness, name, relation]);

  return (
    <FlexBox width="100%">
      {isBusiness && relation === AccountRelation.Primary ? (
        <>
          <BusinessCardHeader isPersonal={false} info={titleInfo} />
          {status}
        </>
      ) : (
        <>
          <FlexBox>
            {isBusiness && <PersonalBadge />}
            <TruncateMarkup>
              <Typography component="span" variant="subtitle2">
                {name}
              </Typography>
            </TruncateMarkup>

            <Typography ml={1} variant="subtitle2" component="span" color={theme.customColors.dark[300]}>
              ({relation === AccountRelation.Primary ? 'Primary' : pascalCase(relation)})
            </Typography>
          </FlexBox>
          {status}
        </>
      )}
    </FlexBox>
  );
}

export default React.memo(DocumentsCardHeader);
