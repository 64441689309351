import { DtoMailV2, ScanCategory, ScanStatus } from '@usgm/inbox-api-types';
import { DateTime } from 'luxon';
import { findCurrentScanStatusTransitions } from './findCurrentScanStatusTransitions';

const formatDate = (date: string) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
};

const MAP_STATUS_TO_ACTION: { [key in ScanStatus]?: string } = {
  [ScanStatus.InProcess]: 'requested',
  [ScanStatus.Completed]: 'completed',
  [ScanStatus.Deleted]: 'deleted',
};

export function generateMailScanStatusDescriptions(mail: DtoMailV2): string[] | undefined {
  if (!mail?.scanInfo?.uuid || mail?.scanInfo.status === ScanStatus.Cancelled) {
    return [];
  }

  const currentScanStatusTransition = findCurrentScanStatusTransitions(mail);
  return currentScanStatusTransition
    ?.map((item) => {
      const requestInfo = `${mail.scanInfo?.categoryType === ScanCategory.UnboxingRequest ? 'An open' : 'A scan'}`;
      const action = MAP_STATUS_TO_ACTION[item.status];
      if (!action) {
        return '';
      }
      return `${requestInfo} request for this item was ${action} ${formatDate(item.updatedOn)}`;
    })
    .filter((item) => !!item);
}
