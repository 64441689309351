import { DtoAuthData } from '@usgm/inbox-api-types';
import { camelizeKeys, inboxHelpers } from '@usgm/utils';
import { Mutex } from 'async-mutex';
import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  AxiosResponse,
  HeadersDefaults,
  RawAxiosRequestHeaders,
} from 'axios';
import { appSlice } from '../../app/appSlice';
import { ENVIRONMENT } from '../../env';
import { apiMessagesSlice } from '../../features/apiMessages/apiMessagesSlice';
import { store } from '../../store';
import { camelizeResponseInterceptor } from './camelizeResponseInterceptor';
import { isValidToken } from './isValidToken';

type ErrorHandledResult<T, E = void> = [T | null, E extends void ? Error : E | null];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ConstructWithAxiosError<TResponse> = ErrorHandledResult<TResponse, AxiosError<any, any>>;

const ACCESS_TOKEN_EXPIRED_CODE = 'ACCESS_TOKEN_EXPIRED';

const oauthClient = axios.create({ baseURL: ENVIRONMENT.ACCOUNT_API_URL });

const mutex = new Mutex();

const getNewAccessToken = async (refreshToken: string | undefined): Promise<ConstructWithAxiosError<DtoAuthData>> => {
  const release = await mutex.acquire();
  let response: ConstructWithAxiosError<DtoAuthData> = [null, null];
  if (!refreshToken) {
    return response;
  }
  try {
    const results = await oauthClient.post('/refresh/token', undefined, {
      headers: {
        'X-Refresh-Token': refreshToken,
      },
    });

    response = [camelizeKeys(results.data), null];
  } catch (error) {
    response = [null, error as AxiosError];
  } finally {
    release();
  }
  return response;
};

const updateStoreAndStorage = ({ data, error }: { error: AxiosError | null; data: DtoAuthData | null }) => {
  if (error) {
    store.dispatch(appSlice.actions.setSessionExpired(true));
  } else {
    store.dispatch({ type: 'AUTH/setAuth', payload: data });
  }
};

const extractMessage = (response?: AxiosResponse, success = false) => {
  if (
    response?.data &&
    typeof response.data === 'object' &&
    'message' in response.data &&
    typeof response.data.message === 'string'
  ) {
    return response.data.message;
  }
  return !success ? 'Something went wrong. Please try again later.' : 'Request has been processed successfully!';
};

export const createInstance = (
  basePath: string,
  headers: RawAxiosRequestHeaders | AxiosHeaders | Partial<HeadersDefaults> = {
    'Content-Type': 'application/json',
  },
): AxiosInstance => {
  const instance = axios.create({
    baseURL: basePath,
    headers,
  });

  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      if (response.config?.interceptMessages) {
        const message = extractMessage(response, true);

        store.dispatch(
          apiMessagesSlice.actions.createMessage({
            severity: 'success',
            text: message,
          }),
        );
      }
      return camelizeResponseInterceptor(response);
    },
    async (axiosError: AxiosError<{ code?: string }>) => {
      const status = axiosError.response?.status;
      const code = axiosError.response?.data?.code;

      const isSessionExpired = store.getState().APP.sessionExpired;

      if (isSessionExpired) {
        store.dispatch(appSlice.actions.setSessionExpired(true));
        return Promise.reject(axiosError);
      }

      if (axiosError.config?.interceptMessages) {
        const message = extractMessage(axiosError.response);

        store.dispatch(
          apiMessagesSlice.actions.createMessage({
            severity: 'error',
            text: message,
          }),
        );
      }

      if (!isSessionExpired && status === 403 && code === ACCESS_TOKEN_EXPIRED_CODE && axiosError.config) {
        const authData = inboxHelpers.getStorageManager().getItem('authData');
        if (!mutex.isLocked()) {
          const [newAuthData, error] = await getNewAccessToken(authData?.refreshToken);
          updateStoreAndStorage({ data: newAuthData, error });
          if (error) {
            return Promise.reject(axiosError);
          }
          if (newAuthData) {
            axiosError.config.headers['X-Access-Token'] = newAuthData.token;
            instance.defaults.headers['X-Access-Token'] = newAuthData.refreshToken;
          }
        } else {
          await mutex.waitForUnlock();
          const result = instance.request(axiosError.config);
          return result;
        }
      }

      return Promise.reject(axiosError);
    },
  );

  instance.interceptors.request.use(async (config) => {
    await mutex.waitForUnlock();
    const authData = inboxHelpers.getStorageManager().getItem('authData');

    if (authData?.token && authData?.refreshToken) {
      const isAccessTokenValid = !!authData?.token && isValidToken(authData?.token);
      const isRefreshTokenValid = !!authData?.refreshToken && isValidToken(authData?.refreshToken);
      if (!isRefreshTokenValid) {
        store.dispatch(appSlice.actions.setSessionExpired(true));
      }

      if (!isAccessTokenValid) {
        const [newAuthData, error] = await getNewAccessToken(authData?.refreshToken);
        if (newAuthData) {
          authData.token = newAuthData.token;
          authData.refreshToken = newAuthData.refreshToken;
        }

        updateStoreAndStorage({ data: newAuthData, error });
      }

      config.headers['X-Access-Token'] = authData.token;
    }

    return config;
  });
  return instance;
};
