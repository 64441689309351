import { CaptchaType } from '@inbox/constants';

export const CAPTCHA_VERIFICATION_FAILED_STATUS = 422;

export type TCallback = (token: string) => void;

export type RecaptchaV2State = {
  isRendered: boolean;
  onSuccess: TCallback;
};

export type RecaptchaContextType = {
  executeRecaptchaV3: (action: CaptchaType) => Promise<string | null>;
  executeRecaptchaV2: ({ requestId, onComplete }: { requestId: string; onComplete: TCallback }) => void;
  siteKeyV2: string;
  getRecaptchaV2State: (requestId: string) => RecaptchaV2State | undefined;
  renderedRecaptchaV2Ids: string;
  isReadyToUse: boolean;
};
