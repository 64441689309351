import { Box, styled } from '@mui/material';

export const DocumentUploadCard = styled(Box, { shouldForwardProp: (prop) => prop !== 'backgroundColor' })<{
  backgroundColor?: string;
}>(({ theme, backgroundColor }) => ({
  position: 'relative',
  padding: theme.spacing(2, 3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 196,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: backgroundColor || theme.palette.background.base,
  width: '100%',
}));
