import { cellHelpers, Inbox, useDialogs } from '@usgm/shared-ui';
import { inboxHelpers, pascalCase } from '@usgm/utils';
import { useMemo } from 'react';
import { selectActiveSubscription, useGetSubscriptionsQuery } from '../../../../../../api/paymentApi';
import { CbPortalSessionType, useChargebeePortal } from '../../../../../../hooks/useChargebeePortal';

import { useCurrentUser } from '../../../../../auth/hooks/useCurrentUser';
import { useGetImportantDatesQuery } from '../../api';
import { CardHeader, CardItem } from '../../components/Card';
import PlanChangeDialog from '../../components/PlanChangeDialog';

enum PlanDetailsDialogs {
  PLAN_CHANGE = 'plan-change',
}

export default function PlanDetailsPage() {
  const { user } = useCurrentUser();
  const { openPortal } = useChargebeePortal();
  const { data: datesData } = useGetImportantDatesQuery(user.userUUID);
  const { dialogList, openDialog, closeDialog } = useDialogs([PlanDetailsDialogs.PLAN_CHANGE]);
  const selectActiveSubscriptionData = useMemo(selectActiveSubscription, []);
  const subscriptionsQuery = useGetSubscriptionsQuery(null, {
    selectFromResult: (result) => ({
      ...result,
      activeSubscription: selectActiveSubscriptionData(result.data),
    }),
  });

  let planText = '';
  if (subscriptionsQuery.activeSubscription) {
    planText = `${pascalCase(subscriptionsQuery.activeSubscription.plan.name)} (${inboxHelpers.getNameOfTerm(
      subscriptionsQuery.activeSubscription.termId,
    )})`;
  }

  return (
    <Inbox.PageContainer>
      <Inbox.Card noSpacings width="100%">
        <CardHeader
          title="Manage Plan & Billing Details"
          subTitle="On the Plan and Billing Details page, you can view and update your subscription plan. This section also allows you to view, edit, or delete your billing details, ensuring your payment information is current and accurate."
        />
        <Inbox.CardContent>
          <CardItem
            label="Plan Details"
            value={planText}
            actionText="Change"
            actionHandler={() => openDialog(PlanDetailsDialogs.PLAN_CHANGE)}
          />
          {datesData?.subscriptionActivationDate && (
            <CardItem
              label="Start Date"
              value={cellHelpers.formatDateString(datesData?.subscriptionActivationDate)}
              actionText=""
            />
          )}
          {datesData?.subscriptionEndDate && (
            <CardItem
              label="Next Billing"
              value={cellHelpers.formatDateString(datesData?.subscriptionEndDate)}
              actionText=""
            />
          )}
          {datesData?.scheduledAccountClosureDate && (
            <CardItem
              label="Closes on"
              value={cellHelpers.formatDateString(datesData?.scheduledAccountClosureDate)}
              actionText=""
            />
          )}
          <CardItem
            label="Payment Methods"
            actionText="View"
            actionHandler={() => openPortal(CbPortalSessionType.PaymentSources)}
          />
          <CardItem
            label="Billing Address"
            actionText="View"
            actionHandler={() => openPortal(CbPortalSessionType.BillingAddress)}
          />
        </Inbox.CardContent>
      </Inbox.Card>

      {dialogList[PlanDetailsDialogs.PLAN_CHANGE] && (
        <PlanChangeDialog
          open={dialogList[PlanDetailsDialogs.PLAN_CHANGE]}
          closeHandler={() => closeDialog(PlanDetailsDialogs.PLAN_CHANGE)}
        />
      )}
    </Inbox.PageContainer>
  );
}
