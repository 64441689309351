import { TextField, styled } from '@mui/material';

export const TextInput = styled(TextField, {
  shouldForwardProp: (prop) => !['textarea', 'preserveSpacing', 'maxWidth'].includes(prop as string),
})<{ textarea?: boolean; preserveSpacing?: boolean; maxWidth?: number }>(
  ({ theme, textarea = false, preserveSpacing = true, maxWidth }) => ({
    color: theme.palette.text.secondary,
    height: textarea ? 'auto' : preserveSpacing ? theme.spacing(10.25) : theme.spacing(7),
    ...(textarea && {
      '& .MuiInputBase-root': {
        padding: 0,
      },
    }),
    ...(maxWidth && {
      maxWidth,
    }),
    '& label.MuiFormLabel-root': {
      textTransform: 'uppercase',
      letterSpacing: '1.2px',
      fontSize: '0.75rem',
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 2,
    },
    '& input::placeholder': {
      color: theme.palette.text.disabled,
      opacity: 1,
    },
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.divider,
    },
  }),
);
