import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, CircularProgress, Theme, Typography, styled, useTheme } from '@mui/material';
import { FlexBox } from '@usgm/shared-ui';

import { usePlans } from '../hooks/usePlans';
import { inboxHelpers } from '@usgm/utils';

import badgeSrc from '../assets/selectedLocationBadge.png';
import YourLocation from '../assets/yourLocation.svg?react';

const YourLocationContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  display: 'flex',
  justifyContent: 'center',

  position: 'relative',
  height: '53px',
  '& > img': {
    position: 'absolute',
    left: '50%',
    bottom: 0,
    transform: 'translate(-50%, 50%)',
  },
}));

const Root = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  width: theme.spacing(21.25),
  height: theme.spacing(21.25),
  borderRadius: '50%',
  outline: `4px solid ${theme.palette.mode === 'light' ? '#C7DBFE' : theme.palette.divider}`,
  overflow: 'hidden',
  backgroundColor: theme.palette.mode === 'light' ? '#F6F6F6' : theme.palette.grey[900],
}));

const AddressInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(2.5, 1, 1, 1),
  backgroundColor: theme.palette.background.paper,
  flex: '1 1 auto',
}));

export function SelectedWarehouseBadge() {
  const { pickedWarehouse: warehouse, warehouseQuery } = usePlans();
  const navigate = useNavigate();
  const theme = useTheme<Theme>();
  const handleClick = () => {
    navigate(inboxHelpers.MAIN_PATHS.SELECT_VIRTUAL_ADDRESS);
  };
  return (
    <Root onClick={handleClick}>
      {warehouseQuery.isLoading ? (
        <FlexBox flex="1 1 auto" justifyContent="center">
          <CircularProgress />
        </FlexBox>
      ) : (
        <>
          <YourLocationContainer>
            <YourLocation />
            <img src={badgeSrc} width={30} height={30} alt="Your Location" />
          </YourLocationContainer>
          <AddressInfoContainer>
            <Typography fontWeight="bold" variant="h6">
              {warehouse.name}
            </Typography>
            <Box>
              <Typography color={theme.customColors.dark[300]} textAlign="center" fontSize="0.75rem">
                {warehouse.address.addressLine}, {warehouse.address.city}
              </Typography>
              <Typography color={theme.customColors.dark[300]} textAlign="center" fontSize="0.75rem">
                {warehouse.address.state},{warehouse.address.postalCode}
              </Typography>
            </Box>
          </AddressInfoContainer>
        </>
      )}
    </Root>
  );
}

export default React.memo(SelectedWarehouseBadge);
