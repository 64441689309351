import { Box, Typography, useTheme } from '@mui/material';
import { useShipmentContext } from './useShipmentContext';
import EntityStatus from '../../../../../billingHistory/components/EntityStatus';
import { EntityStatusCategory } from '@usgm/inbox-api-types';
import { FlexBox } from '@usgm/shared-ui';

function StepHeader() {
  const { activeStepIndex, activeStep } = useShipmentContext();
  const theme = useTheme();
  return (
    <FlexBox mb={1} gap={1} flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
      <Box component="span">
        <EntityStatus hideIcon label={`Step ${activeStepIndex + 1}`} statusCategory={EntityStatusCategory.Neutral} />
      </Box>
      <Typography fontSize="24px" fontWeight={theme.typography.fontWeightBold}>
        {activeStep.header}
      </Typography>
    </FlexBox>
  );
}

export default StepHeader;
