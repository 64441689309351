import { Box, BoxProps, Collapse, styled } from '@mui/material';
import { ExpandButton, FlexBox } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import React from 'react';

const Root = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  width: '100%',
}));

const Header = styled(FlexBox)(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(3, 4),
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    padding: theme.spacing(2.5),
  },
}));

export type CollapsibleCard = {
  header: React.ReactNode;
  children?: React.ReactNode;
  expanded?: boolean;
  onHeaderClick: () => void;
  unmountOnExit?: boolean;
} & BoxProps;

export function CollapsibleCard({
  onHeaderClick,
  header,
  children,
  expanded = false,
  unmountOnExit = false,
  ...containerProps
}: CollapsibleCard) {
  return (
    <Root {...containerProps}>
      <Header onClick={onHeaderClick}>
        <FlexBox flex={1}>{header}</FlexBox>
        {!!onHeaderClick && <ExpandButton expanded={expanded} />}
      </Header>
      <Collapse unmountOnExit={unmountOnExit} in={expanded}>
        {children}
      </Collapse>
    </Root>
  );
}

export default React.memo(CollapsibleCard);
