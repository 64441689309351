import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

import { Toast } from '../Toast';
import { useCopyToClipboard } from '../../hooks';
import { InlineButton } from './InlineButton';

export function CopyAddressButton({ address }: { address: string }) {
  const {
    handleCopy,
    data: copyData,
    showMessage,
    clearMessage,
  } = useCopyToClipboard({
    label: 'Address',
    textToCopy: address,
  });
  return (
    <>
      <InlineButton onClick={handleCopy}>
        Copy <ContentCopyOutlinedIcon />
      </InlineButton>
      {showMessage && (
        <Toast onClose={clearMessage} title={copyData.message} severity={copyData.error ? 'error' : 'success'} />
      )}
    </>
  );
}
