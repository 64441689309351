import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IconButton, List, SwipeableDrawer, styled, SwipeableDrawerProps } from '@mui/material';
import NavigationItem from '../../../../../features/inbox/components/layout/Navigation/NavigationItem';
import TNavigationItem from '../../types/TNavigationItem';
import { Logo } from '../../../../Logo';
import { useNavigate } from 'react-router-dom';

type DrawerMenuProps = {
  open?: boolean;
  width?: number;
  onOpen?: () => void;
  onClose?: () => void;
  menuItems?: TNavigationItem[];
  anchor?: SwipeableDrawerProps['anchor'];
};

const DrawerHeader = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2.25, 1),
  justifyContent: 'flex-start',
}));

export default function DrawerMenu({
  open = false,
  width = 300,
  onOpen,
  onClose,
  menuItems,
  anchor = 'right',
}: DrawerMenuProps) {
  const navigate = useNavigate();
  const handleClose = () => {
    onClose?.();
  };
  const handleOpen = () => {
    onOpen?.();
  };
  const handleNavigationItemClick = (item: TNavigationItem) => () => {
    if (!item.subMenu || !item.subMenu.length) {
      handleClose();
    }
    if (item.href) {
      navigate(item.href);
    }
    item.onClick?.();
  };
  return (
    <SwipeableDrawer
      sx={{
        width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width,
        },
      }}
      anchor={anchor}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <DrawerHeader>
        <IconButton onClick={handleClose}>
          <ArrowBackIosIcon />
        </IconButton>
        <Logo />
      </DrawerHeader>
      <List sx={{ padding: 2 }}>
        {menuItems?.map((item, index) => {
          const { label, href, subMenu } = item;
          return (
            <NavigationItem
              onClick={handleNavigationItemClick(item)}
              subItems={subMenu}
              label={label}
              path={href}
              key={index}
            />
          );
        })}
      </List>
    </SwipeableDrawer>
  );
}
