import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import XIcon from '@mui/icons-material/X';
import { EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { alpha, styled } from '@mui/material';
import React from 'react';

const StyledShareButton = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  width: theme.spacing(7),
  height: theme.spacing(7),
  color: theme.palette.primary.main,
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
  },
  '& > button': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
}));

const AVAILABLE_SHARES = {
  twitter: ({ url, title }: ShareButtonProps) => (
    <TwitterShareButton url={url} title={title}>
      <XIcon fontSize="large" />
    </TwitterShareButton>
  ),
  whatsapp: ({ url, title }: ShareButtonProps) => (
    <WhatsappShareButton url={url} title={title}>
      <WhatsAppIcon fontSize="large" />
    </WhatsappShareButton>
  ),
  facebook: ({ url, title }: ShareButtonProps) => (
    <FacebookShareButton title={title} url={url}>
      <FacebookOutlinedIcon fontSize="large" />
    </FacebookShareButton>
  ),
  email: ({ url, title }: ShareButtonProps) => (
    <EmailShareButton url={url} title={title}>
      <EmailOutlinedIcon fontSize="large" />
    </EmailShareButton>
  ),
};

type TShareButtonKey = keyof typeof AVAILABLE_SHARES;

export type ShareButtonProps = {
  url: string;
  title: string;
};

export type ShareButtonsListProps = ShareButtonProps & { items?: Array<TShareButtonKey> };

export function ShareButtonsList({
  url,
  title,
  items = Object.keys(AVAILABLE_SHARES) as TShareButtonKey[],
}: ShareButtonsListProps) {
  return items.map((item) => (
    <StyledShareButton key={item}>{AVAILABLE_SHARES[item]({ url, title })}</StyledShareButton>
  ));
}

export default React.memo(ShareButtonsList);
