export function isValidToken(token: string): boolean {
  try {
    const expiry = JSON.parse(window.atob(token.split('.')[1])).exp;
    const now = Math.floor(new Date().getTime() / 1000);

    return now < expiry;
  } catch (e) {
    return false;
  }
}
