import { Box, FormControlLabel, Radio } from '@mui/material';
import { DtoAddresses } from '@usgm/inbox-api-types';
import { EntityCell, EntityCellProps } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';

export type AddressInfoCellProps = {
  data: DtoAddresses['addresses'][number];
};

const constructAddressInfo = (data: DtoAddresses['addresses'][number]): EntityCellProps['infoList'] => {
  return [
    {
      title: 'Contact',
      value: data.name,
    },
    {
      title: 'Address',
      value: inboxHelpers.stringifyAddress({ addr: data }),
    },
    {
      title: 'Type',
      value: data.addressType,
    },
    {
      title: '',
      value: <FormControlLabel value={data.uuid} control={<Radio checked={data.isDefault} />} label="Default" />,
    },
  ];
};

function AddressInfoCell({ data }: AddressInfoCellProps) {
  return (
    <Box width="100%">
      <EntityCell headingWidth="20%" hideHeadingBelowPx={540} infoList={constructAddressInfo(data)} />
    </Box>
  );
}

export default AddressInfoCell;
