import { selectAccountVerificationState } from '../../../../../../auth/authSlice';
import { useAppSelector } from '../../../../../../../store';

export function NotarizationTitle() {
  const accountVerificationState = useAppSelector((state) => selectAccountVerificationState(state, null));
  if (!accountVerificationState?.steps.notarization.onlineNotarizationDetails) {
    return 'Get Notarized';
  }

  return null;
}
