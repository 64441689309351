import { Box, styled } from '@mui/material';
import { DtoShipment, ShipmentMailItem } from '@usgm/inbox-api-types';
import { cellHelpers, ItemImageWithWarning } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import DetailsTable from '../../../components/DetailsTable';

export type ShipmentItemsProps = {
  data: DtoShipment;
};

const ItemContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'hideBorder' })<{ hideBorder?: boolean }>(
  ({ theme, hideBorder = false }) => ({
    borderBottom: hideBorder ? 'none' : `3px dashed ${theme.palette.divider}`,
    paddingTop: theme.spacing(4),
    paddingBottom: hideBorder ? 0 : theme.spacing(4),
  }),
);

const getDetailsList = (item: ShipmentMailItem) => [
  {
    title: 'Item Number',
    value: `#${item.mailId}`,
  },
  {
    title: 'Arrived',
    value: cellHelpers.formatDateString(item.arrivalDate),
  },
  {
    title: 'Sender',
    value: item.sender.name,
  },
  {
    title: 'Description',
    value: item.sender.description,
  },
  {
    title: 'Tracking',
    value: item.tracking.id,
  },
  {
    title: 'type',
    value: item.mailType,
  },
  {
    title: 'Size (in)',
    value: inboxHelpers.stringifyDimensions({
      dimensions: item.dimensions,
    }),
  },
  {
    title: 'Weight (lbs)',
    value: item.dimensions.weight,
  },
];

function ShipmentItems({ data }: ShipmentItemsProps) {
  return data.mailItems.map((item, index) => {
    const details = getDetailsList(item).filter((detail) => detail.value);
    return (
      <ItemContainer hideBorder={index === data.mailItems.length - 1} key={index}>
        <ItemImageWithWarning
          imageUrl={item.imageUrl}
          showWarning={data.hasIssue}
          tooltipText={`${data.categoryName} rejected`}
        />
        <Box mt={2}>
          <DetailsTable items={details} />
        </Box>
      </ItemContainer>
    );
  });
}

export default ShipmentItems;
