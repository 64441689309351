import { useEffect } from 'react';

export type TUseScriptParams = {
  url: string;
  anchor: 'head' | 'body';
  async?: boolean;
  id?: string;
  token?: string;
  skip?: boolean;
};
export const useScript = ({ url, id, token, anchor = 'body', async = true, skip }: TUseScriptParams) => {
  useEffect(() => {
    if (skip) {
      return;
    }
    const script = document.createElement('script');

    script.src = url;
    script.async = async;
    if (id) {
      script.setAttribute('id', id);
    }
    if (token) {
      script.dataset.token = token;
    }

    document[anchor].appendChild(script);

    return () => {
      document[anchor].removeChild(script);
    };
  }, [url, anchor, async, id, token, skip]);
};
