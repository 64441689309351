import { Box, styled } from '@mui/material';
import { ReactNode } from 'react';
import FlexBox from '../../FlexBox';
import { ImageCell } from '../cellRenderers/ImageCell';

const CardRoot = styled(FlexBox)(({ theme }) => ({
  flexDirection: 'column',
  width: '100%',
}));

export type GridCardProps = {
  imageUrl?: string;
  dropdownActions?: ReactNode;
  actions?: ReactNode;
  children?: ReactNode;
  selectionContent?: ReactNode;
};
export function GridCard({ actions, children, dropdownActions, selectionContent, imageUrl }: GridCardProps) {
  return (
    <CardRoot>
      <Box width="100%">{imageUrl && <ImageCell imageUrl={imageUrl} />}</Box>
      <Box width="100%">{children}</Box>
      {actions && <Box>{actions}</Box>}
    </CardRoot>
  );
}

export default GridCard;
