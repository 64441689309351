import { Box, Card, styled } from '@mui/material';
import { Libraries, useJsApiLoader } from '@react-google-maps/api';
import { ENVIRONMENT } from '../../../../../env';
import { useGetWarehousesQuery } from '../../../../addressPicker/api';
import PlaceSearchAutocomplete from '../../../../addressPicker/components/PlaceSearchAutocomplete';
import ScrollableWarehouseList from '../../../../addressPicker/components/warehouseLists/ScrollableWarehouseList';
import WarehouseInnerCard from '../../../../addressPicker/components/WarehouseCard/WarehouseInnerCard';
import { useAuth } from '../../../../auth/hooks/useAuth';
import { useAppSelector } from '../../../../../store';
import { selectFilteredWarehousesIds, warehousesSelectors } from '../../../../addressPicker/addressPickerSlice';
import type { DtoWarehouse } from '@usgm/inbox-api-types';
import { useSetWarehouse } from '../../../../addressPicker/hooks/useSetWarehouse';
import { Preloader } from '@usgm/shared-ui';

const libraries: Libraries = ['places'];

const StyledGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  rowGap: theme.spacing(2),
  columnGap: theme.spacing(3),
}));

export default function LocationStep({
  handleSelection,
  changeWarehouseMode = false,
  isDesktop = false,
}: {
  handleSelection: (step?: number) => void;
  changeWarehouseMode: boolean;
  isDesktop: boolean;
}) {
  const { isLoaded: googleMapApiLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ENVIRONMENT.GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const filteredWarehouses = useAppSelector(selectFilteredWarehousesIds);

  const { isLoading } = useGetWarehousesQuery();
  const { user } = useAuth();
  const warehouseId = user?.warehouseId;

  if (isLoading || !googleMapApiLoaded) {
    return <Preloader />;
  }

  return (
    <Box>
      <Box mb={6}>
        <PlaceSearchAutocomplete placeholder="Search by city, state or ZIP code (e.g. Chicago, IL)" />
      </Box>
      <Box px={1}>
        {!isDesktop ? (
          <ScrollableWarehouseList handleSelection={handleSelection} changeWarehouseMode={changeWarehouseMode} />
        ) : (
          <Box mb={5}>
            <StyledGrid>
              {filteredWarehouses.map((id) => {
                const isCurrent = id === warehouseId && changeWarehouseMode;
                if (isCurrent) {
                  return false;
                }
                return <WarehouseItem key={id} id={id} handleSelection={handleSelection} />;
              })}
            </StyledGrid>
          </Box>
        )}
      </Box>
    </Box>
  );
}

const WarehouseItem = ({ id, handleSelection }: { id: DtoWarehouse['id']; handleSelection?: () => void }) => {
  const warehouse = useAppSelector((state) => warehousesSelectors.selectById(state, id));
  const onWarehouseSelect = useSetWarehouse(handleSelection);
  return warehouse ? (
    <Card elevation={1} sx={{ p: 3, height: '100%' }}>
      <WarehouseInnerCard key={warehouse?.id} {...warehouse} onSelect={onWarehouseSelect} />
    </Card>
  ) : null;
};
