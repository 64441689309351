import React from 'react';
import TFooterSection from '../../types/TFooterSection';
import { Box, Link, List, ListItem, Typography, styled } from '@mui/material';
import { inboxHelpers } from '@usgm/utils';

const StyledList = styled(List)(({ theme }) => ({
  margin: 0,
  padding: 0,
  '& li': {
    padding: 0,
    marginBottom: theme.spacing(2),
  },
  '& a': {
    color: theme.palette.common.white,
    fontSize: '0.875rem',
    textDecoration: 'none',
  },
}));

const SectionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  width: '60%',
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(4),
    width: '100%',
    '& > div': {
      flex: `1 0 calc(25% - 32px)`,
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& > div': {
      flex: `1 0 calc(50% - 20px)`,
    },
    width: '100%',
  },
  [`@media ${inboxHelpers.DOWN_SMALL_MOBILE}`]: {
    '& > div': {
      flex: `1 0 calc(50% - 16px)`,
    },
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.customColors.dark[500],
  marginBottom: theme.spacing(2.25),
  fontSize: '0.875rem',
}));

type FooterSectionProps = {
  sections?: TFooterSection[];
};

export default function FooterSection({ sections }: FooterSectionProps) {
  return (
    <SectionsContainer>
      {sections?.map(({ items, title }, index) => (
        <Box key={index}>
          <StyledTitle>{title}</StyledTitle>
          <StyledList>
            {items.map(({ label, external, href }, index) => (
              <ListItem key={index}>
                <Link target={external ? '_blank' : undefined} aria-label={label} href={href}>
                  {label}
                </Link>
              </ListItem>
            ))}
          </StyledList>
        </Box>
      ))}
    </SectionsContainer>
  );
}
