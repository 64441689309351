import { MenuItem, styled } from '@mui/material';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FlexBox, TextInput } from '@usgm/shared-ui';
import { inboxHelpers, onboardingUtils, pascalCase } from '@usgm/utils';

import { uniq } from 'lodash-es';
import { CompanyInfoForm } from './CompanyInfoForm';
import { DESCRIPTION_OPTIONS_BUSINESS, DESCRIPTION_OPTIONS_PERSONAL } from './constants';
import FormRow from './styled/FormRow';

const Root = styled(FlexBox, {
  shouldForwardProp: (prop) => prop !== 'isBusiness',
})<{ isBusiness?: boolean }>(({ theme, isBusiness }) => ({
  borderBottom: isBusiness ? `2px dashed ${theme.palette.divider}` : 'none',
  width: '100%',
  flexDirection: 'column',
  marginBottom: isBusiness ? theme.spacing(3) : 0,
  '&:nth-last-of-type(2)': {
    borderBottom: 'none',
  },
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    marginBottom: 0,
    borderBottom: 'none',
  },
}));

export type AddNameFormRowProps = onboardingUtils.AccountSchemaType & {
  index: number;
  disableRelationType: boolean;
  disabled?: boolean;
  isBusiness?: boolean;
  showAllRelations?: boolean;
};

export function AddNameFormRow({
  index,
  disabled = false,
  disableRelationType,
  isBusiness = false,
  showAllRelations,
}: AddNameFormRowProps) {
  const context = useFormContext<onboardingUtils.AccountNamesFormSchemaType | onboardingUtils.CompanyInfoFormSchema>();
  const selectOptions = useMemo(
    () =>
      isBusiness
        ? showAllRelations
          ? uniq([...DESCRIPTION_OPTIONS_BUSINESS, ...DESCRIPTION_OPTIONS_PERSONAL])
          : DESCRIPTION_OPTIONS_BUSINESS
        : DESCRIPTION_OPTIONS_PERSONAL,
    [isBusiness, showAllRelations],
  );
  if (!context) {
    return null;
  }
  const {
    control,
    watch,
    formState: { errors },
  } = context;

  const relationType = watch(`accountNames.${index}.relationType`);
  const showCompanyForm = showAllRelations
    ? onboardingUtils.BUSINESS_ACCOUNT_RELATIONS.includes(relationType)
    : isBusiness;
  return (
    <Root isBusiness={showCompanyForm}>
      <FormRow>
        <Controller
          render={({ field }) => (
            <TextInput
              {...field}
              disabled={disabled}
              error={!!errors.accountNames?.[index]?.name}
              helperText={errors.accountNames?.[index]?.name?.message}
              InputLabelProps={{ shrink: true }}
              placeholder="Enter Name"
              fullWidth
              required
              label="Name"
            />
          )}
          name={`accountNames.${index}.name` as const}
          control={control}
        />

        {disableRelationType ? (
          <TextInput
            placeholder="Primary"
            fullWidth
            InputLabelProps={{ shrink: true }}
            required
            label="Description"
            disabled
          />
        ) : (
          <Controller
            render={({ field }) => (
              <TextInput
                {...field}
                disabled={disabled}
                select
                error={!!errors.accountNames?.[index]?.relationType}
                helperText={errors.accountNames?.[index]?.relationType?.message}
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
                label="Description"
              >
                {selectOptions.map((option) => (
                  <MenuItem selected={field.value === option} key={option} value={option}>
                    {pascalCase(option)}
                  </MenuItem>
                ))}
              </TextInput>
            )}
            name={`accountNames.${index}.relationType` as const}
            control={control}
          />
        )}
      </FormRow>
      {showCompanyForm && <CompanyInfoForm index={index} />}
    </Root>
  );
}

export default React.memo(AddNameFormRow);
