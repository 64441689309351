import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Box, Typography, useTheme } from '@mui/material';

import { FlexBox, SimpleDialog, TextInput, Toast } from '@usgm/shared-ui';

import { useLazyGenerateMfaCodeQuery } from '../../../../auth/api';
import { TimeoutId } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';
import { useEnableMfaMutation, useLazyDisableMfaQuery } from '../api';
import { RESEND_COUNTDOWN } from '../../../constants';

const schema = z.object({
  code: z.string().min(1, 'Verification Code is required.'),
});

type TwoStepAuthFormType = z.infer<typeof schema>;

type CountDownProps = {
  seconds: number;
  onCountDownEnd?: () => void;
  onActionClick?: () => void;
  renderContent?: (seconds: number) => ReactNode;
  resetOnActionClick?: boolean;
};

export function CountDown({
  seconds,
  onCountDownEnd,
  renderContent,
  onActionClick,
  resetOnActionClick,
}: CountDownProps) {
  const [countDownSeconds, setCountDownSeconds] = useState<number>(seconds);
  const theme = useTheme();

  useEffect(() => {
    let resendTimeout: TimeoutId | undefined;

    if (countDownSeconds > 0) {
      resendTimeout = setTimeout(() => setCountDownSeconds(countDownSeconds - 1), 1000);
    } else {
      onCountDownEnd?.();
      clearTimeout(resendTimeout);
    }

    return () => {
      clearTimeout(resendTimeout);
    };
  }, [countDownSeconds, onCountDownEnd]);

  const handleClick = () => {
    if (!countDownSeconds) {
      onActionClick?.();
      if (resetOnActionClick) {
        setCountDownSeconds(seconds);
      }
    }
  };

  return (
    <Box
      display="inline-block"
      color={theme.palette.primary.main}
      sx={{ cursor: !countDownSeconds ? 'pointer' : 'default', opacity: !countDownSeconds ? '1' : '0.4' }}
      onClick={handleClick}
      fontSize={14}
    >
      {renderContent ? renderContent(countDownSeconds) : countDownSeconds}
    </Box>
  );
}

export function TwoStepAuthDialog({
  userId,
  open,
  closeHandler,
  mfaEnabled,
  triggerMfaCheck,
}: {
  open: boolean;
  closeHandler: () => void;
  triggerMfaCheck: () => void;
  userId?: string;
  mfaEnabled?: boolean;
}) {
  const [token, setToken] = useState<string>('');
  const theme = useTheme();

  const [triggerResend, { data: resendResponse }] = useLazyGenerateMfaCodeQuery();

  useEffect(() => {
    if (!mfaEnabled) {
      triggerResend(userId || '');
    }
  }, [mfaEnabled, triggerResend, userId]);

  const [submitMfaCodeMutation, { isLoading, error }] = useEnableMfaMutation();
  const [triggerDisableMfa, disableMfaQuery] = useLazyDisableMfaQuery();

  useEffect(() => {
    if (resendResponse && resendResponse.data) {
      setToken(resendResponse.data);
    }
  }, [resendResponse]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<TwoStepAuthFormType>({
    resolver: zodResolver(schema),
    mode: 'onChange',
  });

  const onFormSubmit = async (data: TwoStepAuthFormType) => {
    const results = await submitMfaCodeMutation({ ...data, token });
    const hasError = 'error' in results;

    if (!hasError) {
      triggerMfaCheck();
      closeHandler();
    }
  };

  const resendHandler = useCallback(() => {
    if (!userId) {
      throw new Error('User ID is required to resend the verification code');
    }
    triggerResend(userId);
    setValue('code', '', { shouldValidate: true });
  }, [setValue, triggerResend, userId]);

  const isSubmitDisabled = !isValid || isLoading;

  const disableMfaHandler = async () => {
    await triggerDisableMfa();
    triggerMfaCheck();
    closeHandler();
  };

  const renderCountDownContent: CountDownProps['renderContent'] = useCallback((seconds: number) => {
    return seconds ? `Resend in ${seconds} seconds` : 'Resend';
  }, []);

  if (mfaEnabled) {
    return (
      <SimpleDialog
        title="Turn off 2-Step Verification"
        description="You are about to disable 2-Step Authentication. This means that we will not ask for verification codes when
            you log into your US Global Mail account. Disabling 2-Step Verification will make your account less secure
            and is not recommended. Are you sure you want to proceed?"
        open={open}
        closeHandler={closeHandler}
        primaryButtonText="Continue"
        primaryButtonHandler={disableMfaHandler}
        secondaryButtonText="Cancel"
        secondaryButtonHandler={closeHandler}
        isSubmitDisabled={disableMfaQuery.isLoading || disableMfaQuery.isFetching}
        isSubmitting={disableMfaQuery.isLoading || disableMfaQuery.isFetching}
      />
    );
  }
  return (
    <SimpleDialog
      open={open}
      closeHandler={closeHandler}
      primaryButtonText="Enable"
      secondaryButtonText="Cancel"
      secondaryButtonHandler={closeHandler}
      isSubmitDisabled={isSubmitDisabled}
      isForm={true}
      onSubmit={handleSubmit(onFormSubmit)}
      isSubmitting={isLoading}
    >
      <>
        <Typography mt={1} mb={1} variant="h5">
          Enable 2-Step Verification
        </Typography>
        <Typography color={theme.palette.text.secondary} mb={3}>
          To enable the 2-Step Verification, we need to verify your identity. Please enter the code that has been sent
          to your email address
        </Typography>
        <Box>
          <TextInput
            {...register('code')}
            disabled={isLoading}
            error={!!errors.code}
            helperText={errors.code?.message}
            InputLabelProps={{ shrink: true }}
            required
            placeholder="Enter the verification code"
            fullWidth
          />
        </Box>
        <FlexBox justifyContent="space-between">
          <Typography component="span" color={theme.palette.text.secondary} fontSize={14}>
            Did not receive the verification code?
          </Typography>
          <CountDown seconds={RESEND_COUNTDOWN} renderContent={renderCountDownContent} onActionClick={resendHandler} />
        </FlexBox>
      </>
      {error && <Toast title={error.message} severity="error" />}
    </SimpleDialog>
  );
}

export default React.memo(TwoStepAuthDialog);
