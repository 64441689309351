import { CaptchaType } from '@inbox/constants';
import { Link, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import { DtoDocument, RecordStatus, UserType } from '@usgm/inbox-api-types';
import {
  CircularProgressWithLabel,
  ConfirmationDialog,
  DocumentScanner,
  DropZone,
  FlexBox,
  useDialog,
} from '@usgm/shared-ui';
import { dateUtils, pascalCase } from '@usgm/utils';
import { debounce } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ReCaptchaV2 from '../../../../../../captcha/ReCaptchaV2';
import { ControlledAutoComplete } from '../../../../../../components/form/ControlledAutoComplete';
import { useRecaptchaRequest } from '../../../../../../hooks/useRecaptchaRequest';
import AnalyzingIcon from '../../assets/upload-icons/analyzing.svg?react';
import ExtractingIcon from '../../assets/upload-icons/avatar.svg?react';
import UploadingIcon from '../../assets/upload-icons/uploading.svg?react';
import {
  DOCUMENT_TYPE_OPTIONS_MAPPER,
  PRIMARY_DOCUMENT_OPTIONS,
  SECONDARY_DOCUMENT_OPTIONS,
} from '../../config/documentTypeOptionsMap';

import { useAccountApiClient } from '../../../../../../api/axios/AccountApiContext';
import { normalizeFullName } from '../../helpers';
import DefaultDropZonePlaceholder from './DefaultDropZonePlaceholder';
import { DocumentFields } from './DocumentFields';
import { DocumentPreview } from './DocumentPreview';
import DocumentRejectionInfo from './DocumentRejectionInfo';
import {
  DocumentsSchemaType,
  PrimaryDocumentsFieldSchemaType,
  SecondaryDocumentFieldsSchemaType,
  primaryDocumentsFieldSchema,
  secondaryDocumentFieldsSchema,
} from './schema';

enum UploadStatus {
  Idle = 'Idle',
  Uploading = 'Uploading',
  AnalyzingImage = 'Analyzing image',
  ExtractingData = 'Extracting data',
}

const statusIcons = new Map<UploadStatus, React.ReactNode>([
  [UploadStatus.Idle, null],
  [UploadStatus.Uploading, <UploadingIcon />],
  [UploadStatus.AnalyzingImage, <AnalyzingIcon />],
  [UploadStatus.ExtractingData, <ExtractingIcon />],
]);

export type DocumentFormProps = {
  userType: UserType;
  document: DtoDocument | null;
  onRemove?: () => void;
  onPrimaryNameChange?: (value: string) => void;
};

type ExtractedDocumentEntities = Array<{ confidence: number; entityKey: string; entityValue: string }>;

function addDefaultValuesToFieldsMap(
  schema: Record<string, unknown>,
  fieldsMap: Record<string, string>,
): Record<string, string> {
  Object.keys(schema).forEach((key) => {
    if (!fieldsMap[key]) {
      if (['first_name', 'last_name', 'middle_name', 'full_name'].includes(key)) {
        fieldsMap[key] = '';
      } else {
        fieldsMap[key] = 'N/A';
      }
    }
  }, {} as Record<string, string>);
  return fieldsMap;
}

function constructFields<T>(entities: ExtractedDocumentEntities, userType: UserType): T {
  const fields = entities.reduce((acc, { entityKey, entityValue }) => {
    const fieldPath = entityKey as keyof T;

    acc[fieldPath] = entityValue as T[keyof T];

    return acc;
  }, {} as T);

  return addDefaultValuesToFieldsMap(
    userType === UserType.Primary ? primaryDocumentsFieldSchema.shape : secondaryDocumentFieldsSchema.shape,
    fields as Record<string, string>,
  ) as T;
}

function DocumentForm({ userType, onRemove, document, onPrimaryNameChange }: DocumentFormProps) {
  const { closeDialog: closeExpiredDateDialog, open: openExpiredDate, openDialog: openExpiredDateDialog } = useDialog();
  const { watch, setValue } = useFormContext<DocumentsSchemaType>();
  const accountApiClient = useAccountApiClient();

  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(UploadStatus.Idle);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const changeStatus = debounce(() => {
    setUploadStatus(UploadStatus.ExtractingData);
  }, 2000);

  const extractDocumentInfo = async ({
    formData,
    token,
    captchaVersion,
  }: {
    formData: FormData;
    token: string;
    captchaVersion: number;
  }) => {
    const urlParams = new URLSearchParams({ token, cap_version: `${captchaVersion}` });

    setUploadStatus(UploadStatus.Uploading);
    const {
      data: {
        data: { entities },
      },
    } = await accountApiClient.post<{
      data: { entities: ExtractedDocumentEntities };
    }>(`/onboarding/document/extract?${urlParams.toString()}`, formData, {
      onUploadProgress: (progressEvent) => {
        if (progressEvent.progress === 1) {
          setUploadStatus(UploadStatus.AnalyzingImage);

          changeStatus();
        }
        if (progressEvent.progress) {
          setUploadProgress(progressEvent.progress * 100);
        }
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return entities;
  };

  const { makeRequest, requestId } = useRecaptchaRequest({
    action: CaptchaType.ExtractDocumentInfo,
    apiRequest: extractDocumentInfo,
  });

  const fieldsKey = userType === UserType.Primary ? 'primaryDocumentFields' : 'secondaryDocumentFields';

  const fields = watch(fieldsKey);
  const documentFile = watch(userType === UserType.Primary ? 'primaryFile' : 'secondaryFile');

  const primaryDocumentType = watch('primaryDocumentType');
  const secondaryDocumentType = watch('secondaryDocumentType');

  const documentType = userType === UserType.Primary ? primaryDocumentType : secondaryDocumentType;

  const primaryOptions = useMemo(() => {
    return PRIMARY_DOCUMENT_OPTIONS.filter((option) => option.value !== secondaryDocumentType?.value);
  }, [secondaryDocumentType?.value]);

  const secondaryOptions = useMemo(() => {
    return SECONDARY_DOCUMENT_OPTIONS.filter((option) => option.value !== primaryDocumentType?.value);
  }, [primaryDocumentType?.value]);

  const onFileUpload = (userType: UserType) => async (files: File[]) => {
    if (!documentType) {
      setValue(userType === UserType.Primary ? 'primaryDocumentType' : 'secondaryDocumentType', undefined, {
        shouldValidate: true,
      });
      return;
    }

    if (files.length === 0) {
      return;
    }
    const [file] = files;
    const formData = new FormData();

    setValue(userType === UserType.Primary ? 'primaryFile.file' : 'secondaryFile.file', file);

    formData.append('document_image', file);
    formData.append('user_type', userType);
    if (documentType?.value) {
      formData.append('document_type', documentType?.value);
    }

    const entities = await makeRequest({ formData });
    if (!entities) return;
    changeStatus.cancel();

    if (userType === UserType.Secondary) {
      const fieldsMap = constructFields<SecondaryDocumentFieldsSchemaType>(entities, userType);
      fieldsMap['country'] = 'United States';

      setValue('secondaryDocumentFields', fieldsMap, {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else {
      const fieldsMap = constructFields<PrimaryDocumentsFieldSchemaType>(entities, userType);
      fieldsMap['name'] = normalizeFullName(fieldsMap);

      if (dateUtils.isValidDate(fieldsMap['expiration_date'])) {
        if (new Date(fieldsMap['expiration_date']) < new Date()) {
          openExpiredDateDialog();
        }
      }
      setValue('primaryDocumentFields', fieldsMap, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }

    setUploadProgress(0);
    setUploadStatus(UploadStatus.Idle);
  };

  return (
    <>
      <FlexBox width="100%" flexDirection="column">
        <ControlledAutoComplete<DocumentsSchemaType, 'primaryDocumentType' | 'secondaryDocumentType'>
          name={userType === UserType.Primary ? 'primaryDocumentType' : 'secondaryDocumentType'}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option?.label || '')}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          label={`${userType} ID`}
          placeholder={`Select ${pascalCase(userType)} ID type`}
          defaultValue={null}
          renderOption={(props, option) =>
            option && DOCUMENT_TYPE_OPTIONS_MAPPER[option.value] ? (
              <MenuItem {...props} value={option.value} key={option.value}>
                <ListItemIcon>{DOCUMENT_TYPE_OPTIONS_MAPPER[option.value].icon}</ListItemIcon>
                <ListItemText primary={option.label} />
              </MenuItem>
            ) : null
          }
          options={userType === UserType.Primary ? primaryOptions : secondaryOptions}
        />

        {documentFile?.file || documentFile?.url ? (
          <DocumentPreview
            onRemove={uploadStatus === UploadStatus.Idle ? onRemove : undefined}
            url={documentFile.url}
            file={documentFile.file}
          >
            {[UploadStatus.AnalyzingImage, UploadStatus.ExtractingData].includes(uploadStatus) && <DocumentScanner />}
          </DocumentPreview>
        ) : (
          <DropZone
            onAccept={onFileUpload(userType)}
            placeholder={<DefaultDropZonePlaceholder title={`${pascalCase(userType)} ID`} />}
          />
        )}

        <ReCaptchaV2 requestId={requestId} />

        {fields && <DocumentFields userType={userType} onPrimaryNameChange={onPrimaryNameChange} />}
        {uploadStatus !== UploadStatus.Idle && (
          <FlexBox justifyContent="center" mt={3}>
            <CircularProgressWithLabel
              value={uploadProgress}
              variant={uploadStatus === UploadStatus.Uploading ? 'determinate' : 'indeterminate'}
              label={uploadStatus}
            >
              {statusIcons.get(uploadStatus)}
            </CircularProgressWithLabel>
          </FlexBox>
        )}
        {documentFile?.url && document && document.status === RecordStatus.Rejected && (
          <DocumentRejectionInfo document={document} userType={userType} />
        )}
      </FlexBox>
      {openExpiredDate && (
        <ConfirmationDialog
          hideCancelButton
          open={openExpiredDate}
          onClose={closeExpiredDateDialog}
          title="Document is expired"
          confirmText="OK, Got It"
          onConfirm={closeExpiredDateDialog}
          content={
            <Typography color="text.secondary" textAlign="center">
              We regret to inform you that the primary document you have submitted appears to be expired, according to
              our system's records. To proceed, we kindly ask you to provide a current and valid document. If you
              believe this is a mistake and your document is indeed valid, please do not hesitate to reach out to us for
              assistance. We are committed to resolving any issues promptly. Should you need to discuss this further,
              please click Contact Us below or call us at <Link href="tel:12815968965">+1 281-596-8965</Link>
            </Typography>
          }
        />
      )}
    </>
  );
}

export default DocumentForm;
