import { jwtDecode } from 'jwt-decode';
import {
  type DtoAuthData,
  type CreateAccountParams,
  LoginParams,
  UserInfo,
  DtoGenerateMfaCode,
  SubmitMfaCodeParams,
  DtoTeamMember,
  AcceptTeamMemberInvitationParams,
} from '@usgm/inbox-api-types';
import { accountApi } from '../../api/accountApi';
import { isString } from 'lodash-es';

const DEFAULT_AUTH_QUERY_PARAMS = {
  includeAccountVerification: true,
  simplified: true,
};

const transformAuthHelper = (response: DtoAuthData) => {
  const data = isString(response) ? JSON.parse(response) : response;
  if (data.token) {
    const tokenData = jwtDecode<DtoAuthData>(data.token);
    return { ...data, data: tokenData };
  }
  return data;
};

export const authApi = accountApi.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    createAccount: builder.mutation<DtoAuthData, CreateAccountParams>({
      query: ({
        email,
        firstName,
        lastName,
        password,
        token,
        captchaVersion,
        warehouseId,
        queryParams = DEFAULT_AUTH_QUERY_PARAMS,
      }) => ({
        url: '/signup',
        data: { name: `${firstName} ${lastName}`, email, password },
        method: 'post',
        params: { ...queryParams, token, cap_version: captchaVersion },
        headers: {
          'warehouse-id': warehouseId,
        },
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        // @TODO: Add logout endpoint under auth and switch to that
        url: '/logout',
        method: 'post',
      }),
    }),
    login: builder.mutation<DtoAuthData, LoginParams>({
      query: ({ email, password, captchaVersion, token, queryParams = DEFAULT_AUTH_QUERY_PARAMS }) => {
        return {
          url: `/login`,
          data: { email, password },
          params: { token, cap_version: captchaVersion, ...queryParams },
          method: 'post',
          transformResponse: transformAuthHelper,
        };
      },
    }),
    triggerResetPassword: builder.mutation<{ message: string }, { email: string }>({
      query: ({ email }) => ({
        url: '/reset-password/trigger',
        data: { email },
        method: 'post',
      }),
    }),
    verifyResetPasswordToken: builder.query<string, { token: string | null }>({
      query: ({ token }) => ({
        url: `/reset-password/validate/token`,
        headers: {
          Authorization: token,
        },
        transformResponse: (_, meta) => {
          return meta.authorization;
        },
      }),
    }),
    resetPassword: builder.mutation<void, { password: string; token: string }>({
      query: ({ password, token }) => ({
        url: `/reset-password/update`,
        data: { password },
        headers: {
          Authorization: token,
        },
        method: 'post',
      }),
    }),
    submitMfaCode: builder.mutation<DtoAuthData, SubmitMfaCodeParams>({
      query: ({ code, token, userId }) => ({
        url: `/mfa/code/submit`,
        data: { code, mfa_temp_token: token, user_id: userId },
        method: 'post',
        transformResponse: transformAuthHelper,
      }),
    }),
    generateMfaCode: builder.query<DtoGenerateMfaCode, UserInfo['userUUID']>({
      query: (uuid) => ({
        url: `/mfa/code/generate?user=${uuid}`,
      }),
    }),
    getInvitedTeamMemberByToken: builder.query<DtoTeamMember, { token: string }>({
      query: ({ token }) => ({
        url: `/org/invite/user/get?token=${token}`,
      }),
    }),
    acceptInvitation: builder.mutation<DtoAuthData, AcceptTeamMemberInvitationParams>({
      query: ({ firstName, lastName, password, token }) => ({
        url: '/org/invite/accept',
        data: { name: `${firstName} ${lastName}`, token, password },
        method: 'post',
      }),
    }),
  }),
});

export const {
  useCreateAccountMutation,
  useLoginMutation,
  useTriggerResetPasswordMutation,
  useVerifyResetPasswordTokenQuery,
  useResetPasswordMutation,
  useSubmitMfaCodeMutation,
  useLazyGenerateMfaCodeQuery,
  useGetInvitedTeamMemberByTokenQuery,
  useAcceptInvitationMutation,
} = authApi;
