import React from 'react';

import { useAuth } from '../../../auth/hooks/useAuth';
import { UserAddressCard } from './UserAddressCard';

export function UserAddressBox() {
  const { user } = useAuth();

  return user?.userUUID ? <UserAddressCard userUUID={user.userUUID} /> : null;
}

export default React.memo(UserAddressBox);
