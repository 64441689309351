import { ImageCell } from '../DataTable';

import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import { Tooltip } from '../Tooltip';
import { ListItemWarningBadge } from './ListItemWarningBadge';

export type ItemImageWithWarningProps = {
  showWarning?: boolean;
  imageUrl: string;
  tooltipText?: string;
  enablePreview?: boolean;
};

export function ItemImageWithWarning({
  imageUrl,
  showWarning,
  tooltipText,
  enablePreview = true,
}: ItemImageWithWarningProps) {
  return (
    <ImageCell enablePreview={enablePreview} imageUrl={imageUrl}>
      {showWarning && (
        <Tooltip title={tooltipText}>
          <ListItemWarningBadge>
            <PriorityHighOutlinedIcon fontSize="small" />
          </ListItemWarningBadge>
        </Tooltip>
      )}
    </ImageCell>
  );
}

export default ItemImageWithWarning;
