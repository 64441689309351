import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { HeaderStepperContainer } from './HeaderStepperContainer';
import { GeneralStepper } from './GeneralStepper';
import { UnorderedStepper } from './UnorderedStepper';
import { useMobileMode } from '../hooks';

export type PageStepperProps = {
  steps: Array<{ path?: string; label: string }>;
};

export function PageStepper({ steps }: PageStepperProps) {
  const isMobile = useMobileMode();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const activeIndex = steps.findIndex((step) => step.path && pathname.includes(step.path));
  const stepsWithClickHandler = useMemo(() => {
    return steps.map((step) => ({
      label: step.label,
      onClick: () => {
        if (step.path) {
          navigate(step.path);
        }
      },
    }));
  }, [navigate, steps]);

  return (
    <HeaderStepperContainer>
      {isMobile ? (
        <UnorderedStepper activeStep={activeIndex} steps={stepsWithClickHandler} />
      ) : (
        <GeneralStepper activeStep={activeIndex} steps={stepsWithClickHandler} version="filled" />
      )}
    </HeaderStepperContainer>
  );
}
