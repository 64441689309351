import { Box } from '@mui/material';
import { GeneralStepper, GeneralStepperProps, MessageBox, ScrollableArea } from '@usgm/shared-ui';
import { ContentContainer } from '../../../../../components/DynamicSidebar/ContentContainer';

import { selectSelectedMails } from '../../../mailsSlice';

import { ErrorBoundary } from '@sentry/react';
import { useMemo } from 'react';
import { useAppSelector } from '../../../../../../../store';
import ShipmentRequestSuccessMessage from '../ShipmentRequestSuccessMessage';
import { useInitMailsShipment } from './hooks/useInitMailsShipment';
import {
  selectIsMailsDeclarationsValid,
  selectShipmentType,
  selectShippingDeclarationsRequired,
  selectSubmittedShipment,
} from './mailsShipmentSlice';
import { ShipmentProvider } from './steps/ShipmentContext';
import { ALL_SHIPMENT_STEPS } from './steps/config';
import { useShipmentContext } from './steps/useShipmentContext';
import { ShipmentCategory } from '@usgm/inbox-api-types';

export type MailsShipmentRequestProps = {
  handleClose: () => void;
};

function ShipmentStepper() {
  const { activeStepIndex, setActiveStepIndex, activeStep, stepsList } = useShipmentContext();

  const stepperItems: GeneralStepperProps['steps'] = stepsList.map((step, index) => {
    const isSomePreviousStepInvalid = stepsList.some((s, i) => i < index && !s.isValid);
    return {
      label: step.title,
      onClick: isSomePreviousStepInvalid ? undefined : () => setActiveStepIndex(index),
      disabled: isSomePreviousStepInvalid,
    };
  });

  return (
    <>
      <ContentContainer height={100}>
        <GeneralStepper verticalOrder={true} steps={stepperItems} version="filled" activeStep={activeStepIndex} />
      </ContentContainer>
      <ScrollableArea sx={{ height: `calc(100% - 100px)` }}>{activeStep?.component}</ScrollableArea>
    </>
  );
}

function MailsShipmentRequest({ handleClose }: MailsShipmentRequestProps) {
  const submittedShipment = useAppSelector(selectSubmittedShipment);
  const shipmentType = useAppSelector(selectShipmentType);
  const selectedMails = useAppSelector(selectSelectedMails);
  const shippingDeclarationsRequired = useAppSelector(selectShippingDeclarationsRequired);
  const isMailsDeclarationsValid = useAppSelector(selectIsMailsDeclarationsValid);

  const { destinationAddress, selectedRate } = useInitMailsShipment();

  const steps = useMemo(() => {
    const filteredSteps =
      shipmentType === ShipmentCategory.ShipmentRequest
        ? ALL_SHIPMENT_STEPS.filter(
            (step) => step.key !== 'depositSlip' && (step.key !== 'declaration' || shippingDeclarationsRequired),
          )
        : ALL_SHIPMENT_STEPS.filter((step) => step.key !== 'declaration' && step.key !== 'details');
    const newStepsMap = new Map(
      filteredSteps.map((step) => {
        const stepData = { ...step };
        switch (step.key) {
          case 'destination':
            stepData.isValid = !!destinationAddress;
            break;
          case 'shipper':
            stepData.isValid = !!selectedRate;
            break;
          case 'details':
            stepData.isValid = true;
            break;
          case 'declaration':
            stepData.isValid = isMailsDeclarationsValid;
            break;
        }
        return [step.key, stepData];
      }),
    );
    const declarationStep = newStepsMap.get('declaration');
    if (declarationStep) {
      newStepsMap.set('declaration', {
        ...declarationStep,
      });
    }

    return newStepsMap;
  }, [destinationAddress, isMailsDeclarationsValid, selectedRate, shipmentType, shippingDeclarationsRequired]);

  return submittedShipment?.shipmentId ? (
    <ShipmentRequestSuccessMessage handleClose={handleClose} />
  ) : (
    <ErrorBoundary
      onError={(error) => {
        console.error(error);
      }}
      fallback={
        <ContentContainer>
          <MessageBox
            severity="info"
            description="Geolocation and shipping rates are currently unavailable. Please try again later."
          />
        </ContentContainer>
      }
    >
      <Box height="100%">
        <ShipmentProvider mails={selectedMails} defaultSteps={steps}>
          <Box height="100%">
            <ShipmentStepper />
          </Box>
        </ShipmentProvider>
      </Box>
    </ErrorBoundary>
  );
}

export default MailsShipmentRequest;
