import { Divider } from '@mui/material';
import { DetailLabel } from './styled/DetailLabel';
import { DetailRow } from './styled/DetailRow';
import { DetailsHeader } from './styled/DetailsHeader';
import { FlexBox } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import { DtoPackingDimensions } from '@usgm/inbox-api-types';

function PackageDetails({ packingDimensions }: { packingDimensions: DtoPackingDimensions }) {
  return (
    <>
      <DetailsHeader>Package details</DetailsHeader>
      <DetailRow mb={1.5}>
        <DetailLabel component="span">Weight:</DetailLabel> {packingDimensions.weight} lbs
      </DetailRow>
      <Divider />
      <DetailRow display="flex" mt={2}>
        <FlexBox flexDirection="column">
          <DetailLabel component="span">Dimensions</DetailLabel>
          <DetailLabel component="span">({inboxHelpers.DIMENSION_DETAILS_LABEL})</DetailLabel>
        </FlexBox>
        {inboxHelpers.stringifyDimensions({
          dimensions: {
            length: packingDimensions.length,
            width: packingDimensions.width,
            height: packingDimensions.height,
            weight: packingDimensions.weight,
          },
        })}
      </DetailRow>
    </>
  );
}

export default PackageDetails;
