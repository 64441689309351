import { CssBaseline, ThemeProvider } from '@mui/material';
import { darkTheme, lightTheme } from '@usgm/theme';
import { PropsWithChildren } from 'react';
import { selectPrefersDarkMode } from './features/preferences/preferencesSlice';
import { useAppSelector } from './store';

export default function ThemeWrapper({ children }: PropsWithChildren) {
  const prefersDarkMode = useAppSelector(selectPrefersDarkMode);

  const theme = prefersDarkMode ? darkTheme : lightTheme;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
