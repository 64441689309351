import { Button, ConfirmationDialog, useDialog } from '@usgm/shared-ui';
import { TopActionsContainer } from '../../../../components/DynamicSidebar/TopActionsContainer';
import ContactSupport from './ContactSupport';
import { type ShipmentActionsProps } from './ShipmentActionsProps';
import { Typography } from '@mui/material';
import { useCancelShipmentMutation } from '../../api';
import { useAppDispatch } from '../../../../../../store';
import { apiMessagesSlice } from '../../../../../apiMessages/apiMessagesSlice';
import { mailsShipmentSlice } from '../../../mails/components/mailsActionRequests/shipment/mailsShipmentSlice';
import { useMailRequestActionParam } from '../../../mails/hooks/useMailRequestActionParam';
import { MailActionRequest } from '../../../mails/paths';
import { mailsApi, TAG_TYPES as MAIL_TAG_TYPES } from '../../../mails/api';

function EditableShipmentActions({ data, disabled, onActionSuccess }: ShipmentActionsProps) {
  const dispatch = useAppDispatch();
  const confirmCancelationDialog = useDialog<string>({ open: false, value: '' });
  const { setRequest } = useMailRequestActionParam();

  const [cancelShipment, cancelQuery] = useCancelShipmentMutation();

  const handelCancelConfirm = async () => {
    const response = await cancelShipment({ id: data.uuid });
    const hasError = 'error' in response;
    dispatch(
      apiMessagesSlice.actions.createMessage({
        severity: hasError ? 'error' : 'success',
        text: hasError ? 'Unable to cancel the request. Please try again.' : 'Request has been canceled successfully.',
      }),
    );
    if (!hasError) {
      onActionSuccess?.();

      dispatch(
        mailsApi.util.invalidateTags([
          ...data.mailItems.map((item) => ({ type: MAIL_TAG_TYPES.MAIL_ITEM, id: item.mailId })),
          MAIL_TAG_TYPES.MAILS_LIST,
        ]),
      );
    }
  };

  const handleEditClick = () => {
    setRequest(MailActionRequest.Ship);
    dispatch(mailsShipmentSlice.actions.editShipment(data));
  };

  return (
    <>
      <TopActionsContainer>
        {data.isCancelAllowed && (
          <Button
            disabled={disabled}
            onClick={() => confirmCancelationDialog.openDialog()}
            variant="outlined"
            fullWidth
            isLoading={cancelQuery.isLoading}
          >
            Cancel Request
          </Button>
        )}
        {data.isEditAllowed && (
          <Button onClick={handleEditClick} disabled={disabled} variant="contained" fullWidth>
            {data.hasIssue ? 'Resolve Issue' : 'Edit'}
          </Button>
        )}
        {data.isContactSupportAllowed && <ContactSupport data={data} inlineMode />}
      </TopActionsContainer>
      <ConfirmationDialog
        open={confirmCancelationDialog.open}
        confirmText="Confirm"
        cancelText="Cancel"
        onCancel={confirmCancelationDialog.closeDialog}
        onClose={confirmCancelationDialog.closeDialog}
        onConfirm={handelCancelConfirm}
        content={
          <>
            <Typography mb={1} textAlign="center" variant="h5">
              Please confirm!
            </Typography>
            <Typography textAlign="center">Are you sure you want to cancel this request?</Typography>
          </>
        }
      />
    </>
  );
}

export default EditableShipmentActions;
