import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Box, Button, styled } from '@mui/material';
import { FlexBox, Toast } from '@usgm/shared-ui';

import { useCopyToClipboard } from '../hooks/useCopyToClipBoard';

const Label = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.customColors.dark[400],
}));

const Input = styled('div', { shouldForwardProp: (prop) => prop !== 'clickable' })<{ clickable: boolean }>(
  ({ theme, clickable }) => ({
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(2),
    flex: '1 1 auto',
    cursor: clickable ? 'pointer' : 'default',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
);

const CopyButton = styled(Button)(({ theme }) => ({
  alignSelf: 'stretch',
  width: '56px',
  height: '56px',
  borderRadius: theme.shape.borderRadius,
}));

export type CopyCodeProps = {
  code: string;
  label?: string;
  onClick?: () => void;
  fullWidth?: boolean;
};

export function CopyCode({ code, label, fullWidth = true, onClick }: CopyCodeProps) {
  const { data, handleCopy, showMessage, clearMessage } = useCopyToClipboard({ label, textToCopy: code });

  return (
    <Box width={fullWidth ? '100%' : 'auto'}>
      {label ? <Label>{label}</Label> : null}
      <FlexBox gap={2}>
        <Input clickable={!!onClick} onClick={onClick}>
          {code}
        </Input>

        <CopyButton onClick={handleCopy} size="small" variant="contained">
          <ContentCopyOutlinedIcon />
        </CopyButton>
      </FlexBox>
      {showMessage && <Toast title={data.message} onClose={clearMessage} severity={data.error ? 'error' : 'success'} />}
    </Box>
  );
}

export default CopyCode;
