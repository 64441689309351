import { useMemo } from 'react';
import { selectActiveSubscription, useGetSubscriptionsQuery } from '../api/paymentApi';
import { useAuth } from '../features/auth/hooks/useAuth';

export function useActiveSubscription() {
  const { user } = useAuth();
  const selectActiveSubscriptionData = useMemo(selectActiveSubscription, []);

  const subscriptionsQuery = useGetSubscriptionsQuery(null, {
    skip: !user,
    selectFromResult: (result) => ({
      ...result,
      activeSubscription: selectActiveSubscriptionData(result.data),
    }),
  });

  return subscriptionsQuery;
}
