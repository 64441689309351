import { Typography, useTheme } from '@mui/material';
import { type BaseScan } from '@usgm/inbox-api-types';
import { TextCell } from '@usgm/shared-ui';

export type UserInstructionCellProps = {
  data: BaseScan;
  maxWidth?: string;
};
function UserInstructionCell({ data, maxWidth = '250px' }: UserInstructionCellProps) {
  const theme = useTheme();
  const hasIssue = !!data.rejectReason;
  return (
    <TextCell maxWidth={maxWidth}>
      <Typography whiteSpace="wrap" color={hasIssue ? theme.palette.error.main : undefined}>
        {hasIssue ? `Scan request rejected: ${data.rejectReason}` : data.instructions}
      </Typography>
    </TextCell>
  );
}

export default UserInstructionCell;
