import { snakeCaseKeys } from '../snakeCaseKeys';
import { normalizeAccountNameData } from './normalizeAccountNameData';
import { AccountNamesFormSchemaType, CompanyInfoFormSchema } from './schemas';

export function prepareCompanyInfo(
  {
    city,
    country,
    name,
    relationType,
    state,
    streetAddress,
    typeOfBusiness,
    zipCode,
    placeOfRegistration,
    uuid,
    phoneNumber,
  }: Partial<CompanyInfoFormSchema['accountNames'][number]> & AccountNamesFormSchemaType['accountNames'][number],
  isFromAccountVerification = true,
) {
  return {
    ...normalizeAccountNameData({ name, relationType, uuid }, isFromAccountVerification),
    company_address: snakeCaseKeys({
      addressLine: streetAddress,
      city: city || '',
      state: state || '',
      country: country?.code || '',
      postalCode: zipCode,
      companyPlaceOfRegistration: placeOfRegistration,
      companyTypeOfBusiness: typeOfBusiness,
      phoneNumber,
    }),
  };
}
