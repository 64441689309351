import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';

import { DtoUpdateWarehouse, TokenData, type DtoWarehouse, type Location } from '@usgm/inbox-api-types';
import { RootState } from '../../store';

import { haversineDistance, inboxHelpers } from '@usgm/utils';

import { jwtDecode } from 'jwt-decode';
import { addressPickerApi } from './api';
import { MIN_DISTANCE } from './config';
import { warehousesConfig } from '@inbox/constants';

const FEATURE_NAME = 'ADDRESS_PICKER';

export type TMeasuredWarehouse = DtoWarehouse & { distance: number };

type TState = EntityState<DtoWarehouse, number> & {
  selectedLocation: null | Location;
  measuredWarehouses: Array<TMeasuredWarehouse>;
  offset: number;
  place: google.maps.places.AutocompletePrediction | null;
  pickedWarehouse: null | DtoWarehouse['id'];
};

const sortFn = (a: DtoWarehouse, b: DtoWarehouse) => a.address.state.localeCompare(b.address.state);

const warehousesAdapter = createEntityAdapter<DtoWarehouse>();

const initialState: TState = warehousesAdapter.getInitialState({
  selectedLocation: null,
  measuredWarehouses: [],
  offset: 0,
  place: null,
  pickedWarehouse: inboxHelpers.getStorageManager().getItem('selectedWarehouse') || null,
});

export const addressPickerSlice = createSlice({
  name: FEATURE_NAME,
  initialState,
  reducers: {
    setSelectedLocation: (state, action: PayloadAction<TState['selectedLocation']>) => {
      state.selectedLocation = action.payload;
      if (action.payload) {
        state.measuredWarehouses = state.ids
          .map((id) => {
            const warehouse = state.entities[id];
            return {
              ...warehouse,
              distance: haversineDistance(action.payload as Location, warehouse.location, 'mile'),
            };
          })
          .sort((a, b) => a.distance - b.distance);
      } else {
        state.measuredWarehouses = [];
        state.offset = 0;
      }
    },
    setPlace: (state, action: PayloadAction<TState['place']>) => {
      state.place = action.payload;
    },
    loadMoreWarehouses: (state) => {
      state.offset = filterByDistanceRange(state.measuredWarehouses, state.offset).length;
    },
    setWarehouse: (state, action: PayloadAction<DtoWarehouse['id']>) => {
      state.pickedWarehouse = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(addressPickerApi.endpoints.getWarehouses.matchFulfilled), (state, action) => {
      const recommended: DtoWarehouse[] = [];
      const notRecommended: DtoWarehouse[] = [];

      action.payload.results.forEach((warehouse) => {
        if (warehousesConfig.RECOMMENDED_WAREHOUSES.includes(warehouse.id)) {
          recommended.push({ ...warehouse, recommended: true });
        } else {
          notRecommended.push({ ...warehouse, recommended: false });
        }
      });

      state.measuredWarehouses = [];
      state.offset = 0;
      warehousesAdapter.setAll(state, recommended.sort().concat(notRecommended.sort(sortFn)));
    });
    builder.addMatcher(
      isAnyOf(addressPickerApi.endpoints.updateWarehouse.matchFulfilled),
      (_, action: PayloadAction<DtoUpdateWarehouse>) => {
        const auth = inboxHelpers.getStorageManager().getItem('authData');
        if (!auth || !action.payload.accessToken) {
          return;
        }
        const tokenData = jwtDecode<TokenData>(action.payload.accessToken);
        const data = {
          ...auth,
          data: tokenData,
          boxNumber: action.payload.warehouseBoxNumber,
          token: action.payload.accessToken,
          accountStatus: auth.accountStatus,
        };
        inboxHelpers.getStorageManager().setItem('authData', data);
        window.location.reload();
      },
    );
  },
});

export const setWarehouse = createAsyncThunk<
  void,
  { id: DtoWarehouse['id']; callback?: () => void },
  { state: RootState }
>(`${FEATURE_NAME}/SET_WAREHOUSE`, async ({ id, callback }, { dispatch, getState }) => {
  const warehouse = warehousesSelectors.selectById(getState(), id);
  if (warehouse) {
    inboxHelpers.getStorageManager().setItem('selectedWarehouse', warehouse.id);
    dispatch(addressPickerSlice.actions.setWarehouse(warehouse.id));
    callback?.();
  }
});

export const { setSelectedLocation, loadMoreWarehouses, setPlace } = addressPickerSlice.actions;

// selectors

const selectState = (state: RootState) => state[FEATURE_NAME];
export const warehousesSelectors = warehousesAdapter.getSelectors(selectState);

export const selectSelectedLocation = (state: RootState) => selectState(state).selectedLocation;
export const selectPlace = (state: RootState) => selectState(state).place;

const filterByDistanceRange = (warehouses: TMeasuredWarehouse[], offset: number) => {
  const nearestWarehouse = warehouses[offset];
  if (!nearestWarehouse) return warehouses;
  const upperBound = Math.ceil(nearestWarehouse.distance / MIN_DISTANCE);
  return warehouses.filter((warehouse) => warehouse.distance <= upperBound * MIN_DISTANCE);
};

export const selectFilteredWarehouses = createSelector(selectState, (state) => {
  if (state.measuredWarehouses.length > 0) {
    return filterByDistanceRange(state.measuredWarehouses, state.offset);
  }
  return state.ids.map((id) => state.entities[id]);
});

export const selectFilteredWarehousesIds = createSelector(selectState, (state) => {
  if (state.measuredWarehouses.length > 0) {
    return filterByDistanceRange(state.measuredWarehouses, state.offset).map((w) => w.id);
  }
  return state.ids;
});

export const selectMeasuredWarehouses = (state: RootState) => selectState(state).measuredWarehouses;

export const selectOffset = (state: RootState) => selectState(state).offset;

export const selectCanLoadMore = (state: RootState) => {
  const { measuredWarehouses, offset } = selectState(state);
  const newOffset = filterByDistanceRange(measuredWarehouses, offset).length;
  return newOffset < measuredWarehouses.length;
};

export const selectPickedWarehouse = (state: RootState) => selectState(state).pickedWarehouse;

export const selectPickedWarehouseData = (state: RootState) => {
  const { entities, pickedWarehouse } = selectState(state);
  return pickedWarehouse ? entities[pickedWarehouse] : null;
};
