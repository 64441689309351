import { GlobalStyles, Theme, styled, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { AccountStatus, NavigationEntityKey } from '@usgm/inbox-api-types';
import { createScrollingStyles, useSubscribeToDocumentEvent } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import { ErrorInfo, useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../store';
import { ApiMessages } from '../../../apiMessages/components/ApiMessages';
import { useAuth } from '../../../auth/hooks/useAuth';
import { useOnboardingData } from '../../features/onboarding/hooks/useOnboardingData';
import { ONBOARDING_PATHS } from '../../features/onboarding/paths';
import { useGetNavigationQuery } from '../../inboxAccountsApi';
import AccountSuspendedDialog from '../AccountSuspendedDialog';
import ErrorBoundaryFallback from './ErrorBoundaryFallback';
import Header from './Header';
import AccountSidebar from './sidebars/AccountSidebar';
import MainSidebar from './sidebars/MainSidebar';

import { selectIsSessionExpired } from '../../../../app/appSlice';
import SessionExpiredScreen from '../SessionExpiredScreen';

const AppWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  backgroundColor: theme.palette.background.base,
  flexGrow: 1,
}));

const createGlobalStyles = (theme: Theme) => ({
  html: {
    height: '100%',
  },
  body: {
    ...createScrollingStyles({ theme }),
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      overflowY: 'scroll',
    },
  },
  '#root': {
    minHeight: '100%',
    display: 'flex',
  },
});

export default function InboxLayout() {
  const theme = useTheme();
  const { user } = useAuth();
  const [resetError, setResetError] = useState(0);
  const [hasError, setHasError] = useState(false);
  const { pathname } = useLocation();
  const { isTeamMember, accountStatus, isAccountUnderPaymentHold } = useAuth();
  const [showSuspendedDialog, setShowSuspendedDialog] = useState(isAccountUnderPaymentHold);
  const { isPrimaryAccountStepsCompleted, accountVerificationState } = useOnboardingData();
  const accountSidebarHidden = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const [mainSidebarOpen, setMainSidebarOpen] = useState(false);
  const [accountSidebarOpen, setAccountSidebarOpen] = useState(false);
  const isSessionExpired = useAppSelector(selectIsSessionExpired);
  const navigationQuery = useGetNavigationQuery(null, {
    skip: !pathname.includes(inboxHelpers.MAIN_PATHS.APP) || !user,
  });

  const handleUsetifulSetDisplay = useCallback((event: Event) => event && setMainSidebarOpen(true), []);

  useSubscribeToDocumentEvent({
    eventName: 'usetiful:setDisplay',
    handler: handleUsetifulSetDisplay,
  });

  const logError = useCallback((error: Error, info: ErrorInfo) => {
    console.error(error, info);
    setHasError(true);
  }, []);

  useEffect(() => {
    return () => {
      if (hasError) {
        setHasError(false);
        setResetError((prev) => prev + 1);
      }
    };
  }, [hasError, pathname]);

  const handleOpenMainSidebar = useCallback(() => {
    setMainSidebarOpen(true);
    setAccountSidebarOpen(false);
  }, []);

  const handleCloseMainSidebar = useCallback(() => {
    setMainSidebarOpen(false);
  }, []);

  const handleOpenAccountSidebar = useCallback(() => {
    setAccountSidebarOpen(true);
    setMainSidebarOpen(false);
  }, []);

  const handleCloseAccountSidebar = useCallback(() => {
    setAccountSidebarOpen(false);
  }, []);

  const handleCloseAccountSuspendedDialog = useCallback(() => {
    setShowSuspendedDialog(false);
  }, []);

  const globalStyles = useMemo(() => createGlobalStyles(theme), [theme]);

  if (
    accountVerificationState &&
    !isPrimaryAccountStepsCompleted &&
    !isTeamMember &&
    navigationQuery.data?.find((item) => item.key === NavigationEntityKey.VerifyAccount) &&
    ![ONBOARDING_PATHS.MAIN, inboxHelpers.APP_PATHS.SETTINGS, inboxHelpers.APP_PATHS.BILLING_HISTORY].some((path) =>
      pathname.includes(path),
    )
  ) {
    return <Navigate to={ONBOARDING_PATHS.MAIN} />;
  }

  if (accountStatus === AccountStatus.Closed && !pathname.includes(inboxHelpers.APP_PATHS.BILLING_HISTORY)) {
    return <Navigate to={`${inboxHelpers.APP_PATHS.BILLING_HISTORY}/invoices`} />;
  }

  return (
    <>
      <SessionExpiredScreen />
      <GlobalStyles styles={globalStyles} />

      <AppWrapper sx={{ filter: isSessionExpired ? 'blur(4px)' : 'none' }} id="inbox-usetiful">
        <Header onOpenMainSidebar={handleOpenMainSidebar} onOpenAccountSidebar={handleOpenAccountSidebar} />
        <MainSidebar handleOpen={handleOpenMainSidebar} open={mainSidebarOpen} handleClose={handleCloseMainSidebar} />
        {accountSidebarHidden && (
          <AccountSidebar
            anchor="right"
            handleOpen={handleOpenAccountSidebar}
            handleClose={handleCloseAccountSidebar}
            open={accountSidebarOpen}
          />
        )}
        <ErrorBoundary key={resetError} fallbackRender={() => <ErrorBoundaryFallback />} onError={logError}>
          <Box component="main" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Toolbar />
            <Outlet />
          </Box>
        </ErrorBoundary>
        <ApiMessages />
        <AccountSuspendedDialog open={showSuspendedDialog} onClose={handleCloseAccountSuspendedDialog} />
      </AppWrapper>
    </>
  );
}
