import { Box, styled, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import { inboxHelpers } from '@usgm/utils';
import { useCallback } from 'react';
import DoneIcon from '../../../../../../../../assets/complete_done.svg?react';
import CrossIcon from '../../../../../../../../assets/cross_filled.svg?react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../store';
import { mailsShipmentSlice, ratesSelectors } from '../mailsShipmentSlice';

export type RatesRowProps = {
  id: string;
  selected?: boolean;
};

const StyledText = styled(Box)(({ theme }) => ({
  fontSize: '0.875rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
  },
}));

const StyledCircle = styled(Box)(({ theme }) => ({
  width: theme.spacing(2.75),
  height: theme.spacing(2.75),
  borderRadius: '50%',
  border: `2px solid ${theme.palette.primary.main}`,
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(1.75),
    height: theme.spacing(1.75),
  },
}));

const RadioContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  width: theme.spacing(3),
  color: theme.palette.mode === 'light' ? theme.palette.primary.dark : theme.palette.primary.light,
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(2),
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  cursor: 'pointer',
  '&:hover': {
    position: 'relative',
    zIndex: 1,
    boxShadow: '0px 0px 10px 0px rgba(51, 51, 51, 0.10), 0px 0px 40px 0px rgba(85, 85, 85, 0.15)',
  },
}));

function RatesRow({ id, selected }: RatesRowProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const rate = useAppSelector((state) => ratesSelectors.selectById(state, id));

  const handleSelectRate = useCallback(() => {
    dispatch(mailsShipmentSlice.actions.setSelectedRate(rate));
  }, [dispatch, rate]);

  if (!rate) {
    return null;
  }

  const totalCharge = rate.totalCharge.amount ? inboxHelpers.formatPrice(Number(rate.totalCharge.amount)) : ' - ';

  return (
    <StyledTableRow onClick={handleSelectRate}>
      <TableCell>
        <StyledText display="flex" alignItems="center" fontWeight={theme.typography.fontWeightBold}>
          <Box mr={1.5}>
            <RadioContainer display="inline-flex">{selected ? <DoneIcon /> : <StyledCircle />}</RadioContainer>
          </Box>
          {rate.serviceName}
        </StyledText>
      </TableCell>
      <TableCell align="right">
        <StyledText>{rate.deliveryBusinessDays ? `${rate.deliveryBusinessDays} days` : 'N/A'} </StyledText>
      </TableCell>
      <TableCell align="right">
        <StyledText color={rate.shipToday ? theme.palette.success.dark : theme.palette.error.dark}>
          {rate.shipToday ? <DoneIcon /> : <CrossIcon />}
        </StyledText>
      </TableCell>
      <TableCell>
        <StyledText color={theme.palette.text.title} fontWeight={theme.typography.fontWeightBold}>
          {totalCharge}
        </StyledText>
      </TableCell>
      <TableCell>
        <Typography color={rate.trackable ? theme.palette.success.dark : theme.palette.error.dark}>
          {rate?.trackable ? <DoneIcon /> : <CrossIcon />}
        </Typography>
      </TableCell>
    </StyledTableRow>
  );
}

export default RatesRow;
