import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './axios/baseQuery';
import { ENVIRONMENT } from '../env';
import {
  type CreateSubscriptionParams,
  type DtoCoupon,
  type DtoReferral,
  type DtoUserSubscription,
  type DtoUserSubscriptionResult,
  type DtoUserScanBundle,
  type DtoPlanResults,
  type DtoChargebeePortalSession,
  type DtoScanBundleOption,
  type DtoPlanDetails,
  type DtoPlan,
  ScanBundleStatus,
  ScanBundleSubscriptionStatus,
  SubscriptionStatus,
} from '@usgm/inbox-api-types';
import { createSelector } from '@reduxjs/toolkit';

const TAG_TYPES = {
  USER_SUBSCRIPTION: 'UserSubscription',
  USER_SCAN_BUNDLES: 'UserScanBundles',
} as const;

export const paymentApi = createApi({
  reducerPath: 'paymentApi',

  baseQuery: axiosBaseQuery(ENVIRONMENT.PAYMENT_API_URL),
  tagTypes: [TAG_TYPES.USER_SUBSCRIPTION, TAG_TYPES.USER_SCAN_BUNDLES],
  endpoints: (builder) => ({
    getCoupon: builder.query<DtoCoupon, string>({
      query: (couponCode) => ({
        url: `/coupon/code/${couponCode}`,
      }),
    }),
    getReferral: builder.query<DtoReferral, string>({
      query: (referralCode) => ({ url: `referrer/check/${referralCode}` }),
    }),
    createSubscription: builder.mutation<{ result: { vendorSubscriptionId: string } }, CreateSubscriptionParams>({
      query: (params) => ({
        url: '/subscribe',
        method: 'post',
        data: params,
      }),
      invalidatesTags: [TAG_TYPES.USER_SUBSCRIPTION],
    }),
    getSubscriptions: builder.query<DtoUserSubscriptionResult, void | null>({
      query: () => ({
        url: '/subscriptions',
        method: 'get',
      }),
      providesTags: (subscriptions) =>
        subscriptions
          ? [...subscriptions.map(({ id }) => ({ type: TAG_TYPES.USER_SUBSCRIPTION, id })), TAG_TYPES.USER_SUBSCRIPTION]
          : [TAG_TYPES.USER_SUBSCRIPTION],
    }),
    getAllPlans: builder.query<DtoPlanResults, { initials: boolean }>({
      query: ({ initials = false }) => ({
        url: '/plans',
        params: { initials },
        method: 'get',
      }),
    }),
    getChargebeePortalSession: builder.query<DtoChargebeePortalSession, { cbCustomerId: string }>({
      query: ({ cbCustomerId }) => ({
        url: `/portal/session/${cbCustomerId}`,
        method: 'get',
      }),
    }),
    // @TODO: scan bundles api doesn't belong to payments api, separate feature needs to be created
    getUserScanBundles: builder.query<{ results: DtoUserScanBundle[] }, void | null>({
      query: () => ({
        method: 'get',
        url: '/scan-bundle/user',
      }),
      providesTags: () => [TAG_TYPES.USER_SCAN_BUNDLES],
    }),
    getScanBundleOptions: builder.query<{ results: DtoScanBundleOption[] }, void | null>({
      query: () => ({
        method: 'get',
        url: '/scan-bundle',
      }),
    }),
    cancelUserScanBundle: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/scan-bundle/cancel/${id}`,
        method: 'post',
      }),
      invalidatesTags: [TAG_TYPES.USER_SCAN_BUNDLES],
    }),
    purchaseUserScanBundle: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/scan-bundle/purchase/${id}`,
        method: 'post',
      }),
      invalidatesTags: [TAG_TYPES.USER_SCAN_BUNDLES],
    }),
    getPlanDetails: builder.query<DtoPlanDetails, { planId: DtoPlan['id'] }>({
      query: ({ planId }) => ({
        url: `/plan/${planId}`,
        method: 'get',
      }),
    }),
  }),
});

export const {
  useGetCouponQuery,
  useGetReferralQuery,
  useCreateSubscriptionMutation,
  useGetSubscriptionsQuery,
  useGetAllPlansQuery,
  useGetChargebeePortalSessionQuery,
  useGetUserScanBundlesQuery,
  useGetScanBundleOptionsQuery,
  useCancelUserScanBundleMutation,
  usePurchaseUserScanBundleMutation,
  useGetPlanDetailsQuery,
} = paymentApi;

// Selectors

export const findActiveSubscription = (data?: DtoUserSubscriptionResult): DtoUserSubscription | null => {
  return (
    data?.find((subscription) =>
      [SubscriptionStatus.Active, SubscriptionStatus.Future, SubscriptionStatus.InTrial].includes(
        subscription.subscriptionStatus,
      ),
    ) || null
  );
};

export const selectActiveSubscription = () => {
  return createSelector(
    (data?: DtoUserSubscriptionResult) => data,
    (data?: DtoUserSubscriptionResult): DtoUserSubscription | null => {
      return findActiveSubscription(data);
    },
  );
};

export const findActiveScanBundleSubscription = (data?: DtoUserScanBundle[]): DtoUserScanBundle | null => {
  return (
    data?.find((userScanBundle) => userScanBundle.subscriptionStatus === ScanBundleSubscriptionStatus.Active) || null
  );
};

export const findActiveScanBundle = (data?: DtoUserScanBundle[]): DtoUserScanBundle | null => {
  return data?.find((userScanBundle) => userScanBundle.status === ScanBundleStatus.Active) || null;
};

export const selectActiveScanBundle = () => {
  return createSelector((data?: DtoUserScanBundle[]) => data, findActiveScanBundle);
};

export const selectActiveScanBundleSubscription = () => {
  return createSelector((data?: DtoUserScanBundle[]) => data, findActiveScanBundleSubscription);
};
