import { useCallback } from 'react';

import { AccountRelation, RecordStatus, type DtoAccountName } from '@usgm/inbox-api-types';
import { FlexBox, MessageBox, MultiSelectionList } from '@usgm/shared-ui';
import { inboxHelpers, pascalCase } from '@usgm/utils';
import { IdStatusBadge } from '../../onboarding/components/stepItems/addIds/AccountIdStatusRow';

type ManageAdditionalNamesProps = ReturnType<typeof inboxHelpers.requiresAccountDeletion> & {
  accountNames?: DtoAccountName[];
  isError?: boolean;
  onSelectionChange: (selected: string[]) => void;
};

function ManageAdditionalNames({
  accountNames,
  maxCount,
  isError,
  onSelectionChange,
  selected,
}: ManageAdditionalNamesProps) {
  const keyExtractor = useCallback((item: DtoAccountName) => item.user.id, []);

  const labelExtractor = useCallback(
    (item: DtoAccountName) => `${item.user.name} (${pascalCase(item.relation.relationType)})`,
    [],
  );

  const isItemDisabled = useCallback(
    (item: DtoAccountName) => item.relation.relationType === AccountRelation.Primary,
    [],
  );

  if (isError) {
    return <MessageBox severity="error" description={inboxHelpers.GENERIC_ERROR_MESSAGE} />;
  }

  if (!accountNames) {
    throw new Error('Account names are not loaded');
  }

  const extraNames = accountNames.length - maxCount;

  return (
    <>
      {extraNames > 0 && (
        <FlexBox justifyContent="right" mb={1}>
          <IdStatusBadge
            width="auto"
            status={extraNames === selected.length ? RecordStatus.Approved : RecordStatus.Rejected}
            text={`Deleting: ${selected.length}/${extraNames}`}
          />
        </FlexBox>
      )}
      <MultiSelectionList<DtoAccountName, string>
        multiple
        selected={selected}
        isItemDisabled={isItemDisabled}
        items={accountNames}
        onChange={onSelectionChange}
        labelExtractor={labelExtractor}
        keyExtractor={keyExtractor}
      />
    </>
  );
}

export default ManageAdditionalNames;
