import { alpha, Avatar, ButtonProps, styled } from '@mui/material';

import { forwardRef, ReactNode } from 'react';
import { TextButton } from './TextButton';

type StyledProps = {
  color: 'primary' | 'error' | 'secondary' | 'info' | 'success' | 'warning' | 'yellow';
  backgroundColor?: string;
};

const StyledTextButton = styled(TextButton)(() => ({
  '.MuiButton-startIcon': {
    marginLeft: 0,
  },
}));

export type InlineIconButtonProps = Omit<ButtonProps, 'children' | 'startIcon' | 'endIcon' | 'color'> &
  Partial<Omit<StyledProps, 'backgroundColor'>> & { label?: string; icon: ReactNode; iconBackgroundColor?: string };

const IconContainer = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'backgroundColor',
})<StyledProps>(({ theme, color, backgroundColor }) => ({
  backgroundColor: backgroundColor || alpha(theme.palette?.[color].main, 0.15),
  color: theme.palette?.[color].main,
  width: theme.spacing(3),
  height: theme.spacing(3),
}));

export default forwardRef<HTMLButtonElement, InlineIconButtonProps>(function RemoveButton(
  { color = 'primary', iconBackgroundColor, icon, label, ...buttonProps },
  ref,
) {
  return (
    <StyledTextButton
      {...buttonProps}
      ref={ref}
      startIcon={
        <IconContainer backgroundColor={iconBackgroundColor} color={color}>
          {icon}
        </IconContainer>
      }
    >
      {label}
    </StyledTextButton>
  );
});
