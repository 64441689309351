import { DEFAULT_DIMENSION_UNIT, DEFAULT_WEIGHT_UNIT } from '@inbox/constants';
import { type Parcel, type ParcelItem, type ShippableItem, type DtoPackingDimensions } from '@usgm/inbox-api-types';
import { constructMailDeclarationParcelItem } from './constructMailDeclarationParcelItem';
import { constructMailParcelItem } from './constructMailParcelItem';

type ConstructMailsParcelParams = {
  packingDimensions: DtoPackingDimensions;
  mails: ShippableItem[];
  originCountry: string;
};

export function constructMailsParcel({ packingDimensions, mails, originCountry }: ConstructMailsParcelParams): Parcel {
  const items: ParcelItem[] = [];

  mails.forEach((mail) => {
    if (mail.shippingDeclarations?.length && mail.shippingDeclarations?.length > 0) {
      const declarationsLength = mail.shippingDeclarations.length;
      mail.shippingDeclarations.forEach((declaration) => {
        items.push(constructMailDeclarationParcelItem({ declaration, mail, originCountry, declarationsLength }));
      });
    } else {
      items.push(constructMailParcelItem({ mail, originCountry }));
    }
  });

  return {
    boxType: 'custom',
    description: `${mails.length} items`,
    dimension: {
      height: packingDimensions.height,
      depth: packingDimensions.length,
      width: packingDimensions.width,
      unit: DEFAULT_DIMENSION_UNIT,
    },
    items,
    weight: {
      value: packingDimensions.weight,
      unit: DEFAULT_WEIGHT_UNIT,
    },
  };
}
