import { TabsPathParams } from './TabsPathParams';

export function createPathGenerator<T>(path: string) {
  return ({ tab, folderId, itemId, params }: TabsPathParams<T>) => {
    const searchParams = params ? new URLSearchParams(params).toString() : '';

    return `${path}/${folderId ? `folder/${folderId}/${tab}` : tab}${itemId ? `/${itemId}` : ''}${
      searchParams ? `?${searchParams}` : ''
    }`;
  };
}
