import {
  type DtoFileInfo,
  type DtoCreditsList,
  type DtoInvoicesList,
  type DtoUnbilledChargesList,
  type GetBillingPdfDetailsParams,
  type DtoInvoice,
  type DtoCredit,
  type CollectInvoicePaymentParams,
} from '@usgm/inbox-api-types';
import { paymentApi as basePaymentApi } from '../../../../api/paymentApi';
import { snakeCaseKeys } from '@usgm/utils';

const DEFAULT_LIMIT = 25;

type CursorBasedPaginationParams = {
  offset?: string;
  limit?: number;
};

const TAG_TYPES = {
  INVOICE: 'Invoice',
};

export const billingsApi = basePaymentApi
  .enhanceEndpoints({
    addTagTypes: [TAG_TYPES.INVOICE],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCreditsList: builder.query<DtoCreditsList, CursorBasedPaginationParams>({
        query: ({ limit = DEFAULT_LIMIT, offset }) => ({
          url: `/credit-notes/v2`,
          method: 'get',
          params: {
            limit,
            offset,
          },
        }),
      }),
      getInvoicesList: builder.query<DtoInvoicesList, CursorBasedPaginationParams>({
        query: ({ limit = DEFAULT_LIMIT, offset }) => ({
          url: `/invoices/v2`,
          method: 'get',
          params: {
            limit,
            offset,
          },
        }),
      }),
      getUnbilledChargesList: builder.query<DtoUnbilledChargesList, CursorBasedPaginationParams>({
        query: ({ limit = DEFAULT_LIMIT, offset }) => ({
          url: `/unbilled-charges/v2`,
          method: 'get',
          params: {
            limit,
            offset,
          },
        }),
      }),
      getBillingPdfDetails: builder.query<DtoFileInfo, GetBillingPdfDetailsParams>({
        query: ({ type, itemId, invoiceId }) => ({
          url: `/${type}/pdf${invoiceId ? `/${invoiceId}` : ''}/${itemId}`,
          method: 'get',
        }),
      }),
      getInvoiceById: builder.query<DtoInvoice, { id: string }>({
        query: ({ id }) => ({
          url: `/invoices/v2/${id}`,
          method: 'get',
        }),
        providesTags: (result) => (result ? [{ type: TAG_TYPES.INVOICE, id: result.id }] : []),
      }),
      getCreditNoteById: builder.query<DtoCredit, { id: string }>({
        query: ({ id }) => ({
          url: `/credit-notes/v2/${id}`,
          method: 'get',
        }),
      }),
      collectInvoicePayment: builder.mutation<{ message: string }, CollectInvoicePaymentParams>({
        query: (data) => ({
          url: `/payment/collect/new`,
          method: 'post',
          data: snakeCaseKeys(data),
        }),
        invalidatesTags: (_, error, { vendorInvoiceId }) =>
          !error ? [{ type: TAG_TYPES.INVOICE, id: vendorInvoiceId }] : [],
      }),
    }),
  });

export const {
  useGetInvoicesListQuery,
  useGetCreditsListQuery,
  useGetUnbilledChargesListQuery,
  useGetBillingPdfDetailsQuery,
  useGetInvoiceByIdQuery,
  useGetCreditNoteByIdQuery,
  useCollectInvoicePaymentMutation,
} = billingsApi;
