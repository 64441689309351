import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { useAuth } from '../../../../auth/hooks/useAuth';
import DynamicSidebar from '../../../components/DynamicSidebar';
import { DynamicSidebarPath, useDynamicSidebar } from '../../../components/DynamicSidebar/useDynamicSidebar';
import HeaderActionButton, { THeaderActionButton } from '../../../components/HeaderActions/HeaderActionButton';
import ReferralInfo from './ReferralInfo';

export function ReferFriendAction({ asNavigationItem = false }: THeaderActionButton) {
  const { user } = useAuth();
  const { isSelected, toggleSidebar } = useDynamicSidebar(DynamicSidebarPath.ReferAFriend);
  const handleClose = () => toggleSidebar();

  return (
    <>
      <HeaderActionButton
        asNavigationItem={asNavigationItem}
        selected={isSelected}
        onClick={toggleSidebar}
        icon={<PeopleOutlineIcon />}
        label="Refer A Friend"
      />
      <DynamicSidebar
        open={isSelected}
        handleClose={handleClose}
        path={DynamicSidebarPath.Announcements}
        title="Refer a friend"
      >
        {user && <ReferralInfo userId={user.userUUID} />}
      </DynamicSidebar>
    </>
  );
}

export default ReferFriendAction;
