import FacebookIcon from '../../../assets/social-icons/Facebook.svg?react';
import LinkedInIcon from '../../../assets/social-icons/Linkedin.svg?react';
import XIcon from '../../../assets/social-icons/Twitter.svg?react';

import FooterSection from './types/TFooterSection';
import SocialLink from './types/TSocialLink';

import { inboxHelpers } from '@usgm/utils';
import { webSiteUrl } from '../../../helpers/urlHelper';
import TPublicNavigationItem from './types/TPublicNavigationItem';
import TNavigationItem from './types/TNavigationItem';

import VirtualAddress from './icons/virtual_address.svg?react';
import VirtualMailbox from './icons/virtual_mailbox.svg?react';
import VirtualMailRoom from './icons/virtual_mail_room.svg?react';
import Integration from './icons/integration.svg?react';
import Automation from './icons/automation.svg?react';
import Security from './icons/security.svg?react';
import VirtualTeam from './icons/virtual_team.svg?react';
import Individuals from './icons/individuals.svg?react';
import Enterprise from './icons/enterprise.svg?react';
import PricingIndividual from './icons/pricing_individual.svg?react';
import PricingBusiness from './icons/pricing_business.svg?react';
import AboutUs from './icons/about_us.svg?react';
import AffiliateProgram from './icons/affiliate_program.svg?react';
import WallOfLove from './icons/wall_of_love.svg?react';
import howItWorksUrl from './icons/how_it_works.svg';
import { Box, Typography } from '@mui/material';
import { Image } from '@usgm/shared-ui';

export const MAIN_MOBILE_NAVIGATION: TNavigationItem[] = [
  {
    label: 'Products',
    subMenu: [
      {
        label: 'Mail Forwarding',
        href: 'https://www.usglobalmail.com/virtual-address/',
        icon: <VirtualAddress />,
      },
      {
        label: 'Virtual Mailbox',
        href: 'https://www.usglobalmail.com/virtual-mailbox/',
        icon: <VirtualMailbox />,
      },
      {
        label: 'Virtual Mailroom',
        href: 'https://www.usglobalmail.com/enterprise-mail-management/',
        icon: <VirtualMailRoom />,
      },
      {
        label: 'Integrations',
        href: 'https://www.usglobalmail.com/#our_plan_section',
        icon: <Integration />,
      },
      {
        label: 'Features & Automations',
        href: 'https://www.usglobalmail.com/#our_plan_section',
        icon: <Automation />,
      },
      {
        label: 'Security',
        href: 'https://www.usglobalmail.com/secure-mailbox/',
        icon: <Security />,
      },
    ],
  },
  {
    label: 'Solutions',
    subMenu: [
      {
        label: 'Individuals',
        href: 'https://www.usglobalmail.com/individuals/',
        icon: <Individuals />,
      },
      {
        label: 'Virtual Office',
        href: 'https://www.usglobalmail.com/mail-online-services/virtual-business-address/',
        icon: <VirtualTeam />,
      },
      {
        label: 'Enterprise',
        href: 'https://www.usglobalmail.com/enterprise-mail-management/',
        icon: <Enterprise />,
      },
    ],
  },
  {
    label: 'Addresses',
    href: inboxHelpers.MAIN_PATHS.SELECT_VIRTUAL_ADDRESS,
    internal: true,
  },
  {
    label: 'Pricing',
    subMenu: [
      {
        label: 'Individuals',
        href: 'https://www.usglobalmail.com/pricing/',
        icon: <PricingIndividual />,
      },
      {
        label: 'Business',
        href: 'https://www.usglobalmail.com/pricing-enterprise/',
        icon: <PricingBusiness />,
      },
    ],
  },
  {
    label: 'Company',
    subMenu: [
      {
        label: 'About',
        href: 'https://www.usglobalmail.com/about-us/',
        icon: <AboutUs />,
      },
      {
        label: 'Affiliate Program',
        href: 'https://www.usglobalmail.com/affiliate/',
        icon: <AffiliateProgram />,
      },
      {
        label: 'Wall of Love',
        href: 'https://www.usglobalmail.com/reviews/',
        icon: <WallOfLove />,
      },
      {
        label: 'Contact Us',
        href: 'https://www.usglobalmail.com/contact-us/',
      },
      {
        label: "FAQ's",
        href: 'https://www.usglobalmail.com/help_category/frequently-asked-questions/',
      },
      {
        label: 'Blog',
        href: 'https://www.usglobalmail.com/blog/',
      },
    ],
  },
];

export const MAIN_NAVIGATION: TPublicNavigationItem[] = [
  {
    label: 'Product',
    fullWidthSubMenu: true,
    subMenuWidth: 1280,
    subMenu: [
      {
        title: 'OUR SOLUTIONS',
        subMenuSection: [
          {
            label: 'Mail Forwarding',
            href: 'https://www.usglobalmail.com/virtual-address/',
            description: (
              <>
                <Typography>Get a permanent US address and mailbox for</Typography>
                <Typography>business or personal use</Typography>
              </>
            ),
            leftIcon: <VirtualAddress />,
          },
          {
            label: 'Virtual Mailbox',
            href: 'https://www.usglobalmail.com/virtual-mailbox/',
            description: (
              <>
                <Typography>Manage physical mail online, from anywhere. Scan,</Typography>
                <Typography>forward, shred, deposit checks and more</Typography>
              </>
            ),
            leftIcon: <VirtualMailbox />,
          },
          {
            label: 'Virtual Mailroom',
            href: 'https://www.usglobalmail.com/digital-mailroom/',
            description: (
              <>
                <Typography>Streamline mail operations across multiple </Typography>
                <Typography>locations into one digital mailbox</Typography>
              </>
            ),
            leftIcon: <VirtualMailRoom />,
          },
        ],
      },
      {
        title: 'DISCOVER MORE',
        subMenuSection: [
          {
            label: 'Integrations',
            href: 'https://www.usglobalmail.com/#our_plan_section',
            description: (
              <>
                <Typography>Integrates with your favorite</Typography>
                <Typography>apps like Dropbox, Quickbooks</Typography>
                <Typography>and Slack</Typography>
              </>
            ),
            leftIcon: <Integration />,
          },
          {
            label: 'Features & Automations',
            href: 'https://www.usglobalmail.com/#our_plan_section',
            description: (
              <>
                <Typography>Auto ship and scan options for </Typography>
                <Typography>hands off mail management</Typography>
              </>
            ),
            leftIcon: <Automation />,
          },
          {
            label: 'Security',
            href: 'https://www.usglobalmail.com/secure-mailbox/',
            description: (
              <>
                <Typography>Industry leading security with SOC2 & HIPAA</Typography>
                <Typography>standards</Typography>
              </>
            ),
            leftIcon: <Security />,
          },
        ],
      },
      {
        title: 'HOW IT WORKS',
        subMenuSection: [
          {
            label: 'How it works',
            href: 'https://www.usglobalmail.com/how-it-works/',
            description: (
              <Box maxWidth={164}>View, read, shred, deposit checks, and ship to anywhere in the world.</Box>
            ),
            leftIcon: <Image responsive src={howItWorksUrl} alt="How it works" />,
            additionalStyles: (theme) => ({
              backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : theme.palette.grey[50],
            }),
          },
        ],
      },
    ],
  },
  {
    label: 'Solutions',
    fullWidthSubMenu: true,
    subMenuWidth: 689,
    subMenu: [
      {
        title: 'IDEAL FOR',
        subMenuSection: [
          {
            label: 'Individuals',
            href: 'https://www.usglobalmail.com/individuals/',
            description: (
              <>
                <Typography>Stay connected to home while on</Typography>
                <Typography>the move</Typography>
              </>
            ),
            leftIcon: <Individuals />,
          },
          {
            label: 'Virtual Office',
            href: 'https://www.usglobalmail.com/virtual-business-address/',
            description: (
              <>
                <Typography>Manage business mail from any</Typography>
                <Typography>corner of the globe</Typography>
              </>
            ),
            leftIcon: <VirtualTeam />,
          },
        ],
      },
      {
        title: '',
        subMenuSection: [
          {
            label: 'Enterprise',
            href: 'https://www.usglobalmail.com/enterprise-mail-management/',
            description: (
              <>
                <Typography>Streamline & digitize mail</Typography>
                <Typography>management for business</Typography>
              </>
            ),
            leftIcon: <Enterprise />,
          },
        ],
      },
    ],
  },
  {
    label: 'Addresses',
    href: inboxHelpers.MAIN_PATHS.SELECT_VIRTUAL_ADDRESS,
    internal: true,
  },
  {
    label: 'Pricing',
    subMenu: [
      {
        title: 'CHOOSE',
        subMenuSection: [
          {
            label: 'Individuals',
            href: 'https://www.usglobalmail.com/pricing/',
            leftIcon: <PricingIndividual />,
          },
          {
            label: 'Business',
            href: 'https://www.usglobalmail.com/pricing-enterprise/',
            leftIcon: <PricingBusiness />,
          },
        ],
      },
    ],
  },
  {
    label: 'Company',
    subMenu: [
      {
        title: 'SINCE 1999',
        subMenuSection: [
          {
            label: 'About Us',
            href: 'https://www.usglobalmail.com/about-us/',
            description: (
              <>
                <Typography>Read about us, our</Typography>
                <Typography>values and team</Typography>
              </>
            ),
            leftIcon: <AboutUs />,
          },
          {
            label: 'Affiliate Program',
            href: 'https://www.usglobalmail.com/affiliate/',
            description: (
              <>
                <Typography>Learn how our affiliate</Typography>
                <Typography>program works</Typography>
              </>
            ),
            leftIcon: <AffiliateProgram />,
          },
          {
            label: 'Wall of Love',
            href: 'https://www.usglobalmail.com/reviews/',
            description: (
              <>
                <Typography>What customers say</Typography>
                <Typography>about us</Typography>
              </>
            ),
            leftIcon: <WallOfLove />,
          },
        ],
      },
      {
        title: 'CONNECT',
        subMenuSection: [
          {
            label: 'Contact Us',
            href: 'https://www.usglobalmail.com/contact-us/',
          },
          {
            label: "FAQ's",
            href: 'https://www.usglobalmail.com/help_category/frequently-asked-questions/',
          },
          {
            label: 'Blog',
            href: 'https://www.usglobalmail.com/blog/',
          },
        ],
      },
    ],
  },
];

export const FOOTER_SECTIONS: FooterSection[] = [
  {
    title: 'Services',
    items: [
      { label: 'Mail Forwarding', href: webSiteUrl('mail-online-services/personal') },
      { label: 'Virtual MailBox', href: webSiteUrl('virtual-mailbox') },
      { label: 'Virtual PO Box', href: webSiteUrl('virtual-po-box') },
      { label: 'Mail Scanning', href: webSiteUrl('mail-scanning') },
      { label: 'Virtual Address', href: webSiteUrl('virtual-address') },
      { label: 'Enterprise Mail', href: webSiteUrl('enterprise-mail-management') },
      { label: 'USPS 1583 Form', href: webSiteUrl('usps-form-1583') },
    ],
  },
  {
    title: 'Solutions For',
    items: [
      { label: 'Individuals ', href: webSiteUrl('individuals') },
      { label: 'Small Businesses', href: webSiteUrl('mail-online-services/business') },
      { label: 'Enterprise', href: webSiteUrl('enterprise-mail-management') },
      { label: 'HR/Relocation  ', href: webSiteUrl('mail-online-services/corporate') },
    ],
  },
  {
    title: 'About',
    items: [
      { label: 'About Us', href: webSiteUrl('about-us') },
      { label: 'Reviews', href: webSiteUrl('reviews') },
      { label: 'Refer a Friend', href: webSiteUrl('referral-program') },
      {
        label: 'Community',
        href: 'https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fgroups%2F8327259%2F',
        external: true,
      },
      { label: 'Job Opportunities', href: webSiteUrl('job-opportunities') },
      { label: 'Affiliate Program', href: webSiteUrl('affiliate') },
      { label: 'Terms', href: webSiteUrl('terms-of-service') },
      { label: 'Privacy Policy', href: webSiteUrl('privacy-policy') },
    ],
  },
  {
    title: 'Support',
    items: [
      { label: 'FAQ', href: webSiteUrl('help_category/frequently-asked-questions') },
      { label: 'Contact Us', href: webSiteUrl('contact-us') },
      { label: 'Shipping Calculator', href: webSiteUrl('get-shipping-rates') },
      { label: 'Knowledge Base', href: webSiteUrl('help') },
      { label: 'Blog', href: webSiteUrl('blog') },
    ],
  },
];

export const SOCIAL_LINKS: SocialLink[] = [
  {
    icon: <FacebookIcon />,
    href: 'https://www.facebook.com/USGlobalMail/',
    label: 'Facebook',
  },
  {
    icon: <LinkedInIcon />,
    href: 'https://www.linkedin.com/company/us-global-mail',
    label: 'Linkedin',
  },
  {
    icon: <XIcon />,
    href: 'https://twitter.com/usglobalmail/',
    label: 'Twitter',
  },
];
