import { DEFAULT_CURRENCY, DEFAULT_WEIGHT_UNIT } from '@inbox/constants';
import { ParcelItem, type ShippableItem, type ShippingDeclaration } from '@usgm/inbox-api-types';

type ConstructMailDeclarationParcelItemParams = {
  declaration: ShippingDeclaration;
  mail: ShippableItem;
  originCountry: string;
  declarationsLength: number;
};

export function constructMailDeclarationParcelItem({
  declaration,
  mail,
  originCountry,
  declarationsLength,
}: ConstructMailDeclarationParcelItemParams): ParcelItem {
  return {
    description: declaration.description,
    quantity: declaration.quantity,
    mailType: mail.mailType,
    originCountry,
    price: {
      amount: declaration.itemValue,
      currency: DEFAULT_CURRENCY,
    },
    weight: {
      // this is either incorrect calculation of item weight or we assume that all items in the declaration have the same weight
      value: parseFloat(mail.weight) / (declarationsLength * declaration.quantity),
      unit: DEFAULT_WEIGHT_UNIT,
    },
  };
}
