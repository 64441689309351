import { DtoMailV2 } from '@usgm/inbox-api-types';

export function getSenderNameAndDescription(sender: DtoMailV2['sender']) {
  let result = '';
  if (sender?.name) {
    result += sender.name;
  }
  if (sender?.description) {
    result += sender.name ? ` (${sender.description})` : sender.description;
  }
  return result;
}
