import { PlanType } from '@usgm/inbox-api-types';
import { useCallback, useState } from 'react';

export const usePlanInteractions = (plans: PlanType[]) => {
  const defaultSelectedCardId = plans.find((plan) => plan === PlanType.Plus || plan === PlanType.Standard);
  const [selectedCardType, setSelectedCardType] = useState<PlanType | undefined>(defaultSelectedCardId);
  const handleCardSelect = useCallback((id: PlanType) => {
    setSelectedCardType(id);
  }, []);

  const handleCardDeselect = useCallback(() => {
    setSelectedCardType(defaultSelectedCardId);
  }, [defaultSelectedCardId]);

  return {
    selectedCardType,
    handleCardSelect,
    handleCardDeselect,
  };
};
