import { styled } from '@mui/material';
import { FlexBox } from '@usgm/shared-ui';

const BorderedBox = styled(FlexBox)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 4,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 0, 0, 0),
    borderRadius: 0,
    border: 'none',
    borderTop: `1px dashed ${theme.palette.divider}`,
  },
}));

export default BorderedBox;
