import { ThemeOptions } from '@mui/material';

export const breakpoints: ThemeOptions['breakpoints'] = {
  values: {
    xs: 0,
    sm: 768,
    md: 1100,
    lg: 1440,
    xl: 1920,
  },
};
