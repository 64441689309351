import { ReportAnIssueType } from '@usgm/inbox-api-types';
import { Button, useDialog } from '@usgm/shared-ui';
import { Fragment } from 'react';
import { useAuth } from '../../../../../auth/hooks/useAuth';
import { TopActionsContainer } from '../../../../components/DynamicSidebar/TopActionsContainer';
import ReportAnIssueDialog from '../../../../components/ReportAnIssueDialog';
import { type ShipmentActionsProps } from './ShipmentActionsProps';

function ContactSupport({ data, inlineMode }: ShipmentActionsProps) {
  const { isAccountUnderPaymentHold } = useAuth();
  const reportDialog = useDialog<string>({ open: false, value: '' });
  const Container = inlineMode ? Fragment : TopActionsContainer;
  return (
    <Container>
      <Button
        disabled={isAccountUnderPaymentHold}
        fullWidth
        variant="outlined"
        color="primary"
        onClick={() => reportDialog.openDialog()}
      >
        Contact Support
      </Button>
      <ReportAnIssueDialog
        open={reportDialog.open}
        reportAnIssueType={ReportAnIssueType.ShipmentRequest}
        requestID={data.uuid}
        title="Contact Support"
        confirmText="Submit"
        onClose={reportDialog.closeDialog}
      />
    </Container>
  );
}

export default ContactSupport;
