import { z } from 'zod';

const MAIL_ACTION_REQUEST_SCHEMA = z.object({
  mailId: z.number(),
  instruction: z.string().optional().default(''),
  isExpedite: z.boolean().optional().default(false),
  imageUrl: z.string().optional().default(''),
});

export const mailActionRequestSchema = z.object({
  requestedItems: z.array(MAIL_ACTION_REQUEST_SCHEMA),
});

export type TMailActionRequestsSchemaType = z.infer<typeof mailActionRequestSchema>;
