import React from 'react';
import { Box, Button, styled } from '@mui/material';

import { inboxHelpers } from '@usgm/utils';

import { useNavigate } from 'react-router-dom';

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  '& > button': {
    minWidth: '120px',
  },
}));

export type HeaderActionsProps = {
  hideSignup?: boolean;
};

export function HeaderActions({ hideSignup }: HeaderActionsProps) {
  const navigate = useNavigate();
  const handleSignInClick = () => {
    navigate(inboxHelpers.MAIN_PATHS.LOGIN);
  };
  const handleSignUpClick = () => {
    navigate(inboxHelpers.MAIN_PATHS.SELECT_VIRTUAL_ADDRESS);
  };
  return (
    <Root>
      <Button onClick={handleSignInClick} size="medium" variant="outlined">
        Login
      </Button>
      {!hideSignup && (
        <Button onClick={handleSignUpClick} size="medium" variant="contained" color="primary">
          Get Started
        </Button>
      )}
    </Root>
  );
}

export default React.memo(HeaderActions);
