export function getTrackingUrl({
  serviceName,
  trackingNumber,
}: {
  serviceName: string;
  trackingNumber: string;
}): string | null {
  if (!serviceName || !trackingNumber) {
    return null;
  }

  const serviceNameLower = serviceName.toLowerCase();

  if (serviceNameLower.includes('fedex')) {
    return `https://www.fedex.com/apps/fedextrack/?tracknumbers=${trackingNumber}`;
  } else if (serviceNameLower.includes('ups')) {
    return `http://wwwapps.ups.com/WebTracking/track?trackNums=${trackingNumber}&track.x=Track`;
  } else if (serviceNameLower.includes('usps')) {
    return `https://tools.usps.com/go/TrackConfirmAction!input.action?t&tLabels=${trackingNumber}`;
  } else if (serviceNameLower.includes('dhl')) {
    return `https://www.dhl.com/content/g0/en/express/tracking.shtml?AWB=${trackingNumber}&brand=DH`;
  } else if (serviceNameLower.includes('aramex')) {
    return `https://www.aramex.com/express/track-results-multiple.aspx?ShipmentNumber=${trackingNumber}`;
  }

  return null;
}
