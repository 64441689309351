export enum AccountStatus {
  New = 'NEW',
  NameAdded = 'NAME_ADDED',
  AddedAddress = 'ADDED_ADDRESS',
  DocumentPrimaryRejected = 'DOCUMENT_PRIMARY_REJECTED',
  VerifyNotarySecondary = 'VERIFY_NOTARY_SECONDARY',
  RejectNotarySecondary = 'REJECT_NOTARY_SECONDARY',
  Approved = 'APPROVED',
  PendingToActivation = 'PENDING_TO_ACTIVATION',
  Suspended = 'SUSPENDED',
  ChangeOfAddressAdded = 'CHANGE_OF_ADDRESS_ADDED',
  DocumentPrimaryAdded = 'DOCUMENT_PRIMARY_ADDED',
  DocumentPrimaryApproved = 'DOCUMENT_PRIMARY_APPROVED',
  DocumentSecondaryAdded = 'DOCUMENT_SECONDARY_ADDED',
  DocumentSecondaryRejected = 'DOCUMENT_SECONDARY_REJECTED',
  DocumentSecondaryApproved = 'DOCUMENT_SECONDARY_APPROVED',
  Form1583Added = 'FORM_1583_ADDED',
  Form1583Rejected = 'FORM_1583_REJECTED',
  Form1583Approved = 'FORM_1583_APPROVED',
  NotarizedDocumentAdded = 'NOTARIZED_DOCUMENT_ADDED',
  NotarizedDocumentApproved = 'NOTARIZED_DOCUMENT_APPROVED',
  NotarizedDocumentRejected = 'NOTARIZED_DOCUMENT_REJECTED',
  Closed = 'CLOSED',
  ChangeOfAddressConfirmed = 'CHANGE_OF_ADDRESS_CONFIRMED',
  PlanSubscribed = 'PLAN_SUBSCRIBED',
}
