import React, { useMemo } from 'react';

import { AccountInfo } from '@usgm/shared-ui';

import { HeaderActions } from './Header/HeaderActions';

import { useAuth } from '../../../../features/auth/hooks/useAuth';

export type HeaderAccountInfoProps = {
  hideUserDetails?: boolean;
  hideSignup?: boolean;
};

export function HeaderAccountInfo({ hideUserDetails, hideSignup }: HeaderAccountInfoProps) {
  const { user, handleLogout } = useAuth();

  const accountMenuItems = useMemo(() => {
    const items = [
      {
        label: 'Logout',
        onClick: handleLogout,
      },
    ];
    return items;
  }, [handleLogout]);

  return user ? (
    <AccountInfo message="Welcome!" name={user.name} menuItems={accountMenuItems} hideUserDetails={hideUserDetails} />
  ) : (
    <HeaderActions hideSignup={hideSignup} />
  );
}

export default React.memo(HeaderAccountInfo);
