import { Drawer, Toolbar, styled } from '@mui/material';
import { createScrollingStyles } from '@usgm/shared-ui';
import React from 'react';
import DynamicSidebarHeader, { DYNAMIC_DRAWER_HEADER_HEIGHT } from './DynamicSidebarHeader';
import { DynamicSidebarPath } from './useDynamicSidebar';

export type DynamicSidebarProps = {
  children?: React.ReactNode;
  path?: DynamicSidebarPath;
  title?: string;
  handleClose?: () => void;
  open?: boolean;
  disableSpacings?: boolean;
};

const DrawerContent = styled('div')<{ disableSpacings: boolean }>(({ theme, disableSpacings }) => ({
  ...createScrollingStyles({ theme, thumbSize: 6, thumbColor: theme.customColors.dark[400] }),
  backgroundColor: theme.palette.background.default,
  height: `calc(100% - ${DYNAMIC_DRAWER_HEADER_HEIGHT}px)`,
  overflowY: 'scroll',
  padding: theme.spacing(disableSpacings ? 0 : 3),
  [theme.breakpoints.up('sm')]: {
    width: 500,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  },
}));

export function DynamicSidebar({ children, handleClose, open, title, disableSpacings = false }: DynamicSidebarProps) {
  return (
    <Drawer anchor="right" onClose={handleClose} open={open}>
      <Toolbar />
      <DynamicSidebarHeader title={title} handleClose={handleClose} />
      <DrawerContent disableSpacings={disableSpacings}>{children}</DrawerContent>
    </Drawer>
  );
}

export default React.memo(DynamicSidebar);
