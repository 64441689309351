import { Box, IconButton, Typography, styled, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WestIcon from '@mui/icons-material/West';
import { FlexBox, TextButton } from '@usgm/shared-ui';

export const DYNAMIC_DRAWER_HEADER_HEIGHT = 64;

export type DynamicSidebarHeaderProps = {
  title?: string;
  subTitle?: string;
  handleClose?: () => void;
};

const Root = styled(Box)(({ theme }) => ({
  height: DYNAMIC_DRAWER_HEADER_HEIGHT,
  padding: theme.spacing(0, 1.5, 0, 2.75),
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export function DynamicSidebarHeader({ handleClose, subTitle, title }: DynamicSidebarHeaderProps) {
  const isMobile = useMediaQuery('(max-width: 600px)');
  return (
    <Root>
      <FlexBox maxWidth="calc(100% - 64px)">
        <TextButton onClick={handleClose} sx={{ minWidth: 'auto', marginRight: 2 }}>
          <WestIcon />
        </TextButton>
        <Typography maxWidth={isMobile ? '180px' : '350px'} noWrap textOverflow="ellipsis" fontWeight="bold">
          {title}
        </Typography>
      </FlexBox>

      <IconButton onClick={handleClose} sx={{ marginLeft: 3 }}>
        <CloseIcon />
      </IconButton>
    </Root>
  );
}

export default DynamicSidebarHeader;
