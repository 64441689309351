import { CardActions, styled } from '@mui/material';

const StyledActions = styled(CardActions)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  display: 'flex',
  flex: '1 1 auto',
  alignItems: 'flex-start',
  marginBottom: 2,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2.5),
  },
}));

export default StyledActions;
