import { RouterTabsProps } from '@usgm/shared-ui';

import { SCAN_CATEGORY_TYPE_LABELS } from '@usgm/inbox-api-types';
import { useCallback, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../auth/hooks/useAuth';
import DynamicSidebar from '../components/DynamicSidebar';
import { TabsLayout } from '../components/layout/TabsLayout';
import { useGetScanByIdQuery } from '../features/scans/api';
import ScanInfo from '../features/scans/components/ScanInfo';
import { DEFAULT_SCAN_TAB, SCAN_TABS, ScansPathParams, createScansPath } from '../features/scans/paths';
import { useNavigatePreservingParams } from '../hooks/useNavigatePreservingParams';
import { useGetUnreadScansInfoQuery } from '../inboxAccountsApi';

export default function ScansPage() {
  const navigate = useNavigatePreservingParams();
  const { isRegularTeamMember } = useAuth();

  const { folderId, itemId, tab } = useParams<ScansPathParams>();
  const { data: unreadScansInfo } = useGetUnreadScansInfoQuery(null);

  const [searchParams] = useSearchParams();
  const mailId = searchParams.get('mailId');

  const { data: scanData } = useGetScanByIdQuery({ id: itemId || '' }, { skip: !itemId });

  const handleClose = useCallback(() => {
    navigate(createScansPath({ tab: tab || DEFAULT_SCAN_TAB, folderId }), {
      paramsToRemove: ['mailId'],
    });
  }, [folderId, navigate, tab]);

  const tabs: RouterTabsProps['tabs'] = useMemo(() => {
    const folderUnreadCountData = unreadScansInfo?.find(
      ({ folderId: id }) => id === Number(folderId) || (id === null && !folderId),
    );
    return SCAN_TABS.map(({ label, value, supportsTeamMembers, supportsRegularTeamMember }) => {
      return {
        path: createScansPath({ tab: value, folderId, itemId }),
        label: label,
        badge: !isRegularTeamMember && value === 'completed' ? folderUnreadCountData?.unreadCount : 0,
        supportsTeamMembers,
        supportsRegularTeamMember,
      };
    });
  }, [folderId, itemId, unreadScansInfo, isRegularTeamMember]);

  const sidebarTitle = scanData?.categoryType ? `${SCAN_CATEGORY_TYPE_LABELS[scanData?.categoryType]} #${itemId}` : '';

  return (
    <>
      <TabsLayout tabs={tabs} />
      <DynamicSidebar disableSpacings open={!!itemId} handleClose={handleClose} title={sidebarTitle}>
        {itemId && mailId && <ScanInfo id={itemId} mailId={Number(mailId)} />}
      </DynamicSidebar>
    </>
  );
}
