import { inboxHelpers } from '@usgm/utils';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

export function TeamMembersNotSupportedRoute() {
  const { isTeamMember } = useAuth();
  if (isTeamMember) {
    return <Navigate to={inboxHelpers.MAIN_PATHS.INBOX} replace />;
  }
  return <Outlet />;
}

export default TeamMembersNotSupportedRoute;
