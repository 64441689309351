import { Alert, AlertProps, AlertTitle, darken, styled } from '@mui/material';
import { forwardRef, ReactNode } from 'react';

const StyledAlert = styled(Alert)(({ theme, severity = 'error' }) => {
  const color =
    theme.palette.mode === 'dark' ? darken(theme.palette[severity].main, 0.4) : theme.palette[severity].main;
  return {
    borderRadius: 12,
    backgroundColor: `${theme.palette.background[severity]} !important`,
    alignItems: 'center',
    '& .MuiAlertTitle-root': {
      color,
      fontWeight: 600,
      marginTop: 0,
      marginBottom: 0,
    },
    width: '100%',
    '& .MuiAlert-message': {
      color,
    },
    '& .MuiSvgIcon-root': {
      color,
    },
  };
});

export type MessageBoxProps = AlertProps & {
  maxWidth?: string | number;
  description?: ReactNode;
};

export const MessageBox = forwardRef<HTMLDivElement, MessageBoxProps>(
  ({ onClose, severity, variant, maxWidth, title, description, children, icon }: MessageBoxProps, ref) => {
    return (
      <StyledAlert
        onClose={onClose}
        severity={severity}
        variant={variant}
        icon={icon}
        sx={{ maxWidth, backgroundColor: variant === 'outlined' ? 'unset !important' : '' }}
        ref={ref}
      >
        <AlertTitle>{title}</AlertTitle>
        {description}
        {children}
      </StyledAlert>
    );
  },
);
