import { AlertProps, Snackbar } from '@mui/material';
import { useState } from 'react';
import { MessageBox } from './MessageBox';

export type ToastProps = {
  variant?: AlertProps['variant'];
  autoHideDuration?: number;
  title?: string;
  onClose?: () => void;
  severity?: AlertProps['severity'];
  description?: string;
  anchorOrigin?: { horizontal: 'left' | 'center' | 'right'; vertical: 'top' | 'bottom' };
  maxWidth?: string | number;
};

export function Toast({
  title = 'Something went wrong. Please try again later!',
  autoHideDuration = 5000,
  onClose,
  severity = 'error',
  variant = 'filled',
  description,
  anchorOrigin = { horizontal: 'center', vertical: 'bottom' },
  maxWidth = 1,
}: ToastProps) {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };
  return (
    <Snackbar onClose={handleClose} anchorOrigin={anchorOrigin} open={open} autoHideDuration={autoHideDuration}>
      <MessageBox
        onClose={handleClose}
        severity={severity}
        variant={variant}
        title={title}
        description={description}
        sx={{ width: '100%', bgcolor: 'background.paper', maxWidth }}
      />
    </Snackbar>
  );
}
