import * as React from "react";
const SvgNoTeamMembersIcon = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512", width: "512px", height: "512px", style: {
  shapeRendering: "geometricPrecision",
  textRendering: "geometricPrecision",
  imageRendering: "optimizeQuality",
  fillRule: "evenodd",
  clipRule: "evenodd"
}, xmlnsXlink: "http://www.w3.org/1999/xlink", ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.959
}, fill: "#4286fc", d: "M 9.5,9.5 C 173.5,9.5 337.5,9.5 501.5,9.5C 501.5,141.5 501.5,273.5 501.5,405.5C 449.499,405.333 397.499,405.5 345.5,406C 315.347,435.319 285.347,464.819 255.5,494.5C 225.653,464.819 195.653,435.319 165.5,406C 113.501,405.5 61.5011,405.333 9.5,405.5C 9.5,273.5 9.5,141.5 9.5,9.5 Z M 31.5,32.5 C 180.833,32.5 330.167,32.5 479.5,32.5C 479.5,149.5 479.5,266.5 479.5,383.5C 431.499,383.333 383.499,383.5 335.5,384C 308.986,410.68 282.32,437.18 255.5,463.5C 228.68,437.18 202.014,410.68 175.5,384C 127.501,383.5 79.5012,383.333 31.5,383.5C 31.5,266.5 31.5,149.5 31.5,32.5 Z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.941
}, fill: "#4285fc", d: "M 246.5,84.5 C 262.211,83.0607 276.877,86.2273 290.5,94C 287.716,101.02 284.55,107.854 281,114.5C 263.997,105.206 246.997,105.206 230,114.5C 226.431,107.862 223.265,101.029 220.5,94C 228.734,89.3713 237.4,86.2047 246.5,84.5 Z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.958
}, fill: "#4285fc", d: "M 199.5,114.5 C 207.143,116.159 214.143,119.159 220.5,123.5C 211.29,141.419 211.29,159.419 220.5,177.5C 214.235,180.646 207.735,183.313 201,185.5C 191.616,168.982 188.95,151.316 193,132.5C 194.902,126.371 197.069,120.371 199.5,114.5 Z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.958
}, fill: "#4285fc", d: "M 307.5,114.5 C 308.978,114.238 310.311,114.571 311.5,115.5C 321.978,138.05 322.145,160.716 312,183.5C 311.612,184.428 310.945,185.095 310,185.5C 303.265,183.313 296.765,180.646 290.5,177.5C 299.706,159.449 299.706,141.449 290.5,123.5C 296.035,120.233 301.702,117.233 307.5,114.5 Z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.941
}, fill: "#4285fc", d: "M 229.5,191.5 C 234.272,193.72 238.938,196.22 243.5,199C 251.5,202.333 259.5,202.333 267.5,199C 271.833,196.5 276.167,194 280.5,191.5C 284.611,197.545 288.611,203.712 292.5,210C 267.833,229.333 243.167,229.333 218.5,210C 222.556,204.053 226.222,197.887 229.5,191.5 Z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.948
}, fill: "#4285fc", d: "M 244.5,233.5 C 257.558,233.351 270.558,234.018 283.5,235.5C 283.371,242.845 283.038,250.178 282.5,257.5C 273.47,257.297 264.47,256.63 255.5,255.5C 246.53,256.63 237.53,257.297 228.5,257.5C 227.962,250.178 227.629,242.845 227.5,235.5C 233.318,234.972 238.984,234.305 244.5,233.5 Z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.94
}, fill: "#4285fc", d: "M 172.5,273.5 C 169.312,268.305 165.979,263.138 162.5,258C 169.58,251.792 177.58,247.125 186.5,244C 195.087,241.52 203.754,239.353 212.5,237.5C 213.647,237.818 214.481,238.484 215,239.5C 216.694,246.138 218.194,252.805 219.5,259.5C 209.355,261.37 199.355,263.87 189.5,267C 184.825,269.336 180.492,272.169 176.5,275.5C 171.086,285.81 169.086,296.81 170.5,308.5C 178.5,308.5 186.5,308.5 194.5,308.5C 194.814,316.087 194.481,323.587 193.5,331C 178.592,331.823 163.759,331.657 149,330.5C 148.333,316.167 148.333,301.833 149,287.5C 150.056,281.332 151.723,275.332 154,269.5C 154.561,268.978 155.228,268.645 156,268.5C 161.428,270.477 166.928,272.143 172.5,273.5 Z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.94
}, fill: "#4285fc", d: "M 338.5,273.5 C 344.072,272.143 349.572,270.477 355,268.5C 355.772,268.645 356.439,268.978 357,269.5C 359.277,275.332 360.944,281.332 362,287.5C 362.667,301.833 362.667,316.167 362,330.5C 347.241,331.657 332.408,331.823 317.5,331C 316.519,323.587 316.186,316.087 316.5,308.5C 324.5,308.5 332.5,308.5 340.5,308.5C 341.625,297.375 339.958,286.708 335.5,276.5C 331.639,273.472 327.639,270.639 323.5,268C 313.669,264.709 303.669,262.042 293.5,260C 292.572,259.612 291.905,258.945 291.5,258C 293,251.836 294.5,245.669 296,239.5C 296.388,238.572 297.055,237.905 298,237.5C 311.748,239.894 324.915,244.061 337.5,250C 341.344,252.422 345.01,255.089 348.5,258C 345.021,263.138 341.688,268.305 338.5,273.5 Z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.915
}, fill: "#4285fc", d: "M 220.5,308.5 C 243.833,308.5 267.167,308.5 290.5,308.5C 290.814,316.087 290.481,323.587 289.5,331C 266.833,331.667 244.167,331.667 221.5,331C 220.519,323.587 220.186,316.087 220.5,308.5 Z" })));
export default SvgNoTeamMembersIcon;
