import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../store';

import { BaseMail } from '@usgm/inbox-api-types';

const FEATURE_NAME = 'MAILS';

type TState = {
  selectedMails: BaseMail[];
  activeItem: BaseMail | null;
};

const initialState: TState = {
  selectedMails: [],
  activeItem: null,
};

export const mailsSlice = createSlice({
  name: FEATURE_NAME,
  initialState,
  reducers: {
    setSelectedMails: (state, action: PayloadAction<BaseMail[]>) => {
      state.selectedMails = action.payload;
    },
    clearSelectedMails: (state) => {
      state.selectedMails = [];
    },
    setActiveItem: (state, action: PayloadAction<BaseMail | null>) => {
      state.activeItem = action.payload;
    },
    clearActiveItem: (state) => {
      state.activeItem = null;
    },
  },
});

const selectState = (state: RootState) => state[FEATURE_NAME];

export const selectSelectedMails = createSelector(selectState, (state) => state.selectedMails);
export const selectActiveMail = createSelector(selectState, (state) => state.activeItem);
