import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, styled, Theme, useMediaQuery } from '@mui/material';

import { Badge, FlexBox } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';

import logoSrc from '../assets/USGM-logo.svg';
import { ENVIRONMENT } from '../env';
import { useAuth } from '../features/auth/hooks/useAuth';
import { usePreferredInboxAppVersion } from '../hooks/usePreferredInboxAppVersion';

const StyledLink = styled(Link)(() => ({
  display: 'inline-flex',
})) as typeof Link;

const StyledBetaBadge = styled(Badge)(() => ({
  cursor: 'pointer',
}));

export function Logo() {
  const { user } = useAuth();
  const { navigateToLegacy } = usePreferredInboxAppVersion();

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const handleChangeAppVersion = () => {
    navigateToLegacy();
  };

  return (
    <FlexBox>
      <StyledLink
        component={user ? RouterLink : 'a'}
        aria-label="US Global Mail"
        to={user ? inboxHelpers.MAIN_PATHS.INBOX : ''}
        href={ENVIRONMENT.WEBSITE_URL}
      >
        <img width={isSmallScreen ? 178 : 230} src={logoSrc} alt="US Global Mail" />
      </StyledLink>
      {user && <StyledBetaBadge badgeContent={'LEGACY'} color={'info'} onClick={handleChangeAppVersion} />}
    </FlexBox>
  );
}

export default React.memo(Logo);
