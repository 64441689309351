import * as React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { Button, SimpleDialog, useDialog } from '@usgm/shared-ui';

export default function ChangeAddressConfirmationDialog({
  disabled = false,
  isLoading,
  onHandleConfirmation,
}: {
  disabled?: boolean;
  isLoading: boolean;
  onHandleConfirmation: () => void;
}) {
  const { closeDialog, open, openDialog } = useDialog();

  const handleConfirmation = () => {
    closeDialog();
    onHandleConfirmation();
  };

  return (
    <>
      <Button disabled={disabled} variant="contained" onClick={openDialog} sx={{ flex: 1 }}>
        {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Confirm'}
      </Button>
      <SimpleDialog
        open={open}
        closeHandler={closeDialog}
        primaryButtonText="Confirm"
        primaryButtonHandler={handleConfirmation}
        secondaryButtonText="Cancel"
        secondaryButtonHandler={closeDialog}
      >
        <>
          <Typography textAlign={'center'} mt={3.5} mb={1} variant="h5">
            Confirm Address Change
          </Typography>
          <Typography textAlign="center" fontSize="0.875rem">
            Are you sure you want to change your address to the selected location? By confirming, you agree to update
            your address details in our system.
          </Typography>
        </>
      </SimpleDialog>
    </>
  );
}
