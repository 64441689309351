import { Box, BoxProps, styled } from '@mui/material';
import { ReactNode, forwardRef } from 'react';
import { CreateArrowStyleParams, createArrowStyles } from './helpers/createArrowStyles';
import { forwardProps } from './helpers/forwardProps';

type RootStyleProps = {
  clickable?: boolean;
  color?: string;
  backgroundColor?: string;
  height?: number;
  direction?: CreateArrowStyleParams['direction'];
  padding?: string;
  foregroundColor?: string;
};

const Root = styled(Box, {
  shouldForwardProp: forwardProps<RootStyleProps>([
    'foregroundColor',
    'backgroundColor',
    'clickable',
    'color',
    'direction',
    'height',
    'padding',
  ]),
})<RootStyleProps>(
  ({
    theme,
    clickable,
    color = theme.palette.common.white,
    backgroundColor = theme.palette.secondary.main,
    height = 40,
    direction = 'right',
    foregroundColor = theme.palette.background.paper,
  }) => ({
    padding: theme.spacing(1, 2),
    display: 'inline-block',
    cursor: clickable ? 'pointer' : 'default',
    color,
    backgroundColor,
    height: 'auto',
    fontWeight: theme.typography.fontWeightBold,
    position: 'relative',
    '&::before': {
      position: 'absolute',
      left: direction === 'left' ? 'auto' : 0,
      right: direction === 'left' ? 0 : 'auto',
      top: direction === 'up' ? 'auto' : 0,
      bottom: direction === 'up' ? 0 : 'auto',
      content: "''",
      ...createArrowStyles({
        color: foregroundColor,
        direction: direction,
        width: `${height / 2}px`,
        height: `${height / 3}px`,
      }),
    },
  }),
);

export interface ArrowBadgeProps extends RootStyleProps, Omit<BoxProps, 'color' | 'height' | 'padding'> {
  children?: ReactNode;
  onClick?: () => void;
  direction?: CreateArrowStyleParams['direction'];
}

export const ArrowBadge = forwardRef<HTMLDivElement, ArrowBadgeProps>(
  ({ children, onClick, height, direction = 'right', ...styleProps }, ref) => {
    return (
      <Root {...styleProps} height={height} direction={direction} onClick={onClick} ref={ref}>
        {children}
      </Root>
    );
  },
);
