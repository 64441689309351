export function downloadFile({
  url,
  fileName,
  opeInNewTab,
}: {
  url: string;
  fileName?: string;
  opeInNewTab?: boolean;
}) {
  const link = document.createElement('a');
  link.href = url;
  if (fileName) {
    link.download = fileName;
  }
  if (opeInNewTab) {
    link.target = '_blank';
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
