const R = 6378137;

/**
 * @description
 *
 * Haversine
 * formula:	a = sin²(Δφ/2) + cos φ1 ⋅ cos φ2 ⋅ sin²(Δλ/2)
 * c = 2 ⋅ atan2( √a, √(1−a) )
 * d = R ⋅ c
 * where	φ is latitude, λ is longitude, R is earth’s radius (mean radius = 6,371km);
 * note that angles need to be in radians to pass to trig functions!
 *
 */
export function haversineDistance(
  point1: { lng: number; lat: number },
  point2: { lng: number; lat: number },
  unit: 'mile' | 'meter' | 'km' = 'meter',
) {
  const radianPoint1 = (point1.lat * Math.PI) / 180; // φ, λ in radians
  const radianPoint2 = (point2.lat * Math.PI) / 180;
  const latDiff = ((point2.lat - point1.lat) * Math.PI) / 180;
  const lngDiff = ((point2.lng - point1.lng) * Math.PI) / 180;

  const a =
    Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
    Math.cos(radianPoint1) * Math.cos(radianPoint2) * Math.sin(lngDiff / 2) * Math.sin(lngDiff / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  if (unit === 'mile') {
    return distance * 0.000621371;
  }
  if (unit === 'km') {
    return distance / 1000;
  }
  return distance;
}
