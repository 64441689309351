import { NavigationEntityKey } from '@usgm/inbox-api-types';
import { inboxHelpers } from '@usgm/utils';
import { Navigate, Outlet } from 'react-router-dom';
import { useGetNavigationQuery } from '../../../inbox/inboxAccountsApi';

export function VerifyAccountAllowedRoute() {
  const { data } = useGetNavigationQuery(null);
  if (!data?.some((it) => it.key === NavigationEntityKey.VerifyAccount)) {
    return <Navigate to={inboxHelpers.MAIN_PATHS.INBOX} replace />;
  }
  return <Outlet />;
}

export default VerifyAccountAllowedRoute;
