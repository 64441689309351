import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, CircularProgress, styled } from '@mui/material';
import { DtoAccountName } from '@usgm/inbox-api-types';
import { Container, FlexBox, Toast } from '@usgm/shared-ui';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { inboxHelpers, onboardingUtils } from '@usgm/utils';
import { useGetAccountNamesWithStatusQuery, useUpdateAccountNamesMutation } from '../../api';
import { ONBOARDING_PATHS } from '../../paths';
import { AddNamesForm, AddNamesFormLayout } from '../AddNamesForm';
import FormActions from '../AddNamesForm/styled/FormActions';
import PageHeading from '../PageHeading';
import { AddNamesLayoutProps } from './AddNamesLayoutProps';
import createAccountNamesMapper from './createAccountNamesMapper';
import { useAccountPurchaseConfirmation } from './hooks/useAccountPurchaseConfirmaion';

const FormCard = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4, 0),
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(4),
}));

function AddNamesPersonalForm({
  accountNames,
  plan,
}: {
  accountNames: DtoAccountName[];
  plan: AddNamesLayoutProps['plan'];
}) {
  const navigate = useNavigate();
  const defaultValues = useMemo(() => {
    return { accountNames: accountNames.map(createAccountNamesMapper()) };
  }, [accountNames]);

  const [updateMutation, { isLoading: isSubmitting, error }] = useUpdateAccountNamesMutation();

  const methods = useForm({
    resolver: zodResolver(onboardingUtils.addNamesFormSchema),
    defaultValues,
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const updateAccountNames = useCallback(
    async (accountNames: onboardingUtils.AccountSchemaType[]) => {
      const response = await updateMutation(
        accountNames.map(({ name, relationType, uuid }) =>
          onboardingUtils.normalizeAccountNameData({ name, relationType, uuid }),
        ),
      );

      if (!('error' in response)) {
        navigate(ONBOARDING_PATHS.ADD_IDS);
      }
    },
    [navigate, updateMutation],
  );

  const { checkIsPurchaseRequired, dialogComponent } = useAccountPurchaseConfirmation<onboardingUtils.TAccountData>({
    accountNamesCount: accountNames.length,
    onConfirmPurchase: updateAccountNames,
  });

  const onSubmit = async ({ accountNames: submittedAccountNames }: onboardingUtils.AccountNamesFormSchemaType) => {
    if (!isDirty && accountNames.length === 1) {
      navigate(ONBOARDING_PATHS.ADD_IDS);
    }
    const purchaseRequired = checkIsPurchaseRequired(submittedAccountNames);
    if (!purchaseRequired) {
      updateAccountNames(submittedAccountNames);
    }
  };

  const {
    formState: { isValid },
  } = methods;

  const canSubmit = (!!isValid && !isSubmitting) || !isDirty;

  return (
    <>
      <FormProvider {...methods}>
        <FormCard onSubmit={handleSubmit(onSubmit)} component="form">
          <AddNamesForm layout={AddNamesFormLayout.Standard} isSubmitting={isSubmitting} activePlan={plan} />
          <FormActions justifyContent="center" display="flex" paddingTop={4}>
            <span>
              <Button sx={{ minWidth: 140 }} disabled={!canSubmit} type="submit" variant="contained" fullWidth>
                {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Continue'}
              </Button>
            </span>
          </FormActions>
        </FormCard>
      </FormProvider>
      {dialogComponent}
      {error && <Toast severity="error" description={error.message || inboxHelpers.GENERIC_ERROR_MESSAGE} />}
    </>
  );
}

export function AddNamesStandard({ plan, user }: AddNamesLayoutProps) {
  const { isLoading, data } = useGetAccountNamesWithStatusQuery(null, { skip: !user });

  return (
    <Container>
      <PageHeading title="Add Name (s)" subtitle="Add companies and individual names associated with your account." />
      {isLoading && (
        <FlexBox justifyContent="center" minHeight={200}>
          <CircularProgress />
        </FlexBox>
      )}

      {data && <AddNamesPersonalForm accountNames={data.data.accountNames} plan={plan} />}
    </Container>
  );
}
