import { DEFAULT_CURRENCY, DEFAULT_WEIGHT_UNIT, NO_DECLARATION_ITEM_DESCRIPTION } from '@inbox/constants';
import { ParcelItem, ShippableItem } from '@usgm/inbox-api-types';

type ConstructMailParcelItemParams = {
  mail: ShippableItem;
  originCountry: string;
};

export function constructMailParcelItem({ mail, originCountry }: ConstructMailParcelItemParams): ParcelItem {
  return {
    description: NO_DECLARATION_ITEM_DESCRIPTION,
    quantity: 1,
    mailType: mail.mailType,
    originCountry,
    price: {
      amount: 0.1,
      currency: DEFAULT_CURRENCY,
    },
    weight: {
      value: parseFloat(mail.weight),
      unit: DEFAULT_WEIGHT_UNIT,
    },
  };
}
