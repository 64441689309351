import { Box, Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps, useTheme } from '@mui/material';

import React, { forwardRef, useMemo } from 'react';
import { useAnchorEl, useMobileMode } from '../hooks';
import { BottomSheet } from './BottomSheet';

export type TooltipProps = MuiTooltipProps & {
  maxWidth?: number | string;
  backgroundColor?: string;
  fontWeight?: string;
  color?: string;
  disableBottomSheet?: boolean;
};

export const Tooltip = forwardRef<unknown, TooltipProps>(
  ({ fontWeight, backgroundColor, disableBottomSheet = false, ...props }, ref) => {
    const theme = useTheme();
    const { handleClick, handleClose, open } = useAnchorEl();
    const isMobile = useMobileMode();
    const onContentClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      handleClick(e);
    };

    const tooltipSlotProps: MuiTooltipProps['slotProps'] = useMemo(() => {
      return {
        arrow: {
          sx: {
            color: backgroundColor || theme.palette.background.tooltip,
          },
        },
        tooltip: {
          sx: {
            fontSize: '0.875rem',
            lineHeight: 1.4,
            padding: '0.75rem',
            maxWidth: props.maxWidth,
            backgroundColor: backgroundColor || theme.palette.background.tooltip,
            fontWeight,
            '& > p': {
              fontSize: '0.875rem',
            },
            '& a': {
              color: theme.palette.primary.main,
              textDecoration: 'none',
            },
          },
        },
      };
    }, [backgroundColor, fontWeight, props.maxWidth, theme.palette.background.tooltip, theme.palette.primary.main]);

    return !disableBottomSheet && isMobile && props.title ? (
      <>
        <Box ref={ref} component="span" onClick={onContentClick}>
          {props.children}
        </Box>
        {open && (
          <BottomSheet wrapContent open={open} closeHandler={handleClose}>
            {props.title}
          </BottomSheet>
        )}
      </>
    ) : (
      <MuiTooltip ref={ref} {...props} slotProps={tooltipSlotProps} />
    );
  },
);
