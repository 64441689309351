import { ReactNode, useId, useState } from 'react';
import { isString } from 'lodash-es';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Box, BoxProps, Typography, styled } from '@mui/material';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    background: 'none',
    boxShadow: 'none',
    '&::before': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root': {
      padding: theme.spacing(3, 0),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      borderBottom: 'none',
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      color: theme.palette.primary.dark,
      margin: 0,
    },
    '&:last-child': {
      '& .MuiAccordionSummary-root': {
        borderBottom: 'none',
      },
    },
  }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  padding: theme.spacing(0),
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: theme.spacing(0),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0, 0, 1.75, 0),
}));

const AccordionContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 4),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
}));

type SimpleFAQAccordionProps = {
  items: { title: ReactNode; content: ReactNode }[];
  containerProps?: BoxProps;
};

export function SimpleFAQAccordion({ items, containerProps }: SimpleFAQAccordionProps) {
  const [expanded, setExpanded] = useState<string | null>(null);
  const faqId = useId();
  const handleChange = (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <AccordionContainer {...containerProps}>
      {items.map(({ title, content }, index) => {
        const id = `${faqId}-${index}`;
        return (
          <Accordion key={id} expanded={expanded === id} onChange={handleChange(id)}>
            <AccordionSummary aria-controls={id} id={id}>
              {isString(title) ? <Typography fontWeight="600">{title}</Typography> : title}
            </AccordionSummary>
            <AccordionDetails>
              {isString(content) ? (
                <Typography fontWeight="400" lineHeight={1.6}>
                  {content}
                </Typography>
              ) : (
                content
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </AccordionContainer>
  );
}
