import { inboxHelpers } from '@usgm/utils';

const ROOT_PATH = `${inboxHelpers.MAIN_PATHS.APP}/${inboxHelpers.APP_PATHS.SETTINGS}`;

export const SETTINGS_ROUTES = {
  ACCOUNT_SETTINGS_ROUTE: 'account-settings',
  ACCOUNT_DETAILS_ROUTE: 'account-details',
  ACCOUNT_NAMES_ROUTE: 'account-names',
  PLAN_DETAILS_ROUTE: 'plan-details',
  ADDRESS_BOOK_ROUTE: 'address-book',
  AUTOMATIONS: 'automations',
  ADD_ONS: 'add-ons',
  SCAN_BUNDLES: 'scan-bundles',
  TEAM_MEMBERS: 'team-members',
  INVITE_TEAM_MEMBERS: 'invite',
  USER_MANAGEMENT: 'user-management',
  EXPORTS: 'exports',
  AUTO_SCAN: 'auto-scan',
  SHIPMENT_SCHEDULING: 'shipment-scheduling',
  ADD_ADDRESS: 'add',
  EDIT_ADDRESS: 'edit',
  ADD_ACCOUNT_NAME: 'add',
  EDIT_ACCOUNT_NAME: 'edit',
  EDIT_ACCOUNT_NAME_DOCUMENTS: 'documents',
  NOTARIZE_ACCOUNT_NAME: 'notarize',
};

const ACCOUNT_SETTINGS_ROOT = `${ROOT_PATH}/${SETTINGS_ROUTES.ACCOUNT_SETTINGS_ROUTE}`;

const ADD_ONS_ROOT = `${ROOT_PATH}/${SETTINGS_ROUTES.ADD_ONS}`;
const AUTOMATIONS_ROOT = `${ROOT_PATH}/${SETTINGS_ROUTES.AUTOMATIONS}`;

export const SETTINGS_PATHS = {
  // Account Settings tabs
  ACCOUNT_SETTINGS_ROOT,
  ACCOUNT_DETAILS: `${ACCOUNT_SETTINGS_ROOT}/${SETTINGS_ROUTES.ACCOUNT_DETAILS_ROUTE}`,
  ACCOUNT_NAMES: `${ACCOUNT_SETTINGS_ROOT}/${SETTINGS_ROUTES.ACCOUNT_NAMES_ROUTE}`,
  ADDRESS_BOOK: `${ACCOUNT_SETTINGS_ROOT}/${SETTINGS_ROUTES.ADDRESS_BOOK_ROUTE}`,
  ADD_ADDRESS: `${ACCOUNT_SETTINGS_ROOT}/${SETTINGS_ROUTES.ADDRESS_BOOK_ROUTE}/${SETTINGS_ROUTES.ADD_ADDRESS}`,
  EDIT_ADDRESS: (uuid: string) =>
    `${ACCOUNT_SETTINGS_ROOT}/${SETTINGS_ROUTES.ADDRESS_BOOK_ROUTE}/${SETTINGS_ROUTES.EDIT_ADDRESS}/${uuid}`,
  ADD_ACCOUNT_NAME: `${ACCOUNT_SETTINGS_ROOT}/${SETTINGS_ROUTES.ACCOUNT_NAMES_ROUTE}/${SETTINGS_ROUTES.ADD_ACCOUNT_NAME}`,
  EDIT_ACCOUNT_NAME: (uuid: string) =>
    `${ACCOUNT_SETTINGS_ROOT}/${SETTINGS_ROUTES.ACCOUNT_NAMES_ROUTE}/${SETTINGS_ROUTES.EDIT_ACCOUNT_NAME}/${uuid}`,

  EDIT_ACCOUNT_NAME_DOCUMENTS: (uuid: string) =>
    `${ACCOUNT_SETTINGS_ROOT}/${SETTINGS_ROUTES.ACCOUNT_NAMES_ROUTE}/${SETTINGS_ROUTES.EDIT_ACCOUNT_NAME_DOCUMENTS}/${uuid}`,
  NOTARIZE_ACCOUNT_NAME: (uuid: string) =>
    `${ACCOUNT_SETTINGS_ROOT}/${SETTINGS_ROUTES.ACCOUNT_NAMES_ROUTE}/${SETTINGS_ROUTES.NOTARIZE_ACCOUNT_NAME}/${uuid}`,

  // Add ons
  ADD_ONS_ROOT,
  SCAN_BUNDLES: `${ADD_ONS_ROOT}/${SETTINGS_ROUTES.SCAN_BUNDLES}`,
  TEAM_MEMBERS: `${ADD_ONS_ROOT}/${SETTINGS_ROUTES.TEAM_MEMBERS}`,
  INVITE_TEAM_MEMBERS: `${ADD_ONS_ROOT}/${SETTINGS_ROUTES.TEAM_MEMBERS}/${SETTINGS_ROUTES.INVITE_TEAM_MEMBERS}`,

  // Automations
  AUTOMATIONS_ROOT: AUTOMATIONS_ROOT,
  AUTO_SCANS: `${AUTOMATIONS_ROOT}/${SETTINGS_ROUTES.AUTO_SCAN}`,
  SHIPMENT_SCHEDULING: `${AUTOMATIONS_ROOT}/${SETTINGS_ROUTES.SHIPMENT_SCHEDULING}`,

  PLAN_DETAILS: `${ACCOUNT_SETTINGS_ROOT}/${SETTINGS_ROUTES.PLAN_DETAILS_ROUTE}`,

  EXPORTS: `${ROOT_PATH}/${SETTINGS_ROUTES.EXPORTS}`,
};
