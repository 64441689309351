import { TextButton, useDialog } from '@usgm/shared-ui';
import { Theme, useMediaQuery } from '@mui/material';

import { InstructionsDialog } from './dialogs/InstructionsDialog';
import { StepItemProps } from '../StepItemProps';
import { StepText } from '../../VerifyAccountSteps';
import { useAuth } from '../../../../../../auth/hooks/useAuth';

export type RedirectMailStepItemContentProps = StepItemProps;

export function RedirectMailStepItemContent({ completed, skipped }: RedirectMailStepItemContentProps) {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const { user } = useAuth();
  const { closeDialog, open, openDialog } = useDialog();

  return (
    <>
      <StepText mb={0.5} completed={completed} skipped={skipped}>
        Submit a change of address with USPS. That is how your mail will come to us.
      </StepText>
      <TextButton onClick={openDialog}>View Instructions</TextButton>
      {user && <InstructionsDialog fullScreen={isMobile} userUUID={user.userUUID} open={open} onClose={closeDialog} />}
    </>
  );
}
