import { Button, CircularProgress, List, ListItem, ListItemText, Typography, styled } from '@mui/material';
import { DtoMailV2, ScanStatus } from '@usgm/inbox-api-types';
import { ConfirmationDialog, FlexBox, cellHelpers, useDialog, useResponsiveModes } from '@usgm/shared-ui';
import { useAuth } from '../../../../../auth/hooks/useAuth';
import DetailsTable, { DetailsTableProps } from '../../../../components/DetailsTable';
import { dateUtils, inboxHelpers } from '@usgm/utils';
import { ReactNode } from 'react';
import { useGetScanPdfFileByIdQuery } from '../../../scans/api';
import PdfViewer from '../../../../components/pdfViewer';
import { useScanDownload } from '../../../scans/hooks/useScanDownload';

export type MailDetailsProps = {
  data: DtoMailV2;
  variant: 'full' | 'compact';
};

const StyledList = styled(List)(({ theme }) => ({
  padding: theme.spacing(0),
  '& .MuiListItem-root': {
    padding: theme.spacing(0),
    '.MuiListItemText-root': {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      '.MuiListItemText-primary': {
        fontWeight: theme.typography.fontWeightBold,
      },
      '.MuiListItemText-secondary': {
        fontSize: '0.875rem',
        fontWeight: theme.typography.fontWeightBold,
        color: theme.customColors.dark[400],
      },
    },
  },
}));

function ListDetails({ items }: { items: Array<{ title: string; value: ReactNode }> }) {
  return (
    <StyledList>
      {items.map((item, index) => (
        <ListItem key={index}>
          <ListItemText primary={item.value} secondary={item.title} />
        </ListItem>
      ))}
    </StyledList>
  );
}

const checkIfScanActionsAvailable = (data: DtoMailV2) => {
  const currentScanStatusTransition = inboxHelpers.findCurrentScanStatusTransitions(data);
  const latestTransition = currentScanStatusTransition
    ? currentScanStatusTransition[currentScanStatusTransition?.length - 1]
    : null;
  return (
    data.scanInfo?.status === ScanStatus.Completed &&
    latestTransition?.updatedOn &&
    !dateUtils.isOlderThan({
      amount: inboxHelpers.OUTDATE_MAIL_SCAN_IN_MONTHS,
      unit: 'months',
      dateStr: latestTransition?.updatedOn,
    })
  );
};

function MailDetails({ data, variant = 'full' }: MailDetailsProps) {
  const { isAccountUnderPaymentHold } = useAuth();
  const { isDownMobileLandscape } = useResponsiveModes();
  const pdfDialog = useDialog<string>({ open: false, value: '' });
  const pdfQuery = useGetScanPdfFileByIdQuery(
    { id: pdfDialog.value },
    { skip: !pdfDialog.value, refetchOnMountOrArgChange: true },
  );
  const { handleDownloadClick, isLoading: isDownloading } = useScanDownload({ id: data.scanInfo?.uuid });

  const measurement = cellHelpers.formatMeasurement(data.measurement);
  const weight = cellHelpers.formatWeight(data.weight);
  const arrivalDate = cellHelpers.formatDateString(data.arrivalDate);
  const itemType = data.mailTypeLabel;
  const hasStorageChargesLabel = data.hasStorageCharges ? 'Yes' : 'No';
  const list: DetailsTableProps['items'] = [];

  if (data.sender?.name || data.sender?.description) {
    list.push({
      title: 'Sender',
      value: inboxHelpers.getSenderNameAndDescription(data.sender),
    });
  }

  if (data.recipientName) {
    list.push({
      title: 'Recipient',
      value: data.recipientName,
    });
  }

  list.push(
    ...[
      {
        title: 'Measurements',
        value: measurement,
      },
      {
        title: 'Weight',
        value: weight,
      },
      {
        title: 'Arrived',
        value: arrivalDate,
      },
      {
        title: 'Type',
        value: itemType,
      },
      {
        title: 'Accumulating storage charges',
        value: hasStorageChargesLabel,
      },
    ],
  );

  if (data.trackingNumber) {
    list.push({
      title: 'Tracking #',
      value: data.trackingNumber,
    });
  }

  const scanDescriptions = inboxHelpers.generateMailScanStatusDescriptions(data);
  const scanActionsAvailable = checkIfScanActionsAvailable(data);
  const buttonSize = isDownMobileLandscape ? 'small' : 'medium';

  if (scanDescriptions?.length) {
    list.push({
      title: 'Description',
      value: (
        <>
          {scanDescriptions?.map((description, index) => (
            <Typography key={index} variant="body2">
              {description}
            </Typography>
          ))}
          {scanActionsAvailable && (
            <FlexBox gap={1} mt={1} justifyContent="center">
              <Button
                disabled={isAccountUnderPaymentHold || isDownloading}
                onClick={handleDownloadClick}
                fullWidth
                variant="contained"
                color="primary"
                size={buttonSize}
              >
                {isDownloading ? <CircularProgress size={16} /> : 'Download'}
              </Button>
              <Button
                disabled={isAccountUnderPaymentHold}
                onClick={() => pdfDialog.openDialog(data.scanInfo?.uuid)}
                fullWidth
                size={buttonSize}
                variant="outlined"
                color="primary"
              >
                View
              </Button>
              {pdfDialog.value ? (
                <ConfirmationDialog
                  large={true}
                  open={pdfDialog.open}
                  onClose={pdfDialog.closeDialog}
                  title={`Scan #${data.scanInfo?.uuid}`}
                  hideButtons
                  content={<PdfViewer loading={pdfQuery.isLoading} blobFileUrl={pdfQuery.data} />}
                />
              ) : null}
            </FlexBox>
          )}
        </>
      ),
    });
  }

  return 'full' === variant ? <DetailsTable items={list} /> : <ListDetails items={list} />;
}

export default MailDetails;
