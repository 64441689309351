import { Box, styled } from '@mui/material';

import { Container, FlexBox, SimpleFAQAccordion } from '@usgm/shared-ui';
import NotarizationStep from '../components/notarization/NotarizationStep';

import PageHeading from '../components/PageHeading';

import { onboardingUtils } from '@usgm/utils';

const SetupAppointmentCard = styled(FlexBox)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2.5,
  padding: theme.spacing(3, 4),
  backgroundColor: theme.palette.background.paper,
  gap: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2.5),
  },
}));

export default function GetNotarizedPage() {
  return (
    <Container>
      <Box mb={4} component="section">
        <PageHeading
          title="Notarize your form 1583"
          subtitle="Form 1583 is essential for us to legally receive your mail, as per US postal regulations."
        />
        <SetupAppointmentCard mt={3}>
          <NotarizationStep />
        </SetupAppointmentCard>
      </Box>
      <SimpleFAQAccordion items={onboardingUtils.FAQ_DATA} />
    </Container>
  );
}
