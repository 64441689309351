import { useNavigate } from 'react-router-dom';
import { inboxHelpers } from '@usgm/utils';
import { useAppDispatch } from '../../../store';
import { planPickerSlice } from '../planPickerSlice';
import { useCallback } from 'react';
import { DtoPlan } from '@usgm/inbox-api-types';
import { useAuth } from '../../auth/hooks/useAuth';

export const useSelectPlan = (redirect = true, handleSelection?: () => void) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth();

  const callbackHandler = useCallback(
    (plan: DtoPlan) => {
      if (typeof handleSelection === 'function') {
        handleSelection();
      } else {
        // Default behavior
        inboxHelpers.getStorageManager().setItem('selectedPlan', plan);
        if (redirect) {
          navigate(isAuthenticated ? inboxHelpers.MAIN_PATHS.CHECKOUT : inboxHelpers.MAIN_PATHS.CREATE_ACCOUNT);
        }
      }
    },
    [handleSelection, isAuthenticated, navigate, redirect],
  );

  const onPlanSelect = useCallback(
    (plan: DtoPlan) => () => {
      dispatch(planPickerSlice.actions.setPickedPlan(plan));
      callbackHandler(plan);
    },
    [callbackHandler, dispatch],
  );
  return { onPlanSelect };
};
