import { Link, styled, Typography } from '@mui/material';
import { FlexBox } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import { webSiteUrl } from '../../../../../../../../../helpers/urlHelper';

const Root = styled(FlexBox)(({ theme }) => ({
  gap: theme.spacing(2),
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2),
}));

function GlobalAccountNameLimitReached() {
  return (
    <Root>
      <Typography mb={3} textAlign="center" variant="h5">
        You have reached the global limit of account names
      </Typography>
      <Typography>
        Please{' '}
        <Link target="_blank" href={webSiteUrl('contact-us')}>
          contact us
        </Link>{' '}
        if you need to add more than {inboxHelpers.MAX_ACCOUNTS_LIMIT} account names
      </Typography>
    </Root>
  );
}

export default GlobalAccountNameLimitReached;
