import { EntityFilter, TMailFilters, TScanFilters } from '@usgm/inbox-api-types';
import { constructListFilters } from './constructListFilters';

export function constructGenericFilters<T extends { value: string; filterKey: string; label: string }>({
  filter,
  folderId,
  currentTab,
  tabs,
}: {
  currentTab: string;
  tabs: Array<T>;
  folderId?: string | number;
  filter?: EntityFilter<TMailFilters | TScanFilters, string[]>;
}) {
  const filtersToApply = filter ? { ...filter } : {};

  if (folderId) {
    filtersToApply['folder'] = [folderId.toString()];
  } else if (!filtersToApply['folder'] || !filtersToApply['folder'].length) {
    filtersToApply['folder'] = ['all'];
  }
  if (currentTab) {
    const filterKey = tabs.find((item) => item.value === currentTab)?.filterKey;
    filtersToApply['status'] = filtersToApply['status'] || [];
    if (filterKey) {
      filtersToApply['status'] = [...filtersToApply['status'], filterKey];
    }
  }

  const filterQueryString = constructListFilters(filtersToApply);
  return filterQueryString;
}
