import { Box, Theme, Typography, TypographyProps, styled, useMediaQuery } from '@mui/material';

import CompletedIcon from '../assets/completeDone.svg?react';
import { FlexBox } from '@usgm/shared-ui';
import { ReactNode } from 'react';

const shouldForwardProp = (prop: string) => prop !== 'completed' && prop !== 'skipped';

type StyledProps = {
  completed?: boolean;
  skipped?: boolean;
};

const StepId = styled(Box, { shouldForwardProp })<StyledProps>(({ theme, completed }) => ({
  minWidth: theme.spacing(3.75),
  minHeight: theme.spacing(3.75),
  color: completed ? theme.customColors.dark[400] : theme.palette.success.main,
  border: `2px solid ${completed ? theme.customColors.dark[400] : theme.palette.success.main}`,
  borderRadius: '50%',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(2.5),
  fontWeight: 600,
}));

const StepContent = styled(Box)(({ theme }) => ({
  maxWidth: '482px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

const ActionsContainer = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

const StepContainer = styled(Box, { shouldForwardProp })<StyledProps>(({ theme, completed }) => ({
  display: 'flex',
  paddingBottom: theme.spacing(3),
  paddingTop: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`,
  alignItems: completed ? 'center' : 'flex-start',
  '&:last-child': {
    borderBottom: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
  },
}));

export const StepText = styled(
  ({ children, ...props }: TypographyProps) => <Typography {...props}>{children}</Typography>,
  {
    shouldForwardProp,
  },
)<StyledProps>(
  ({
    theme: {
      palette: { text, mode },
      customColors,
    },
    completed,
    skipped,
  }) => ({
    textDecoration: skipped ? 'line-through' : 'none',
    color: skipped || completed ? (mode === 'dark' ? text.secondary : customColors.dark[400]) : text.primary,
  }),
);

const Title = styled(StepText, { shouldForwardProp })<StyledProps>(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  marginBottom: theme.spacing(0.5),
}));

export type VerifyAccountStepsProps = {
  items: Array<{
    completed?: boolean;
    skipped?: boolean;
    stepId: number;
    content?: ReactNode;
    title?: string | ReactNode;
    actions?: ReactNode;
  }>;
};
export function VerifyAccountSteps({ items }: VerifyAccountStepsProps) {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  return (
    <Box mt={isMobile ? 2 : 4}>
      {items.map(({ content, stepId, completed, title, actions, skipped }) => {
        return (
          <StepContainer completed={completed && !content && !actions} key={stepId}>
            <StepId completed={completed}>{stepId}</StepId>
            <FlexBox
              flex="1 1 auto"
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems={isMobile ? 'flex-start' : 'center'}
            >
              <StepContent>
                {title && (
                  <Title completed={completed} skipped={skipped}>
                    {title}
                  </Title>
                )}
                {content}
              </StepContent>
              <ActionsContainer>{actions || (completed && !isMobile && <CompletedIcon />)}</ActionsContainer>
            </FlexBox>
            {isMobile && completed && !actions && (
              <Box ml={1}>
                <CompletedIcon />
              </Box>
            )}
          </StepContainer>
        );
      })}
    </Box>
  );
}
