import { zodResolver } from '@hookform/resolvers/zod';
import WestIcon from '@mui/icons-material/West';
import { Box, CircularProgress } from '@mui/material';
import { Button, FlexBox, Inbox, TextButton, useMobileMode } from '@usgm/shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../../../store';
import { apiMessagesSlice } from '../../../../../apiMessages/apiMessagesSlice';
import { useAuth } from '../../../../../auth/hooks/useAuth';
import { StyledCardHeaderTitle } from '../../../../components/styled/StyledCardHeaderTitle';
import { useUpdateAddressMutation } from '../../../../inboxAccountsApi';
import { SETTINGS_PATHS } from '../../paths';
import { CardHeader } from '../Card';
import { SettingsFormBox } from '../SettingsFormBox';
import ShippingAddressForm, { shippingAddressFormSchema, ShippingAddressFormSchemaType } from './ShippingAddressForm';

export type EditAddressProps = {
  addressUuid: string;
  defaultValues: Partial<ShippingAddressFormSchemaType>;
};
function EditAddress({ addressUuid, defaultValues }: EditAddressProps) {
  const isMobile = useMobileMode();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const [updateAddress, { isLoading: isSubmitting }] = useUpdateAddressMutation();

  const formMethods = useForm<ShippingAddressFormSchemaType>({
    resolver: zodResolver(shippingAddressFormSchema),
    mode: 'onChange',
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = formMethods;

  const handleBackClick = () => {
    navigate(SETTINGS_PATHS.ADDRESS_BOOK);
  };

  const onSubmit = async (data: ShippingAddressFormSchemaType) => {
    const response = await updateAddress({ userUuid: user?.userUUID || '', addressUuid, addr: data });
    const hasError = 'error' in response;
    dispatch(
      apiMessagesSlice.actions.createMessage({
        severity: hasError ? 'error' : 'success',
        text: hasError
          ? 'Failed saving address. Please make sure all required fields are correctly entered and try again.'
          : 'Address saved successfully.',
      }),
    );
    if (!hasError) {
      navigate(SETTINGS_PATHS.ADDRESS_BOOK);
    }
  };

  const isFormSubmitDisabled = !isValid || !isDirty || isSubmitting;

  return (
    <Inbox.PageContainer>
      <FormProvider {...formMethods}>
        <Box height={'100%'} onSubmit={handleSubmit(onSubmit)} component="form" noValidate>
          <Inbox.Card noSpacings>
            <CardHeader
              title={
                <>
                  <TextButton onClick={handleBackClick} sx={{ minWidth: 'auto', marginRight: 2 }}>
                    <WestIcon />
                  </TextButton>
                  <StyledCardHeaderTitle variant={isMobile ? 'body1' : 'h6'}>Edit Address</StyledCardHeaderTitle>
                </>
              }
            >
              <FlexBox gap={2} justifyContent={'flex-end'}>
                {!isMobile && (
                  <Button variant="outlined" onClick={handleBackClick}>
                    Back
                  </Button>
                )}
                <Button
                  disabled={isFormSubmitDisabled}
                  type="submit"
                  variant="contained"
                  sx={{ minWidth: isMobile ? 80 : 120 }}
                >
                  {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Save'}
                </Button>
              </FlexBox>
            </CardHeader>

            <Inbox.CardContent height="100%">
              <SettingsFormBox>
                <FormProvider {...formMethods}>
                  <ShippingAddressForm defaultValues={defaultValues} />
                </FormProvider>
              </SettingsFormBox>
            </Inbox.CardContent>
          </Inbox.Card>
        </Box>
      </FormProvider>
    </Inbox.PageContainer>
  );
}

export default EditAddress;
