import { splitToWords } from './splitToWords';

const capitalize = (str: string) => {
  const parts = [str.charAt(0).toUpperCase()];
  if (str.length > 1) {
    parts.push(str.slice(1).toLocaleLowerCase());
  }
  return parts.join('');
};

export function pascalCase(str: string) {
  if (!str || str.length === 0 || typeof str !== 'string') {
    return str;
  }
  const words = splitToWords(str);
  return words.length > 0 ? words.map((word) => capitalize(word)).join(' ') : capitalize(str);
}
