import { styled } from '@mui/material';

export const HeaderStepperContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.75, 6),
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& .MuiStepper-horizontal': {
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2.75, 2.5),
  },
}));
