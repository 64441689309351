import { useEffect, useState } from 'react';

import { ReportAnIssueParams } from '@usgm/inbox-api-types';
import { ConfirmationDialog, ConfirmationDialogProps, TextInput, Textarea } from '@usgm/shared-ui';

import { useReportAnIssueMutation } from '../../../api/mailsApi';
import { useAppDispatch } from '../../../store';
import { apiMessagesSlice } from '../../apiMessages/apiMessagesSlice';

export type ReportAnIssueDialogProps = Omit<ConfirmationDialogProps, 'content'> & Omit<ReportAnIssueParams, 'comment'>;
function ReportAnIssueDialog({
  requestID,
  requestedOn,
  mailItemId,
  requestUUID,
  reportAnIssueType,
  onClose,
  title = 'Report An Issue',
  confirmText = 'Report',
  ...dialogProps
}: ReportAnIssueDialogProps) {
  const dispatch = useAppDispatch();
  const [reportAnIssue, { data, error, reset }] = useReportAnIssueMutation();

  const [comment, setComment] = useState('');
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };

  useEffect(() => {
    const apiMessage = data?.message || error?.message;
    if (apiMessage) {
      dispatch(apiMessagesSlice.actions.createMessage({ text: apiMessage, severity: error ? 'error' : 'success' }));
    }
    return () => {
      reset();
    };
  }, [data, dispatch, error, reset]);

  const handleConfirm = () => {
    reportAnIssue({ comment, requestID, requestedOn, requestUUID, mailItemId, reportAnIssueType });
    reset();
  };

  return (
    <ConfirmationDialog
      {...dialogProps}
      open={dialogProps.open}
      title={title}
      onConfirm={handleConfirm}
      onClose={onClose}
      confirmText={confirmText}
      disabled={!comment}
      content={
        <>
          <TextInput
            InputLabelProps={{ shrink: true }}
            label="Item ID"
            fullWidth
            disabled
            value={`#${mailItemId || requestID}`}
          />
          <Textarea
            value={comment}
            onChange={handleChange}
            autoFocus
            resize="none"
            fullWidth
            placeholder="Please describe the issue you are experiencing"
          />
        </>
      }
    />
  );
}

export default ReportAnIssueDialog;
