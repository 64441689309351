export enum ShipmentErrorCode {
  AddressError = 'AddressError',
  DimensionsError = 'DimensionsError',
  RatesError = 'RatesError',
}

const MESSAGES: Record<ShipmentErrorCode, string> = {
  [ShipmentErrorCode.AddressError]: 'Unable to load addresses',
  [ShipmentErrorCode.DimensionsError]: 'Unable to calculate dimensions',
  [ShipmentErrorCode.RatesError]: 'Unable to load rates',
};

export class ShipmentError extends Error {
  public readonly code: ShipmentErrorCode;
  constructor({ message, code }: { message?: string; code: ShipmentErrorCode }) {
    super(message || MESSAGES[code]);
    this.name = this.constructor.name;
    this.code = code;
  }
}
