import { inboxHelpers } from '@usgm/utils';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ENVIRONMENT } from '../env';

const BETA_TO_STABLE_ROUTE_MAP: { betaPath: string; stablePath: string }[] = [
  {
    betaPath: `${inboxHelpers.MAIN_PATHS.APP}/${inboxHelpers.APP_PATHS.VERIFY_ACCOUNT}`,
    stablePath: '/verify-account',
  },
  { betaPath: inboxHelpers.MAIN_PATHS.INBOX, stablePath: '/inbox' },
  { betaPath: `${inboxHelpers.MAIN_PATHS.APP}/${inboxHelpers.APP_PATHS.SCANS}`, stablePath: '/scans' },
  { betaPath: `${inboxHelpers.MAIN_PATHS.APP}/${inboxHelpers.APP_PATHS.SHIPMENTS}`, stablePath: '/shipments' },
  {
    betaPath: `${inboxHelpers.MAIN_PATHS.APP}/${inboxHelpers.APP_PATHS.BILLING_HISTORY}`,
    stablePath: '/billing-history',
  },
  { betaPath: `${inboxHelpers.MAIN_PATHS.APP}/${inboxHelpers.APP_PATHS.DISCARDS}`, stablePath: '/discards' },
  { betaPath: `${inboxHelpers.MAIN_PATHS.APP}/${inboxHelpers.APP_PATHS.SETTINGS}`, stablePath: '/settings' },
  { betaPath: inboxHelpers.MAIN_PATHS.SELECT_VIRTUAL_ADDRESS, stablePath: '/select-virtual-address' },
  // SELECT_PLAN mapped to /select-virtual-address intentionally, as in the stable app it is not possible to directly go to select plan page
  { betaPath: inboxHelpers.MAIN_PATHS.SELECT_PLAN, stablePath: '/select-virtual-address' },
];

export const usePreferredInboxAppVersion = () => {
  const { pathname } = useLocation();

  const redirectTo = useMemo(
    () => BETA_TO_STABLE_ROUTE_MAP.find((it) => pathname.startsWith(it.betaPath))?.stablePath || '/',
    [pathname],
  );

  const navigateToLegacy = useCallback(() => {
    window.location.href = `${ENVIRONMENT.STABLE_INBOX_URL}${redirectTo}`;
  }, [redirectTo]);

  return { navigateToLegacy };
};
