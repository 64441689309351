import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Menu, MenuItem, Typography, styled, useTheme } from '@mui/material';
import { useAnchorEl } from '../hooks';
import FlexBox from './FlexBox';
import { UserAvatar } from './UserAvatar';

export type AccountInfoProps = {
  name?: string;
  message?: string;
  menuItems?: Array<{ label: string; onClick?: () => void }>;
  hideUserDetails?: boolean;
};

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    boxShadow: theme.shadows[1],
    width: 194,
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
  },
}));

const UserName = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.common.white : '#344054', // @TODO: understand where this color comes from
}));

const StyledUserRow = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.customColors.dark[300],
  },
}));

export function AccountInfo({ name, message, menuItems, hideUserDetails }: AccountInfoProps) {
  const theme = useTheme();
  const { anchorEl, handleClick, handleClose, open } = useAnchorEl<HTMLElement>();
  return (
    <>
      <StyledUserRow
        id="account-button"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <FlexBox>
          <UserAvatar name={name} />
          {!hideUserDetails && (name || message) && (
            <Box flexDirection="column" ml={1}>
              <Typography
                fontSize="0.875rem"
                color={theme.palette.mode === 'dark' ? theme.palette.grey[400] : theme.customColors.dark[300]}
              >
                {message}
              </Typography>
              <FlexBox>
                <UserName fontWeight="bold">{name}</UserName>
                <ExpandMoreIcon />
              </FlexBox>
            </Box>
          )}
        </FlexBox>
      </StyledUserRow>
      {menuItems && (
        <StyledMenu
          id="account-menu"
          aria-labelledby="account-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {menuItems.map(({ label, onClick }, index) => {
            return (
              <MenuItem key={index} onClick={onClick}>
                {label}
              </MenuItem>
            );
          })}
        </StyledMenu>
      )}
    </>
  );
}
