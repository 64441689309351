import { Avatar } from '@mui/material';
import { ActiveIndicatorBadge } from './ActiveIndicatorBadge';

export type UserAvatarProps = {
  name?: string;
  onClick?: () => void;
};

export function UserAvatar({ name, onClick }: UserAvatarProps) {
  return (
    <ActiveIndicatorBadge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
      sx={{ cursor: 'pointer' }}
    >
      <Avatar onClick={onClick} alt={name} />
    </ActiveIndicatorBadge>
  );
}
