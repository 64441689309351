import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import { List, ListItemIcon, ListItemText, Paper, Typography, alpha, styled, Box } from '@mui/material';
import { Announcement } from '@usgm/inbox-api-types';
import { FlexBox, TruncateText, Preloader, NoRowPlaceholder } from '@usgm/shared-ui';
import { dateUtils } from '@usgm/utils';
import { useCallback } from 'react';
import { useGetAnnouncementsQuery, useMarkAnnouncementAsReadMutation } from '../../../../../api/notificationsApi';
import { useAppDispatch } from '../../../../../store';
import { apiMessagesSlice } from '../../../../apiMessages/apiMessagesSlice';

const SinceTime = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  color: theme.customColors.dark[400],
  right: theme.spacing(2),
  bottom: theme.spacing(2),
}));

const AnnouncementText = styled(Typography)(() => ({
  whiteSpace: 'pre-line',
  overflowWrap: 'break-word',
}));

const StyledListItemIcon = styled(ListItemIcon, { shouldForwardProp: (prop) => prop !== 'isRead' })<{
  isRead: boolean;
}>(({ theme, isRead }) => ({
  width: theme.spacing(8),
  height: theme.spacing(8),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(2),
  borderRadius: '50%',
  backgroundColor: !isRead ? theme.palette.background.paper : theme.palette.action.hover,
}));

const Card = styled(Paper, { shouldForwardProp: (prop) => prop !== 'isRead' })<{ isRead: boolean }>(
  ({ theme, isRead }) => ({
    cursor: isRead ? 'default' : 'pointer',
    width: '100%',
    position: 'relative',
    padding: theme.spacing(2),
    maxWidth: '100%',
    minHeight: 160,
    backgroundColor: isRead ? theme.palette.background.paper : alpha(theme.palette.primary.main, 0.1),
  }),
);

export function AnnouncementsList() {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetAnnouncementsQuery(null);
  const [markAsRead, { error }] = useMarkAnnouncementAsReadMutation();

  const handleClick = useCallback(
    (uuid: Announcement['id'], isRead: boolean) => {
      if (isRead) {
        return;
      }
      markAsRead({ uuid });
    },
    [markAsRead],
  );

  if (error) {
    dispatch(
      apiMessagesSlice.actions.createMessage({
        severity: 'error',
        text: 'Something went wrong marking the announcement as read. Please try again later.',
      }),
    );
  }

  if (isLoading) {
    return <Preloader />;
  }
  return (
    <>
      {!data?.announcements?.length && (
        <Box mt={2}>
          <NoRowPlaceholder
            text={'No new announcements at this time'}
            icon={<CampaignOutlinedIcon color="primary" />}
            justifyContent="flex-start"
            minHeight="0"
          />
        </Box>
      )}
      <List gap={2} flexDirection="column" component={FlexBox}>
        {data?.announcements?.map(({ body, endDate, id, isRead, subject, startDate }) => (
          <Card isRead={isRead} key={id} onClick={() => handleClick(id, isRead)}>
            <FlexBox alignItems="flex-start">
              <StyledListItemIcon isRead={isRead}>
                <CampaignOutlinedIcon fontSize="large" />
              </StyledListItemIcon>
              <ListItemText
                primary={subject}
                secondary={
                  <TruncateText controlled lines={3}>
                    <AnnouncementText overflow="break-word">{body}</AnnouncementText>
                  </TruncateText>
                }
              />

              <SinceTime>{dateUtils.formatRelativeTime(startDate)}</SinceTime>
            </FlexBox>
          </Card>
        ))}
      </List>
    </>
  );
}
