import { UserType } from '@usgm/inbox-api-types';
import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { DocumentsSchemaType } from './schema';
import { Grid, useMediaQuery } from '@mui/material';
import { TextInput } from '@usgm/shared-ui';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { inboxHelpers } from '@usgm/utils';

export type DocumentFieldsProps = {
  userType: UserType;
  onPrimaryNameChange?: (value: string) => void;
};

const getIcon = (hasError: boolean) =>
  hasError ? <ErrorOutlineOutlinedIcon color="error" /> : <CheckIcon color="success" />;

export function DocumentFields({ userType, onPrimaryNameChange }: DocumentFieldsProps) {
  const isSmallScreen = useMediaQuery(inboxHelpers.DOWN_MOBILE_LANDSCAPE);
  const {
    register,
    formState: { errors },
  } = useFormContext<DocumentsSchemaType>();

  return userType === UserType.Primary ? (
    <Grid mt={isSmallScreen ? 1 : 3} spacing={isSmallScreen ? 1 : 3} container>
      <Grid item xs={12} sm={6}>
        <TextInput
          required
          fullWidth
          {...register('primaryDocumentFields.name')}
          label="Full Name"
          InputProps={{
            endAdornment: getIcon(!!errors.primaryDocumentFields?.name),
            onChange: (event: ChangeEvent<HTMLInputElement>) => onPrimaryNameChange?.(event?.target?.value),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          required
          fullWidth
          {...register('primaryDocumentFields.id_number')}
          label="ID Number"
          InputProps={{ endAdornment: getIcon(!!errors.primaryDocumentFields?.id_number) }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          required
          fullWidth
          {...register('primaryDocumentFields.issuing_entity')}
          label="Issuing Entity"
          InputProps={{
            endAdornment: getIcon(!!errors.primaryDocumentFields?.issuing_entity),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          required
          fullWidth
          {...register('primaryDocumentFields.expiration_date')}
          label="Expiration Date"
          InputProps={{
            endAdornment: getIcon(!!errors.primaryDocumentFields?.expiration_date),
          }}
        />
      </Grid>
    </Grid>
  ) : (
    <Grid mt={3} spacing={3} container>
      <Grid item xs={12} sm={6}>
        <TextInput
          required
          fullWidth
          {...register('secondaryDocumentFields.full_name')}
          label="Full Name"
          InputProps={{
            endAdornment: getIcon(!!errors.secondaryDocumentFields?.full_name),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          required
          fullWidth
          {...register('secondaryDocumentFields.street_address')}
          label="Address Line"
          InputProps={{
            endAdornment: getIcon(!!errors.secondaryDocumentFields?.street_address),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          required
          fullWidth
          {...register('secondaryDocumentFields.country')}
          label="Country"
          InputProps={{
            endAdornment: getIcon(!!errors.secondaryDocumentFields?.country),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          required
          fullWidth
          {...register('secondaryDocumentFields.state')}
          label="State"
          InputProps={{
            endAdornment: getIcon(!!errors.secondaryDocumentFields?.state),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          required
          fullWidth
          {...register('secondaryDocumentFields.city')}
          label="City"
          InputProps={{
            endAdornment: getIcon(!!errors.secondaryDocumentFields?.city),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          required
          fullWidth
          {...register('secondaryDocumentFields.postal_code')}
          label="Zip"
          InputProps={{
            endAdornment: getIcon(!!errors.secondaryDocumentFields?.postal_code),
          }}
        />
      </Grid>
    </Grid>
  );
}
