import { Card, styled } from '@mui/material';
import { inboxHelpers } from '@usgm/utils';

export const StyledOptionCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 4 * theme.shape.borderRadius,
  background: theme.palette.background.card,
  boxShadow: theme.shadows[3],
  padding: theme.spacing(1.5, 4, 4, 4),
  margin: theme.spacing(2),
  maxWidth: theme.spacing(38),
  '&:hover': {
    background: theme.palette.background.cardHover,
  },
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    maxWidth: '100%',
    width: '100%',
    margin: theme.spacing(2, 0),
  },
}));
