import { RouterTabsProps } from '@usgm/shared-ui';

import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import DynamicSidebar from '../components/DynamicSidebar';
import { TabsLayout } from '../components/layout/TabsLayout';

import ShipmentInfo from '../features/shipments/components/ShipmentInfo';
import { SHIPMENT_TABS, ShipmentPathParams, createShipmentsPath } from '../features/shipments/paths';
import { useNavigatePreservingParams } from '../hooks/useNavigatePreservingParams';
import { useGetShipmentByIdQuery } from '../features/shipments/api';
import { SHIPMENT_TYPES_OPTIONS } from '../features/shipments/components/ShipmentsCategoryFilter';
import ShipmentDrawer from '../features/mails/components/mailsActionRequests/shipment/ShipmentDrawer';

export default function ShipmentsPage() {
  const navigate = useNavigatePreservingParams();
  const { folderId, itemId, tab } = useParams<ShipmentPathParams>();
  const { data: shipmentData } = useGetShipmentByIdQuery({ id: itemId || '' }, { skip: !itemId });

  const handleClose = useCallback(() => {
    navigate(createShipmentsPath({ tab: tab || 'completed' }));
  }, [navigate, tab]);
  const tabs: RouterTabsProps['tabs'] = useMemo(() => {
    return SHIPMENT_TABS.map(({ label, value, supportsTeamMembers, supportsRegularTeamMember }) => {
      return {
        path: createShipmentsPath({ tab: value, folderId, itemId }),
        label: label,
        supportsTeamMembers,
        supportsRegularTeamMember,
      };
    });
  }, [folderId, itemId]);

  const sidebarTitle = shipmentData ? `${SHIPMENT_TYPES_OPTIONS.get(shipmentData.categoryType) || ''} #${itemId}` : '';

  return (
    <>
      <TabsLayout tabs={tabs} />
      <DynamicSidebar disableSpacings open={!!itemId} handleClose={handleClose} title={sidebarTitle}>
        {itemId && <ShipmentInfo handleClose={handleClose} id={itemId} />}
      </DynamicSidebar>
      <ShipmentDrawer handleClose={handleClose} />
    </>
  );
}
