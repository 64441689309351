import { isUnboxingMailType } from '@usgm/inbox-api-types';
import { useMemo } from 'react';
import { useAppSelector } from '../../../../../../../../store';
import { DEFAULT_DECLARATION } from '../../../MailInfo/ShippingDeclarationsManager';
import { mailsToShipSelectors } from '../mailsShipmentSlice';
import { TMailsDeclarationSchemaType } from '../steps/DeclarationsStep';

export function useShippingMailsDeclarations() {
  const mails = useAppSelector(mailsToShipSelectors.selectAll);

  const mailDeclarations: TMailsDeclarationSchemaType = useMemo(
    () => ({
      mails: mails
        .filter((mail) => isUnboxingMailType(mail.mailType))
        .map((mail) => ({
          mailId: mail.id,
          imageUrl: mail.imageUrl,
          declarations: mail.shippingDeclarations || [DEFAULT_DECLARATION],
        })),
    }),
    [mails],
  );
  return mailDeclarations;
}
