import {
  Box,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu as MuiMenu,
  styled,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { IconMenuItemProps, MenuItemData, NestedMenuItem } from 'mui-nested-menu';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { FlexBox, useAnchorEl } from '@usgm/shared-ui';
import { Link, useMatch } from 'react-router-dom';
import TPublicNavItemProps from '../../../types/TPublicNavItemProps';
import { ReactNode } from 'react';

const resetBg = { backgroundColor: 'transparent' };

const createActiveMenuItemAnchorStyles = (theme: Theme) => ({
  color: theme.palette.primary.main,
  position: 'relative',
  '& .MuiListItemText-root': {
    position: 'relative',
  },
  '& .MuiListItemText-root::after': {
    content: '""',
    position: 'absolute',
    display: 'block',
    height: 2,
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    bottom: '-6px',
  },
});

const Menu = styled(MuiMenu)(({ theme }) => ({
  '.MuiPaper-root': {
    borderRadius: theme.shape.borderRadius * 3,
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    borderColor: theme.palette.divider,
  },
  '.MuiList-root': {
    display: 'flex',
    gap: theme.spacing(1.5),
    padding: theme.spacing(4),
  },

  '.MuiMenuItem-root': {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.5),
  },
  '.MuiMenuItem-root:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? '#5f6164' : '#D7E6FE',
  },
})) as typeof MuiMenu;
const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.customColors.dark[400],
  fontSize: 14,
  fontWeight: theme.typography.fontWeightBold,
  whiteSpace: 'pre-wrap',
})) as typeof Typography;

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.title,
  padding: '0',
  whiteSpace: 'nowrap',
  '&.Mui-selected': {
    ...resetBg,
    ...createActiveMenuItemAnchorStyles(theme),
    '&:hover': resetBg,
  },
  '&:hover': resetBg,

  '& .MuiSvgIcon-root': {
    marginLeft: theme.spacing(1),
  },
})) as typeof ListItemButton;

const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'expandOnHover' && prop !== 'fullWidthSubMenu' && prop !== 'subMenuWidth',
})<{
  subMenuWidth?: number;
  expandOnHover?: boolean;
  fullWidthSubMenu?: boolean;
}>(({ theme, expandOnHover, fullWidthSubMenu, subMenuWidth }) => ({
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 1.5),
  },
  ...(expandOnHover && {
    position: fullWidthSubMenu ? 'initial' : 'relative',
    '& > .sub-menu-container': {
      borderRadius: theme.shape.borderRadius * 3,
      padding: theme.spacing(2.5),
      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
      display: 'none',
      position: 'absolute',
      top: '100%',
      left: '50%',
      transform: 'translateX(-50%)',
      gap: theme.spacing(2.5),
      backgroundColor: theme.palette.background.paper,
      width: fullWidthSubMenu ? subMenuWidth : 'max-content',
      maxWidth: `calc(100vw - ${theme.spacing(10)})`,
    },
    '&:hover, &:active': {
      '& > a': {
        ...createActiveMenuItemAnchorStyles(theme),
        '& > .MuiSvgIcon-root': {
          transform: 'rotate(180deg)',
        },
      },
      '& > .sub-menu-container': {
        display: 'flex',
      },
    },
  }),
}));

const StyledListItemText = styled(ListItemText, { shouldForwardProp: (prop) => prop !== 'selected' })<{
  selected: boolean;
}>(({ theme, selected }) => ({
  textAlign: 'center',
  '& > span': {
    display: 'inline-flex',
    color: selected ? theme.palette.primary.main : 'inherit',
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const StyledSubMenuLink = styled(FlexBox)(({ theme }) => ({
  textDecoration: 'none',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: theme.spacing(2),
  color: theme.palette.text.primary,
  padding: theme.spacing(1.5),
  borderRadius: 2 * theme.shape.borderRadius,
  marginBottom: theme.spacing(0.5),
  maxWidth: 'fit-content',
  '&:hover, &:active': {
    backgroundColor: theme.palette.mode === 'dark' ? '#5f6164' : '#D7E6FE',
  },
  '&:last-of-type': {
    marginBottom: 0,
  },
})) as typeof Box & { hasIcon?: boolean };

const StyledDescription = styled(Box)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#475467' : theme.palette.text.secondary,
  fontSize: 14,
  lineHeight: 1.4,
  '& p': {
    color: theme.palette.text.secondary,
    fontSize: 14,
    whiteSpace: 'nowrap',
  },
}));

function NestedDropdown({
  parentMenuOpen = false,
  items,
  ...itemProps
}: IconMenuItemProps & {
  items?: MenuItemData[];
  parentMenuOpen?: boolean;
  href?: string;
  description?: ReactNode;
  additionalStyles?: (theme: Theme) => SxProps;
}) {
  const theme = useTheme();

  if (!items) {
    return (
      <StyledSubMenuLink
        sx={!itemProps.additionalStyles ? { minWidth: '250px' } : itemProps.additionalStyles(theme)}
        href={itemProps.href}
        component="a"
      >
        {!!itemProps.leftIcon && <Box display="inline-flex">{itemProps.leftIcon}</Box>}
        <FlexBox flexDirection="column" gap={0.5} alignItems="flex-start" alignSelf="center">
          <Typography fontWeight={600}>{itemProps.label}</Typography>
          <StyledDescription>{itemProps.description}</StyledDescription>
        </FlexBox>
      </StyledSubMenuLink>
    );
  }

  return (
    <NestedMenuItem {...itemProps} parentMenuOpen={parentMenuOpen}>
      {items?.map((item, index) => (
        <NestedDropdown {...item} key={index} parentMenuOpen={parentMenuOpen} />
      ))}
    </NestedMenuItem>
  );
}

export default function NavMenuItem({
  internal,
  expandOnHover = false,
  label,
  subMenu,
  fullWidthSubMenu,
  subMenuWidth,
  href,
  ...rest
}: TPublicNavItemProps) {
  const matchesPath = !!useMatch(href || '');

  const { anchorEl, handleClick, handleClose, open } = useAnchorEl<HTMLElement>();
  const onClick = subMenu ? handleClick : undefined;

  const subMenuList = subMenu?.map(({ title, subMenuSection }, sectionIndex) => (
    <Box flex="1 1 auto" key={sectionIndex}>
      <SectionTitle>{title || ' '}</SectionTitle>
      <Divider sx={{ color: (theme) => theme.palette.divider, margin: (theme) => theme.spacing(1.5, 0) }} />
      {subMenuSection?.map((item, index) => (
        <NestedDropdown {...item} key={index} parentMenuOpen={open} />
      ))}
    </Box>
  ));

  return (
    <StyledListItem
      subMenuWidth={subMenuWidth}
      fullWidthSubMenu={fullWidthSubMenu}
      expandOnHover={expandOnHover}
      {...rest}
    >
      <StyledListItemButton
        aria-label={label}
        selected={matchesPath && !!href}
        onClick={onClick}
        href={internal ? undefined : href}
        to={internal ? href : undefined}
        component={internal ? Link : 'a'}
        sx={open ? {} : null}
      >
        <StyledListItemText primary={label} selected={(matchesPath && !!href) || open} />
        {subMenu ? (
          open ? (
            <ExpandLess color={'primary'} />
          ) : (
            <ExpandMore color={matchesPath && !!href ? 'primary' : 'inherit'} />
          )
        ) : null}
      </StyledListItemButton>
      {subMenu &&
        (expandOnHover ? (
          <Box className="sub-menu-container">{subMenuList}</Box>
        ) : (
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{ marginTop: 5.3 }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            subMenuList
          </Menu>
        ))}
    </StyledListItem>
  );
}
