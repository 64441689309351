import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useMailRequestActionParam() {
  const [searchParams, setSearchParams] = useSearchParams();
  const request = searchParams.get('request');

  const cancelRequest = useCallback(() => {
    searchParams.delete('request');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const setRequest = useCallback(
    (actionRequest: string) => {
      searchParams.set('request', actionRequest);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return { request, cancelRequest, setRequest };
}
