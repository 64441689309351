import React, { useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { DtoScanBundleOption } from '@usgm/inbox-api-types';
import { inboxHelpers } from '@usgm/utils';
import { Badge, cellHelpers, ConfirmationDialog, ConfirmPurchaseDialog, useDialog } from '@usgm/shared-ui';
import { StyledCardButton } from './styled/StyledCardButton';
import { StyledDescription } from './styled/StyledDescription';
import { StyledHeader } from './styled/StyledHeader';
import { StyledOptionCard } from './styled/StyledOptionCard';
import { StyledPrice } from './styled/StyledPrice';
import { StyledUsageBox } from './styled/StyledUsageBox';

export type ScanBundleOptionCardProps = {
  scanBundleOption: DtoScanBundleOption;
  onPurchase?: () => void;
  onCancel?: () => void;
  onUpgradePlan?: () => void;
  cardState: {
    disabled: boolean;
    isLoading: boolean;
    isActiveBundle: boolean;
    isActiveSubscription: boolean;
    isScanBundleSupported: boolean;
    expiresAt?: string;
    pagesUsed?: number;
  };
};

function ScanBundleOptionCard({
  scanBundleOption,
  onPurchase,
  onCancel,
  onUpgradePlan,
  cardState,
}: ScanBundleOptionCardProps) {
  const [isHovered, setIsHovered] = useState(false);
  const cancelDialog = useDialog();
  const chooseDialog = useDialog();

  const isCurrent = cardState.isActiveBundle || cardState.isActiveSubscription;

  const getChooseButtonLabel = () => {
    if (!cardState.isScanBundleSupported) {
      return 'Upgrade to Business';
    }
    if (cardState.isActiveBundle) {
      return `Expires on ${cellHelpers.formatDateString(cardState.expiresAt)}`;
    }
    return `Choose ${inboxHelpers.formatNumber(scanBundleOption?.numPages)} pages`;
  };

  const getSubscriptionDetails = () => {
    if (cardState.isActiveSubscription) {
      return `Active, Renews on ${cellHelpers.formatDateString(cardState.expiresAt)}`;
    }
    if (cardState.isActiveBundle) {
      return 'Active, Non Renewing';
    }
    return '';
  };

  const getConfirmPurchaseDescription = () => {
    return `By clicking the Confirm button below, you're subscribing to a scan bundle for ${inboxHelpers.formatNumber(
      scanBundleOption?.numPages,
    )} pages / ${inboxHelpers.convertDaysToPeriod(scanBundleOption?.lifetimeDays)}.`;
  };

  const onActionButtonClick = () => {
    if (!cardState.isScanBundleSupported) {
      return onUpgradePlan?.();
    }
    chooseDialog.openDialog();
  };

  const handleCancel = () => {
    onCancel?.();
  };

  const handleChoose = () => {
    onPurchase?.();
  };

  return (
    <StyledOptionCard onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <Box height={20} mb={2} textAlign={'center'}>
        {isCurrent && <Badge badgeContent={getSubscriptionDetails()} color={'success'} />}
      </Box>
      <StyledHeader variant={'h4'}>{inboxHelpers.formatNumber(scanBundleOption?.numPages)} pages</StyledHeader>
      <Box>
        <StyledDescription>{scanBundleOption?.description}</StyledDescription>
        <StyledPrice>
          <span className="price">{inboxHelpers.formatPrice(scanBundleOption?.price)}</span>
          <span className="period">/{inboxHelpers.convertDaysToPeriod(scanBundleOption?.lifetimeDays)}</span>
        </StyledPrice>
      </Box>
      <Box height={44}>
        {isCurrent && (
          <StyledUsageBox mt={2}>
            <Typography>
              {inboxHelpers.getScanBundleUsage(cardState.pagesUsed || 0, scanBundleOption.numPages)}
            </Typography>
          </StyledUsageBox>
        )}
      </Box>
      {!cardState.isActiveSubscription && (
        <StyledCardButton
          onClick={onActionButtonClick}
          variant={isHovered && !cardState.disabled ? 'contained' : 'outlined'}
          size="medium"
          disabled={cardState.disabled || cardState.isLoading}
        >
          {cardState.isLoading ? <CircularProgress size={24} /> : getChooseButtonLabel()}
        </StyledCardButton>
      )}
      {cardState.isActiveSubscription && (
        <StyledCardButton
          onClick={cancelDialog.openDialog}
          variant={'outlined'}
          size="medium"
          disabled={cardState.isLoading}
        >
          {cardState.isLoading ? <CircularProgress size={24} /> : 'Cancel'}
        </StyledCardButton>
      )}

      <ConfirmationDialog
        onClose={cancelDialog.closeDialog}
        onConfirm={handleCancel}
        confirmText="Yes, Cancel!"
        cancelText="No"
        open={cancelDialog.open}
        content={
          <>
            <Typography textAlign="center" variant="h6">
              Are you sure you want to cancel your scan bundle subscription? You can still use your bundle until{' '}
              {cellHelpers.formatDateString(cardState.expiresAt)}. After that, it won't renew.
            </Typography>
            <Typography mt={6} textAlign={'center'}>
              Please note, you can't subscribe to a new bundle until this one expires on{' '}
              {cellHelpers.formatDateString(cardState.expiresAt)}.
            </Typography>
          </>
        }
      />

      <ConfirmPurchaseDialog
        open={chooseDialog.open}
        onClose={chooseDialog.closeDialog}
        onConfirm={handleChoose}
        subtotal={scanBundleOption?.price}
        total={scanBundleOption?.price}
        description={getConfirmPurchaseDescription()}
      />
    </StyledOptionCard>
  );
}

export default ScanBundleOptionCard;
