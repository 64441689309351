export async function commonErrorWrapper<T>(fn: () => Promise<T>): Promise<{ data: T | null; error: unknown }> {
  try {
    const data = await fn();
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
}
