import { DtoReferredUsersList, DtoUserReferralInfo, UserUuidParam } from '@usgm/inbox-api-types';
import { accountApi } from '../../../../api/accountApi';

export const referralsApi = accountApi.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    getReferralInfoByUserId: builder.query<DtoUserReferralInfo, UserUuidParam>({
      query: ({ userId }) => ({
        url: `/user/referrer/link/details`,
        method: 'get',
        params: { userId },
      }),
    }),
    getReferralsListByUserId: builder.query<DtoReferredUsersList, UserUuidParam>({
      query: ({ userId }) => ({
        url: '/user/referred/list',
        method: 'get',
        params: { userId },
      }),
    }),
  }),
});

export const { useGetReferralInfoByUserIdQuery, useGetReferralsListByUserIdQuery } = referralsApi;
