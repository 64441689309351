import { Box, List, styled } from '@mui/material';
import React from 'react';
import { useAuth } from '../../../auth/hooks/useAuth';

import AnnouncementAction from '../../features/announcements/components/AnnouncementAction';
import ReferFriendAction from '../../features/referrals/components/ReferFriendAction';
import HelpAction from '../../features/help/components/HelpAction';

import { THeaderActionButton } from './HeaderActionButton';

const ActionsContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'asNavigationItem' })<THeaderActionButton>(
  ({ theme, asNavigationItem }) => ({
    display: 'flex',
    padding: asNavigationItem ? theme.spacing(0) : theme.spacing(0, 3),
    flexDirection: asNavigationItem ? 'column' : 'row',
    alignItems: 'center',
    marginRight: asNavigationItem ? 0 : theme.spacing(4.5),
    alignSelf: 'stretch',
    gap: asNavigationItem ? theme.spacing(0.5) : theme.spacing(2),
    borderLeft: asNavigationItem ? 'none' : `1px solid ${theme.palette.divider}`,
    borderRight: asNavigationItem ? 'none' : `1px solid ${theme.palette.divider}`,
  }),
);

export function HeaderActions({ asNavigationItem = false }: THeaderActionButton) {
  const { isTeamMember } = useAuth();
  return (
    <ActionsContainer asNavigationItem={asNavigationItem} component={asNavigationItem ? List : 'div'}>
      <AnnouncementAction asNavigationItem={asNavigationItem} />
      {!isTeamMember && <ReferFriendAction asNavigationItem={asNavigationItem} />}
      <HelpAction asNavigationItem={asNavigationItem} />
    </ActionsContainer>
  );
}

export default React.memo(HeaderActions);
