import { useCallback, useEffect, useState } from 'react';

import { Box, FormControl, FormControlLabel, Typography, useTheme } from '@mui/material';

import { ScanCategory } from '@usgm/inbox-api-types';

import { cellHelpers, Checkbox, MessageBox, Toast } from '@usgm/shared-ui';

import { TMailActionRequestsSchemaType } from './mailActionRequestSchema';
import RequestedItems from './RequestedItem';

import { useMailsActionRequestMutation } from '../../api';
import { selectSelectedMails } from '../../mailsSlice';
import { MailActionRequest } from '../../paths';

import { LETTER_SCAN_FEE, SCAN_PAGE_FEE } from '@inbox/constants';
import { inboxHelpers } from '@usgm/utils';
import { useAppSelector } from '../../../../../../store';
import { ContentContainer } from '../../../../components/DynamicSidebar/ContentContainer';
import Preloader from '../../../../components/DynamicSidebar/Preloader';
import { useGetUserFreeScansQuery } from '../../../settings/api';
import { useUserScanBundleData } from '../../../settings/hooks/useUserScanBundleData';
import RequestSuccessMessage from './RequestSuccessMessage';

type MailsScanRequestProps = {
  allowExpedite?: boolean;
};

function MailsScanRequest({ allowExpedite }: MailsScanRequestProps) {
  const theme = useTheme();

  const selectedMails = useAppSelector(selectSelectedMails);

  const getUserFreeScansQuery = useGetUserFreeScansQuery();
  const { activeScanBundle, isLoading: isScanBundlesLoading, totalPages, availablePages } = useUserScanBundleData();

  const [isScanBundleUsageChecked, setIsScanBundleUsageChecked] = useState<boolean>(false);

  useEffect(() => {
    setIsScanBundleUsageChecked(availablePages > 0);
  }, [availablePages]);

  const isLoading = getUserFreeScansQuery.isLoading || isScanBundlesLoading;

  const [scanMailsRequestMutation, scanMailsRequestMutationQuery] = useMailsActionRequestMutation();

  const title = `${selectedMails.length} Item${selectedMails.length > 1 ? 's' : ''}`;

  const onFormSubmit = useCallback(
    (data: TMailActionRequestsSchemaType) => {
      scanMailsRequestMutation(
        data.requestedItems.map((request) => ({
          id: request.mailId,
          instruction: request.instruction,
          isExpedite: request.isExpedite,
          userBundleId: isScanBundleUsageChecked ? activeScanBundle?.id : undefined,
          categoryType: ScanCategory.ScanRequest,
        })),
      );
    },
    [activeScanBundle?.id, isScanBundleUsageChecked, scanMailsRequestMutation],
  );

  const handleToggleUseScanBundle = () => {
    setIsScanBundleUsageChecked((prev) => !prev);
  };

  if (scanMailsRequestMutationQuery.isSuccess) {
    return <RequestSuccessMessage actionRequest={MailActionRequest.Scan} />;
  }

  return (
    <Box minHeight="100%">
      {isLoading ? (
        <Preloader />
      ) : (
        <ContentContainer>
          {getUserFreeScansQuery.data?.data && getUserFreeScansQuery.data?.data.numPages > 0 && (
            <Box mb={4}>
              <MessageBox severity="success">
                Your membership plan includes free scan pages, of which you have{' '}
                {inboxHelpers.formatNumber(getUserFreeScansQuery.data?.data.numPages, false)} pages remaining to use
                till {cellHelpers.formatDateString(getUserFreeScansQuery.data?.data.validTill)}
              </MessageBox>
            </Box>
          )}
          <Typography mb={1} variant="h5">
            {title}
          </Typography>
          <Typography mb={2}>
            {selectedMails.length > 1 ? 'Each' : 'This'} item will be opened and scanned. A PDF scan will be available
            in your inbox.
          </Typography>
          {!!availablePages && (
            <FormControl>
              <FormControlLabel
                control={<Checkbox defaultChecked={availablePages > 0} onChange={handleToggleUseScanBundle} />}
                label={
                  <Typography>
                    use from scan bundle{' '}
                    <Typography
                      fontWeight={theme.typography.fontWeightBold}
                      component="span"
                      color={theme.palette.primary.main}
                    >
                      ({inboxHelpers.formatNumber(availablePages, false)} out of{' '}
                      {inboxHelpers.formatNumber(totalPages, false)} left)
                    </Typography>
                  </Typography>
                }
              />
            </FormControl>
          )}

          {!isScanBundleUsageChecked && (
            <Typography mb={3}>
              <Typography
                color={theme.customColors.dark[400]}
                fontWeight={theme.typography.fontWeightBold}
                component="span"
              >
                Standard Scan:
              </Typography>
              <Typography ml={1} fontWeight={theme.typography.fontWeightBold} component="span">
                Available in 2-4 business hrs. Fee: {inboxHelpers.formatPrice(LETTER_SCAN_FEE)}/letter +{' '}
                {inboxHelpers.formatPrice(SCAN_PAGE_FEE)}/page
              </Typography>
            </Typography>
          )}

          <RequestedItems
            activeScanBundle={activeScanBundle}
            isSubmitting={scanMailsRequestMutationQuery.isLoading}
            onFormSubmit={onFormSubmit}
            actionRequest={MailActionRequest.Scan}
            hideInstruction={isScanBundleUsageChecked}
            allowExpedite={allowExpedite}
          />
        </ContentContainer>
      )}

      {scanMailsRequestMutationQuery.error && (
        <Toast title={scanMailsRequestMutationQuery.error.message} severity="error" />
      )}
    </Box>
  );
}

export default MailsScanRequest;
