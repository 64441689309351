import { Route, Routes, useNavigate } from 'react-router-dom';
import PublicLayout from '../components/layouts/public';

import { inboxHelpers } from '@usgm/utils';

import React, { useEffect } from 'react';

import PageLoader from '../components/PageLoader';
import { useAuthRoutes } from '../features/auth/hooks/useAuthRoutes';
import { useInboxRoutes } from '../features/inbox/hooks/useInboxRoutes';
import useMetaTags from '../hooks/useMetaTags';
import { useAppSelector } from '../store';
import { selectIsUnderMaintenanceMode } from './appSlice';

const SelectPlanPage = React.lazy(() => import('../features/planPicker/pages/MainPage'));
const SelectAddressPage = React.lazy(() => import('../features/addressPicker/pages/MainPage'));
const HomePage = React.lazy(() => import('./pages/HomePage'));
const UpdateEmailsSubscription = React.lazy(() => import('./pages/UpdateEmailsSubscription'));
const Maintenance = React.lazy(() => import('./pages/Maintenance'));
const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage'));

export function App() {
  const { renderMetaTags } = useMetaTags();
  const inboxRoutes = useInboxRoutes();
  const authRoutes = useAuthRoutes();
  const navigate = useNavigate();
  const isUnderMaintenanceMode = useAppSelector(selectIsUnderMaintenanceMode);
  useEffect(() => {
    if (isUnderMaintenanceMode) {
      navigate(inboxHelpers.MAIN_PATHS.MAINTENANCE);
    }
  }, [isUnderMaintenanceMode, navigate]);
  useEffect(() => {
    window.zE?.('webWidget', 'hide');
  }, []);

  return (
    <>
      {renderMetaTags}
      <Routes>
        <Route path="/">
          {inboxRoutes}
          {authRoutes}
          <Route element={<PublicLayout disableFooter disableHeaderItems />}>
            <Route
              path={inboxHelpers.MAIN_PATHS.UNSUBSCRIBE}
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <UpdateEmailsSubscription />
                </React.Suspense>
              }
            />
            <Route
              path={inboxHelpers.MAIN_PATHS.MAINTENANCE}
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <Maintenance />
                </React.Suspense>
              }
            />
          </Route>
          <Route element={<PublicLayout />}>
            <Route
              index
              path={inboxHelpers.MAIN_PATHS.HOME}
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <HomePage />
                </React.Suspense>
              }
            />
            <Route
              path={inboxHelpers.MAIN_PATHS.SELECT_PLAN_BY_WAREHOUSE_ID()}
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <SelectPlanPage />
                </React.Suspense>
              }
            />
            <Route
              path={inboxHelpers.MAIN_PATHS.SELECT_PLAN}
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <SelectPlanPage />
                </React.Suspense>
              }
            />

            <Route
              path={inboxHelpers.MAIN_PATHS.SELECT_VIRTUAL_ADDRESS}
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <SelectAddressPage />
                </React.Suspense>
              }
            />
          </Route>
          <Route element={<PublicLayout disableFooter />}>
            <Route
              path="*"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <NotFoundPage />
                </React.Suspense>
              }
            />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
