import { Theme } from '@mui/material';

export const getCardContentStyles = (theme: Theme) => ({
  padding: theme.spacing(3, 6),

  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 2.5),
  },
});
