import { BaseMail, MailStatus } from '@usgm/inbox-api-types';
import { inboxHelpers, routeHelpers } from '@usgm/utils';

const ROOT_PATH = `${inboxHelpers.MAIN_PATHS.APP}/${inboxHelpers.APP_PATHS.INBOX}`;

export const MAIL_TABS = [
  {
    label: 'Inbox',
    value: 'mails',
    filterKey: MailStatus.Inbox,
    supportsTeamMembers: true,
    supportsRegularTeamMember: true,
  },
  {
    label: 'Quarantine',
    value: 'quarantine',
    filterKey: MailStatus.Quarantine,
    supportsTeamMembers: true,
    supportsRegularTeamMember: true,
  },
] as const;

export const DEFAULT_MAIL_TAB = MAIL_TABS[0].value;

type MailTab = (typeof MAIL_TABS)[number]['value'];

export const MAILS_ROUTES = {
  MAILS: `:tab/:itemId?`,
  FOLDER_MAILS: `folder/:folderId/:tab/:itemId?`,
  DISCARDED_MAILS: `discards/:itemId?`,
};

export type MailsPathParams = Omit<routeHelpers.TabsPathParams<MailTab>, 'params'>;

export const createMailsPath = routeHelpers.createPathGenerator<MailTab>(ROOT_PATH);

export const createDiscardPath = (id: BaseMail['id']) => {
  return `${inboxHelpers.MAIN_PATHS.APP}/${inboxHelpers.APP_PATHS.DISCARDS}/${id}`;
};

export enum MailActionRequest {
  Scan = 'scan',
  Open = 'open',
  Ship = 'ship',
}
