import { useEffect } from 'react';
import { ConfirmationDialog, TextInput, useDialog } from '@usgm/shared-ui';
import { useForm } from 'react-hook-form';

import { useEditMailFolderMutation } from '../api';
import { folderSelectors } from '../folderSlice';
import { FolderSchema } from './schema';

import { useAppSelector } from '../../../../../../store';

export function EditFolderDialog(props: ReturnType<typeof useDialog<number>>) {
  const folder = useAppSelector((state) => folderSelectors.selectById(state, props.value || -1));

  const {
    reset,
    register,
    watch,
    formState: { isValid, isDirty },
  } = useForm<FolderSchema>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (folder) {
      reset({ label: folder.label });
    }
  }, [folder, reset]);

  const label = watch('label');
  const [editFolderMutation] = useEditMailFolderMutation();

  const isDisabled = !isValid || !isDirty;

  const handleConfirm = () => {
    if (!label || !folder) return;
    editFolderMutation({ id: folder.id, label });
  };
  return props.open ? (
    <ConfirmationDialog
      open={props.open}
      confirmText="Save"
      disabled={isDisabled}
      onCancel={props.closeDialog}
      onConfirm={handleConfirm}
      onClose={props.closeDialog}
      title="Edit Folder"
      content={<TextInput {...register('label')} fullWidth />}
    />
  ) : null;
}
