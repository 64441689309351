import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  alpha,
  styled,
  useTheme,
} from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { type Except } from 'type-fest';

import { NavigationItemType, NavigationItem as TNavigationItem } from '@usgm/inbox-api-types';
import { Badge, FlexBox, Tooltip, useAnchorEl } from '@usgm/shared-ui';
import { useEffect, useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../auth/hooks/useAuth';

function SecondaryAction({ onClick }: { onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void }) {
  return (
    <IconButton onClick={onClick} edge="end" size="small" aria-label="open actions">
      <MoreIcon />
    </IconButton>
  );
}

type TMenuItem = {
  icon?: React.ReactNode;
  label?: string;
  onClick?: (hasSubItems: boolean) => void;
  href?: string;
};

type TBadgeColor = 'secondary' | 'primary' | 'default' | 'error' | 'info' | 'success' | 'warning';

export type NavigationItemProps = TMenuItem &
  Except<TNavigationItem, 'key' | 'subItems' | 'path' | 'type'> & {
    counterBadgeColor?: TBadgeColor;
    type?: NavigationItemType;
    path?: string;
    badgeColor?: TBadgeColor;
    actions?: Array<TMenuItem>;
    isSubMenuItem?: boolean;
    subItems?: Array<NavigationItemProps>;
    badgeContent?: string | number;
    rootPath?: string;
    defaultPath?: string;
    collapsed?: boolean;
    domId?: string;
  };

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'isButton',
})<{ active?: boolean; isButton?: boolean }>(({ theme, active, isButton }) => ({
  ...(isButton && {
    '& svg': {
      color: theme.palette.primary.main,
    },
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
    },
  }),
  ...(active && {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  }),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  borderRadius: 6,
}));

const StyledListItem = styled(ListItem, { shouldForwardProp: (prop) => prop !== 'isSubMenuItem' })<{
  isSubMenuItem?: boolean;
}>(({ theme, isSubMenuItem }) => ({
  ...(isSubMenuItem && {
    paddingLeft: theme.spacing(3),
  }),
  marginBottom: theme.spacing(1),
}));
const StyledListItemText = styled(Typography)(({ theme }) => ({
  flex: '1 1 auto',
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  overflowX: 'hidden',
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  marginLeft: theme.spacing(5),
  '& ul': {
    paddingTop: 0,
    paddingBottom: 0,
    '& li': {
      padding: theme.spacing(1.25),
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
  },
}));

const IconContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'collapsed' })<{ collapsed?: boolean }>(
  ({ theme, collapsed = false }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: !collapsed ? theme.spacing(2) : 0,
  }),
);

export function NavigationItem({
  actions,
  counterBadge,
  badgeColor = 'secondary',
  badgeContent,
  counterBadgeColor = 'secondary',
  path,
  subItems,
  label,
  icon,
  onClick,
  isSubMenuItem = false,
  isErrored,
  disabled,
  tooltip,
  type,
  rootPath,
  defaultPath,
  href,
  collapsed,
  domId,
}: NavigationItemProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const { isRegularTeamMember } = useAuth();
  const matchesPath = path ? pathname.startsWith(rootPath || path) : false;
  const [isSubMenuOpened, setIsSubMenuOpened] = useState(matchesPath);
  const { anchorEl, handleClick, handleClose, open } = useAnchorEl<HTMLElement>();

  useEffect(() => {
    setIsSubMenuOpened(matchesPath);
  }, [matchesPath]);

  const handleSecondaryActionClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    handleClick(e);
  };

  const secondaryAction =
    actions && actions?.length > 0 ? <SecondaryAction onClick={handleSecondaryActionClick} /> : undefined;
  const handleActionItemClick = (onClick: TMenuItem['onClick']) => (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();

    onClick?.(!!subItems?.length);
    handleClose();
  };

  const hasSubMenu = subItems && subItems.length > 0;

  const handleItemClick = () => {
    if (disabled) return;

    if (hasSubMenu) {
      setIsSubMenuOpened((prev) => !prev);
    }

    if (href) {
      window.open(href, '_blank');
    }

    if (path && !open) {
      navigate(defaultPath || path);
    }
    onClick?.(!!subItems?.length);
  };

  return (
    <>
      <Tooltip disableBottomSheet={true} title={tooltip} placement="right">
        <StyledListItem
          id={domId}
          isSubMenuItem={isSubMenuItem}
          onClick={handleItemClick}
          disablePadding
          secondaryAction={secondaryAction}
        >
          <StyledListItemButton
            isButton={type === NavigationItemType.Button}
            selected={matchesPath}
            disabled={disabled}
          >
            {icon ? (
              <IconContainer
                collapsed={collapsed}
                sx={{ color: matchesPath ? theme.palette.primary.main : theme.customColors.dark[400] }}
              >
                {icon}
              </IconContainer>
            ) : null}
            {!collapsed && (
              <StyledListItemText sx={{ color: matchesPath ? theme.palette.primary.main : theme.palette.text.primary }}>
                {label}
                {badgeContent ? <StyledBadge badgeContent={badgeContent} color={badgeColor} /> : null}
                {counterBadge && !isRegularTeamMember ? (
                  <StyledBadge badgeContent={counterBadge} color={counterBadgeColor} />
                ) : null}
                {isErrored && (
                  <FlexBox ml={0.625} component="span">
                    <InfoOutlinedIcon fontSize="small" color="error" />
                  </FlexBox>
                )}
              </StyledListItemText>
            )}

            {hasSubMenu && !collapsed ? (
              isSubMenuOpened ? (
                <ExpandLess color={matchesPath ? 'primary' : 'inherit'} />
              ) : (
                <ExpandMore color={matchesPath ? 'primary' : 'inherit'} />
              )
            ) : null}
          </StyledListItemButton>
          {actions && actions?.length > 0 && (
            <StyledMenu
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              sx={{ marginLeft: 5 }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {actions.map(({ label, icon, onClick }, index) => (
                <MenuItem sx={{ minWidth: 194 }} key={index} onClick={handleActionItemClick(onClick)}>
                  {icon ? <IconContainer>{icon}</IconContainer> : null}
                  <ListItemText>{label}</ListItemText>
                </MenuItem>
              ))}
            </StyledMenu>
          )}
        </StyledListItem>
      </Tooltip>
      {hasSubMenu && !collapsed && (
        <Collapse in={isSubMenuOpened} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subItems.map((item, index) => (
              <NavigationItem
                isSubMenuItem={true}
                {...item}
                key={item.resourceId || index}
                onClick={() =>
                  item.onClick && item.type === NavigationItemType.Button
                    ? item.onClick?.(!!item?.subItems?.length)
                    : onClick?.(!!item?.subItems?.length)
                }
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

export default NavigationItem;
