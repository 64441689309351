import React from 'react';
import { Box, Typography, BoxProps, styled } from '@mui/material';
import { Button } from './buttons';
import FlexBox from './FlexBox';

const StyledHostContainer = styled(Box)(() => ({
  position: 'relative',
  maxHeight: '100%',
  overflow: 'hidden',
}));

const StyledLockContainer = styled(FlexBox)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledLockBox = styled(FlexBox)(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.spacing(2.5),
  backgroundColor: theme.palette.background.cardHover,
  padding: theme.spacing(2.5),
  borderRadius: 5 * theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}));

export type LockOverlayProps = BoxProps & {
  isLocked: boolean;
  onActionClick: () => void;
  title?: string;
  description?: string;
  actionLabel?: string;
};

export function LockOverlay({ children, isLocked, onActionClick, title, description, actionLabel }: LockOverlayProps) {
  return (
    <StyledHostContainer>
      <Box
        sx={{
          filter: isLocked ? 'blur(4px)' : 'none',
          pointerEvents: isLocked ? 'none' : 'auto',
          height: '100%',
        }}
      >
        {children}
      </Box>
      {isLocked && (
        <StyledLockContainer>
          <StyledLockBox>
            <Typography variant="h6">{title}</Typography>
            <Typography>{description}</Typography>
            <Button variant={'contained'} onClick={onActionClick}>
              {actionLabel}
            </Button>
          </StyledLockBox>
        </StyledLockContainer>
      )}
    </StyledHostContainer>
  );
}
