import { DtoMailV2 } from '@usgm/inbox-api-types';
import { inboxHelpers } from '@usgm/utils';
import DetailsTable from '../../../../components/DetailsTable';

export type DiscardDetailsProps = {
  data: DtoMailV2;
};
function DiscardDetails({ data }: DiscardDetailsProps) {
  const list = [
    {
      title: 'Permanently deleted after',
      value: inboxHelpers.calculateDaysUntilDiscard({ statusTransition: data.statusTransition }),
    },
    {
      title: 'Type',
      value: data.mailTypeLabel,
    },
  ];
  if (data.trackingNumber) {
    list.push({
      title: 'Tracking',
      value: data.trackingNumber,
    });
  }

  return <DetailsTable items={list} />;
}

export default DiscardDetails;
