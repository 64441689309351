import { ScanCategory } from './types/ScanCategory';

export const SCANS_FILTERS = ['category', 'status', 'folder'] as const;

export type TScanFilters = (typeof SCANS_FILTERS)[number];

export const EXPORT_SCANS_TAG = 'ExportScans';

export const SCAN_CATEGORY_TYPE_LABELS = {
  [ScanCategory.ScanRequest]: 'Scan Request',
  [ScanCategory.UnboxingRequest]: 'Open Request',
};

export const SCAN_CATEGORY_NAMES = {
  [ScanCategory.ScanRequest]: 'Scan',
  [ScanCategory.UnboxingRequest]: 'Open',
};
