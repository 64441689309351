import { Typography } from '@mui/material';
import { DtoSubscriptionPlan } from '@usgm/inbox-api-types';
import { PureLink, useDialog, WarningBox } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import PlanChangeDialog from '../../settings/components/PlanChangeDialog';

export type UpgradePlanMessageProps = {
  plan?: DtoSubscriptionPlan;
  currentTotal?: number;
};
function UpgradePlanMessage({ plan, currentTotal = 0 }: UpgradePlanMessageProps) {
  const planChangeDialog = useDialog();
  const maxNames = plan?.accountNamesPlan.maxNames;

  const showWarning = maxNames && currentTotal > maxNames;
  const showUpgrade = !plan?.options?.isBusiness;

  return showWarning ? (
    <WarningBox>
      <Typography>
        {showUpgrade ? (
          <>
            <span>
              <PureLink onClick={planChangeDialog.openDialog}>Upgrade your plan</PureLink> to include more additional
              names, or{' '}
            </span>
            <PlanChangeDialog open={planChangeDialog.open} closeHandler={planChangeDialog.closeDialog} />
          </>
        ) : (
          ''
        )}
        {showUpgrade ? 'a' : 'A'}dd names on current plan for just{' '}
        <strong>{inboxHelpers.formatPrice(inboxHelpers.COST_OF_EXTRA_ACCOUNT_NAME_PER_MONTH)}/name/month.</strong>
      </Typography>
    </WarningBox>
  ) : null;
}

export default UpgradePlanMessage;
