import { UserWarehouseAddress } from '@usgm/inbox-api-types';
import { InlineButton } from '@usgm/shared-ui';
import { useNavigate } from 'react-router-dom';
import { SETTINGS_PATHS } from '../../features/settings/paths';
import { DynamicSidebarPath } from '../DynamicSidebar/useDynamicSidebar';

export function ChangeAddressButton(props: { warehouseAddress: UserWarehouseAddress; boxNumber?: number | null }) {
  const navigate = useNavigate();

  const handleChangeClick = () => {
    navigate({
      pathname: SETTINGS_PATHS.ACCOUNT_DETAILS,
      search: `activeSidebar=${DynamicSidebarPath.ChangeAddress}`,
    });
  };

  return <InlineButton onClick={handleChangeClick}>Change</InlineButton>;
}
