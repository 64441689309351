import {
  Badge,
  CircularProgress,
  IconButton,
  LinearProgress,
  styled,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  GridCallbackDetails,
  gridClasses,
  GridRowClassNameParams,
  GridRowId,
  GridRowParams,
  GridRowSelectionModel,
  GridRowSpacingParams,
  GridSlots,
  GridValidRowModel,
  useGridApiRef,
} from '@mui/x-data-grid';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import FlexBox from '../FlexBox';
import { DataGridContainer } from './styled/DataGridContainer';
import { DataGridHeaderContainer } from './styled/DataGridHeaderContainer';

import RefreshIcon from '@mui/icons-material/Refresh';

import { useMobileMode } from '../../hooks';
import { Tooltip } from '../Tooltip';
import { Checkbox } from '../form/Checkbox';
import { DataTablePagination } from './DataTablePagination';
import MobileAction from './MobileAction';
import { MobileFooter } from './MobileFooter';
import SelectionFilter, { SelectionFilterProps } from './SelectionFilter';
import { DATA_TABLE_CLASSES, DataGrid, DataGridProps, NO_ROWS_CLASS } from './styled/DataGrid';
import NoRowPlaceholder from './styled/NoRowPlaceholder';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RenderBatchActionsParams<T extends GridValidRowModel = any> = {
  ids: GridRowSelectionModel;
  rows: GridRowParams<T>[];
  clearSelection?: () => void;
  totalSelected?: boolean;
};

export type DataTableProps = Omit<DataGridProps, 'slots'> & {
  totalRows?: number;
  entityName?: string;
  placeholderIcon?: React.ReactNode;
  onRefresh?: () => void;
  isLoading?: boolean;
  paginationControl?: ReactNode;
  enableUnread?: boolean;
  headerLeft?: ReactNode;
  headerRight?: ReactNode;
  selectionFilters?: SelectionFilterProps['filters'];
  multiSelect?: boolean;
  renderBatchActions?: (data: RenderBatchActionsParams) => ReactNode;
  disableHeaderCheckbox?: boolean;
  hideColumns?: boolean;
  disableCustomMobileFooter?: boolean;
  enableTotalSelection?: boolean;
  mobileFooter?: ReactNode;
  stickyHeaderTopOffset?: number;
};

const SelectAllButton = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.primary.main,
}));

export function DataTable({
  placeholderIcon,
  entityName,
  totalRows,
  loading,
  onRefresh,
  columns,
  rows = [],
  disableColumnResize = true,
  disableColumnFilter = true,
  disableColumnMenu = true,
  hideFooter = true,
  disableCustomMobileFooter = false,
  disableColumnSorting = true,
  disableColumnSelector = false,
  rowHeight = 192,
  isLoading,
  paginationControl,
  headerLeft,
  enableUnread = false,
  checkboxSelection = true,
  multiSelect = false,
  selectionFilters = [],
  renderBatchActions,
  hideColumns,
  enableTotalSelection = false,
  disableHeaderCheckbox = false,
  mobileFooter = null,
  stickyHeaderTopOffset,
  ...dataGridProps
}: DataTableProps) {
  const isMobile = useMobileMode();
  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);
  const [totalIsSelected, setTotalIsSelected] = useState<boolean>(false);
  const [allVisibleRowsSelected, setAllVisibleRowsSelected] = useState<boolean>(false);
  const [selectionFilterIndexes, setSelectionFilterIndexes] = useState<number[]>([]);
  const apiRef = useGridApiRef();
  const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (apiRef.current === null) {
    // @ts-expect-error {} is the initial value set by useGridApiRef
    apiRef.current = {};
  }

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 16 : 8,
      bottom: params.isLastVisible ? 16 : 8,
    };
  }, []);

  const selectableRows = useMemo(() => {
    return dataGridProps.isRowSelectable
      ? rows?.filter((row) =>
          dataGridProps?.isRowSelectable
            ? dataGridProps?.isRowSelectable({
                row,
                id: dataGridProps.getRowId ? dataGridProps.getRowId(row) : row.id,
                columns: apiRef.current?.getAllColumns?.(),
              })
            : true,
        )
      : rows;
  }, [apiRef, dataGridProps, rows]);

  const slots: DataGridProps['slots'] = useMemo(
    () => ({
      baseCheckbox: Checkbox,
      loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
      noResultsOverlay: () => (
        <NoRowPlaceholder
          icon={placeholderIcon}
          text={`No ${entityName || 'results'} with specified filters, try expanding your search.`}
        />
      ),
      noRowsOverlay: () => <NoRowPlaceholder icon={placeholderIcon} text={`No ${entityName || 'results'} found.`} />,
      footer: () =>
        !disableCustomMobileFooter && rows?.length ? (
          <MobileFooter totalRows={totalRows}>{mobileFooter}</MobileFooter>
        ) : null,
    }),
    [placeholderIcon, entityName, disableCustomMobileFooter, rows, totalRows, mobileFooter],
  );

  const getRowClassName = useCallback(
    (params: GridRowClassNameParams<GridValidRowModel>) => {
      if ('isRead' in params.row && !params.row.isRead && enableUnread) {
        return DATA_TABLE_CLASSES.UNREAD_ROW;
      }
      return '';
    },
    [enableUnread],
  );

  const handleSelectionFilterChange: SelectionFilterProps['onChange'] = ({ indexes, filterFn }) => {
    setSelectionFilterIndexes(indexes);

    if (filterFn) {
      const selectionModel = selectableRows
        ?.filter(filterFn)
        .map((row) => (dataGridProps.getRowId ? dataGridProps.getRowId(row) : row.id)) as GridRowId[];
      apiRef.current.setRowSelectionModel(selectionModel);
    }
  };

  const handleMultiSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && selectableRows) {
      apiRef.current.setRowSelectionModel(
        selectableRows.map((row) => (dataGridProps.getRowId ? dataGridProps.getRowId(row) : row.id)),
      );
    } else {
      apiRef.current.setRowSelectionModel([]);
    }
    setAllVisibleRowsSelected(event.target.checked);
  };

  const handleRowSelectionModelChange = useCallback(
    (ids: GridRowSelectionModel, details: GridCallbackDetails) => {
      setAllVisibleRowsSelected(ids.length === rows?.length);
      dataGridProps.onRowSelectionModelChange?.(ids, details);
      setSelectedIds(ids);
      if (ids.length !== rows?.length) {
        setTotalIsSelected(false);
      }
    },
    [dataGridProps, rows?.length],
  );

  const clearSelection = useCallback(() => {
    apiRef.current.setRowSelectionModel([]);
    setAllVisibleRowsSelected(false);
    setTotalIsSelected(false);
  }, [apiRef]);

  const batchActionsParams = useMemo(
    () => ({
      ids: selectedIds,
      rows: selectedIds.map((id) => apiRef.current.getRowParams(id)),
      clearSelection,
      totalSelected: totalIsSelected,
    }),
    [selectedIds, clearSelection, totalIsSelected, apiRef],
  );

  const toggleSelectTotal = useCallback(() => {
    setTotalIsSelected((prev) => !prev);
  }, []);

  const selectionText = totalIsSelected
    ? `All ${totalRows} item(s) are selected`
    : `Selected ${selectedIds.length} out of ${totalRows} item(s)`;

  return (
    <DataGridContainer>
      {isLoading ? (
        <FlexBox width="100%" flex="1 1 auto" minHeight="100%" justifyContent="center">
          <CircularProgress size={isMobile ? 32 : 64} />
        </FlexBox>
      ) : (
        <>
          <DataGridHeaderContainer stickyTop={stickyHeaderTopOffset}>
            {isSmallScreen && (
              <MobileAction onClearSelection={clearSelection} selectedCount={selectedIds.length}>
                {renderBatchActions?.(batchActionsParams)}
              </MobileAction>
            )}
            <FlexBox width={isSmallScreen ? '100%' : undefined} justifyContent="flex-start">
              <FlexBox width={isSmallScreen ? '100%' : undefined} gap={2}>
                {multiSelect && (
                  <FlexBox>
                    <Badge
                      color="primary"
                      badgeContent={
                        selectedIds.length > 0 && !isLargeScreen && !isSmallScreen ? (
                          <Tooltip title={selectionText}>
                            <span>{selectedIds.length}</span>
                          </Tooltip>
                        ) : null
                      }
                    >
                      <Checkbox checked={allVisibleRowsSelected} onChange={handleMultiSelectChange} />
                    </Badge>

                    {selectionFilters.length > 0 && (
                      <SelectionFilter
                        selectedIndexes={selectionFilterIndexes}
                        filters={selectionFilters}
                        onChange={handleSelectionFilterChange}
                      />
                    )}
                  </FlexBox>
                )}
                {onRefresh && (
                  <FlexBox marginLeft={isSmallScreen && multiSelect ? 'auto' : 'none'} alignItems="center">
                    <IconButton onClick={onRefresh} color="primary">
                      <RefreshIcon />
                    </IconButton>
                  </FlexBox>
                )}
                {headerLeft}
                {!isSmallScreen ? renderBatchActions?.(batchActionsParams) : null}
              </FlexBox>
            </FlexBox>
            {selectedIds.length > 0 && totalRows && isLargeScreen && (
              <FlexBox alignItems="center">
                <Typography variant="h5">{selectionText}</Typography>
                {enableTotalSelection && (
                  <SelectAllButton ml={2} variant="h5" onClick={toggleSelectTotal}>
                    {totalIsSelected ? 'Clear selection' : 'Select all'}
                  </SelectAllButton>
                )}
              </FlexBox>
            )}
            {!isSmallScreen && (
              <FlexBox justifyContent="flex-end">
                {paginationControl ? paginationControl : totalRows ? <DataTablePagination count={totalRows} /> : null}
              </FlexBox>
            )}
          </DataGridHeaderContainer>

          <DataGrid
            {...dataGridProps}
            {...(isSmallScreen ? { columnHeaderHeight: 1 } : {})}
            loading={loading}
            className={rows.length === 0 ? NO_ROWS_CLASS : undefined}
            autoHeight
            hideColumns={isSmallScreen ? true : hideColumns}
            disableColumnResize={disableColumnResize}
            disableColumnFilter={disableColumnFilter}
            disableColumnSorting={disableColumnSorting}
            disableColumnMenu={disableColumnMenu}
            hideFooter={!disableCustomMobileFooter && hideFooter && !isSmallScreen}
            getRowSpacing={getRowSpacing}
            getRowClassName={getRowClassName}
            apiRef={apiRef}
            columns={columns}
            rows={rows}
            onRowSelectionModelChange={handleRowSelectionModelChange}
            disableColumnSelector={disableColumnSelector}
            disableRowSelectionOnClick
            checkboxSelection={checkboxSelection}
            slots={slots}
            sx={{
              [`& .${gridClasses.columnHeaderCheckbox} .${gridClasses.columnHeaderTitleContainer}`]: {
                display: disableHeaderCheckbox ? 'none' : 'flex',
              },
            }}
          />
        </>
      )}
    </DataGridContainer>
  );
}
