import { AccountRelation } from '@usgm/inbox-api-types';
import { onboardingUtils } from '@usgm/utils';

export const extractCompanyAddressForReuse = (
  companyInfo: onboardingUtils.CompanyInfoFormSchema['accountNames'][number],
): onboardingUtils.CompanyInfoFormSchema['accountNames'][number] =>
  ({
    country: companyInfo.country,
    state: companyInfo.state,
    city: companyInfo.city,
    streetAddress: companyInfo.streetAddress,
    zipCode: companyInfo.zipCode,
    phoneNumber: companyInfo.phoneNumber,
    relationType: AccountRelation.Company,
    placeOfRegistration: companyInfo.placeOfRegistration,
    typeOfBusiness: companyInfo.typeOfBusiness,
  } as onboardingUtils.CompanyInfoFormSchema['accountNames'][number]);
