import { ShipmentScheduleFrequency } from '@usgm/inbox-api-types';
import { z } from 'zod';

export const shipmentSchedulingFormSchema = z
  .object({
    shipmentSchedule: z.nativeEnum(ShipmentScheduleFrequency),
    shipmentScheduleDay: z.number().default(1).optional(),
    scheduleAddressId: z.string().min(1, 'Address id is required.'),
    lettersService: z.string().min(1, 'Letters service is required.'),
    smallPackagesService: z.string().min(1, 'Small packages service is required.'),
    largePackagesService: z.string().min(1, 'Large packages service is required.'),
    scheduleDefaultPackingInstructions: z.string().optional(),
    scheduleOpenAndRepack: z.boolean(),
  })
  .superRefine(({ shipmentSchedule, shipmentScheduleDay }, ctx) => {
    if (
      [ShipmentScheduleFrequency.Weekly, ShipmentScheduleFrequency.Monthly].includes(shipmentSchedule) &&
      !shipmentScheduleDay
    ) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please specify scheduled day for shipment',
        path: ['shipmentScheduleDay'],
      });
    }
    if (shipmentSchedule === ShipmentScheduleFrequency.Weekly && shipmentScheduleDay && shipmentScheduleDay > 5) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please specify scheduled day for shipment',
        path: ['shipmentScheduleDay'],
      });
    }
  });

export type ShipmentSchedulingFormSchemaType = z.infer<typeof shipmentSchedulingFormSchema>;
