import { Box } from '@mui/material';

import { Preloader } from '@usgm/shared-ui';

import { ContentContainer } from '../../../components/DynamicSidebar/ContentContainer';

import { TopActionsContainer } from '../../../components/DynamicSidebar/TopActionsContainer';
import { useGetInvoiceByIdQuery } from '../api';
import DownloadBillingPdf from './DownloadBillingPdf';
import InvoiceDetails from './InvoiceDetails';

export type InvoiceInfoProps = {
  id: string;
};
function InvoiceInfo({ id }: InvoiceInfoProps) {
  const { data, isLoading } = useGetInvoiceByIdQuery({ id });

  return (
    <Box minHeight="100%">
      {isLoading && <Preloader />}
      {data && (
        <>
          <TopActionsContainer>
            <DownloadBillingPdf
              buttonLabel="Download PDF"
              buttonProps={{
                fullWidth: true,
                variant: 'contained',
              }}
              itemId={id}
              type="invoice"
            />
          </TopActionsContainer>
          <ContentContainer>
            <InvoiceDetails data={data} />
          </ContentContainer>
        </>
      )}
    </Box>
  );
}

export default InvoiceInfo;
