import { useCallback, useState } from 'react';
import { inboxHelpers } from '@usgm/utils';

export function useCopyToClipboard({ label, textToCopy }: { label?: string; textToCopy: string }) {
  const [data, setData] = useState<{ error: boolean; message: string }>({ error: false, message: '' });
  const [showMessage, setShowMessage] = useState(false);
  const clearMessage = useCallback(() => setShowMessage(false), []);
  const handleCopy = useCallback(() => {
    (async () => {
      const { error } = await inboxHelpers.copyTextToClipboard(textToCopy);
      setShowMessage(true);
      if (!error) {
        setData({ message: `${label} ${label ? `copied` : 'Copied'} to clipboard.`, error: false });
      } else {
        setData({
          message: inboxHelpers.extractErrorMessage(error, `Failed to copy${label ? ' ' + label : ''}.`),
          error: false,
        });
      }
    })();
  }, [label, textToCopy]);

  return { data, showMessage, handleCopy, clearMessage };
}
