import { alpha, Avatar, IconButtonProps, styled } from '@mui/material';
import { TextButton } from './';
import * as React from 'react';
import { forwardRef, ReactNode } from 'react';

interface IconLinkButtonProps extends IconButtonProps {
  icon: ReactNode;
}

const StyledIconContainer = styled(Avatar)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.15),
  color: theme.palette.primary.main,
  width: theme.spacing(3),
  height: theme.spacing(3),
}));

export default forwardRef<HTMLButtonElement, Omit<IconLinkButtonProps, 'color'>>(function IconLinkButton(props, ref) {
  return (
    <TextButton {...props} startIcon={<StyledIconContainer>{props.icon}</StyledIconContainer>}>
      {props.value}
    </TextButton>
  );
});
