import { Box } from '@mui/material';
import { StickyTabPageContent, PageStepper } from '@usgm/shared-ui';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../../../store/index';
import {
  selectHasRejectedDocuments,
  selectIsAccountNamesAdded,
  selectIsDocumentsUploaded,
} from '../../../../../auth/authSlice';
import { useOnboardingData } from '../../hooks/useOnboardingData';
import { ONBOARDING_PATHS } from '../../paths';

const STEPPER = [
  {
    label: 'Add Names',
    path: ONBOARDING_PATHS.ADD_NAMES,
  },
  {
    label: 'Add IDs',
    path: ONBOARDING_PATHS.ADD_IDS,
  },
  {
    label: 'Notarize',
    path: ONBOARDING_PATHS.GET_NOTARIZED,
  },
];

export function OnboardingStepLayout() {
  const { isLoading } = useOnboardingData();
  const isAccountNamesAdded = useAppSelector((state) => selectIsAccountNamesAdded(state, null));
  const isDocumentsUploaded = useAppSelector(selectIsDocumentsUploaded);
  const hasRejectedDocuments = useAppSelector((state) => selectHasRejectedDocuments(state, null));

  const { pathname } = useLocation();
  const isNotarized = pathname.includes(ONBOARDING_PATHS.GET_NOTARIZED);
  const isIdsPage = pathname.includes(ONBOARDING_PATHS.ADD_IDS);

  const idsPageRedirect = isIdsPage && !isAccountNamesAdded ? ONBOARDING_PATHS.ADD_NAMES : null;
  const notarizedPageRedirect =
    isNotarized && (!isDocumentsUploaded || hasRejectedDocuments) ? ONBOARDING_PATHS.ADD_IDS : idsPageRedirect;

  const redirect = idsPageRedirect || notarizedPageRedirect;

  if (redirect && !isLoading) {
    return <Navigate to={redirect} />;
  }

  return (
    <Box>
      <PageStepper steps={STEPPER} />
      <StickyTabPageContent>{<Outlet />}</StickyTabPageContent>
    </Box>
  );
}
