import { GridColDef } from '@mui/x-data-grid';
import { cellHelpers } from '.';

export const DEFAULT_COL_DEF: Partial<GridColDef> = {
  type: 'string',
  flex: 1,
  filterable: false,
  sortable: false,
  display: 'flex',
  renderCell: cellHelpers.renderTextCell,
};

export const DEFAULT_IMAGE_CELL_PARAMS: Partial<GridColDef> = {
  minWidth: 200,
  maxWidth: 300,
};
