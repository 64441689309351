import { type ShippableItem } from '@usgm/inbox-api-types';

export function calculateTotalInsuredAmount({ mails }: { mails: ShippableItem[] }) {
  return mails.reduce((total, mail) => {
    if (!mail.shippingDeclarations) {
      return total;
    } else {
      return mail.shippingDeclarations?.reduce((declarationsTotal, declaration) => {
        return declarationsTotal + declaration.itemValue * declaration.quantity;
      }, total);
    }
  }, 0);
}
