import { FormControlLabel, Typography, useTheme } from '@mui/material';
import { StyledFormControl } from './styled/StyledFormControl';
import { Checkbox, FlexBox } from '@usgm/shared-ui';

import { useAppDispatch, useAppSelector } from '../../../../../../../../../store';
import { mailsShipmentSlice, selectIncludeNewItems } from '../../mailsShipmentSlice';

function IncludeNewItemsControl() {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const includeNewItems = useAppSelector(selectIncludeNewItems);
  const handleChange = () => {
    dispatch(mailsShipmentSlice.actions.setIncludeNewItems(!includeNewItems));
  };

  return (
    <StyledFormControl>
      <FormControlLabel
        value={includeNewItems}
        onChange={handleChange}
        sx={{ alignItems: 'flex-start' }}
        defaultChecked={includeNewItems}
        control={<Checkbox />}
        label={
          <FlexBox
            gap={0.5}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            component="span"
          >
            <Typography fontWeight={theme.typography.fontWeightBold} component="span">
              Include new items which arrive in my inbox
            </Typography>
            <Typography fontSize="0.75rem" color={theme.customColors.dark[300]} component="span">
              Items received on shipping date and undeclared items (for international shipments) will not be added to
              the shipment.
            </Typography>
          </FlexBox>
        }
      />
    </StyledFormControl>
  );
}

export default IncludeNewItemsControl;
