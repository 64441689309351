import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CalculateShipmentRatesParams,
  type DtoCalculateShipmentRates,
  type DtoValidateAddress,
  type ValidateAddressParams,
} from '@usgm/inbox-api-types';
import { ENVIRONMENT } from '../env';
import { axiosBaseQuery } from './axios/baseQuery';
import { snakeCaseKeys } from '@usgm/utils';

export const postmenApi = createApi({
  reducerPath: 'postmenApi',
  baseQuery: axiosBaseQuery(ENVIRONMENT.POSTMEN_API_URL),
  endpoints: (builder) => ({
    validateAddress: builder.mutation<DtoValidateAddress, ValidateAddressParams>({
      query: (address) => ({
        url: '/validate-address',
        method: 'post',
        data: address,
      }),
    }),
    calculateShipmentRates: builder.mutation<DtoCalculateShipmentRates, CalculateShipmentRatesParams>({
      query: ({ shipTo: { street1, street2, street3, ...shipTo }, ...rest }) => ({
        url: '/rates',
        method: 'post',
        data: {
          ...snakeCaseKeys(rest),
          ship_to: {
            // https://github.com/blakeembrey/change-case/issues/50
            ...snakeCaseKeys(shipTo),
            street1,
            street2,
            street3,
          },
        },
      }),
    }),
  }),
});

export const { useCalculateShipmentRatesMutation, useValidateAddressMutation } = postmenApi;
