import { Box, Typography, styled } from '@mui/material';

import { BaseShipment } from '@usgm/inbox-api-types';
import { Preloader } from '@usgm/shared-ui';

import { useAuth } from '../../../../auth/hooks/useAuth';
import { ContentContainer } from '../../../components/DynamicSidebar/ContentContainer';

import { useGetShipmentByIdQuery } from '../api';
import ShipmentDetails from './ShipmentDetails';
import ShipmentItems from './ShipmentItems';
import ContactSupport from './actions/ContactSupport';
import EditableShipmentActions from './actions/EditableShipmentActions';

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '1.25rem',
  paddingBottom: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export type ShipmentInfoProps = {
  id: BaseShipment['uuid'];
  handleClose?: () => void;
};

function ShipmentInfo({ id, handleClose }: ShipmentInfoProps) {
  const { isAccountUnderPaymentHold } = useAuth();
  const { data, isLoading } = useGetShipmentByIdQuery({ id });

  const isEditable = data?.isEditAllowed || data?.isCancelAllowed;

  const Actions = isEditable ? EditableShipmentActions : ContactSupport;

  return (
    <Box minHeight="100%">
      {isLoading && <Preloader />}
      {data && (
        <>
          <Actions onActionSuccess={handleClose} disabled={isAccountUnderPaymentHold} data={data} />

          <ContentContainer>
            <SectionTitle>Shipment Details</SectionTitle>
            <Box mb={2}>
              <ShipmentDetails data={data} />
            </Box>
            <SectionTitle mt={6}>Items & Declarations</SectionTitle>
            <ShipmentItems data={data} />
          </ContentContainer>
        </>
      )}
    </Box>
  );
}

export default ShipmentInfo;
