import { ListItem, ListItemProps, styled } from '@mui/material';
import { FlexBox } from './FlexBox';
import { ReactNode } from 'react';

const StyledListItem = styled(ListItem, { shouldForwardProp: (prop) => prop !== 'iconColor' })<{ iconColor?: string }>(
  ({ theme, iconColor }) => ({
    display: 'flex',
    padding: theme.spacing(1, 0),
    '& span>svg': {
      color: iconColor,
    },
  }),
);

type IconListItemProps = ListItemProps & {
  icon: ReactNode;
  iconColor?: string;
};

export function IconListItem(props: IconListItemProps) {
  const { icon, ...itemProps } = props;
  return (
    <StyledListItem {...itemProps}>
      <FlexBox justifyContent="center" mr={1.5} component="span">
        {icon}
      </FlexBox>
      {props.children}
    </StyledListItem>
  );
}

export default IconListItem;
