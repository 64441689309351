import { Box, CircularProgress, styled, Typography } from '@mui/material';
import { FlexBox, InlineButton, useMobileMode } from '@usgm/shared-ui';
import { CardItemWrapper } from './CardItemWrapper';

const InnerContent = styled(FlexBox)(({ theme }) => ({
  width: '100%',
  alignItems: 'flex-start',
  flex: 2,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const ItemLabel = styled(Typography)(({ theme }) => ({
  color: theme.customColors.dark[400],
  fontWeight: theme.typography.fontWeightBold,
  marginRight: theme.spacing(2),
  lineHeight: 1,
}));

type ItemProps = {
  label: string;
  value?: string;
  valueColor?: string;
  actionHandler?: () => void;
  actionText?: string;
  isLoading?: boolean;
};

export function CardItem({
  label,
  value,
  actionText,
  actionHandler,
  isLoading = false,
  valueColor = 'inherit',
}: ItemProps) {
  const isMobile = useMobileMode();

  const actionButton = actionText && <InlineButton onClick={actionHandler}>{actionText}</InlineButton>;

  return (
    <CardItemWrapper>
      <InnerContent>
        <FlexBox alignItems="flex-start" flex={1} width="100%" mb={1}>
          <ItemLabel>{label}</ItemLabel>
          {isMobile ? actionButton : null}
        </FlexBox>

        <Box flex={2}>
          {!isLoading ? (
            <Typography lineHeight="1" color={valueColor} fontWeight="bold">
              {value}
            </Typography>
          ) : (
            <CircularProgress size={16} />
          )}
        </Box>
      </InnerContent>
      {!isMobile && (
        <Box flex={1} ml={2} justifyContent="center" alignItems="flex-start" alignSelf="stretch" display="flex">
          {actionButton}
        </Box>
      )}
    </CardItemWrapper>
  );
}
