import { ReactNode } from 'react';
import { Card, CardContent, CardHeader, CircularProgress, styled } from '@mui/material';
import mainLogoSrc from '../assets/mainLogo.svg';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 5,
  padding: theme.spacing(2),
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  textAlign: 'center',
  '& .MuiCardHeader-subheader': {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export type LoadingCardProps = {
  maxWidth?: number;
  maxHeight?: number;
  includeLogo?: boolean;
  title?: string;
  children?: ReactNode;
  largeText?: boolean;
  hasLoading?: boolean;
  logoWidth?: string;
};

export function LoadingCard({
  includeLogo = true,
  maxHeight = 300,
  maxWidth = 300,
  hasLoading = true,
  title,
  logoWidth,

  children,
}: LoadingCardProps) {
  return (
    <StyledCard sx={{ maxWidth, maxHeight }}>
      <StyledCardHeader
        title={includeLogo ? <img width={logoWidth || maxWidth - 100} src={mainLogoSrc} alt="US Global Mail" /> : null}
        subheader={title}
      />
      <StyledCardContent>
        {hasLoading ? <CircularProgress /> : null}
        {children}
      </StyledCardContent>
    </StyledCard>
  );
}
