import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, styled, Typography } from '@mui/material';

import { ScanBundleSubscriptionStatus } from '@usgm/inbox-api-types';
import { cellHelpers, FlexBox, Inbox, MessageBox, Preloader, Toast } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';

import {
  useCancelUserScanBundleMutation,
  useGetScanBundleOptionsQuery,
  usePurchaseUserScanBundleMutation,
} from '../../../../../../../api/paymentApi';

import { useOnboardingData } from '../../../../onboarding/hooks/useOnboardingData';
import { useGetUserFreeScansQuery } from '../../../api';
import { CardHeader } from '../../../components/Card';
import ScanBundleOptionCard, { ScanBundleOptionCardProps } from '../../../components/ScanBundles/ScanBundleOptionCard';
import { useUserScanBundleData } from '../../../hooks/useUserScanBundleData';
import { SETTINGS_PATHS } from '../../../paths';

const StyledContainer = styled(FlexBox)(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  height: '100%',
  paddingTop: theme.spacing(2),
}));

function ScanBundlesPage() {
  const navigate = useNavigate();
  const { data, isLoading } = useGetScanBundleOptionsQuery();
  const { activeScanBundle, activeScanBundleSubscription } = useUserScanBundleData();
  const [cancelUserScanBundle, cancelUserScanBundleQuery] = useCancelUserScanBundleMutation();
  const [purchaseUserScanBundle, purchaseUserScanBundleQuery] = usePurchaseUserScanBundleMutation();
  const { isLoading: isLoadingFreeScans, data: userFreeScans } = useGetUserFreeScansQuery();
  const { activeSubscription } = useOnboardingData();
  const isScanBundleSupported = activeSubscription?.plan?.otherOptions?.isScanBundlesSupported;

  const getCardState = useMemo(
    () =>
      (optionId: number): ScanBundleOptionCardProps['cardState'] => ({
        isScanBundleSupported: !!isScanBundleSupported,
        isActiveBundle: activeScanBundle?.bundleOptionId === optionId,
        isActiveSubscription: activeScanBundleSubscription?.bundleOptionId === optionId,
        disabled: activeScanBundle
          ? activeScanBundle.bundleOptionId !== optionId ||
            activeScanBundle.subscriptionStatus !== ScanBundleSubscriptionStatus.Active
          : false,
        expiresAt: activeScanBundle?.bundleOptionId === optionId ? activeScanBundle?.endDate : undefined,
        isLoading:
          (cancelUserScanBundleQuery.isLoading && optionId === activeScanBundleSubscription?.bundleOptionId) ||
          (purchaseUserScanBundleQuery.isLoading && optionId === purchaseUserScanBundleQuery.originalArgs?.id),
        pagesUsed: activeScanBundle?.bundleOptionId === optionId ? activeScanBundle?.pagesUsed : undefined,
      }),
    [
      activeScanBundle,
      activeScanBundleSubscription,
      cancelUserScanBundleQuery.isLoading,
      purchaseUserScanBundleQuery.isLoading,
      purchaseUserScanBundleQuery.originalArgs,
      isScanBundleSupported,
    ],
  );

  const handleCancel = () => {
    if (cancelUserScanBundleQuery.isLoading || !activeScanBundleSubscription?.id) return;
    cancelUserScanBundle({ id: activeScanBundleSubscription.id });
  };

  const handlePurchase = (bundleOptionId: number) => {
    if (purchaseUserScanBundleQuery.isLoading) return;
    purchaseUserScanBundle({ id: bundleOptionId });
  };

  const getUserFreeScansMessage = () => {
    if (!userFreeScans?.data?.numPages) return '';
    return `Your membership plan includes free scan pages, of which you have ${inboxHelpers.formatNumber(
      userFreeScans.data.numPages,
      false,
    )} pages remaining to use till ${cellHelpers.formatDateString(userFreeScans.data.validTill)}.`;
  };

  const getPageSubtitleText = () => {
    if (activeScanBundleSubscription) {
      return 'Add new or cancel scan bundles. Bundles auto renew when used up or upon expiry, whichever comes first, until canceled.';
    }
    if (activeScanBundle) {
      return 'You currently have a canceled bundle that has not expired. Bundles will be available again when all scan pages are used up or the bundle expires.';
    }
    return 'Purchase a set number of pages for scanning at a discounted rate. All bundles auto renew when used up or upon expiry, whichever comes first, until canceled.';
  };

  const handleUpgradeToBusiness = () => {
    navigate(SETTINGS_PATHS.PLAN_DETAILS);
  };

  return (
    <>
      <Inbox.PageContainer>
        <Inbox.Card noSpacings>
          <CardHeader
            title="Choose Your Bundle"
            subTitle="Save more on individual scans with our perfect scan bundle subscriptions."
          />

          <Inbox.CardContent>
            <StyledContainer>
              <Typography>{getPageSubtitleText()}</Typography>
              {!isLoadingFreeScans && userFreeScans?.data?.numPages ? (
                <Box mt={1}>
                  <MessageBox title={getUserFreeScansMessage()} severity={'info'} />
                </Box>
              ) : null}
              <FlexBox flexWrap={'wrap'} justifyContent={'center'} width={'100%'}>
                {isLoading && <Preloader />}
                {!isLoading &&
                  data?.results &&
                  data.results.map((bundleOption) => (
                    <ScanBundleOptionCard
                      key={bundleOption?.id}
                      scanBundleOption={bundleOption}
                      cardState={getCardState(bundleOption?.id)}
                      onCancel={handleCancel}
                      onPurchase={() => handlePurchase(bundleOption?.id)}
                      onUpgradePlan={handleUpgradeToBusiness}
                    />
                  ))}
              </FlexBox>
            </StyledContainer>
          </Inbox.CardContent>
        </Inbox.Card>
      </Inbox.PageContainer>

      {(cancelUserScanBundleQuery.isError || purchaseUserScanBundleQuery.isError) && (
        <Toast
          severity={'error'}
          title={'Oops! Something went wrong. Please check your payment method and try again.'}
        />
      )}
      {cancelUserScanBundleQuery.isSuccess && (
        <Toast severity={'success'} title={'Scan Bundle subscription canceled.'} />
      )}
      {purchaseUserScanBundleQuery.isSuccess && (
        <Toast severity={'success'} title={'Scan Bundle subscription activated.'} />
      )}
    </>
  );
}

export default ScanBundlesPage;
