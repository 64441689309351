import { DataGridProps } from '@mui/x-data-grid';

export function useDefaultGridConfig(): Partial<DataGridProps> {
  return {
    disableColumnResize: true,
    disableColumnMenu: true,
    hideFooter: true,
    disableRowSelectionOnClick: true,
    rowHeight: 80,
  };
}
