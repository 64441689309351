import { DtoUnbilledCharge } from '@usgm/inbox-api-types';
import { EntityCell } from '@usgm/shared-ui';

const constructUnbilledChargeInfoList = (data: DtoUnbilledCharge) => {
  return [
    {
      title: 'Charge ID',
      value: `#${data.vendorId}`,
    },
    {
      title: 'Charge Description',
      value: data.description,
    },
    {
      title: 'Quantity',
      value: data.quantity,
    },
    {
      title: 'Total Amount',
      value: data.amountTotal,
    },
    {
      title: 'Created date',
      value: data.date,
    },
  ];
};

export type UnbilledChargeInfoCellProps = {
  data: DtoUnbilledCharge;
};
function UnbilledChargeInfoCell({ data }: UnbilledChargeInfoCellProps) {
  return <EntityCell infoList={constructUnbilledChargeInfoList(data)} />;
}

export default UnbilledChargeInfoCell;
