import { USETIFUL_TOUR_ID } from '@inbox/constants';
import { Box, Typography } from '@mui/material';

import { UserInfo } from '@usgm/inbox-api-types';
import { FlexBox, Preloader, UnderlineTextButton } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import CopyCode from '../../../components/CopyCode';

import { useUserWarehouseDetails } from '../../../hooks/useUserWarehouseDetails';
import { useOnboardingData } from '../../onboarding/hooks/useOnboardingData';
import { WorkingHours } from './WorkingHours';

export type HelpSidebarContentProps = {
  userUUID: UserInfo['userUUID'];
  handleCloseSidebar?: () => void;
};

export function HelpSidebarContent({ userUUID, handleCloseSidebar }: HelpSidebarContentProps) {
  const { data, isLoading } = useUserWarehouseDetails(userUUID);
  const { isPrimaryAccountStepsCompleted } = useOnboardingData();

  const handlePhoneClick = () => {
    window.location.href = `tel:${data?.warehousePhone}`;
  };
  const handleEmailClick = () => {
    window.location.href = `mailto:${data?.warehouseEmail}`;
  };
  const handleAddressClick = () => {
    if (data?.warehouseAddress) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${inboxHelpers.stringifyAddress({
          addr: data.warehouseAddress,
        })}`,
        '_blank',
      );
    } else {
      console.error('No address found');
    }
  };
  const handleShowTourClick = () => {
    window.USETIFUL?.tour?.start?.(USETIFUL_TOUR_ID);
    handleCloseSidebar?.();
  };

  if (isLoading) return <Preloader />;

  if (!data) return null;

  const { stringifiedAddress, warehouseEmail, warehouseOperationalHours, warehousePhone } = data;

  return (
    <>
      <Typography>Please reach out to us using the information below</Typography>
      <FlexBox gap={3} mt={5} flexDirection="column">
        <CopyCode onClick={handleEmailClick} code={warehouseEmail} label="Email address" />

        <CopyCode onClick={handlePhoneClick} code={warehousePhone} label="Phone number" />

        <CopyCode onClick={handleAddressClick} code={stringifiedAddress} label="Store address" />
      </FlexBox>
      <Box mt={5}>
        <WorkingHours workingHours={warehouseOperationalHours} />
      </Box>
      {isPrimaryAccountStepsCompleted && (
        <FlexBox mt={5}>
          <UnderlineTextButton onClick={handleShowTourClick}>Guide me through my Inbox</UnderlineTextButton>
        </FlexBox>
      )}
    </>
  );
}
