import { Box, Button, styled, useTheme } from '@mui/material';

import { GridRowParams } from '@mui/x-data-grid';
import {
  type BaseMail,
  isUnboxingMailType,
  ReportAnIssueType,
  SCAN_MAIL_TYPES,
  ScanMailType,
  ScanStatus,
  ShipmentCategory,
} from '@usgm/inbox-api-types';
import {
  AddButton,
  FlexBox,
  MultiSelectionList,
  SelectMenu,
  SelectMenuProps,
  Toast,
  useDialog,
  useResponsiveModes,
} from '@usgm/shared-ui';

import { MouseEvent, useCallback, useMemo, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../../../store';
import { useAuth } from '../../../../../auth/hooks/useAuth';
import DeclareMailItemDialog from '../../../../components/DeclareMailItemDialog';
import ReportAnIssueDialog from '../../../../components/ReportAnIssueDialog';
import { SETTINGS_PATHS } from '../../../settings/paths';
import { useMoveMailsToFolderMutation, useUpdateIsReadStatusMutation } from '../../api';
import { useMailFolders } from '../../hooks/useMailFolders';
import { useMailsListParams } from '../../hooks/useMailsListParams';
import { TFolder } from '../../mailFolders/folderSlice';
import { useFolderManagement } from '../../mailFolders/useFolderManagement';
import { mailsSlice } from '../../mailsSlice';
import { MailActionRequest } from '../../paths';
import ConfirmDiscardsDialog from '../ConfirmDiscardsDialog';
import InfoDialog, { InfoDialogProps } from '../ConfirmMailRequestDialog';
import ConfirmReturnDialog from '../ConfirmReturnDialog';
import { mailsShipmentSlice } from '../mailsActionRequests/shipment/mailsShipmentSlice';

const PaperBackgroundButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '&:hover': { backgroundColor: theme.palette.background.paper },
}));

export type MailActionsProps = {
  ids: BaseMail['id'][];
  singleItem?: boolean;
  rows?: Omit<GridRowParams<BaseMail>, 'columns'>[];
  clearSelection?: () => void;
  isQuarantine?: boolean;
  moreVariant?: 'icon' | 'text';
  shortButtons?: boolean;
};

function MailActions({
  ids,
  singleItem,
  rows,
  clearSelection,
  isQuarantine = false,
  moreVariant = 'text',
  shortButtons = false,
}: MailActionsProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isRegularTeamMember, isAccountUnderPaymentHold } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const { createFolder, dialogs } = useFolderManagement();
  const [moveToFolderInfo, setMoveToFolderInfo] = useState<{ info?: { isSuccess: boolean; message: string } }>({});

  const { allFolders, folderEntities, folderIdExtractor, folderLabelExtractor } = useMailFolders();

  const contactSupportDialog = useDialog();
  const discardDialog = useDialog();
  const returnDialog = useDialog();
  const declareDialog = useDialog();
  const mailActionRequestDialog = useDialog<string>({ open: false, value: '' });

  const { filter, folderId, limit, skip, tab } = useMailsListParams();
  const [updateIsReadStatus] = useUpdateIsReadStatusMutation();
  const [moveMailToFolderMutation, { isLoading: moveToFolderIsLoading, reset: resetMoveToFolderMutation }] =
    useMoveMailsToFolderMutation();

  const theme = useTheme();
  const { isDownMobileLandscape, isMobile } = useResponsiveModes();

  const moveSelectedMailToFolder = useCallback(
    async (folderId: number) => {
      const response = await moveMailToFolderMutation({ ids, folderId });
      const mailsLabel = ids.length > 1 ? 'mails' : 'mail';
      const folderName = folderEntities[folderId]?.label || 'Unassigned';
      if ('error' in response) {
        setMoveToFolderInfo({ info: { isSuccess: false, message: `Failed to move ${mailsLabel} ${folderName}` } });
      } else {
        setMoveToFolderInfo({ info: { isSuccess: true, message: `Moved ${mailsLabel} to ${folderName}` } });
      }
    },
    [folderEntities, ids, moveMailToFolderMutation],
  );

  const handleFolderSelect = useCallback(
    (closeParent: () => void) => async (selectedItems: Array<string | number>) => {
      const folderId = Number(selectedItems[0]);
      if (!folderId) return;
      closeParent();
      await moveSelectedMailToFolder(folderId);
    },
    [moveSelectedMailToFolder],
  );

  const resetMoveToFolderInfo = useCallback(() => {
    setMoveToFolderInfo({});
    resetMoveToFolderMutation();
    clearSelection?.();
  }, [clearSelection, resetMoveToFolderMutation]);

  const packages = useMemo(() => rows?.filter(({ row }) => isUnboxingMailType(row.mailType)) || [], [rows]);
  const letters = useMemo(
    () => rows?.filter(({ row }) => SCAN_MAIL_TYPES.includes(row.mailType as ScanMailType)) || [],
    [rows],
  );

  const showOpenButton = packages.length > 0;
  const showScanButton = letters.length > 0;
  const showDepositButton = letters.length > 0;
  const showRemoveFromFolderButton = useMemo(() => rows?.some(({ row }) => row.mailFolder?.id), [rows]);

  const handleMailActionRequest = useCallback(
    (actionRequest: MailActionRequest) => {
      const items = (actionRequest === MailActionRequest.Scan ? letters : packages) || [];
      clearSelection?.();
      dispatch(mailsSlice.actions.setSelectedMails(items.map(({ row }) => row)));
      searchParams.set('request', actionRequest);
      setSearchParams(searchParams);
    },
    [clearSelection, dispatch, letters, packages, searchParams, setSearchParams],
  );

  const handleScanClick = (ev: MouseEvent) => {
    ev.stopPropagation();
    if (packages.length > 0) {
      mailActionRequestDialog.openDialog(
        'One of your items is a package. Scan is not available for package but you can open your package instead.',
      );
    } else {
      handleMailActionRequest(MailActionRequest.Scan);
    }
  };

  const handleOpenClick = useCallback(
    (ev?: MouseEvent) => {
      ev?.stopPropagation();
      if (letters.length > 0) {
        mailActionRequestDialog.openDialog(
          'One of your items is a letter. Open is not available for letter but you can scan them instead.',
        );
      } else {
        handleMailActionRequest(MailActionRequest.Open);
      }
    },
    [handleMailActionRequest, letters.length, mailActionRequestDialog],
  );

  const actionsDisabled = singleItem && !!rows?.length && rows[0].row.scanInfo?.status === ScanStatus.InProcess;

  const handleShipClick = useCallback(
    (shipmentType: ShipmentCategory) => (ev?: MouseEvent) => {
      ev?.stopPropagation();

      const itemsToShip = shipmentType === ShipmentCategory.CheckDepositRequest ? letters : rows;
      dispatch(mailsShipmentSlice.actions.resetState());
      dispatch(
        mailsShipmentSlice.actions.setMails({
          mails: itemsToShip?.map(({ row }) => row) || [],
          shipmentType,
        }),
      );
      handleMailActionRequest(MailActionRequest.Ship);
    },
    [dispatch, handleMailActionRequest, rows, letters],
  );

  const handleReturnClick = useCallback(() => {
    dispatch(
      mailsShipmentSlice.actions.setMails({
        mails: rows?.map(({ row }) => row) || [],
        shipmentType: ShipmentCategory.ReturnToSenderRequest,
      }),
    );
    returnDialog.openDialog();
  }, [dispatch, rows, returnDialog]);

  const handleDeclareClick = useCallback(() => {
    declareDialog.openDialog();
  }, [declareDialog]);

  const getScanRequestLabel = useCallback(
    (label: string) => {
      return singleItem && rows?.[0]?.row?.scanInfo ? `Re-${label}` : label;
    },
    [rows, singleItem],
  );

  const secondaryActions: SelectMenuProps<never>['options'] = useMemo(() => {
    const actions = [
      ...(isDownMobileLandscape && showOpenButton
        ? [
            {
              label: getScanRequestLabel('Open'),
              disabled: actionsDisabled,
              onClick: () => handleOpenClick(),
            },
          ]
        : []),
      ...(showDepositButton
        ? [
            {
              label: 'Deposit Check',
              onClick: handleShipClick(ShipmentCategory.CheckDepositRequest),
            },
          ]
        : []),
      {
        label: 'Add Name',
        supportsQuarantine: true,
        onlyQuarantine: true,
        disabled: actionsDisabled,
        onClick: () => {
          navigate(SETTINGS_PATHS.ACCOUNT_NAMES);
        },
      },
      {
        label: 'Discard',
        supportsQuarantine: true,
        disabled: actionsDisabled,
        onClick: () => {
          discardDialog.openDialog();
        },
      },
      ...(!isRegularTeamMember
        ? [
            {
              label: 'Move to Folder',
              renderContent: function ({ closeParent }: { closeParent: () => void }) {
                const handleAddButtonClick = () => {
                  createFolder();
                  closeParent();
                };
                return (
                  <Box width={shortButtons || isMobile ? '100%' : 300} px={2}>
                    <MultiSelectionList<TFolder, string>
                      hideCheckbox
                      listMaxHeight={260}
                      onChange={handleFolderSelect(closeParent)}
                      searchPlaceholder="Search folders"
                      items={allFolders}
                      selected={[]}
                      keyExtractor={folderIdExtractor}
                      labelExtractor={folderLabelExtractor}
                    />
                    <Box mt={3}>
                      <AddButton onClick={handleAddButtonClick} label="Add Folder" />
                    </Box>
                  </Box>
                );
              },
            },
            ...(showRemoveFromFolderButton
              ? [
                  {
                    label: 'Remove from Folder',
                    supportsQuarantine: false,
                    disabled: actionsDisabled,
                    onClick: () => {
                      moveSelectedMailToFolder(-1);
                    },
                  },
                ]
              : []),
          ]
        : []),
      {
        label: 'Return',
        supportsQuarantine: true,
        disabled: actionsDisabled,
        onClick: () => {
          handleReturnClick();
        },
      },
      ...(showOpenButton
        ? [
            {
              label: 'Declare',
              onClick: handleDeclareClick,
            },
          ]
        : []),
      {
        label: 'Mark as Read',
        onClick: async () => {
          const filteredIds = rows?.filter(({ row }) => !row.isRead).map(({ row }) => row.id) || [];
          if (filteredIds.length > 0) {
            await updateIsReadStatus({ ids: filteredIds, isRead: true, tab, filter, folderId, limit, skip });
          }
          clearSelection?.();
        },
      },
      {
        label: 'Mark as Unread',
        onClick: async () => {
          const filteredIds = rows?.filter(({ row }) => row.isRead).map(({ row }) => row.id) || [];
          if (filteredIds.length > 0) {
            await updateIsReadStatus({ ids: filteredIds, isRead: false, tab, filter, folderId, limit, skip });
          }
          clearSelection?.();
        },
      },
    ];

    if (singleItem) {
      actions.unshift({
        label: 'Contact Support',
        disabled: actionsDisabled,
        onClick: async () => {
          contactSupportDialog.openDialog();
        },
      });
    }

    return isQuarantine
      ? actions.filter((action) => action.supportsQuarantine)
      : actions.filter((action) => !action.onlyQuarantine);
  }, [
    isDownMobileLandscape,
    showOpenButton,
    getScanRequestLabel,
    actionsDisabled,
    showDepositButton,
    handleShipClick,
    isRegularTeamMember,
    showRemoveFromFolderButton,
    handleDeclareClick,
    singleItem,
    isQuarantine,
    handleOpenClick,
    navigate,
    discardDialog,
    shortButtons,
    isMobile,
    handleFolderSelect,
    allFolders,
    folderIdExtractor,
    folderLabelExtractor,
    createFolder,
    moveSelectedMailToFolder,
    handleReturnClick,
    rows,
    clearSelection,
    updateIsReadStatus,
    tab,
    filter,
    folderId,
    limit,
    skip,
    contactSupportDialog,
  ]);

  const handleSelectMenuOpen = useCallback(() => {
    if (singleItem && rows && rows.length > 0) {
      dispatch(mailsSlice.actions.setActiveItem(rows[0].row));
    }
  }, [dispatch, rows, singleItem]);

  const clearActiveItem = useMemo(() => {
    if (singleItem) {
      return () => {
        dispatch(mailsSlice.actions.setActiveItem(null));
      };
    }
  }, [dispatch, singleItem]);

  const mailRequestDialogActions: InfoDialogProps['actions'] = useMemo(() => {
    return [
      {
        label: `Scan Letters (${letters.length})`,
        onClick: () => handleMailActionRequest(MailActionRequest.Scan),
        variant: 'contained',
      },
      {
        label: `Open Packages (${packages.length})`,
        onClick: () => handleMailActionRequest(MailActionRequest.Open),
      },
    ];
  }, [handleMailActionRequest, letters.length, packages.length]);

  const getItemsForDeclaration = useMemo(() => {
    return (
      packages?.map((it) => ({
        mailId: it.row?.id,
        declarations: it.row?.shippingDeclarations || [],
      })) || []
    );
  }, [packages]);

  const isScanRequestDisabled = singleItem && rows?.[0]?.row?.scanInfo?.status === ScanStatus.InProcess;

  return (
    <>
      <FlexBox justifyContent={shortButtons ? 'flex-end' : 'space-between'} width="100%" gap={shortButtons ? 1 : 2}>
        {!isQuarantine && (
          <>
            <Button
              onClick={handleShipClick(ShipmentCategory.ShipmentRequest)}
              disabled={isAccountUnderPaymentHold}
              fullWidth={!shortButtons}
              variant="contained"
              color="primary"
            >
              Ship
            </Button>

            {showScanButton && (
              <PaperBackgroundButton
                disabled={isAccountUnderPaymentHold || isScanRequestDisabled}
                onClick={handleScanClick}
                fullWidth={!shortButtons}
                variant="outlined"
              >
                {getScanRequestLabel('Scan')}
              </PaperBackgroundButton>
            )}

            {showOpenButton && !isDownMobileLandscape && (
              <PaperBackgroundButton
                disabled={isAccountUnderPaymentHold || isScanRequestDisabled}
                onClick={handleOpenClick}
                fullWidth={!shortButtons}
                variant="outlined"
              >
                {getScanRequestLabel('Open')}
              </PaperBackgroundButton>
            )}
          </>
        )}

        <SelectMenu
          disabled={isAccountUnderPaymentHold}
          containerProps={{ sx: { flex: '1 1 auto' } }}
          asBottomSheet={isMobile}
          onOpen={handleSelectMenuOpen}
          isLoading={moveToFolderIsLoading}
          moreVariant={moreVariant}
          buttonProps={{
            sx: [
              {
                width: '100%',
                backgroundColor: theme.palette.background.paper,
                '&:hover': { backgroundColor: theme.palette.background.paper },
              },
            ],
          }}
          direction={singleItem ? 'left' : 'right'}
          onChange={clearActiveItem}
          onClose={clearActiveItem}
          display="Button"
          options={secondaryActions}
          label={'More'}
        />
        {singleItem && (
          <ReportAnIssueDialog
            reportAnIssueType={ReportAnIssueType.MailRequest}
            open={contactSupportDialog.open}
            requestID={ids[0]}
            confirmText="Submit"
            title="Contact Support"
            onClose={contactSupportDialog.closeDialog}
            onConfirm={contactSupportDialog.closeDialog}
          />
        )}
        <ConfirmDiscardsDialog ids={ids} open={discardDialog.open} onClose={discardDialog.closeDialog} />
        <ConfirmReturnDialog ids={ids} open={returnDialog.open} onClose={returnDialog.closeDialog} />
        <DeclareMailItemDialog
          open={declareDialog.open}
          onClose={declareDialog.closeDialog}
          items={getItemsForDeclaration}
        />
        <InfoDialog
          actions={mailRequestDialogActions}
          message={mailActionRequestDialog.value || ''}
          open={mailActionRequestDialog.open}
          onClose={mailActionRequestDialog.closeDialog}
        />
        {dialogs}
      </FlexBox>
      {moveToFolderInfo.info && (
        <Toast
          onClose={resetMoveToFolderInfo}
          severity={moveToFolderInfo.info.isSuccess ? 'success' : 'error'}
          title={moveToFolderInfo.info.message}
        />
      )}
    </>
  );
}

export default MailActions;
