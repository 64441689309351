import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  avatarClasses,
  listItemTextClasses,
  styled,
} from '@mui/material';

import { ReactNode } from 'react';

const Root = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  width: 306,
}));

type StyledListItemProps = {
  color: 'error' | 'success';
};

const StyledListItem = styled(ListItem, { shouldForwardProp: (prop) => prop !== 'color' })<StyledListItemProps>(
  ({ theme, color }) => {
    const backgroundColor = theme.palette.background[color];
    const svgColor = theme.palette[color]?.dark;
    return {
      alignItems: 'flex-start',
      padding: 0,
      marginBottom: theme.spacing(1.75),
      '&:last-of-type': {
        marginBottom: 0,
      },
      '.MuiListItemAvatar-root': {
        minWidth: 0,
      },
      [`& .${listItemTextClasses.root}`]: {
        marginTop: 0,
        marginBottom: 0,
        '.MuiTypography-root': {
          fontSize: '0.875rem',
        },
      },
      [`& .${avatarClasses.root}`]: {
        minWidth: 0,
        marginRight: theme.spacing(2.25),
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor,
        '& > svg': {
          color: svgColor,
          width: theme.spacing(2),
          height: theme.spacing(2),
        },
      },
    };
  },
);

export type InstructionDetailsProps = {
  title?: string;
  description?: string;
  items: Array<
    {
      icon: ReactNode;
      text: string;
    } & StyledListItemProps
  >;
};

export function IdInstructions({ description, items, title }: InstructionDetailsProps) {
  return (
    <Root>
      {title && (
        <Typography fontSize="0.875rem" mb={1.75}>
          {title}
        </Typography>
      )}
      {description && (
        <Typography fontSize="0.875rem" mb={1.75}>
          {description}
        </Typography>
      )}
      <List>
        {items.map(({ icon, text, color }, index) => (
          <StyledListItem key={index} color={color}>
            <ListItemAvatar>
              <Avatar>{icon}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={text} />
          </StyledListItem>
        ))}
      </List>
    </Root>
  );
}
