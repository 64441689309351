import { Switch, SwitchProps, styled } from '@mui/material';
import { forwardProps } from './helpers/forwardProps';

const SIZES = {
  extraSmall: {
    width: 24,
    height: 14,
    thumbSize: 8,
  },
  small: {
    width: 42,
    height: 26,
    thumbSize: 18,
  },
  large: {
    width: 64,
    height: 34,
    thumbSize: 26,
  },
};

export type IOSSwitchProps = SwitchProps & {
  width?: number;
  height?: number;
  thumbSize?: number;
  color?: string;
  variant?: 'small' | 'large' | 'extraSmall';
};

export const IOSSwitch = styled(
  (props: SwitchProps) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />,
  {
    shouldForwardProp: forwardProps<IOSSwitchProps>(['width', 'height', 'thumbSize', 'color', 'size']),
  },
)<IOSSwitchProps>(
  ({
    variant = 'large',
    theme,
    width: propWidth,
    height: propHeight,
    thumbSize: propThumbSize,
    color = theme.palette.secondary.main,
  }) => {
    const width = propWidth || SIZES[variant].width;
    const height = propHeight || SIZES[variant].height;
    const thumbSize = propThumbSize || SIZES[variant].thumbSize;
    return {
      width,
      height,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: (height - thumbSize) / 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: `translateX(${width - height}px)`,
          color: theme.palette.common.white,
          '& + .MuiSwitch-track': {
            backgroundColor: color,
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color,
          border: `6px solid ${theme.palette.common.white}`,
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: thumbSize,
        height: thumbSize,
      },
      '& .MuiSwitch-track': {
        borderRadius: height / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#DBE2EB' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500,
        }),
      },
    };
  },
);
