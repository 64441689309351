import AddIcon from '../../assets/plus.svg?react';
import BasicIcon from './assets/basic.svg?react';
import BusinessIcon from './assets/business.svg?react';
import DoneIcon from '../../assets/complete_done.svg?react';
import { PlanType, PlanTerm } from '@usgm/inbox-api-types';
import PlusIcon from './assets/plus.svg?react';
import { ReactNode } from 'react';
import { Theme } from '@mui/material';
import albertPic from './assets/albert.png';
import romanPic from './assets/roman.png';

export type PlanConfig = {
  image: ReactNode;
  description: string;
  badge?: string;
  getColor: (theme: Theme) => string;
  price: {
    [PlanTerm.Monthly]: number;
    [PlanTerm.Annually]: number;
  };
  icon: ReactNode;
  type: PlanType;
  features?: string[];
};

export const PLAN_TYPES_MAPPER: Record<PlanType, PlanConfig> = {
  [PlanType.Business]: {
    icon: <AddIcon />,
    image: <BusinessIcon />,
    description: 'Scale your business, increase productivity, add up to 10 companies',
    getColor: (theme: Theme) => theme.palette.success.dark,
    price: {
      monthly: 34.95,
      annually: 29.95,
    },
    type: PlanType.Business,
    features: [
      'Unlimited exterior mail scan',
      '180 days Free physical mail storage',
      'Up to 80% off on shipping rates',
      'Express customer service',
      'Save big with scan bundles',
    ],
  },
  [PlanType.Enterprise]: {
    icon: <AddIcon />,
    image: <BusinessIcon />,
    description: 'Scale your business, increase productivity, add up to 10 companies',
    getColor: (theme: Theme) => theme.palette.success.dark,
    price: {
      monthly: 0,
      annually: 0,
    },
    type: PlanType.Business,
    features: [
      'Unlimited exterior mail scan',
      '180 days Free physical mail storage',
      'Up to 80% off on shipping rates',
      'Express customer service',
      'Save big with scan bundles',
    ],
  },
  [PlanType.Plus]: {
    icon: <DoneIcon />,
    image: <PlusIcon />,
    description: 'More power for small teams who wants better collaboration, up to 10 users',
    badge: 'Most Popular',
    getColor: (theme: Theme) => theme.palette.primary.main,
    price: {
      monthly: 19.95,
      annually: 14.95,
    },
    type: PlanType.Plus,
    features: [
      'Unlimited exterior mail scan',
      '90 days Free physical mail storage',
      'Up to 60% off on shipping rates',
      'Express customer service',
    ],
  },
  [PlanType.Basic]: {
    icon: <DoneIcon />,
    image: <BasicIcon />,
    description: 'More power for individual user who wants best in class features',
    getColor: (theme: Theme) => theme.palette.text.primary,
    price: {
      monthly: 14.95,
      annually: 9.95,
    },
    type: PlanType.Basic,
    features: [
      'Unlimited exterior mail scan',
      '45 days Free physical mail storage',
      'Up to 60% off on shipping rates',
      'Standard customer service',
    ],
  },
  [PlanType.Standard]: {
    icon: <DoneIcon />,
    image: <PlusIcon />,
    description: 'More power for small teams who wants better collaboration, up to 10 users',
    getColor: (theme: Theme) => theme.palette.secondary.main,
    price: {
      monthly: 19.95,
      annually: 14.95,
    },
    type: PlanType.Standard,
    features: [
      'Unlimited exterior mail scan',
      '90 days Free physical mail storage',
      'Up to 60% off on shipping rates',
      'Express customer service',
    ],
  },
  [PlanType.Starter]: {
    icon: <DoneIcon />,
    image: <BasicIcon />,
    description: 'More power for individual user who wants best in class features',
    getColor: (theme: Theme) => theme.palette.text.primary,
    price: {
      monthly: 14.95,
      annually: 9.95,
    },
    type: PlanType.Starter,
    features: [
      'Unlimited exterior mail scan',
      '45 days Free physical mail storage',
      'Up to 60% off on shipping rates',
      'Standard customer service',
    ],
  },
};

export const DEFAULT_PLANS = [PLAN_TYPES_MAPPER.BUSINESS, PLAN_TYPES_MAPPER.PLUS, PLAN_TYPES_MAPPER.BASIC];

export const GOOGLE_REVIEWS = {
  rating: 4.6,
  maxRating: 5,
  totalReviews: 2000,
  reviews: [
    {
      rating: 5,
      text: `Fantastic service! I'm a permanent US expat and I really need US Global mail which is safe, fast and reliable service. I recommend it to everyone in my situation. Customer support is always ready to assist you if you have any questions and they write you back the same day.`,
      name: 'Albert Chang',
      avatar: albertPic,
    },
    {
      rating: 5,
      text: `Gives you back your time! We are a 40 person remote team. We were using a PO box and an assistant collected mail weekly and scanned and emailed it to the team. It was a nightmare, often resulting in late fees and missed payments. No more! US Global Mail does that now and brilliantly. We get our mail on time and frankly, I don’t think about mail anymore. It just works.`,
      name: 'Raman Kashyap',
      avatar: romanPic,
    },
  ],
};

export type TReview = (typeof GOOGLE_REVIEWS.reviews)[number];

export const SECTION_IDS = {
  SELECT_A_PLAN: 'select-a-plan',
  PLAN_FEATURES_LIST: 'plan-features-list',
  PLAN_ADD_ONS: 'add-ons-list',
};

export const REVIEWS_BY_PLAN = {
  [PlanType.Basic]: GOOGLE_REVIEWS.reviews[0],
  [PlanType.Starter]: GOOGLE_REVIEWS.reviews[0],
  [PlanType.Standard]: GOOGLE_REVIEWS.reviews[0],
  [PlanType.Plus]: GOOGLE_REVIEWS.reviews[0],
  [PlanType.Business]: GOOGLE_REVIEWS.reviews[1],
  [PlanType.Enterprise]: GOOGLE_REVIEWS.reviews[1],
};
