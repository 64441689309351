import { inboxHelpers } from '@usgm/utils';
import { Route, useLocation } from 'react-router-dom';
import NotFoundPage from '../../../app/pages/NotFoundPage';
import { SocialAuthHandler } from '../../auth/components/SocialAuthHandler';
import { InitInbox } from '../../auth/components/protectedRoutes/InitInbox';
import { useAuth } from '../../auth/hooks/useAuth';
import InboxLayout from '../components/layout';
import PageLoader from '../components/layout/PageLoader.tsx';
import { DEFAULT_NAVIGATION, NAVIGATION_ENTITY_MAP } from '../constants';
import { useOnboardingRoutes } from '../features/onboarding/hooks/useOnboardingRoutes';
import { useSettingsRoutes } from '../features/settings/hooks/useSettingsRoutes';
import { useGetNavigationQuery } from '../inboxAccountsApi';

export function useInboxRoutes() {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const pathParts = pathname.split(`${inboxHelpers.MAIN_PATHS.APP}/`);
  const onboardingRoutes = useOnboardingRoutes();
  const settingsRoutes = useSettingsRoutes();

  const { data, isLoading } = useGetNavigationQuery(null, {
    skip: !pathname.includes(inboxHelpers.MAIN_PATHS.APP) || !user,
  });

  const routes =
    data?.map((item) => {
      const { key, path } = item;
      const element = NAVIGATION_ENTITY_MAP[key].element;
      const relativePath = path.replace('/', '');

      const subPaths =
        item.subItems?.forEach((subItem) => {
          const element = NAVIGATION_ENTITY_MAP[subItem.key].element;

          if (!subItem.resourceId) {
            return {
              element,
              path: subItem.path,
            };
          }
        }) || [];

      const defaultSubPaths = NAVIGATION_ENTITY_MAP[key].subPaths || [];

      return {
        element,
        path: relativePath,
        subPaths: [...subPaths, ...defaultSubPaths],
      };
    }) || DEFAULT_NAVIGATION; // fallback to default navigation if data is not available

  return (
    <Route path={inboxHelpers.MAIN_PATHS.APP} element={<SocialAuthHandler />}>
      <Route element={<InitInbox />}>
        <Route element={<InboxLayout />}>
          {pathParts?.[1] && isLoading && <Route path={pathParts[1]} element={<PageLoader />} />}
          {onboardingRoutes}
          {settingsRoutes}
          {routes?.map(({ element, path, subPaths }) => {
            if (subPaths) {
              return (
                <Route key={path} path={path} element={element}>
                  {subPaths.map(({ element, path: subPath }) => {
                    return <Route key={subPath} path={subPath} element={element} />;
                  })}
                </Route>
              );
            } else {
              return <Route key={path} path={path} element={element} />;
            }
          })}
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Route>
    </Route>
  );
}
