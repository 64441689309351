import { DateTime, DurationObjectUnits } from 'luxon';

export function isOlderThan({
  amount,
  dateStr,
  unit,
}: {
  dateStr: string;
  unit: keyof Required<DurationObjectUnits>;
  amount: number;
}) {
  const inputDate = DateTime.fromISO(dateStr);
  const currentDate = DateTime.now();
  const comparisonDate = currentDate.minus({ [unit]: amount });

  return inputDate < comparisonDate;
}
