import { useAuth } from './useAuth';

export const useCurrentUser = () => {
  const { accountStatus, isAccountUnderPaymentHold, isRegularTeamMember, isTeamMember, user } = useAuth();

  if (!user?.userUUID) {
    throw new Error('Can not use useCurrentUser hook for guest users');
  }

  return {
    accountStatus,
    isAccountUnderPaymentHold,
    isRegularTeamMember,
    isTeamMember,
    user,
  };
};
