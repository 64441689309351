import { Box, CircularProgress, Link, Typography, alpha, styled, useMediaQuery } from '@mui/material';
import { UserInfo } from '@usgm/inbox-api-types';
import { CloseButton, CopyAddressButton, FlexBox, StyledDialog } from '@usgm/shared-ui';
import { useUserWarehouseDetails } from '../../../../../../hooks/useUserWarehouseDetails';
import InstructionsIcon from '../../../../assets/instructions.svg?react';
import { StyledDialogContent } from '../../styled/StyledDialogContent';
import { DialogProps } from './DialogProps';
import { inboxHelpers } from '@usgm/utils';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    padding: theme.spacing(2.5),
  },
}));

const DescriptionBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(1.5),
}));

const BoxHeader = styled(StyledBox)(({ theme }) => ({
  borderBottom: `1px solid ${alpha(theme.palette.divider, 0.5)}`,
}));

const AddressInfo = styled(StyledBox)(({ theme }) => ({
  minHeight: theme.spacing(20.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.base,
  border: `1px dashed #CFCFCF`, // new border color which isn't specified in the style guide
}));

const AddressInfoLoader = styled(AddressInfo)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export type InstructionsDialogProps = DialogProps & {
  userUUID: UserInfo['userUUID'];
};

const InstructionsIconContainer = styled(FlexBox)(({ theme }) => ({
  marginTop: theme.spacing(3.75),
  marginBottom: theme.spacing(5.75),
  justifyContent: 'center',
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

const HeadingContainer = styled(Box)(({ theme }) => ({
  maxWidth: 366,
  margin: `${theme.spacing(3)} auto`,
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    maxWidth: '80%',
  },
}));

export function InstructionsDialog({ open, onClose, userUUID, fullScreen }: InstructionsDialogProps) {
  const isSmallScreen = useMediaQuery(inboxHelpers.DOWN_MOBILE_LANDSCAPE);
  const { data, isLoading } = useUserWarehouseDetails(userUUID);

  return (
    <StyledDialog fullScreen={fullScreen} open={open} onClose={onClose}>
      <CloseButton onClick={onClose} />
      <StyledDialogContent>
        <InstructionsIconContainer>
          <InstructionsIcon />
        </InstructionsIconContainer>
        <HeadingContainer>
          <Typography textAlign="center" variant={isSmallScreen ? 'h5' : 'h4'}>
            Here's how to change your address with USPS
          </Typography>
        </HeadingContainer>
        <DescriptionBox>
          <BoxHeader>
            <Typography align="center">Access this link and follow the steps:</Typography>
            <Typography align="center">
              <Link target="_blank" href="https://moversguide.usps.com/mgo/">
                https://moversguide.usps.com/mgo/
              </Link>
            </Typography>
          </BoxHeader>
          <StyledBox>
            <Typography>Note:</Typography>
            <Typography mt={1.5}>
              <strong>For mail forwarding</strong>, select <strong>Temporary</strong> (15 days to 6 months, extendable
              to 12 months). <strong>Cancel anytime</strong>, and mail will return to your old address under temporary
              forwarding.
            </Typography>
            <Typography mt={1.5}>
              Set a reminder to either extend or transition to Permanent forwarding for longer relocations or a lasting
              mail solution.
            </Typography>
          </StyledBox>
        </DescriptionBox>
        {isLoading ? (
          <AddressInfoLoader
            alignSelf="stretch"
            minHeight={20.5}
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <CircularProgress />
          </AddressInfoLoader>
        ) : (
          data && (
            <AddressInfo mt={2}>
              <Typography>Here’s your US Global Mail address:</Typography>
              <FlexBox mt={0.5}>
                <Typography maxWidth={394} fontWeight="bold">
                  {data.stringifiedAddress}
                </Typography>
                <CopyAddressButton address={data.stringifiedAddress} />
              </FlexBox>
              <Typography maxWidth={394} fontSize="0.875rem" color="secondary" mt={1.5}>
                For the USPS mail forwarding please use <strong>PMB.</strong> However, when sharing your address with
                others, you can replace <strong>PMB</strong> with <strong>Suite, Unit</strong> or <strong>Apt</strong>{' '}
                if desired.
              </Typography>
            </AddressInfo>
          )
        )}
      </StyledDialogContent>
    </StyledDialog>
  );
}
