import { useTheme } from '@mui/material';
import { FlexBox } from '@usgm/shared-ui';
import React, { useEffect, useRef } from 'react';
import { useRecaptchaContext } from './useRecaptchaContext';

export type RecaptchaV2Props = {
  requestId: string;
};
const ReCaptchaV2 = ({ requestId }: RecaptchaV2Props) => {
  const theme = useTheme();
  const widgetRef = useRef<string | null>(null);
  const { siteKeyV2, renderedRecaptchaV2Ids, getRecaptchaV2State } = useRecaptchaContext();

  useEffect(() => {
    const state = getRecaptchaV2State(requestId);
    if (state?.isRendered) {
      try {
        if (!widgetRef.current) {
          widgetRef.current = window.grecaptcha.render(requestId, {
            callback: state.onSuccess,
            theme: theme.palette.mode,
          });
        } else {
          window.grecaptcha.reset(widgetRef.current);
        }
      } catch (error: unknown) {
        console.warn(`Recaptcha rendering error: ${error}`);
      }
    }
  }, [renderedRecaptchaV2Ids, getRecaptchaV2State, requestId, theme.palette.mode]);

  return (
    <FlexBox justifyContent="center" pt={2}>
      <div className="g-recaptcha" id={requestId} data-sitekey={siteKeyV2}></div>
    </FlexBox>
  );
};

export default ReCaptchaV2;
