import { List } from '@mui/material';
import { NavigationEntityKey, NavigationItemType, type NavigationItem as TNavigationItem } from '@usgm/inbox-api-types';
import { useEffect } from 'react';
import { useAuth } from '../../../../auth/hooks/useAuth';
import { useOnboardingData } from '../../../features/onboarding/hooks/useOnboardingData';
import useUsetifulScript from '../../../hooks/useUsetifulScript';
import NavigationItem, { NavigationItemProps } from './NavigationItem';
import { NAVIGATION_ENTITY_MAP } from '../../../constants';
import TrashIcon from '../../../assets/icons/trash.svg?react';
import PenIcon from '../../../assets/icons/pen.svg?react';
import PlusIcon from '../../../../../assets/plus.svg?react';
import { inboxHelpers } from '@usgm/utils';
import { useFolderManagement } from '../../../features/mails/mailFolders/useFolderManagement';

export type NavigationProps = {
  data: TNavigationItem[];
  collapsed?: boolean;
  onItemClick?: () => void;
};

const constructNavigationItem = (
  item: NavigationItemProps & { key: NavigationEntityKey },
  deleteHandler: (id?: number) => void,
  editHandler: (id?: number) => void,
  parentPath?: string,
  parentKey?: NavigationEntityKey,
  showActions?: boolean,
): NavigationItemProps => {
  const { key, ...itemData } = item;
  const entity = NAVIGATION_ENTITY_MAP[key];

  const path = item.resourceId
    ? `${parentPath}/folder/${item.resourceId}/${parentKey === NavigationEntityKey.Scan ? 'pending' : 'mails'}`
    : `${parentPath}${item.path}`;
  const rootPath = item.resourceId ? `${parentPath}/folder/${item.resourceId}/` : `${parentPath}${item.path}`;
  if (showActions && item.editable && item.resourceId) {
    itemData.actions = [
      { icon: <PenIcon />, label: 'Edit', onClick: () => editHandler(item.resourceId) },
      { icon: <TrashIcon />, label: 'Delete', onClick: () => deleteHandler(item.resourceId) },
    ];
  }
  if (item.isNew) {
    itemData.badgeColor = 'success';
    itemData.badgeContent = 'New';
  }

  return {
    ...itemData,
    path,
    defaultPath: entity.defaultPath,
    rootPath: rootPath,
    icon: entity.icon,
    supportsRegularTeamMember: true,
    domId: entity.domId,
  };
};

export function Navigation({ data, collapsed, onItemClick }: NavigationProps) {
  const { createFolder, deleteFolder, dialogs, editFolder } = useFolderManagement();
  const { isPrimaryAccountStepsCompleted } = useOnboardingData();
  const { user, isTeamMember, isRegularTeamMember } = useAuth();
  useUsetifulScript({ skip: !isPrimaryAccountStepsCompleted });

  useEffect(() => {
    window.usetifulTags = { userId: user?.userUUID };
  }, [user?.userUUID]);

  const items = data.map((item) => {
    const itemData = constructNavigationItem(
      item,
      deleteFolder,
      editFolder,
      inboxHelpers.MAIN_PATHS.APP,
      undefined,
      !isRegularTeamMember,
    );
    const defaultSubItems = NAVIGATION_ENTITY_MAP[item.key].subItems || [];

    const subItems =
      item.subItems?.map((subItem) => {
        return constructNavigationItem(
          subItem,
          deleteFolder,
          editFolder,
          itemData.path,
          item.key,
          !isRegularTeamMember,
        );
      }) || [];

    if (item.key === NavigationEntityKey.Mailbox && !isRegularTeamMember) {
      subItems?.push({
        label: 'Add Folder',
        icon: <PlusIcon />,
        path: '',
        onClick: () => {
          createFolder();
        },
        type: NavigationItemType.Button,
      });
    }

    if (
      !isTeamMember &&
      !isPrimaryAccountStepsCompleted &&
      [
        NavigationEntityKey.Mailbox,
        NavigationEntityKey.Scan,
        NavigationEntityKey.Shipment,
        NavigationEntityKey.Discard,
      ].includes(item.key)
    ) {
      itemData.disabled = true;
      itemData.tooltip = 'Please upload all necessary documents and complete notarization to unlock your mailbox';
    }

    let filteredSubItems = [...subItems, ...defaultSubItems];
    if (isRegularTeamMember) {
      filteredSubItems = filteredSubItems.filter((it) => it.supportsRegularTeamMember);
    }

    return {
      ...itemData,
      subItems: filteredSubItems,
    };
  });

  const handleItemClick = (hasSubItems: boolean) => {
    if (!hasSubItems) {
      onItemClick?.();
    }
  };

  return (
    <>
      <List sx={{ padding: 0 }}>
        {items.map((item) => (
          <NavigationItem
            collapsed={collapsed}
            key={item.path}
            {...item}
            onClick={(hasSubItems) => handleItemClick(hasSubItems)}
          />
        ))}
      </List>
      {dialogs}
    </>
  );
}
