import { EntityState, createEntityAdapter, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { inboxAccountsApi } from '../../../inboxAccountsApi';
import { RootState } from '../../../../../store';
import { NavigationEntityKey } from '@usgm/inbox-api-types';

export type TFolder = {
  id: number | string;
  label: string;
  uuid: string;
};

type TState = EntityState<TFolder, number | string> & {
  isLoading: boolean;
};

const FEATURE_NAME = 'MAIL_FOLDER';

const foldersAdapter = createEntityAdapter<TFolder>();

const initialState: TState = foldersAdapter.getInitialState({
  isLoading: false,
});

export const mailFolderSlice = createSlice({
  name: FEATURE_NAME,
  initialState,
  reducers: {
    updateFolder: foldersAdapter.updateOne,
    deleteFolder: foldersAdapter.removeOne,
    addFolder: foldersAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(inboxAccountsApi.endpoints.getNavigation.matchRejected), (state) => {
      state.isLoading = false;
    });
    builder.addMatcher(isAnyOf(inboxAccountsApi.endpoints.getNavigation.matchFulfilled), (state, { payload }) => {
      state.isLoading = false;
      const mailItems = payload.find((item) => item.key === NavigationEntityKey.Mailbox);

      if (mailItems?.subItems) {
        const folders: TFolder[] = [];
        mailItems.subItems.forEach((item) => {
          if (item.resourceId && item.resourceUuid) {
            folders.push({ id: item.resourceId, label: item.label || '', uuid: item.resourceUuid });
          }
        });
        foldersAdapter.setAll(state, folders);
      }
    });
    builder.addMatcher(isAnyOf(inboxAccountsApi.endpoints.getNavigation.matchPending), (state) => {
      state.isLoading = true;
    });
  },
});

// selectors

const selectState = (state: RootState) => state[FEATURE_NAME];

export const folderSelectors = foldersAdapter.getSelectors(selectState);

export const selectFolderUuids = createSelector(
  (state: RootState) => state,
  (_, folderIds: TFolder['id'][]) => folderIds,
  (state: RootState, folderIds: TFolder['id'][]) => {
    return folderIds.map((id) => folderSelectors.selectById(state, id)?.uuid);
  },
);
