import { Box, Button, Theme, useMediaQuery } from '@mui/material';
import { FlexBox, TextButton, useDialog } from '@usgm/shared-ui';

import { AccountVerificationStepStatus } from '@usgm/inbox-api-types';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { MarkAsDoneDialog } from './dialogs/MarkAsDoneDialog';
import { SkipDialog } from './dialogs/SkipDialog';
import { StepItemProps } from '../StepItemProps';
import { StepText } from '../../VerifyAccountSteps';
import { usePatchMailRedirectionStepMutation } from '../../../api';

export type RedirectMailStepItemActionsProps = StepItemProps;

export function RedirectMailStepItemActions({ skipped, completed }: RedirectMailStepItemActionsProps) {
  const [patchMailRedirectionMutation] = usePatchMailRedirectionStepMutation();
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const { closeDialog: closeSkipDialog, open: skipDialogOpen, openDialog: openSkipDialog } = useDialog();
  const { closeDialog: closeMarkAsDoneDialog, open: markAsDoneOpen, openDialog: openMarkAsDoneDialog } = useDialog();
  const handleUndoClick = () => {
    patchMailRedirectionMutation({ status: AccountVerificationStepStatus.InProgress });
  };

  const handleMarkAsDone = () => {
    patchMailRedirectionMutation({ status: AccountVerificationStepStatus.Completed });
  };

  const handleSkip = () => {
    patchMailRedirectionMutation({ status: AccountVerificationStepStatus.Skipped });
  };

  if (skipped) {
    return (
      <>
        <StepText completed component="span">
          Skipped.
        </StepText>
        <TextButton onClick={handleUndoClick}>Undo?</TextButton>
      </>
    );
  }
  return (
    <FlexBox flexDirection="column" alignItems={isMobile ? 'flex-start' : 'flex-end'} gap={1}>
      <Button onClick={openMarkAsDoneDialog} variant="contained" color="primary" endIcon={<ArrowForwardIosIcon />}>
        Mark as Done
      </Button>

      <Box>
        <StepText mr={1} component="span">
          Don't have a US address?
        </StepText>
        <TextButton onClick={openSkipDialog}>Skip this action</TextButton>
      </Box>
      <SkipDialog onConfirm={handleSkip} fullScreen={isMobile} open={skipDialogOpen} onClose={closeSkipDialog} />
      <MarkAsDoneDialog
        onConfirm={handleMarkAsDone}
        fullScreen={isMobile}
        open={markAsDoneOpen}
        onClose={closeMarkAsDoneDialog}
      />
    </FlexBox>
  );
}
