import {
  AlertProps,
  Box,
  BoxProps,
  CircularProgress,
  Divider,
  Theme,
  Typography,
  alpha,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { AccountRelation, CaptchaParams, RecordStatus, UserType } from '@usgm/inbox-api-types';
import { Button, ConfirmationDialog, FlexBox, InfoTitle, Toast, useDialog } from '@usgm/shared-ui';
import { MouseEvent, memo, useMemo, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { inboxHelpers, snakeCaseKeys } from '@usgm/utils';
import { FormProvider, useForm } from 'react-hook-form';

import { omit } from 'lodash-es';

import { useAccountApiClient } from '../../../../../../api/axios/AccountApiContext';
import { useAppDispatch } from '../../../../../../store';
import { ONBOARDING_TAG_TYPES, onboardingApi, useDeleteDocumentByIdMutation } from '../../api';
import { DOCUMENT_TYPE_OPTIONS_MAPPER } from '../../config/documentTypeOptionsMap';
import { denormalizeFullName, getCardStatus, hasMissingDocuments } from '../../helpers';
import { AccountNamesMap } from '../../hooks/useAccountNamesMap';
import { CollapsibleCard } from '../CollapsibleCard';
import DocumentForm from './DocumentForm';
import DocumentsCardHeader from './DocumentsCardHeader';
import { DocumentsSchemaType, documentsSchema } from './schema';

const StyledFormContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hideBorder',
})<{
  hideBorder?: boolean;
}>(({ theme, hideBorder }) => ({
  padding: theme.spacing(4),
  borderTop: hideBorder ? 'none' : `1px solid ${alpha(theme.palette.text.primary, 0.12)}`,
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    padding: theme.spacing(3, hideBorder ? 0 : 2.5),
  },
}));

function DocumentsUploadForm({
  hideBorder,
  ...props
}: { hideBorder?: boolean; noValidate?: boolean; autoComplete: string } & BoxProps) {
  return <StyledFormContainer hideBorder={hideBorder} {...props} />;
}

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: alpha(theme.palette.text.primary, 0.12),
}));

export interface DocumentsCardProps extends Partial<CaptchaParams> {
  userId: string;
  accountNamesMap: AccountNamesMap;
  scrollIndex: string;
  onContinue: () => void;
  isBusiness?: boolean;
  collapsible?: boolean;
  accountsMissingIds?: string[];
  onDocumentSubmit?: (userId: string) => void;
  onDocumentDelete?: (userId: string) => void;
}

export function DocumentsCard({
  userId,
  accountNamesMap,
  scrollIndex,
  onContinue,
  captchaVersion,
  token,
  isBusiness = false,
  collapsible = true,
  accountsMissingIds,
  onDocumentSubmit,
  onDocumentDelete,
}: DocumentsCardProps) {
  const dispatch = useAppDispatch();
  const {
    closeDialog: closeDeleteConfirmationDialog,
    open: openDeleteConfirmation,
    openDialog: openDeleteConfirmationDialog,
    value,
  } = useDialog<{ documentId: string; type: UserType }>({ open: false, value: null });

  const [uploadAlert, setUploadAlert] = useState<{ message: string; severity: AlertProps['severity'] } | null>(null);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [cardStatus, setCardStatus] = useState<ReturnType<typeof getCardStatus>>(
    getCardStatus(userId, accountNamesMap),
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const cardData = accountNamesMap.get(userId);
  const accountApiClient = useAccountApiClient();

  const missingDocuments = useMemo(() => ['Missing', RecordStatus.Rejected, null].includes(cardStatus), [cardStatus]);

  const defaultValues = useMemo(() => {
    if (!cardData) {
      return undefined;
    }
    const { documents } = cardData;
    const defaultValues = {} as DocumentsSchemaType;
    const primaryDocumentData = documents.get(UserType.Primary);
    const secondaryDocumentData = documents.get(UserType.Secondary);

    if (primaryDocumentData?.id && primaryDocumentData?.documentFields) {
      defaultValues.primaryDocumentFields = {
        expiration_date: primaryDocumentData.documentFields.expirationDate,
        ...snakeCaseKeys(omit(primaryDocumentData?.documentFields, 'expirationDate')),
      };
    }

    if (secondaryDocumentData?.id && secondaryDocumentData?.documentAddress) {
      defaultValues.secondaryDocumentFields = {
        full_name: secondaryDocumentData.documentAddress.name,
        street_address: secondaryDocumentData.documentAddress.addressLine,
        ...snakeCaseKeys(omit(secondaryDocumentData.documentAddress, 'name', 'addressLine')),
      };
    }

    [primaryDocumentData, secondaryDocumentData].forEach((documentData) => {
      const typePath = documentData?.userType === UserType.Primary ? 'primaryDocumentType' : 'secondaryDocumentType';
      const urlPath = documentData?.userType === UserType.Primary ? 'primaryFile' : 'secondaryFile';
      if (documentData?.documentType) {
        defaultValues[typePath] = {
          label: DOCUMENT_TYPE_OPTIONS_MAPPER[documentData.documentType]?.label,
          value: documentData.documentType,
        };
      }
      if (documentData?.documentUrl) {
        defaultValues[urlPath] = {
          url: documentData.documentUrl,
          id: documentData.id,
        };
      }
    });

    return defaultValues;
  }, [cardData]);

  const formMethods = useForm<DocumentsSchemaType>({
    resolver: zodResolver(documentsSchema),
    mode: 'onChange',
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    reset,
  } = formMethods;

  const onSubmit = (data: DocumentsSchemaType) => {
    const formData = new FormData();

    if (data.primaryFile?.file) {
      formData.append('primary_file', data.primaryFile.file);
    }
    if (data.secondaryFile?.file) {
      formData.append('secondary_file', data.secondaryFile.file);
    }

    formData.append('secondary_document_type', data.secondaryDocumentType?.value || '');
    formData.append('primary_document_type', data.primaryDocumentType?.value || '');

    if (data.primaryDocumentFields) {
      formData.append('primary_document_fields', JSON.stringify(data.primaryDocumentFields));
    }
    if (data.secondaryDocumentFields) {
      const { street_address: address_line, ...rest } = data.secondaryDocumentFields;
      formData.append(
        'secondary_document_address',
        JSON.stringify({
          ...rest,
          address_line: data.secondaryDocumentFields.street_address,
        }),
      );
    }
    setIsSubmitting(true);

    accountApiClient
      .put(`/user/${userId}/documents/upload/v2`, formData, {
        interceptMessages: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        onDocumentSubmit?.(userId);
        reset(data, { keepValues: true, keepDirtyValues: false });
        setCardStatus(RecordStatus.InReview);
        dispatch(onboardingApi.util.invalidateTags([ONBOARDING_TAG_TYPES.ONBOARDING_STATE]));
        onContinue();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const [deleteDocumentById] = useDeleteDocumentByIdMutation();
  const handleRemove = ({ type }: { documentId?: string; type: UserType }) => {
    if (type === UserType.Primary) {
      formMethods.setValue('primaryFile', undefined, { shouldValidate: true });
      formMethods.setValue('primaryDocumentType', undefined, { shouldValidate: true });
      formMethods.setValue('primaryDocumentFields', undefined, { shouldValidate: true });
    } else {
      formMethods.setValue('secondaryFile', undefined, { shouldValidate: true });
      formMethods.setValue('secondaryDocumentType', undefined, { shouldValidate: true });
      formMethods.setValue('secondaryDocumentFields', undefined, { shouldValidate: true });
    }
    setCardStatus('Missing');
    dispatch(onboardingApi.util.invalidateTags([ONBOARDING_TAG_TYPES.ONBOARDING_STATE]));
    onDocumentDelete?.(userId);
  };

  const handleDeleteConfirmation = () => {
    if (value) {
      deleteDocumentById({ documentId: value.documentId, captchaVersion, token });
      handleRemove(value);
      closeDeleteConfirmationDialog();
    }
  };
  const handleRemoveClick =
    ({ type, documentId }: { documentId?: string; type: UserType }) =>
    () => {
      if (documentId) {
        openDeleteConfirmationDialog({ documentId, type });
      } else {
        handleRemove({ type, documentId });
      }
    };
  const handlePrimaryNameChange = (newValue: string) => {
    const denormalizedName = denormalizeFullName(newValue);
    formMethods.setValue('primaryDocumentFields.first_name', denormalizedName.firstName);
    formMethods.setValue('primaryDocumentFields.middle_name', denormalizedName.middleName);
    formMethods.setValue('primaryDocumentFields.last_name', denormalizedName.lastName);
    formMethods.setValue('primaryDocumentFields.name', newValue, { shouldDirty: true });
  };

  const [expanded, setExpanded] = useState(cardStatus !== RecordStatus.Approved);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const theme = useTheme();

  if (!cardData || !cardStatus) {
    return null;
  }

  const canSubmit = isValid;

  const { documents, name, relation } = cardData;

  const handleContinueButtonClick = (ev: MouseEvent<HTMLButtonElement>) => {
    if (!isDirty) {
      ev.stopPropagation();
      ev.preventDefault();
      onContinue();
    }
  };

  const submitLabel = missingDocuments
    ? !accountsMissingIds?.length || accountsMissingIds.length <= 1
      ? 'Save & Continue'
      : 'Save'
    : isDirty
    ? 'Save & Continue'
    : 'Continue';

  const formContent = (
    <FormProvider {...formMethods}>
      {collapsible && <StyledDivider />}
      <DocumentsUploadForm
        hideBorder={!collapsible}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        {isBusiness && relation === AccountRelation.Primary && (
          <Box mb={5}>
            <Typography mb={2}>
              USPS requires two types of identification for the primary account holder (Company Officer) to set up mail
              delivery. No documents are required for the business.
            </Typography>
            <InfoTitle
              maxWidth={306}
              title=" Why are my personal IDs required for a business account?"
              color={theme.customColors.dark[300]}
              description={
                <>
                  <Typography mb={1}>
                    This requirement aligns with USPS regulations and is essential for the verification process.{' '}
                  </Typography>
                  <Typography>
                    By verifying the primary account holder's identity, we ensure the security and compliance of your
                    business account.
                  </Typography>
                </>
              }
            />
          </Box>
        )}
        <FlexBox alignItems="flex-start" flexDirection={isMobile ? 'column' : 'row'} gap={3}>
          {Array.from(documents.entries()).map(([userType, document]) => {
            return (
              <DocumentForm
                key={userType}
                onRemove={handleRemoveClick({ documentId: document?.uuid, type: userType })}
                document={document}
                userType={userType}
                onPrimaryNameChange={handlePrimaryNameChange}
              />
            );
          })}
        </FlexBox>
        <FlexBox justifyContent="center" mt={10}>
          <Button
            type="submit"
            onClick={handleContinueButtonClick}
            variant="contained"
            disabled={!canSubmit || isSubmitting}
          >
            {isSubmitting ? <CircularProgress color="inherit" size={16} /> : submitLabel}
          </Button>
        </FlexBox>
      </DocumentsUploadForm>
    </FormProvider>
  );

  return (
    <>
      {!collapsible ? (
        formContent
      ) : (
        <CollapsibleCard
          id={`card-${scrollIndex}`}
          expanded={expanded}
          header={
            <DocumentsCardHeader
              name={name}
              relation={relation}
              expanded={expanded}
              isBusiness={isBusiness}
              cardStatus={cardStatus}
            />
          }
          onHeaderClick={handleExpandClick}
        >
          {formContent}
        </CollapsibleCard>
      )}
      {uploadAlert && (
        <Toast onClose={() => setUploadAlert(null)} title={uploadAlert.message} severity={uploadAlert.severity} />
      )}
      {openDeleteConfirmation && (
        <ConfirmationDialog
          onClose={closeDeleteConfirmationDialog}
          content={
            <Typography textAlign={'center'} mt={3.5} mb={1} variant="h5">
              Are you sure you want to delete this document?
            </Typography>
          }
          confirmText="Delete"
          open={openDeleteConfirmation}
          onConfirm={handleDeleteConfirmation}
        />
      )}
    </>
  );
}

export default memo(DocumentsCard);
