import { useParams } from 'react-router-dom';
import { useGenericFilters } from '../../../components/filters/useGenericFilters';
import { DEFAULT_MAIL_TAB, MailsPathParams } from '../paths';
import { MAILS_FILTERS, TMailFilters } from '@usgm/inbox-api-types';
import { usePaginationParams } from '@usgm/shared-ui';

export function useMailsListParams() {
  const { filtersCount, getAllFilters } = useGenericFilters<TMailFilters>(MAILS_FILTERS);
  const { folderId, tab = DEFAULT_MAIL_TAB } = useParams<MailsPathParams>();
  const { limit, skip } = usePaginationParams();
  const filter = getAllFilters();

  return {
    filter,
    folderId,
    limit,
    skip,
    tab,
    filtersCount,
  };
}
