import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReferralStatus, ReferredUser } from '@usgm/inbox-api-types';
import { Tooltip } from '@usgm/shared-ui';

export type ReferralsListProps = {
  title?: string;
  referrals: ReferredUser[];
};

const getStatusIcon = (status: ReferralStatus) => {
  return (
    <Tooltip
      placement="left"
      arrow
      title={
        status === ReferralStatus.CodeActive
          ? 'Benefit already availed for this referral.'
          : 'Referral code not used yet.'
      }
    >
      {status === ReferralStatus.CodeActive ? (
        <CheckCircleOutlinedIcon color="success" />
      ) : (
        <HighlightOffRoundedIcon color="error" />
      )}
    </Tooltip>
  );
};

export function ReferralsList({ referrals, title }: ReferralsListProps) {
  if (referrals.length === 0) {
    return null;
  }
  return (
    <>
      {title && (
        <Typography mt={6} textAlign="center" variant="h5">
          {title}
        </Typography>
      )}
      <TableContainer component={'span'}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell component="th">
                <strong>Name</strong>
              </TableCell>
              <TableCell align="right" component="th">
                <strong>Status</strong>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {referrals.map((referral) => (
              <TableRow key={referral.userId}>
                <TableCell>{referral.name}</TableCell>
                <TableCell align="right">{getStatusIcon(referral.referralStatus)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
