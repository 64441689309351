import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { AlertProps } from '@mui/material';

const FEATURE_NAME = 'apiMessages';

type TMessagesState = {
  message: {
    severity: AlertProps['severity'];
    text: string;
  } | null;
};

const initialState: TMessagesState = {
  message: null,
};

export const apiMessagesSlice = createSlice({
  name: FEATURE_NAME,
  initialState: initialState,
  reducers: {
    createMessage(state, action: PayloadAction<TMessagesState['message']>) {
      state.message = action.payload;
    },
    clearMessage(state) {
      state.message = null;
    },
  },
});

const selectState = (state: RootState) => state[FEATURE_NAME];

export const selectApiMessage = (state: RootState) => selectState(state).message;
