import { Box, Theme, styled } from '@mui/material';
import { CSSProperties } from 'react';

type TVariant = 'small' | 'inner' | 'large';

export type ContainerProps = {
  variant?: TVariant;
};

const stylesMap: Record<TVariant, (theme: Theme) => CSSProperties> = {
  small: (theme) => ({
    maxWidth: 970,
  }),
  inner: (theme) => ({
    maxWidth: 1280,
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 4),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 2.5),
    },
  }),
  large: (theme) => ({
    maxWidth: 1440,
    [theme.breakpoints.down('lg')]: {
      maxWidth: 1280,
      padding: theme.spacing(0, 4),
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 1100,
      padding: theme.spacing(0, 2.5),
    },
  }),
};

export const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<ContainerProps>(({ theme, variant = 'large' }) => ({
  padding: theme.spacing(0, 2.5),
  marginLeft: 'auto',
  marginRight: 'auto',
  boxSizing: 'border-box',
  ...stylesMap[variant](theme),
}));

export default Container;
