import { useState } from 'react';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';

import { Button, styled } from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FlexBox, UnderlineTextButton, useDialog } from '@usgm/shared-ui';
import { useNavigate } from 'react-router-dom';
import { ENVIRONMENT } from '../../../../../../env';
import { useOnboardingData } from '../../hooks/useOnboardingData';
import { ONBOARDING_PATHS } from '../../paths';

import AlreadyHaveNotaryModal from './AlreadyHaveNotaryModal';

import { useAuth } from '../../../../../auth/hooks/useAuth';

import { AccountVerificationStepStatus } from '@usgm/inbox-api-types';
import { useAppSelector } from '../../../../../../store/index';
import { selectAccountVerificationState } from '../../../../../auth/authSlice';
import { useScheduleOnlineNotarizationMutation } from '../../api';

const Root = styled(FlexBox)(({ theme }) => ({
  gap: theme.spacing(1),
  alignItems: 'flex-end',
  flexDirection: 'column',
  flex: '1 1 auto',
  minWidth: 250,
  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
  },
}));

export type NotaryActionsProps = {
  accountId?: string;
  onSuccess?: () => void;
};

function NotaryActions({ accountId, onSuccess }: NotaryActionsProps) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { isNotarizationNeeded } = useOnboardingData(true);
  const accountVerificationState = useAppSelector((state) => selectAccountVerificationState(state, null));

  const hasForm1583Rejection = accountVerificationState?.steps.notarization?.accountNames.some(
    (accountName) => accountName.status === AccountVerificationStepStatus.Rejected,
  );
  const [scheduleOnlineNotarization] = useScheduleOnlineNotarizationMutation();

  const { closeDialog, open, openDialog } = useDialog();
  const [calendlyPopupOpen, setCalendlyPopupOpen] = useState(false);
  useCalendlyEventListener({
    onEventScheduled: (e) => {
      scheduleOnlineNotarization({
        scheduledEventUrl: e.data.payload.event.uri,
      });
      if (onSuccess) {
        onSuccess();
      } else {
        navigate(ONBOARDING_PATHS.MAIN);
      }
    },
  });

  const appRootElement = document.getElementById('root');
  return (
    <Root>
      <Button
        fullWidth
        endIcon={<ArrowForwardIosIcon />}
        onClick={() => setCalendlyPopupOpen(true)}
        variant="contained"
        disabled={!isNotarizationNeeded}
      >
        {!accountVerificationState?.steps?.notarization?.onlineNotarizationDetails?.scheduledDate
          ? 'Set'
          : 'Re-schedule'}{' '}
        Notary Appointment
      </Button>
      {hasForm1583Rejection ? (
        <Button fullWidth onClick={openDialog} variant="outlined">
          Re-upload Rejected Form(s)
        </Button>
      ) : (
        <UnderlineTextButton disabled={!isNotarizationNeeded} onClick={openDialog}>
          Already have a notary?
        </UnderlineTextButton>
      )}
      {open && (
        <AlreadyHaveNotaryModal onSuccess={onSuccess} accountId={accountId} open={open} closeDialog={closeDialog} />
      )}
      {user && appRootElement && (
        <PopupModal
          url={ENVIRONMENT.CALENDLY_URL}
          pageSettings={{
            hideEventTypeDetails: false,
          }}
          prefill={{
            name: user.name,
            email: user.email,
          }}
          onModalClose={() => setCalendlyPopupOpen(false)}
          open={calendlyPopupOpen}
          rootElement={appRootElement}
        />
      )}
    </Root>
  );
}

export default NotaryActions;
