import React, { useCallback, useState } from 'react';
import { useGetNavigationQuery } from '../../../inboxAccountsApi';
import UserAddressBox from '../../UserAddressBox';
import { Navigation } from '../Navigation';
import { NavigationSkeleton } from '../Navigation/NavigationSkeleton';
import Sidebar, { SidebarProps } from './Sidebar';
import { Box, Theme, styled, useMediaQuery } from '@mui/material';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const ToggleCollapseButton = styled(Box, { shouldForwardProp: (prop) => prop !== 'collapsed' })<{
  collapsed?: boolean;
}>(({ theme, collapsed = false }) => ({
  maxWidth: 32,
  cursor: 'pointer',
  marginBottom: theme.spacing(1),
  display: 'flex',
  padding: theme.spacing(0.5),
  zIndex: theme.zIndex.drawer + 1,
  borderRadius: 2 * theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  marginLeft: 'auto',
  marginRight: collapsed ? 'auto' : undefined,
}));

export function MainSidebar(props: SidebarProps) {
  const { data, isLoading } = useGetNavigationQuery(null);
  const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [collapsedPreference, setCollapsedPreference] = useState(true);

  const isCollapsible = !isLargeScreen && !isSmallScreen;

  const collapsed = isCollapsible ? collapsedPreference : false;

  const toggleCollapse = useCallback(() => {
    setCollapsedPreference(!collapsedPreference);
  }, [collapsedPreference]);

  const onNavigationItemClick = useCallback(() => {
    setCollapsedPreference(true);
    props.handleClose?.();
  }, [props]);

  return (
    <Box sx={{ position: 'relative', width: isCollapsible ? 60 : 'auto' }}>
      <Sidebar {...props} collapsed={collapsed} isCollapsible={isCollapsible}>
        <Box>
          {isCollapsible ? (
            <ToggleCollapseButton collapsed={collapsed} onClick={toggleCollapse}>
              {collapsed ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
            </ToggleCollapseButton>
          ) : null}
          {isLoading && <NavigationSkeleton count={7} />}
          {data && data?.length > 0 && (
            <Navigation collapsed={collapsed} data={data} onItemClick={onNavigationItemClick} />
          )}
        </Box>
        {!collapsed && <UserAddressBox />}
      </Sidebar>
    </Box>
  );
}

export default React.memo(MainSidebar);
