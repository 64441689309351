import { Box, styled } from '@mui/material';

export const StepContentContainer = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  padding: theme.spacing(8, 0),
  display: 'flex',
  justifyContent: 'center',
  width: '90%',
  minHeight: 400,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 2.5),
    width: '100%',
  },
}));
