import { zodResolver } from '@hookform/resolvers/zod';

import { Box, FormControlLabel, FormGroup, styled, Typography, useTheme } from '@mui/material';
import { AccountStatus, OrgUserStatus, UserInfo } from '@usgm/inbox-api-types';

import { cellHelpers, Checkbox, SimpleDialog, TextInput, Toast, useDialog } from '@usgm/shared-ui';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useCloseAccountMutation, useScheduleCloseAccountMutation } from '../api';

const schema = z.object({
  reason: z.string().min(1, 'Reason is required.'),
  acknowledgeMailRegulations: z.boolean().refine((value) => value === true),
  understandAccountClosure: z.boolean().refine((value) => value === true),
});

type CloseAccountFormType = z.infer<typeof schema>;

const CheckboxLabel = styled(Typography)(({ theme }) => ({
  fontSize: 14,
})) as typeof Typography;

function CloseAccountDialog({
  open,
  closeHandler,
  triggerGetDates,
  scheduledAccountClosureDate,
  userUuid,
}: {
  open: boolean;
  userUuid?: UserInfo['userUUID'];
  closeHandler: (status?: OrgUserStatus) => void;
  triggerGetDates: () => void;
  scheduledAccountClosureDate?: string;
  closeDialog: () => void;
}) {
  const theme = useTheme();

  const [scheduleCloseAccount, { isLoading: isLoadingScheduleCloseAccount, error: errorScheduleCloseAccount }] =
    useScheduleCloseAccountMutation();
  const [closeAccount, { isLoading: isLoadingCloseAccount, error: errorCloseAccount }] = useCloseAccountMutation();
  const [cancelDate, setCancelDate] = useState<string | undefined>(scheduledAccountClosureDate);
  const { open: openConfirmDialog, closeDialog, openDialog } = useDialog();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    trigger: triggerValidation,
  } = useForm<CloseAccountFormType>({
    resolver: zodResolver(schema),
    mode: 'onChange',
  });

  const onFormSubmit = async (data?: CloseAccountFormType | null, status?: OrgUserStatus) => {
    const results = await scheduleCloseAccount({ ...data, status });
    const hasError = 'error' in results;

    if (!hasError) {
      triggerGetDates();
      if (status === OrgUserStatus.Active) {
        setCancelDate(results.data.closureDate);
      } else {
        closeHandler(status);
      }
    }
  };

  const closeAccountHandler = async (data?: CloseAccountFormType | null, status?: AccountStatus) => {
    const results = await closeAccount({ ...data, status, userId: userUuid });
    const hasError = 'error' in results;

    if (!hasError) {
      closeHandler(OrgUserStatus.Active);
    }
  };

  useEffect(() => {
    // ToDo get scheduledAccountClosureDate in this dialog using according hook
    setCancelDate(scheduledAccountClosureDate);
  }, [scheduledAccountClosureDate]);

  const isSubmitDisabled = !isValid || isLoadingCloseAccount || isLoadingScheduleCloseAccount;
  const errorMessage = errorScheduleCloseAccount?.message || errorCloseAccount?.message;

  if (cancelDate) {
    return (
      <SimpleDialog
        closeHandler={closeHandler}
        open={open}
        primaryButtonText="Do not close my account"
        primaryButtonHandler={() => {
          // ToDo separate form functionality from mutations
          onFormSubmit(null, OrgUserStatus.Cancelled);
        }}
      >
        <Box pr={3}>
          <Typography variant="h5" mt={3}>
            Your account is set to close on {cellHelpers.formatDateString(cancelDate)}
          </Typography>
          <Typography color={theme.palette.text.secondary} mt={2}>
            Closed in error? Click below to cancel closing
          </Typography>
        </Box>
      </SimpleDialog>
    );
  }

  return (
    <SimpleDialog
      title="Close Account"
      open={open}
      closeHandler={closeHandler}
      primaryButtonText="Do not close my account"
      primaryButtonHandler={closeHandler}
      secondaryButtonText="Close at the end of Term"
      isSecondaryButtonTypeSubmit={true}
      isPrimaryButtonTypeSubmit={false}
      isSubmitDisabled={isSubmitDisabled}
      hasMoreMenu={true}
      moreButtonItems={[
        {
          handler: async () => {
            if (await triggerValidation()) {
              openDialog();
            }
          },
          title: 'Close Immediately',
        },
      ]}
      isForm={true}
      onSubmit={handleSubmit((data) => onFormSubmit(data, OrgUserStatus.Active))}
    >
      <Box>
        <Typography mb={1.5} fontWeight={500}>
          Please tell us the reason for cancellation
        </Typography>
        <TextInput
          {...register('reason')}
          multiline
          textarea
          maxRows={3}
          minRows={3}
          disabled={isLoadingCloseAccount || isLoadingScheduleCloseAccount}
          error={!!errors.reason}
          InputLabelProps={{ shrink: true }}
          required
          placeholder="E.g. Need an address in fort Worth, TX/ Returning to the US"
          fullWidth
        />
        <FormGroup sx={{ mt: 3 }}>
          <FormControlLabel
            sx={{ display: 'flex', alignItems: 'flex-start', py: 1, px: 0, fontSize: 14 }}
            required
            control={<Checkbox {...register('acknowledgeMailRegulations')} />}
            label={
              <CheckboxLabel component="span">
                Per US portal regulations, I recognize I cannot forward mail from my USGM address through the post
                office directly. I need to update all my contacts with my new address.
              </CheckboxLabel>
            }
          />
          <FormControlLabel
            sx={{ display: 'flex', alignItems: 'flex-start', py: 1, px: 0, fontSize: 14 }}
            required
            control={<Checkbox {...register('understandAccountClosure')} />}
            label={
              <CheckboxLabel component="span">
                I understand that upon account closure, US Global Mail will securely shred my existing and incoming mail
                (accepted for 180 days as per USPS regulations).
              </CheckboxLabel>
            }
          />
        </FormGroup>
      </Box>
      {openConfirmDialog && (
        <SimpleDialog
          closeHandler={closeDialog}
          open={openConfirmDialog}
          primaryButtonText="Yes, Close!"
          primaryButtonHandler={handleSubmit((data) => closeAccountHandler(data, AccountStatus.Closed))}
          secondaryButtonText="No"
          secondaryButtonHandler={closeDialog}
          isSubmitDisabled={isSubmitDisabled}
          isSubmitting={isLoadingCloseAccount}
        >
          <Box pr={3} sx={{ textAlign: 'center' }}>
            <Typography textAlign={'center'} mt={3.5} mb={1} variant="h5">
              Are you sure you want to close your account?
            </Typography>
            <Typography textAlign="center" fontSize="0.875rem">
              Closing your account will cancel your subscription and you will not have access to your account and mail
              items anymore
            </Typography>
          </Box>
        </SimpleDialog>
      )}
      {errorMessage && <Toast title={errorMessage} severity="error" />}
    </SimpleDialog>
  );
}

export default React.memo(CloseAccountDialog);
