import { CardContent, styled } from '@mui/material';

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(4),
  minHeight: '500px',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3),
    minHeight: '460px',
  },
}));

export default StyledCardContent;
