import { Box, Theme, Typography, darken, styled } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { FlexBox } from '@usgm/shared-ui';
import { RecordStatus } from '@usgm/inbox-api-types';
import { forwardRef } from 'react';

export const ACCOUNT_ID_BADGE_STATUSES = {
  Missing: 'Needed',
  [RecordStatus.Approved]: 'Accepted',
  [RecordStatus.InReview]: 'Added',
  [RecordStatus.Rejected]: 'Rejected',
};

type TAccountIdBadgeStatusKey = keyof typeof ACCOUNT_ID_BADGE_STATUSES;

const STATUS_STYLES_MAP: Record<
  TAccountIdBadgeStatusKey,
  (theme: Theme) => { color: string; backgroundColor: string }
> = {
  Missing: (theme: Theme) => ({
    color: theme.palette.error.main,
    backgroundColor:
      theme.palette.mode === 'dark' ? darken(theme.palette.error.main, 0.3) : theme.palette.background.error,
  }),
  [RecordStatus.Approved]: (theme: Theme) => ({
    color: theme.palette.success.main,
    backgroundColor:
      theme.palette.mode === 'dark' ? darken(theme.palette.success.dark, 0.3) : theme.palette.background.success,
  }),
  [RecordStatus.InReview]: (theme: Theme) => ({
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'dark' ? darken(theme.palette.primary.dark, 0.3) : theme.palette.background.info,
  }),
  [RecordStatus.Rejected]: (theme: Theme) => ({
    color: theme.palette.warning.main,
    backgroundColor:
      theme.palette.mode === 'dark' ? darken(theme.palette.warning.dark, 0.3) : theme.palette.background.warning,
  }),
};

export const StatusBadge = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'status' && prop !== 'width' && prop !== 'textHidden',
})<{
  status?: TAccountIdBadgeStatusKey;
  width?: number | string;
  textHidden?: boolean;
}>(({ theme, status = 'Missing', width = 160, textHidden = false }) => {
  const colors = STATUS_STYLES_MAP[status](theme);
  if (theme.palette.mode === 'dark') {
    colors['color'] = theme.palette.text.primary;
  }
  return {
    ...colors,
    borderRadius: textHidden ? '50%' : theme.shape.borderRadius * 4,
    padding: theme.spacing(0.5, 1),
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightBold,
    width: textHidden ? theme.spacing(4) : width,
    height: textHidden ? theme.spacing(4) : 'auto',
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    svg: {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.625rem',
      padding: theme.spacing(0.25, 0.5),
      width: textHidden ? theme.spacing(2.5) : width,
      height: textHidden ? theme.spacing(2.5) : 'auto',
      svg: {
        width: theme.spacing(1.5),
        height: theme.spacing(1.5),
      },
    },
  };
});

const getIcon = (status: TAccountIdBadgeStatusKey) => {
  if (['Missing', RecordStatus.Rejected].includes(status)) {
    return <ErrorOutlineOutlinedIcon />;
  } else {
    return <CheckIcon />;
  }
};

export const IdStatusBadge = forwardRef<
  HTMLDivElement,
  {
    status: TAccountIdBadgeStatusKey;
    text: string;
    width?: number | string;
    hideText?: boolean;
  }
>(({ status, text, width, hideText = false }, ref) => {
  return (
    <StatusBadge textHidden={hideText} ref={ref} width={width} status={status}>
      {getIcon(status)}
      {!hideText ? text : ''}
    </StatusBadge>
  );
});

export function AccountIdStatusRow({
  showAsRow = true,
  showName = true,
  name,
  primaryStatus = 'Missing',
  secondaryStatus = 'Missing',
}: {
  showAsRow?: boolean;
  showName?: boolean;
  name: string;
  primaryStatus?: TAccountIdBadgeStatusKey;
  secondaryStatus?: TAccountIdBadgeStatusKey;
}) {
  return (
    <FlexBox
      width="100%"
      alignItems={showAsRow ? 'center' : 'flex-start'}
      flexDirection={showAsRow ? 'row' : 'column'}
      gap={1}
    >
      {showName && (
        <Typography flex={'1 1 auto'} lineHeight={1.5} minWidth={120}>
          {name}
        </Typography>
      )}
      <IdStatusBadge text={`Primary ID ${ACCOUNT_ID_BADGE_STATUSES[primaryStatus]}`} status={primaryStatus} />
      <IdStatusBadge text={`Secondary ID ${ACCOUNT_ID_BADGE_STATUSES[secondaryStatus]}`} status={secondaryStatus} />
    </FlexBox>
  );
}
