import { Button } from '@mui/material';
import { getGeocode } from 'use-places-autocomplete';
import GpsIcon from '../../../../assets/gps.svg?react';
import { useAppDispatch } from '../../../../store';
import { setPlace, setSelectedLocation } from '../../addressPickerSlice';
import { convertResultToPrediction, formatGeoResultAddress } from '../../helper';

const ERROR_MESSAGE_MAPPER: Record<number, string> = {
  [GeolocationPositionError.PERMISSION_DENIED]:
    'Geolocation is currently disabled on this machine. Enable geolocation in your settings or use search bar.',
  [GeolocationPositionError.POSITION_UNAVAILABLE]:
    'Geolocation is currently unavailable. Use search bar to find your location.',
  [GeolocationPositionError.TIMEOUT]: 'Geolocation request timed out. Use search bar to find your location.',
};

type MyLocationButtonProps = {
  label?: string;
};
export default function MyLocationButton({ label = 'Use My Location' }: MyLocationButtonProps) {
  const dispatch = useAppDispatch();
  const getCurrentLocation = () => {
    const geo = navigator.geolocation;
    if (geo) {
      geo.getCurrentPosition(
        async (position) => {
          const location = { lat: position.coords.latitude, lng: position.coords.longitude };
          const [firstResult] = await getGeocode({ location });
          firstResult.formatted_address = formatGeoResultAddress(firstResult);
          dispatch(setPlace(convertResultToPrediction(firstResult)));
          dispatch(setSelectedLocation(location));
        },
        (error) => {
          const message =
            ERROR_MESSAGE_MAPPER[error.code] || 'An unknown error occurred. Use search bar to find your location.';
          alert(message);
        },
        { timeout: 10000, enableHighAccuracy: true },
      );
    } else {
      alert('Geolocation is not supported');
    }
  };
  return (
    <Button onClick={getCurrentLocation} startIcon={<GpsIcon />}>
      {label}
    </Button>
  );
}
