import { Theme, useTheme } from '@mui/material';
import { PlanTerm } from '@usgm/inbox-api-types';
import { useMobileMode } from '@usgm/shared-ui';
import { getAnnualTermSavingsInfo } from '../features/planPicker/helpers/getAnnualTermSavingsInfo';
import { usePlanInteractions } from '../features/planPicker/hooks/usePlanInteractions';
import { usePlans } from '../features/planPicker/hooks/usePlans';
import { plansSelectors, selectPlanTerm } from '../features/planPicker/planPickerSlice';
import { useAppSelector } from '../store';

export const usePlanList = (changeWarehouseMode = false) => {
  const { plansQuery, pickedWarehouse } = usePlans(changeWarehouseMode);
  const plans = useAppSelector(plansSelectors.selectAll);

  const theme = useTheme<Theme>();

  const isMobile = useMobileMode(changeWarehouseMode);

  const term = useAppSelector(selectPlanTerm);
  const { handleCardDeselect, handleCardSelect, selectedCardType } = usePlanInteractions(plans.map((p) => p.name));
  const selectedPlanId = plans.find((p) => p.name === selectedCardType)?.id || '';
  const currentPlan = useAppSelector((state) => plansSelectors.selectById(state, selectedPlanId));
  const { badgeText: savingsBadgeText, hasSavings } = getAnnualTermSavingsInfo({ plan: currentPlan, term });

  const badgeText = term === PlanTerm.Monthly ? undefined : savingsBadgeText;

  return {
    plansQuery,
    pickedWarehouse,
    plans,
    term,
    isMobile,
    theme,
    handleCardDeselect,
    handleCardSelect,
    selectedCardType,
    badgeText,
    hasSavings,
  };
};
