import { Box, styled } from '@mui/material';
import { FlexBox, RouterTabs, RouterTabsProps, UI_CONSTANTS } from '@usgm/shared-ui';

import { useMemo } from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../../../auth/hooks/useAuth';

const TABS_CONTAINER_HEIGHT = 64;

const TabsContainer = styled(FlexBox, {
  shouldForwardProp: (prop) => prop !== 'lessPadding',
})<{
  lessPadding?: boolean;
}>(({ theme, lessPadding }) => ({
  backgroundColor: theme.palette.background.paper,
  paddingTop: theme.spacing(lessPadding ? 0 : 1.875),
  height: TABS_CONTAINER_HEIGHT,
  paddingLeft: theme.spacing(6),
  position: 'sticky',
  top: UI_CONSTANTS.APP_BAR_HEIGHT,
  boxShadow: `0px 1px 0px 0px ${theme.palette.divider}`,
  zIndex: 100,
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
    paddingLeft: theme.spacing(0),
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: 0,
  },
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 6),
  height: '100%',
  alignSelf: 'stretch',
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(0, 4),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 0),
  },
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(6),
  minHeight: `calc(100vh - ${2 * UI_CONSTANTS.APP_BAR_HEIGHT}px)`,
  maxWidth: '1920px',
  width: '100%',
  margin: '0 auto',
  padding: theme.spacing(6, 6, 0, 6),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(4, 4, 0, 4),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 0),
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: 0,
  },
}));

export function TabsLayout({
  tabs,
  rootPath,
  autoScroll = false,
  children,
}: {
  tabs?: RouterTabsProps['tabs'];
  rootPath?: string;
  autoScroll?: boolean;
  children?: React.ReactNode;
}) {
  const { pathname } = useLocation();
  const { isTeamMember, isRegularTeamMember } = useAuth();

  const filteredTabs = useMemo(
    () =>
      isTeamMember
        ? tabs?.filter((it) => it.supportsTeamMembers && (!isRegularTeamMember || it.supportsRegularTeamMember))
        : tabs,
    [isRegularTeamMember, isTeamMember, tabs],
  );

  const hasTabWithSubText = useMemo(() => {
    return filteredTabs?.some((it) => it.subText);
  }, [filteredTabs]);

  const defaultTab = useMemo(() => tabs?.find((it) => it.isDefault), [tabs]);
  if (defaultTab && rootPath && pathname === rootPath) {
    return <Navigate to={defaultTab.path} replace />;
  }

  const content = <InnerContainer>{children ? children : <Outlet />}</InnerContainer>;

  return (
    <>
      {filteredTabs && (
        <TabsContainer height={UI_CONSTANTS.APP_BAR_HEIGHT} lessPadding={hasTabWithSubText}>
          <RouterTabs tabs={filteredTabs} />
        </TabsContainer>
      )}

      {content}
    </>
  );
}
