import { styled, TabProps, TabScrollButton } from '@mui/material';
import { SyntheticEvent, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TabIcon from './TabIcon';
import { Tab, Tabs } from './styled';

const StyledScrollButton = styled(TabScrollButton)(({ theme }) => ({
  width: 24,
  overflow: 'hidden',
  transition: 'width 0.5s',
  '&.Mui-disabled': {
    width: 0,
  },
}));

export type RouterTabsProps = {
  tabs: Array<{
    label: string;
    tooltip?: TabProps['icon'];
    path: string;
    badge?: string | number;
    subText?: string;
    isDefault?: boolean;
    supportsTeamMembers?: boolean;
    supportsRegularTeamMember?: boolean;
  }>;
};

const StyledRouterTabs = styled(Tabs)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    '& .MuiTabs-scroller': {
      width: 'calc(100% - 56px)',
    },
    '& .MuiTabs-scrollButtons': {
      width: 24,
      svg: {
        width: '1.25em',
        height: '1.25em',
      },
      '&.Mui-disabled': {
        width: 24,
      },
    },
  },
}));

export function RouterTabs({ tabs }: RouterTabsProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleTabChange = (_: SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };
  const hasSubtext = tabs.some(({ subText }) => !!subText);
  const value = useMemo(() => {
    return (
      tabs.find((tab) => {
        return pathname.includes(tab.path);
      })?.path || pathname
    );
  }, [pathname, tabs]);

  return (
    <StyledRouterTabs
      onChange={handleTabChange}
      value={value}
      role="navigation"
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      ScrollButtonComponent={StyledScrollButton}
    >
      {tabs.map(({ badge, label, path, tooltip, subText }, index) => (
        <Tab
          key={index}
          hasSubtext={hasSubtext}
          icon={<TabIcon subText={subText} badge={badge} tooltip={tooltip} />}
          label={label}
          value={path}
          iconPosition="end"
        />
      ))}
    </StyledRouterTabs>
  );
}
