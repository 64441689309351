import { PropsWithChildren, StrictMode } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { RecaptchaProvider } from './captcha/RecaptchaContext';
import { Provider as ReduxProvider } from 'react-redux';
import { ENVIRONMENT } from './env';
import ThemeWrapper from './ThemeWrapper';
import { store } from './store';
import { ApiClientsProvider } from './api/axios/AccountApiContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

export default function Providers({ children }: PropsWithChildren) {
  return (
    <StrictMode>
      <HelmetProvider>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <GoogleReCaptchaProvider reCaptchaKey={ENVIRONMENT.RECAPTCHA_SITE_KEY_V3}>
            <ReduxProvider store={store}>
              <BrowserRouter>
                <ApiClientsProvider>
                  <RecaptchaProvider>
                    <ThemeWrapper>{children}</ThemeWrapper>
                  </RecaptchaProvider>
                </ApiClientsProvider>
              </BrowserRouter>
            </ReduxProvider>
          </GoogleReCaptchaProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </StrictMode>
  );
}
