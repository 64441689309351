import { AccountRelation, DtoAccountName, DtoPlan } from '@usgm/inbox-api-types';
import { onboardingUtils } from '..';

export function requiresAccountDeletion(
  newPlan: DtoPlan,
  accountNames: DtoAccountName[],
): {
  deletion: boolean;
  maxCount: number;
  relationsRequiredToDelete: AccountRelation[];
  selected: string[];
  extraNames: number;
} {
  const newMaxNames = newPlan.accountNamesPlan.maxNames;

  const hasDeletionRequiredAccounts =
    !newPlan.options?.isBusiness &&
    accountNames.some((accountName) =>
      onboardingUtils.BUSINESS_ACCOUNT_RELATIONS.includes(accountName.relation.relationType),
    );
  const relationsRequiredToDelete = hasDeletionRequiredAccounts ? onboardingUtils.BUSINESS_ACCOUNT_RELATIONS : [];

  const deletion = (newMaxNames && newMaxNames < accountNames.length) || hasDeletionRequiredAccounts;

  return {
    deletion,
    maxCount: newMaxNames,
    relationsRequiredToDelete,
    extraNames: accountNames.length - newMaxNames,
    selected: accountNames
      .filter((accountName) => relationsRequiredToDelete.includes(accountName.relation.relationType))
      .map((accountName) => accountName.user.uuid),
  };
}
