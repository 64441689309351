import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';

import { DynamicSidebar } from '../../../components/DynamicSidebar';
import { DynamicSidebarPath, useDynamicSidebar } from '../../../components/DynamicSidebar/useDynamicSidebar';
import HeaderActionButton, { THeaderActionButton } from '../../../components/HeaderActions/HeaderActionButton';

import { useGetAnnouncementsQuery } from '../../../../../api/notificationsApi';
import { AnnouncementsList } from './AnnouncementsList';

export function AnnouncementAction({ asNavigationItem = false }: THeaderActionButton) {
  const { data } = useGetAnnouncementsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const { isSelected, toggleSidebar } = useDynamicSidebar(DynamicSidebarPath.Announcements);
  const handleClose = () => toggleSidebar();

  return (
    <>
      <HeaderActionButton
        asNavigationItem={asNavigationItem}
        badgeContent={data?.unreadCount && data.unreadCount > 0 ? data.unreadCount : undefined}
        selected={isSelected}
        onClick={toggleSidebar}
        label="Announcements"
        icon={<CampaignOutlinedIcon />}
      />

      <DynamicSidebar
        open={isSelected}
        handleClose={handleClose}
        path={DynamicSidebarPath.Announcements}
        title="Announcements"
      >
        <AnnouncementsList />
      </DynamicSidebar>
    </>
  );
}

export default AnnouncementAction;
