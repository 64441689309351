import { inboxHelpers } from '@usgm/utils';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  processLogout,
  selectAccountStatus,
  selectIsAccountUnderPaymentHold,
  selectIsAuthenticated,
  selectIsRegularTeamMember,
  selectIsTeamMember,
  selectRefreshToken,
  selectToken,
  selectUserData,
} from '../authSlice';

export const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const user = useAppSelector(selectUserData);
  const token = useAppSelector(selectToken);
  const refreshToken = useAppSelector(selectRefreshToken);
  const isTeamMember = useAppSelector(selectIsTeamMember);
  const isRegularTeamMember = useAppSelector(selectIsRegularTeamMember);
  const accountStatus = useAppSelector(selectAccountStatus);
  const isAccountUnderPaymentHold = useAppSelector(selectIsAccountUnderPaymentHold);

  const handleLogout = useCallback(() => {
    dispatch(
      processLogout({
        callback: () => {
          navigate(inboxHelpers.MAIN_PATHS.LOGIN);
        },
      }),
    );
  }, [dispatch, navigate]);

  return {
    isAuthenticated,
    isTeamMember,
    user,
    token,
    refreshToken,
    isRegularTeamMember,
    isAccountUnderPaymentHold,
    accountStatus,
    handleLogout,
  };
};
