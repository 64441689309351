import { Box, darken, styled } from '@mui/material';

export const StyledUsageBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.success,
  borderRadius: 2 * theme.shape.borderRadius,
  padding: theme.spacing(0.5, 2),

  '> .MuiTypography-root': {
    color: darken(theme.palette.success.dark, 0.2),
  },
}));
