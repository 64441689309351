import { useFormContext } from 'react-hook-form';
import ControlledInput from '../../../../../../../components/ControlledInput';
import { Box } from '@mui/material';
import { BankAccountFormSchemaType } from './schemas';

function BankAccountFormFields() {
  const {
    control,
    formState: { errors },
  } = useFormContext<BankAccountFormSchemaType>();
  return (
    <Box mt={4}>
      <Box mb={1}>
        <ControlledInput<BankAccountFormSchemaType>
          control={control}
          errors={errors}
          name="accountNumber"
          label="Account Number"
        />
      </Box>
      <Box mb={1}>
        <ControlledInput<BankAccountFormSchemaType>
          control={control}
          errors={errors}
          name="bankName"
          label="Bank Name"
        />
      </Box>
      <Box>
        <ControlledInput<BankAccountFormSchemaType>
          control={control}
          errors={errors}
          name="bankState"
          label="Bank State"
        />
      </Box>
    </Box>
  );
}

export default BankAccountFormFields;
