import { Box, Theme, Typography, styled, useMediaQuery, useTheme } from '@mui/material';

import TFooterSection from '../../types/TFooterSection';
import SocialLink from '../../types/TSocialLink';

import { FlexBox, Rating } from '@usgm/shared-ui';
import FooterSections from './FooterSections';
import SocialLinks from './SocialLinks';
import FooterBottom from './styled/FooterBottom';
import FooterTop from './styled/FooterTop';

import logoSrc from '../../../../../assets/USGM-logo.svg';

const FooterWrapper = styled('div')(({ theme }) => ({
  background:
    theme.palette.mode === 'light'
      ? theme.palette.text.title || theme.palette.common.black
      : theme.palette.background.paper,
  color: theme.palette.common.white,
  padding: `${theme.spacing(8)} 0 ${theme.spacing(6)} 0`,
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(3.75)} 0 ${theme.spacing(3.75)} 0`,
  },
}));

const RatingContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(6.5),
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(2.5),
  },
}));

type FooterProps = {
  sections?: TFooterSection[];
  socialLinks?: SocialLink[];
};

function FooterLogo() {
  return (
    <Box mt={-0.875} mb={1} ml={-0.75}>
      <img src={logoSrc} alt="US Global Mail" />
    </Box>
  );
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
})) as typeof Typography;

const FooterContainer = styled(Box)(({ theme }) => ({
  flexWrap: 'wrap',
  maxWidth: 1170,
  margin: '0 auto',
  display: 'flex',
  width: 'fill-available',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

function RatingsInfo() {
  const isDownMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <Box mt={isDownMd ? 4 : 6}>
      <RatingContainer>
        <StyledTypography mb={1}>
          <Rating name="read-only" value={5} readOnly />
        </StyledTypography>
        <StyledTypography>Based on 1000+ Reviews</StyledTypography>
      </RatingContainer>
      <StyledTypography>Worldwide ERC</StyledTypography>
    </Box>
  );
}

export default function Footer({ sections, socialLinks }: FooterProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <FooterWrapper>
      <FooterTop>
        <FooterContainer>
          <Box mr="auto">
            <FooterLogo />
            <StyledTypography fontStyle="normal" color={theme.customColors.dark[500]} component="address">
              1321 Upland Drive Houston, TX 77043
            </StyledTypography>
            {!isDownMd && <RatingsInfo />}
          </Box>
          <FooterSections sections={sections} />
          {isDownMd && <RatingsInfo />}
        </FooterContainer>
      </FooterTop>

      <FooterBottom flexDirection={isMobile ? 'column' : 'row'}>
        <FooterContainer>
          <FlexBox
            width="100%"
            alignItems={isMobile ? 'center' : undefined}
            flexDirection={isMobile ? 'column-reverse' : 'row'}
          >
            <StyledTypography mt={isMobile ? 1 : 0} color={theme.customColors.dark[500]}>
              &copy; {new Date().getFullYear()} USGM. All rights reserved.
            </StyledTypography>
            {socialLinks && <SocialLinks items={socialLinks} />}
          </FlexBox>
        </FooterContainer>
      </FooterBottom>
    </FooterWrapper>
  );
}
