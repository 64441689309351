import { Preloader } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';

import { useActiveSubscription } from '../../../../../../../../../hooks/useActiveSubscription';

import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../../../../../../auth/hooks/useAuth';
import { useGetAccountNamesWithStatusQuery } from '../../../../../../onboarding/api';
import { SETTINGS_PATHS } from '../../../../../paths';
import AddEditNameForm from '../components/AddEditNameForm';
import GlobalAccountNameLimitReached from '../components/GlobalAccountNameLimitReached';

function AddEditName() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { accountNameUuid } = useParams<{ accountNameUuid: string }>();

  const accountsQuery = useGetAccountNamesWithStatusQuery(null, { skip: !user });
  const subscriptionQuery = useActiveSubscription();

  const onComplete = useCallback(
    (accountId?: string) => {
      if (accountId) {
        navigate(SETTINGS_PATHS.EDIT_ACCOUNT_NAME_DOCUMENTS(accountId));
      }
    },
    [navigate],
  );

  if (
    accountsQuery.data?.data.accountNames &&
    accountsQuery.data.data.accountNames.length >= inboxHelpers.MAX_ACCOUNTS_LIMIT
  ) {
    return <GlobalAccountNameLimitReached />;
  }

  return accountsQuery.isLoading || subscriptionQuery.isLoading ? (
    <Preloader />
  ) : (
    subscriptionQuery.activeSubscription && accountsQuery.data?.data && (
      <AddEditNameForm
        onSuccess={onComplete}
        accountId={accountNameUuid}
        accountNames={accountsQuery.data?.data.accountNames}
        plan={subscriptionQuery.activeSubscription.plan}
      />
    )
  );
}

export default AddEditName;
