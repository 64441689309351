import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, MenuItem, MenuList, Popover, Typography, styled } from '@mui/material';
import { PopoverContent, useAnchorEl } from '@usgm/shared-ui';
import { useId } from 'react';
import { useGenericFilters } from './useGenericFilters';

const FilterBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  justifyContent: 'space-between',
  '& .selection-indicator': {
    color: theme.palette.success.main,
  },
}));

export type CategoryFilterProps = {
  label?: string;
  filtersMap: Map<string, string>;
};

const CATEGORY_FILTERS = ['category'] as const;

type TFilterKeys = (typeof CATEGORY_FILTERS)[number];

function CategoryFilter({ label = 'Filter', filtersMap }: CategoryFilterProps) {
  const popperId = useId();
  const { anchorEl, handleClick, handleClose, open } = useAnchorEl<HTMLElement>();
  const { getFilterByKey, updateFilterByKey, clearFilterByKey } = useGenericFilters<TFilterKeys>(CATEGORY_FILTERS);

  const [filteredCategoryKey] = getFilterByKey('category');
  const categoryKey = filteredCategoryKey ?? 'all';
  const categoryLabel = filtersMap.get(categoryKey) ?? 'All';

  const handleCategoryChange = (categoryKey: string) => () => {
    if (categoryKey === 'all') {
      clearFilterByKey('category');
    } else {
      updateFilterByKey('category', [categoryKey]);
    }

    handleClose();
  };

  return (
    <>
      <FilterBox onClick={handleClick} aria-describedby={popperId}>
        <Typography component="span">{label}</Typography>
        <Typography fontWeight="600" ml={0.5} component="span" color="text.secondary">
          {categoryLabel}
        </Typography>
        <Typography display="inline-flex" ml={2} component="span">
          <ExpandMoreIcon />
        </Typography>
      </FilterBox>
      <Popover
        id={popperId}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
        anchorEl={anchorEl}
        open={open}
      >
        <PopoverContent>
          <MenuList>
            {Array.from(filtersMap.entries()).map(([key, value]) => (
              <StyledMenuItem key={key} onClick={handleCategoryChange(key)}>
                {value}
                <Typography ml={2} className="selection-indicator" component="span">
                  {key === categoryKey && <CheckIcon />}
                </Typography>
              </StyledMenuItem>
            ))}
          </MenuList>
        </PopoverContent>
      </Popover>
    </>
  );
}

export default CategoryFilter;
