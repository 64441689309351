import AddIcon from '@mui/icons-material/Add';
import { ButtonProps } from '@mui/material';

import { forwardRef } from 'react';
import InlineIconButton from './InlineIconButton';

type StyledProps = {
  color: 'primary' | 'error' | 'secondary' | 'info' | 'success' | 'warning' | 'yellow';
};

export type AddButtonProps = Omit<ButtonProps, 'children' | 'startIcon' | 'endIcon' | 'color'> &
  Partial<StyledProps> & { label?: string };

export default forwardRef<HTMLButtonElement, AddButtonProps>(function RemoveButton(
  { color = 'primary', label, ...buttonProps },
  ref,
) {
  return <InlineIconButton {...buttonProps} ref={ref} color={color} icon={<AddIcon />} label={label} />;
});
