// @TODO: backend response needs to be corrected to send the id of destination address
import { type DtoShipment, type UserWarehouseAddress } from '@usgm/inbox-api-types';

export function constructShipTo({
  destinationAddress,
  email,
}: {
  destinationAddress: UserWarehouseAddress | DtoShipment['destination'];
  email: string;
}) {
  return {
    city: destinationAddress.city,
    country: destinationAddress.country,
    postalCode: destinationAddress.postalCode,
    contactName: destinationAddress.name,
    email,
    phone: destinationAddress.phoneNumber,
    state: destinationAddress.state,
    street1: destinationAddress.addressLine,
    street2: destinationAddress.addressLine2 || undefined,
    street3: destinationAddress.addressLine3 || undefined,
    type: 'residential',
  };
}
