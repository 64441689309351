import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { GridEventListener } from '@mui/x-data-grid';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { type BillingItem } from '@usgm/inbox-api-types';
import { Button, DataTable, FlexBox, useMobileMode } from '@usgm/shared-ui';

import { useGetCreditsListQuery, useGetInvoicesListQuery, useGetUnbilledChargesListQuery } from '../api';
import { CREDITS_COLUMNS, CREDITS_MOBILE_COLUMNS } from '../gridConfig/credits';
import { INVOICES_COLUMNS, INVOICES_MOBILE_COLUMNS } from '../gridConfig/invoices';
import { UNBILLED_CHARGES_COLUMNS, UNBILLED_CHARGES_MOBILE_COLUMNS } from '../gridConfig/unbilledCharges';
import { BillingPathParams, DEFAULT_BILLING_TAB, createBillingsPath } from '../paths';

import NoBillingIcon from '../../../assets/noBilling.svg?react';
import { useGetRowHeight } from '../../settings/hooks/useGetRowHeight';

const getLisInfoBasedOnTab = (
  tab: string,
  isMobile: boolean,
  {
    creditsQuery,
    invoicesQuery,
    unbilledChargesQuery,
  }: {
    creditsQuery: ReturnType<typeof useGetCreditsListQuery>;
    invoicesQuery: ReturnType<typeof useGetInvoicesListQuery>;
    unbilledChargesQuery: ReturnType<typeof useGetUnbilledChargesListQuery>;
  },
) => {
  switch (tab) {
    case 'credit-notes':
      return {
        query: creditsQuery,
        name: 'credits',
        columns: isMobile ? CREDITS_MOBILE_COLUMNS : CREDITS_COLUMNS,
        rowHeight: isMobile ? 260 : 80,
      };
    case 'unbilled-charges':
      return {
        query: unbilledChargesQuery,
        name: 'unbilled charges',
        columns: isMobile ? UNBILLED_CHARGES_MOBILE_COLUMNS : UNBILLED_CHARGES_COLUMNS,
        rowHeight: isMobile ? 220 : 80,
      };
    case 'invoices':
      return {
        query: invoicesQuery,
        name: 'invoices',
        columns: isMobile ? INVOICES_MOBILE_COLUMNS : INVOICES_COLUMNS,
        rowHeight: isMobile ? 300 : 80,
      };
    default:
      return null;
  }
};

const OFFSET_MAP = new Map<number, string>([[0, 'init']]);

function BillingList() {
  const navigate = useNavigate();

  const isMobile = useMobileMode();

  const [nextPrevInfo, setNextPrevInfo] = useState<{ next: string | undefined; prev: string | undefined }>({
    next: undefined,
    prev: undefined,
  });
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { tab = DEFAULT_BILLING_TAB } = useParams<BillingPathParams>();
  const offset = OFFSET_MAP.get(currentPage);

  // Queries
  const creditsQuery = useGetCreditsListQuery(
    { offset: offset === 'init' ? undefined : offset },
    {
      skip: tab !== 'credit-notes',
    },
  );

  const unbilledChargesQuery = useGetUnbilledChargesListQuery(
    { offset: offset === 'init' ? undefined : offset },
    {
      skip: tab !== 'unbilled-charges',
    },
  );

  const invoicesQuery = useGetInvoicesListQuery(
    { offset: offset === 'init' ? undefined : offset },
    {
      skip: tab !== 'invoices',
    },
  );

  const listInfo = useMemo(
    () =>
      getLisInfoBasedOnTab(tab, isMobile, {
        creditsQuery,
        invoicesQuery,
        unbilledChargesQuery,
      }),
    [creditsQuery, invoicesQuery, isMobile, tab, unbilledChargesQuery],
  );

  const nextOffset = listInfo?.query.data?.nextOffset || '';

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    if (tab !== 'unbilled-charges') {
      navigate(createBillingsPath({ itemId: params.row.vendorId, tab }));
    }
  };

  useEffect(() => {
    return () => {
      OFFSET_MAP.clear();
      OFFSET_MAP.set(0, 'init');
      setNextPrevInfo({ next: undefined, prev: undefined });
      setCurrentPage(0);
    };
  }, [tab]);

  useEffect(() => {
    if (nextOffset) {
      OFFSET_MAP.set(currentPage + 1, nextOffset);

      setNextPrevInfo({
        next: OFFSET_MAP.get(currentPage + 1),
        prev: OFFSET_MAP.get(currentPage - 1),
      });
    } else {
      setNextPrevInfo({
        next: undefined,
        prev: OFFSET_MAP.get(currentPage - 1),
      });
    }
  }, [currentPage, nextOffset]);

  const nextDisabled = !nextPrevInfo.next;
  const prevDisabled = !nextPrevInfo.prev;

  const getRowHeight = useGetRowHeight(isMobile);

  const paginationControl = useMemo(
    () => (
      <FlexBox gap={2}>
        <Button
          minWidth={isMobile ? 80 : undefined}
          size={isMobile ? 'small' : 'medium'}
          startIcon={<ArrowBackIosIcon />}
          disabled={prevDisabled}
          onClick={() => setCurrentPage((prev) => prev - 1)}
          variant="outlined"
        >
          Prev
        </Button>

        <Button
          minWidth={isMobile ? 80 : undefined}
          endIcon={<ArrowForwardIosIcon />}
          disabled={nextDisabled}
          onClick={() => setCurrentPage((prev) => prev + 1)}
          variant="outlined"
          size={isMobile ? 'small' : 'medium'}
        >
          Next
        </Button>
      </FlexBox>
    ),
    [isMobile, nextDisabled, prevDisabled],
  );

  const getRowId = useCallback((row: BillingItem) => {
    return row.vendorId;
  }, []);

  return (
    listInfo && (
      <DataTable
        onRowClick={handleRowClick}
        getRowId={getRowId}
        getRowHeight={getRowHeight}
        headerLeft={isMobile ? paginationControl : undefined}
        placeholderIcon={<NoBillingIcon />}
        paginationControl={paginationControl}
        onRefresh={listInfo.query.refetch}
        entityName={listInfo.name}
        totalRows={listInfo.query.data?.total}
        loading={listInfo.query.isFetching}
        columns={listInfo.columns}
        rows={listInfo.query.data?.list}
        isLoading={listInfo.query.isLoading}
        checkboxSelection={false}
        disableCustomMobileFooter={true}
      />
    )
  );
}

export default BillingList;
