import { MONTH_DAYS } from '@inbox/constants';
import { Box, MenuItem } from '@mui/material';
import { ShipmentScheduleFrequency } from '@usgm/inbox-api-types';
import { FlexBox } from '@usgm/shared-ui';
import { dateUtils, pascalCase } from '@usgm/utils';
import { ControllerRenderProps, useFormContext } from 'react-hook-form';
import ControlledInput from '../../../../components/ControlledInput';
import { ShipmentSchedulingFormSchemaType } from './shipmentSchedulingFormSchema';

const WORKING_WEEK_DAYS = dateUtils.createWeekDaysArray('EEEE', true);

function ShipmentScheduleFrequencyFields() {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<ShipmentSchedulingFormSchemaType>();

  const shipmentSchedule = watch('shipmentSchedule');

  const shipmentScheduleDayOptions =
    shipmentSchedule === ShipmentScheduleFrequency.Weekly
      ? WORKING_WEEK_DAYS
      : shipmentSchedule === ShipmentScheduleFrequency.Monthly
      ? MONTH_DAYS
      : [];

  const renderFrequencyOptions = (field: ControllerRenderProps<ShipmentSchedulingFormSchemaType>) =>
    Object.values(ShipmentScheduleFrequency).map((option) => (
      <MenuItem selected={field.value === option} key={option} value={option}>
        {pascalCase(option)}
      </MenuItem>
    ));

  const renderScheduleDayOptions = (field: ControllerRenderProps<ShipmentSchedulingFormSchemaType>) =>
    shipmentScheduleDayOptions.map((option: { value: number; label: string }) => (
      <MenuItem selected={field.value === option.value} key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));

  return (
    <FlexBox width={'100%'} gap={2}>
      <Box flex={1}>
        <ControlledInput<ShipmentSchedulingFormSchemaType>
          name="shipmentSchedule"
          control={control}
          label="Shipment Schedule"
          select
          errors={errors}
          renderOptions={renderFrequencyOptions}
        />
      </Box>
      {shipmentSchedule && shipmentSchedule !== ShipmentScheduleFrequency.Daily && (
        <Box minWidth={132}>
          <ControlledInput<ShipmentSchedulingFormSchemaType>
            name="shipmentScheduleDay"
            control={control}
            label="Scheduled Day"
            select
            errors={errors}
            renderOptions={renderScheduleDayOptions}
          />
        </Box>
      )}
    </FlexBox>
  );
}

export default ShipmentScheduleFrequencyFields;
