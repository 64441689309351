import { Box, BoxProps } from '@mui/material';

export type TextCellProps = Omit<BoxProps, 'component'>;
export function TextCell({ children, mt = 1, ...props }: TextCellProps) {
  return (
    <Box maxWidth="100%" component="div" className="cell-text" mt={mt} {...props}>
      {children}
    </Box>
  );
}
