import { ConfirmationDialog, TextInput, useDialog } from '@usgm/shared-ui';
import { useForm } from 'react-hook-form';
import { FolderSchema } from './schema';
import { useAddMailFolderMutation } from '../api';

export function CreateFolderDialog(props: ReturnType<typeof useDialog<void>>) {
  const {
    register,
    watch,
    reset,
    formState: { isValid, isDirty },
  } = useForm<FolderSchema>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      label: '',
    },
  });

  const label = watch('label');
  const [addFolderMutation] = useAddMailFolderMutation();

  const isDisabled = !isValid || !isDirty;

  const handleConfirm = () => {
    if (!label) return;
    reset();
    addFolderMutation({ label });
  };

  return props.open ? (
    <ConfirmationDialog
      open={props.open}
      confirmText="Save"
      disabled={isDisabled}
      onCancel={props.closeDialog}
      onConfirm={handleConfirm}
      onClose={props.closeDialog}
      title="Add Folder"
      content={<TextInput {...register('label')} fullWidth />}
    />
  ) : null;
}
