import { Tab as MuiTab, styled } from '@mui/material';

export const Tab = styled(MuiTab, { shouldForwardProp: (prop) => prop !== 'hasSubtext' })<{ hasSubtext?: boolean }>(
  ({ theme, hasSubtext }) => ({
    textTransform: 'capitalize',
    fontSize: '1.125rem',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    minHeight: 'auto',
    paddingTop: theme.spacing(2),
    paddingBottom: hasSubtext ? theme.spacing(2.5) : theme.spacing(1.375),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(1.875),
    position: 'relative',
    '& .tab-subtext': {
      position: 'absolute',
      bottom: theme.spacing(0.5),
      fontSize: '0.75rem',
      lineHeight: '1.25rem',
      display: 'block',
      color: theme.customColors.dark[400],
      fontWeight: theme.typography.fontWeightBold,
      marginTop: theme.spacing(0.5),
    },
  }),
);
