import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Box, Divider, styled, Typography, useTheme } from '@mui/material';
import { ShipmentCategory, UserWarehouseAddress } from '@usgm/inbox-api-types';
import { Button, FlexBox, InlineButton, Toast, useCopyToClipboard } from '@usgm/shared-ui';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../../store';
import { ContentContainer } from '../../../../components/DynamicSidebar/ContentContainer';
import SuccessBox from '../../../../components/SuccessBox';
import { createShipmentsPath } from '../../../shipments/paths';
import {
  selectBankAccount,
  selectDestinationAddress,
  selectSelectedRate,
  selectShipmentType,
  selectSubmittedShipment,
} from './shipment/mailsShipmentSlice';

const CopyContainer = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 3,
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2, 3, 2, 2),
  backgroundColor: theme.palette.background.paper,
}));

const StyledSubheader = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '0.875rem',
  color: theme.customColors.dark[300],
}));

const StyledDetailText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  marginTop: theme.spacing(1),
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

const constructAddressDetails = (address: UserWarehouseAddress) => {
  const details = [address.name, address.addressLine];
  if (address.addressLine2) {
    details.push(address.addressLine2);
  }
  if (address.addressLine3) {
    details.push(address.addressLine3);
  }

  details.push(`${address.city}, ${address.state}`);
  details.push(`${address.country} - ${address.postalCode}`);
  details.push(address.phoneNumber);

  return details;
};

function ShipmentRequestSuccessMessage({ handleClose }: { handleClose: () => void }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const submittedShipment = useAppSelector(selectSubmittedShipment);
  const selectedRate = useAppSelector(selectSelectedRate);
  const destinationAddress = useAppSelector(selectDestinationAddress);
  const shipmentType = useAppSelector(selectShipmentType);
  const bankAccount = useAppSelector(selectBankAccount);
  const requestTypeLabel = shipmentType === ShipmentCategory.CheckDepositRequest ? 'Deposit check' : 'Shipment';

  const { clearMessage, data, handleCopy, showMessage } = useCopyToClipboard({
    label: `${requestTypeLabel} request ID`,
    textToCopy: submittedShipment?.shipmentId || '',
  });

  const details = destinationAddress ? constructAddressDetails(destinationAddress) : [];

  const handleViewShipmentDetailsClick = () => {
    handleClose();
    navigate(createShipmentsPath({ tab: 'pending', itemId: submittedShipment?.shipmentId }));
  };

  return (
    <ContentContainer>
      <SuccessBox heading={`Your ${requestTypeLabel} request has been submitted`} />
      <CopyContainer>
        <Typography mb={1} fontSize="0.875rem" color={theme.customColors.dark[300]}>
          {requestTypeLabel} request ID
        </Typography>
        <FlexBox gap={2}>
          <Typography color={theme.palette.success.main}>#{submittedShipment?.shipmentId}</Typography>
          <InlineButton sx={{ color: theme.palette.text.primary }} onClick={handleCopy}>
            <ContentCopyOutlinedIcon />
          </InlineButton>
        </FlexBox>
      </CopyContainer>
      <Box mt={3}>
        <Button onClick={handleViewShipmentDetailsClick} variant="contained" color="primary" fullWidth>
          View {requestTypeLabel} Details
        </Button>
      </Box>

      <StyledSubheader>YOUR {requestTypeLabel.toUpperCase()} WILL BE SENT VIA</StyledSubheader>
      <StyledDetailText>{selectedRate?.serviceName}</StyledDetailText>
      <StyledDivider />

      <StyledSubheader>TO</StyledSubheader>
      <Box my={1}>
        {details.map((detail, index) => (
          <StyledDetailText key={index}>{detail}</StyledDetailText>
        ))}
      </Box>
      <StyledDivider />

      {shipmentType === ShipmentCategory.CheckDepositRequest && bankAccount && (
        <>
          <StyledSubheader>BANK DETAILS</StyledSubheader>
          <StyledDetailText>{bankAccount.accountNumber}</StyledDetailText>
          <StyledDetailText>{bankAccount.bankName}</StyledDetailText>
          <StyledDetailText>{bankAccount.bankState}</StyledDetailText>
          <StyledDivider />
        </>
      )}

      {showMessage && <Toast onClose={clearMessage} title={data.message} severity={data.error ? 'error' : 'success'} />}
    </ContentContainer>
  );
}

export default ShipmentRequestSuccessMessage;
