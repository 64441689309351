import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';

import { PICKUP_SERVICE_TYPE } from '@inbox/constants';
import {
  type BaseMail,
  type BaseShipment,
  type CalculatePackingDimensionsParams,
  DtoBankAccount,
  type DtoPackingDimensions,
  type DtoShipment,
  isUnboxingMailType,
  type Rate,
  ShipmentCategory,
  type ShipmentRequestParams,
  ShipmentSignature,
  type ShippableItem,
  type UserWarehouseAddress,
} from '@usgm/inbox-api-types';
import { mailsShipmentUtils } from '@usgm/utils';
import { DateTime } from 'luxon';
import { geolocationApi } from '../../../../../../../api/geolocationApi';
import { postmenApi } from '../../../../../../../api/postmenApi';
import { RootState } from '../../../../../../../store';
import { shipmentsApi } from '../../../../shipments/api';
import { DEFAULT_DECLARATION } from '../../MailInfo/ShippingDeclarationsManager';
import { TMailsDeclarationSchemaType } from './steps/DeclarationsStep';

const FEATURE_NAME = 'MAILS_SHIPMENT';

export type TShippingDetails = {
  instructions: string;
  requestedShipmentDate: string | null;
  isConsolidationCostPermitted: boolean;
  isExpediteShipping: boolean;
  signatureRequired: boolean;
  includeNewItems: boolean;
  shipmentId?: BaseShipment['uuid'];
};

const DEFAULT_SHIPPING_DETAILS: TShippingDetails = {
  instructions: '',
  requestedShipmentDate: null,
  isConsolidationCostPermitted: false,
  isExpediteShipping: false,
  signatureRequired: false,
  includeNewItems: false,
};

type TDefaultState = {
  destinationAddress: UserWarehouseAddress | DtoShipment['destination'] | null;
  ratesInfo: EntityState<Rate & { id: string }, string> & {
    isLoading: boolean;
    selectedRate: (Rate & { id: string }) | null;
  };
  insureShipment: boolean;
  insuranceAmount: number;
  packingDimensionsInfo: {
    data: DtoPackingDimensions | null;
    isLoading: boolean;
  };
  originalMails: ShippableItem[];
  isMailsDeclarationsValid: boolean;
  shippingDetails: TShippingDetails;

  submittedShipment: {
    shipmentId: BaseShipment['uuid'];
  } | null;
  ratesRequestId: string | null;
  destinationAddressId: ShipmentRequestParams['destinationAddressId'];
  serviceType: string | null;
  shipmentType: ShipmentCategory | null;
  bankAccount: DtoBankAccount | null;
  depositCheckInstructions: string | null;
};

type TState = EntityState<ShippableItem, number> & TDefaultState;

const mailsToShipAdapter = createEntityAdapter<ShippableItem>();
const ratesAdapter = createEntityAdapter<Rate & { id: string }>();

const DEFAULT_STATE: TDefaultState = {
  destinationAddress: null,
  insureShipment: false,
  insuranceAmount: 0,
  ratesInfo: ratesAdapter.getInitialState({
    isLoading: false,
    selectedRate: null,
  }),
  packingDimensionsInfo: {
    data: null,
    isLoading: false,
  },

  originalMails: [],
  isMailsDeclarationsValid: false,
  shippingDetails: { ...DEFAULT_SHIPPING_DETAILS },
  serviceType: null,
  submittedShipment: null,
  ratesRequestId: null,
  destinationAddressId: null,
  shipmentType: null,
  bankAccount: null,
  depositCheckInstructions: null,
};

const initialState: TState = mailsToShipAdapter.getInitialState({ ...DEFAULT_STATE });

const isShippingDeclarationsRequired = (state: TState) => {
  if (!state.destinationAddress) {
    return false;
  }

  const isShippingDeclarationApplicable = state.ids?.some((id) => isUnboxingMailType(state.entities[id].mailType));
  const usaMilitaryBaseCheck = mailsShipmentUtils.shipmentIsNotBoundForUSMilitaryBase(state.destinationAddress);
  return isShippingDeclarationApplicable && usaMilitaryBaseCheck;
};

export const mailsShipmentSlice = createSlice({
  name: FEATURE_NAME,
  initialState,
  reducers: {
    setMails: (state, action: PayloadAction<{ mails: BaseMail[]; shipmentType: ShipmentCategory }>) => {
      state.originalMails = action.payload.mails;
      state.shipmentType = action.payload.shipmentType;
      mailsToShipAdapter.setAll(state, action.payload.mails);
    },
    setDestinationAddress: (
      state,
      action: PayloadAction<
        UserWarehouseAddress | (DtoShipment['destination'] & { countryCode: string; uuid: string }) | null
      >,
    ) => {
      state.destinationAddress = action.payload;
      state.destinationAddressId = action.payload?.uuid || null;
      state.ratesInfo.selectedRate = null;
      if (!isShippingDeclarationsRequired(state)) {
        mailsToShipAdapter.setAll(state, state.originalMails);
        state.isMailsDeclarationsValid = false;
      }
    },
    setBankAccount: (state, action: PayloadAction<DtoBankAccount | null>) => {
      state.bankAccount = action.payload;
    },
    setDepositCheckInstructions: (state, action: PayloadAction<string | null>) => {
      state.depositCheckInstructions = action.payload;
    },
    setIsMailDeclarationsValid: (state, action: PayloadAction<boolean>) => {
      state.isMailsDeclarationsValid = action.payload;
    },
    setInsureShipment: (state, action: PayloadAction<boolean>) => {
      state.insureShipment = action.payload;
    },
    setInsuranceAmount: (state, action: PayloadAction<number>) => {
      state.insuranceAmount = action.payload;
    },
    setSelectedRate: (state, action: PayloadAction<Rate & { id: string }>) => {
      state.ratesInfo.selectedRate = action.payload;
      state.serviceType = action.payload.serviceType;
      if (state.ratesInfo.selectedRate.serviceType !== PICKUP_SERVICE_TYPE && state.ids.length > 1) {
        state.shippingDetails.isConsolidationCostPermitted = true;
      }
    },
    setMailsShippingDeclarations: (state, action: PayloadAction<TMailsDeclarationSchemaType>) => {
      action.payload.mails.forEach((mail) => {
        mailsToShipAdapter.updateOne(state, { changes: { shippingDeclarations: mail.declarations }, id: mail.mailId });
      });
    },
    resetState: (state) => {
      state.destinationAddress = DEFAULT_STATE.destinationAddress;
      state.destinationAddressId = DEFAULT_STATE.destinationAddressId;

      state.bankAccount = DEFAULT_STATE.bankAccount;
      state.depositCheckInstructions = DEFAULT_STATE.depositCheckInstructions;

      state.insureShipment = DEFAULT_STATE.insureShipment;
      state.insuranceAmount = DEFAULT_STATE.insuranceAmount;

      state.ratesInfo = DEFAULT_STATE.ratesInfo;
      state.ratesRequestId = null;
      state.serviceType = DEFAULT_STATE.serviceType;

      state.packingDimensionsInfo = DEFAULT_STATE.packingDimensionsInfo;
      state.submittedShipment = DEFAULT_STATE.submittedShipment;

      state.shipmentType = DEFAULT_STATE.shipmentType;

      state.originalMails = DEFAULT_STATE.originalMails;
      state.isMailsDeclarationsValid = DEFAULT_STATE.isMailsDeclarationsValid;
      state.shippingDetails = { ...DEFAULT_SHIPPING_DETAILS };
      mailsToShipAdapter.removeAll(state);
      ratesAdapter.removeAll(state.ratesInfo);
    },
    setSignatureRequired: (state, action: PayloadAction<boolean>) => {
      state.shippingDetails.signatureRequired = action.payload;
    },
    setIsExpediteShipping: (state, action: PayloadAction<boolean>) => {
      state.shippingDetails.isExpediteShipping = action.payload;
    },
    setIncludeNewItems: (state, action: PayloadAction<boolean>) => {
      state.shippingDetails.includeNewItems = action.payload;
    },
    setRequestedShipmentDate: (state, action: PayloadAction<string | null>) => {
      state.shippingDetails.requestedShipmentDate = action.payload;
    },
    // @TODO: in the DtoShipment insured amount is missing so we can't correctly show toggle state
    editShipment: (state, { payload }: PayloadAction<DtoShipment>) => {
      state.shippingDetails = {
        instructions: payload.instructions,
        requestedShipmentDate: payload.requestedShipDate,
        isConsolidationCostPermitted: payload.isOpenAndConsolidatePermitted,
        isExpediteShipping: payload.isExpedited,
        signatureRequired: payload.signature.id === ShipmentSignature.Required,
        includeNewItems: payload.isNewItemsIncluded,
        shipmentId: payload.uuid,
      };

      state.shipmentType = payload.categoryType;

      state.destinationAddress = payload.destination;
      state.destinationAddressId = payload.destinationAddressId;
      state.depositCheckInstructions = payload.instructions;

      state.serviceType = payload.serviceType;
      state.insureShipment = !!payload.insuredAmount;
      state.insuranceAmount = payload.insuredAmount || 0;

      const originalMails: ShippableItem[] = payload.mailItems.map((mail) => {
        const { weight, ...measurement } = mail.dimensions;
        const item: ShippableItem = {
          imageUrl: mail.imageUrl,
          id: mail.mailId,
          mailType: mail.mailType,
          measurement,
          weight,
          shippingDeclarations: mail.declarations,
          arrivalDate: mail.arrivalDate,
        };
        return item;
      });
      state.originalMails = originalMails;
      mailsToShipAdapter.setAll(state, originalMails);
    },
  },
  extraReducers(builder) {
    builder.addMatcher(isAnyOf(geolocationApi.endpoints.calculatePackingDimensions.matchRejected), (state) => {
      state.packingDimensionsInfo.isLoading = false;
    });
    builder.addMatcher(isAnyOf(geolocationApi.endpoints.calculatePackingDimensions.matchPending), (state) => {
      state.packingDimensionsInfo.isLoading = true;
    });
    builder.addMatcher(
      isAnyOf(geolocationApi.endpoints.calculatePackingDimensions.matchFulfilled),
      (state, { payload }) => {
        state.packingDimensionsInfo.isLoading = false;
        state.packingDimensionsInfo.data = payload;
      },
    );
    builder.addMatcher(isAnyOf(postmenApi.endpoints.calculateShipmentRates.matchPending), (state, action) => {
      state.ratesRequestId = action.meta.requestId;
      state.ratesInfo.isLoading = true;
    });
    builder.addMatcher(isAnyOf(postmenApi.endpoints.calculateShipmentRates.matchRejected), (state, action) => {
      if (state.ratesRequestId === action.meta.requestId) {
        state.ratesInfo.isLoading = false;
      }
    });
    builder.addMatcher(
      isAnyOf(postmenApi.endpoints.calculateShipmentRates.matchFulfilled),
      (state, { payload, meta: { requestId } }) => {
        if (state.ratesRequestId === requestId) {
          state.ratesInfo.isLoading = false;

          const rates = payload.data.rates.map((rate) => {
            const rateData = {
              ...rate,
              id: rate.serviceType,
              serviceName: (rate.serviceName || rate.serviceType).replace(/_/g, ' '),
              trackable:
                rate.trackable &&
                mailsShipmentUtils.isTrackableRate({
                  country: state.destinationAddress?.country || '',
                  shipmentRate: rate,
                  mailTypes: state.ids.map((id) => state.entities[id].mailType),
                }),
            };

            if (rate.serviceType === state.serviceType) {
              state.ratesInfo.selectedRate = rateData;
            }
            return rateData;
          });
          ratesAdapter.setAll(state.ratesInfo, rates);
        }
      },
    );
    builder.addMatcher(
      isAnyOf(
        shipmentsApi.endpoints.createMailsShippingRequest.matchFulfilled,
        shipmentsApi.endpoints.updateMailsShippingRequest.matchFulfilled,
      ),
      (state, { payload }) => {
        state.submittedShipment = {
          shipmentId: payload.shipmentId,
        };
      },
    );
  },
});

const selectState = (state: RootState) => state[FEATURE_NAME];

const selectRatesState = (state: RootState) => state[FEATURE_NAME].ratesInfo;

// Adapters selectors
export const mailsToShipSelectors = mailsToShipAdapter.getSelectors(selectState);
export const ratesSelectors = ratesAdapter.getSelectors(selectRatesState);

export const selectDeclarationsFormData = createSelector(selectState, (state) => {
  return {
    mails: state.ids?.map((mailId) => {
      const mail = state.entities[mailId];
      return {
        mailId: mail.id,
        imageUrl: mail.imageUrl,
        declarations: mail.shippingDeclarations || [DEFAULT_DECLARATION],
      };
    }),
  };
});

export const selectDestinationAddress = createSelector(selectState, (state) => state.destinationAddress);
export const selectDestinationAddressId = createSelector(selectState, (state) => state.destinationAddressId);

export const selectMailsDimensions = createSelector(selectState, (state): CalculatePackingDimensionsParams => {
  return state.ids?.map((mailId) => {
    const mail = state.entities[mailId];
    return {
      length: mail.measurement.length,
      width: mail.measurement.width,
      height: mail.measurement.height,
      weight: mail.weight,
      type: mail.mailType,
    };
  });
});

export const selectIsRatesLoading = createSelector(selectRatesState, (state) => state.isLoading);
export const selectPackingDimensions = createSelector(selectState, (state) => state.packingDimensionsInfo);
export const selectInsureShipment = createSelector(selectState, (state) => state.insureShipment);
export const selectInsuranceAmount = createSelector(selectState, (state) => state.insuranceAmount);
export const selectSelectedRate = createSelector(selectRatesState, (state) => state.selectedRate);
export const selectShippingDeclarationsRequired = (state: RootState) =>
  isShippingDeclarationsRequired(selectState(state));

export const selectIsMailsDeclarationsValid = createSelector(selectState, (state) => {
  return state.ids
    .filter((id) => isUnboxingMailType(state.entities[id].mailType))
    .every((id) => {
      return !!state.entities[id].shippingDeclarations?.length;
    });
});

export const selectIsShipmentIncludesPackage = createSelector(selectState, (state) => {
  return state.ids?.some((id) => isUnboxingMailType(state.entities[id].mailType));
});

export const selectIsConsolidationCostPermitted = createSelector(
  selectState,
  (state) => state.shippingDetails.isConsolidationCostPermitted,
);

export const selectShippingDetails = createSelector(selectState, (state) => state.shippingDetails);

export const selectIsExpediteShipping = createSelector(
  selectState,
  (state) => state.shippingDetails.isExpediteShipping,
);
export const selectIncludeNewItems = createSelector(selectState, (state) => state.shippingDetails.includeNewItems);
export const selectSignatureRequired = createSelector(selectState, (state) => state.shippingDetails.signatureRequired);
export const selectInstructions = createSelector(selectState, (state) => state.shippingDetails.instructions);
export const selectRequestedShipmentDate = createSelector(selectState, (state) =>
  state.shippingDetails.requestedShipmentDate ? DateTime.fromISO(state.shippingDetails.requestedShipmentDate) : null,
);

export const selectSubmittedShipment = createSelector(selectState, (state) => state.submittedShipment);

export const selectShipmentType = createSelector(selectState, (state) => state.shipmentType);

export const selectBankAccount = createSelector(selectState, (state) => state.bankAccount);

export const selectDepositCheckInstructions = createSelector(selectState, (state) => state.depositCheckInstructions);
