import { useState } from 'react';

import { Box, Link, List, Typography, useTheme } from '@mui/material';
import { ConfirmationDialog, DropZone, IconListItem, Preloader } from '@usgm/shared-ui';

import DoneIcon from '../../../../../../assets/complete_done.svg?react';
import UploadIcon from '../../../../assets/icons/upload.svg?react';

import { onboardingUtils } from '@usgm/utils';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { selectAccountVerificationState } from '../../../../../auth/authSlice';
import { useUploadOfflineNotarizationMutation } from '../../api';
import { useOnboardingData } from '../../hooks/useOnboardingData';
import DownloadForm1583 from './DownloadForm1583';
import FileRow from './FileRow';
import { apiMessagesSlice } from '../../../../../apiMessages/apiMessagesSlice';

export type AlreadyHaveNotaryModalProps = {
  open: boolean;
  closeDialog: () => void;
  accountId?: string;
  onSuccess?: () => void;
};

export default function AlreadyHaveNotaryModal({
  closeDialog,
  open,
  accountId,
  onSuccess,
}: AlreadyHaveNotaryModalProps) {
  const dispatch = useAppDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const { isLoading } = useOnboardingData(true);

  const theme = useTheme();
  const accountVerificationState = useAppSelector((state) => selectAccountVerificationState(state, null));
  const accountNames = accountVerificationState?.steps.accountNames?.accountNames;
  const [files, setFiles] = useState<Array<{ file: File; userId: string | null; progress: number }>>([]);

  const [uploadOfflineNotarizationMutation] = useUploadOfflineNotarizationMutation();

  const filteredAccounts = accountNames?.filter((account) => {
    if (accountId) {
      return account.id === accountId;
    }

    return !onboardingUtils.NOTARIZATION_CAN_BE_SKIPPED_RELATIONS.includes(account.relation);
  });

  const handleAccept = (files: File[]) => {
    setFiles((prev) => [...prev, ...files.map((file) => ({ file, userId: null, progress: 0 }))]);
  };

  const detailsList = [
    <>
      Form must be dated, signed and stamped by a{' '}
      <Link href="https://www.usglobalmail.com/helps/what-is-a-notary-and-where-can-i-find-one" target="_blank">
        notary
      </Link>
    </>,
    'Sections 1 through 4, as well as sections 8, 9, 13, and 14 must be completely filled out. Section 7 must be filled if adding a business',
  ];

  const handleRemove = (index: number) => () => {
    setFiles((files) => files.filter((_, i) => i !== index));
  };

  const handleSelectAccount = (index: number) => (accountId: string) => {
    setFiles((files) =>
      files.map((file, i) => {
        if (i === index) {
          return { ...file, userId: accountId };
        }
        return file;
      }),
    );
  };
  const isSubmitDisabled = files.some(({ userId }) => !userId) || files.length === 0;
  const handleUpload = async () => {
    const promises: Promise<unknown>[] = [];
    files.forEach(({ file, userId }) => {
      if (userId) {
        promises.push(uploadOfflineNotarizationMutation({ accountUUID: userId, formFile: file }).unwrap());
      }
    });
    try {
      setIsUploading(true);
      await Promise.all(promises);

      onSuccess?.();
      closeDialog();
    } catch (e: unknown) {
      dispatch(
        apiMessagesSlice.actions.createMessage({
          text: 'Failed to submit form1583. Please try again later.',
          severity: 'error',
        }),
      );
    } finally {
      setIsUploading(false);
    }
  };
  const maxFiles = filteredAccounts?.length ? filteredAccounts.length - files.length : 0;

  return (
    <ConfirmationDialog
      autoCloseOnConfirm={false}
      open={open}
      title="Already have a notary?"
      content={
        isLoading ? (
          <Preloader minHeight={400} />
        ) : (
          <>
            <Typography mb={2.25}>
              Simply download the form below, get it notarized, and upload the notarized version here.
            </Typography>
            {filteredAccounts && filteredAccounts.length > 0 && <DownloadForm1583 accounts={filteredAccounts} />}
            <Box mb={3} mt={3}>
              <Typography component="span">
                Before uploading, please make sure your form has following details
              </Typography>
              <Typography color={theme.palette.error.main}>or it will be rejected:</Typography>
              <List sx={{ padding: theme.spacing(0) }}>
                {detailsList?.map((detail, index) => (
                  <IconListItem
                    sx={{ padding: theme.spacing(1, 0, 0, 0) }}
                    iconColor={theme.palette.success.main}
                    key={index}
                    icon={<DoneIcon />}
                  >
                    <Typography color="text.primary" component="span">
                      {detail}
                    </Typography>
                  </IconListItem>
                ))}
              </List>
            </Box>
            {files.map(({ file, userId }, index) => (
              <FileRow
                key={index}
                file={file}
                onRemove={handleRemove(index)}
                userId={userId}
                onChange={handleSelectAccount(index)}
                usersList={filteredAccounts || []}
              />
            ))}
            {maxFiles ? (
              <DropZone
                onAccept={handleAccept}
                multiple={true}
                maxFiles={maxFiles}
                borderStyle="dashed"
                icon={<UploadIcon />}
                backgroundColor={theme.palette.background.paper}
                accept={{
                  'application/pdf': ['.pdf'],
                }}
              />
            ) : null}
          </>
        )
      }
      isLoading={isUploading}
      disabled={isSubmitDisabled || isUploading}
      confirmText="Upload Form(s)"
      onConfirm={handleUpload}
      onClose={closeDialog}
    />
  );
}
