import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import { useDebouncedValue } from '../hooks';

export type SearchBarProps = {
  placeholder?: string;
  initialValue?: string;
  onChange: (q: string) => void;
  isLoading?: boolean;
  debounce?: number;
};

export function SearchBar({ onChange, initialValue = '', placeholder, isLoading, debounce = 300 }: SearchBarProps) {
  const { handleChange, currentValue, reset } = useDebouncedValue<string>({
    value: initialValue,
    delay: debounce,
    onChange,
  });

  const handleClearInput = () => {
    handleChange('');
    reset();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e.target.value);
  };
  return (
    <TextField
      fullWidth
      onChange={handleInputChange}
      value={currentValue}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="medium" />
          </InputAdornment>
        ),
        endAdornment: isLoading ? (
          <InputAdornment position="end">
            <CircularProgress size={24} />
          </InputAdornment>
        ) : currentValue ? (
          <InputAdornment position="end" onClick={handleClearInput}>
            <CloseIcon fontSize="medium" />
          </InputAdornment>
        ) : undefined,
      }}
      variant="outlined"
    />
  );
}

export default SearchBar;
