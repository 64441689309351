import { Box, CircularProgress, Typography, styled, useTheme } from '@mui/material';

import { CopyAddressButton, FlexBox, InfoTitle } from '@usgm/shared-ui';
import { useAuth } from '../../../auth/hooks/useAuth';
import { useUserWarehouseDetails } from '../../hooks/useUserWarehouseDetails';
import { ChangeAddressButton } from './ChangeAddressButton';

const Card = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(2.5, 2),
  minHeight: 172,
  marginTop: theme.spacing(6),
}));

const Preloader = styled(Card)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 172,
}));

export function UserAddressCard({ userUUID }: { userUUID: string }) {
  const { isLoading, data } = useUserWarehouseDetails(userUUID);
  const theme = useTheme();
  const { isTeamMember } = useAuth();

  if (isLoading) {
    return (
      <Preloader width="100%" height="100%" justifyContent="center">
        <CircularProgress />
      </Preloader>
    );
  }

  if (!data) return null;
  const { boxNumber, stringifiedAddress, warehouseAddress } = data;
  return (
    <Card>
      <Typography color={theme.customColors.dark[300]}>Here’s your US Global Mail address:</Typography>
      <Box mt={0.5}>
        <Typography fontWeight="bold" component="span">
          {warehouseAddress.addressLine}{' '}
        </Typography>
        <InfoTitle
          fontWeight="bold"
          color={theme.customColors.dark[300]}
          description="You can replace PMB with Suite, Apt., Unit etc. when sharing your address, except when changing the address with USPS."
          title={`PMB ${boxNumber}`}
        />

        <Typography fontWeight="bold">
          {warehouseAddress.addressLine2} {warehouseAddress.addressLine3} {warehouseAddress.city},{' '}
          {warehouseAddress.state} {warehouseAddress.postalCode}, {warehouseAddress.country}
        </Typography>
        <FlexBox mt={2}>
          {!isTeamMember ? <ChangeAddressButton warehouseAddress={warehouseAddress} boxNumber={boxNumber} /> : <Box />}
          <CopyAddressButton address={stringifiedAddress} />
        </FlexBox>
      </Box>
    </Card>
  );
}
