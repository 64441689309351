import { Box, Theme, styled } from '@mui/material';
import { Accept, useDropzone } from 'react-dropzone';

import { inboxHelpers } from '@usgm/utils';
import { Placeholder } from './Placeholder';

type DropZoneStyledProps = {
  disabled?: boolean;
  isFocused: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
  backgroundColor?: string;
  borderStyle?: string;
};

const getBorderStyle = (
  { isFocused, isDragAccept, isDragReject, borderStyle = 'solid' }: DropZoneStyledProps,
  theme: Theme,
) => {
  let color = theme.palette.divider;
  if (isFocused) {
    color = theme.palette.primary.main;
  }
  if (isDragAccept) {
    color = theme.palette.success.main;
  }
  if (isDragReject) {
    color = theme.palette.error.main;
  }

  return `1px ${borderStyle} ${color}`;
};

const DropZoneContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    !(
      ['isFocused', 'isDragAccept', 'isDragReject', 'disabled', 'backgroundColor', 'borderStyle'] as PropertyKey[]
    ).includes(prop),
})<DropZoneStyledProps>(({ theme, isFocused, isDragAccept, isDragReject, disabled, backgroundColor, borderStyle }) => ({
  pointerEvents: disabled ? 'none' : 'auto',
  cursor: disabled ? 'not-allowed' : 'pointer',
  border: getBorderStyle({ isFocused, isDragAccept, isDragReject, borderStyle }, theme),
  transition: theme.transitions.create('border', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  position: 'relative',
  padding: theme.spacing(2, 3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 196,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: backgroundColor || theme.palette.background.base,
  width: '100%',
}));

export type DocumentDropzoneProps = {
  accept?: Accept;
  title?: string;
  maxSize?: number;
  multiple?: boolean;
  maxFiles?: number;
  onAccept?: (files: File[]) => void;
  placeholder?: React.ReactNode;
  disabled?: boolean;
  backgroundColor?: string;
  borderStyle?: string;
  icon?: React.ReactNode;
};

export function DropZone({
  accept = inboxHelpers.DEFAULT_FILE_ACCEPT,
  backgroundColor,
  disabled,
  multiple = false,
  maxFiles = 1,
  maxSize = inboxHelpers.MAX_FILE_UPLOAD_SIZE,
  onAccept,
  placeholder,
  borderStyle,
  icon,
}: DocumentDropzoneProps) {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        onAccept?.(acceptedFiles);
      }
    },
    multiple,
    maxSize,
    maxFiles,
    accept,
  });

  return (
    <DropZoneContainer
      borderStyle={borderStyle}
      backgroundColor={backgroundColor}
      disabled={disabled}
      {...getRootProps({ isFocused, isDragAccept, isDragReject })}
    >
      <input {...getInputProps()} />
      {placeholder ? (
        placeholder
      ) : (
        <Placeholder icon={icon} maxFileSize={maxSize} accept={Object.values(accept).join(', ')} />
      )}
    </DropZoneContainer>
  );
}
