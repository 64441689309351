import { inboxHelpers } from '@usgm/utils';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

export function AuthRequiredRoute() {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to={inboxHelpers.MAIN_PATHS.LOGIN} replace />;
  }
  return <Outlet />;
}

export default AuthRequiredRoute;
