import { DtoExportItem } from '@usgm/inbox-api-types';
import { cellHelpers, EntityCell, EntityCellProps } from '@usgm/shared-ui';
import ExportActionCell from '../../ExportActionCell';

export type ExportInfoCellProps = {
  data: DtoExportItem;
};

const constructExportInfo = (data: DtoExportItem): EntityCellProps['infoList'] => {
  return [
    {
      title: 'Download',
      value: <ExportActionCell row={data} />,
    },
    {
      title: 'Created On',
      value: cellHelpers.formatDateString(data.createdOn),
    },
    {
      title: 'Expires On',
      value: data.expiresOn?.humanDate || '-',
    },
  ];
};

function ExportInfoCell({ data }: ExportInfoCellProps) {
  return <EntityCell infoList={constructExportInfo(data)} />;
}

export default ExportInfoCell;
