import { Badge, Button, IconButton, Theme, Typography, styled, useMediaQuery } from '@mui/material';

import {
  DataTable,
  DataTableProps,
  ImageCell,
  RenderBatchActionsParams,
  TextCell,
  Toast,
  Tooltip,
  cellHelpers,
  dataTableConfig,
  useGetImageRowHeight,
  useMobileMode,
} from '@usgm/shared-ui';

import PolicyIcon from '@mui/icons-material/Policy';
import FiltersIcon from '../../../assets/filters.svg?react';
import NoEmailsIcon from '../../../assets/noMailsIcon.svg?react';

import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import { FlexBox } from '@usgm/shared-ui';

import { MAIL_ITEM_TYPE_MAPPER, MailType, ScanStatus, type BaseMail } from '@usgm/inbox-api-types';
import { inboxHelpers } from '@usgm/utils';
import DynamicSidebar from '../../../components/DynamicSidebar';
import { DynamicSidebarPath, useDynamicSidebar } from '../../../components/DynamicSidebar/useDynamicSidebar';

import { useCallback, useMemo } from 'react';
import { useGetMailsListQuery, useUpdateIsReadStatusMutation } from '../api';
import { useMailsListParams } from '../hooks/useMailsListParams';
import { useFolderManagement } from '../mailFolders/useFolderManagement';
import { createMailsPath } from '../paths';
import FolderLink from './FolderLink';
import MailActions from './MailInfo/MailActions';
import MailsFilters from './filters';

import { useNavigatePreservingParams } from '../../../hooks/useNavigatePreservingParams';
import FolderName from '../mailFolders/FolderName';
import ActionsCell from './ActionsCell';
import MailInfoCell from './MailInfoCell';
import ScanInfoIndicator from './ScanInfoIndicator';

const isSelectableRow = (row: BaseMail) => row.scanInfo?.status !== ScanStatus.InProcess;

const MAILS_SELECTION_COLUMN: GridColDef = {
  ...GRID_CHECKBOX_SELECTION_COL_DEF,
  renderCell: (params: GridRenderCellParams<BaseMail>) => {
    return (
      <Tooltip
        title={
          !isSelectableRow(params.row)
            ? 'This item is in a scan request. Once the scan is complete, this option will be enabled'
            : undefined
        }
      >
        <FlexBox flexDirection="column">
          {GRID_CHECKBOX_SELECTION_COL_DEF.renderCell && GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(params)}
          {params.row.mailStatus === 'LOOKUP_REQUIRED' && (
            <Tooltip title="This piece of mail came in with an incomplete address. Please update your address with the sender.">
              <IconButton color="secondary">
                <PolicyIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          )}
        </FlexBox>
      </Tooltip>
    );
  },
};
const MAILS_COLUMNS: GridColDef[] = [
  MAILS_SELECTION_COLUMN,
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    ...dataTableConfig.DEFAULT_IMAGE_CELL_PARAMS,
    field: 'imageUrl',
    headerName: 'Item Image',

    renderCell: (params: GridRenderCellParams<BaseMail>) => {
      return <ImageCell enablePreview imageUrl={params.row.imageUrl} />;
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'id',
    minWidth: 100,
    maxWidth: 150,
    headerName: 'Item Number',
    renderCell: (params: GridRenderCellParams<BaseMail>) => {
      return (
        <TextCell>
          <Typography>{params.row.id}</Typography>
          {params.row.scanInfo?.uuid && (
            <FlexBox mt={1} justifyContent="center">
              <ScanInfoIndicator scanInfo={params.row.scanInfo} />
            </FlexBox>
          )}
        </TextCell>
      );
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'folderId',
    headerName: 'Folder',
    maxWidth: 200,
    minWidth: 100,
    valueGetter: (value: BaseMail['mailFolder']) => {
      return value?.id;
    },
    renderCell: (params: GridRenderCellParams<BaseMail>) => {
      return (
        <TextCell>
          <FolderLink folder={params.row.mailFolder} />
        </TextCell>
      );
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'mailTypeLabel',
    maxWidth: 200,
    headerName: 'Item Type',
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    maxWidth: 150,
    field: 'measurement',
    headerName: 'Measurements',
    valueGetter: cellHelpers.formatMeasurement<BaseMail['measurement']>,
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'weight',
    headerName: 'Weight',
    maxWidth: 100,
    valueGetter: cellHelpers.formatWeight,
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'trackingNumber',
    headerName: 'Tracking',
    maxWidth: 200,
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'arrivalDate',
    headerName: 'Arrived',
    minWidth: 340,
    renderCell: (params: GridRenderCellParams<BaseMail>) => {
      return <ActionsCell moreVariant="icon" data={params} />;
    },
  },
] as const;

const MAILS_MOBILE_COLUMNS: GridColDef[] = [
  MAILS_SELECTION_COLUMN,
  {
    field: 'mailInfo',
    headerName: 'info',
    flex: 1,
    renderCell: (params: GridRenderCellParams<BaseMail>) => {
      return <MailInfoCell data={params.row} />;
    },
  },
];

const FiltersButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  height: 'auto',
  minWidth: 'auto',
}));

const SELECTION_FILTERS: DataTableProps['selectionFilters'] = [
  {
    label: 'Read',
    filter: (row) => row.isRead,
  },
  {
    label: 'Unread',
    filter: (row) => !row.isRead,
  },
  {
    label: 'Lookup Required',
    filter: (row) => row.mailStatus === 'LOOKUP_REQUIRED',
  },
  {
    label: 'Unassigned',
    filter: (row) => {
      return !row.mailFolder?.id;
    },
  },
  {
    label: 'Mail Type',
    filters: (Object.keys(MAIL_ITEM_TYPE_MAPPER) as MailType[]).map((key) => {
      return {
        label: MAIL_ITEM_TYPE_MAPPER[key],
        filter: (row) => row.mailType === key,
      };
    }),
  },
];

function MailsList() {
  const navigate = useNavigatePreservingParams();
  const isMobile = useMobileMode();
  const isDownMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const { createFolder, dialogs } = useFolderManagement();

  const { isSelected, toggleSidebar } = useDynamicSidebar(DynamicSidebarPath.Filters);

  const { filter, filtersCount, folderId, limit, skip, tab } = useMailsListParams();

  const { data, isLoading, isFetching, refetch } = useGetMailsListQuery({
    tab,
    folderId,
    limit,
    skip,
    filter,
  });

  const handleAddFolderClick = () => {
    toggleSidebar();
    createFolder();
  };

  const [updateIsReadStatus, markAsReadQuery] = useUpdateIsReadStatusMutation();

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    if ('isRead' in params.row && !params.row.isRead) {
      updateIsReadStatus({ ids: [params.row.id], tab, folderId, limit, skip, filter, isRead: true });
    }
    navigate(createMailsPath({ tab, folderId, itemId: params.row.id }));
  };

  const columns = useMemo(() => {
    const columns = isMobile ? MAILS_MOBILE_COLUMNS : MAILS_COLUMNS;
    return folderId || isMobile ? columns.filter((col) => col.field !== 'folderId') : columns;
  }, [folderId, isMobile]);

  const renderBatchActions = useCallback(
    ({ ids, rows, clearSelection }: RenderBatchActionsParams) => {
      return ids.length > 0 ? (
        <MailActions
          isQuarantine={tab === 'quarantine'}
          clearSelection={clearSelection}
          rows={rows}
          ids={ids as BaseMail['id'][]}
          moreVariant={isMobile ? 'icon' : 'text'}
          shortButtons={isMobile}
        />
      ) : null;
    },
    [isMobile, tab],
  );

  const isRowSelectable = useCallback(({ row }: GridRowParams<BaseMail>) => {
    return isSelectableRow(row);
  }, []);

  const getRowHeight = useGetImageRowHeight(isMobile);
  const columnVisibilityModel = useMemo(
    () => ({
      trackingNumber: isDownMd ? false : true,
    }),
    [isDownMd],
  );
  return (
    <>
      {!isMobile && <FolderName />}
      <DataTable
        columnVisibilityModel={columnVisibilityModel}
        getRowHeight={getRowHeight}
        onRowClick={handleRowClick}
        onRefresh={refetch}
        entityName="mail"
        placeholderIcon={<NoEmailsIcon />}
        totalRows={data?.count}
        loading={isFetching}
        columns={columns}
        rows={data?.list ?? []}
        isLoading={isLoading}
        selectionFilters={SELECTION_FILTERS}
        renderBatchActions={renderBatchActions}
        enableUnread
        multiSelect
        disableHeaderCheckbox
        isRowSelectable={isRowSelectable}
        headerLeft={
          !folderId && tab !== 'quarantine' ? (
            <Badge badgeContent={filtersCount} color="primary">
              <FiltersButton onClick={toggleSidebar} variant="outlined">
                <FiltersIcon />
              </FiltersButton>
            </Badge>
          ) : undefined
        }
      />
      {dialogs}
      <DynamicSidebar open={isSelected} handleClose={toggleSidebar} path={DynamicSidebarPath.Filters} title="Filters">
        <MailsFilters onAddFolderClick={handleAddFolderClick} />
      </DynamicSidebar>
      {markAsReadQuery.error ? (
        <Toast title={markAsReadQuery.error?.message || inboxHelpers.GENERIC_ERROR_MESSAGE} severity="error" />
      ) : null}
    </>
  );
}

export default MailsList;
