import { Box, styled, Typography } from '@mui/material';
import WestIcon from '@mui/icons-material/West';
import { FlexBox, Inbox, TextButton } from '@usgm/shared-ui';
import * as React from 'react';
import { ReactNode } from 'react';

const Root = styled(Inbox.CardContent, { shouldForwardProp: (prop) => prop !== 'hasChildren' })<{
  hasChildren?: boolean;
}>(({ theme, hasChildren }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  minHeight: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),

  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    flexDirection: hasChildren ? 'column' : 'row',
    gap: theme.spacing(2),
  },
}));

export function CardHeader({
  children,
  title,
  subTitle,
  onBackButtonClick,
}: {
  children?: React.ReactNode;
  title?: string | ReactNode;
  subTitle?: ReactNode;
  onBackButtonClick?: () => void;
}) {
  return (
    <Root hasChildren={!!children}>
      {(title || subTitle) && (
        <Box width="100%">
          <FlexBox>
            {onBackButtonClick && (
              <TextButton onClick={onBackButtonClick} sx={{ minWidth: 'auto', marginRight: 2 }}>
                <WestIcon />
              </TextButton>
            )}
            {React.isValidElement(title) ? (
              title
            ) : (
              <Typography mb={1} variant="h5">
                {title}
              </Typography>
            )}
          </FlexBox>
          {React.isValidElement(subTitle) ? subTitle : <Typography maxWidth={800}>{subTitle}</Typography>}
        </Box>
      )}
      {children}
    </Root>
  );
}
