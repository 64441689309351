import { useContext } from 'react';
import { RecaptchaContext } from './RecaptchaContext';

export const useRecaptchaContext = () => {
  const context = useContext(RecaptchaContext);
  if (!context) {
    throw new Error('useRecaptchaContext must be used within a RecaptchaProvider');
  }
  return context;
};
