import { DtoCredit } from '@usgm/inbox-api-types';
import { Preloader } from '@usgm/shared-ui';
import { useGetCreditNoteByIdQuery } from '../api';
import { Box } from '@mui/material';

import { TopActionsContainer } from '../../../components/DynamicSidebar/TopActionsContainer';
import DownloadBillingPdf from './DownloadBillingPdf';
import { ContentContainer } from '../../../components/DynamicSidebar/ContentContainer';
import CreditNoteDetails from './CreditNoteDetails';

export type CreditNoteInfoProps = {
  id: DtoCredit['vendorId'];
};
function CreditNoteInfo({ id }: CreditNoteInfoProps) {
  const { data, isLoading } = useGetCreditNoteByIdQuery({ id });

  return (
    <Box minHeight="100%">
      {isLoading && <Preloader />}
      {data && (
        <>
          <TopActionsContainer>
            <DownloadBillingPdf
              buttonLabel="Download PDF"
              buttonProps={{
                fullWidth: true,
                variant: 'contained',
              }}
              itemId={id}
              invoiceId={data.invoiceId}
              type="credit"
            />
          </TopActionsContainer>
          <ContentContainer>
            <CreditNoteDetails data={data} />
          </ContentContainer>
        </>
      )}
    </Box>
  );
}

export default CreditNoteInfo;
