import { Typography } from '@mui/material';
import { BaseMail } from '@usgm/inbox-api-types';
import { ConfirmationDialogProps, ConfirmationDialog } from '@usgm/shared-ui';
import { useUpsertShippingRequest } from './mailsActionRequests/shipment/hooks/useUpsertShippingRequest';

export type ConfirmDiscardsDialogProps = Omit<ConfirmationDialogProps, 'content'> & {
  ids: BaseMail['id'][];
  message?: string;
};
function ConfirmDiscardsDialog({ ids, message, ...dialogProps }: ConfirmDiscardsDialogProps) {
  const { upsertQuery, upsertMailsShippingRequest } = useUpsertShippingRequest('');
  const plural = ids.length > 1 ? 's' : '';
  const modalMessage = message || `Are you sure you want to return selected item${plural}?`;

  const handleConfirm = () => {
    upsertMailsShippingRequest({ showSuccessMessage: true });
  };

  return (
    <ConfirmationDialog
      {...dialogProps}
      onConfirm={handleConfirm}
      content={
        <Typography textAlign="center" variant="h5">
          {modalMessage}
        </Typography>
      }
      confirmText="Yes"
      cancelText="No"
      isLoading={upsertQuery.isLoading}
    />
  );
}

export default ConfirmDiscardsDialog;
