import { CircularProgress } from '@mui/material';
import { FlexBox } from '@usgm/shared-ui';

export type PreloaderProps = {
  minHeight?: number;
};

export default function Preloader({ minHeight = 400 }: PreloaderProps) {
  return (
    <FlexBox minHeight={minHeight} justifyContent="center">
      <CircularProgress />
    </FlexBox>
  );
}
