import { DataTable, useGetImageRowHeight, useMobileMode, usePaginationParams } from '@usgm/shared-ui';

import NoShipmentsIcon from '../../../assets/noShipments.svg?react';

import { useParams } from 'react-router-dom';

import { GridEventListener } from '@mui/x-data-grid';
import { SHIPMENTS_FILTERS, TShipmentFilters } from '@usgm/inbox-api-types';
import { useMemo } from 'react';
import { useGenericFilters } from '../../../components/filters/useGenericFilters';
import { useNavigatePreservingParams } from '../../../hooks/useNavigatePreservingParams';
import { useGetShipmentsListQuery } from '../api';
import { COMPLETED_SHIPMENTS_COLUMNS } from '../gridConfig/completed';
import { getMobileColumns } from '../gridConfig/getMobileColumns';
import { PENDING_SHIPMENTS_COLUMNS } from '../gridConfig/pending';
import { ShipmentPathParams, createShipmentsPath } from '../paths';
import ShipmentsCategoryFilter from './ShipmentsCategoryFilter';

function ShipmentsList() {
  const isMobile = useMobileMode();
  const navigate = useNavigatePreservingParams();
  const { tab = 'pending' } = useParams<ShipmentPathParams>();
  const { limit, skip } = usePaginationParams();
  const { getAllFilters } = useGenericFilters<TShipmentFilters>(SHIPMENTS_FILTERS);

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    navigate(createShipmentsPath({ itemId: params.row.uuid, tab }));
  };

  const { data, isLoading, isFetching, refetch } = useGetShipmentsListQuery({
    tab: tab,
    limit,
    skip,
    filter: getAllFilters(),
  });

  const columns = useMemo(() => {
    if (isMobile) {
      return getMobileColumns(tab === 'pending');
    }
    return tab === 'completed' ? COMPLETED_SHIPMENTS_COLUMNS : PENDING_SHIPMENTS_COLUMNS;
  }, [isMobile, tab]);

  const getRowHeight = useGetImageRowHeight(isMobile);

  return (
    <DataTable
      onRowClick={handleRowClick}
      checkboxSelection={false}
      headerLeft={<ShipmentsCategoryFilter />}
      disableColumnSelector
      onRefresh={refetch}
      entityName="shipments"
      placeholderIcon={<NoShipmentsIcon />}
      getRowHeight={getRowHeight}
      totalRows={data?.count}
      loading={isFetching}
      columns={columns}
      rows={data?.list ?? []}
      getRowId={(row) => row.uuid}
      isLoading={isLoading}
    />
  );
}

export default ShipmentsList;
