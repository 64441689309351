import { Box, styled } from '@mui/material';
import { createScrollingStyles } from './ScrollableArea';

export const StickyTabPageContent = styled(Box)(({ theme }) => ({
  ...createScrollingStyles({ theme, thumbSize: 6 }),
  overflowY: 'auto',
  padding: theme.spacing(6, 4.5),
  maxHeight: 'calc(100% - 60px)',
  marginTop: theme.spacing(7.5),
  height: '100%',

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 0),
  },
}));
