import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { useMailRequestActionParam } from '../../../../../hooks/useMailRequestActionParam';

import { depositSlipSchema, DepositSlipSchemaType } from './schemas';

import StepHeader from '../StepHeader';

import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useGetBoxNumberByUserUuidQuery } from '../../../../../../../../../api/accountApi';
import { useAppDispatch, useAppSelector } from '../../../../../../../../../store';
import { useAuth } from '../../../../../../../../auth/hooks/useAuth';
import { ContentContainer } from '../../../../../../../components/DynamicSidebar/ContentContainer';
import ActionButtons from '../../ActionButtons';
import {
  mailsShipmentSlice,
  mailsToShipSelectors,
  selectBankAccount,
  selectDepositCheckInstructions,
} from '../../mailsShipmentSlice';
import DepositSlipForm from './DepositSlipForm';

function DepositSlip() {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { cancelRequest } = useMailRequestActionParam();
  const boxNumberQuery = useGetBoxNumberByUserUuidQuery(user?.userUUID || '', {
    skip: !user,
  });

  const mailIds = useAppSelector(mailsToShipSelectors.selectIds);
  const bankAccount = useAppSelector(selectBankAccount);
  const depositCheckInstructions = useAppSelector(selectDepositCheckInstructions);

  const defaultValues = useMemo(() => {
    const data: Partial<DepositSlipSchemaType> = {
      items: mailIds,
      instructions: depositCheckInstructions || '',
    };
    if (boxNumberQuery.data?.box) {
      data.boxNumber = boxNumberQuery.data.box.warehouseBoxNumber;
    }
    return data;
  }, [boxNumberQuery.data?.box, mailIds, depositCheckInstructions]);

  const formMethods = useForm<DepositSlipSchemaType>({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(depositSlipSchema),
  });

  const handleNextClick = () => {
    dispatch(mailsShipmentSlice.actions.setDepositCheckInstructions(formMethods.getValues('instructions')));
  };

  const handleCancelShipment = () => {
    dispatch(mailsShipmentSlice.actions.resetState());
    cancelRequest();
  };

  const canProceed = !!bankAccount;

  return (
    <>
      <ContentContainer pt={0} pb={11.75}>
        <StepHeader />
        <Typography mb={4}>Please fill out the deposit slip details below</Typography>
        <FormProvider {...formMethods}>
          <DepositSlipForm />
        </FormProvider>
      </ContentContainer>
      <ActionButtons
        canProceed={canProceed}
        onNextClick={handleNextClick}
        backLabel="Cancel"
        onBackClick={handleCancelShipment}
      />
    </>
  );
}

export default DepositSlip;
