import { DateTime } from 'luxon';
import { isShipmentAvailableDate } from './isShipmentAvailableDate';
import { adjustDateForShipmentCutoffOrNonBusiness } from './adjustDateForShipmentCutoffOrNonBusiness';

export function getClosestShipmentDate() {
  const now = DateTime.now();
  if (isShipmentAvailableDate(now)) {
    return now;
  }

  return adjustDateForShipmentCutoffOrNonBusiness(
    now.plus({ days: 1 }).set({ hour: 0, minute: 0, second: 0 }),
  ).toLocal();
}
