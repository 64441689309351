import * as React from 'react';
import { forwardRef, useImperativeHandle } from 'react';
import { useAppSelector } from '../../../../../store';
import { selectPickedPlan, selectPlanTerm } from '../../../../planPicker/planPickerSlice';
import { selectPickedWarehouseData } from '../../../../addressPicker/addressPickerSlice';
import { Box, Button, styled, Typography, useTheme } from '@mui/material';
import { FlexBox } from '@usgm/shared-ui';
import { useAuth } from '../../../../auth/hooks/useAuth';
import ScanBundleNotAvailableWarningBox from '../../../components/ScanBundleNotAvailableWarningBox';
import ChangeAddressConfirmationDialog from '../../../components/UserAddressBox/ChangeAddressConfirmationDialog';
import { inboxHelpers, pascalCase } from '@usgm/utils';
import { UpdateWarehouseParams, PlanTerm } from '@usgm/inbox-api-types';
import PageLoader from '../../../../../components/PageLoader';
import { useUserScanBundleData } from '../../settings/hooks/useUserScanBundleData';

const ConfirmationContentText = styled(Typography)(({ theme }) => ({
  color: theme.customColors.dark[300],
})) as typeof Typography;

function ConfirmationStep(
  {
    handleClose,
    handleConfirm,
    updateWarehouseLoading,
    isDesktop,
  }: {
    handleClose: () => void;
    handleConfirm: (props: UpdateWarehouseParams) => void;
    updateWarehouseLoading: boolean;
    isDesktop: boolean;
  },
  ref: React.ForwardedRef<{ onConfirm: () => void }>,
) {
  const theme = useTheme();
  const { user } = useAuth();
  const selectedPlan = useAppSelector(selectPickedPlan);
  const planTerm = useAppSelector(selectPlanTerm);
  const selectedAddress = useAppSelector(selectPickedWarehouseData);
  const addressValue = selectedAddress
    ? `${selectedAddress.address.addressLine}, ${selectedAddress.address.name}, ${selectedAddress.address.country}, ${selectedAddress.address.postalCode}`
    : '';

  const membership = selectedPlan?.membership.items.find((i) => i.months === (planTerm === PlanTerm.Annually ? 12 : 1));

  const { isLoading, activeScanBundle } = useUserScanBundleData();

  const onConfirm = () => {
    const termId = planTerm === PlanTerm.Annually ? 2 : 1;
    handleConfirm({ termId, warehouseId: selectedAddress?.id, userId: user?.userUUID, planId: selectedPlan?.id });
  };

  useImperativeHandle(ref, () => ({
    onConfirm,
  }));

  // @TODO: Move this to inbox helpers and import it here and in PlanChangeDialog.tsx
  const shouldShowScanBundleWarning = () => {
    return selectedPlan && !selectedPlan.otherOptions?.isScanBundlesSupported && activeScanBundle;
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <FlexBox flexDirection="column">
      <Box
        sx={
          isDesktop
            ? {
                p: 4,
                border: '1px solid #DBE2EB',
                width: 'auto',
                borderRadius: theme.spacing(0.5),
                backgroundColor: theme.palette.background.default,
              }
            : null
        }
      >
        <Typography mb={2} color="text.primary">
          Please review your selection and confirm the address change
        </Typography>
        <Box sx={!isDesktop ? { p: 2 } : null}>
          <Box>
            <Typography fontWeight="600" color="text.primary" component="span" mr={1}>
              Address:
            </Typography>
            <ConfirmationContentText component="span">{addressValue}</ConfirmationContentText>
          </Box>
          <Box mt={1}>
            <Typography fontWeight="600" component="span" mr={1}>
              Selected plan:
            </Typography>
            <ConfirmationContentText component="span">
              {selectedPlan && pascalCase(selectedPlan.name)}
            </ConfirmationContentText>
          </Box>
          <Box mt={1}>
            <Typography fontWeight="600" component="span" mr={1}>
              Selected term:
            </Typography>
            <ConfirmationContentText component="span">
              Pay {membership && inboxHelpers.formatPrice(membership.price)}/month, {planTerm}
            </ConfirmationContentText>
          </Box>
        </Box>
      </Box>
      {shouldShowScanBundleWarning() && <ScanBundleNotAvailableWarningBox />}
      {!isDesktop && (
        <FlexBox gap={2} mt={2} width="100%">
          <Button variant="outlined" onClick={handleClose} sx={{ flex: 1 }}>
            Cancel
          </Button>
          <ChangeAddressConfirmationDialog onHandleConfirmation={onConfirm} isLoading={updateWarehouseLoading} />
        </FlexBox>
      )}
    </FlexBox>
  );
}

export default forwardRef(ConfirmationStep);
