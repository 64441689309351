import { MouseEvent, useCallback, useState } from 'react';

export function useAnchorEl<T>() {
  const [anchorEl, setAnchorEl] = useState<null | T>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((e: MouseEvent<T>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);
  const handleClose = useCallback(() => setAnchorEl(null), []);

  return { anchorEl, open, handleClick, handleClose };
}
