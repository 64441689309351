import { Box, styled, Typography } from '@mui/material';
import { FlexBox, IOSSwitch } from '@usgm/shared-ui';
import { ChangeEvent } from 'react';

const StyledControlTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: 1.25 * theme.typography.fontSize,
}));

export type SettingFeatureSwitcherProps = {
  title?: string;
  description?: string;
  isLoading?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  checked?: boolean;
  defaultChecked?: boolean;
};

function SettingFeatureSwitcher({
  title,
  description,
  onChange,
  isLoading,
  disabled,
  checked,
  defaultChecked,
}: SettingFeatureSwitcherProps) {
  const handleChange = (_: ChangeEvent, checked: boolean) => {
    onChange?.(checked);
  };
  return (
    <FlexBox justifyContent={'space-between'} width="100%">
      <Box>
        <StyledControlTitle>{title}</StyledControlTitle>
        <Typography>{description}</Typography>
      </Box>
      <IOSSwitch
        variant={'large'}
        onChange={handleChange}
        disabled={disabled || isLoading}
        checked={checked}
        defaultChecked={defaultChecked}
      />
    </FlexBox>
  );
}

export default SettingFeatureSwitcher;
