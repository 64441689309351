import { UserWarehouseAddress } from '@usgm/inbox-api-types';
import { ReactNode } from 'react';
import DateStep from './DateStep';
import DeclarationsStep from './DeclarationsStep';
import DestinationStep from './DestinationStep';
import ShipperStep from './ShipperStep';
import DepositSlip from './DepositSlip';

// @TODO delete this and separate stepper from shipment
export type ShipmentData = {
  destination: UserWarehouseAddress | null;
  shipper: string | null;
  details: string | null;
  depositSlip: string | null;
  declaration: {
    item: string;
    value: number;
  } | null;
};

export type ShipmentDataKey = keyof ShipmentData;

type ShipmentStep<T extends ShipmentDataKey> = {
  key: T;
  title: string;
  component?: ReactNode;
  errorMessage?: string;
  header: string;
  isValid: boolean;
};

export type ShipmentContextType = {
  steps: Map<ShipmentDataKey, ShipmentStep<ShipmentDataKey>>;
  activeStepIndex: number;
  setActiveStepIndex: (index: number) => void;
  activeStep: ShipmentStep<ShipmentDataKey>;
  stepsList: Array<ShipmentStep<ShipmentDataKey>>;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
};

const DESTINATION_STEP: ShipmentStep<'destination'> = {
  key: 'destination',
  title: 'Destination',
  component: <DestinationStep />,
  errorMessage: 'Please select address to continue',
  header: 'Select a destination',
  isValid: false,
};

const SHIPPER_STEP: ShipmentStep<'shipper'> = {
  key: 'shipper',
  title: 'Shipper',
  component: <ShipperStep />,
  errorMessage: 'Please select a shipper to continue',
  header: 'Select a shipper',
  isValid: false,
};

const DATE_STEP: ShipmentStep<'details'> = {
  key: 'details',
  title: 'Details',
  component: <DateStep />,
  errorMessage: 'Please select a date to continue',
  header: 'Shipment Details',
  isValid: false,
};

const DECLARATIONS_STEP: ShipmentStep<'declaration'> = {
  key: 'declaration',
  title: 'Declaration',
  component: <DeclarationsStep />,
  errorMessage: 'Please add declarations for all items to continue',
  header: 'Customs declarations',
  isValid: false,
};

export const DEPOSIT_SLIP_STEP: ShipmentStep<'depositSlip'> = {
  key: 'depositSlip',
  title: 'Deposit Slip',
  component: <DepositSlip />,
  errorMessage: 'Please complete the from to continue',
  header: 'Deposit Slip',
  isValid: false,
};

export const ALL_SHIPMENT_STEPS = [DEPOSIT_SLIP_STEP, DESTINATION_STEP, DECLARATIONS_STEP, SHIPPER_STEP, DATE_STEP];
