import { useTheme } from '@mui/material';
import { OrgUserStatus } from '@usgm/inbox-api-types';
import { cellHelpers, Inbox, useDialogs } from '@usgm/shared-ui';
import { pascalCase } from '@usgm/utils';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../../../../store';
import { authSlice } from '../../../../../auth/authSlice';

import { useLazyCheckMfaStatusQuery, useLazyGetImportantDatesQuery } from '../../api';

import { useCurrentUser } from '../../../../../auth/hooks/useCurrentUser';
import { DynamicSidebarPath, useDynamicSidebar } from '../../../../components/DynamicSidebar/useDynamicSidebar';
import { useUserWarehouseDetails } from '../../../../hooks/useUserWarehouseDetails';
import { CardHeader, CardItem } from '../../components/Card';
import CloseAccountDialog from '../../components/CloseAccountDialog';
import EmailUpdateDialog from '../../components/EmailUpdateDialog';
import NameUpdateDialog from '../../components/NameUpdateDialog';
import PasswordUpdateDialog from '../../components/PasswordUpdateDialog';
import TwoStepAuthDialog from '../../components/TwoStepAuthDialog';
import AddressPage from './AddressPage';

enum AccountDetailsDialogs {
  EMAIL_UPDATE = 'email-update',
  NAME_UPDATE = 'name-update',
  TWO_STEP_AUTH = 'two-step-auth',
  PASSWORD_UPDATE = 'password-update',
  CLOSE_ACCOUNT = 'close-account',
}

export default function AccountDetailsPage() {
  const { user, isTeamMember } = useCurrentUser();
  const dispatch = useAppDispatch();

  const { data: addressData } = useUserWarehouseDetails(user.userUUID);
  const { isSelected, toggleSidebar } = useDynamicSidebar(DynamicSidebarPath.ChangeAddress);

  const [triggerMfaCheck, { data: mfaData, isFetching: isFetchingMfaStatus }] = useLazyCheckMfaStatusQuery();
  const [getImportantDates, { data: datesData }] = useLazyGetImportantDatesQuery();
  const theme = useTheme();
  const { dialogList, openDialog, closeDialog } = useDialogs([
    AccountDetailsDialogs.EMAIL_UPDATE,
    AccountDetailsDialogs.NAME_UPDATE,
    AccountDetailsDialogs.TWO_STEP_AUTH,
    AccountDetailsDialogs.PASSWORD_UPDATE,
    AccountDetailsDialogs.CLOSE_ACCOUNT,
  ]);

  useEffect(() => {
    triggerMfaCheck();
    user && !isTeamMember && getImportantDates(user.userUUID);
  }, [getImportantDates, triggerMfaCheck, user, isTeamMember]);

  const handleAccountClosure = (status?: OrgUserStatus) => {
    closeDialog(AccountDetailsDialogs.CLOSE_ACCOUNT);
    if (status === OrgUserStatus.Active) {
      dispatch(authSlice.actions.logout());
    }
  };

  const handleAddressChange = () => {
    toggleSidebar();
  };

  const accountStatusValue = datesData?.scheduledAccountClosureDate
    ? `Scheduled to be closed on ${cellHelpers.formatDateString(datesData?.scheduledAccountClosureDate)}`
    : `Active, renews on ${cellHelpers.formatDateString(datesData?.subscriptionEndDate)}`;

  if (isSelected && !isTeamMember) {
    return <AddressPage />;
  }

  return (
    <>
      <Inbox.PageContainer>
        <Inbox.Card noSpacings>
          <CardHeader
            title="Manage Account Details"
            subTitle="Handle your personal details, security settings, and US Global Mail address. Update your email, password, and name, and enable two-step verification for enhanced security."
          />

          <Inbox.CardContent>
            <CardItem
              label="Email"
              value={user.email}
              actionText="Change"
              actionHandler={() => openDialog(AccountDetailsDialogs.EMAIL_UPDATE)}
            />
            <CardItem
              label="Full Name"
              value={user.name}
              actionText="Change"
              actionHandler={() => openDialog(AccountDetailsDialogs.NAME_UPDATE)}
            />
            <CardItem
              label="Password"
              value="**********"
              actionText="Change"
              actionHandler={() => openDialog(AccountDetailsDialogs.PASSWORD_UPDATE)}
            />
            <CardItem
              label="2-Step Verification"
              value={pascalCase(mfaData?.data?.mfaStatus || '')}
              valueColor={mfaData?.data?.mfaStatus === 'enabled' ? theme.palette.success.main : 'inherit'}
              actionText={mfaData?.data?.mfaStatus === 'enabled' ? 'Disable' : 'Enable'}
              actionHandler={() => openDialog(AccountDetailsDialogs.TWO_STEP_AUTH)}
              isLoading={isFetchingMfaStatus}
            />
            {!isTeamMember && (
              <CardItem
                label="Account Status"
                value={accountStatusValue}
                actionText="Close Account"
                actionHandler={() => openDialog(AccountDetailsDialogs.CLOSE_ACCOUNT)}
              />
            )}
            {!isTeamMember && addressData && (
              <CardItem
                label="Your US Mailing Address"
                value={addressData.stringifiedAddress}
                actionText="Change"
                actionHandler={handleAddressChange}
              />
            )}
          </Inbox.CardContent>
        </Inbox.Card>
      </Inbox.PageContainer>

      {dialogList[AccountDetailsDialogs.EMAIL_UPDATE] && (
        <EmailUpdateDialog
          open={dialogList[AccountDetailsDialogs.EMAIL_UPDATE]}
          fullName={user.name}
          email={user.email}
          closeHandler={() => closeDialog(AccountDetailsDialogs.EMAIL_UPDATE)}
          userId={user.userUUID}
        />
      )}
      {dialogList[AccountDetailsDialogs.NAME_UPDATE] && (
        <NameUpdateDialog
          open={dialogList[AccountDetailsDialogs.NAME_UPDATE]}
          email={user.email}
          name={user.name}
          closeHandler={() => closeDialog(AccountDetailsDialogs.NAME_UPDATE)}
          userId={user.userUUID}
        />
      )}
      {dialogList[AccountDetailsDialogs.PASSWORD_UPDATE] && (
        <PasswordUpdateDialog
          open={dialogList[AccountDetailsDialogs.PASSWORD_UPDATE]}
          fullName={user.name}
          email={user.email}
          closeHandler={() => closeDialog(AccountDetailsDialogs.PASSWORD_UPDATE)}
          userId={user.userUUID}
        />
      )}
      {dialogList[AccountDetailsDialogs.TWO_STEP_AUTH] && (
        <TwoStepAuthDialog
          open={dialogList[AccountDetailsDialogs.TWO_STEP_AUTH]}
          closeHandler={() => closeDialog(AccountDetailsDialogs.TWO_STEP_AUTH)}
          userId={user?.userUUID}
          mfaEnabled={mfaData?.data?.mfaStatus === 'enabled'}
          triggerMfaCheck={triggerMfaCheck}
        />
      )}
      {dialogList[AccountDetailsDialogs.CLOSE_ACCOUNT] && (
        <CloseAccountDialog
          open={dialogList[AccountDetailsDialogs.CLOSE_ACCOUNT]}
          userUuid={user?.userUUID}
          closeHandler={handleAccountClosure}
          triggerGetDates={() => user && !isTeamMember && getImportantDates(user.userUUID)}
          scheduledAccountClosureDate={datesData?.scheduledAccountClosureDate}
          closeDialog={() => ({})}
        />
      )}
    </>
  );
}
