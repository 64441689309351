import { AccountRelation } from '@usgm/inbox-api-types';

export function normalizeAccountNameData(
  {
    name,
    relationType,
    uuid,
  }: {
    name: string;
    relationType: AccountRelation;
    uuid?: string;
  },
  isFromAccountVerification = true,
) {
  return {
    name,
    // @TODO: hacky staff because of tech debt in the past
    // backend reference to start cleanup https://gitlab.com/usgm/backend/usgm-accounts/-/blob/master/src/Controllers/UserController.ts#L148
    relation: relationType === AccountRelation.MinorChild ? 'MINOR_CHILD' : relationType,
    is_from_account_verification: isFromAccountVerification,
    regenerate_form1583: true,
    childId: uuid,
    notes: '',
  };
}
