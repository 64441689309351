// TODO this component needs to be refactored
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ModalProps,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import * as React from 'react';
import { ActionButton, Button, CloseButton, FlexBox, useMobileMode } from '../index';
import { BottomSheet } from './BottomSheet';

export type SimpleDialogProps = {
  title?: string;
  description?: string;
  open: boolean;
  children?: React.ReactNode;
  closeHandler: () => void | ModalProps['onClose'];
  minWidth?: number | string;
  primaryButtonText?: React.ReactNode;
  primaryButtonHandler?: () => void;
  isPrimaryButtonTypeSubmit?: boolean;
  isSubmitDisabled?: boolean;
  secondaryButtonText?: string;
  secondaryButtonHandler?: () => void;
  isSecondaryButtonTypeSubmit?: boolean;
  hasMoreMenu?: boolean;
  moreButtonItems?: { handler?: () => void; title?: string }[];
  isForm?: boolean;
  onSubmit?: () => void;
  avoidBottomSheet?: boolean;
  isSubmitting?: boolean;
};

export const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

function MobileDialog({
  open,
  title,
  description,
  isSubmitDisabled = false,
  primaryButtonText,
  primaryButtonHandler,
  isPrimaryButtonTypeSubmit = true,
  secondaryButtonText,
  secondaryButtonHandler,
  isSecondaryButtonTypeSubmit = false,
  hasMoreMenu = false,
  moreButtonItems,
  children,
  closeHandler,
  isForm = false,
  onSubmit,
  isSubmitting,
}: SimpleDialogProps) {
  const theme = useTheme();

  const wrapperProps = isForm
    ? {
        noValidate: true,
        autoComplete: 'off',
        onSubmit: onSubmit,
      }
    : {};

  return (
    <BottomSheet title={title} open={open} closeHandler={closeHandler}>
      {!!title && <Divider sx={{ margin: theme.spacing(2, 0, 3) }} />}
      {description && (
        <Typography fontSize={14} sx={{ textAlign: 'center', margin: (theme) => theme.spacing(2) }}>
          {description}
        </Typography>
      )}
      <Box component={isForm ? 'form' : 'div'} {...wrapperProps} sx={{ overflowY: 'auto' }}>
        <Box>{children}</Box>
        <Divider sx={{ padding: theme.spacing(3, 0, 0) }} />
        {(primaryButtonText || secondaryButtonText) && (
          <FlexBox width="100%" gap={1.5} alignSelf="flex-end" sx={{ padding: (theme) => theme.spacing(2, 0) }}>
            {secondaryButtonText && (
              <FlexBox sx={{ width: hasMoreMenu ? '75%' : primaryButtonText ? '50%' : '100%' }}>
                <Button
                  variant="outlined"
                  fullWidth
                  autoFocus
                  disabled={isSecondaryButtonTypeSubmit && isSubmitDisabled}
                  onClick={secondaryButtonHandler}
                  type={isForm && isSecondaryButtonTypeSubmit ? 'submit' : 'button'}
                >
                  {secondaryButtonText || 'Cancel'}
                </Button>
                {hasMoreMenu && (
                  <ActionButton variant="outlined" moreButtonItems={moreButtonItems} isDisabled={isSubmitDisabled} />
                )}
              </FlexBox>
            )}
            {primaryButtonText && (
              <Button
                sx={{ width: secondaryButtonText ? '50%' : '100%' }}
                variant="contained"
                disabled={isPrimaryButtonTypeSubmit && isSubmitDisabled}
                onClick={primaryButtonHandler}
                type={isForm && isPrimaryButtonTypeSubmit ? 'submit' : 'button'}
                isLoading={isSubmitting}
              >
                {primaryButtonText || 'Confirm'}
              </Button>
            )}
          </FlexBox>
        )}
      </Box>
    </BottomSheet>
  );
}

const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'minWidth',
})<{
  minWidth?: SimpleDialogProps['minWidth'];
}>(({ minWidth }) => ({
  '& .MuiDialog-paper': {
    minWidth,
    maxWidth: 592,
  },
}));
function DesktopDialog({
  open,
  title,
  description,
  isSubmitDisabled = false,
  primaryButtonText,
  primaryButtonHandler,
  isPrimaryButtonTypeSubmit = true,
  secondaryButtonText,
  secondaryButtonHandler,
  isSecondaryButtonTypeSubmit = false,
  hasMoreMenu = false,
  moreButtonItems,
  children,
  closeHandler,
  minWidth = 592,

  isForm = false,
  onSubmit,
  isSubmitting,
}: SimpleDialogProps) {
  const theme = useTheme();
  const isMobile = useMobileMode();

  const wrapperProps = isForm
    ? {
        noValidate: true,
        autoComplete: 'off',
        onSubmit: onSubmit,
      }
    : {};

  return (
    <StyledDialog minWidth={isMobile ? undefined : minWidth} fullScreen={isMobile} open={open} onClose={closeHandler}>
      <CloseButton aria-label="close" onClick={closeHandler}>
        <CloseIcon />
      </CloseButton>
      {title && (
        <DialogTitle
          sx={{
            padding: theme.spacing(2.5, 4, 2),
            color: theme.palette.text.title,
            fontWeight: theme.typography.fontWeightBold,
          }}
        >
          {title}
        </DialogTitle>
      )}
      <Box
        display="flex"
        flexDirection="column"
        flex={isMobile ? '1 1 auto' : 'none'}
        component={isForm ? 'form' : 'div'}
        {...wrapperProps}
      >
        <DialogContent dividers={!!title} sx={{ padding: theme.spacing(3, 4, 4), color: theme.palette.text.primary }}>
          {description && <DialogContentText fontSize="0.875rem">{description}</DialogContentText>}
          {children}
        </DialogContent>
        {!title ? <Divider /> : null}
        {(primaryButtonText || secondaryButtonText) && (
          <DialogActions sx={{ margin: (theme) => theme.spacing(2.5, 3) }}>
            <FlexBox width="100%" gap={1.5}>
              {secondaryButtonText && (
                <FlexBox sx={{ width: hasMoreMenu ? '75%' : primaryButtonText ? '50%' : '100%' }}>
                  <Button
                    variant="outlined"
                    fullWidth
                    autoFocus
                    disabled={isSecondaryButtonTypeSubmit && isSubmitDisabled}
                    onClick={secondaryButtonHandler}
                    type={isForm && isSecondaryButtonTypeSubmit ? 'submit' : 'button'}
                  >
                    {secondaryButtonText || 'Cancel'}
                  </Button>
                  {hasMoreMenu && (
                    <ActionButton variant="outlined" moreButtonItems={moreButtonItems} isDisabled={isSubmitDisabled} />
                  )}
                </FlexBox>
              )}
              {primaryButtonText && (
                <Button
                  isLoading={isSubmitting}
                  sx={{ width: secondaryButtonText ? '50%' : '100%' }}
                  variant="contained"
                  disabled={isPrimaryButtonTypeSubmit && isSubmitDisabled}
                  onClick={primaryButtonHandler}
                  type={isForm && isPrimaryButtonTypeSubmit ? 'submit' : 'button'}
                >
                  {primaryButtonText || 'Confirm'}
                </Button>
              )}
            </FlexBox>
          </DialogActions>
        )}
      </Box>
    </StyledDialog>
  );
}

export function SimpleDialog(props: SimpleDialogProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  if (!props.open) return null;
  if (isDesktop || props.avoidBottomSheet) {
    return <DesktopDialog {...props} />;
  }
  return <MobileDialog {...props} />;
}
