import { zodResolver } from '@hookform/resolvers/zod';
import { FormControl, FormControlLabel, useTheme } from '@mui/material';
import { AddressType, ShipmentCategory } from '@usgm/inbox-api-types';
import { Checkbox, ConfirmationDialog, ConfirmationDialogProps, useMobileMode } from '@usgm/shared-ui';
import { toLowerCaseWords } from '@usgm/utils';
import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../../../../../store';
import { apiMessagesSlice } from '../../../../../../../../apiMessages/apiMessagesSlice';
import { useAuth } from '../../../../../../../../auth/hooks/useAuth';
import { useAddAddressMutation } from '../../../../../../../inboxAccountsApi';
import ShippingAddressForm, {
  shippingAddressFormSchema,
  ShippingAddressFormSchemaType,
} from '../../../../../../settings/components/AddressBook/ShippingAddressForm';
import { selectShipmentType } from '../../mailsShipmentSlice';

export type AddAddressDialogProps = ConfirmationDialogProps & {
  onAddressAdded: (address: ShippingAddressFormSchemaType, uuid: string, useNewAddress?: boolean) => void;
};

function AddAddressDialog({
  title = 'Add new address',
  confirmText = 'Add',
  cancelText = 'Cancel',
  onAddressAdded,
  onCancel,
  ...dialogProps
}: Omit<AddAddressDialogProps, 'content'>) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isMobile = useMobileMode();
  const { user } = useAuth();

  const [useNewAddress, setUseNewAddress] = useState(true);
  const shipmentType = useAppSelector(selectShipmentType);
  const addressType =
    shipmentType === ShipmentCategory.ShipmentRequest ? AddressType.Shipping : AddressType.CheckDeposit;
  const [addAddress, { isLoading: isSubmitting }] = useAddAddressMutation();

  const formMethods = useForm<ShippingAddressFormSchemaType>({
    resolver: zodResolver(shippingAddressFormSchema),
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      address_type: addressType,
      country: { code: '', name: '', id: '' },
    },
  });
  const {
    formState: { isValid },
    getValues,
  } = formMethods;

  const disabled = !isValid || isSubmitting;

  const handleConfirm = useCallback(async () => {
    const address = getValues();
    const response = await addAddress({ userUuid: user?.userUUID || '', addr: getValues() });
    const hasError = 'error' in response;
    dispatch(
      apiMessagesSlice.actions.createMessage({
        severity: hasError ? 'error' : 'success',
        text: hasError
          ? 'Failed saving address. Please make sure all required fields are correctly entered and try again.'
          : 'Address saved successfully.',
      }),
    );

    if (!hasError) {
      onAddressAdded(address, response.data.id, useNewAddress);
    }
  }, [addAddress, dispatch, getValues, onAddressAdded, useNewAddress, user?.userUUID]);

  return (
    <ConfirmationDialog
      {...dialogProps}
      title={title}
      disabled={disabled}
      onCancel={onCancel}
      onClose={onCancel}
      onConfirm={handleConfirm}
      isLoading={isSubmitting}
      confirmText={confirmText}
      cancelText={cancelText}
      autoCloseOnConfirm={false}
      backgroundColor={theme.palette.background.paper}
      large
      height={isMobile ? '100%' : '75%'}
      content={
        <FormProvider {...formMethods}>
          <ShippingAddressForm disableTypeSelection />
          <FormControl>
            <FormControlLabel
              onChange={() => setUseNewAddress(!useNewAddress)}
              checked={useNewAddress}
              control={<Checkbox />}
              label={`Use this address for this ${toLowerCaseWords(shipmentType || 'request')}`}
            />
          </FormControl>
        </FormProvider>
      }
    />
  );
}

export default AddAddressDialog;
