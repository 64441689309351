import { isObject } from 'lodash-es';

export function constructListFilters(filters: unknown): string {
  if (!isObject(filters)) {
    return '';
  }
  return Object.entries(filters)
    .filter(([, value]) => value.length > 0)
    .map(([key, value]) => {
      return `filter=${key}:${value.join(',')}`;
    })
    .join('&');
}
