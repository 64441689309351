import { AxiosInstance } from 'axios';
import { ReactNode, createContext, useContext } from 'react';
import { ENVIRONMENT } from '../../env';
import { createInstance } from './createInstance';

interface ApiContextType {
  accountApiInstance: AxiosInstance | undefined;
}

let instance: ApiContextType['accountApiInstance'] = undefined;

const ApiContext = createContext<ApiContextType | undefined>(undefined);

export const ApiClientsProvider = ({ children }: { children: ReactNode }) => {
  if (!instance) {
    instance = createInstance(ENVIRONMENT.ACCOUNT_API_URL, {
      interceptMessages: true,
    });
  }

  return <ApiContext.Provider value={{ accountApiInstance: instance }}>{children}</ApiContext.Provider>;
};

export const useAccountApiClient = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('useAccountApi must be used within an ApiProvider');
  }
  return context.accountApiInstance as AxiosInstance;
};
