import { TextareaAutosize, TextareaAutosizeProps, Typography, styled, useTheme } from '@mui/material';
import FlexBox from '../FlexBox';
import { forwardRef } from 'react';

type StyledProps = {
  resize: 'none' | 'both' | 'horizontal' | 'vertical';
  fullWidth: boolean;
};

const StyledTextareaAutosize = styled(TextareaAutosize, {
  shouldForwardProp: (prop) => prop !== 'resize' && prop !== 'fullWidth',
})<StyledProps>(({ theme, resize, fullWidth }) => ({
  backgroundColor: theme.palette.background.paper,
  resize,
  width: fullWidth ? '100%' : 'auto',
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.primary,
  '&:focus': {
    borderColor: theme.palette.primary.main,
  },
  '&:focus-visible': {
    outline: 'none',
    borderColor: theme.palette.primary.main,
  },
}));

export type TextAreaProps = TextareaAutosizeProps & Partial<StyledProps> & { showLength?: boolean };

export const Textarea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      fullWidth = true,
      resize = 'vertical',
      maxLength = 1000,
      maxRows = 10,
      minRows = 6,
      showLength = true,
      value,
      ...rest
    },
    ref,
  ) => {
    const theme = useTheme();
    return (
      <FlexBox flexDirection="column">
        <StyledTextareaAutosize
          ref={ref}
          {...rest}
          fullWidth={fullWidth}
          resize={resize}
          maxLength={maxLength}
          maxRows={maxRows}
          minRows={minRows}
        />
        {showLength ? (
          <Typography
            fontSize="0.75rem"
            fontWeight={theme.typography.fontWeightBold}
            color={theme.customColors.dark[400]}
            mt={0.5}
            alignSelf="flex-end"
          >
            {value?.toString().length}/{maxLength}
          </Typography>
        ) : null}
      </FlexBox>
    );
  },
);
