import { Box, CircularProgress, styled } from '@mui/material';
import { AddButton, Button, FlexBox, MultiSelectionList } from '@usgm/shared-ui';
import { useCallback, useState } from 'react';
import { useMailFolders } from '../../../mails/hooks/useMailFolders';
import { TFolder } from '../../../mails/mailFolders/folderSlice';

const ActionsContainer = styled(FlexBox)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
}));

export type TeamMemberFoldersManagerProps = {
  isUpdating?: boolean;
  onClose?: () => void;
  onAddFolderClick?: () => void;
  onSubmit: (folderIds: string[]) => void;
  assignedFoldersIds: string[];
};
function TeamMemberFoldersManager({
  onClose,
  onAddFolderClick,
  isUpdating,
  assignedFoldersIds,
  onSubmit,
}: TeamMemberFoldersManagerProps) {
  const [selectedFolders, setSelectedFolders] = useState<string[]>(() => assignedFoldersIds);

  const { allFolders, folderUuidExtractor, folderLabelExtractor } = useMailFolders();

  const handleApplyClick = useCallback(async () => {
    onSubmit(selectedFolders);
  }, [onSubmit, selectedFolders]);

  const handleSelectionChange = useCallback((selected: string[]) => {
    setSelectedFolders(selected);
  }, []);

  return (
    <>
      <Box p={2}>
        <MultiSelectionList<TFolder, string>
          listMaxHeight={200}
          onChange={handleSelectionChange}
          searchPlaceholder="Search folders"
          items={allFolders}
          selected={selectedFolders}
          keyExtractor={folderUuidExtractor}
          labelExtractor={folderLabelExtractor}
        />
        {onAddFolderClick && (
          <Box mt={3}>
            <AddButton onClick={onAddFolderClick} label="Add Folder" />
          </Box>
        )}
      </Box>
      <ActionsContainer p={2} gap={2}>
        <Button onClick={onClose} fullWidth variant="outlined">
          Cancel
        </Button>
        <Button fullWidth disabled={isUpdating} variant="contained" onClick={handleApplyClick}>
          {isUpdating ? <CircularProgress size={16} /> : 'Apply'}
        </Button>
      </ActionsContainer>
    </>
  );
}

export default TeamMemberFoldersManager;
