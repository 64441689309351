import { ScanCategory } from '@usgm/inbox-api-types';
import CategoryFilter from '../../../components/filters/CategoryFilter';
import React from 'react';

const SCANS_CATEGORY_OPTIONS = new Map<string, string>([
  ['all', 'All'],
  [ScanCategory.ScanRequest, 'Scan'],
  [ScanCategory.UnboxingRequest, 'Open'],
]);

export type ScansCategoryFilterProps = {
  label?: string;
};
export function ScansCategoryFilter({ label = 'Type:' }: ScansCategoryFilterProps) {
  return <CategoryFilter label={label} filtersMap={SCANS_CATEGORY_OPTIONS} />;
}

export default React.memo(ScansCategoryFilter);
