import { Palette, createTheme } from '@mui/material/styles';
import { createComponents, darkPalette, shape, createTypography, breakpoints } from '../options';
import { createCustomPalette } from '../options/palettes/helpers';

export let darkTheme = createTheme({
  palette: darkPalette,
  typography: createTypography(darkPalette as Palette),
  components: createComponents(darkPalette as Palette),
  shape,
  breakpoints,
  customColors: {
    dark: {
      100: '#00000033',
      200: ' #4C4C4C',
      300: '#757B83',
      400: '#9BA6B4',
      500: 'rgba(255, 255, 255, 0.65)',
    },
    menuBorder: '#2E2E2E',
  },
});

darkTheme = createTheme(darkTheme, {
  palette: createCustomPalette(darkTheme),
});
