import { Box, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import QuestionMarkIcon from '../../features/inbox/assets/icons/questionMark.svg?react';
import { Container, FlexBox } from '@usgm/shared-ui';

const PageContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(6, 0),
  [theme.breakpoints.down('lg')]: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const CardWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2.5, 6.5),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 3),
  },
}));

export default function NotFoundPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <PageContainer>
      <CardWrapper>
        <FlexBox flexDirection={isMobile ? 'column' : 'row'} gap={24}>
          <Box flex="1 1 auto">
            <Typography color={theme.palette.primary.dark} variant="h4">
              Page Not Found
            </Typography>
            <Typography mt={2} color={theme.palette.text.secondary} maxWidth={500}>
              The page you were looking for could not be found. It might have been removed, renamed, or did not exist in
              the first place.
            </Typography>
          </Box>
          <QuestionMarkIcon />
        </FlexBox>
      </CardWrapper>
    </PageContainer>
  );
}
