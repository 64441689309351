import { AccountRelation, DtoDocument, RecordStatus, UserType } from '@usgm/inbox-api-types';
import { useAuth } from '../../../../auth/hooks/useAuth';
import { useGetAccountNamesWithStatusQuery } from '../api';
import { useMemo } from 'react';
import { onboardingUtils } from '@usgm/utils';

export type AccountNameDocumentInfo = {
  name: string;
  relation: AccountRelation;
  documents: Map<UserType, DtoDocument | null>;
  status?: RecordStatus | 'Missing';
};

export type AccountNamesMap = Map<string, AccountNameDocumentInfo>;

export function useAccountNamesMap() {
  const { user } = useAuth();
  const { isLoading, data: result, isFetching } = useGetAccountNamesWithStatusQuery(null, { skip: !user });

  const accountNamesMap: AccountNamesMap | undefined | null = useMemo(() => {
    const map: AccountNamesMap = new Map();
    result?.data?.accountNames
      ?.filter((account) => {
        return !onboardingUtils.DOCUMENTS_CAN_BE_SKIPPED_RELATIONS.includes(account.relation.relationType);
      })
      ?.forEach(({ user: { uuid, name }, relation: { relationType } }) => {
        map.set(uuid, {
          name,
          relation: relationType,
          documents: new Map([
            [UserType.Primary, null],
            [UserType.Secondary, null],
          ]),
        });
      });
    return map;
  }, [result?.data?.accountNames]);

  return {
    accountNamesMap,
    isLoading: isFetching || isLoading,
    accountsList: result?.data?.accountNames,
  };
}
