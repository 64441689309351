import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store';
import { selectPickedWarehouse, warehousesSelectors } from '../../addressPicker/addressPickerSlice';
import { useGetWarehousePlansQuery } from '../api';
import { useGetWarehousesQuery } from '../../addressPicker/api';
import { useAuth } from '../../auth/hooks/useAuth';
import { planPickerSlice } from '../planPickerSlice';
import { useEffect } from 'react';

export const usePlans = (changeWarehouseMode = false) => {
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const pickedWarehouseId = useAppSelector(selectPickedWarehouse) || 0;
  const { id } = useParams<{ id: string }>();
  const paramId = id ? parseInt(id) : 0;
  const warehouseId = changeWarehouseMode ? pickedWarehouseId : user?.warehouseId || paramId || pickedWarehouseId;

  const warehouseQuery = useGetWarehousesQuery(undefined, { skip: !warehouseId });

  const pickedWarehouse = useAppSelector((state) => warehousesSelectors.selectById(state, warehouseId));

  const plansQuery = useGetWarehousePlansQuery(
    { warehouse: warehouseId },
    {
      skip: !warehouseId,
      refetchOnMountOrArgChange: changeWarehouseMode,
    },
  );
  useEffect(() => {
    // extraReducer doesn't handle cached data in matchFulfilled state
    if (plansQuery.data) {
      dispatch(planPickerSlice.actions.setPlans(plansQuery.data));
    }
  }, [dispatch, plansQuery.data]);

  return { plansQuery, pickedWarehouse, warehouseQuery, pickedWarehouseId };
};
