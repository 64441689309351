import { DtoBankAccount } from '@usgm/inbox-api-types';

export const stringifyBankAccount = (bankAccount: DtoBankAccount) => {
  if (!bankAccount) return '';
  const parts: string[] = [];
  [bankAccount.bankName, bankAccount.bankState].forEach((part) => {
    if (part) {
      parts.push(part);
    }
  });
  return parts.join(', ');
};
