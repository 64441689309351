import { Box, styled } from '@mui/material';
import { DataTablePagination } from './DataTablePagination';

export const DATA_GRID_FOOTER_HEIGHT = 80;

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2, 2),
  boxSizing: 'border-box',
  width: '100%',
  borderTop: `1px solid ${theme.palette.divider}`,
  height: DATA_GRID_FOOTER_HEIGHT,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  position: 'sticky',
  bottom: 0,
}));

export type MobileFooterProps = {
  totalRows?: number;
  children?: React.ReactNode;
};
export function MobileFooter({ totalRows, children }: MobileFooterProps) {
  return (
    <FooterContainer>
      {children}
      {!!totalRows && <DataTablePagination count={totalRows} />}
    </FooterContainer>
  );
}

export default MobileFooter;
