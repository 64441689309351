export enum CaptchaType {
  FormDownload = 'form_download',
  IdsDownload = 'ids_download',
  ExtractDocumentInfo = 'extract_document_info',
  Login = 'login',
  Signup = 'signup',
  EcportScans = 'export_scans',
}

export const CAPTCHA_VERSION_V2 = 2;
export const CAPTCHA_VERSION_V3 = 3;
