import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { FormControl, Typography, styled, useTheme } from '@mui/material';

import { FlexBox, SelectBox, RemoveButton } from '@usgm/shared-ui';
import React from 'react';
import { TAccount } from './TAccount';
import { inboxHelpers } from '@usgm/utils';

export type FileRowProps = {
  file: File;
  userId: string | null;
  usersList: Array<TAccount>;
  onRemove?: () => void;
  onChange?: (value: string) => void;
};

const FileInfo = styled(FlexBox)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1.875),
  maxWidth: '68%',
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    maxWidth: '50%',
  },
}));

const FileName = styled(Typography)(({ theme }) => ({
  maxWidth: `calc(100% - ${theme.spacing(2)})`,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export function FileRow({ file, userId, usersList, onRemove, onChange }: FileRowProps) {
  const theme = useTheme();
  const handleChange = (value: string) => {
    onChange?.(value);
  };
  return (
    <FlexBox mb={2} gap={2}>
      <FileInfo>
        <FlexBox justifyContent="flex-start" maxWidth={`calc(100% - ${theme.spacing(3)})`}>
          <InsertDriveFileOutlinedIcon fontSize="small" sx={{ color: theme.customColors.dark[400] }} />
          <FileName ml={1} noWrap maxWidth={252}>
            {file.name}
          </FileName>
        </FlexBox>
        {onRemove && <RemoveButton onClick={onRemove} />}
      </FileInfo>

      <FormControl fullWidth>
        <SelectBox<TAccount>
          getLabel={(option: TAccount) => option.name}
          getValue={(option: TAccount) => option.id}
          value={userId}
          label="Select User"
          options={usersList}
          onChange={handleChange}
        />
      </FormControl>
    </FlexBox>
  );
}
export default React.memo(FileRow);
