import { Box, Typography } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid';

import { BaseMail, MailStatus } from '@usgm/inbox-api-types';
import { DATA_TABLE_CLASSES, TextCell, cellHelpers } from '@usgm/shared-ui';

import MailActions from './MailInfo/MailActions';
import { selectActiveMail } from '../mailsSlice';
import { useAppSelector } from '../../../../../store';
import { useMemo } from 'react';
import { CSSProperties } from '@mui/material/styles/createMixins';

export type ActionsCellProps = {
  data: Omit<GridRowParams<BaseMail>, 'columns'>;
  moreVariant?: 'icon' | 'text';
};

function ActionsCell({ data, moreVariant = 'text' }: ActionsCellProps) {
  const activeItem = useAppSelector(selectActiveMail);
  const style = useMemo(
    () => (activeItem?.id === data.id ? ({ visibility: 'visible' } as CSSProperties) : {}),
    [activeItem, data.id],
  );

  const isQuarantine = data?.row.mailStatus === MailStatus.Quarantine;
  return (
    <TextCell>
      <Typography>{cellHelpers.formatDateString(data.row.arrivalDate)}</Typography>
      <Box style={{ visibility: style.visibility }} mt={4} className={DATA_TABLE_CLASSES.FLOATING_ACTIONS}>
        <MailActions
          isQuarantine={data?.row.mailStatus === MailStatus.Quarantine}
          moreVariant={isQuarantine ? 'text' : moreVariant}
          singleItem
          rows={[data]}
          ids={[data.row.id]}
        />
      </Box>
    </TextCell>
  );
}

export default ActionsCell;
