import { ListItemButton, styled } from '@mui/material';

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  '&:last-of-type': {
    marginBottom: 0,
  },
}));
