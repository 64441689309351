import { Toast } from '@usgm/shared-ui';
import { useAppDispatch, useAppSelector } from '../../../store';
import { apiMessagesSlice, selectApiMessage } from '../apiMessagesSlice';

export function ApiMessages() {
  const apiMessage = useAppSelector(selectApiMessage);
  const appDispatch = useAppDispatch();
  const clearMessage = () => {
    appDispatch(apiMessagesSlice.actions.clearMessage());
  };

  return apiMessage ? <Toast title={apiMessage.text} severity={apiMessage.severity} onClose={clearMessage} /> : null;
}
