import {
  UserInfo,
  DtoMfaStatus,
  DtoImportantDates,
  DtoAuthData,
  DtoGenerateMfaCode,
  EnableMfaParams,
  CloseAccountParams,
  DtoCloseAccount,
  UpdateUserDataParams,
  UpdateUserSubscriptionParams,
  ScheduledCloseAccountParams,
  DtoTeamMemberInviteResult,
  DtoTeamMemberInvitee,
  ResponseInfo,
  UpdateTeamMemberRoleParams,
  DtoUserSettings,
  DtoExportItem,
  EXPORT_SCANS_TAG,
  DtoUserFreeScans,
} from '@usgm/inbox-api-types';
import { DtoTeamMember } from '@usgm/inbox-api-types';
import { snakeCaseKeys, inboxHelpers } from '@usgm/utils';
import { isString } from 'lodash-es';
import { accountApi } from '../../../../api/accountApi';

export enum UserSettingType {
  All = 'ALL',
  ShippingPreferences = 'SHIPPING_PREFERENCES',
  ScanOptions = 'SCAN_OPTIONS',
}

const TAG_TYPES = {
  TEAM_MEMBERS: 'TeamMembers',
  TEAM_MEMBER_FOLDERS: 'TeamMemberFolders',
  USER_SETTINGS: 'UserSettings',
} as const;

export const settingsApi = accountApi
  .enhanceEndpoints({
    addTagTypes: [TAG_TYPES.TEAM_MEMBERS, TAG_TYPES.TEAM_MEMBER_FOLDERS, TAG_TYPES.USER_SETTINGS, EXPORT_SCANS_TAG],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      checkMfaStatus: builder.query<DtoMfaStatus, void>({
        query: () => ({
          url: `/account/details`,
        }),
      }),
      getImportantDates: builder.query<DtoImportantDates, UserInfo['userUUID']>({
        query: (uuid) => ({
          url: `/customer/${uuid}/important/dates`,
        }),
      }),
      // @TODO: this api endpoint should be a mutation instead of a query (E.g PUT /mfa/disable)
      disableMfa: builder.query<DtoAuthData, void | null>({
        query: () => ({
          url: `/mfa/disable`,
        }),
      }),
      enableMfa: builder.mutation<DtoAuthData, EnableMfaParams>({
        query: ({ code, token }) => ({
          method: 'post',
          url: `/mfa/enable`,
          data: { verificationCode: code, mfaTempToken: token },
        }),
      }),
      updateUserData: builder.mutation<DtoGenerateMfaCode, UpdateUserDataParams>({
        query: ({ uuid, email, name, oldPassword, newPassword }) => ({
          method: 'put',
          url: `/user/${uuid}`,
          data: { email, name, old_password: oldPassword, password: newPassword },
        }),
      }),
      updateUserSubscription: builder.mutation<void | undefined, UpdateUserSubscriptionParams>({
        query: ({ planId, termId }) => ({
          method: 'post',
          url: `/user/subscription/update`,
          data: { plan_id: planId, term_id: termId },
        }),
      }),
      scheduleCloseAccount: builder.mutation<DtoCloseAccount, ScheduledCloseAccountParams>({
        query: ({ reason, status }) => ({
          method: 'post',
          url: `/customer/schedule/account/closure`,
          data: { closeReason: reason, status },
        }),
      }),
      closeAccount: builder.mutation<DtoCloseAccount, CloseAccountParams>({
        query: ({ userId, reason, status }) => ({
          method: 'post',
          url: `/customer/updateStatus`,
          data: { userId, closeReason: reason, status },
        }),
      }),
      // @TODO: team members api endpoints needs to be moved to separate file under team members feature
      // Team Members api endpoints
      getTeamMembersList: builder.query<DtoTeamMember[], void | null>({
        query: () => ({
          method: 'get',
          url: '/org/users',
        }),
        providesTags: () => [TAG_TYPES.TEAM_MEMBERS],
      }),
      inviteTeamMembers: builder.mutation<{ data: DtoTeamMemberInviteResult[] }, DtoTeamMemberInvitee[]>({
        query: (invitees) => ({
          method: 'post',
          url: `/org/invite`,
          data: invitees,
        }),
        invalidatesTags: [TAG_TYPES.TEAM_MEMBERS],
      }),
      deleteTeamMemberById: builder.mutation<ResponseInfo, { teamMemberId: string }>({
        query: ({ teamMemberId }) => ({
          url: `/org/user/cancel/${teamMemberId}`,
          method: 'post',
        }),
        invalidatesTags: [TAG_TYPES.TEAM_MEMBERS],
      }),
      updateTeamMemberRoleById: builder.mutation<ResponseInfo, UpdateTeamMemberRoleParams>({
        query: ({ teamMemberId, role }) => ({
          url: `/org/user/role/update/${teamMemberId}`,
          method: 'post',
          data: { role },
        }),
        invalidatesTags: [TAG_TYPES.TEAM_MEMBERS],
      }),
      resendTeamMemberInvitation: builder.mutation<ResponseInfo, { teamMemberId: string }>({
        query: ({ teamMemberId }) => ({
          method: 'post',
          url: `/org/user/resend/invitation/${teamMemberId}`,
        }),
      }),
      getTeamMemberFolders: builder.query<{ data: Array<{ id: string; name: string }> }, { teamMemberId: string }>({
        query: ({ teamMemberId }) => ({
          url: `/org/user/${teamMemberId}/folders`,
          method: 'get',
        }),
        providesTags: (_, __, { teamMemberId }) => [{ type: TAG_TYPES.TEAM_MEMBER_FOLDERS, id: teamMemberId }],
      }),
      setTeamMemberFolders: builder.mutation<ResponseInfo, { teamMemberId: string; mailFolderIds: string[] }>({
        query: ({ teamMemberId, mailFolderIds }) => ({
          url: `/org/user/folders/update/${teamMemberId}`,
          method: 'post',
          data: { mailFolderIds },
        }),
        invalidatesTags: (_, __, { teamMemberId }) => [{ type: TAG_TYPES.TEAM_MEMBER_FOLDERS, id: teamMemberId }],
      }),
      getScanExportList: builder.query<DtoExportItem[], void | null>({
        query: () => ({
          method: 'get',
          url: '/scan/batch/export',
          transformResponse: (data: string) => {
            const jsonResponse = inboxHelpers.parseJsonSafe<{ result: DtoExportItem[] }>(data);
            return jsonResponse?.result;
          },
        }),
        providesTags: () => [EXPORT_SCANS_TAG],
      }),
      getUserSettings: builder.query<DtoUserSettings | null, { settingType?: UserSettingType }>({
        query: ({ settingType }) => ({
          url: `/user/settings/options`,
          method: 'get',
          params: snakeCaseKeys({ userSettingType: settingType ?? UserSettingType.All }),
        }),
        providesTags: (_, __, { settingType }) => [{ type: TAG_TYPES.USER_SETTINGS, id: settingType }],
        transformResponse: (response: { userSettings: DtoUserSettings } | string): DtoUserSettings | null => {
          const parsedResponse = isString(response)
            ? inboxHelpers.parseJsonSafe<{ userSettings: DtoUserSettings }>(response)
            : response;
          return parsedResponse?.userSettings || null;
        },
      }),
      createOrUpdateUserSettings: builder.mutation<
        { userSetting: DtoUserSettings },
        { uuid?: string; payload: Partial<DtoUserSettings> }
      >({
        query: ({ uuid, payload }) => ({
          url: `/user/setting/options`,
          method: uuid ? 'put' : 'post',
          data: snakeCaseKeys(payload),
          params: uuid ? snakeCaseKeys({ userSettingId: uuid }) : null,
        }),
        invalidatesTags: (_, __, { payload }) => {
          const tagsToInvalidate = [{ type: TAG_TYPES.USER_SETTINGS, id: UserSettingType.All }];
          if (payload.scanOptions) {
            tagsToInvalidate.push({ type: TAG_TYPES.USER_SETTINGS, id: UserSettingType.ScanOptions });
          }
          if (payload.shippingPreferences) {
            tagsToInvalidate.push({ type: TAG_TYPES.USER_SETTINGS, id: UserSettingType.ShippingPreferences });
          }
          return tagsToInvalidate;
        },
      }),
      // @TODO: free scans api doesn't belong to settings api
      getUserFreeScans: builder.query<{ data: DtoUserFreeScans }, void | null>({
        query: () => ({
          url: `/free-scans`,
          method: 'get',
        }),
      }),
    }),
  });

export const {
  useLazyCheckMfaStatusQuery,
  useLazyGetImportantDatesQuery,
  useGetImportantDatesQuery,
  useLazyDisableMfaQuery,
  useEnableMfaMutation,
  useUpdateUserDataMutation,
  useUpdateUserSubscriptionMutation,
  useScheduleCloseAccountMutation,
  useCloseAccountMutation,
  useGetTeamMembersListQuery,
  useInviteTeamMembersMutation,
  useDeleteTeamMemberByIdMutation,
  useUpdateTeamMemberRoleByIdMutation,
  useResendTeamMemberInvitationMutation,
  useGetTeamMemberFoldersQuery,
  useSetTeamMemberFoldersMutation,
  useGetScanExportListQuery,
  useGetUserSettingsQuery,
  useCreateOrUpdateUserSettingsMutation,
  useGetUserFreeScansQuery,
} = settingsApi;
