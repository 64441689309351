import { Typography, styled } from '@mui/material';

export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 600,
  marginBottom: theme.spacing(2.5),
  textAlign: 'center',
})) as typeof Typography;

export default SectionTitle;
