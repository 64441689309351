import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useGenericFilters<TFilterKey extends string>(filters: readonly TFilterKey[]) {
  const [searchParams, setSearchParams] = useSearchParams();

  const getFilterByKey = useCallback(
    (key: TFilterKey) => {
      return (
        searchParams
          .get(key)
          ?.split(',')
          .filter((s) => !!s) || []
      );
    },
    [searchParams],
  );

  const clearFilterByKey = useCallback(
    (key: TFilterKey) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(key);

      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams],
  );

  const updateFilterByKey = useCallback(
    (key: TFilterKey, value: string[]) => {
      const newSearchParams = new URLSearchParams(searchParams);
      if (value.length === 0) {
        newSearchParams.delete(key);
      } else {
        newSearchParams.set(key, value.join(','));
      }
      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams],
  );

  const getAllFilters = useCallback(() => {
    return filters.reduce((acc, key) => {
      acc[key as TFilterKey] = getFilterByKey(key as TFilterKey);

      return acc;
    }, {} as Record<TFilterKey, string[]>);
  }, [filters, getFilterByKey]);

  const filtersCount = filters.reduce((acc, key) => {
    if (searchParams.has(key)) {
      return acc + 1;
    }
    return acc;
  }, 0);

  return {
    filtersCount,
    getFilterByKey,
    updateFilterByKey,
    getAllFilters,
    clearFilterByKey,
  };
}
