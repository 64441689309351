import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { inboxHelpers } from '@usgm/utils';

import React from 'react';

export type PageHeadingProps = {
  title: string;
  subtitle?: string;
};
export function PageHeading({ subtitle, title }: PageHeadingProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(inboxHelpers.DOWN_MOBILE_LANDSCAPE);
  return (
    <>
      <Typography mb={0.5} variant={isSmallScreen ? 'h5' : 'h4'}>
        {title}
      </Typography>
      {subtitle && <Typography color={theme.customColors.dark[300]}>{subtitle}</Typography>}
    </>
  );
}

export default React.memo(PageHeading);
