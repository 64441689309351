import { CircularProgress } from '@mui/material';
import { FlexBox } from '@usgm/shared-ui';

export default function PageLoader() {
  return (
    <FlexBox width="100%" height="100%" justifyContent="center">
      <CircularProgress />
    </FlexBox>
  );
}
