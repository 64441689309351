import { useMemo } from 'react';

export const EDIT_ADDRESS_STEPS = [
  { label: 'Pick a location', stepNumber: 0 },
  { label: 'Select a Plan', stepNumber: 1 },
  { label: 'Confirm', stepNumber: 2 },
];

export const useEditAddressSteps = (activeStepIndex: number, handleClick: (step: number) => void) => {
  const steps = useMemo(() => {
    return EDIT_ADDRESS_STEPS.map((step, index) => {
      const isEnabled = index < activeStepIndex;
      return {
        label: step.label,
        onClick: isEnabled
          ? () => {
              handleClick(step.stepNumber);
            }
          : undefined,
      };
    });
  }, [activeStepIndex, handleClick]);
  return { steps, activeStepIndex };
};
