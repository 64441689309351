import { ADDRESS_SCHEMA_FIELDS } from '@inbox/constants';
import { Box, FormControl, FormControlLabel, MenuItem, styled, Typography, useTheme } from '@mui/material';
import { AddressType } from '@usgm/inbox-api-types';
import { Checkbox, FlexBox, InfoTitle, MessageBox, TextInput } from '@usgm/shared-ui';
import { pascalCase } from '@usgm/utils';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { z } from 'zod';
import AddressForm from '../../../../../../components/AddressForm';

const ADDRESS_TYPE_OPTIONS = [AddressType.Shipping, AddressType.CheckDeposit];

export const shippingAddressFormSchema = z.object({
  address_line: ADDRESS_SCHEMA_FIELDS.ADDRESS_LINE.min(1, 'Address line is required.'),
  address_line_2: ADDRESS_SCHEMA_FIELDS.ADDRESS_LINE_ADDITIONAL,
  address_line_3: ADDRESS_SCHEMA_FIELDS.ADDRESS_LINE_ADDITIONAL,
  country: ADDRESS_SCHEMA_FIELDS.COUNTRY,
  city: ADDRESS_SCHEMA_FIELDS.CITY,
  state: ADDRESS_SCHEMA_FIELDS.STATE,
  zip: ADDRESS_SCHEMA_FIELDS.ZIP,
  name: z.string().min(1, 'Recipient name is required.'),
  address_type: z.nativeEnum(AddressType),
  phone_number: z.string().min(1, 'Phone number is required.'),
  is_default: z.boolean(),
});

export type ShippingAddressFormSchemaType = z.infer<typeof shippingAddressFormSchema>;

const StyledMessageBoxContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export type ShippingAddressFormProps = {
  defaultValues?: Partial<ShippingAddressFormSchemaType>;
  disableTypeSelection?: boolean;
};
function ShippingAddressForm({ defaultValues, disableTypeSelection }: ShippingAddressFormProps) {
  const theme = useTheme();
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<ShippingAddressFormSchemaType>();

  return (
    <FlexBox flexDirection={'column'} width={'100%'} gap={2} alignItems={'left'}>
      {defaultValues && (
        <StyledMessageBoxContainer>
          <MessageBox
            severity="info"
            title="Please note"
            description="Changing your address here will not change any addresses on open shipment requests. You must update any open requests individually."
          />
        </StyledMessageBoxContainer>
      )}
      <Controller
        render={({ field }) => (
          <TextInput
            {...field}
            select
            error={!!errors?.address_type}
            helperText={errors?.address_type?.message}
            InputLabelProps={{ shrink: true }}
            fullWidth
            required
            disabled={disableTypeSelection}
            label="Address Type"
          >
            {ADDRESS_TYPE_OPTIONS.map((option) => (
              <MenuItem selected={field.value === option} key={option} value={option}>
                {pascalCase(option)}
              </MenuItem>
            ))}
          </TextInput>
        )}
        name="address_type"
        control={control}
      />
      <TextInput
        {...register('name')}
        error={!!errors?.name?.message}
        helperText={errors?.name?.message}
        InputLabelProps={{ shrink: true }}
        fullWidth
        required
        label="Recipient Name"
      />
      <AddressForm autocompleteLabel="Address Line" extended defaultValues={defaultValues} />
      <Box>
        <TextInput
          {...register('phone_number')}
          error={!!errors?.phone_number?.message}
          helperText={errors?.phone_number?.message}
          InputLabelProps={{ shrink: true }}
          fullWidth
          required
          label="Phone Number"
          sx={{ height: 68 }}
        />
        <InfoTitle
          color={theme.palette.text.secondary}
          title="Phone number is required to help the shipper resolve delivery issues."
          description=""
        />
      </Box>
      <Controller
        render={({ field }) => (
          <FormControl>
            <FormControlLabel
              {...register('is_default')}
              sx={{ marginTop: 2 }}
              control={<Checkbox defaultChecked={defaultValues?.is_default} />}
              label={<Typography>Make this my default address</Typography>}
            />
          </FormControl>
        )}
        name="is_default"
        control={control}
      />
    </FlexBox>
  );
}

export default ShippingAddressForm;
