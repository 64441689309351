import DynamicSidebar from '../../../../../components/DynamicSidebar';
import { MailActionRequest } from '../../../paths';

import {
  mailsShipmentSlice,
  mailsToShipSelectors,
  selectShipmentType,
  selectShippingDetails,
} from './mailsShipmentSlice';

import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../store';
import { useMailRequestActionParam } from '../../../hooks/useMailRequestActionParam';
import MailsShipmentRequest from './MailsShipmentRequest';
import { ErrorBoundary } from '@sentry/react';
import { ContentContainer } from '../../../../../components/DynamicSidebar/ContentContainer';
import { MessageBox } from '@usgm/shared-ui';
import { ShipmentCategory } from '@usgm/inbox-api-types';

export type ShipmentDrawerProps = {
  handleClose?: () => void;
};
function ShipmentDrawer({ handleClose }: ShipmentDrawerProps) {
  const dispatch = useAppDispatch();
  const { request, cancelRequest } = useMailRequestActionParam();
  const mailsToShip = useAppSelector(mailsToShipSelectors.selectAll);
  const { shipmentId } = useAppSelector(selectShippingDetails);
  const shipmentType = useAppSelector(selectShipmentType);

  const handleCloseSidebar = useCallback(() => {
    cancelRequest();
    dispatch(mailsShipmentSlice.actions.resetState());
    handleClose?.();
  }, [cancelRequest, dispatch, handleClose]);

  const createDrawerName = shipmentType === ShipmentCategory.ShipmentRequest ? 'Ship' : 'Deposit Check';
  const editDrawerName = shipmentType === ShipmentCategory.ShipmentRequest ? 'Shipment' : 'Deposit Check';

  const sidebarTitle = shipmentId
    ? `Edit ${editDrawerName} ${shipmentId}`
    : mailsToShip.length === 1
    ? `${createDrawerName} item #${mailsToShip[0].id}`
    : `${createDrawerName} (${mailsToShip.length} Items)`;

  return (
    <DynamicSidebar
      disableSpacings
      open={mailsToShip.length > 0 && request === MailActionRequest.Ship}
      handleClose={handleCloseSidebar}
      title={sidebarTitle}
    >
      <ErrorBoundary
        onError={(error) => {
          console.error(error);
        }}
        fallback={
          <ContentContainer>
            <MessageBox
              severity="info"
              description="Geolocation and shipping rates are currently unavailable. Please try again later."
            />
          </ContentContainer>
        }
      >
        <MailsShipmentRequest handleClose={handleCloseSidebar} />
      </ErrorBoundary>
    </DynamicSidebar>
  );
}

export default ShipmentDrawer;
