import { Typography } from '@mui/material';
import { Badge } from '../Badge';
import FlexBox from '../FlexBox';

export type TabIconProps = {
  badge?: string | number;
  tooltip?: React.ReactNode;
  badgeColor?: 'secondary' | 'primary' | 'default' | 'error' | 'info' | 'success' | 'warning';
  subText?: string;
};
function TabIcon({ badge, tooltip, badgeColor = 'secondary', subText }: TabIconProps) {
  return (
    <>
      {(!!badge || !!tooltip) && (
        <FlexBox>
          {tooltip ? (
            <FlexBox justifyContent="center" ml={0.25} component="span">
              {tooltip}
            </FlexBox>
          ) : null}
          {badge ? (
            <FlexBox justifyContent="center" ml={1} component="span">
              <Badge badgeContent={badge} color={badgeColor} />{' '}
            </FlexBox>
          ) : null}
        </FlexBox>
      )}
      {subText && (
        <Typography className="tab-subtext" component="span">
          {subText}
        </Typography>
      )}
    </>
  );
}

export default TabIcon;
