import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps, alpha, styled } from '@mui/material';
import { forwardRef } from 'react';

const Icon = styled('span', { shouldForwardProp: (prop) => prop !== 'hasError' })<{ hasError?: boolean }>(
  ({ theme, hasError }) => ({
    borderRadius: theme.shape.borderRadius * 1.5,
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginLeft: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${hasError ? theme.palette.error.main : theme.palette.primary.main}`,
    'input:hover ~ &, .Mui-focusVisible &': {
      boxShadow: `0px 0px 0px 3.2px ${alpha(hasError ? theme.palette.error.main : theme.palette.primary.main, 0.2)}`,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      border: `2px solid ${theme.palette.action.disabled}`,
    },
  }),
);

const CheckedIcon = styled(Icon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,

  '&::before': {
    display: 'block',
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath" +
      "  stroke='white' stroke-width='2' stroke-linecap='square' d='M3.5 8L6.5 11L13.5 4.5' /%3E%3C/svg%3E\")",
    content: '""',
  },
}));

export const Checkbox = forwardRef<HTMLButtonElement, MuiCheckboxProps & { hasError?: boolean }>((props, ref) => {
  return (
    <MuiCheckbox
      ref={ref}
      {...props}
      sx={{
        ...props.sx,
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<CheckedIcon />}
      icon={<Icon hasError={props.hasError} />}
    />
  );
}) as typeof MuiCheckbox;
