import * as Sentry from '@sentry/react';
import { ENVIRONMENT } from './env';

const EnvironmentsSupportingSentry = ['staging', 'mydaak', 'production'];

const initSentry = () => {
  if (!EnvironmentsSupportingSentry.includes(ENVIRONMENT.ENV)) return;

  Sentry.init({
    dsn: ENVIRONMENT.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

export default initSentry;
