import { Box, Typography, alpha, styled, useTheme } from '@mui/material';
import { FlexBox, useMobileMode } from '@usgm/shared-ui';
import BusinessIcon from '../assets/company.svg?react';
import PersonalIcon from '../assets/group.svg?react';
import TruncateMarkup from 'react-truncate-markup';

export const IconContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'color' })<{ color?: string }>(
  ({ theme, color }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    borderRadius: theme.shape.borderRadius * 2,
    color: color || theme.palette.primary.main,
    backgroundColor: alpha(color || theme.palette.primary.main, 0.15),
    marginRight: theme.spacing(2),
  }),
);

export function BusinessBadge() {
  const theme = useTheme();
  return (
    <IconContainer color={theme.palette.primary.main}>
      <BusinessIcon />
    </IconContainer>
  );
}

export function PersonalBadge() {
  const theme = useTheme();
  return (
    <IconContainer color={theme.palette.success.main}>
      <PersonalIcon />
    </IconContainer>
  );
}

export type IconHeaderProps = {
  title: string;
  isPersonal?: boolean;
  info?: {
    name: string;
    relation: string;
  };
};

export type BusinessCardHeaderProps = Omit<IconHeaderProps, 'title'>;

export function IconHeader({ title, isPersonal, info }: IconHeaderProps) {
  const theme = useTheme();
  const isMobile = useMobileMode();
  return (
    <FlexBox>
      {isPersonal ? <PersonalBadge /> : <BusinessBadge />}
      <TruncateMarkup>
        <Typography variant="h5">
          {isMobile ? info?.name || title : info?.name ? `${title} - ${info.name}` : title}
        </Typography>
      </TruncateMarkup>

      {info?.relation && (
        <Typography ml={1} variant="subtitle2" component="span" color={theme.customColors.dark[300]}>
          ({info?.relation})
        </Typography>
      )}
    </FlexBox>
  );
}

function BusinessCardHeader({ isPersonal = true, info }: BusinessCardHeaderProps) {
  const title = isPersonal ? 'For Personal Use' : 'For Business Use';

  return <IconHeader isPersonal={isPersonal} title={title} info={info} />;
}

export default BusinessCardHeader;
