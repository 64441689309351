import { inboxHelpers } from '@usgm/utils';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export function usePaginationParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const limit = Number(searchParams.get('limit')) || inboxHelpers.DEFAULT_PAGINATION_LIMIT;
  const skip = Number(searchParams.get('skip')) || 0;

  const setPaginationParams = useCallback(
    ({ limit, skip }: { limit?: number; skip?: number }) => {
      const newLimit = limit || inboxHelpers.DEFAULT_PAGINATION_LIMIT;
      const newSkip = skip || 0;
      searchParams.set('limit', newLimit.toString());
      searchParams.set('skip', newSkip.toString());
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return { limit, skip, setPaginationParams };
}
