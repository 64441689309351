export function convertDaysToPeriod(days: number): string {
  const daysInMonth = 30;
  const daysInYear = 365;

  if (days >= daysInYear) {
    const years = Math.floor(days / daysInYear);
    return years === 1 ? 'year' : `${years} years`;
  } else {
    const months = Math.floor(days / daysInMonth);
    return months === 1 ? 'month' : `${months} months`;
  }
}
