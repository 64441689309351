import { AccountRelation } from '@usgm/inbox-api-types';

export const DOCUMENTS_CAN_BE_SKIPPED_RELATIONS = [
  AccountRelation.MinorChild,
  AccountRelation.Deceased,
  AccountRelation.Company,
  AccountRelation.Trust,
];

export const NOTARIZATION_CAN_BE_SKIPPED_RELATIONS = [AccountRelation.MinorChild, 'MINOR_CHILD'];

export const BUSINESS_ACCOUNT_RELATIONS = [AccountRelation.Company, AccountRelation.Trust];

export const FAQ_DATA = [
  {
    title: 'How does online notarization take place?',
    content:
      'Once you book your appointment, you’ll receive a Zoom link via email. In the call, the notary will verify the ID’s, review the form and have you sign digitally. The process takes about 5  minutes.',
  },
  {
    title: 'How should I prepare for the online notarization?',
    content: `Have your email with the Zoom link handy along with your ID, and a camera-equipped device (like a phone or laptop). At the appointment time, just click the Zoom link, and we'll take it from there! If you need a hand with English, feel free to use Google Translate. Looking forward to assisting you!`,
  },
  {
    title: 'What if I need to change the time later?',
    content: `You can reschedule your appointment using the link in the appointment email. This can be done prior to the appointment time and within the first 10 minutes of the appointment itself.`,
  },
];
