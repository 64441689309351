import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, IconButton, Theme, Toolbar, styled, useMediaQuery } from '@mui/material';
import React from 'react';

import { FlexBox, UserAvatar } from '@usgm/shared-ui';
import Logo from '../../../../components/Logo';
import HeaderAccountInfo from '../../../../components/layouts/public/components/HeaderAccountInfo';
import { useAuth } from '../../../auth/hooks/useAuth';
import { ThemeSwitcher } from '../../../preferences/components/ThemeSwitcher';
import HeaderActions from '../HeaderActions';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: theme.spacing(8),
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'fixed',
  zIndex: theme.zIndex.drawer + 1,
}));

export type HeaderProps = {
  onOpenMainSidebar: () => void;
  onOpenAccountSidebar: () => void;
};

export function Header({ onOpenMainSidebar, onOpenAccountSidebar }: HeaderProps) {
  const isActionsHidden = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const { user } = useAuth();
  return (
    <StyledAppBar>
      <StyledToolbar>
        <FlexBox alignSelf="stretch" width="100%">
          {isActionsHidden && (
            <Box>
              <IconButton aria-label="Open Main Menu" edge="end" onClick={onOpenMainSidebar}>
                <MenuIcon />
              </IconButton>
            </Box>
          )}

          <Logo />
          <FlexBox alignSelf="stretch">
            {!isActionsHidden ? (
              <>
                <ThemeSwitcher />
                <HeaderActions />
                <HeaderAccountInfo />
              </>
            ) : (
              user && <UserAvatar onClick={onOpenAccountSidebar} name={user.name} />
            )}
          </FlexBox>
        </FlexBox>
      </StyledToolbar>
    </StyledAppBar>
  );
}

export default React.memo(Header);
