import { CircularProgress, Typography, styled, useTheme, Box } from '@mui/material';
import { FlexBox } from '@usgm/shared-ui';

import { ReactNode, useState } from 'react';
import { CaptchaType } from '@inbox/constants';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ReCaptchaV2 from '../../../../../../captcha/ReCaptchaV2';
import { useRecaptchaRequest } from '../../../../../../hooks/useRecaptchaRequest';

import ZipIcon from '../../assets/zipIcon.svg?react';
import PdfIcon from '../../assets/pdfIcon.svg?react';

import { AccountRelation } from '@usgm/inbox-api-types';
import { saveAs } from 'file-saver';

import { useAccountApiClient } from '../../../../../../api/axios/AccountApiContext';

export type DownloadForm1583Props = {
  label?: string | ReactNode;
  showIcon?: boolean;
  accounts?: Array<{ id: string; name: string; relation: AccountRelation }>;
  fullWidth?: boolean;
};

const DownloadBox = styled(FlexBox)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.5, 3),
  backgroundColor: theme.palette.background.cardHover,
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1, 3),
  },
}));

export function DownloadForm1583({ accounts, label, showIcon = true, fullWidth = true }: DownloadForm1583Props) {
  const theme = useTheme();
  const accountApiClient = useAccountApiClient();

  const [isDownloading, setIsDownloading] = useState(false);
  const isMultiple = !accounts || accounts.length > 1;
  const fileName = isMultiple ? 'Form1583.zip' : accounts[0].name + '_Form1583.pdf';
  const fileIcon = isMultiple ? <ZipIcon /> : <PdfIcon />;

  const downloadFormsQuery = async ({ token, captchaVersion }: { token: string; captchaVersion: number }) => {
    const urlParams = new URLSearchParams({ token, cap_version: String(captchaVersion) });
    if (accounts?.length === 1) {
      urlParams.append('userId', accounts[0].id);
    }
    setIsDownloading(true);
    const response = await accountApiClient.get(`download/form?${urlParams.toString()}`, {
      responseType: 'blob',
      interceptMessages: true,
    });
    return response;
  };

  const { makeRequest, requestId } = useRecaptchaRequest<unknown, ReturnType<typeof downloadFormsQuery>>({
    action: CaptchaType.IdsDownload,
    apiRequest: downloadFormsQuery,
  });

  const handleDownloadFiles = async () => {
    const response = await makeRequest();
    if (response?.data) {
      const newBlob = new Blob([response.data], { type: isMultiple ? 'application/zip' : 'application/pdf' });
      saveAs(newBlob, fileName);
    }
    setIsDownloading(false);
  };

  return (
    <>
      <DownloadBox width={fullWidth ? '100%' : undefined} onClick={handleDownloadFiles}>
        {isDownloading ? (
          <Box width="100%" textAlign="center">
            <CircularProgress size={16} />
          </Box>
        ) : (
          <FlexBox display="inline-flex" gap={1}>
            {showIcon ? fileIcon : null}
            <Typography textAlign="center" color={theme.palette.primary.main}>
              {label || fileName}
            </Typography>
            {showIcon ? <FileDownloadOutlinedIcon /> : null}
          </FlexBox>
        )}
      </DownloadBox>
      <ReCaptchaV2 requestId={requestId} />
    </>
  );
}

export default DownloadForm1583;
