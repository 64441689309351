import { useCallback, useEffect, useMemo } from 'react';

import { DtoBankAccount } from '@usgm/inbox-api-types';
import { Controller, useFormContext } from 'react-hook-form';

import { Box, styled, Typography, useTheme } from '@mui/material';

import { FlexBox, Textarea, useDialog } from '@usgm/shared-ui';

import { useAppDispatch, useAppSelector } from '../../../../../../../../../store';
import ControlledInput from '../../../../../../../components/ControlledInput';
import { useGetBankAccountsQuery } from '../../../../../../../inboxAccountsApi';
import { mailsShipmentSlice, selectBankAccount } from '../../mailsShipmentSlice';
import BankAccountManagerDialog from './BankAccountManagerDialog';
import BankAccountsSelectionList from './BankAccountsSelectionList';
import { DepositSlipSchemaType } from './schemas';

const SeparatorText = styled(Box)(({ theme }) => ({
  color: theme.customColors.dark[400],
  fontSize: '0.75rem',
  fontWeight: theme.typography.fontWeightBold,
  textTransform: 'uppercase',
  position: 'relative',
  textAlign: 'center',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    height: 1,
    width: '100%',
    backgroundColor: theme.palette.divider,
    zIndex: 0,
  },
  '& span': {
    position: 'relative',
    zIndex: 1,
    display: 'inline-block',
    padding: theme.spacing(0, 0.75),

    backgroundColor: theme.palette.background.default,
  },
}));

function DepositSlipForm() {
  const chooseBankAccountsDialog = useDialog({ open: false, value: '' });
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { data: bankAccounts } = useGetBankAccountsQuery();
  const bankAccount = useAppSelector(selectBankAccount);

  const {
    control,
    formState: { errors },
  } = useFormContext<DepositSlipSchemaType>();

  const openBankAccountManagerDialog = () => {
    chooseBankAccountsDialog.openDialog();
  };

  const inputProps = useMemo(
    () => ({ sx: { backgroundColor: theme.palette.background.paper } }),
    [theme.palette.background.paper],
  );

  const onAccountClick = useCallback(
    (account: DtoBankAccount) => {
      dispatch(mailsShipmentSlice.actions.setBankAccount(account));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!bankAccount && bankAccounts?.list?.length) {
      dispatch(mailsShipmentSlice.actions.setBankAccount(bankAccounts.list[0]));
    }
    if (bankAccount && !bankAccounts?.list?.length) {
      dispatch(mailsShipmentSlice.actions.setBankAccount(null));
    }
  }, [bankAccount, bankAccounts?.list, dispatch]);

  return (
    <>
      <FlexBox gap={2}>
        <ControlledInput<DepositSlipSchemaType>
          InputProps={inputProps}
          control={control}
          errors={errors}
          name="items"
          label="ITEM ID(S)"
          disabled
        />
        <ControlledInput<DepositSlipSchemaType>
          InputProps={inputProps}
          control={control}
          errors={errors}
          name="boxNumber"
          label="Mail Box"
          disabled
        />
      </FlexBox>
      <SeparatorText>
        <span>Will be sent for deposit to</span>
      </SeparatorText>
      <Box mb={4}>
        <BankAccountsSelectionList
          onManageClick={openBankAccountManagerDialog}
          onAddClick={openBankAccountManagerDialog}
          onSelect={onAccountClick}
          selectedId={bankAccount?.uuid}
        />
      </Box>
      <Box mb={1}>
        <Controller
          control={control}
          name="instructions"
          render={({ field }) => (
            <>
              <Typography mb={1} fontWeight={theme.typography.fontWeightBold}>
                Additional Instruction
              </Typography>
              <Textarea {...field} resize="none" fullWidth placeholder="Tell us how you want the items to be packed" />
            </>
          )}
        />
      </Box>
      <Box>
        <Typography mb={1} fontSize="0.875rem" color={theme.palette.text.disabled}>
          Note:
        </Typography>
        <Typography fontSize="0.875rem">
          We will attach this slip to your check and mail it to your bank. Please provide the bank address in the next
          screen.
        </Typography>
      </Box>
      <BankAccountManagerDialog
        open={chooseBankAccountsDialog.open}
        onCancel={chooseBankAccountsDialog.closeDialog}
        onClose={chooseBankAccountsDialog.closeDialog}
        confirmText={'Add'}
      />
    </>
  );
}

export default DepositSlipForm;
