import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { snakeCaseKeys } from '@usgm/utils';
import { Preloader } from '@usgm/shared-ui';

import NotFoundPage from '../../../../../../../app/pages/NotFoundPage';

import { useGetAddressByUuidQuery } from '../../../../../inboxAccountsApi';
import EditAddress from '../../../components/AddressBook/EditAddress';
import { ShippingAddressFormSchemaType } from '../../../components/AddressBook/ShippingAddressForm';

function EditAddressPage() {
  const { addressUuid } = useParams();
  const { data, isLoading, isSuccess } = useGetAddressByUuidQuery(
    { addressUuid: addressUuid || '' },
    {
      skip: !addressUuid,
    },
  );

  const defaultValues = useMemo<Partial<ShippingAddressFormSchemaType>>(() => {
    if (!data) {
      return {};
    }
    const { country, countryCode, postalCode, addressLine2, addressLine3, ...rest } = data;

    return {
      ...snakeCaseKeys<Partial<ShippingAddressFormSchemaType>>(rest),
      address_line_2: addressLine2 || '',
      address_line_3: addressLine3 || '',
      country: {
        code: countryCode || country,
        name: country,
        id: countryCode || country,
      },
      zip: data.postalCode,
    };
  }, [data]);

  return (
    <>
      {isLoading && <Preloader />}
      {data && !isLoading && addressUuid && <EditAddress defaultValues={defaultValues} addressUuid={addressUuid} />}
      {/*@TODO: Modify NotFoundPage to accept and use icon and text, so we can use it in different occasions (e.g. address), not only for pages */}
      {!isLoading && (!isSuccess || !data) && <NotFoundPage />}
    </>
  );
}

export default EditAddressPage;
