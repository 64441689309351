import { CaptchaType } from '@inbox/constants';
import { Box, CircularProgress } from '@mui/material';
import { UserType } from '@usgm/inbox-api-types';
import { FlexBox, Toast } from '@usgm/shared-ui';
import { onboardingUtils } from '@usgm/utils';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReCaptchaV2 from '../../../../../../../../../captcha/ReCaptchaV2';
import { useRecaptchaRequest } from '../../../../../../../../../hooks/useRecaptchaRequest';
import { useAppSelector } from '../../../../../../../../../store';
import { onboardingApi } from '../../../../../../onboarding/api';
import DocumentsCard, { DocumentsCardProps } from '../../../../../../onboarding/components/DocumentsCard/index';
import { useAccountNamesMap } from '../../../../../../onboarding/hooks/useAccountNamesMap';
import { SETTINGS_PATHS } from '../../../../../paths';
import { accountsNamesDocumentsSelectors, selectIsLoadingDocuments } from '../accountNamesSlice';
import { StepContentContainer } from '../components/StepContentContainer';
import StepNotRequiredMessage from '../components/StepNotRequiredMessage';

function UploadIds() {
  const navigate = useNavigate();

  const { accountNameUuid: accountId } = useParams<{ accountNameUuid: string }>();
  const isLoading = useAppSelector(selectIsLoadingDocuments);
  const allAccountDocuments = useAppSelector(accountsNamesDocumentsSelectors.selectAll);

  const { accountNamesMap, isLoading: isAccountNamesLoading, accountsList } = useAccountNamesMap();

  const [trigger] = onboardingApi.endpoints.getUserDocuments.useLazyQuery();
  const { error, makeRequest, requestId, isReadyToUse } = useRecaptchaRequest({
    action: CaptchaType.IdsDownload,
    apiRequest: trigger,
  });

  useEffect(() => {
    if (isReadyToUse) {
      makeRequest({ includeFileUrl: true });
    }
  }, [isReadyToUse, makeRequest]);

  const accountsNameMapWithDocuments = useMemo(() => {
    const refreshMap = new Map(accountNamesMap);

    Array.from(refreshMap.entries()).forEach(([userId, accountData]) => {
      accountData.documents = new Map([
        [UserType.Primary, null],
        [UserType.Secondary, null],
      ]);
      const accountDocuments = allAccountDocuments.find((doc) => doc.userId === userId);
      if (accountDocuments) {
        accountDocuments.documents.forEach((document) => {
          accountData.documents.set(document.userType, document);
        });
      }
    });

    return refreshMap;
  }, [accountNamesMap, allAccountDocuments]);

  const onComplete = useCallback(() => {
    if (accountId) {
      navigate(SETTINGS_PATHS.NOTARIZE_ACCOUNT_NAME(accountId));
    }
  }, [accountId, navigate]);

  if (!accountId || !accountNamesMap) {
    return null;
  }

  const accountData = accountsList?.find((account) => account.user.uuid === accountId);

  if (isLoading || isAccountNamesLoading || !accountData) {
    return (
      <FlexBox minHeight={400} justifyContent="center">
        <CircularProgress />
      </FlexBox>
    );
  }

  if (accountData && onboardingUtils.DOCUMENTS_CAN_BE_SKIPPED_RELATIONS.includes(accountData.relation.relationType)) {
    return (
      <StepNotRequiredMessage
        actionName="upload documents"
        onContinue={onComplete}
        userId={accountId}
        relationType={accountData.relation.relationType}
      />
    );
  }

  return allAccountDocuments.length > 0 ? (
    <StepContentContainer>
      <FlexBox width="100%" flexDirection="column" gap={3}>
        <ReCaptchaV2 requestId={requestId} />
        {allAccountDocuments
          .filter(({ userId }) => {
            return userId === accountId;
          })
          .map(({ userId }) => (
            <Box key={userId} width="100%">
              <DocumentsCard
                collapsible={false}
                isBusiness={false}
                onContinue={onComplete}
                scrollIndex={userId}
                userId={userId}
                accountNamesMap={accountsNameMapWithDocuments}
              />
            </Box>
          ))}
      </FlexBox>
      {error && <Toast severity="error" description={error.message} />}
    </StepContentContainer>
  ) : null;
}

export default UploadIds;
