import { Box, CircularProgress, Typography, styled } from '@mui/material';
import { Button, FlexBox, Textarea, useResponsiveModes } from '@usgm/shared-ui';

import SendIcon from '../../../assets/icons/send.svg?react';
import { useState } from 'react';
import { useAddMailNoteMutation, useUpdateMailNoteByIdMutation } from '../api';
import { BaseMail, DtoNote } from '@usgm/inbox-api-types';

export type SendNoteProps = {
  onCancel?: () => void;
  id: BaseMail['id'];
  onSend?: () => void;
  noteData?: DtoNote;
};

const ActionsContainer = styled(FlexBox, { shouldForwardProp: (prop) => prop !== 'asRow' })<{ asRow?: boolean }>(
  ({ theme, asRow = false }) => ({
    gap: theme.spacing(2),
    flexDirection: asRow ? 'row-reverse' : 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    button: {
      width: asRow ? '100%' : 'auto',
    },
  }),
);

const Root = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  boxShadow: `0px 0px 10px 0px rgba(51, 51, 51, 0.10), 0px 0px 40px 0px rgba(85, 85, 85, 0.15)`,
}));

function SendNote({ onCancel, id, onSend, noteData }: SendNoteProps) {
  const [value, setValue] = useState(noteData?.note || '');
  const [addMailNoteMutation, addQuery] = useAddMailNoteMutation();
  const [updateMailNoteMutation, updateQuery] = useUpdateMailNoteByIdMutation();

  const isLoading = addQuery.isLoading || updateQuery.isLoading;

  const { isDownMobileLandscape } = useResponsiveModes();

  const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };
  const handleSendClick = async () => {
    if (!value) return;
    if (noteData?.id) {
      await updateMailNoteMutation({ id: noteData.id, note: value, mailId: noteData.mailId });
    } else {
      await addMailNoteMutation({ id, note: value });
    }

    onSend?.();
  };
  return (
    <Root>
      <Typography mb={1} fontWeight={600}>
        Mail Note:
      </Typography>
      <FlexBox flexDirection={isDownMobileLandscape ? 'column' : 'row'} gap={2} alignItems="top">
        <Box flex="1 1 auto">
          <Textarea
            resize="none"
            onChange={handleMessageChange}
            value={value}
            defaultValue={value}
            fullWidth
            placeholder="Add Note..."
          />
        </Box>
        <ActionsContainer asRow={isDownMobileLandscape}>
          <Button onClick={handleSendClick} disabled={!value || isLoading} variant={!value ? 'outlined' : 'contained'}>
            {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
          </Button>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        </ActionsContainer>
      </FlexBox>
    </Root>
  );
}

export default SendNote;
