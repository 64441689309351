import { IconButton, styled } from '@mui/material';
import { DocumentUploadCard } from './styled/DocumentUploadCard';
import CloseIcon from '@mui/icons-material/Close';
import FilePreview from './FilePreview';

const DeleteButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.background.paper,
  padding: 0,
  background: theme.palette.mode === 'light' ? theme.customColors.dark[400] : theme.palette.grey[200],
  width: '1.5rem',
  height: '1.5rem',
  position: 'absolute',
  top: 10,
  right: 10,
}));

export type DocumentPreviewProps = {
  children: React.ReactNode;
  onRemove?: () => void;
  file?: File;
  url?: string;
};

export function DocumentPreview({ children, file, onRemove, url }: DocumentPreviewProps) {
  return (
    <DocumentUploadCard backgroundColor="#B4CFFE">
      {!!onRemove && (
        <DeleteButton onClick={onRemove}>
          <CloseIcon fontSize="small" />
        </DeleteButton>
      )}
      {children}
      <FilePreview enablePopupView url={url} file={file} />
    </DocumentUploadCard>
  );
}
