import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getKeys, inboxHelpers, onboardingUtils } from '@usgm/utils';
import { RootState } from '../../../../../../store';

const FEATURE_NAME = 'COMPANY_ADDRESSES';

const companyAddressesAdapter = createEntityAdapter<{ id: number } & onboardingUtils.TCompanyData>();

const initialState = companyAddressesAdapter.getInitialState();

const storedData = inboxHelpers.getStorageManager().getItem('companiesData');

const savedItemIds = getKeys(storedData || {});

const savedItems = storedData ? savedItemIds.map((id) => ({ ...storedData[id], id })) : [];

const storedState = companyAddressesAdapter.setAll(initialState, savedItems);

export const companyAddressesSlice = createSlice({
  name: FEATURE_NAME,
  initialState: storedState,
  reducers: {
    saveCompanyData: (state, action: PayloadAction<{ id: number; data: onboardingUtils.TCompanyData }>) => {
      const exists = !!state.entities[action.payload.id];
      if (!exists) {
        companyAddressesAdapter.upsertOne(state, {
          ...action.payload.data,
          id: action.payload.id,
        });
      } else {
        companyAddressesAdapter.updateOne(state, {
          id: action.payload.id,
          changes: { ...action.payload.data, id: action.payload.id },
        });
      }

      inboxHelpers.getStorageManager().setItem('companiesData', {
        ...state.entities,
        [action.payload.id]: { ...action.payload.data, id: action.payload.id },
      });
    },
    removeCompanyData: (state, action: PayloadAction<number>) => {
      companyAddressesAdapter.removeOne(state, action.payload);
      const oldCompanies = inboxHelpers.getStorageManager().getItem('companiesData');
      if (oldCompanies?.[action.payload]) {
        delete oldCompanies[action.payload];

        inboxHelpers.getStorageManager().setItem('companiesData', oldCompanies);
      }
    },
    clearCompanyData: (state) => {
      companyAddressesAdapter.removeAll(state);
      inboxHelpers.getStorageManager().removeItem('companiesData');
    },
  },
});

const selectState = (state: RootState) => state[FEATURE_NAME];

export const companyAddressesSelectors = companyAddressesAdapter.getSelectors(selectState);
