import { Typography, styled } from '@mui/material';
import { ConfirmationDialog, ConfirmationDialogProps } from './';
import FlexBox from './FlexBox';
import { inboxHelpers } from '@usgm/utils';

export type ConfirmPurchaseDialogProps = Omit<ConfirmationDialogProps, 'content' | 'confirmText' | 'title'> & {
  total: number;
  subtotal: number;
  description: string;
};

const SubTotalBox = styled(FlexBox)(({ theme }) => ({
  borderBottom: `1px dashed ${theme.customColors.dark[300]}`,
  padding: theme.spacing(2, 0),
  marginBottom: theme.spacing(3),
  'span:first-of-type': {
    color: theme.customColors.dark[300],
  },
}));

const TotalBox = styled(FlexBox)(({ theme }) => ({
  'span:first-of-type': {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export function ConfirmPurchaseDialog({ subtotal, total, description, ...dialogProps }: ConfirmPurchaseDialogProps) {
  return (
    <ConfirmationDialog
      {...dialogProps}
      title="Confirm Your Purchase"
      content={
        <>
          <Typography>{description}</Typography>
          <SubTotalBox>
            <Typography component="span">Subtotal</Typography>
            <Typography variant="h5" component="span">
              {inboxHelpers.formatPrice(subtotal)}
            </Typography>
          </SubTotalBox>
          <TotalBox>
            <Typography component="span">Total</Typography>
            <Typography variant="h4" component="span">
              {inboxHelpers.formatPrice(total)}
            </Typography>
          </TotalBox>
        </>
      }
    />
  );
}
