import { FormControlLabel, Typography, useTheme } from '@mui/material';
import { StyledFormControl } from './styled/StyledFormControl';

import { useAppDispatch, useAppSelector } from '../../../../../../../../../store';
import { mailsShipmentSlice, selectIsExpediteShipping } from '../../mailsShipmentSlice';
import { inboxHelpers } from '@usgm/utils';
import { Checkbox } from '@usgm/shared-ui';

function ExpediteShippingControl({ expeditePrice }: { expeditePrice?: number | string }) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isExpediteShipping = useAppSelector(selectIsExpediteShipping);

  const handleChange = () => {
    dispatch(mailsShipmentSlice.actions.setIsExpediteShipping(!isExpediteShipping));
  };

  return (
    <StyledFormControl>
      <FormControlLabel
        value={isExpediteShipping}
        defaultChecked={isExpediteShipping}
        onChange={handleChange}
        control={<Checkbox />}
        label={
          <>
            <Typography mr={1} fontWeight={theme.typography.fontWeightBold} component="span">
              Expedite shipping to ship today
            </Typography>
            {expeditePrice && (
              <Typography color={theme.customColors.dark[300]} component="span">
                ({inboxHelpers.formatPrice(Number(expeditePrice))} add on)
              </Typography>
            )}
          </>
        }
      />
    </StyledFormControl>
  );
}

export default ExpediteShippingControl;
