import { Box, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { UpdateWarehouseParams, UserWarehouseAddress } from '@usgm/inbox-api-types';
import { FlexBox, RoundedBadge, SectionTitle, Toast, UnorderedStepper } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import { Ref, useEffect, useState } from 'react';
import LocationIcon from '../../../../../assets/location.svg?react';
import { useUpdateWarehouseMutation } from '../../../../addressPicker/api';
import { useEditAddressSteps } from '../useEditAddressSteps';
import ConfirmationStep from './ConfirmationStep';
import LocationStep from './LocationStep';
import { PlansStep } from './PlansStep';

enum STEPS {
  LOCATION_STEP = 0,
  PLAN_STEP = 1,
  CONFIRMATION_STEP = 2,
}

const Card = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(2, 2),
  marginBottom: theme.spacing(3),
}));

function WarehouseCard({ address }: { address: UserWarehouseAddress }) {
  const theme = useTheme();
  return (
    <FlexBox flexDirection="column">
      <FlexBox mb={1.5} width="100%" alignItems="center">
        <RoundedBadge
          svgcolor={theme.palette.primary.contrastText}
          color={theme.palette.secondary.main}
          bg={theme.palette.secondary.main}
          size={theme.spacing(8.5)}
        >
          <LocationIcon />
        </RoundedBadge>
        <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 3, paddingRight: 5.25, flex: 1 }}>
          <Typography sx={{ color: theme.customColors.dark[300] }}>Your selected location</Typography>
          <SectionTitle sx={{ textAlign: 'left', marginBottom: 0 }}>{address.name}</SectionTitle>
          <Typography sx={{ color: theme.customColors.dark[400] }}>
            {inboxHelpers.stringifyAddress({ addr: address })}
          </Typography>
        </Box>
      </FlexBox>
    </FlexBox>
  );
}

function ChangeAddressContent({
  reference,
  warehouseAddress,
  handleClose,
  canConfirm,
  isDesktop = false,
  handleLoading,
}: {
  reference?: Ref<{ onConfirm: () => void }>;
  warehouseAddress: UserWarehouseAddress;
  handleClose: () => void;
  canConfirm?: (state: boolean) => void;
  isDesktop?: boolean;
  handleLoading?: (state: boolean) => void;
}) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(STEPS.LOCATION_STEP);
  const [hasError, setHasError] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [updateWarehouse, { isLoading, error }] = useUpdateWarehouseMutation();

  const handleClick = (step?: number) => {
    if (step || step === STEPS.LOCATION_STEP) {
      setActiveStep(step);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleConfirm = async ({ userId, planId, termId, warehouseId }: UpdateWarehouseParams) => {
    const results = await updateWarehouse({ userId, planId, termId, warehouseId });
    const hasError = 'error' in results;
    setHasError(hasError);
    if (!hasError) {
      handleClose();
    }
  };

  useEffect(() => {
    handleLoading?.(isLoading);
  }, [handleLoading, isLoading]);

  useEffect(() => {
    if (typeof canConfirm === 'function') {
      canConfirm(activeStep !== STEPS.CONFIRMATION_STEP);
    }
  }, [activeStep, canConfirm]);

  const { steps } = useEditAddressSteps(activeStep, handleClick);
  return (
    <>
      {!isDesktop && (
        <Card>
          <WarehouseCard address={warehouseAddress} />
        </Card>
      )}
      <Box mb={8}>
        <UnorderedStepper steps={steps} activeStep={activeStep} />
      </Box>
      <Box sx={{ mt: 3 }}>
        {activeStep === STEPS.LOCATION_STEP && (
          <LocationStep isDesktop={isDesktop} handleSelection={handleClick} changeWarehouseMode={true} />
        )}
        {activeStep === STEPS.PLAN_STEP && (
          <PlansStep isDesktop={isDesktop} handleSelection={handleClick} changeWarehouseMode={true} />
        )}
        {activeStep === STEPS.CONFIRMATION_STEP && (
          <ConfirmationStep
            ref={reference}
            isDesktop={isDesktop}
            handleClose={handleClose}
            handleConfirm={handleConfirm}
            updateWarehouseLoading={isLoading}
          />
        )}
      </Box>
      {hasError && error && (
        <Toast
          autoHideDuration={5000}
          maxWidth={!isMobile ? '460px' : 1}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          title={error.message}
        />
      )}
    </>
  );
}

export default ChangeAddressContent;
