import { Step, StepIconProps, StepLabel, Stepper, Theme, alpha, styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { inboxHelpers } from '@usgm/utils';

type StyledStepperProps = {
  version?: 'filled' | 'outlined';
  verticalOrder?: boolean;
};

export interface GeneralStepsProps extends StyledStepperProps {
  title?: string;
  steps: Array<{ label: string; onClick?: () => void }>;
  activeStep?: number;
}

const createVerticalOrderStyles = (theme: Theme) => ({
  alignItems: 'flex-start',
  '.MuiStep-root': {
    flex: 1,
    '.MuiStepLabel-root': {
      flexDirection: 'column',
      textAlign: 'center',
      '.MuiStepLabel-labelContainer': {
        marginTop: theme.spacing(1),
      },
    },
  },
});

export const StyledStepper = styled(Stepper, {
  shouldForwardProp: (prop) => prop !== 'version' && prop !== 'verticalOrder',
})<StyledStepperProps>(({ theme, version, verticalOrder }) => ({
  justifyContent: 'center',
  '& .MuiStepLabel-root': {
    cursor: 'pointer',
    '& .Mui-disabled': {
      cursor: 'pointer',
    },
  },
  '& .MuiStepLabel-label': {
    color: theme.customColors.dark[200],
  },
  '& .MuiStepLabel-label.Mui-active': {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  '& .MuiStepLabel-label.Mui-completed': {
    color: theme.palette.primary.dark,
  },
  '& .cm-icon': {
    fontSize: '0.875rem',
    color: theme.palette.primary.main,
    backgroundColor: version === 'outlined' ? 'none' : '#F3F9FD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
    border: version === 'outlined' ? `2px solid ${theme.palette.primary.main}` : `2px solid #F3F9FD`,
    '& svg': {
      width: '1rem',
      height: '1rem',
    },
  },
  '& .cm-icon.cm-icon-active': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  '& .cm-icon.cm-icon-completed': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    border: `2px solid ${theme.palette.primary.dark}`,
  },
  ...(verticalOrder && createVerticalOrderStyles(theme)),
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    ...createVerticalOrderStyles(theme),
  },
}));

export const StyledConnector = styled('div', { shouldForwardProp: (prop) => prop !== 'noMargins' })<{
  noMargins?: boolean;
}>(({ theme, noMargins }) => ({
  display: 'flex',
  color: alpha(theme.palette.primary.main, 0.35),
  marginLeft: theme.spacing(noMargins ? 0 : 4),
  marginRight: theme.spacing(noMargins ? 0 : 4),
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
  },
}));

export const StepIconComponent = (props: StepIconProps) => {
  const { icon, active, completed } = props;
  return (
    <div className={`cm-icon ${completed ? 'cm-icon-completed' : active ? 'cm-icon-active' : ''}`}>
      {completed ? <CheckIcon /> : icon}
    </div>
  );
};

export type GeneralStepperProps = StyledStepperProps & {
  steps: Array<{ label: string; onClick?: () => void; disabled?: boolean }>;
  activeStep?: number;
};

export function GeneralStepper({ activeStep, steps, version, verticalOrder = false }: GeneralStepperProps) {
  return (
    <StyledStepper
      version={version}
      activeStep={activeStep}
      verticalOrder={verticalOrder}
      connector={
        <StyledConnector noMargins={verticalOrder}>
          <ChevronRightIcon />
        </StyledConnector>
      }
    >
      {steps.map((step, index) => (
        <Step disabled={step.disabled} onClick={step.onClick} key={index}>
          <StepLabel StepIconComponent={StepIconComponent}>{step.label}</StepLabel>
        </Step>
      ))}
    </StyledStepper>
  );
}
