export function extractErrorMessage(error: unknown, defaultMessage = 'An error occurred') {
  if (typeof error === 'string') {
    return error;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return defaultMessage;
}
