import { Box, Button, styled, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { memo, MouseEvent, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { type DtoPlan, PlanTerm, PlanType } from '@usgm/inbox-api-types';
import { Container, FlexBox, PlanCard, UnorderedStepper, useScrollToElement } from '@usgm/shared-ui';
import { inboxHelpers, pascalCase } from '@usgm/utils';

import { useAppSelector } from '../../../store';
import { DEFAULT_PLANS, PLAN_TYPES_MAPPER, SECTION_IDS } from '../config';

import { openCalendly } from '../../../helpers/openCalendly';
import { usePlanList } from '../../../hooks/usePlanList';
import { useRegistrationSteps } from '../../../hooks/useRegistrationSteps';
import { useSelectPlan } from '../hooks/useSelectPlan';
import { plansSelectors } from '../planPickerSlice';
import PlanTermSwitcher from './PlanTermSwitcher';
import { SelectPlanSubHeader } from './SelectPlanSubHeader';
import SelectedWarehouseBadge from './SelectedWarehouseBadge';
import { getAnnualTermSavingsInfo } from '../helpers/getAnnualTermSavingsInfo';

const Section = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: `${theme.spacing(3.875)} 0px ${theme.spacing(6)} 0px`,
}));

const SelectedWarehouseBadeContainer = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
}));

const PlanActions = ({
  onClick,
  text,
  type,
  selected,
}: {
  type: PlanType;
  onClick: () => void;
  text: string;
  selected?: boolean;
}) => {
  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    // To avoid card click handler
    e.stopPropagation();
    onClick();
  };
  return (
    <FlexBox flexDirection="column" width="100%" gap={1.5}>
      <Button onClick={handleButtonClick} variant={selected ? 'contained' : 'outlined'} fullWidth size="large">
        {text}
      </Button>
      {type === PlanType.Business && (
        <Button onClick={openCalendly} fullWidth size="large">
          Contact Sales for Custom Plan
        </Button>
      )}
    </FlexBox>
  );
};

export const PlanItem = memo(
  ({
    id,
    term,
    selected,
    onCardDeselect,
    onCardSelect,
    changeWarehouseMode,
    handleSelection,
  }: {
    id: DtoPlan['id'];
    term: PlanTerm;
    selected?: boolean;
    onCardSelect?: (id: PlanType) => void;
    onCardDeselect?: (id: PlanType) => void;
    changeWarehouseMode?: boolean;
    handleSelection?: () => void;
  }) => {
    const { onPlanSelect } = useSelectPlan(true, handleSelection);
    const theme = useTheme<Theme>();

    const plan = useAppSelector((state) => plansSelectors.selectById(state, id));
    const monthsCount = term === PlanTerm.Monthly ? 1 : 12;
    const title = pascalCase(plan.name);
    const config = PLAN_TYPES_MAPPER[plan.name];

    const defaultPrice = config?.price?.[term];
    const price = plan.membership.items.find((item) => item.months === monthsCount)?.price || defaultPrice;

    const handleSelect = useCallback(() => onCardSelect?.(plan.name), [onCardSelect, plan.name]);
    const handleDeselect = () => onCardDeselect?.(plan.name);

    const { badgeText } = getAnnualTermSavingsInfo({ plan, term });

    const actions = useMemo(() => {
      return (
        <PlanActions type={plan.name} onClick={onPlanSelect(plan)} text={`Select ${title} Plan`} selected={selected} />
      );
    }, [onPlanSelect, plan, selected, title]);

    if (!price) {
      console.warn(`No price found for plan ${plan.name}`);
    }
    if (!config) {
      console.error(`No config found for plan ${plan.name}`);
      return null;
    }
    if (!PLAN_TYPES_MAPPER[plan.name]) {
      console.error(`No config found for plan ${plan.name}`);
      return null;
    }
    const priceLabel = inboxHelpers.formatPrice(price);
    return (
      <PlanCard
        onClick={onPlanSelect(plan)}
        onMouseEnter={handleSelect}
        onMouseLeave={handleDeselect}
        title={title}
        image={config.image}
        price={priceLabel}
        subTitle={config.description}
        color={config.getColor(theme)}
        features={plan.features}
        changeWarehouseMode={changeWarehouseMode}
        badge={config.badge}
        featuresIcon={config.icon}
        actions={actions}
        savings={badgeText}
      />
    );
  },
);

export function PlansListSection() {
  const { steps, activeStepIndex } = useRegistrationSteps(1);
  const {
    pickedWarehouse,
    plans,
    term,
    isMobile,
    theme,
    handleCardDeselect,
    handleCardSelect,
    selectedCardType,
    badgeText,
    hasSavings,
  } = usePlanList();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();

  const handlePlanActionClick = () => {
    navigate(inboxHelpers.MAIN_PATHS.SELECT_VIRTUAL_ADDRESS);
  };

  const scrollToFeaturesList = useScrollToElement(SECTION_IDS.PLAN_FEATURES_LIST);

  return (
    <Section>
      {pickedWarehouse ? (
        <Container mb={7.75} variant="inner">
          <UnorderedStepper activeStep={activeStepIndex} steps={steps} />
        </Container>
      ) : null}
      <Container id={SECTION_IDS.SELECT_A_PLAN} position="relative">
        {isDesktop && pickedWarehouse && (
          <Box position="relative">
            <SelectedWarehouseBadeContainer>
              <SelectedWarehouseBadge />
            </SelectedWarehouseBadeContainer>
          </Box>
        )}
        <Typography textAlign="center" variant={isMobile ? 'h4' : 'h1'}>
          Select a Plan
        </Typography>
        <SelectPlanSubHeader />
        {pickedWarehouse ? (
          <PlanTermSwitcher switchSize={isMobile ? 'small' : 'large'} badgeText={badgeText} hideBadge={!hasSavings} />
        ) : null}
        <FlexBox
          flexDirection={isDesktop ? 'row' : 'column'}
          mt={4.5}
          justifyContent="stretch"
          gap={isDesktop ? 4 : 2.5}
        >
          {pickedWarehouse
            ? plans.map(({ id, name }) => (
                <PlanItem
                  onCardDeselect={handleCardDeselect}
                  onCardSelect={handleCardSelect}
                  selected={name === selectedCardType}
                  key={id}
                  id={id}
                  term={term}
                />
              ))
            : DEFAULT_PLANS.map(({ description, getColor, icon, image, price, badge, type, features }) => (
                <PlanCard
                  features={features}
                  title={pascalCase(type)}
                  subTitle={description}
                  badge={badge}
                  key={type}
                  featuresIcon={icon}
                  image={image}
                  color={getColor(theme)}
                  price={`$${price.annually} - $${price.monthly}`}
                  actions={
                    <PlanActions
                      type={type}
                      onClick={handlePlanActionClick}
                      text={`Select Address To See Actual Price`}
                    />
                  }
                />
              ))}
        </FlexBox>
        <FlexBox mt={7.75} justifyContent="center">
          <Box width={isDesktop ? 320 : '100%'}>
            <Button onClick={scrollToFeaturesList} fullWidth variant="outlined" size="large">
              Compare Plans
            </Button>
          </Box>
        </FlexBox>
      </Container>
    </Section>
  );
}

export default React.memo(PlansListSection);
