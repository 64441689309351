import { useEffect, useMemo, useState } from 'react';
import {
  selectActiveSubscription,
  useGetChargebeePortalSessionQuery,
  useGetSubscriptionsQuery,
} from '../api/paymentApi';
import { ENVIRONMENT } from '../env';

export enum CbPortalSessionType {
  PaymentSources = 'portal_payment_methods',
  BillingAddress = 'portal_address',
}

// TODO: Move chargebee instance initialization in a separate hook so we don't initialize instance each time the portal is opening.
// TODO: remove useEffect to avoid unnecessary renders.
export function useChargebeePortal() {
  const [portalConfig, setPortalConfig] = useState<{ customerId: string; subscriptionId: string } | null>(null);
  const selectActiveSubscriptionData = useMemo(selectActiveSubscription, []);

  const { activeSubscription } = useGetSubscriptionsQuery(null, {
    selectFromResult: (result) => ({
      ...result,
      activeSubscription: selectActiveSubscriptionData(result.data),
    }),
  });
  const { data: portalSession } = useGetChargebeePortalSessionQuery(
    { cbCustomerId: portalConfig?.customerId || '' },
    { skip: !portalConfig?.customerId },
  );

  const openPortal = (section: CbPortalSessionType) => {
    const chargebeeInstance = (window as any).Chargebee.init({ site: ENVIRONMENT.CHARGE_BEE_SITE });
    chargebeeInstance.setPortalSession(() => Promise.resolve(portalSession));
    const portalInstance = chargebeeInstance.createChargebeePortal();
    portalInstance?.openSection({
      sectionType: section,
      params: { subscriptionId: portalConfig?.subscriptionId },
    });
  };

  useEffect(() => {
    if (activeSubscription) {
      setPortalConfig({
        customerId: activeSubscription.vendorCustomerId || '',
        subscriptionId: activeSubscription.vendorSubscriptionId,
      });
    }
  }, [activeSubscription]);

  return { openPortal };
}
