import { Button } from '@usgm/shared-ui';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../../../store';
import {
  selectHasRejectedDocuments,
  selectIsAccountNamesAdded,
  selectIsDocumentsUploaded,
} from '../../../../../../auth/authSlice';
import { ONBOARDING_PATHS } from '../../../paths';

export function AddIdsStepActions() {
  const navigate = useNavigate();
  const isAccountNamesAdded = useAppSelector((state) => selectIsAccountNamesAdded(state, null));
  const hasRejectedDocuments = useAppSelector((state) => selectHasRejectedDocuments(state, null));
  const isDocumentsUploaded = useAppSelector(selectIsDocumentsUploaded);

  const handleUploadIdsClick = () => {
    navigate(ONBOARDING_PATHS.ADD_IDS);
  };
  return (
    (isAccountNamesAdded || hasRejectedDocuments) && (
      <Button onClick={handleUploadIdsClick} variant="contained">
        {hasRejectedDocuments || isDocumentsUploaded ? 'Re-Upload ID(s)' : 'Upload ID(s)'}
      </Button>
    )
  );
}
