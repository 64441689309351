import { Fade, Modal } from '@mui/material';

export type ImagePopupProps = {
  open: boolean;
  onClose?: () => void;
  imageUrl?: string;
  maxWidth?: string;
  maxHeight?: string;
};
export function ImagePopup({ open, onClose, imageUrl, maxWidth = '90%', maxHeight = '90%' }: ImagePopupProps) {
  return (
    <Modal
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={open}
      onClose={onClose}
      closeAfterTransition
    >
      <Fade in={open} timeout={500}>
        <img src={imageUrl} alt="Preview" style={{ maxHeight, maxWidth, outline: 'none' }} />
      </Fade>
    </Modal>
  );
}
