import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Typography } from '@mui/material';
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { BaseScan, ScanStatus } from '@usgm/inbox-api-types';
import { FlexBox, ImageCell, Tooltip, cellHelpers, dataTableConfig, TextCell } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import CompletedScansActions from '../actions/CompletedScansActions';
import ScanAutoDeletionDate from '../ScanAutoDeletionDate';

export const COMPLETED_SCANS_COLUMNS: GridColDef[] = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    renderCell: (params: GridRenderCellParams<BaseScan>) => {
      return (
        <FlexBox flexDirection="column">
          {GRID_CHECKBOX_SELECTION_COL_DEF.renderCell && GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(params)}
        </FlexBox>
      );
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    ...dataTableConfig.DEFAULT_IMAGE_CELL_PARAMS,
    field: 'imageUrl',
    headerName: 'Item Image',
    renderCell: (params: GridRenderCellParams<BaseScan>) => {
      return <ImageCell enablePreview imageUrl={params.row?.mailImageUrl} />;
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'item',
    headerName: 'Item #',
    renderCell: (params: GridRenderCellParams<BaseScan>) => {
      return <TextCell>{params.row.mailId}</TextCell>;
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'statusTransition',
    headerName: 'Scanned On',

    valueGetter: (value: BaseScan['statusTransition']) => {
      const statusTransition =
        typeof value === 'string' ? inboxHelpers.parseJsonSafe<BaseScan['statusTransition']>(value) : value;

      return cellHelpers.formatDateString(
        statusTransition?.find((transition) => transition.status === ScanStatus.Completed)?.updatedOn,
      );
    },
  },

  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'autoDeleteOn',
    headerName: 'Deleting',

    renderHeader: () => {
      return (
        <Typography display="flex" alignItems="center">
          Deleting
          <FlexBox alignItems="center" display="inline-flex" ml={0.5}>
            <Tooltip title="For security purposes, scans will be automatically deleted after 180 days">
              <HelpOutlineOutlinedIcon />
            </Tooltip>
          </FlexBox>
        </Typography>
      );
    },
    valueGetter: (value: BaseScan['autoDeleteOn']) => {
      if (!value) {
        return 'N/A';
      }
      return <ScanAutoDeletionDate dateValue={value} />;
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'scanningFileActions',
    headerName: 'Scanning File',
    minWidth: 300,
    renderCell: (params: GridRenderCellParams<BaseScan>) => {
      return (
        <FlexBox gap={1}>
          <CompletedScansActions
            categoryType={params.row.categoryType}
            id={params.row.uuid}
            mailId={params.row.mailId}
            createdAt={params.row.createdAt}
          />
        </FlexBox>
      );
    },
  },
] as const;
