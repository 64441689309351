import { useMemo } from 'react';
import {
  selectActiveScanBundle,
  selectActiveScanBundleSubscription,
  useGetUserScanBundlesQuery,
} from '../../../../../api/paymentApi';

// @TODO: doesn't belongs to settings feature
export function useUserScanBundleData() {
  const selectActiveScanBundleData = useMemo(selectActiveScanBundle, []);
  const selectActiveScanBundleSubscriptionData = useMemo(selectActiveScanBundleSubscription, []);
  const { isLoading, data, activeScanBundle, activeScanBundleSubscription } = useGetUserScanBundlesQuery(null, {
    refetchOnMountOrArgChange: true,
    selectFromResult: (result) => ({
      ...result,
      activeScanBundle: selectActiveScanBundleData(result.data?.results),
      activeScanBundleSubscription: selectActiveScanBundleSubscriptionData(result.data?.results),
    }),
  });
  const totalPages = activeScanBundle?.bundleOption.numPages || 0;
  const pagesUsed = activeScanBundle?.pagesUsed || 0;
  const availablePages = totalPages ? totalPages - pagesUsed : 0;

  return { isLoading, data, activeScanBundle, activeScanBundleSubscription, availablePages, totalPages, pagesUsed };
}
