import { Box, Typography } from '@mui/material';
import { type BaseScan } from '@usgm/inbox-api-types';
import { DATA_TABLE_CLASSES, TextCell, cellHelpers } from '@usgm/shared-ui';
import PendingScansActions from '../actions/PendingScansActions';

export type ActionsCellProps = {
  data: BaseScan;
};
function ActionsCell({ data }: ActionsCellProps) {
  return (
    <TextCell>
      <Typography>{cellHelpers.formatDateString(data.createdAt)}</Typography>
      <Box maxWidth={300} mt={4} className={DATA_TABLE_CLASSES.FLOATING_ACTIONS}>
        <PendingScansActions data={data} />
      </Box>
    </TextCell>
  );
}

export default ActionsCell;
