import { useTheme } from '@mui/material';
import { GridRowSpacingParams } from '@mui/x-data-grid';
import { useCallback } from 'react';

export function useGetRowSpacing(spacing = 1) {
  const theme = useTheme();
  const themeSpacing = 8;
  return useCallback(
    (params: GridRowSpacingParams) => {
      return {
        top: params.isFirstVisible ? 2 * themeSpacing * spacing : themeSpacing * spacing,
        bottom: params.isLastVisible ? 2 * themeSpacing * spacing : themeSpacing * spacing,
      };
    },
    [spacing, theme],
  );
}
