import { MouseEvent, ReactElement, ReactNode, useId } from 'react';

import {
  Box,
  BoxProps,
  ButtonProps,
  CircularProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuProps,
  Button as MuiButton,
  Typography,
  alpha,
  styled,
} from '@mui/material';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { useAnchorEl } from '../hooks';
import { Badge } from './Badge';
import { BottomSheet } from './BottomSheet';
import FlexBox from './FlexBox';
import { Popover } from './Popover';
import { PopoverContent } from './PopoverContent';
import { Button } from './buttons/Button';

type StyledProps = {
  display: 'Button' | 'Select';
};

const StyledMoreButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'moreVariant' })<{
  moreVariant: 'icon' | 'text';
}>(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  minWidth: 'auto',
}));

const StyledButton = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 4,
  justifyContent: 'space-between',
  color: theme.palette.text.primary,
}));

const StyledMenu = styled(
  (props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    />
  ),
  { shouldForwardProp: (prop) => prop !== 'variant' },
)<StyledProps>(({ theme, display }) => ({
  '& .MuiPaper-root': {
    borderRadius: 4,
    border: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow: theme.shadows[1],
    '& .MuiMenu-list': {
      padding: '4px 0',
      width: '100%',
    },
    '& .MuiMenuItem-root': {
      ...(display === 'Button' && {
        justifyContent: 'flex-start',
        padding: theme.spacing(2),
        fontWeight: theme.typography.fontWeightBold,
      }),
      '& .MuiSvgIcon-root': {
        fontSize: display === 'Select' ? 18 : 24,
        color: display === 'Button' ? theme.customColors.dark[400] : theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '& span > .MuiSvgIcon-root': {
        marginRight: theme.spacing(0),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export type SelectMenuProps<T> = Partial<StyledProps> & {
  containerProps?: BoxProps;
  moreVariant?: 'text' | 'icon';
  fullWidth?: boolean;
  value?: T;
  disabled?: boolean;
  onChange?: (value: T | undefined) => void;
  label?: ReactNode;
  buttonProps?: ButtonProps;
  isLoading?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  direction?: 'left' | 'right';
  asBottomSheet?: boolean;

  options: Array<{
    value?: T;
    label: string;
    icon?: React.ReactNode;
    disabled?: boolean;
    onClick?: () => void;
    comingSoon?: boolean;
    renderContent?: (v: { closeParent: () => void }) => ReactElement;
  }>;
};

function MenuItemWithSubContent({
  label,
  icon,
  direction = 'right',
  renderContent,
  closeParent,
  asBottomSheet = false,
}: {
  asBottomSheet?: boolean;
  direction?: 'left' | 'right';
  label: string;
  icon?: ReactNode;
  closeParent: () => void;
  renderContent: (v: { closeParent: () => void }) => ReactElement;
}) {
  const popoverId = useId();
  const { anchorEl, handleClick, handleClose, open } = useAnchorEl<HTMLElement>();

  return (
    <>
      <MenuItem aria-describedby={popoverId} onClick={handleClick}>
        <FlexBox>
          {icon && <ListItemIcon>{icon}</ListItemIcon>}
          {label}
          <Typography
            component="span"
            ml={3}
            alignSelf="flex-end"
            display="inline-flex"
            variant="body2"
            color="text.secondary"
          >
            <KeyboardArrowRightIcon />
          </Typography>
        </FlexBox>
      </MenuItem>
      {!asBottomSheet ? (
        <Popover
          id={popoverId}
          anchorOrigin={{
            vertical: 'top',
            horizontal: direction,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: direction === 'left' ? 'right' : 'left',
          }}
          onClose={handleClose}
          spacings={{ left: direction === 'right' ? 2 : undefined, right: direction === 'left' ? 2 : undefined }}
          anchorEl={anchorEl}
          open={open}
        >
          <PopoverContent>{renderContent({ closeParent })}</PopoverContent>
        </Popover>
      ) : open ? (
        <BottomSheet open={open} closeHandler={handleClose} containerProps={{ px: 0 }}>
          {renderContent({ closeParent })}
        </BottomSheet>
      ) : null}
    </>
  );
}

export function SelectMenu<T extends string | number | null = string>({
  containerProps = {},
  moreVariant = 'text',
  onChange,
  label,
  options,
  value,
  disabled,
  fullWidth,
  isLoading,
  display = 'Select',
  buttonProps,
  onOpen,
  onClose,
  direction = 'right',
  asBottomSheet,
}: SelectMenuProps<T>) {
  const { anchorEl, handleClick, handleClose, open } = useAnchorEl<HTMLButtonElement>();
  const buttonId = useId();
  const menuId = useId();
  const handleMenuItemClick = (v: T | undefined, onClick?: () => void) => {
    handleClose();
    onChange?.(v);
    onClick?.();
  };
  const selectedOption = options.find((o) => o.value === value);

  const ButtonComponent = display === 'Button' ? StyledMoreButton : StyledButton;

  const handleMenuOpen = (ev: MouseEvent<HTMLButtonElement>) => {
    onOpen?.();
    handleClick?.(ev);
  };

  const handleCloseMenu = () => {
    onClose?.();
    handleClose();
  };

  return (
    <Box {...containerProps}>
      <ButtonComponent
        {...buttonProps}
        moreVariant={moreVariant}
        fullWidth={fullWidth}
        disabled={disabled || isLoading}
        id={buttonId}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleMenuOpen}
        endIcon={!isLoading && moreVariant !== 'icon' && <KeyboardArrowDownIcon />}
      >
        {isLoading ? (
          <CircularProgress size={20} />
        ) : moreVariant === 'icon' ? (
          <MoreHorizIcon />
        ) : (
          label || selectedOption?.label || 'Select'
        )}
      </ButtonComponent>
      {asBottomSheet ? (
        open ? (
          <BottomSheet open={open} closeHandler={handleClose} containerProps={{ px: 0 }}>
            {options.map(({ label, icon, onClick, value, renderContent, comingSoon, disabled }, index) =>
              renderContent ? (
                <MenuItemWithSubContent
                  asBottomSheet={asBottomSheet}
                  direction={direction}
                  closeParent={handleCloseMenu}
                  label={label}
                  renderContent={renderContent}
                  key={index}
                />
              ) : (
                <MenuItem disabled={disabled} key={index} onClick={() => handleMenuItemClick(value, onClick)}>
                  {icon}
                  {comingSoon ? (
                    <Badge badgeContent="Soon" color="success">
                      {label}
                    </Badge>
                  ) : (
                    label
                  )}
                </MenuItem>
              ),
            )}
          </BottomSheet>
        ) : null
      ) : (
        <StyledMenu
          display={display}
          id={menuId}
          MenuListProps={{
            'aria-labelledby': buttonId,
            sx: { width: anchorEl?.offsetWidth },
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
        >
          {options.map(({ label, icon, onClick, value, renderContent, comingSoon, disabled }, index) =>
            renderContent ? (
              <MenuItemWithSubContent
                direction={direction}
                closeParent={handleCloseMenu}
                label={label}
                renderContent={renderContent}
                key={index}
              />
            ) : (
              <MenuItem disabled={disabled} key={index} onClick={() => handleMenuItemClick(value, onClick)}>
                {icon}
                {comingSoon ? (
                  <Badge badgeContent="Soon" color="success">
                    {label}
                  </Badge>
                ) : (
                  label
                )}
              </MenuItem>
            ),
          )}
        </StyledMenu>
      )}
    </Box>
  );
}

export default SelectMenu;
