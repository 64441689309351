import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Link, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';
import { webSiteUrl } from '../../../../../../../../../helpers/urlHelper';
import { useAppDispatch } from '../../../../../../../../../store';
import { ContentContainer } from '../../../../../../../components/DynamicSidebar/ContentContainer';
import { DECLARATION_SCHEMA } from '../../../../MailInfo/ShippingDeclarationsManager';
import ActionButtons from '../../ActionButtons';
import { useShippingMailsDeclarations } from '../../hooks/useShippingMailsDeclarations';
import { mailsShipmentSlice } from '../../mailsShipmentSlice';
import StepHeader from '../StepHeader';
import MailItem from './MailItem';
import InsureShipment from '../ShipperStep/InsureShipment';

export const MAIL_DECLARATION_SCHEMA = z.object({
  mailId: z.number(),
  imageUrl: z.string(),
  declarations: z.array(DECLARATION_SCHEMA),
});

const mailsDeclarationSchema = z.object({
  mails: z.array(MAIL_DECLARATION_SCHEMA),
});

export type TMailsDeclarationSchemaType = z.infer<typeof mailsDeclarationSchema>;

function DeclarationsStep() {
  const dispatch = useAppDispatch();
  const mailDeclarations = useShippingMailsDeclarations();

  const formMethods = useForm<TMailsDeclarationSchemaType>({
    mode: 'onChange',
    resolver: zodResolver(mailsDeclarationSchema),
    defaultValues: mailDeclarations,
    criteriaMode: 'firstError',
  });
  const { control } = formMethods;

  useEffect(() => {
    dispatch(mailsShipmentSlice.actions.setIsMailDeclarationsValid(formMethods.formState.isValid));
  }, [dispatch, formMethods, formMethods.formState.isValid]);

  const handleNextClick = () => {
    dispatch(mailsShipmentSlice.actions.setMailsShippingDeclarations(formMethods.getValues()));
  };

  useEffect(() => {
    formMethods.trigger();
  }, [formMethods]);

  const { fields: mailsDeclarations } = useFieldArray({
    control,
    name: 'mails',
  });

  return (
    <>
      <ContentContainer pt={0} pb={11.75}>
        <StepHeader />
        <Typography>
          We don't know what's in these packages Please list your items below. Declaration is to specify a value for the
          carrier should any damage or loss occur at Customs when shipping internationally{' '}
          <Link target="_blank" href={webSiteUrl('helps/can-i-write-my-own-declarations/')}>
            Learn more
          </Link>
        </Typography>
        <Box mt={4}>
          <FormProvider {...formMethods}>
            {mailsDeclarations.map((mail, index) => (
              <MailItem key={mail.mailId} fieldIndex={index} imageUrl={mail.imageUrl} mailId={mail.mailId} />
            ))}
          </FormProvider>
        </Box>
        <Box mt={4}>
          <InsureShipment />
        </Box>
      </ContentContainer>
      <ActionButtons
        onNextClick={handleNextClick}
        canProceed={formMethods.formState.isValid}
        errorMessage="Please add declarations for all items to continue"
      />
    </>
  );
}

export default React.memo(DeclarationsStep);
