import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
  circularProgressClasses,
  styled,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';

export type CircularProgressWithLabelProps = CircularProgressProps & {
  value?: number;
  label?: string;
  children?: ReactNode;
  secondLayer?: boolean;
  variant?: 'determinate' | 'indeterminate';
  progressColor?: string;
};

const ContentContainer = styled(
  Box,
  {},
)(({ theme }) => ({
  color: theme.customColors.dark[400],
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export function CircularProgressWithLabel(props: CircularProgressWithLabelProps) {
  const theme = useTheme();
  const secondaryLayerColor = theme.customColors.dark[400];
  const {
    size = 46,
    thickness = 4,
    variant = 'indeterminate',
    secondLayer = true,
    value,
    children,
    label,
    progressColor = theme.palette.primary.main,
    ...progressProps
  } = props;
  return (
    <Box display="inline-flex" alignItems="center">
      <Box position="relative" display="inline-flex">
        {secondLayer && (
          <CircularProgress
            variant="determinate"
            sx={{
              color: secondaryLayerColor,
            }}
            size={size}
            thickness={thickness}
            {...progressProps}
            value={100}
          />
        )}
        <CircularProgress
          variant={variant}
          disableShrink
          sx={{
            color: progressColor,
            animationDuration: '550ms',
            position: secondLayer ? 'absolute' : 'relative',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={size}
          thickness={thickness}
          {...props}
        />
        <ContentContainer>
          {children
            ? children
            : value && (
                <Typography variant="caption" component="div" color="text.secondary">
                  {value}
                </Typography>
              )}
        </ContentContainer>
      </Box>
      {label && (
        <Typography fontSize="0.875rem" ml={3.125} color={theme.customColors.dark[300]} variant="caption">
          {label}
        </Typography>
      )}
    </Box>
  );
}
