import { styled } from '@mui/material';
import { FlexBox } from '@usgm/shared-ui';

const FormActions = styled(FlexBox, { shouldForwardProp: (prop) => prop !== 'noBorder' })<{ noBorder?: boolean }>(
  ({ theme, noBorder }) => ({
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    borderTop: noBorder ? 'none' : `1px solid ${theme.palette.divider}`,
  }),
);

export default FormActions;
