import { useCallback } from 'react';
import { TFolder, folderSelectors } from '../mailFolders/folderSlice';
import { useAppSelector } from '../../../../../store';

export function useMailFolders() {
  const allFolders = useAppSelector(folderSelectors.selectAll);
  const folderEntities = useAppSelector(folderSelectors.selectEntities);
  const folderIdExtractor = useCallback(({ id }: TFolder) => id.toString(), []);
  const folderUuidExtractor = useCallback(({ uuid }: TFolder) => uuid, []);
  const folderLabelExtractor = useCallback(({ label }: TFolder) => label, []);

  return { allFolders, folderEntities, folderIdExtractor, folderUuidExtractor, folderLabelExtractor } as const;
}
