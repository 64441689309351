import { useNavigate } from 'react-router-dom';
import { inboxHelpers } from '@usgm/utils';
import { useMemo } from 'react';

export const SIGN_UP_STEPS = [
  { label: 'Pick a location', path: inboxHelpers.MAIN_PATHS.SELECT_VIRTUAL_ADDRESS },
  { label: 'Select a Plan', path: inboxHelpers.MAIN_PATHS.SELECT_PLAN },
  { label: 'Create your Account', path: inboxHelpers.MAIN_PATHS.SIGN_UP },
];

export const useRegistrationSteps = (activeStepIndex: number) => {
  const navigate = useNavigate();
  const steps = useMemo(() => {
    return SIGN_UP_STEPS.map((step, index) => {
      const isEnabled = index < activeStepIndex;
      return {
        label: step.label,
        onClick: isEnabled
          ? () => {
              navigate(step.path);
            }
          : undefined,
      };
    });
  }, [activeStepIndex, navigate]);
  return { steps, activeStepIndex };
};
