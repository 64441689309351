import { zodResolver } from '@hookform/resolvers/zod';
import { Box, styled } from '@mui/material';
import { AccountNameRequest, AccountRelation, DtoAccountName, DtoSubscriptionPlan } from '@usgm/inbox-api-types';
import { inboxHelpers, onboardingUtils } from '@usgm/utils';
import { useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useActiveSubscription } from '../../../../../../../../../hooks/useActiveSubscription';
import { AddNameFormRow } from '../../../../../../onboarding/components/AddNamesForm/AddNameFormRow';

import { Button, ConfirmPurchaseDialog, FlexBox, useDialog } from '@usgm/shared-ui';
import { useAppDispatch, useAppSelector } from '../../../../../../../../../store';
import { apiMessagesSlice } from '../../../../../../../../apiMessages/apiMessagesSlice';
import { countrySelectors } from '../../../../../../countries/countriesSlice';
import { useGetAccountNamesWithStatusQuery, useUpdateAccountNamesMutation } from '../../../../../../onboarding/api';
import createAccountNamesMapper from '../../../../../../onboarding/components/addNames/createAccountNamesMapper';
import { StepContentContainer } from './StepContentContainer';

const FormContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 0),
  maxWidth: 800,
}));

export type AddEditNameFormProps = {
  accountNames: DtoAccountName[];
  plan: DtoSubscriptionPlan;
  accountId?: DtoAccountName['user']['id'];
  onSuccess?: (accountId?: string) => void;
};

function AddEditNameForm({ accountNames, plan, accountId, onSuccess }: AddEditNameFormProps) {
  const dispatch = useAppDispatch();

  const countryEntities = useAppSelector(countrySelectors.selectEntities);
  const existingAccount = useMemo(
    () => accountNames.find((acc) => acc.user.id === accountId),
    [accountId, accountNames],
  );
  const [updateMutation, updateQuery] = useUpdateAccountNamesMutation();
  const subscriptionQuery = useActiveSubscription();
  const { data } = useGetAccountNamesWithStatusQuery(null);

  const {
    closeDialog: closeConfirmPurchaseDialog,
    open: openConfirmPurchase,
    openDialog: openConfirmPurchaseDialog,
    value: accountNamesToPurchase,
  } = useDialog<onboardingUtils.AccountNamesFormSchemaType & onboardingUtils.CompanyInfoFormSchema>();

  const defaultValues = useMemo(() => {
    const mapper = createAccountNamesMapper(countryEntities);
    return {
      accountNames: [
        existingAccount
          ? mapper(existingAccount)
          : onboardingUtils.createEmptyAccountName<onboardingUtils.AccountSchemaType>(),
      ],
    };
  }, [countryEntities, existingAccount]);

  const methods = useForm<onboardingUtils.AccountNamesFormSchemaType & onboardingUtils.CompanyInfoFormSchema>({
    resolver: (data, context, options) => {
      const schema =
        plan?.options?.isBusiness &&
        [AccountRelation.Trust, AccountRelation.Company].includes(data.accountNames[0].relationType)
          ? onboardingUtils.addNamesCompanyInfoFormSchema
          : onboardingUtils.addNamesFormSchema;
      return zodResolver(schema)(data, context, options);
    },
    defaultValues,
    mode: 'onChange',
  });

  const { fields } = useFieldArray<onboardingUtils.AccountNamesFormSchemaType & onboardingUtils.CompanyInfoFormSchema>({
    name: 'accountNames',
    control: methods.control,
  });

  const saveName = async (data: onboardingUtils.AccountNamesFormSchemaType & onboardingUtils.CompanyInfoFormSchema) => {
    const [newAccount] = data.accountNames;
    if (!newAccount) return;
    const accountNamesRequests: AccountNameRequest[] = [];

    accountNamesRequests.push(onboardingUtils.prepareCompanyInfo(newAccount, false));

    const response = await updateMutation(accountNamesRequests);
    const hasError = 'error' in response;

    dispatch(
      apiMessagesSlice.actions.createMessage({
        severity: hasError ? 'error' : 'success',
        text: !hasError
          ? `Account name ${existingAccount ? 'updated' : 'added'} successfully.`
          : inboxHelpers.GENERIC_ERROR_MESSAGE,
      }),
    );

    if (!hasError && 'data' in response) {
      onSuccess?.(response.data.userIds[0]);
    }
  };

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const canSubmit = isValid && !updateQuery.isLoading;
  const maxNames = subscriptionQuery.activeSubscription?.plan?.accountNamesPlan.maxNames;
  const showPurchaseDialog =
    !accountId && maxNames && data?.data?.accountNames && data.data.accountNames.length >= maxNames;

  const onSubmit = (data: onboardingUtils.AccountNamesFormSchemaType & onboardingUtils.CompanyInfoFormSchema) => {
    if (showPurchaseDialog) {
      openConfirmPurchaseDialog(data);
    } else {
      saveName(data);
    }
  };

  const onConfirmPurchase = async () => {
    if (!accountNamesToPurchase) {
      return;
    }
    saveName(accountNamesToPurchase);
  };

  return (
    <StepContentContainer>
      <FormProvider {...methods}>
        <FormContainer width="100%" onSubmit={handleSubmit(onSubmit)} component="form">
          {fields.map(({ relationType, uuid, name }, index) => (
            <AddNameFormRow
              showAllRelations
              key={uuid}
              isBusiness={plan?.options?.isBusiness}
              disabled={updateQuery.isLoading}
              index={index}
              disableRelationType={relationType === AccountRelation.Primary}
              name={name}
              relationType={relationType}
              uuid={uuid}
            />
          ))}
          <FlexBox justifyContent="center">
            <Button
              type="submit"
              isLoading={updateQuery.isLoading}
              disabled={!canSubmit}
              variant="contained"
              sx={{ minWidth: 140 }}
            >
              {existingAccount ? 'Update' : 'Add'}
            </Button>
          </FlexBox>
        </FormContainer>
      </FormProvider>
      <ConfirmPurchaseDialog
        onClose={closeConfirmPurchaseDialog}
        open={openConfirmPurchase}
        onCancel={closeConfirmPurchaseDialog}
        onConfirm={onConfirmPurchase}
        subtotal={inboxHelpers.COST_OF_EXTRA_ACCOUNT_NAME_PER_MONTH}
        total={inboxHelpers.COST_OF_EXTRA_ACCOUNT_NAME_PER_MONTH}
        description="By clicking the Confirm button below, you're buying 1 additional name for your account."
      />
    </StepContentContainer>
  );
}

export default AddEditNameForm;
