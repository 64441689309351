import { Box, Typography, useTheme } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { TMailsDeclarationSchemaType } from '.';
import { AddButton, FlexBox, ImageCell } from '@usgm/shared-ui';
import { DeclarationsRow, DEFAULT_DECLARATION } from '../../../../MailInfo/ShippingDeclarationsManager';

function MailItem({ fieldIndex, imageUrl, mailId }: { fieldIndex: number; mailId: number; imageUrl: string }) {
  const theme = useTheme();
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<TMailsDeclarationSchemaType>();

  const { fields, remove, append } = useFieldArray({
    control,
    name: `mails.${fieldIndex}.declarations`,
  });

  return (
    <Box mb={5}>
      <Typography mb={1} fontWeight={theme.typography.fontWeightBold} color={theme.palette.primary.main} variant="h6">
        Item #{mailId}
      </Typography>
      <ImageCell enablePreview height={452} imageUrl={imageUrl} />
      <Box mt={4}>
        {fields.map((field, index) => (
          <DeclarationsRow
            key={field.id}
            errors={{
              declarations: errors.mails?.[fieldIndex]?.declarations?.[index]?.description?.message,
              itemValue: errors.mails?.[fieldIndex]?.declarations?.[index]?.itemValue?.message,
              quantity: errors.mails?.[fieldIndex]?.declarations?.[index]?.quantity?.message,
            }}
            register={register}
            fieldsPath={`mails.${fieldIndex}.declarations.${index}`}
            index={index}
            remove={remove}
            showRemoveButton={fields.length > 1}
          />
        ))}
      </Box>
      <FlexBox minHeight={48} mt={1}>
        <AddButton
          label={fields.length > 0 ? 'Add another declaration' : 'Add a declaration'}
          onClick={() => append(DEFAULT_DECLARATION)}
        />
      </FlexBox>
    </Box>
  );
}
export default MailItem;
