import { Global } from '@emotion/react';
import { Box, BoxProps, ModalProps, styled, SwipeableDrawer, Typography } from '@mui/material';
import * as React from 'react';
import { Puller } from './SimpleDialog';
import { isString } from 'lodash-es';

type BottomSheetProps = {
  open: boolean;
  children?: React.ReactNode;
  closeHandler: () => void | ModalProps['onClose'];
  wrapContent?: boolean;
  onOpen?: () => void;
  hidePuller?: boolean;
  containerProps?: BoxProps;
  title?: React.ReactNode;
};

const StyledDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    borderRadius: `${theme.shape.borderRadius * 4}px ${theme.shape.borderRadius * 4}px 0 0`,
    backgroundColor: theme.palette.background.default,
  },
}));

export function BottomSheet({
  open,
  closeHandler,
  children,
  wrapContent = true,
  onOpen,
  hidePuller = false,
  containerProps,
  title,
}: BottomSheetProps) {
  const handleOpen = () => {
    onOpen?.();
  };

  const mergedContainerProps = React.useMemo(() => {
    return {
      ...containerProps,
      px: containerProps?.px ?? 2.5,
      pt: containerProps?.pt ?? (hidePuller ? 3 : 1),
      pb: containerProps?.pb ?? 3,
      style: {
        ...(containerProps?.style || {}),
        overflow: 'auto',
      },
    };
  }, [containerProps, hidePuller]);

  return (
    <>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            overflow: 'visible',
          },
        }}
      />
      <StyledDrawer
        container={undefined}
        anchor="bottom"
        open={open}
        onClose={closeHandler}
        onOpen={handleOpen}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {wrapContent ? (
          <>
            {!hidePuller && (
              <Box p={2} display="flex" justifyContent="center">
                <Puller />
              </Box>
            )}
            {title ? (
              isString(title) ? (
                <Typography textAlign="center" px={4} variant="h6">
                  {title}
                </Typography>
              ) : (
                title
              )
            ) : null}
            <Box {...mergedContainerProps}>{children}</Box>
          </>
        ) : (
          children
        )}
      </StyledDrawer>
    </>
  );
}
