import { Box, Button, Typography, styled, useTheme } from '@mui/material';
import { FlexBox, RoundedBadge, SectionTitle } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import LocationIcon from '../../../../assets/location.svg?react';
import { PartialBy } from '../../../../helpers/typeHelpers';
import { TMeasuredWarehouse } from '../../addressPickerSlice';
import RecommendedBadges from './RecommendedBadges';

const CardRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export interface WarehouseCardProps extends PartialBy<TMeasuredWarehouse, 'distance'> {
  recommended?: boolean;
  variant?: 'card' | 'list';
  onSelect?: (id: TMeasuredWarehouse['id']) => void;
}

export default function WarehouseInnerCard(props: WarehouseCardProps) {
  const theme = useTheme();
  const { recommended, address, variant = 'list', distance, onSelect } = props;
  const iconBg = variant === 'card' ? (recommended ? '#ECF4FF' : theme.palette.common.white) : '#ECF4FF';
  const iconColor = recommended ? theme.palette.primary.main : '#B4CFFE';
  const onClickHandler = () => {
    onSelect?.(props.id);
  };
  return (
    <CardRoot>
      <FlexBox mb={1.5} width="100%" alignItems="flex-start">
        <RoundedBadge color={iconColor} bg={iconBg} size={theme.spacing(8.5)}>
          <LocationIcon />
        </RoundedBadge>
        <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 3, paddingRight: 5.25, flex: 1 }}>
          <SectionTitle sx={{ textAlign: 'left', marginBottom: 0 }}>{address.name}</SectionTitle>
          <Typography sx={{ color: theme.customColors.dark[400] }}>
            {inboxHelpers.stringifyAddress({ addr: address })}
          </Typography>
        </Box>
        {variant === 'list' && onSelect && (
          <Button onClick={onClickHandler} size="small" variant={recommended ? 'contained' : 'outlined'}>
            Select
          </Button>
        )}
      </FlexBox>

      {recommended && variant !== 'card' && <RecommendedBadges warehouseId={props.id} />}
      {variant === 'card' && (
        <FlexBox alignItems="end">
          {recommended ? (
            <RecommendedBadges warehouseId={props.id} />
          ) : (
            distance && <Typography color={theme.palette.text.secondary}>{Math.round(distance)} miles away</Typography>
          )}

          {onSelect && (
            <Button onClick={onClickHandler} size="medium" variant={recommended ? 'contained' : 'outlined'}>
              Select
            </Button>
          )}
        </FlexBox>
      )}
    </CardRoot>
  );
}
