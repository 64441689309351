import { DtoAccountName } from '@usgm/inbox-api-types';
import { TCountry } from '../../../countries/countriesSlice';

export default function createAccountNamesMapper(countryEntities?: Record<string, TCountry>) {
  return ({ relation, user: { uuid, name }, companyAddress }: DtoAccountName) => {
    const country = countryEntities?.[companyAddress?.country || ''] || {
      name: '',
      code: '',
      id: '',
    };
    return {
      name,
      relationType: relation.relationType,
      uuid,
      streetAddress: companyAddress?.addressLine || '',
      city: companyAddress?.city || '',
      state: companyAddress?.state || '',
      country,
      zipCode: companyAddress?.postalCode || '',
      typeOfBusiness: companyAddress?.companyTypeOfBusiness || '',
      placeOfRegistration: companyAddress?.companyPlaceOfRegistration || '',
      phoneNumber: companyAddress?.phoneNumber || '',
    };
  };
}
