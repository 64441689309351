import {
  Box,
  Link,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { useMobileMode, Preloader } from '@usgm/shared-ui';
import { useAppSelector } from '../../../../../../../../store';
import { ContentContainer } from '../../../../../../components/DynamicSidebar/ContentContainer';
import { ratesSelectors, selectIsRatesLoading, selectSelectedRate } from '../mailsShipmentSlice';

import RatesRow from './RatesRow';

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  whiteSpace: 'nowrap',
})) as typeof Typography;

const StyledEmphasizedHeadingText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.text.neutral,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '0.75rem',
}));

const StyledHeadingText = styled(StyledText)(({ theme }) => ({
  color: theme.customColors.dark[300],
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '0.75rem',
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  left: 0,
  backgroundColor: theme.customColors.menuBorder,
}));

const TableHeadCell = styled(TableCell)(({ theme }) => ({
  verticalAlign: 'top',
}));

const StyledTable = styled(Table)(({ theme }) => ({
  width: '100%',
  '& tr': {
    '& th': {
      borderBottom: 'none',
    },
    '& td, & th': {
      padding: theme.spacing(1),
    },
    '& td:first-of-type, & th:first-of-type': {
      paddingLeft: theme.spacing(3),
    },
    '& td:last-of-type, & th:last-of-type': {
      paddingRight: theme.spacing(3),
    },
  },
  '& tr:last-of-type': {
    '& td': {
      borderBottom: 'none',
    },
  },
}));

function RatesList() {
  const theme = useTheme();
  const isMobile = useMobileMode();

  const rates = useAppSelector(ratesSelectors.selectAll);
  const isLoading = useAppSelector(selectIsRatesLoading);
  const selectedRate = useAppSelector(selectSelectedRate);

  const deliveryDateColumnTitle = isMobile ? (
    <>
      <StyledHeadingText>Estimated</StyledHeadingText>
      <StyledHeadingText>Delivery</StyledHeadingText>
    </>
  ) : (
    <>
      <StyledHeadingText>Estimated Delivery</StyledHeadingText>
      <StyledHeadingText>
        Date <StyledText component="span">(Business)</StyledText>
      </StyledHeadingText>
    </>
  );

  return (
    <Box pb={12.75}>
      <ContentContainer mb={2} pt={0} pb={0}>
        <Typography fontWeight={theme.typography.fontWeightBold}>All rates are estimates only</Typography>
      </ContentContainer>
      <StyledTable>
        <StyledTableHead>
          <TableRow>
            <TableHeadCell>
              <StyledHeadingText>Service</StyledHeadingText>
            </TableHeadCell>
            <TableHeadCell align="right">{deliveryDateColumnTitle}</TableHeadCell>
            <TableHeadCell align="right">
              <StyledHeadingText>Ship</StyledHeadingText>
              <StyledHeadingText>Today</StyledHeadingText>
            </TableHeadCell>
            <TableHeadCell>
              <StyledEmphasizedHeadingText>Discounted</StyledEmphasizedHeadingText>
              <StyledEmphasizedHeadingText>rate</StyledEmphasizedHeadingText>
            </TableHeadCell>
            <TableHeadCell>
              <StyledHeadingText>Trackable</StyledHeadingText>
            </TableHeadCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={5}>
                <Preloader minHeight={200} />
              </TableCell>
            </TableRow>
          ) : rates && rates.length > 0 ? (
            rates.map(({ id }) => {
              return <RatesRow key={id} selected={id === selectedRate?.serviceType} id={id} />;
            })
          ) : (
            <TableRow>
              <TableCell colSpan={5}>
                <Box py={5} px={3}>
                  <Typography
                    textAlign="center"
                    fontWeight={theme.typography.fontWeightBold}
                    color={theme.customColors.dark[400]}
                  >
                    No rates available.
                  </Typography>
                  <Typography
                    textAlign="center"
                    fontWeight={theme.typography.fontWeightBold}
                    color={theme.customColors.dark[400]}
                  >
                    Please update the address or{' '}
                    <Link target="_blank" href="https://www.usglobalmail.com/contact-us/">
                      contact support.
                    </Link>
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </StyledTable>
    </Box>
  );
}

export default RatesList;
