import { styled, Typography } from '@mui/material';

export const StyledPrice = styled(Typography)(({ theme }) => ({
  color: '#949494', // @TODO: need to understand semantic colors and where this belongs to
  fontSize: '1rem',
  '& .price': {
    fontSize: '1.25em',
  },
  '& .period': {
    fontSize: '1em',
  },
}));
