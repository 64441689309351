import { NoRowPlaceholder } from '@usgm/shared-ui';
import NoAccountIcon from '../../../../../../../assets/noTeamMembersIcon.svg?react';

export type AccountNotFoundProps = {
  message?: string;
  accountId?: string;
};
function AccountNotFound({ accountId, message }: AccountNotFoundProps) {
  const text = message || `Unable to find account with id ${accountId}`;
  return <NoRowPlaceholder text={text} icon={<NoAccountIcon />} />;
}

export default AccountNotFound;
