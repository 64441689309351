import { Box, styled } from '@mui/material';

export const DataGridContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: `${theme.shape.borderRadius * 2}px 0px 0px ${theme.shape.borderRadius * 2}px`,
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  minHeight: 'calc(100% - 64px)',
  maxWidth: '100vw',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'transparent',
  },
}));
