import React from 'react';

import { useMediaQuery } from '@mui/material';
import { FlexBox } from '@usgm/shared-ui';

import {
  AccountIdentificationFileType,
  AccountRelation,
  AccountVerificationStepStatus,
  IdentificationsStep,
} from '@usgm/inbox-api-types';

import { useAppSelector } from '../../../../../../../store';
import { selectAccountVerificationState } from '../../../../../../auth/authSlice';
import { AccountIdStatusRow } from './AccountIdStatusRow';

type TFilesList = IdentificationsStep['documentIds'][number]['files'];
type TFilesMap = Record<string, TFilesList>;

export function AddIdsStepItemContent() {
  const accountVerificationState = useAppSelector((state) => selectAccountVerificationState(state, null));
  const showAsRows = useMediaQuery('(min-width: 600px)');
  if (
    !accountVerificationState ||
    accountVerificationState.steps.accountNames.status === AccountVerificationStepStatus.InProgress
  ) {
    return 'USPS regulations require ID verification to safeguard your mail against fraud, identity theft and errors.';
  }

  const accountNames = accountVerificationState.steps.accountNames.accountNames.filter(
    (account) =>
      ![
        AccountRelation.MinorChild,
        AccountRelation.Deceased,
        'MINOR_CHILD',
        AccountRelation.Company,
        AccountRelation.Trust,
      ].includes(account.relation),
  );

  const filesMap = accountVerificationState.steps.identifications.documentIds.reduce((acc, { id, files }) => {
    acc[id] = files;
    return acc;
  }, {} as TFilesMap);

  return (
    <FlexBox flexDirection="column" gap={showAsRows ? 1 : 3}>
      {accountNames.map(({ id, name }) => {
        const files = filesMap[id];
        const primaryStatus = files?.find(({ type }) => type === AccountIdentificationFileType.Primary)?.status;
        const secondaryStatus = files?.find(({ type }) => type === AccountIdentificationFileType.Secondary)?.status;

        return (
          <AccountIdStatusRow
            showAsRow={showAsRows}
            showName={accountNames.length > 1}
            primaryStatus={primaryStatus}
            secondaryStatus={secondaryStatus}
            name={name}
            key={id}
          />
        );
      })}
    </FlexBox>
  );
}

export default React.memo(AddIdsStepItemContent);
