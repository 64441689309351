import {
  type BaseMail,
  type DtoMailV2,
  type DtoMailsList,
  type DtoNote,
  type DtoNotesList,
  type GetMailsListParams,
  MailStatus,
  type MailsActionRequestParams,
  type PaginationParams,
  type PostMailDeclarationsParams,
  type ResponseInfo,
} from '@usgm/inbox-api-types';
import { inboxHelpers, snakeCaseKeys } from '@usgm/utils';
import { mailsApi as baseMailsApi } from '../../../../api/mailsApi';
import { MAIL_TABS, MailsPathParams } from './paths';

import { INBOX_TAG_TYPES, inboxAccountsApi } from '../../inboxAccountsApi';
import { scansApi } from '../scans/api';

export const TAG_TYPES = {
  MAIL_NOTE: 'MailNote',
  MAILS_LIST: 'MailsList',
  MAIL_ITEM: 'MailItem',
  DISCARDS_LIST: 'DiscardsList',
} as const;

export const mailsApi = baseMailsApi
  .enhanceEndpoints({
    addTagTypes: [TAG_TYPES.MAIL_NOTE, TAG_TYPES.MAILS_LIST, TAG_TYPES.MAIL_ITEM, TAG_TYPES.DISCARDS_LIST],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMailsList: builder.query<DtoMailsList, GetMailsListParams & Omit<MailsPathParams, 'itemId'>>({
        query: ({ tab, folderId, limit = inboxHelpers.DEFAULT_PAGINATION_LIMIT, skip = 0, filter }) => {
          const filterQueryString = inboxHelpers.constructGenericFilters({
            currentTab: tab,
            tabs: [...MAIL_TABS],
            folderId,
            filter,
          });
          return {
            url: `/mails/v2${filterQueryString ? `?${filterQueryString}` : ''}`,
            method: 'get',
            params: {
              skip,
              limit,
            },
          };
        },
        providesTags: (result) =>
          result && result.list
            ? [...result.list.map((mail) => ({ type: TAG_TYPES.MAIL_ITEM, id: mail.id })), TAG_TYPES.MAILS_LIST]
            : [TAG_TYPES.MAILS_LIST],
      }),
      getDiscardedMailsList: builder.query<DtoMailsList, PaginationParams>({
        query: ({ limit = inboxHelpers.DEFAULT_PAGINATION_LIMIT, skip = 0 }) => ({
          url: `/mails/v2?filter=status:${MailStatus.DiscardRequest}`,
          method: 'get',
          params: {
            skip,
            limit,
          },
        }),
        providesTags: (result) =>
          result && result.list
            ? [...result.list.map((mail) => ({ type: TAG_TYPES.MAIL_ITEM, id: mail.id })), TAG_TYPES.DISCARDS_LIST]
            : [TAG_TYPES.DISCARDS_LIST],
      }),
      getMailById: builder.query<DtoMailV2, { id: BaseMail['id'] }>({
        query: ({ id }) => ({
          url: `/mails/v2/${id}`,
          method: 'get',
        }),
        providesTags: (result) => [{ type: TAG_TYPES.MAIL_ITEM, id: result?.id }],
      }),
      addMailNote: builder.mutation<ResponseInfo, { id: BaseMail['id']; note: string }>({
        query: ({ id, note }) => ({
          url: `/mail/${id}/add/new/note`,
          method: 'post',
          data: {
            note,
          },
        }),
        invalidatesTags: [TAG_TYPES.MAIL_NOTE],
      }),

      updateMailNoteById: builder.mutation<ResponseInfo, { id: DtoNote['uuid']; note: string; mailId: string }>({
        query: ({ id, note }) => ({
          url: `/mail/update/note/${id}`,
          method: 'put',
          // @TODO: Inconsistent naming in the API in some case it is note in other cases it is notes
          data: {
            notes: note,
          },
        }),
        onQueryStarted: async ({ id, mailId, note }, { dispatch, queryFulfilled }) => {
          const patchList = dispatch(
            mailsApi.util.updateQueryData('getNotesByMailId', { id: Number(mailId) }, (draft) => {
              const noteIndex = draft.data.findIndex((note) => note.id === id);
              if (noteIndex === -1) return;
              draft.data[noteIndex].note = note;
            }),
          );
          try {
            await queryFulfilled;
          } catch (error) {
            patchList.undo();
          }
        },
      }),

      deleteMailNoteById: builder.mutation<ResponseInfo, { id: DtoNote['uuid']; mailId: string }>({
        query: ({ id }) => ({
          url: `/mail/delete/note/${id}`,
          method: 'delete',
        }),
        onQueryStarted: async ({ id, mailId }, { dispatch, queryFulfilled }) => {
          const patchList = dispatch(
            mailsApi.util.updateQueryData('getNotesByMailId', { id: Number(mailId) }, (draft) => {
              draft.data = draft.data.filter((note) => note.id !== id);
            }),
          );
          try {
            await queryFulfilled;
          } catch (error) {
            patchList.undo();
          }
        },
      }),

      getNotesByMailId: builder.query<DtoNotesList, { id: BaseMail['id'] }>({
        query: ({ id }) => ({
          url: `/mail/${id}/notes/all`,
          method: 'get',
        }),
        providesTags: (result) =>
          result
            ? [...result.data.map((note) => ({ type: TAG_TYPES.MAIL_NOTE, id: note.id })), TAG_TYPES.MAIL_NOTE]
            : [TAG_TYPES.MAIL_NOTE],
      }),
      updateIsReadStatus: builder.mutation<
        ResponseInfo,
        { ids: Array<BaseMail['id']>; isRead?: boolean } & GetMailsListParams & Omit<MailsPathParams, 'itemId'>
      >({
        query: ({ ids, isRead = true }) => ({
          url: `/inbox/unread`,
          method: 'put',
          data: {
            mail_ids: ids.join(','),
            action: isRead ? 'read' : 'unread',
          },
        }),
        onQueryStarted: async ({ isRead }, { dispatch, queryFulfilled }) => {
          try {
            // @TODO: This should be done in a more efficient way
            await queryFulfilled;
            dispatch(
              inboxAccountsApi.util.invalidateTags([
                INBOX_TAG_TYPES.NAVIGATION_SETTING,
                INBOX_TAG_TYPES.UNREAD_MAILS_INFO,
              ]),
            );
          } catch (error) {
            console.error(`Error marking mail item(s) as ${isRead ? 'read' : 'unread'}`, error);
          }
        },
        invalidatesTags: (_, error, { ids }) =>
          !error ? [...ids.map((id) => ({ id, type: TAG_TYPES.MAIL_ITEM })), TAG_TYPES.MAILS_LIST] : [],
      }),
      restoreDiscardedMails: builder.mutation<ResponseInfo, { ids: BaseMail['id'][] }>({
        query: ({ ids }) => ({
          url: `/mail/discard/request`,
          method: 'patch',
          data: {
            requests: ids.map((id) => ({ mail_id: id, mail_status: MailStatus.DiscardMistake })),
          },
        }),
        invalidatesTags: (_, error, { ids }) =>
          !error
            ? [...ids.map((id) => ({ type: TAG_TYPES.MAIL_ITEM, id })), TAG_TYPES.DISCARDS_LIST, TAG_TYPES.MAILS_LIST]
            : [],
      }),

      discardMails: builder.mutation<ResponseInfo, { ids: BaseMail['id'][] }>({
        query: ({ ids }) => ({
          url: `/mail/discard/request`,
          method: 'patch',
          data: {
            requests: ids.map((id) => ({ mail_id: id, mail_status: MailStatus.DiscardRequest })),
          },
        }),
        invalidatesTags: (_, error, { ids }) =>
          !error
            ? [...ids.map((id) => ({ id, type: TAG_TYPES.MAIL_ITEM })), TAG_TYPES.MAILS_LIST, TAG_TYPES.DISCARDS_LIST]
            : [],
      }),
      moveMailsToFolder: builder.mutation<ResponseInfo, { ids: BaseMail['id'][]; folderId: number }>({
        query: ({ ids, folderId }) => ({
          url: `/move-to-folder/${folderId}`,
          method: 'patch',
          data: {
            mailIds: ids,
          },
        }),
        onQueryStarted: async ({ ids, folderId }, { dispatch, queryFulfilled }) => {
          try {
            // @TODO: This should be done in a more efficient way
            await queryFulfilled;
            dispatch(
              inboxAccountsApi.util.invalidateTags([
                INBOX_TAG_TYPES.NAVIGATION_SETTING,
                INBOX_TAG_TYPES.UNREAD_MAILS_INFO,
              ]),
            );
          } catch (error) {
            console.error('Error moving mails to folder', error);
          }
        },
        invalidatesTags: (_, error, { ids }) =>
          !error ? [...ids.map((id) => ({ id, type: TAG_TYPES.MAIL_ITEM })), TAG_TYPES.MAILS_LIST] : [],
      }),
      postMailDeclarations: builder.mutation<ResponseInfo, PostMailDeclarationsParams>({
        query: ({ mailId, declarations }) => ({
          url: `/admin/shipment/add/item/${mailId}/declarations`,
          method: 'patch',
          data: {
            declarations: snakeCaseKeys(declarations),
          },
        }),
        invalidatesTags: (_, error, { mailId }) => (!error ? [{ type: TAG_TYPES.MAIL_ITEM, id: mailId }] : []),
      }),
      mailsActionRequest: builder.mutation<ResponseInfo & { ids: string[] }, MailsActionRequestParams>({
        query: (requestedMails) => ({
          url: '/scan',
          method: 'post',
          data: requestedMails.map((request) => snakeCaseKeys(request)),
        }),
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled;
            dispatch(scansApi.util.invalidateTags(['ScanList']));
          } catch (error) {
            console.error('Error opening mails request', error);
          }
        },
        invalidatesTags: (_, error, requestedMails) =>
          !error
            ? [
                ...requestedMails.map((request) => ({ type: TAG_TYPES.MAIL_ITEM, id: request.id })),
                TAG_TYPES.MAILS_LIST,
              ]
            : [],
      }),
    }),
  });

export const {
  useGetMailsListQuery,
  useGetNotesByMailIdQuery,
  useAddMailNoteMutation,
  useGetDiscardedMailsListQuery,
  useGetMailByIdQuery,
  useUpdateIsReadStatusMutation,
  useDeleteMailNoteByIdMutation,
  useUpdateMailNoteByIdMutation,
  useRestoreDiscardedMailsMutation,
  useDiscardMailsMutation,
  useMoveMailsToFolderMutation,
  usePostMailDeclarationsMutation,
  useMailsActionRequestMutation,
} = mailsApi;
