import * as React from 'react';
import { ReactNode } from 'react';
import { List, Popover, styled, Typography } from '@mui/material';
import IconListItem from './IconListItem';

type PopoverMenuProps = {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  open: boolean;
  itemsList: {
    label: ReactNode;
    icon: ReactNode;
    clickHandler?: () => void;
  }[];
};

const PopoverCard = styled(Popover)(({ theme }) => ({
  width: '100%',
  '& .MuiPaper-root': {
    borderRadius: '16px',
    border: '3px solid #D8E4FC', // ToDo set in theme to apply dark mode color
  },
}));

const StyledIconListItem = styled(IconListItem, {
  shouldForwardProp: (prop) => prop !== 'cursor',
})<{ cursor: 'pointer' | 'inherit' }>(({ theme, cursor = 'inherit' }) => ({
  padding: theme.spacing(1.5, 0),
  borderBottom: `1px solid ${theme.palette.divider}`,
  cursor,
  '&:first-of-type': {
    paddingTop: 0,
  },
  '&:last-child': {
    paddingBottom: 0,
    borderBottom: 'none',
  },
}));

export function PopoverMenu(props: PopoverMenuProps) {
  const { anchorEl, handleClose, open, itemsList } = props;
  const handleItemClick = (item: PopoverMenuProps['itemsList'][number]) => () => {
    if (item.clickHandler) {
      item.clickHandler();
    }
    handleClose();
  };
  return (
    <PopoverCard
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handleClose}
      disableRestoreFocus
    >
      <List sx={{ padding: (theme) => theme.spacing(2) }}>
        {itemsList?.map((item, index) => (
          <StyledIconListItem
            key={index}
            icon={item.icon}
            onClick={handleItemClick(item)}
            cursor={item.clickHandler ? 'pointer' : 'inherit'}
          >
            <Typography color="text.primary" component="span">
              {item.label}
            </Typography>
          </StyledIconListItem>
        ))}
      </List>
    </PopoverCard>
  );
}

export default PopoverMenu;
