import { FlexBox, InfoTitle } from '@usgm/shared-ui';
import { Typography, alpha, styled, useTheme } from '@mui/material';

import { IdInstructions } from './IdInstructions';
import React from 'react';
import { ID_INSTRUCTIONS } from '../../config/idInstructions';
import { inboxHelpers } from '@usgm/utils';

const Root = styled(FlexBox)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.2),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2, 3),
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

export function AddIdsInstruction() {
  const theme = useTheme();
  return (
    <Root>
      <Typography>Instruction </Typography>
      <FlexBox gap={3}>
        <InfoTitle
          color={theme.palette.primary.dark[400]}
          maxWidth={320}
          title="For Primary ID"
          description={<IdInstructions {...ID_INSTRUCTIONS['primary']} />}
        />
        <InfoTitle
          color={theme.palette.primary.dark[400]}
          maxWidth={320}
          title="For Secondary ID"
          description={<IdInstructions {...ID_INSTRUCTIONS['secondary']} />}
        />
      </FlexBox>
    </Root>
  );
}

export default React.memo(AddIdsInstruction);
