export enum DocumentType {
  DriverLicense = 'DRIVER_LICENSE',
  GovernmentIdentificationCard = 'GOVERNMENT_IDENTIFICATION_CARD',
  AlienRegistrationCard = 'ALIEN_REGISTRATION_CARD',
  CertificateOfNaturalization = 'CERTIFICATE_OF_NATURALIZATION',
  Passport = 'PASSPORT',
  StateIdentificationCard = 'STATE_IDENTIFICATION_CARD',
  VoterCard = 'VOTER_CARD',
  VehicleRegistrationCard = 'VEHICLE_REGISTRATION_CARD',
  VehicleInsurancePolicy = 'VEHICLE_INSURANCE_POLICY',
  CurrentLease = 'CURRENT_LEASE',
  HealthCareInfo = 'HEALTH_CARE_INFO',
  UniformedServiceId = 'UNIFORMED_SERVICE_ID',
  UsAccessCard = 'US_ACCESS_CARD',
  UsUniversityIdCard = 'US_UNIVERSITY_ID_CARD',
  MatriculaConsular = 'MATRICULA_CONSULAR',
  NexusCard = 'NEXUS_CARD',
  UsPermanentResidentCard = 'US_PERMANENT_RESIDENT_CARD',
  MortgageOrDeedOfTrust = 'MORTGAGE_OR_DEED_OF_TRUST',
  HomeOrVehicleInsurancePolicy = 'HOME_OR_VEHICLE_INSURANCE_POLICY',
}
