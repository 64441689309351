import { Box, Chip, Stack, Typography, styled } from '@mui/material';
import { useState } from 'react';
import FlexBox from './FlexBox';
import { createScrollingStyles } from './ScrollableArea';
import SearchBar from './SearchBar';
import { Checkbox } from './form/Checkbox';

const NoResultsContainer = styled(FlexBox)(({ theme }) => ({
  color: theme.customColors.dark[400],
  justifyContent: 'center',
  minHeight: 100,
  p: {
    color: theme.customColors.dark[400],
    fontWeight: 600,
  },
}));

const ListContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'maxHeight' })<{ maxHeight?: number }>(
  ({ theme, maxHeight }) => ({
    ...createScrollingStyles({ theme }),
    maxHeight,
    overflowY: 'scroll',
  }),
);

type TSelectionItem<TItem, TItemKey> = {
  label: string;
  key: TItemKey;
  checked: boolean;
  item: TItem;
};

export type MultiSelectionListProps<T, ItemKey> = {
  items: T[];
  multiple?: boolean;
  onChange: (selected: TSelectionItem<T, ItemKey>['key'][]) => void;
  selected?: TSelectionItem<T, ItemKey>['key'][];
  enableSearch?: boolean;
  searchPlaceholder?: string;
  renderItem?: (item: T & { checked: boolean }) => React.ReactNode;
  keyExtractor?: (item: T) => ItemKey;
  labelExtractor?: (item: T) => string;
  listMaxHeight?: number;
  hideCheckbox?: boolean;
  isItemDisabled?: (item: T) => boolean;
};

const SelectionItem = styled(FlexBox, { shouldForwardProp: (prop) => prop !== 'clickable' })<{ clickable?: boolean }>(
  ({ theme, clickable = false }) => ({
    margin: theme.spacing(clickable ? 0 : 2, 0),
    justifyContent: 'flex-start',
    cursor: clickable ? 'pointer' : 'default',
    padding: clickable ? theme.spacing(1) : 0,
    borderRadius: clickable ? theme.shape.borderRadius : 0,

    '&:hover': {
      backgroundColor: clickable ? theme.palette.action.hover : 'transparent',
    },
  }),
);

export function MultiSelectionList<T = never, ItemKey extends string | number = number>({
  items,
  onChange,
  enableSearch = true,
  keyExtractor = (s: T) => s as unknown as ItemKey,
  renderItem,
  searchPlaceholder,
  selected = [],
  labelExtractor = (s: T) => s as unknown as string,
  listMaxHeight,
  hideCheckbox,
  isItemDisabled,
  multiple = true,
}: MultiSelectionListProps<T, ItemKey>) {
  const [search, setSearch] = useState('');

  const handleSearchChange = (q: string) => {
    setSearch(q);
  };

  const toggleSelection = (key: TSelectionItem<T, ItemKey>['key']) => () => {
    if (selected?.includes(key)) {
      onChange(selected.filter((item) => item !== key));
    } else {
      onChange([...selected, key]);
    }
  };

  const filteredEntries = search
    ? items.filter((item) => labelExtractor(item).toLowerCase().includes(search.toLowerCase()))
    : items;

  const list = filteredEntries.map((item) => {
    const itemKey = keyExtractor(item);
    const label = labelExtractor(item);
    const isDisabled = isItemDisabled ? isItemDisabled(item) : false;
    const checked = !!selected?.includes(itemKey);

    if (renderItem) {
      return renderItem({ checked: checked, ...item });
    }
    return (
      <SelectionItem
        onClick={hideCheckbox ? toggleSelection(itemKey) : undefined}
        clickable={hideCheckbox}
        key={itemKey}
      >
        {!hideCheckbox && (
          <Checkbox
            disabled={isDisabled}
            sx={{ padding: 0, mr: 2 }}
            checked={checked}
            onChange={toggleSelection(itemKey)}
          />
        )}
        <Typography overflow="hidden" textOverflow="ellipsis" fontWeight="600">
          {label}
        </Typography>
      </SelectionItem>
    );
  });

  return (
    <>
      {enableSearch && <SearchBar placeholder={searchPlaceholder} onChange={handleSearchChange} />}
      {multiple && selected.length > 0 && (
        <Stack sx={{ mt: 2 }} useFlexGap flexWrap="wrap" direction="row" spacing={1}>
          {selected.map((key) => {
            const item = items.find((i) => keyExtractor(i) === key);
            if (!item) {
              return null;
            }

            return (
              <Chip
                color="primary"
                key={keyExtractor(item)}
                variant="outlined"
                onDelete={toggleSelection(keyExtractor(item))}
                label={labelExtractor(item)}
              />
            );
          })}
        </Stack>
      )}

      {list.length > 0 ? (
        <ListContainer mt={2} maxHeight={listMaxHeight}>
          {list}
        </ListContainer>
      ) : (
        <NoResultsContainer justifyContent="center" minHeight={200}>
          <Typography>No items found</Typography>
        </NoResultsContainer>
      )}
    </>
  );
}
