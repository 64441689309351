import {
  Box,
  styled,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { PlanTerm } from '@usgm/inbox-api-types';
import { FlexBox, IOSSwitch, LabelBadge, useIsTouchDevice, useMobileMode } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import { useAppDispatch, useAppSelector } from '../../../store';
import { planPickerSlice, selectPlanTerm } from '../planPickerSlice';

export type PlanTermSwitcherProps = {
  switchSize?: 'small' | 'large' | 'extraSmall';
  badgeText?: string;
  hideBadge?: boolean;
  justifyContent?: 'left' | 'center' | 'right';
};

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 4,
  textTransform: 'none',
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
  flex: '1 1 auto',
  '&.Mui-selected': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 4,
  width: '100%',
}));

const SavingLabelContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: `calc(100% + ${theme.spacing(2)})`,
  width: 'max-content',
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    transform: 'none',
    left: 'auto',
    right: 0,
    top: theme.spacing(-4),
  },
}));

export function PlanTermSwitcher({
  badgeText,
  hideBadge,
  switchSize,
  justifyContent = 'center',
}: PlanTermSwitcherProps) {
  const dispatch = useAppDispatch();
  const isDownMobileLandscape = useMediaQuery(inboxHelpers.DOWN_MOBILE_LANDSCAPE);
  const term = useAppSelector(selectPlanTerm);

  const toggleTerm = () => {
    dispatch(planPickerSlice.actions.setTerm(term === PlanTerm.Annually ? PlanTerm.Monthly : PlanTerm.Annually));
  };

  const theme = useTheme<Theme>();
  const isMobile = useMobileMode();
  const activeColor = 'text.primary';
  const inActiveColor = 'text.secondary';
  const isAnnual = term === PlanTerm.Annually;
  const switchGap = isMobile ? 1 : 2;

  const isTouchDevice = useIsTouchDevice();

  const handleChange = (event: React.MouseEvent<HTMLElement>, newTerm: PlanTerm) => {
    if (!newTerm) return;
    dispatch(planPickerSlice.actions.setTerm(newTerm));
  };

  const savingBadge = !!badgeText && !hideBadge && !isMobile && !isTouchDevice && (
    <SavingLabelContainer>
      <LabelBadge
        borderRadius="8px"
        backgroundColor={theme.palette.mode === 'light' ? theme.palette.yellow.main : theme.palette.yellow.dark}
        label={badgeText}
        color={theme.palette.yellow.contrastText}
      />
    </SavingLabelContainer>
  );

  return isDownMobileLandscape ? (
    <Box position="relative" mt={5}>
      {savingBadge}
      <StyledToggleButtonGroup color="secondary" value={term} exclusive onChange={handleChange} aria-label="Term">
        <StyledToggleButton value={PlanTerm.Monthly}>Billed Monthly</StyledToggleButton>
        <StyledToggleButton value={PlanTerm.Annually}>Billed Annually </StyledToggleButton>
      </StyledToggleButtonGroup>
    </Box>
  ) : (
    <FlexBox mt={5} justifyContent={justifyContent}>
      <FlexBox
        width="fit-content"
        position="relative"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent={justifyContent}
      >
        <FlexBox>
          <Typography
            onClick={() => isAnnual && toggleTerm()}
            sx={{
              cursor: 'pointer',
              color: !isAnnual ? activeColor : inActiveColor,
              fontWeight: !isAnnual ? 'bold' : 'normal',
              mr: switchGap,
            }}
          >
            Billed Monthly
          </Typography>
          <IOSSwitch variant={switchSize} checked={isAnnual} onChange={toggleTerm} />
          <Typography
            onClick={() => !isAnnual && toggleTerm()}
            sx={{
              cursor: 'pointer',
              ml: switchGap,
              color: isAnnual ? activeColor : inActiveColor,
              fontWeight: isAnnual ? 'bold' : 'normal',
            }}
          >
            Billed Annually
          </Typography>
          {savingBadge}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default PlanTermSwitcher;
