import { Box, Skeleton, styled } from '@mui/material';
import { FlexBox } from '@usgm/shared-ui';

export type NavigationSkeletonProps = {
  count: number;
};

const SkeletonItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '8px',
  padding: theme.spacing(1, 1.5),
  '&:last-child': {
    marginBottom: 0,
  },
}));

export function NavigationSkeleton({ count }: NavigationSkeletonProps) {
  return (
    <Box>
      {Array.from({ length: count }).map((_, index) => (
        <SkeletonItem key={index}>
          <FlexBox sx={{ width: (theme) => theme.spacing(7) }}>
            <Skeleton variant="rectangular" width={20} height={20} />
          </FlexBox>
          <Skeleton variant="text" width="100%" />
        </SkeletonItem>
      ))}
    </Box>
  );
}
