import { selectActiveSubscription, useGetSubscriptionsQuery } from '../../../../../api/paymentApi';

import { useMemo } from 'react';
import { useAppSelector } from '../../../../../store';
import {
  selectAccountVerificationState,
  selectHasRejectedDocuments,
  selectIsAccountNamesAdded,
  selectIsDocumentsUploaded,
  selectIsNotarizationNeeded,
  selectIsPrimaryAccountStepsCompleted,
  selectIsVerified,
} from '../../../../auth/authSlice';
import { useGetOnboardingStateQuery } from '../api';

export function useOnboardingData(refetch = false) {
  const queryParams = null;
  const accountVerificationState = useAppSelector((state) => selectAccountVerificationState(state, queryParams));
  const selectActiveSubscriptionData = useMemo(selectActiveSubscription, []);
  const accountVerificationStateQuery = useGetOnboardingStateQuery(queryParams, {
    refetchOnMountOrArgChange: refetch,
  });

  const subscriptionsQuery = useGetSubscriptionsQuery(null, {
    selectFromResult: (result) => ({
      ...result,
      activeSubscription: selectActiveSubscriptionData(result.data),
    }),
  });

  const hasRejectedDocuments = useAppSelector((state) => selectHasRejectedDocuments(state, queryParams));

  const isDocumentsUploaded = useAppSelector(selectIsDocumentsUploaded);
  const isVerified = useAppSelector((state) => selectIsVerified(state, queryParams));
  const isAccountNamesAdded = useAppSelector((state) => selectIsAccountNamesAdded(state, queryParams));
  const isPrimaryAccountStepsCompleted = useAppSelector((state) =>
    selectIsPrimaryAccountStepsCompleted(state, queryParams),
  );
  const isNotarizationNeeded = useAppSelector((state) => selectIsNotarizationNeeded(state, queryParams));

  return {
    isVerified,
    accountVerificationStateQuery,
    isLoading: subscriptionsQuery.isLoading || accountVerificationStateQuery.isLoading,
    activeSubscription: subscriptionsQuery.activeSubscription,
    accountVerificationState,
    isAccountNamesAdded,
    hasRejectedDocuments,
    isDocumentsUploaded,
    isPrimaryAccountStepsCompleted,
    isNotarizationNeeded,
  };
}
