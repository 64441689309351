import { DtoPlan, type DtoAuthData, type DtoWarehouse } from '@usgm/inbox-api-types';
import { TCompanyData } from '../onboarding';
import { TypedStorageManager } from '../storageManager';

type TStorage = {
  selectedWarehouse: DtoWarehouse['id'];
  selectedPlan: DtoPlan;
  authData: DtoAuthData;
  prefersDarkMode?: boolean;
  companiesData: Record<number, TCompanyData & { id: number }>;
};

type TSessionStorage = {
  resetPasswordToken: string;
};

const sessionStorageManager = new TypedStorageManager<TSessionStorage>(sessionStorage);
const storageManager = new TypedStorageManager<TStorage>();

sessionStorageManager.init();
storageManager.init();

export const getStorageManager = () => {
  return storageManager;
};

export const getSessionStorageManager = () => {
  return sessionStorageManager;
};
