import { usePlanList } from '../../../../../hooks/usePlanList';
import { Container, FlexBox } from '@usgm/shared-ui';
import { Box, CircularProgress, styled } from '@mui/material';
import PlanTermSwitcher from '../../../../planPicker/components/PlanTermSwitcher';
import React from 'react';
import { PlanItem } from '../../../../planPicker/components/PlansListSection';

const StyledGrid = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'desktop' && prop !== 'count',
})<{ desktop: boolean; count: number }>(({ theme, desktop = false, count }) => ({
  display: 'grid',
  gridTemplateColumns: desktop ? `repeat(${count}, 1fr)` : '1fr',
  rowGap: theme.spacing(2),
  columnGap: theme.spacing(3),
}));

export function PlansStep({
  handleSelection,
  changeWarehouseMode = false,
  isDesktop = false,
}: {
  handleSelection: (step?: number) => void;
  changeWarehouseMode: boolean;
  isDesktop: boolean;
}) {
  const {
    plansQuery,
    pickedWarehouse,
    plans,
    term,
    handleCardDeselect,
    handleCardSelect,
    selectedCardType,
    badgeText,
    hasSavings,
  } = usePlanList(changeWarehouseMode);

  return (
    <Box>
      <Container sx={{ padding: 0 }} position="relative">
        {pickedWarehouse ? <PlanTermSwitcher switchSize="small" badgeText={badgeText} hideBadge={!hasSavings} /> : null}
        <FlexBox
          flexDirection={isDesktop ? 'row' : 'column'}
          mt={4.5}
          justifyContent="stretch"
          gap={isDesktop ? 1 : 2.5}
        >
          {plansQuery.isFetching ? (
            <FlexBox minHeight={400} justifyContent="center" flex={1}>
              <CircularProgress />
            </FlexBox>
          ) : (
            <StyledGrid desktop={isDesktop} count={plans.length}>
              {plans.map(({ id, name }) => (
                <PlanItem
                  handleSelection={handleSelection}
                  onCardDeselect={handleCardDeselect}
                  onCardSelect={handleCardSelect}
                  selected={name === selectedCardType}
                  changeWarehouseMode={changeWarehouseMode}
                  key={id}
                  id={id}
                  term={term}
                />
              ))}
            </StyledGrid>
          )}
        </FlexBox>
      </Container>
    </Box>
  );
}

export default React.memo(PlansStep);
