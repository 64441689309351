import { useCallback, useState } from 'react';

export function useDialogs(dialogKeys: string[]) {
  const [dialogList, setDialogList] = useState(() => {
    const result: Record<string, boolean> = {};
    dialogKeys.forEach((key) => {
      result[key] = false;
    });
    return result;
  });

  const openDialog = useCallback((key: string) => {
    setDialogList((prev) => ({ ...prev, [key]: true }));
  }, []);

  const closeDialog = useCallback((key: string) => {
    setDialogList((prev) => ({ ...prev, [key]: false }));
  }, []);

  return { dialogList, openDialog, closeDialog };
}
