import { styled } from '@mui/material';
import { FlexBox, Inbox, MessageBox, Preloader, PureLink, Toast } from '@usgm/shared-ui';
import { Link as RouterLink } from 'react-router-dom';

import { useCreateOrUpdateUserSettingsMutation, useGetUserSettingsQuery, UserSettingType } from '../../api';
import { CardHeader } from '../../components/Card';
import SettingFeatureSwitcher from '../../components/SettingFeatureSwitcher';
import { useUserScanBundleData } from '../../hooks/useUserScanBundleData';
import { SETTINGS_PATHS } from '../../paths';

const Root = styled(FlexBox)(({ theme }) => ({
  gap: theme.spacing(6),
  alignItems: 'flex-start',
  flexDirection: 'column',
  padding: theme.spacing(2.5, 0),
}));

const FEATURE_DESCRIPTION_MAP: { [key in 'letter' | 'largeLetter']: string } = {
  letter:
    'We will open and scan all letters as soon as they arrive to your mailbox. Letters are regular sized envelops, usually 4" x 9".',
  largeLetter:
    'We will open and scan all large letters as soon as they arrive to your mailbox. Large Letters are envelops usually sized 8" x 11" or 8" x 14".',
};

function AutoScanPage() {
  const { activeScanBundle, isLoading: isLoadingActiveScanBundle } = useUserScanBundleData();
  const { data: userSettings, isLoading } = useGetUserSettingsQuery({ settingType: UserSettingType.ScanOptions });
  const [saveUserSettings, saveUserSettingsQuery] = useCreateOrUpdateUserSettingsMutation();

  const createToggleHandler = (settingKey: 'autoScanLetters' | 'autoScanLargeLetters') => (value: boolean) => {
    saveUserSettings({
      uuid: userSettings?.uuid,
      payload: { scanOptions: { ...userSettings?.scanOptions, [settingKey]: value } },
    });
  };

  return (
    <>
      <Inbox.PageContainer>
        <Inbox.Card noSpacings>
          <CardHeader
            subTitle="To simplify mail management, you can set up your mail contents to get automatically scanned upon arrival. Junk mail is pre-sorted so it never makes it into your mailbox or gets scanned. Activate by choosing your preferences below."
            title="Get your mail items automatically scanned"
          />

          <Inbox.CardContent height="100%">
            {isLoading ? (
              <Preloader minHeight={200} />
            ) : (
              <Root>
                {!activeScanBundle && !isLoadingActiveScanBundle && (
                  <MessageBox severity={'info'}>
                    Maximize savings with a{' '}
                    <PureLink to={SETTINGS_PATHS.SCAN_BUNDLES} component={RouterLink}>
                      Scan Bundle
                    </PureLink>
                  </MessageBox>
                )}

                {!isLoading && (
                  <>
                    <SettingFeatureSwitcher
                      title={'Letters'}
                      description={FEATURE_DESCRIPTION_MAP['letter']}
                      defaultChecked={userSettings?.scanOptions?.autoScanLetters}
                      onChange={createToggleHandler('autoScanLetters')}
                      isLoading={saveUserSettingsQuery.isLoading}
                    />
                    <SettingFeatureSwitcher
                      title={'Large Letters'}
                      description={FEATURE_DESCRIPTION_MAP['largeLetter']}
                      defaultChecked={userSettings?.scanOptions?.autoScanLargeLetters}
                      onChange={createToggleHandler('autoScanLargeLetters')}
                      isLoading={saveUserSettingsQuery.isLoading}
                    />
                  </>
                )}
              </Root>
            )}
          </Inbox.CardContent>
        </Inbox.Card>
      </Inbox.PageContainer>
      {saveUserSettingsQuery.isError && <Toast title={'Oops! Settings were not saved due to an unexpected error'} />}
      {saveUserSettingsQuery.isSuccess && (
        <Toast title={'Auto Scan settings successfully updated'} severity={'success'} />
      )}
    </>
  );
}

export default AutoScanPage;
