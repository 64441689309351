import { inboxHelpers, routeHelpers } from '@usgm/utils';

const ROOT_PATH = `${inboxHelpers.MAIN_PATHS.APP}/${inboxHelpers.APP_PATHS.BILLING_HISTORY}`;

export const BILLINGS_TABS = [
  {
    label: 'Credits',
    value: 'credit-notes',
  },
  {
    label: 'Invoices',
    value: 'invoices',
  },
  {
    label: 'Unbilled Charges',
    value: 'unbilled-charges',
  },
] as const;

export const DEFAULT_BILLING_TAB = BILLINGS_TABS[1].value;

type BillingTab = (typeof BILLINGS_TABS)[number]['value'];

export const BILLING_ROUTES = {
  BILLINGS: `:tab/:itemId?`,
};

export type BillingPathParams = Omit<routeHelpers.TabsPathParams<BillingTab>, 'params'>;

export const createBillingsPath = routeHelpers.createPathGenerator<BillingTab>(ROOT_PATH);
