import { CircularProgress } from '@mui/material';
import { FlexBox } from '@usgm/shared-ui';

export default function PageLoader() {
  return (
    <FlexBox justifyContent="center" minHeight="90vh">
      <CircularProgress />
    </FlexBox>
  );
}
