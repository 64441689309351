import { StatusTransition } from '@usgm/inbox-api-types';
import { DateTime } from 'luxon';

export function findLatestStatusTransition(transitions: StatusTransition[], targetStatus: string): StatusTransition {
  if (!Array.isArray(transitions) || typeof targetStatus !== 'string') {
    throw new Error('Invalid input');
  }

  return (
    transitions
      .filter((transition) => transition.status === targetStatus && DateTime.fromISO(transition.updatedOn).isValid)
      .sort((a, b) => DateTime.fromISO(b.updatedOn).toMillis() - DateTime.fromISO(a.updatedOn).toMillis())[0] || null
  );
}
