import React from 'react';
import { Route } from 'react-router-dom';

import PublicLayout from '../../../components/layouts/public';
import { inboxHelpers } from '@usgm/utils';
import PageLoader from '../../../components/PageLoader';
import ResetPasswordTokenRequired from '../components/protectedRoutes/ResetPasswordTokenRequired';
import PlanSelectionRequiredRoute from '../components/protectedRoutes/PlanSelectionRequiredRoute';
import AuthRequiredRoute from '../components/protectedRoutes/AuthRequiredRoute';
import LoginRoute from '../components/publicRoutes/LoginRoute';
import { SocialAuthHandler } from '../components/SocialAuthHandler';

const CheckoutPage = React.lazy(() => import('../pages/CheckoutPage'));
const CreateAccountPage = React.lazy(() => import('../pages/CreateAccountPage'));
const ForgotPasswordPage = React.lazy(() => import('../pages/ForgotPasswordPage'));
const LoginPage = React.lazy(() => import('../pages/LoginPage'));
const ResetPasswordPage = React.lazy(() => import('../pages/ResetPasswordPage'));
const VerifyResetPasswordTokenPage = React.lazy(() => import('../pages/VerifyResetPasswordTokenPage'));
const WelcomePage = React.lazy(() => import('../pages/WelcomePage'));

export const useAuthRoutes = () => {
  return (
    <Route element={<PublicLayout disableFooter />}>
      <Route element={<LoginRoute />}>
        <Route
          path={inboxHelpers.MAIN_PATHS.LOGIN}
          element={
            <React.Suspense fallback={<PageLoader />}>
              <LoginPage />
            </React.Suspense>
          }
        />
      </Route>
      <Route element={<ResetPasswordTokenRequired />}>
        <Route
          path={inboxHelpers.MAIN_PATHS.RESET_PASSWORD}
          element={
            <React.Suspense fallback={<PageLoader />}>
              <ResetPasswordPage />
            </React.Suspense>
          }
        />
      </Route>
      <Route
        path={inboxHelpers.MAIN_PATHS.FORGOT_PASSWORD}
        element={
          <React.Suspense fallback={<PageLoader />}>
            <ForgotPasswordPage />
          </React.Suspense>
        }
      />
      <Route
        path={inboxHelpers.MAIN_PATHS.VERIFY_RESET_PASSWORD_TOKEN}
        element={
          <React.Suspense fallback={<PageLoader />}>
            <VerifyResetPasswordTokenPage />
          </React.Suspense>
        }
      />
      <Route element={<AuthRequiredRoute />}>
        <Route
          path={inboxHelpers.MAIN_PATHS.WELCOME}
          element={
            <React.Suspense fallback={<PageLoader />}>
              <WelcomePage />
            </React.Suspense>
          }
        />
      </Route>

      <Route element={<PlanSelectionRequiredRoute />}>
        <Route element={<SocialAuthHandler />}>
          <Route element={<AuthRequiredRoute />}>
            <Route
              path={inboxHelpers.MAIN_PATHS.CHECKOUT}
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <CheckoutPage />
                </React.Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path={inboxHelpers.MAIN_PATHS.CREATE_ACCOUNT}
          element={
            <React.Suspense fallback={<PageLoader />}>
              <CreateAccountPage />
            </React.Suspense>
          }
        />
      </Route>

      <Route
        path={inboxHelpers.MAIN_PATHS.ACCEPT_TEAM_MEMBER_INVITATION}
        element={
          <React.Suspense fallback={<PageLoader />}>
            <CreateAccountPage isTeamMemberAcceptMode={true} />
          </React.Suspense>
        }
      />
    </Route>
  );
};
