import { Box, styled, Typography } from '@mui/material';
import React, { useId, useMemo, useRef } from 'react';

import { type DtoBankAccount } from '@usgm/inbox-api-types';
import { ConfirmationDialog, MoreMenu, useDialog } from '@usgm/shared-ui';
import { getKeys } from '@usgm/utils';

const BANK_ACCOUNT_ACTIONS = {
  edit: {
    label: 'Edit',
    color: undefined,
  },
  delete: {
    label: 'Delete',
    color: 'dangerous',
  },
} as const;

type TBankAccountAction = keyof typeof BANK_ACCOUNT_ACTIONS;

const StyledMoreButtonCell = styled(Box)(({ theme }) => ({
  textAlign: 'right',
  width: '100%',
  marginRight: theme.spacing(8),
}));

export type BankAccountActionsProps = {
  item: DtoBankAccount;
  onEditClick: (bankAccount: DtoBankAccount) => void;
  onDeleteClick: (bankAccount: DtoBankAccount) => void;
};

export function BankAccountActions({ item, onEditClick, onDeleteClick }: BankAccountActionsProps) {
  const moreButton = useRef<HTMLHtmlElement>();

  const popperId = useId();

  // Dialogs
  const deleteDialog = useDialog();

  const allActions = useMemo(() => {
    const actions: { [Key in TBankAccountAction]: () => void } = {
      edit: () => onEditClick(item),
      delete: () => deleteDialog.openDialog(),
    };
    return actions;
  }, [item, onEditClick, deleteDialog]);

  const actionsList = useMemo(() => {
    return getKeys<typeof BANK_ACCOUNT_ACTIONS>(BANK_ACCOUNT_ACTIONS).map((action) => ({
      label: BANK_ACCOUNT_ACTIONS[action].label,
      onClick: allActions[action],
      dangerous: BANK_ACCOUNT_ACTIONS[action].color === 'dangerous',
    }));
  }, [allActions]);

  return (
    <>
      <StyledMoreButtonCell>
        <Box ref={moreButton}>
          <MoreMenu containerProps={{ 'aria-describedby': popperId }} items={actionsList} />
        </Box>
      </StyledMoreButtonCell>
      <ConfirmationDialog
        onClose={deleteDialog.closeDialog}
        onConfirm={() => onDeleteClick(item)}
        confirmText="Confirm"
        cancelText="Cancel"
        open={deleteDialog.open}
        content={
          <Box>
            <Typography textAlign="center" variant="h5">
              Are you sure you want to delete this bank account?
            </Typography>
          </Box>
        }
      />
    </>
  );
}

export default React.memo(BankAccountActions);
