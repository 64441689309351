import { ScrollableArea } from '@usgm/shared-ui';
import { Outlet } from 'react-router-dom';

export function ScrollableLayout() {
  return (
    <ScrollableArea>
      <Outlet />
    </ScrollableArea>
  );
}
