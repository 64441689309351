import { Box, Typography } from '@mui/material';
import FlexBox from '../FlexBox';

export type PlaceholderProps = {
  maxFileSize?: number;
  accept?: string;
  icon?: React.ReactNode;
};

export function Placeholder({ accept, maxFileSize, icon }: PlaceholderProps) {
  const sizeInMb = maxFileSize ? maxFileSize / 1024 / 1024 : 0;
  const helperText = `${accept ? `accepts ${accept} files, ` : ''} ${sizeInMb ? `max size ${sizeInMb}MB` : ''}`;
  return (
    <FlexBox flexDirection="column">
      <Box>{icon}</Box>
      <Typography>
        <Typography fontWeight="bold" component={'span'} color="primary.main">
          Upload file
        </Typography>{' '}
        or drag and drop file here
      </Typography>
      {helperText ? <Typography>({helperText})</Typography> : null}
    </FlexBox>
  );
}
