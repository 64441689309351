import { Route } from 'react-router-dom';

import { inboxHelpers } from '@usgm/utils';
import TeamMembersNotSupportedRoute from '../../../../auth/components/protectedRoutes/TeamMembersNotSupportedRoute';
import VerifyAccountAllowedRoute from '../../../../auth/components/protectedRoutes/VerifyAccountAllowedRoute';
import { ScrollableLayout } from '../../../components/layout/ScrollableLayout';
import { OnboardingStepLayout } from '../components/OnboardingStepLayout';
import AddIdsPage from '../pages/AddIdsPage';
import AddNamesPage from '../pages/AddNamesPage';
import GetNotarizedPage from '../pages/GetNotarizedPage';
import WelcomePage from '../pages/WelcomePage';
import { ONBOARDING_ROUTES } from '../paths';

export function useOnboardingRoutes() {
  return (
    <Route element={<TeamMembersNotSupportedRoute />}>
      <Route element={<VerifyAccountAllowedRoute />}>
        <Route path={inboxHelpers.APP_PATHS.VERIFY_ACCOUNT} element={<ScrollableLayout />}>
          <Route index element={<WelcomePage />} />
          <Route element={<OnboardingStepLayout />}>
            <Route path={ONBOARDING_ROUTES.ADD_NAMES_ROUTE} element={<AddNamesPage />} />
            <Route path={ONBOARDING_ROUTES.ADD_IDS_ROUTE} element={<AddIdsPage />} />
            <Route path={ONBOARDING_ROUTES.GET_NOTARIZED_ROUTE} element={<GetNotarizedPage />} />
          </Route>
        </Route>
      </Route>
    </Route>
  );
}
