import { Done, PriorityHigh } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import WestIcon from '@mui/icons-material/West';
import { Box, CircularProgress, MenuItem, styled, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DtoTeamMemberInvitee, DtoTeamMemberInviteResult, OrgUserRole } from '@usgm/inbox-api-types';
import {
  Button,
  FlexBox,
  IconLinkButton,
  Inbox,
  RemoveButton,
  TextButton,
  TextInput,
  Toast,
  useMobileMode,
} from '@usgm/shared-ui';
import { isValidEmail } from '@usgm/utils';

import { StyledCardHeaderTitle } from '../../../../../components/styled/StyledCardHeaderTitle';
import { useInviteTeamMembersMutation } from '../../../api';
import { CardHeader } from '../../../components/Card';
import { StyledSelectBox } from '../../../components/TeamMembers/styled/StyledSelectBox';
import { normalizeTeamMemberRole } from '../../../helpers';
import { SETTINGS_PATHS } from '../../../paths';

interface TeamMemberInvitee extends DtoTeamMemberInvitee {
  error?: boolean;
  message?: string;
  invited?: boolean;
  uuid?: string;
}

const ROLE_OPTIONS = [OrgUserRole.Regular, OrgUserRole.Manager];
const DEFAULT_ROW: TeamMemberInvitee = {
  name: '',
  email: '',
  role: OrgUserRole.Regular,
};

const StyledExclamatoryIcon = styled(PriorityHigh)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const StyledDoneIcon = styled(Done)(({ theme }) => ({
  color: theme.palette.success.main,
}));

function InviteTeamMembersPage() {
  const navigate = useNavigate();
  const isMobile = useMobileMode();

  const [updateMutation, { isLoading: isSubmitting, error }] = useInviteTeamMembersMutation();
  const [invitees, setInvitees] = useState([DEFAULT_ROW]);

  const handleBackClick = () => {
    navigate(SETTINGS_PATHS.TEAM_MEMBERS);
  };

  const appendRow = () => {
    setInvitees([...invitees, DEFAULT_ROW]);
  };

  const removeRow = (index: number) => {
    setInvitees(invitees.filter((_, ind) => ind !== index));
  };

  const updateRow = <K extends keyof DtoTeamMemberInvitee>({
    index,
    field,
    value,
  }: {
    index: number;
    field: K;
    value: DtoTeamMemberInvitee[K];
  }) => {
    const invitee = invitees[index];
    if (!invitee) {
      return;
    }
    const updatedRow = { ...invitee, [field]: value };
    if (field === 'email') {
      updatedRow.error = !isValidEmail(value as string);
      updatedRow.message = '';
    }
    setInvitees([...invitees.slice(0, index), updatedRow, ...invitees.slice(index + 1)]);
  };

  const validateRows = (): boolean => {
    const validateInvitees = invitees.map((it) => ({ ...it, error: it.error || !isValidEmail(it.email) }));
    setInvitees(validateInvitees);
    return validateInvitees.every((it) => !it.error);
  };

  const renderEmailInputIcon = (invitee: TeamMemberInvitee): ReactNode => {
    if (invitee.invited) {
      return <StyledDoneIcon />;
    }
    if (invitee.error && invitee.message) {
      return <StyledExclamatoryIcon />;
    }
    return null;
  };

  const onSubmit = async () => {
    const canSubmit = validateRows();
    const payload = invitees
      .filter((it) => !it.error && !it.invited)
      .map((it) => ({ name: it.name, email: it.email, role: it.role }));
    if (!canSubmit || !payload.length) {
      return;
    }
    const response = await updateMutation(payload);
    if ('data' in response && !('error' in response)) {
      const updatedInvitees = invitees.map((it) => {
        const responseItem = (response as { data: { data: DtoTeamMemberInviteResult[] } }).data.data?.find(
          (r) => r?.email === it.email,
        );
        if (!responseItem) {
          return it;
        }
        return {
          ...it,
          error: !responseItem.success,
          message: responseItem.message,
          invited: responseItem.success,
          uuid: responseItem.uuid,
        };
      });
      setInvitees(updatedInvitees);
      if (updatedInvitees.every((it) => it.invited)) {
        navigate(SETTINGS_PATHS.TEAM_MEMBERS);
      }
    }
  };

  const inviteButton = (
    <Button variant="contained" onClick={onSubmit} minWidth={166}>
      {isSubmitting ? <CircularProgress size={24} color="inherit" /> : isMobile ? 'Send' : 'Send Invitations'}
    </Button>
  );

  return (
    <>
      <Inbox.PageContainer>
        <Inbox.Card noSpacings>
          <CardHeader
            title={
              <>
                <TextButton onClick={handleBackClick} sx={{ minWidth: 'auto', marginRight: 2 }}>
                  <WestIcon />
                </TextButton>
                <StyledCardHeaderTitle variant={isMobile ? 'body1' : 'h6'}>Invite Team Members</StyledCardHeaderTitle>
              </>
            }
          >
            {!isMobile && (
              <FlexBox gap={2} justifyContent={'flex-end'}>
                <Button variant="outlined" onClick={handleBackClick}>
                  Back
                </Button>

                {inviteButton}
              </FlexBox>
            )}
          </CardHeader>

          <Inbox.CardContent>
            <Typography mb={6}>
              Invite multiple team members at once. Choose User or Manager permissions. Users can access only the
              folders they are added to while Managers have access to the entire mailbox. Invitees will receive an email
              with instructions to complete their registration with US Global Mail.
            </Typography>
            {invitees &&
              invitees.map((invitee, index) => (
                <FlexBox
                  key={index}
                  gap={2}
                  alignItems={'flex-start'}
                  pb={invitee.error ? 2.15 : 5}
                  pr={isMobile || invitees.length > 1 ? 0 : 5}
                  flexDirection={isMobile ? 'column' : 'row'}
                >
                  <TextInput
                    label="Email"
                    autoFocus
                    required
                    placeholder="Enter email address"
                    fullWidth
                    value={invitee.email}
                    onChange={(event) => updateRow({ index, field: 'email', value: event.target.value })}
                    error={invitee.error}
                    helperText={invitee.error ? invitee.message || 'Invalid email' : ''}
                    sx={{ height: 'auto', flex: 3 }}
                    disabled={invitee.invited}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ endAdornment: renderEmailInputIcon(invitee) }}
                  />
                  <TextInput
                    sx={{ height: 'auto', flex: 2 }}
                    label="Name (optional)"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Name"
                    fullWidth
                    disabled={invitee.invited}
                    value={invitee.name}
                    onChange={(event) => updateRow({ index, field: 'name', value: event.target.value })}
                  />
                  <StyledSelectBox
                    label=""
                    InputLabelProps={{ shrink: true }}
                    placeholder="Role"
                    fullWidth
                    disabled={invitee.invited}
                    select
                    value={invitee.role}
                    onChange={(event) => updateRow({ index, field: 'role', value: event.target.value as OrgUserRole })}
                  >
                    {ROLE_OPTIONS.map((role) => (
                      <MenuItem key={role} value={role}>
                        {normalizeTeamMemberRole(role)}
                      </MenuItem>
                    ))}
                  </StyledSelectBox>
                  {invitees.length > 1 &&
                    (isMobile ? (
                      <FlexBox justifyContent="flex-end" width="100%">
                        <Button
                          onClick={isSubmitting || invitee.invited ? undefined : () => removeRow(index)}
                          disabled={isSubmitting}
                          size="small"
                          variant="outlined"
                          color="error"
                        >
                          Remove
                        </Button>
                      </FlexBox>
                    ) : (
                      <RemoveButton onClick={isSubmitting || invitee.invited ? undefined : () => removeRow(index)} />
                    ))}
                </FlexBox>
              ))}
            <Box ml={0.5}>
              <IconLinkButton onClick={appendRow} icon={<AddIcon fontSize="small" />} value="Add more" />
            </Box>
            {isMobile && (
              <FlexBox mt={4} justifyContent="center">
                {inviteButton}
              </FlexBox>
            )}
          </Inbox.CardContent>
        </Inbox.Card>
      </Inbox.PageContainer>
      {error && <Toast title={error.message || 'Something went wrong, please try again later'} severity="error" />}
    </>
  );
}

export default InviteTeamMembersPage;
