import {
  Box,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  StepperProps,
  Theme,
  alpha,
  styled,
  useMediaQuery,
} from '@mui/material';

type UnorderedStepperProps = StepperProps & { steps: Array<{ label: string; onClick?: () => void }> };

const CustomLabel = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
  textAlign: 'center',
}));

const StyledStepper = styled(Stepper)(({ theme }) => ({
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
  },
  '& .MuiStepConnector-line': {
    borerTopWidth: 2,
    borderTopStyle: 'dashed',
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#D7DDE4',
  },
  '& .Mui-active .MuiStepConnector-line, & .Mui-completed .MuiStepConnector-line': {
    borderTopStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
  '& .MuiStepLabel-root': {
    position: 'relative',
  },
  '.MuiStepLabel-iconContainer': {
    paddingRight: 0,
  },
  '& .MuiStep-horizontal': {
    padding: 0,
  },

  '.MuiStepLabel-labelContainer': {
    padding: 0,
    position: 'absolute',
    width: 'auto',
    left: '50%',
    top: theme.spacing(4),
    transform: 'translateX(-50%)',
  },
  '& .MuiStepLabel-label': {
    color: '#9BA6B4',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    fontWeight: theme.typography.fontWeightBold,
    '&.Mui-active': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  '& .Mui-completed .MuiStepLabel-label': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const UnorderedStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#D7DDE4',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  paddingLeft: 0,
  position: 'relative',

  '@keyframes pulsate': {
    '0%': { transform: 'scale(0.3, 0.3)', opacity: 0 },
    '50%': { opacity: 1.0 },
    '100%': { transform: 'scale(1, 1)', opacity: 0 },
  },
  '& .cm-circle': {
    position: 'relative',
    width: 8,
    height: 8,
    backgroundColor: 'currentColor',
    borderRadius: '50%',
    ...(ownerState.active && {
      backgroundColor: theme.palette.primary.main,
      '&:before': {
        content: '""',
        color: theme.palette.primary.main,
        border: `16px solid ${alpha(theme.palette.primary.main, 0.3)}`,
        width: 8,
        height: 8,
        position: 'absolute',
        left: -12,
        bottom: -12,
        borderRadius: '50%',
        animation: 'pulsate 1.5s ease-out',
        animationIterationCount: 'infinite',
      },
    }),
  },
  '& .cm-circle.completed-circle': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const StyledStepLabel = styled(StepLabel)<{ cursor: 'pointer' | 'inherit' }>(({ cursor = 'inherit' }) => ({
  cursor,
}));

function UnorderedStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <UnorderedStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <div className="completed-circle cm-circle" /> : <div className="incomplete-circle cm-circle" />}
    </UnorderedStepIconRoot>
  );
}
export const UnorderedStepper = (props: UnorderedStepperProps) => {
  const isMobile = useMediaQuery<Theme>((theme: Theme) => theme.breakpoints.down('sm'));
  const { steps, ...stepperProps } = props as UnorderedStepperProps;
  const activeStep = stepperProps.activeStep || 0;

  return (
    <Box maxWidth="100%">
      <StyledStepper {...stepperProps}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StyledStepLabel
              cursor={step.onClick ? 'pointer' : 'inherit'}
              onClick={step.onClick}
              StepIconComponent={UnorderedStepIcon}
            >
              {isMobile ? '' : step.label}
            </StyledStepLabel>
          </Step>
        ))}
      </StyledStepper>
      {isMobile && <CustomLabel>{steps[activeStep]?.label || `STEP ${activeStep + 1}`}</CustomLabel>}
    </Box>
  );
};
