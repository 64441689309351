import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled, Typography } from '@mui/material';
import { Button, ConfirmationDialog, ConfirmationDialogProps, FlexBox } from '@usgm/shared-ui';
import { ReactNode } from 'react';

export type InfoDialogProps = Omit<ConfirmationDialogProps, 'content' | 'hideButtons'> & {
  actions: Array<{
    label: string;
    onClick: () => void;
    variant?: 'contained' | 'outlined';
  }>;
  message: string;
  icon?: ReactNode;
};

const IconContainer = styled(FlexBox)(({ theme }) => ({
  justifyContent: 'center',
  marginBottom: theme.spacing(4),
  '& svg': {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));

function InfoDialog({
  icon = <InfoOutlinedIcon color="primary" />,
  actions,
  message,
  ...dialogProps
}: InfoDialogProps) {
  return (
    <ConfirmationDialog
      {...dialogProps}
      hideButtons
      content={
        <>
          <IconContainer>{icon}</IconContainer>

          <Typography variant="h5" textAlign="center" mb={4}>
            {message}
          </Typography>
          <FlexBox gap={2} flexDirection="column">
            {actions.map(({ label, onClick, variant }) => (
              <Button key={label} variant={variant || 'outlined'} fullWidth onClick={onClick}>
                {label}
              </Button>
            ))}
          </FlexBox>
        </>
      }
    />
  );
}

export default InfoDialog;
