import { MouseEvent } from 'react';
import { Typography } from '@mui/material';

import { BaseScan, ReportAnIssueType, SCAN_CATEGORY_TYPE_LABELS, ScanCategory } from '@usgm/inbox-api-types';
import { Button, ConfirmationDialog, FlexBox, useDialog } from '@usgm/shared-ui';
import { useAuth } from '../../../../../auth/hooks/useAuth';

import { useCancelScanRequestMutation } from '../../api';
import ReportAnIssueDialog from '../../../../components/ReportAnIssueDialog';
import { useAppDispatch } from '../../../../../../store';
import { apiMessagesSlice } from '../../../../../apiMessages/apiMessagesSlice';
import { useNavigatePreservingParams } from '../../../../hooks/useNavigatePreservingParams';
import { createScansPath } from '../../paths';

export type PendingScansActionsProps = {
  data: BaseScan;
};

function PendingScansActions({ data }: PendingScansActionsProps) {
  const { isAccountUnderPaymentHold } = useAuth();
  const navigate = useNavigatePreservingParams();
  const dispatch = useAppDispatch();
  const reportDialog = useDialog<string>({ open: false, value: '' });
  const confirmCancelScanDialog = useDialog<string>({ open: false, value: '' });
  const [cancelScanMutation] = useCancelScanRequestMutation();

  const requestName = data.categoryType ? SCAN_CATEGORY_TYPE_LABELS[data.categoryType] : '';

  const handleContactSupportClick = (ev: MouseEvent) => {
    ev.stopPropagation();
    reportDialog.openDialog();
  };

  const handleCancelScanClick = (ev: MouseEvent) => {
    ev.stopPropagation();
    confirmCancelScanDialog.openDialog();
  };

  const handleConfirmCancelScan = async () => {
    const response = await cancelScanMutation({ id: data.uuid, mailId: data.mailId });
    const hasError = 'error' in response;
    dispatch(
      apiMessagesSlice.actions.createMessage({
        severity: hasError ? 'error' : 'success',
        text: hasError
          ? 'Failed to cancel scan request. Please try again later.'
          : 'Scan request cancelled successfully',
      }),
    );
    confirmCancelScanDialog.closeDialog();
    navigate(createScansPath({ tab: 'pending' }));
  };

  const hasIssue = !!data.rejectReason;

  return (
    <FlexBox width="100%" gap={2}>
      <Button
        disabled={isAccountUnderPaymentHold}
        onClick={handleCancelScanClick}
        color="error"
        variant="outlined"
        fullWidth
      >
        Cancel {data.categoryType === ScanCategory.ScanRequest ? 'Scan' : 'Open'}
      </Button>
      {hasIssue && (
        <Button disabled={isAccountUnderPaymentHold} onClick={handleContactSupportClick} fullWidth variant="outlined">
          Contact Support
        </Button>
      )}
      <ConfirmationDialog
        open={confirmCancelScanDialog.open}
        confirmText="Confirm"
        cancelText="Cancel"
        onCancel={confirmCancelScanDialog.closeDialog}
        onClose={confirmCancelScanDialog.closeDialog}
        onConfirm={handleConfirmCancelScan}
        content={
          <>
            <Typography mb={1} textAlign="center" variant="h5">
              Please confirm!
            </Typography>
            <Typography textAlign="center">Are you sure you want to cancel this {requestName}?</Typography>
          </>
        }
      />
      {hasIssue && (
        <ReportAnIssueDialog
          reportAnIssueType={ReportAnIssueType.ScanRequest}
          requestID={data.uuid}
          title="Contact Support"
          confirmText="Submit"
          requestedOn={data.createdAt}
          mailItemId={data.mailId}
          open={reportDialog.open}
          onClose={reportDialog.closeDialog}
        />
      )}
    </FlexBox>
  );
}

export default PendingScansActions;
