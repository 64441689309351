import { zodResolver } from '@hookform/resolvers/zod';
import { ADDRESS_SCHEMA_FIELDS } from '@inbox/constants';
import { Autocomplete, Box, styled, textFieldClasses } from '@mui/material';
import { Libraries, useJsApiLoader } from '@react-google-maps/api';
import { FlexBox, Preloader, TextInput } from '@usgm/shared-ui';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';
import { z } from 'zod';
import { ENVIRONMENT } from '../env';
import AutocompleteInput from '../features/addressPicker/components/PlaceSearchAutocomplete/AutocompleteInput';
import AutocompletePlace from '../features/addressPicker/components/PlaceSearchAutocomplete/AutocompletePlace';
import AutocompletePopper from '../features/addressPicker/components/PlaceSearchAutocomplete/AutocompletePopper';

import { inboxHelpers } from '@usgm/utils';
import { countrySelectors } from '../features/inbox/features/countries/countriesSlice';
import { useAppSelector } from '../store';
import { ControlledAutoComplete } from './form/ControlledAutoComplete';

export type AddressFormProps = {
  spacing?: number;
  autocompletePlaceholder?: string;
  autocompleteLabel?: string;
  handleValues?: (values: Partial<AddressFormSchema>) => void;
  showPlaceholders?: boolean;
  defaultValues?: Partial<AddressFormSchema>;
  extended?: boolean;
};

const TwoColsContainer = styled(FlexBox)(({ theme }) => ({
  gap: theme.spacing(2),
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    flexDirection: 'column',
    '& > div': {
      width: '100%',
      [`&.${textFieldClasses.root}`]: {
        width: '100%',
      },
    },
  },
}));

const addressFormSchema = z.object({
  country: ADDRESS_SCHEMA_FIELDS.COUNTRY,
  address_line: ADDRESS_SCHEMA_FIELDS.ADDRESS_LINE,
  address_line_2: ADDRESS_SCHEMA_FIELDS.ADDRESS_LINE_ADDITIONAL,
  address_line_3: ADDRESS_SCHEMA_FIELDS.ADDRESS_LINE_ADDITIONAL,
  city: ADDRESS_SCHEMA_FIELDS.CITY,
  zip: ADDRESS_SCHEMA_FIELDS.ZIP,
  state: ADDRESS_SCHEMA_FIELDS.STATE,
});

export type AddressFormSchema = z.infer<typeof addressFormSchema>;

const libraries: Libraries = ['places'];

function AddressFormFields({
  spacing,
  autocompleteLabel,
  autocompletePlaceholder,
  showPlaceholders,
  handleValues,
  extended,
}: AddressFormProps) {
  const allCountries = useAppSelector(countrySelectors.selectAll);

  const [value, setValue] = useState<google.maps.places.AutocompletePrediction | null>(null);

  const {
    control,
    formState: { errors, defaultValues },

    setValue: setFormValue,
  } = useFormContext<AddressFormSchema>();

  const {
    value: inputValue,
    setValue: setInputValue,
    suggestions: { data: options, status },
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  useEffect(() => {
    setInputValue(defaultValues?.address_line || '', true);
  }, [defaultValues, setInputValue]);

  const handleChange = async (__: SyntheticEvent, newValue: google.maps.places.AutocompletePrediction | null) => {
    if (!newValue) {
      return;
    }
    const placeDetails = await getDetails({ placeId: newValue?.place_id });
    const newAddress: Partial<AddressFormSchema> = {};
    let streetNumber, streetName;

    if (typeof placeDetails === 'object' && 'address_components' in placeDetails) {
      placeDetails?.address_components?.forEach((component) => {
        const types = component.types;
        if (types.includes('country')) {
          newAddress.country = {
            code: component.short_name,
            name: component.long_name,
            id: component.short_name,
          };
        }
        if (
          types.includes('locality') ||
          types.includes('sublocality') ||
          types.includes('administrative_area_level_3')
        ) {
          newAddress.city = component.long_name;
        }
        if (types.includes('administrative_area_level_1')) {
          newAddress.state = component.long_name;
        }
        if (types.includes('postal_code')) {
          newAddress.zip = component.long_name;
        }
        if (extended && types.includes('subpremise')) {
          newAddress.address_line_2 = component.long_name;
        }
        if (types.includes('street_number')) {
          streetNumber = component.long_name;
        }
        if (types.includes('route')) {
          streetName = component.long_name;
        }
      });
      if (streetNumber && streetName) {
        newAddress.address_line = `${streetNumber} ${streetName}`;
      } else {
        newAddress.address_line = newValue.description;
      }
      (Object.keys(newAddress) as Array<keyof AddressFormSchema>).forEach((key) => {
        if (newAddress[key]) {
          setFormValue(key, newAddress[key], { shouldDirty: true, shouldValidate: true });
        }
      });
    }

    handleValues?.(newAddress);
    setFormValue('address_line', newAddress.address_line || '');

    setValue(newValue);
  };

  const clearInput = () => {
    setValue(null);
    setInputValue('');
  };

  return (
    <Box width={'100%'}>
      <Box pb={spacing}>
        <Autocomplete
          id="address-search"
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
          isOptionEqualToValue={(option, value) => option.place_id === value?.place_id}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          freeSolo
          filterSelectedOptions
          value={value}
          inputValue={inputValue}
          noOptionsText={
            !inputValue || status !== 'ZERO_RESULTS'
              ? 'Start typing to search for an address.'
              : 'No matching addresses found. Please refine your search.'
          }
          onChange={handleChange}
          onInputChange={(event, newInputValue) => {
            setFormValue('address_line', newInputValue, { shouldDirty: true, shouldValidate: true });
            handleValues?.({ address_line: newInputValue });
            setInputValue(newInputValue);
          }}
          PopperComponent={AutocompletePopper}
          renderInput={(params) => (
            <AutocompleteInput
              variant="form"
              {...params}
              label={autocompleteLabel}
              onClearInput={clearInput}
              required
              filled={!!value}
              placeholder={autocompletePlaceholder}
            />
          )}
          renderOption={(props, option) => (
            <AutocompletePlace attributes={props} option={option} key={option.place_id} />
          )}
        />
      </Box>
      {extended && (
        <>
          <Controller
            control={control}
            name="address_line_2"
            render={({ field }) => (
              <TextInput
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  handleValues?.({ address_line_2: e.target.value });
                }}
                error={!!errors.address_line_2}
                helperText={errors.address_line_2?.message}
                InputLabelProps={{ shrink: true }}
                label="Address Line 2"
                fullWidth
                placeholder={showPlaceholders ? 'Address Line 2' : ''}
              />
            )}
          />
          <Controller
            control={control}
            name="address_line_3"
            render={({ field }) => (
              <TextInput
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  handleValues?.({ address_line_3: e.target.value });
                }}
                error={!!errors.address_line_3}
                helperText={errors.address_line_3?.message}
                InputLabelProps={{ shrink: true }}
                label="Address Line 3"
                fullWidth
                placeholder={showPlaceholders ? 'Address Line 3' : ''}
              />
            )}
          />
        </>
      )}
      <TwoColsContainer mb={spacing}>
        <ControlledAutoComplete<AddressFormSchema, 'country'>
          defaultValue={defaultValues?.country || null}
          name="country"
          getOptionLabel={(option) => (typeof option === 'string' ? option : option?.name || '')}
          isOptionEqualToValue={(option, value) => option?.code === value?.code}
          options={allCountries}
          label="Country"
          handleChange={(selectedOption) => {
            if (Array.isArray(selectedOption)) {
              handleValues?.({ country: selectedOption[0] });
            } else {
              handleValues?.({ country: selectedOption || undefined });
            }
          }}
          placeholder={showPlaceholders ? 'Enter Country' : ''}
        />
        <Controller
          control={control}
          name="state"
          render={({ field }) => (
            <TextInput
              {...field}
              onChange={(e) => {
                field.onChange(e);
                handleValues?.({ state: e.target.value });
              }}
              error={!!errors.state}
              helperText={errors.state?.message}
              fullWidth
              label="State"
              required
              InputLabelProps={{ shrink: true }}
              placeholder={showPlaceholders ? 'Enter State' : ''}
            />
          )}
        />
      </TwoColsContainer>
      <TwoColsContainer mb={spacing}>
        <Box width="100%">
          <Controller
            control={control}
            name="city"
            render={({ field }) => (
              <TextInput
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  handleValues?.({ city: e.target.value });
                }}
                error={!!errors.city}
                helperText={errors.city?.message}
                fullWidth
                label="City"
                required
                InputLabelProps={{ shrink: true }}
                placeholder={showPlaceholders ? 'Enter City' : ''}
              />
            )}
          />
        </Box>
        <Box minWidth={160}>
          <Controller
            control={control}
            name="zip"
            render={({ field }) => (
              <TextInput
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  handleValues?.({ zip: e.target.value });
                }}
                fullWidth
                error={!!errors.zip}
                helperText={errors.zip?.message}
                InputLabelProps={{ shrink: true }}
                label="Zip Code"
                type="Zip"
                required
                placeholder={showPlaceholders ? 'Enter Zip Code' : ''}
              />
            )}
          />
        </Box>
      </TwoColsContainer>
    </Box>
  );
}

function ControlledAddressForm({
  spacing,
  autocompleteLabel,
  autocompletePlaceholder,
  handleValues,
  defaultValues,
  extended,
}: AddressFormProps) {
  const addressFormMethods = useForm<AddressFormSchema>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: zodResolver(addressFormSchema),
    defaultValues,
  });

  useEffect(() => {
    if (defaultValues) {
      addressFormMethods.reset(defaultValues);
    }
  }, [addressFormMethods, defaultValues]);

  return (
    <FormProvider {...addressFormMethods}>
      <AddressFormFields
        handleValues={handleValues}
        spacing={spacing}
        autocompleteLabel={autocompleteLabel}
        autocompletePlaceholder={autocompletePlaceholder}
        extended={extended}
      />
    </FormProvider>
  );
}

function AddressForm({
  spacing = 1,
  autocompleteLabel = 'Address Line',
  autocompletePlaceholder = '',
  handleValues,
  showPlaceholders = false,
  defaultValues,
  extended,
}: AddressFormProps) {
  const { isLoaded: googleMapApiLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ENVIRONMENT.GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (!googleMapApiLoaded) {
    return <Preloader />;
  }

  if (handleValues) {
    return (
      <ControlledAddressForm
        defaultValues={defaultValues}
        showPlaceholders={showPlaceholders}
        spacing={spacing}
        autocompleteLabel={autocompleteLabel}
        autocompletePlaceholder={autocompletePlaceholder}
        handleValues={handleValues}
        extended={extended}
      />
    );
  }

  return (
    <AddressFormFields
      showPlaceholders={showPlaceholders}
      spacing={spacing}
      autocompleteLabel={autocompleteLabel}
      autocompletePlaceholder={autocompletePlaceholder}
      extended={extended}
    />
  );
}

export default AddressForm;
