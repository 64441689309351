import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { type SetOptional } from 'type-fest';
import { useBoolean } from '../../hooks';

import { useCallback, useMemo } from 'react';
import { MultiLevelMenuProps, MultiLevelMenu } from '../MultiLevelMenu';
import { SelectionFilterButton } from './styled/SelectionFilterButton';

type SelectionFilter = {
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter: (row: any) => boolean;
};

export type SelectionFilterProps = {
  filters: Array<SetOptional<SelectionFilter, 'filter'> & { filters?: Array<SelectionFilter> }>;
  onChange: (info: { filterFn?: SelectionFilter['filter']; indexes: number[] }) => void;
  selectedIndexes?: number[];
};

function SelectionFilter({ filters, onChange, selectedIndexes = [] }: SelectionFilterProps) {
  const { value: open, toggle: toggleFilterOpen } = useBoolean(false);

  const handleFilterChange = useCallback(
    (indexes: number[]) => () => {
      const [firstLevel, secondLevel] = indexes;
      let filterFn = filters[firstLevel].filter;

      if (secondLevel !== undefined) {
        filterFn = filters?.[firstLevel].filters?.[secondLevel].filter;
      }

      onChange({
        indexes,
        filterFn,
      });
      toggleFilterOpen();
    },
    [filters, onChange, toggleFilterOpen],
  );

  const items: MultiLevelMenuProps['items'] = useMemo(() => {
    return filters.map((filter, index) => {
      const { label, filters } = filter;
      const items = filters?.map(({ label }, subIndex) => ({
        content: label,
        selected: selectedIndexes?.[1] === subIndex,
        onClick: handleFilterChange([index, subIndex]),
      }));

      return {
        onClick: handleFilterChange([index]),
        content: label,
        selected: selectedIndexes?.[0] === index,
        items,
      };
    });
  }, [filters, handleFilterChange, selectedIndexes]);

  return (
    <MultiLevelMenu items={items} open={open} onClose={toggleFilterOpen}>
      <SelectionFilterButton onClick={toggleFilterOpen}>
        <KeyboardArrowDownIcon />
      </SelectionFilterButton>
    </MultiLevelMenu>
  );
}

export default SelectionFilter;
