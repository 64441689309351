import { ConfirmationDialog, FlexBox, useDialog } from '@usgm/shared-ui';
import { useGetScanPdfFileByIdQuery } from '../api';
import { type BaseScan, SCAN_CATEGORY_NAMES, ScanCategory } from '@usgm/inbox-api-types';
import { CircularProgress } from '@mui/material';
import PdfViewer, { PdfViewerError } from '../../../components/pdfViewer';
import { useCallback, useEffect } from 'react';

export function useViewScanRequest({ id, categoryType }: { id: BaseScan['uuid']; categoryType: ScanCategory }) {
  const pdfDialog = useDialog<string>({ open: false, value: '' });
  const pdfQuery = useGetScanPdfFileByIdQuery(
    { id: pdfDialog.value },
    { skip: !pdfDialog.value, refetchOnMountOrArgChange: true },
  );

  const openDialog = useCallback(() => {
    pdfDialog.openDialog(id);
  }, [id, pdfDialog]);

  useEffect(() => {
    return () => {
      if (pdfQuery.data) {
        URL.revokeObjectURL(pdfQuery.data);
      }
    };
  }, [pdfQuery.data]);

  return {
    dialog: pdfDialog.value ? (
      <ConfirmationDialog
        large={true}
        open={pdfDialog.open}
        onClose={pdfDialog.closeDialog}
        title={`${SCAN_CATEGORY_NAMES[categoryType]} #${id}`}
        hideButtons
        content={
          pdfQuery.data ? <PdfViewer loading={pdfQuery.isLoading} blobFileUrl={pdfQuery.data} /> : <PdfViewerError />
        }
      />
    ) : null,
    openDialog,
    closeDialog: pdfDialog.closeDialog,
    isOpen: pdfDialog.open,
  };
}
