import { useParams } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { BaseScan } from '@usgm/inbox-api-types';
import { ItemImageWithWarning, Preloader } from '@usgm/shared-ui';

import { useGetMailByIdQuery } from '../../mails/api';
import { useGetScanByIdQuery } from '../api';
import { DEFAULT_SCAN_TAB, ScansPathParams } from '../paths';
import CompletedScansActions from './actions/CompletedScansActions';
import PendingScansActions from './actions/PendingScansActions';
import ScanDetails from './ScanDetails';

import { ContentContainer } from '../../../components/DynamicSidebar/ContentContainer';

import { TopActionsContainer } from '../../../components/DynamicSidebar/TopActionsContainer';

export type ScanInfoProps = {
  id: BaseScan['uuid'];
  mailId: BaseScan['mailId'];
};
function ScanInfo({ id, mailId }: ScanInfoProps) {
  const { data: scanData, isLoading: isScanLoading } = useGetScanByIdQuery({ id });
  const { data: mailData, isLoading: isMailLoading } = useGetMailByIdQuery({ id: mailId });
  const { tab = DEFAULT_SCAN_TAB } = useParams<ScansPathParams>();

  return (
    <Box minHeight="100%">
      {(isScanLoading || isMailLoading) && <Preloader />}
      {scanData && mailData && (
        <>
          <TopActionsContainer>
            {tab === 'pending' ? (
              <PendingScansActions data={scanData} />
            ) : (
              <CompletedScansActions
                categoryType={scanData.categoryType}
                createdAt={scanData.createdAt}
                mailId={scanData.mailId}
                id={id}
              />
            )}
          </TopActionsContainer>
          <ContentContainer>
            <Typography mb={2} variant="h5">
              Item: #{scanData.mailId}
            </Typography>
            <Box mb={2}>
              <ItemImageWithWarning
                tooltipText={`Request rejected: ${scanData.rejectReason}`}
                imageUrl={scanData.mailImageUrl}
                showWarning={!!scanData.rejectReason}
              />
            </Box>

            <ScanDetails mail={mailData} scan={scanData} />
          </ContentContainer>
        </>
      )}
    </Box>
  );
}

export default ScanInfo;
