import { Skeleton, alpha, skeletonClasses, styled } from '@mui/material';
import FlexBox from '../../FlexBox';

import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { useDialog } from '../../../hooks';
import { ImagePopup } from '../../ImagePopup';
import { MouseEvent, ReactNode, useState } from 'react';

const ImageContainer = styled(FlexBox, { shouldForwardProp: (prop) => prop !== 'clickable' && prop !== 'fitObject' })<{
  clickable?: boolean;
  fitObject: boolean;
  height: number;
}>(({ theme, clickable, fitObject }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.info,
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  height: 'auto',
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius * 4,
  width: '100%',
  position: 'relative',
  zIndex: 0,
  aspectRatio: '16/9',
  '& > svg': {
    width: theme.spacing(10),
    height: theme.spacing(10),
    color: theme.palette.common.white,
  },
  img: {
    position: 'absolute',
    zIndex: 1,
    cursor: clickable ? 'pointer' : 'default',
    display: 'block',
    ...(fitObject
      ? {
          objectFit: 'cover',
          width: '100%',
          height: '100%',
        }
      : {
          maxWidth: `calc(100% - ${theme.spacing(4)})`,
          maxHeight: `calc(100% - ${theme.spacing(4)})`,
        }),
  },
  [`.${skeletonClasses.root}`]: {
    backgroundColor: alpha(theme.palette.common.white, 0.1),
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

export type ImageCellProps = {
  imageUrl?: string;
  enablePreview?: boolean;
  fitObject?: boolean;
  height?: number;
  onPreviewClick?: () => void;
  children?: ReactNode;
};
export function ImageCell({
  imageUrl,
  enablePreview,
  fitObject = true,
  height = 168,
  onPreviewClick,
  children,
}: ImageCellProps) {
  const [loaded, setLoaded] = useState<boolean>(!imageUrl);
  const { closeDialog, open, openDialog } = useDialog();

  const handleImageLoad = () => {
    setLoaded(true);
  };

  const handlePreviewClick = (ev: MouseEvent<HTMLImageElement>) => {
    ev.stopPropagation();
    if (enablePreview) {
      onPreviewClick?.();
      openDialog();
    }
  };

  return (
    <>
      <ImageContainer fitObject={fitObject} height={height} clickable={!!imageUrl && !!enablePreview}>
        {!loaded && <Skeleton animation="wave" variant="rectangular" />}
        <img
          style={{ visibility: !loaded ? 'hidden' : 'initial' }}
          onLoad={handleImageLoad}
          onClick={handlePreviewClick}
          src={imageUrl}
          alt="preview"
        />
        {loaded ? children : null}

        <ImageOutlinedIcon fontSize="large" />
      </ImageContainer>
      {enablePreview && imageUrl && <ImagePopup open={open} onClose={closeDialog} imageUrl={imageUrl} />}
    </>
  );
}

export default ImageCell;
