import { Box } from '@mui/material';
import { Container } from '@usgm/shared-ui';
import AddIdsInstruction from '../components/AddIsInstructions';
import { DocumentsCardsList } from '../components/DocumentsCardsList';
import { PageHeading } from '../components/PageHeading';
import { useAccountNamesMap } from '../hooks/useAccountNamesMap';
import { useAuth } from '../../../../auth/hooks/useAuth';
import { useOnboardingData } from '../hooks/useOnboardingData';
import { useEffect } from 'react';

export default function AddIdsPage() {
  const { hasRejectedDocuments, activeSubscription } = useOnboardingData();
  const { accountNamesMap, isLoading } = useAccountNamesMap();
  const { user } = useAuth();
  const isBusiness = activeSubscription?.plan?.options?.isBusiness;
  useEffect(() => {
    if (hasRejectedDocuments) {
      window.zE('webWidget', 'show');
    }
    return () => {
      window.zE('webWidget', 'hide');
    };
  }, [hasRejectedDocuments]);

  if (!accountNamesMap) {
    return null;
  }

  return (
    <Container>
      <Box mb={4} component="section">
        <PageHeading
          title="Add Identifications"
          subtitle="USPS regulations require ID verification to safeguard your mail against fraud, identity theft and errors."
        />
      </Box>

      <Box mb={3} component="section">
        <AddIdsInstruction />
      </Box>
      {user && (
        <DocumentsCardsList
          isBusiness={isBusiness}
          isAccountNamesLoading={isLoading}
          currentUserId={user.userUUID}
          accountNamesMap={accountNamesMap}
        />
      )}
    </Container>
  );
}
