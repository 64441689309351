import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { inboxHelpers } from '@usgm/utils';
import { isBoolean } from 'lodash-es';

type TPreferencesState = {
  prefersDarkMode: boolean;
  language: string;
};

const userPrefersDarkMode = inboxHelpers.getStorageManager().getItem('prefersDarkMode');

const initialState: TPreferencesState = {
  prefersDarkMode: isBoolean(userPrefersDarkMode)
    ? userPrefersDarkMode
    : window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
  language: 'en',
};

export const preferencesSlice = createSlice({
  name: 'preferences',
  initialState: initialState,
  reducers: {
    toggleDarkMode(state) {
      state.prefersDarkMode = !state.prefersDarkMode;
      inboxHelpers.getStorageManager().setItem('prefersDarkMode', state.prefersDarkMode);
    },
    setLanguage(state, action) {
      state.language = action.payload;
    },
  },
});

const selectState = (state: RootState) => state.preferences;

export const selectPrefersDarkMode = (state: RootState) => selectState(state).prefersDarkMode;
