export enum MailType {
  Letter = 'LETTER',
  LargeLetter = 'LARGELETTER',
  Magazine = 'MAGAZINE',
  Catalog = 'CATALOG',
  Softpak = 'SOFTPAK',
  Package = 'PACKAGE',
  Inventory = 'INVENTORY',
  Fulfillment = 'FULFILLMENT',
  Distribution = 'DISTRIBUTION',
}
