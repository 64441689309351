import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { styled } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid';
import { DtoAccountName } from '@usgm/inbox-api-types';
import {
  BoldText,
  Button,
  DataGrid,
  dataTableConfig,
  FlexBox,
  GridCardContainer,
  MobileFooter,
  TextCell,
  useMobileMode,
} from '@usgm/shared-ui';
import { inboxHelpers, pascalCase } from '@usgm/utils';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActiveSubscription } from '../../../../../../../hooks/useActiveSubscription';
import EntityStatus from '../../../../billingHistory/components/EntityStatus';
import { useGetAccountNamesWithStatusQuery } from '../../../../onboarding/api';
import DownloadForm1583 from '../../../../onboarding/components/notarization/DownloadForm1583';
import UpgradePlanMessage from '../../../../onboarding/components/UpgradePlanMessage';
import { AccountNameActions } from '../../../components/AccountNames/AccountNameActions';
import AccountNameInfoCell from '../../../components/AccountNames/tableCells/AccountNameInfoCell';
import { CardContent, CardHeader } from '../../../components/Card';
import { useGetRowHeight } from '../../../hooks/useGetRowHeight';
import { useGetRowSpacing } from '../../../hooks/useGetRowSpacing';
import { SETTINGS_PATHS } from '../../../paths';

const StyledHeaderButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2.5),
}));

const DESKTOP_COLUMNS: GridColDef[] = [
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'name',
    headerName: 'Name',
    renderCell: (params: GridRenderCellParams<DtoAccountName>) => {
      return (
        <TextCell>
          <BoldText>{params.row.user?.name}</BoldText>
        </TextCell>
      );
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'relation',
    headerName: 'Type',
    renderCell: (params: GridRenderCellParams<DtoAccountName>) => {
      return <TextCell>{pascalCase(params.row.relation?.relationType)}</TextCell>;
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'status',
    headerName: 'Status',
    renderCell: (params: GridRenderCellParams<DtoAccountName>) => {
      const statusDetails = inboxHelpers.getAccountStatusDetails(params.row.accountStatus);
      return <EntityStatus statusCategory={statusDetails.category} label={statusDetails.label} />;
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'actions',
    flex: 0,
    renderHeader: () => '',
    renderCell: (params: GridRenderCellParams<DtoAccountName>) => {
      return (
        <AccountNameActions
          accountStatus={params.row.accountStatus}
          uuid={params.row.user.uuid}
          relationType={params.row.relation?.relationType}
        />
      );
    },
  },
];

const MOBILE_COLUMNS: GridColDef[] = [
  {
    field: 'accountNameInfo',
    headerName: 'Info',
    flex: 1,
    renderCell: (params: GridRenderCellParams<DtoAccountName>) => {
      return <AccountNameInfoCell data={params.row} />;
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'actions',
    flex: 0,
    maxWidth: 24,
    renderCell: (params: GridRenderCellParams<DtoAccountName>) => {
      return (
        <AccountNameActions
          accountStatus={params.row.accountStatus}
          uuid={params.row.user.uuid}
          relationType={params.row.relation?.relationType}
        />
      );
    },
  },
];

function AccountNamesPage() {
  const isMobile = useMobileMode();
  const navigate = useNavigate();

  const subscriptionQuery = useActiveSubscription();
  const { isFetching, data, isLoading } = useGetAccountNamesWithStatusQuery(null);

  const navigateToAddNamePage = useCallback(() => {
    navigate(SETTINGS_PATHS.ADD_ACCOUNT_NAME);
  }, [navigate]);

  const handleAddAccountNameClick = useCallback(() => {
    if (!data?.data.accountNames || !subscriptionQuery.activeSubscription?.plan) {
      return;
    }
    navigateToAddNamePage();
  }, [data?.data.accountNames, navigateToAddNamePage, subscriptionQuery.activeSubscription?.plan]);

  const getRowHeight = useGetRowHeight(isMobile);
  const getRowSpacing = useGetRowSpacing();

  const accountNameColumns = useMemo(() => {
    if (isMobile) {
      return MOBILE_COLUMNS;
    }
    return DESKTOP_COLUMNS;
  }, [isMobile]);

  const title = isMobile ? 'Account Names' : 'Manage Account Names';
  const downloadButtonConfig = useMemo(
    () => ({
      label: isMobile ? <FileDownloadOutlinedIcon /> : 'Download Forms',
      width: isMobile ? '100%' : 170,
    }),
    [isMobile],
  );

  const addButtonConfig = useMemo(
    () => ({
      label: isMobile ? 'Add' : 'Add Account Name',
      width: isMobile ? '100%' : 160,
    }),
    [isMobile],
  );

  const disableAddButton = isFetching || isLoading || subscriptionQuery.isLoading;
  const showMobileFooter = isMobile && !isFetching;
  const getRowId = useCallback((row: GridValidRowModel) => row.user.uuid, []);

  const actions = useMemo(
    () => (
      <FlexBox width="100%" justifyContent={isMobile ? 'space-between' : 'flex-end'}>
        <FlexBox textAlign="center" width={downloadButtonConfig.width}>
          <DownloadForm1583 label={downloadButtonConfig.label} showIcon={false} />
        </FlexBox>
        <StyledHeaderButton
          disabled={disableAddButton}
          variant="contained"
          onClick={handleAddAccountNameClick}
          fullWidth={isMobile}
        >
          {addButtonConfig.label}
        </StyledHeaderButton>
      </FlexBox>
    ),
    [
      addButtonConfig.label,
      disableAddButton,
      downloadButtonConfig.label,
      downloadButtonConfig.width,
      handleAddAccountNameClick,
      isMobile,
    ],
  );

  const slots = useMemo(
    () => ({
      footer: showMobileFooter ? () => <MobileFooter>{actions}</MobileFooter> : undefined,
    }),
    [actions, showMobileFooter],
  );

  return (
    <GridCardContainer>
      <CardHeader
        title={title}
        subTitle="Add, edit, or delete personal and company names in the Manage Account Names section. You can also include additional names beyond your plan's allocation, enhancing your mail and delivery organization."
      >
        {!isMobile && actions}
      </CardHeader>
      <CardContent>
        <UpgradePlanMessage
          currentTotal={data?.data.accountNames.length}
          plan={subscriptionQuery.activeSubscription?.plan}
        />
        <DataGrid
          slots={slots}
          disableColumnResize
          autoHeight
          disableColumnMenu
          hideFooter={!showMobileFooter}
          hideColumns={isMobile}
          disableRowSelectionOnClick
          getRowHeight={getRowHeight}
          rows={data?.data?.accountNames || []}
          columns={accountNameColumns}
          loading={isFetching}
          getRowSpacing={getRowSpacing}
          getRowId={getRowId}
        />
      </CardContent>
    </GridCardContainer>
  );
}

export default AccountNamesPage;
