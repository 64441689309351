import { Box, Typography } from '@mui/material';
import { DtoCredit } from '@usgm/inbox-api-types';
import EntityStatus from './EntityStatus';
import { cellHelpers } from '@usgm/shared-ui';
import { useMemo } from 'react';
import { inboxHelpers } from '@usgm/utils';
import { DetailRow } from './styled/DetailRow';
import SummaryRow from './SummaryRow';

export type CreditNoteDetailsProps = {
  data: DtoCredit;
};
function CreditNoteDetails({ data }: CreditNoteDetailsProps) {
  const topDetails = useMemo(() => {
    return [
      {
        title: 'Status',
        value: (
          <Box display="inline-flex">
            <EntityStatus label={data.statusLabel} statusCategory={data.statusCategory} />
          </Box>
        ),
      },
      {
        title: 'Created On',
        value: cellHelpers.formatDateString(data.date),
      },
    ];
  }, [data.date, data.statusCategory, data.statusLabel]);

  const summaries = useMemo(() => {
    return [
      {
        label: 'Total',
        summary: inboxHelpers.formatPrice(data.amountTotal),
        makeBold: true,
      },
      {
        label: 'Allocations',
        summary: inboxHelpers.formatPrice(data.amountAllocated),
      },

      {
        label: 'Amount Available (USD)',
        summary: inboxHelpers.formatPrice(data.amountAvailable),
        makeBold: true,
      },
    ];
  }, [data.amountAllocated, data.amountAvailable, data.amountTotal]);
  return (
    <>
      {topDetails.map(({ title, value }, index) => {
        return (
          <DetailRow key={index}>
            <Typography>{title}</Typography>
            <Typography>{value}</Typography>
          </DetailRow>
        );
      })}

      <DetailRow mt={2}>
        <Typography fontWeight={600}>Items</Typography>
        <Typography fontWeight={600}>Amount(USD)</Typography>
      </DetailRow>
      {data.lineItems?.map(({ quantity, description, amountTotal }) => (
        <DetailRow>
          <Typography maxWidth="70%">{description}</Typography>
          <Typography>
            {quantity} x {inboxHelpers.formatPrice(amountTotal)}
          </Typography>
        </DetailRow>
      ))}
      {summaries.map(({ label, summary, makeBold }, index) => {
        return <SummaryRow makeBold={makeBold} key={index} label={label} summary={summary} />;
      })}
    </>
  );
}

export default CreditNoteDetails;
