import { Box, Typography, styled, useTheme } from '@mui/material';
import { ReactNode } from 'react';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

import { DtoNote } from '@usgm/inbox-api-types';
import { FlexBox, TextButton, cellHelpers } from '@usgm/shared-ui';
import { useAuth } from '../../../../auth/hooks/useAuth';

const ContentBox = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));

const StyledActionsContainer = styled(ContentBox)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const NoteCard = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
}));

const StyledTextButton = styled(TextButton)(() => ({
  minWidth: 0,
}));

export type NoteItemProps = DtoNote & {
  actions?: ReactNode;
  onNoteEdit?: (note: DtoNote) => void;
  onNoteDelete?: (note: DtoNote) => void;
};
function NoteItem({ actions, onNoteDelete, onNoteEdit, ...noteData }: NoteItemProps) {
  const { user: currentUser } = useAuth();
  const { user, note, createdOn } = noteData;
  const theme = useTheme();
  const canModify = currentUser?.userUUID === user.uuid;

  return (
    <NoteCard>
      <ContentBox py={3}>
        <FlexBox>
          <Box>
            By{' '}
            <Typography fontWeight="600" component="span">
              {user.name}
            </Typography>
          </Box>
          {canModify && (
            <FlexBox gap={2}>
              {!!onNoteEdit && (
                <StyledTextButton onClick={() => onNoteEdit(noteData)}>
                  <ModeEditOutlineOutlinedIcon />
                </StyledTextButton>
              )}
              {!!onNoteDelete && (
                <StyledTextButton onClick={() => onNoteDelete(noteData)}>
                  <DeleteOutlineOutlinedIcon />
                </StyledTextButton>
              )}
            </FlexBox>
          )}
        </FlexBox>
        <Typography mt={2} variant="caption" color="textSecondary">
          {note}
        </Typography>
        <FlexBox justifyContent="flex-end">
          <Typography color={theme.customColors.dark[400]}>{cellHelpers.relativeDate({ value: createdOn })}</Typography>
        </FlexBox>
      </ContentBox>
      {!!actions && <StyledActionsContainer py={2}>{actions}</StyledActionsContainer>}
    </NoteCard>
  );
}

export default NoteItem;
