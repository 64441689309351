import { BaseShipment, EntityStatusCategory } from '@usgm/inbox-api-types';
import { EntityCell, EntityCellProps, cellHelpers, FlexBox, ShipmentTrackingLink } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import EntityStatus from '../../billingHistory/components/EntityStatus';
import { CategoryBadge } from './CategoryBadge';
import { SHIPMENT_TYPES_OPTIONS } from './ShipmentsCategoryFilter';

const constructShipmentInfo = (data: BaseShipment, isPending?: boolean): EntityCellProps['infoList'] => {
  return [
    {
      title: 'Shipment ID',
      value: data.uuid,
    },
    {
      title: 'Service',
      value: data.serviceName,
    },
    {
      title: 'Destination',
      value: data.destination ? inboxHelpers.stringifyAddress({ addr: data.destination }) : 'N/A',
    },
    ...(isPending
      ? [
          {
            title: 'Request Date',
            value: cellHelpers.formatDateString(data.createdAt),
          },
        ]
      : [
          {
            title: 'Tracking',
            value: <ShipmentTrackingLink trackingNumber={data.trackingNumber} serviceName={data.serviceName} />,
          },
          {
            title: 'Shipped On',
            value: cellHelpers.formatDateString(data.createdAt),
          },
        ]),
  ];
};

const renderBadges = (data: BaseShipment) => {
  return (
    <FlexBox flexDirection="column" gap={1} alignItems="flex-start">
      <CategoryBadge category={data.categoryType} name={SHIPMENT_TYPES_OPTIONS.get(data.categoryType) || ''} />
      {data.isAutoForward && <EntityStatus label="Auto Forwarding" statusCategory={EntityStatusCategory.Info} />}
    </FlexBox>
  );
};

export type ShipmentInfoCellProps = {
  data: BaseShipment;
  isPending?: boolean;
};
function ShipmentInfoCell({ data, isPending = false }: ShipmentInfoCellProps) {
  return (
    <EntityCell
      hasIssue={data.hasIssue}
      issueTooltip={`${data.categoryName} rejected`}
      infoList={constructShipmentInfo(data, isPending)}
      imageUrl={data.mailImageUrl}
      badgeContent={renderBadges(data)}
    />
  );
}

export default ShipmentInfoCell;
