import { styled } from '@mui/material';
import FlexBox from '../FlexBox';

export const ListItemWarningBadge = styled(FlexBox)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.error.main,
  borderRadius: '50%',
  boxShadow: theme.shadows[1],
  position: 'absolute',
  padding: theme.spacing(1),
  right: theme.spacing(2),
  bottom: theme.spacing(2),
  zIndex: 2,
  justifyContent: 'center',
  border: `2px solid ${theme.palette.error.main}`,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5),
    border: `1px solid ${theme.palette.error.main}`,
    right: theme.spacing(1),
    bottom: theme.spacing(1),
    svg: {
      width: 16,
      height: 16,
    },
  },
}));
