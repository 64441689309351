import AccessTime from '@mui/icons-material/AccessTime';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DateTime, WeekdayNumbers } from 'luxon';

import { Avatar, Table, TableBody, TableCell, TableContainer, TableRow, Typography, styled } from '@mui/material';
import { DtoAddresses } from '@usgm/inbox-api-types';
import { ActiveIndicatorBadge } from '@usgm/shared-ui';
import { dateUtils } from '@usgm/utils';

export type WorkingHoursProps = {
  workingHours: DtoAddresses['warehouseOperationalHours'];
};

const IconContainer = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: theme.spacing(3),
  height: theme.spacing(3),
  marginRight: theme.spacing(2),
  color: theme.palette.common.white,
}));

const ClosedIconContainer = styled(IconContainer)(({ theme }) => ({
  backgroundColor: theme.customColors.dark[400],
}));

export function WorkingHours({ workingHours }: WorkingHoursProps) {
  const daysOfWeek = dateUtils.createWeekDaysMap();
  const today = DateTime.now().weekday;

  workingHours.forEach(({ day, end, start }) => {
    const weekDay = parseInt(day, 10) as WeekdayNumbers;
    const data = daysOfWeek.get(weekDay);
    if (data && start && end) {
      daysOfWeek.set(weekDay, {
        ...data,
        hours: `${start} - ${end}`,
      });
    }
  });

  const formattedData = Array.from(daysOfWeek.values());

  return (
    <TableContainer>
      <Table size="small">
        <TableBody>
          {formattedData.map(({ name, hours, weekday }) => (
            <TableRow key={name}>
              <TableCell>
                <Typography
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                  fontWeight="bold"
                >
                  <ActiveIndicatorBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    variant={today === weekday ? 'dot' : 'standard'}
                  >
                    {hours ? (
                      <IconContainer>
                        <AccessTime />
                      </IconContainer>
                    ) : (
                      <ClosedIconContainer>
                        <HighlightOffIcon />
                      </ClosedIconContainer>
                    )}
                  </ActiveIndicatorBadge>
                  <Typography ml={1} fontWeight={'inherit'}>
                    {name}
                  </Typography>
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography textAlign="right" color="text.primary">
                  {hours ? <span>{hours}</span> : <span>Closed</span>}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
