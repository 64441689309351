import { Typography } from '@mui/material';
import { ConfirmationDialog, useDialog } from '@usgm/shared-ui';
import { useDeleteMailFolderMutation } from '../api';
import { useLocation, useNavigate } from 'react-router-dom';
import { inboxHelpers } from '@usgm/utils';

export function ConfirmFolderDeletion(props: ReturnType<typeof useDialog<number>>) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [deleteMailFolderMutation] = useDeleteMailFolderMutation();
  const handleConfirm = async () => {
    if (props.value) {
      await deleteMailFolderMutation({ id: props.value });
      if (pathname.includes(`folder/${props.value}`)) {
        props.closeDialog();
        navigate(inboxHelpers.MAIN_PATHS.INBOX);
      }
    }
  };
  return props.open ? (
    <ConfirmationDialog
      open={props.open}
      confirmText="Yes"
      cancelText="No"
      onCancel={props.closeDialog}
      onConfirm={handleConfirm}
      onClose={props.closeDialog}
      content={
        <>
          <Typography mb={1} textAlign="center" variant="h5">
            Are you sure you wish to delete this folder?
          </Typography>
          <Typography>Deleting this folder will revert your items back into your MailBox</Typography>
        </>
      }
    />
  ) : null;
}
