import { Box, Typography, useTheme } from '@mui/material';
import { AccountRelation } from '@usgm/inbox-api-types';
import { Button } from '@usgm/shared-ui';
import { pascalCase } from '@usgm/utils';

export type StepNotRequiredMessageProps = {
  relationType: AccountRelation;
  onContinue?: (userId: string) => void;
  userId?: string;
  actionName: string;
};
function StepNotRequiredMessage({ relationType, onContinue, userId, actionName }: StepNotRequiredMessageProps) {
  const theme = useTheme();
  const handleContinue = onContinue && userId ? () => onContinue(userId) : undefined;
  return (
    <Box py={8} textAlign="center">
      <Typography textAlign="center" variant="h5" color={theme.customColors.dark[400]}>
        You don't need to {actionName} for{' '}
        <Typography fontStyle="italic" component="span" variant="h5" color={theme.palette.primary.main}>
          {pascalCase(relationType)}
        </Typography>{' '}
        account name
      </Typography>

      {handleContinue && (
        <Button onClick={handleContinue} sx={{ minWidth: 140, mt: 6 }} variant="contained" color="primary">
          Continue
        </Button>
      )}
    </Box>
  );
}

export default StepNotRequiredMessage;
