import { Box, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import { Fragment, MouseEvent, ReactElement, ReactNode } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { useBoolean } from '../hooks';
import FlexBox from './FlexBox';

type TItem = {
  content: ReactNode;
  items?: TItem[];
  subContent?: ReactNode;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  onClick?: (item: TItem) => void;
  selected?: boolean;
};

type SubLevelDirection = 'right' | 'left';
type ItemVariant = 'button' | 'standard';

type StyledItemProps = {
  selected?: boolean;
  variant?: ItemVariant;
};

const StyledItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'variant',
})<StyledItemProps>(({ theme, selected, variant }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(variant === 'button' ? 2 : 1.25, 2),
  backgroundColor: selected ? theme.palette.action.selected : undefined,
  fontWeight: variant === 'button' ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
  fontSize: variant === 'button' ? '1rem' : '0.875rem',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const StyledSubMenuTooltip = styled(
  ({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />,
  {
    shouldForwardProp: (prop) => prop !== 'maxWidth' && prop !== 'backgroundColor',
  },
)<TooltipProps>(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.paper,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2, 0),
    border: `1px solid ${theme.palette.divider}`,
    fontSize: '0.875rem',
    lineHeight: 1.4,
    width: 254,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
}));

function NestedMenuItem({
  item,
  subLevelDirection = 'right',
  variant = 'standard',
}: {
  item: TItem;
  subLevelDirection?: SubLevelDirection;
  variant?: ItemVariant;
}) {
  const { content, subContent, iconLeft, iconRight, items, onClick, selected } = item;

  const { value: open, toggle: toggleTooltip } = useBoolean(false);

  const hasSubItems = (items && items.length > 0) || !!subContent;

  const handleMenuItemClick = (ev: MouseEvent<HTMLElement>) => {
    if ((items && items.length > 0) || subContent) {
      toggleTooltip();
    } else {
      onClick?.(item);
    }
  };

  const arrowIcon = subLevelDirection === 'right' ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />;

  return (
    <StyledSubMenuTooltip
      arrow={false}
      onClose={toggleTooltip}
      open={open}
      PopperProps={{
        disablePortal: true,
      }}
      placement={`${subLevelDirection}-start`}
      title={
        items && items.length > 0
          ? items.map((subItem, index) => (
              <NestedMenuItem variant={variant} subLevelDirection={subLevelDirection} key={index} item={subItem} />
            ))
          : subContent
      }
    >
      <Box>
        <StyledItem
          variant={variant}
          justifyContent="space-between"
          flexDirection={subLevelDirection === 'left' ? 'row-reverse' : 'row'}
          selected={selected}
          onClick={handleMenuItemClick}
        >
          <Box display="flex" alignItems="center">
            {iconLeft}
            {content}
            {iconRight}
          </Box>
          {hasSubItems && (
            <FlexBox
              alignSelf="flex-end"
              ml={subLevelDirection === 'right' ? 2 : 0}
              mr={subLevelDirection === 'left' ? 2 : 0}
            >
              {arrowIcon}
            </FlexBox>
          )}
        </StyledItem>
      </Box>
    </StyledSubMenuTooltip>
  );
}

export type MultiLevelMenuProps = {
  items: TItem[];
  onClose?: () => void;
  open?: boolean;
  children: ReactElement;
  subLevelDirection?: SubLevelDirection;
  variant?: ItemVariant;
};
export function MultiLevelMenu({
  items,
  onClose,
  subLevelDirection = 'right',
  open = false,
  children,
  variant = 'standard',
}: MultiLevelMenuProps) {
  return (
    <StyledSubMenuTooltip
      title={
        <Fragment>
          {items.map((item, index) => (
            <NestedMenuItem variant={variant} subLevelDirection={subLevelDirection} key={index} item={item} />
          ))}
        </Fragment>
      }
      arrow={false}
      onClose={onClose}
      open={open}
    >
      {children}
    </StyledSubMenuTooltip>
  );
}
