import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';

import { CSSObject, SwipeableDrawer, Theme, styled, useMediaQuery } from '@mui/material';
import { createScrollingStyles } from '@usgm/shared-ui';
import { DRAWER_WIDTH } from '../../../constants';

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const StyledDrawer = styled(SwipeableDrawer, { shouldForwardProp: (prop) => prop !== 'collapsed' })<{
  collapsed: boolean;
}>(({ theme, collapsed = false }) => ({
  width: collapsed ? 60 : DRAWER_WIDTH,
  flexShrink: 0,
  ...(!collapsed && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      ...createScrollingStyles({ theme, thumbSize: 6 }),
      boxSizing: 'border-box',
      overflowY: 'scroll',
    },
  }),
  ...(collapsed && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
    },
  }),
}));

const DrawerContent = styled(Box, { shouldForwardProp: (prop) => prop !== 'isCollapsible' && prop !== 'collapsed' })<{
  collapsed: boolean;
  isCollapsible: boolean;
}>(({ theme, isCollapsible }) => ({
  ...createScrollingStyles({ theme }),
  padding: theme.spacing(isCollapsible ? 2 : 4, isCollapsible ? 1 : 2),
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
}));

export type SidebarProps = {
  collapsed?: boolean;
  isCollapsible?: boolean;
  open: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  children?: React.ReactNode;
  anchor?: 'left' | 'right';
};

export function Sidebar({
  handleClose,
  open,
  handleOpen,
  children,
  anchor = 'left',
  isCollapsible = false,
  collapsed = false,
}: SidebarProps) {
  const isDrawerHidden = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));

  return (
    <StyledDrawer
      collapsed={collapsed}
      variant={isDrawerHidden ? 'temporary' : 'permanent'}
      ModalProps={{
        keepMounted: isDrawerHidden,
      }}
      anchor={anchor}
      onOpen={handleOpen}
      open={isDrawerHidden ? open : true}
      onClose={handleClose}
    >
      <Toolbar />

      <DrawerContent isCollapsible={isCollapsible} collapsed={collapsed}>
        {children}
      </DrawerContent>
    </StyledDrawer>
  );
}

export default React.memo(Sidebar);
