import { IconButton, IconButtonProps, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const CloseButton = styled((props: IconButtonProps) => (
  <IconButton {...props}>{props.children || <CloseIcon />}</IconButton>
))(({ theme }) => ({
  color: theme.palette.text.secondary,
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
}));
