import { Address } from '@usgm/inbox-api-types';

export const stringifyAddress = ({ addr, boxNumber }: { addr: Partial<Address>; boxNumber?: number }) => {
  if (!addr) return '';
  const parts = [];
  if (addr.addressLine) {
    const addressLine = `${addr.addressLine}${boxNumber ? ` PMB ${boxNumber}` : ''}`;
    parts.push(addressLine);
  }
  [addr.city, addr.state, addr.postalCode].forEach((part) => {
    if (part) {
      parts.push(part);
    }
  });
  return parts.join(', ');
};
