import { DeepSnakeCaseKeys } from '@usgm/inbox-api-types';
import { isObject, snakeCase } from 'lodash-es';

export function snakeCaseKeys<T>(obj: T): DeepSnakeCaseKeys<T> {
  if (Array.isArray(obj)) {
    return obj.map((v) => snakeCaseKeys(v)) as unknown as DeepSnakeCaseKeys<T>;
  } else if (isObject(obj)) {
    const newObj = obj as Record<string, unknown>;
    return Object.keys(obj).reduce((acc: Record<string, unknown>, key: string) => {
      const snakeKey = !key.includes('_') && !key.includes(' ') ? snakeCase(key) : key;
      acc[snakeKey] = snakeCaseKeys(newObj[key]);
      return acc;
    }, {} as Record<string, unknown>) as DeepSnakeCaseKeys<T>;
  }
  return obj as DeepSnakeCaseKeys<T>;
}
