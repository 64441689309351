import { useCallback, useMemo } from 'react';

import { ConfirmPurchaseDialog, useDialog } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';

import { useActiveSubscription } from '../../../../../../../hooks/useActiveSubscription';

export function useAccountPurchaseConfirmation<TAccount>({
  accountNamesCount,
  onConfirmPurchase,
}: {
  accountNamesCount: number;
  onConfirmPurchase: (accountNames: TAccount[]) => void;
}) {
  const confirmPurchaseDialog = useDialog<TAccount[]>();

  const { activeSubscription } = useActiveSubscription();

  if (!activeSubscription) {
    throw new Error('Active subscription not found');
  }

  const diff = confirmPurchaseDialog.value?.length
    ? confirmPurchaseDialog.value?.length -
      Math.max(accountNamesCount, activeSubscription.plan.accountNamesPlan.maxNames)
    : 0;
  const subtotal = diff * inboxHelpers.COST_OF_EXTRA_ACCOUNT_NAME_PER_MONTH;

  const total = subtotal;

  const handleConfirmPurchase = useCallback(() => {
    if (confirmPurchaseDialog.value) {
      onConfirmPurchase?.(confirmPurchaseDialog.value);
    }
  }, [confirmPurchaseDialog.value, onConfirmPurchase]);

  const dialogComponent = useMemo(() => {
    return (
      <ConfirmPurchaseDialog
        onClose={confirmPurchaseDialog.closeDialog}
        open={confirmPurchaseDialog.open}
        onCancel={confirmPurchaseDialog.closeDialog}
        onConfirm={handleConfirmPurchase}
        subtotal={subtotal}
        total={total}
        description={`By clicking the Confirm button below, you're buying  ${diff}  additional name(s) for your account.`}
      />
    );
  }, [confirmPurchaseDialog.closeDialog, confirmPurchaseDialog.open, diff, handleConfirmPurchase, subtotal, total]);

  const checkIsPurchaseRequired = useCallback(
    (newAccountNames: TAccount[]) => {
      const purchaseRequired =
        newAccountNames.length > Math.max(accountNamesCount, activeSubscription.plan.accountNamesPlan.maxNames);
      if (purchaseRequired) {
        confirmPurchaseDialog.openDialog(newAccountNames);
      }
      return purchaseRequired;
    },
    [accountNamesCount, activeSubscription.plan.accountNamesPlan.maxNames, confirmPurchaseDialog],
  );

  return {
    dialogComponent,
    checkIsPurchaseRequired,
  };
}
