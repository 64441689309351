import { AccountRelation } from '@usgm/inbox-api-types';

export function createEmptyAccountName<T>(isBusiness = false): T {
  const defaultRowData = {
    name: '',
    relationType: isBusiness ? AccountRelation.Company : AccountRelation.Adult,
    uuid: '',
  };
  const rowData = isBusiness
    ? {
        ...defaultRowData,
        city: '',
        state: '',
        country: null,
        zipCode: '',
        streetAddress: '',
        placeOfRegistration: '',
        typeOfBusiness: '',
      }
    : defaultRowData;
  return { ...rowData } as unknown as T;
}
