import { useDialog } from '@usgm/shared-ui';
import { ConfirmFolderDeletion } from './dialogs/ConfirmFolderDeletion';
import { CreateFolderDialog } from './dialogs/CreateFolderDialog';
import { EditFolderDialog } from './dialogs/EditFolderDialog';

export function useFolderManagement() {
  const deletionDialog = useDialog<number>();
  const editDialog = useDialog<number>();
  const createDialog = useDialog<void>();

  return {
    deleteFolder: deletionDialog.openDialog,
    editFolder: editDialog.openDialog,
    createFolder: createDialog.openDialog,
    dialogs: (
      <>
        <ConfirmFolderDeletion {...deletionDialog} />
        <EditFolderDialog {...editDialog} />
        <CreateFolderDialog {...createDialog} />
      </>
    ),
  };
}
