import { Button, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { StyledDialog } from './StyledDialog';
import { CloseButton } from '../buttons';
import { StyledDialogTitle } from './StyledDialogTitle';

import React, { FC } from 'react';
import { StyledDialogContent } from './StyledDialogContent';
import { StyledDialogActions } from './StyledDialogActions';

export type CouponDialogProps = {
  open: boolean;
  handleClose: () => void;
  primaryButtonText?: string;
  title: string;
  children: React.ReactNode;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  isLoading?: boolean;
  submitDisabled?: boolean;
};

export const FormDialog: FC<CouponDialogProps> = ({
  open,
  handleClose,
  onSubmit,
  children,
  title,
  primaryButtonText = 'Save',
  isLoading = false,
  submitDisabled = false,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onSubmit(event);
  };
  return (
    <StyledDialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit,
      }}
    >
      <StyledDialogTitle>{title}</StyledDialogTitle>
      <CloseButton aria-label="Close" onClick={handleClose} />

      <StyledDialogContent>{children}</StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={submitDisabled} variant="contained" type="submit">
          {isLoading ? <CircularProgress size={16} /> : primaryButtonText}
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default React.memo(FormDialog);
