import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { BaseShipment } from '@usgm/inbox-api-types';
import ShipmentInfoCell from '../components/ShipmentInfoCell';

export const getMobileColumns = (isPending?: boolean): GridColDef[] => {
  return [
    {
      field: 'shipmentInfo',
      headerName: 'Info',
      flex: 1,
      renderCell: (params: GridRenderCellParams<BaseShipment>) => {
        return <ShipmentInfoCell isPending={isPending} data={params.row} />;
      },
    },
  ];
};
