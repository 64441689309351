import { FlexBox, Toast } from '@usgm/shared-ui';
import { onboardingUtils } from '@usgm/utils';
import { uniq } from 'lodash-es';
import ReCaptchaV2 from '../../../../../captcha/ReCaptchaV2';
import { useRecaptchaRequest } from '../../../../../hooks/useRecaptchaRequest';
import { DocumentsCard, DocumentsCardProps } from './DocumentsCard';

import { CaptchaType } from '@inbox/constants';
import { CircularProgress } from '@mui/material';
import { AccountRelation, DtoUserDocuments, UserInfo, UserType } from '@usgm/inbox-api-types';
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../store';
import { selectIsDocumentsUploaded } from '../../../../auth/authSlice';
import { onboardingApi } from '../api';
import { hasMissingDocuments } from '../helpers';
import { AccountNamesMap } from '../hooks/useAccountNamesMap';
import { ONBOARDING_PATHS } from '../paths';

export type DocumentsCardsListProps = {
  accountNamesMap: AccountNamesMap;
  currentUserId: UserInfo['userUUID'];
  isAccountNamesLoading: boolean;
  isBusiness?: boolean;
};

export function DocumentsCardsList({
  accountNamesMap,
  currentUserId,
  isAccountNamesLoading,
  isBusiness = false,
}: DocumentsCardsListProps) {
  const isDocumentsUploaded = useAppSelector(selectIsDocumentsUploaded);
  const [triggerRefetchOnboardingState] = onboardingApi.endpoints.getOnboardingState.useLazyQuery();

  const navigate = useNavigate();
  const [trigger] = onboardingApi.endpoints.getUserDocuments.useLazyQuery();

  const { isLoading, error, makeRequest, requestId, data, token, version, isReadyToUse } =
    useRecaptchaRequest<DtoUserDocuments>({
      action: CaptchaType.IdsDownload,
      apiRequest: trigger,
    });

  useEffect(() => {
    if (isReadyToUse) {
      makeRequest({ includeFileUrl: true });
    }
  }, [isReadyToUse, makeRequest]);

  const accountsNameMapWithDocuments = useMemo(() => {
    const refreshMap = new Map(accountNamesMap);

    Array.from(refreshMap.entries()).forEach(([userId, accountData]) => {
      accountData.documents = new Map([
        [UserType.Primary, null],
        [UserType.Secondary, null],
      ]);
      const accountDocuments = data?.documents.find((doc) => doc.userId === userId);
      if (accountDocuments) {
        accountDocuments.documents.forEach((document) => {
          accountData.documents.set(document.userType, document);
        });
      }
    });

    return refreshMap;
  }, [accountNamesMap, data]);

  const [accountsMissingIds, setAccountsMissingIds] = useState<string[]>([]);

  const scrollToNextAccount = useCallback(() => {
    const nextAccount = accountsMissingIds?.length ? accountsMissingIds[0] : null;

    if (nextAccount) {
      const target = document.getElementById(`card-${nextAccount}`);
      if (target) {
        target.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    return nextAccount;
  }, [accountsMissingIds]);

  useEffect(() => {
    setAccountsMissingIds(() => {
      return Array.from(accountsNameMapWithDocuments.entries())
        .filter(([userId]) => {
          return hasMissingDocuments(userId, accountsNameMapWithDocuments);
        })
        .map(([userId]) => userId);
    });
  }, [accountsNameMapWithDocuments]);

  useLayoutEffect(() => {
    if (!data) return;
    scrollToNextAccount();
  }, [scrollToNextAccount, data]);

  if (isLoading || isAccountNamesLoading) {
    return (
      <FlexBox minHeight={400} justifyContent="center" flexDirection="column" gap={3}>
        <ReCaptchaV2 requestId={requestId} />
        <CircularProgress />
      </FlexBox>
    );
  }

  const handleContinue: DocumentsCardProps['onContinue'] = async () => {
    const nextAccount = scrollToNextAccount();

    if (!nextAccount) {
      if (!isDocumentsUploaded) {
        await triggerRefetchOnboardingState(null);
      }
      navigate(ONBOARDING_PATHS.GET_NOTARIZED);
    }
  };

  const handleDocumentSubmit = (userId: string) => {
    setAccountsMissingIds((prev) => prev.filter((it) => it !== userId));
  };

  const handleDocumentDelete = (userId: string) => {
    setAccountsMissingIds((prev) => uniq([...prev, userId]));
  };

  if (error) {
    return <Toast severity="error" description={error.message} />;
  }

  return (
    <>
      <ReCaptchaV2 requestId={requestId} />
      {data ? (
        <FlexBox width="100%" flexDirection="column" gap={3}>
          {Array.from(accountsNameMapWithDocuments.entries())
            .filter(([userId]) => {
              const accountData = accountsNameMapWithDocuments.get(userId);
              if (accountData?.relation === AccountRelation.Primary && userId !== currentUserId) {
                return false;
              }
              return accountData && !onboardingUtils.DOCUMENTS_CAN_BE_SKIPPED_RELATIONS.includes(accountData.relation);
            })
            .map(([userId]) => {
              return (
                <DocumentsCard
                  isBusiness={isBusiness}
                  captchaVersion={version}
                  onContinue={handleContinue}
                  token={token}
                  scrollIndex={userId}
                  userId={userId}
                  accountNamesMap={accountsNameMapWithDocuments}
                  key={userId}
                  accountsMissingIds={accountsMissingIds}
                  onDocumentSubmit={handleDocumentSubmit}
                  onDocumentDelete={handleDocumentDelete}
                />
              );
            })}
        </FlexBox>
      ) : null}
    </>
  );
}
