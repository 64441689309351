import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { DtoDocument } from '@usgm/inbox-api-types';
import { RootState } from '../../../../../../../../store';
import { onboardingApi } from '../../../../../onboarding/api';

const FEATURE_NAME = 'ACCOUNT_NAMES';

const accountNamesDocumentsAdapter = createEntityAdapter<{ documents: DtoDocument[]; id: string; userId: string }>();

const initialState = accountNamesDocumentsAdapter.getInitialState({
  isLoading: false,
});

export const accountsNameSlice = createSlice({
  name: FEATURE_NAME,
  initialState: initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isAnyOf(onboardingApi.endpoints.getUserDocuments.matchPending), (state, action) => {
        state.isLoading = true;
      })
      .addMatcher(isAnyOf(onboardingApi.endpoints.getUserDocuments.matchRejected), (state, action) => {
        state.isLoading = false;
      })
      .addMatcher(isAnyOf(onboardingApi.endpoints.getUserDocuments.matchFulfilled), (state, action) => {
        state.isLoading = false;
        accountNamesDocumentsAdapter.setAll(
          state,
          action.payload.documents.map(({ documents, userId }) => ({ documents, id: userId, userId })),
        );
      });
  },
});

const selectState = (state: RootState) => state[FEATURE_NAME];

export const accountsNamesDocumentsSelectors = accountNamesDocumentsAdapter.getSelectors(selectState);

export const selectIsLoadingDocuments = (state: RootState) => selectState(state).isLoading;
