import { Box } from '@mui/material';
import { Button } from '@usgm/shared-ui';
import { useNavigate } from 'react-router-dom';
import { ContentContainer } from '../../../../components/DynamicSidebar/ContentContainer';
import SuccessBox from '../../../../components/SuccessBox';

import { ScanCategory } from '@usgm/inbox-api-types';
import { useAppDispatch } from '../../../../../../store';
import { createScansPath } from '../../../scans/paths';
import { mailsSlice } from '../../mailsSlice';
import { MailActionRequest } from '../../paths';

export type RequestSuccessMessageProps = {
  actionRequest: MailActionRequest;
};
function RequestSuccessMessage({ actionRequest }: RequestSuccessMessageProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleViewRequestsClick = () => {
    dispatch(mailsSlice.actions.clearSelectedMails());
    navigate(
      createScansPath({
        tab: 'pending',
        params: {
          category: actionRequest === MailActionRequest.Open ? ScanCategory.UnboxingRequest : ScanCategory.ScanRequest,
        },
      }),
    );
  };

  const actionLabel = actionRequest === MailActionRequest.Open ? 'open request' : 'scan';

  return (
    <ContentContainer>
      <SuccessBox
        heading="Your request has been submitted"
        subHeading={`Your ${actionLabel} will be available in 2-4 hours`}
      />
      <Box mt={4}>
        <Button fullWidth variant="contained" color="primary" onClick={handleViewRequestsClick}>
          View Request(s)
        </Button>
      </Box>
    </ContentContainer>
  );
}

export default RequestSuccessMessage;
