import { MouseEvent, useCallback } from 'react';

import { BaseScan } from '@usgm/inbox-api-types';

import { scansApi } from '../api';

export function useScanDownload({
  id,
  isDisabled = false,
  fileName,
}: {
  id?: BaseScan['uuid'];
  isDisabled?: boolean;
  fileName?: string;
}) {
  const [triggerDownload, downloadQuery] = scansApi.endpoints.downloadScanFileById.useLazyQuery();

  const handleDownloadClick = useCallback(
    (ev: MouseEvent) => {
      if (isDisabled || !id) {
        return;
      }
      ev.stopPropagation();
      triggerDownload({ id });
    },
    [id, isDisabled, triggerDownload],
  );

  return {
    handleDownloadClick,
    isLoading: downloadQuery.isLoading || downloadQuery.isFetching,
  };
}
