import { Box, Stack, Typography, alpha, styled, useTheme } from '@mui/material';
import { ReactNode } from 'react';

const IconContainer = styled(Box)(({ theme }) => ({
  borderRadius: '100%',
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  padding: theme.spacing(5),
  svg: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
    svg: {
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
  },
}));

const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'justifyContent' && prop !== 'minHeight',
})<{
  justifyContent?: string;
  minHeight?: string;
}>(({ justifyContent = 'center', minHeight = '100%' }) => ({
  alignItems: 'center',
  justifyContent,
  minHeight,
}));

export type NoRowPlaceholderProps = {
  text?: string;
  icon?: ReactNode;
  justifyContent?: string;
  minHeight?: string;
};
function NoRowPlaceholder({ text, icon, justifyContent, minHeight }: NoRowPlaceholderProps) {
  const theme = useTheme();
  return (
    <StyledStack className="no-row-placeholder" justifyContent={justifyContent} minHeight={minHeight}>
      {icon ? <IconContainer mb={3}>{icon}</IconContainer> : null}
      <Typography px={6} textAlign="center" color={theme.customColors.dark[400]} variant="h5">
        {text ? text : 'No results found'}
      </Typography>
    </StyledStack>
  );
}

export default NoRowPlaceholder;
