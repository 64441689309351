import { AccountRelation, AccountStatus, EntityStatusCategory } from '@usgm/inbox-api-types';
import { getKeys } from '../getKeys';
import { pascalCase } from '../pascalCase';

export const ACCOUNT_NAME_ACTIONS = {
  editName: {
    label: 'Edit Name',
    color: undefined,
  },
  editDocuments: {
    label: 'Edit IDs',
    color: undefined,
  },
  delete: {
    label: 'Delete',
    color: 'dangerous',
  },
} as const;

export type TAccountNameAction = keyof typeof ACCOUNT_NAME_ACTIONS;

export function isDocumentUploadNeededForRelation(relationType: AccountRelation) {
  return [AccountRelation.Primary, AccountRelation.Adult].includes(relationType);
}

export function canModifyAccount(accountStatus: AccountStatus, relationType: AccountRelation) {
  if (relationType === AccountRelation.MinorChild) {
    return ![AccountStatus.DocumentPrimaryApproved, AccountStatus.Approved].includes(accountStatus);
  }
  return ![
    AccountStatus.Form1583Approved,
    AccountStatus.NotarizedDocumentAdded,
    AccountStatus.NotarizedDocumentApproved,
    AccountStatus.Approved,
  ].includes(accountStatus);
}

export function isAccountNameActionAllowed(relationType: AccountRelation, action: TAccountNameAction) {
  if (relationType === AccountRelation.Primary && action === 'delete') {
    return false;
  }
  if (!isDocumentUploadNeededForRelation(relationType) && action === 'editDocuments') {
    return false;
  }
  return true;
}

export const STATUS_CATEGORY_TO_ACCOUNT_STATUS_MAP: { [Key in EntityStatusCategory]: AccountStatus[] } = {
  [EntityStatusCategory.Success]: [AccountStatus.Approved],
  [EntityStatusCategory.Error]: [
    AccountStatus.Closed,
    AccountStatus.DocumentPrimaryRejected,
    AccountStatus.Form1583Rejected,
    AccountStatus.DocumentSecondaryRejected,
    AccountStatus.NotarizedDocumentRejected,
  ],
  [EntityStatusCategory.Neutral]: [AccountStatus.Suspended],
  [EntityStatusCategory.Info]: [],
} as const;

const ACCOUNT_STATUSES_TO_SHOW_AS_IS = [
  AccountStatus.Closed,
  AccountStatus.New,
  AccountStatus.Suspended,
  AccountStatus.Approved,
  AccountStatus.NotarizedDocumentRejected,
  AccountStatus.DocumentPrimaryRejected,
  AccountStatus.DocumentPrimaryRejected,
  AccountStatus.Form1583Rejected,
];

export function getAccountStatusDetails(accountStatus: AccountStatus): {
  category: EntityStatusCategory;
  label: string;
} {
  const category = getKeys<typeof STATUS_CATEGORY_TO_ACCOUNT_STATUS_MAP>(STATUS_CATEGORY_TO_ACCOUNT_STATUS_MAP).find(
    (key) => STATUS_CATEGORY_TO_ACCOUNT_STATUS_MAP[key].includes(accountStatus),
  );
  const label = ACCOUNT_STATUSES_TO_SHOW_AS_IS.includes(accountStatus)
    ? pascalCase(accountStatus)
    : 'Pending Verification';
  return { category: category || EntityStatusCategory.Info, label };
}
