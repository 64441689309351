import { List, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { FlexBox } from '../FlexBox';
import IconListItem from '../IconListItem';

import StyledArrowBadge from './styled/Badge';
import StyledCard from './styled/Card';
import StyledActions from './styled/CardActions';
import StyledCardContent from './styled/CardContent';
import StyledHeader from './styled/CardHeader';
import { StyledPrice } from './styled/Price';
import { LabelBadge } from '../LabelBadge';
import { useIsTouchDevice, useMobileMode } from '../../hooks';

export type PlanCardProps = {
  title: string;
  subTitle: string;
  price: string;
  period?: string;
  color?: string;
  features?: string[];
  featuresIcon?: ReactNode;
  image: ReactNode;
  badge?: string;
  actions?: React.ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
  changeWarehouseMode?: boolean;
  savings?: string;
};

export function PlanCard({
  title,
  badge,
  subTitle,
  image,
  actions,
  features,
  featuresIcon,
  price,
  color,
  onClick,
  onMouseEnter,
  onMouseLeave,
  period = 'month',
  changeWarehouseMode = false,
  savings,
}: PlanCardProps) {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const foregroundColor = isHovered ? theme.palette.background.cardHover : theme.palette.background.card;

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMobileMode();
  const isTouchDevice = useIsTouchDevice();

  const handleMouseEnter = () => {
    onMouseEnter?.();
    setIsHovered(true);
  };

  const showBadge = !!savings && (isTouchDevice || isMobile);

  const handleMouseLeave = () => {
    onMouseLeave?.();
    setIsHovered(false);
  };
  return (
    <StyledCard onClick={onClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <StyledHeader sx={{ padding: changeWarehouseMode ? 3 : 'auto' }}>
        {showBadge ? (
          <FlexBox mb={1} justifyContent="flex-end" paddingX={isDesktop ? (changeWarehouseMode ? 0 : 4) : 2.5}>
            <LabelBadge
              borderRadius="8px"
              backgroundColor={theme.palette.mode === 'light' ? theme.palette.yellow.main : theme.palette.yellow.dark}
              label={savings}
              color={theme.palette.yellow.contrastText}
            />
          </FlexBox>
        ) : null}
        <FlexBox position="relative">
          <Typography
            paddingX={isDesktop ? (changeWarehouseMode ? 0 : 4) : 2.5}
            color={color}
            variant={changeWarehouseMode ? 'h5' : 'h2'}
            fontSize={changeWarehouseMode ? 24 : 'auto'}
          >
            {title}
          </Typography>
          {badge && <StyledArrowBadge foregroundColor={foregroundColor}>{badge}</StyledArrowBadge>}
        </FlexBox>
        <FlexBox
          paddingX={isDesktop ? (changeWarehouseMode ? 0 : 4) : 2.5}
          justifyContent="stretch"
          sx={{ mt: changeWarehouseMode ? 1 : 0 }}
        >
          <div className="plan-info">
            <Typography
              mb={1}
              color="text.secondary"
              fontWeight="bold"
              fontSize={changeWarehouseMode ? 12 : 'auto'}
              sx={{ mr: changeWarehouseMode ? 1 : 0 }}
            >
              {subTitle}
            </Typography>
            <StyledPrice>
              <span className="price">{price}</span>
              <span className="period">/{period}</span>
            </StyledPrice>
          </div>
          <div className="pan-icon-container">{image}</div>
        </FlexBox>
      </StyledHeader>
      {!changeWarehouseMode && features?.length ? (
        <StyledCardContent>
          <List>
            {features?.map((feature, index) => (
              <IconListItem iconColor={color} key={index} icon={featuresIcon}>
                <Typography color="text.secondary" component="span">
                  {feature}
                </Typography>
              </IconListItem>
            ))}
          </List>
        </StyledCardContent>
      ) : null}

      {actions && <StyledActions sx={{ padding: changeWarehouseMode ? 3 : 'auto' }}>{actions}</StyledActions>}
    </StyledCard>
  );
}

export default PlanCard;
