import { Box, Typography, styled } from '@mui/material';
import React, { useCallback, useId, useMemo, useRef } from 'react';

import { ConfirmationDialog, MoreMenu, useDialog } from '@usgm/shared-ui';
import { getKeys, inboxHelpers } from '@usgm/utils';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../../../store';
import { apiMessagesSlice } from '../../../../../apiMessages/apiMessagesSlice';
import { useAuth } from '../../../../../auth/hooks/useAuth';

import { useDeleteAddressMutation } from '../../../../inboxAccountsApi';
import { SETTINGS_PATHS } from '../../paths';

const StyledMoreButtonCell = styled(Box)(({ theme }) => ({
  textAlign: 'right',
  width: '100%',
  marginRight: theme.spacing(8),
}));

export type AddressActionsProps = {
  addressUuid: string;
};

function AddressActions({ addressUuid }: AddressActionsProps) {
  const moreButton = useRef<HTMLHtmlElement>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();

  const popperId = useId();

  // Dialogs
  const deleteDialog = useDialog();

  const [deleteAddress, deleteAddressQuery] = useDeleteAddressMutation();

  const handleUpdateAddress = useCallback(() => {
    navigate(SETTINGS_PATHS.EDIT_ADDRESS(addressUuid));
  }, [navigate, addressUuid]);

  const handleDeleteAddress = async () => {
    if (deleteAddressQuery.isLoading) {
      return;
    }
    const response = await deleteAddress({ userUuid: user?.userUUID || '', addressUuid });
    const hasError = 'error' in response;
    dispatch(
      apiMessagesSlice.actions.createMessage({
        severity: hasError ? 'error' : 'success',
        text: hasError
          ? response.error?.message || 'Failed to delete address. Please try again later.'
          : response.data?.message || 'Address successfully deleted',
      }),
    );
  };

  const allActions = useMemo(() => {
    const actions: { [Key in inboxHelpers.TAddressesAction]: () => void } = {
      edit: () => handleUpdateAddress(),
      delete: () => deleteDialog.openDialog(),
    };

    return actions;
  }, [deleteDialog, handleUpdateAddress]);

  const actionsList = useMemo(() => {
    return getKeys<typeof inboxHelpers.ADDRESSES_ACTIONS>(inboxHelpers.ADDRESSES_ACTIONS).map((action) => ({
      label: inboxHelpers.ADDRESSES_ACTIONS[action].label,
      onClick: allActions[action],
      dangerous: inboxHelpers.ADDRESSES_ACTIONS[action].color === 'dangerous',
    }));
  }, [allActions]);

  return (
    <>
      <StyledMoreButtonCell>
        <Box ref={moreButton}>
          <MoreMenu containerProps={{ 'aria-describedby': popperId }} items={actionsList} />
        </Box>
      </StyledMoreButtonCell>

      <ConfirmationDialog
        onClose={deleteDialog.closeDialog}
        onConfirm={handleDeleteAddress}
        confirmText="Delete"
        cancelText="Cancel"
        open={deleteDialog.open}
        content={
          <Box>
            <Typography textAlign="center" variant="h5">
              Are you sure you want to delete this address?
            </Typography>
            <Typography textAlign="center">
              Deleting this address will permanently remove the address from your address book.
            </Typography>
          </Box>
        }
      />
    </>
  );
}

export default React.memo(AddressActions);
