import { styled, Typography } from '@mui/material';

export const UnderlineTextButton = styled(Typography, { shouldForwardProp: (prop) => prop !== 'disabled' })<{
  disabled?: boolean;
}>(({ theme, disabled }) => ({
  color: theme.customColors.dark[300],
  borderBottom: `1px dashed ${theme.customColors.dark[300]}`,
  cursor: 'pointer',
  pointerEvents: disabled ? 'none' : 'unset',
}));
