import { Box, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import DeleteIcon from '@mui/icons-material/Delete';

import CheckIcon from '@mui/icons-material/Check';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { ConfirmationDialog, FlexBox, TextButton, TextInput, Tooltip, useDialog } from '@usgm/shared-ui';
import { apiMessagesSlice } from '../../../../../apiMessages/apiMessagesSlice';
import { extractCompanyAddressForReuse } from './schema';
import BorderedBox from './styled/BorderedBox';
import FormRow from './styled/FormRow';

import { inboxHelpers, onboardingUtils } from '@usgm/utils';
import { MouseEvent, useState } from 'react';
import AddressForm, { AddressFormSchema } from '../../../../../../components/AddressForm';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { companyAddressesSelectors, companyAddressesSlice } from './companyAddressSlice';

export type CompanyInfoFormProps = {
  index: number;
};

export function CompanyInfoForm({ index }: CompanyInfoFormProps) {
  const dispatch = useAppDispatch();
  const savedCompanies = useAppSelector(companyAddressesSelectors.selectAll);
  const { closeDialog, open, openDialog } = useDialog();
  const [companyIndexToUse, setCompanyIndexToUse] = useState<number | null>(null);
  const context = useFormContext<onboardingUtils.CompanyInfoFormSchema>();

  const {
    setValue,
    getValues,
    control,

    formState: { errors },
  } = context;

  const currentCompanyInfo = useWatch({
    control,
    name: `accountNames.${index}`,
  });

  const handleSaveClick = () => {
    dispatch(
      companyAddressesSlice.actions.saveCompanyData({
        id: index,
        data: {
          ...currentCompanyInfo,
          name: currentCompanyInfo.name || 'N/A',
        },
      }),
    );
    dispatch(
      apiMessagesSlice.actions.createMessage({
        text: 'Company address saved as template',
        severity: 'success',
      }),
    );
  };

  const onAddressSelect = (companyIndexToUse: number) => () => {
    setCompanyIndexToUse(companyIndexToUse);
  };

  const handleAddressChange = (data: Partial<AddressFormSchema>) => {
    const { address_line, zip, ...rest } = data;
    const newValues = {
      ...getValues(`accountNames.${index}`),
    };

    if (address_line) {
      newValues.streetAddress = address_line;
    }

    if (zip) {
      newValues.zipCode = zip;
    }

    setValue(`accountNames.${index}`, {
      ...newValues,
      ...rest,
    });
  };

  const [defaultAddressValues, setDefaultAddressValues] = useState<AddressFormSchema>(() => {
    const values = getValues(`accountNames.${index}`);

    return {
      address_line: values.streetAddress,
      city: values.city,
      state: values.state,
      zip: values.zipCode,
      address_line_2: '',
      address_line_3: '',
      country: values.country,
    };
  });

  const onConfirm = () => {
    if (typeof companyIndexToUse !== 'number') {
      return;
    }
    const companyAddress = savedCompanies[companyIndexToUse];
    const existingValues = getValues(`accountNames.${index}`);

    if (companyAddress) {
      setValue(
        `accountNames.${index}`,
        {
          ...existingValues,
          ...extractCompanyAddressForReuse(companyAddress),
        },
        { shouldValidate: true },
      );

      setDefaultAddressValues({
        address_line: companyAddress.streetAddress,
        city: companyAddress.city,
        state: companyAddress.state,
        zip: companyAddress.zipCode,
        address_line_2: '',
        address_line_3: '',
        country: companyAddress.country,
      });
      setCompanyIndexToUse(null);
    }
  };

  const handleRemoveCompanyData = (index: number) => (ev: MouseEvent) => {
    ev.stopPropagation();

    dispatch(companyAddressesSlice.actions.removeCompanyData(index));
    setCompanyIndexToUse(null);
  };

  return (
    <BorderedBox mb={3} width="100%" flexDirection="column">
      <FlexBox mb={4} width="100%">
        <FlexBox>
          <Typography mr={0.5} fontWeight="600">
            Company Info
          </Typography>
        </FlexBox>
        <TextButton onClick={handleSaveClick}>Save as Template</TextButton>
      </FlexBox>
      <FormRow>
        <Controller
          name={`accountNames.${index}.typeOfBusiness`}
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={!!errors.accountNames?.[index]?.typeOfBusiness}
              helperText={errors.accountNames?.[index]?.typeOfBusiness?.message}
              fullWidth
              InputLabelProps={{ shrink: true }}
              placeholder="Eg: Online, Ecommerce"
              required
              label="Type of Business"
            />
          )}
        />
        <Controller
          name={`accountNames.${index}.placeOfRegistration`}
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={!!errors.accountNames?.[index]?.placeOfRegistration}
              helperText={errors.accountNames?.[index]?.placeOfRegistration?.message}
              fullWidth
              InputLabelProps={{ shrink: true }}
              placeholder="City/County, State, Country..."
              required
              label="Place Of Registration"
            />
          )}
        />
      </FormRow>
      <Box mt={3} mb={4} width="100%">
        <FlexBox>
          <FlexBox>
            <Typography mr={0.5} fontWeight={600}>
              Company Address
            </Typography>
            <Tooltip
              maxWidth={306}
              title={
                <>
                  <Typography fontSize="0.875rem" mb={2}>
                    Company Address is where the company is physically engaged in its business operations.
                  </Typography>
                  <Typography fontSize="0.875rem">
                    Please provide the specific location where the company conducts its activities or maintains its main
                    office.
                  </Typography>
                </>
              }
            >
              <InfoOutlinedIcon fontSize="small" sx={{ color: (theme) => theme.customColors.dark[400] }} />
            </Tooltip>
          </FlexBox>
          <Box>
            <TextButton onClick={openDialog}>Load from Template</TextButton>
          </Box>
        </FlexBox>
      </Box>
      <Box width="100%">
        <AddressForm
          defaultValues={defaultAddressValues}
          showPlaceholders
          autocompleteLabel="Street Address"
          autocompletePlaceholder="Enter Street Address"
          handleValues={handleAddressChange}
        />
      </Box>

      <FormRow mb={1}>
        <Controller
          name={`accountNames.${index}.phoneNumber`}
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={!!errors.accountNames?.[index]?.phoneNumber}
              helperText={errors.accountNames?.[index]?.streetAddress?.message}
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="tel"
              placeholder={`(213) 747-0828`}
              label="Phone Number"
            />
          )}
        />
      </FormRow>
      <ConfirmationDialog
        title="Choose Template"
        confirmText="Use Address"
        open={open}
        disabled={typeof companyIndexToUse !== 'number'}
        onConfirm={onConfirm}
        onClose={closeDialog}
        content={
          <>
            {!savedCompanies.length ? (
              <Typography textAlign="center">No saved templates</Typography>
            ) : (
              <List>
                {savedCompanies.map((company, index) => (
                  <ListItemButton
                    key={index}
                    onClick={onAddressSelect(index)}
                    sx={{ border: (theme) => `1px solid ${theme.palette.divider}`, mb: 2 }}
                  >
                    <ListItemIcon>{companyIndexToUse === index ? <CheckIcon color="success" /> : null}</ListItemIcon>
                    <ListItemText
                      primary={company?.name}
                      secondary={inboxHelpers.stringifyAddress({
                        addr: {
                          addressLine: company?.streetAddress,
                          city: company?.city || '',
                          state: company?.state || '',
                          postalCode: company?.zipCode || '',
                          country: company?.country?.name || '',
                        },
                      })}
                    />

                    <IconButton onClick={handleRemoveCompanyData(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemButton>
                ))}
              </List>
            )}
          </>
        }
      />
    </BorderedBox>
  );
}
