import { TEAM_MEMBER_MONTHLY_FEE } from '@inbox/constants';
import { Box, LinearProgress, styled, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridSlots } from '@mui/x-data-grid';
import { DtoTeamMember } from '@usgm/inbox-api-types';
import {
  BoldText,
  Button,
  DataGrid,
  dataTableConfig,
  FlexBox,
  GridCardContainer,
  MobileFooter,
  NO_ROWS_CLASS,
  NoRowPlaceholder,
  TextCell,
  useMobileMode,
} from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import NoTeamMembersIcon from '../../../../../assets/noTeamMembersIcon.svg?react';
import EntityStatus from '../../../../billingHistory/components/EntityStatus';
import { useGetTeamMembersListQuery } from '../../../api';
import { CardContent, CardHeader } from '../../../components/Card';
import TeamMemberInfoCell from '../../../components/TeamMembers/tableCells/TeamMemberInfoCell';
import TeamMemberActions from '../../../components/TeamMembers/TeamMemberActions';
import { useGetRowHeight } from '../../../hooks/useGetRowHeight';
import { useGetRowSpacing } from '../../../hooks/useGetRowSpacing';
import { SETTINGS_PATHS } from '../../../paths';

const DEFAULT_TEAM_MEMBER_NAME = 'Invited User';

const StyledInviteButton = styled(Button)(({ theme }) => ({
  minWidth: 160,
}));

const DESKTOP_COLUMNS: GridColDef[] = [
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'email',
    headerName: 'Email',
    flex: 2,
    renderCell: (params: GridRenderCellParams<DtoTeamMember>) => {
      return (
        <TextCell>
          <BoldText>{params.row.email}</BoldText>
          <Typography my={0.5}>{params.row.name || DEFAULT_TEAM_MEMBER_NAME}</Typography>
        </TextCell>
      );
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'orgRoleLabel',
    headerName: 'Role',
    renderCell: (params: GridRenderCellParams<DtoTeamMember>) => {
      return (
        <TextCell>
          <BoldText>{params.row.orgRoleLabel}</BoldText>
        </TextCell>
      );
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'orgStatus',
    headerName: 'Status',
    renderCell: (params) => {
      return <EntityStatus label={params.row.orgStatusLabel} statusCategory={params.row.orgStatusCategory} />;
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'actions',
    renderHeader: () => '',
    renderCell: (params) => {
      return (
        <TeamMemberActions orgStatus={params.row.orgStatus} teamMemberId={params.row.id} role={params.row.orgRole} />
      );
    },
  },
];

const MOBILE_COLUMNS: GridColDef[] = [
  {
    field: 'teamMemberInfo',
    headerName: 'Info',
    flex: 1,
    renderCell: (params: GridRenderCellParams<DtoTeamMember>) => {
      return <TeamMemberInfoCell data={params.row} />;
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'actions',
    flex: 0,
    maxWidth: 16,
    renderCell: (params) => {
      return (
        <TeamMemberActions orgStatus={params.row.orgStatus} teamMemberId={params.row.id} role={params.row.orgRole} />
      );
    },
  },
];

function TeamMembersPage() {
  const navigate = useNavigate();
  const isMobile = useMobileMode();

  const { data, isFetching, isLoading } = useGetTeamMembersListQuery();

  const handleInviteMembersClick = useCallback(() => {
    navigate(SETTINGS_PATHS.INVITE_TEAM_MEMBERS);
  }, [navigate]);

  const inviteButton = useMemo(() => {
    return (
      <StyledInviteButton variant="contained" onClick={handleInviteMembersClick}>
        Invite Members
      </StyledInviteButton>
    );
  }, [handleInviteMembersClick]);

  const slots = useMemo(
    () => ({
      loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
      footer: () =>
        isMobile && !isFetching && !isLoading ? (
          <MobileFooter>
            <FlexBox width="100%" justifyContent="center">
              {inviteButton}
            </FlexBox>
          </MobileFooter>
        ) : undefined,
      noRowsOverlay: () => (
        <NoRowPlaceholder
          icon={<NoTeamMembersIcon />}
          text={`No team members yet. Invite members to join your team.`}
        />
      ),
    }),
    [inviteButton, isFetching, isLoading, isMobile],
  );

  const teamMemberColumns = useMemo(() => {
    if (isMobile) {
      return MOBILE_COLUMNS;
    }
    return DESKTOP_COLUMNS;
  }, [isMobile]);

  const subHeaderText = isMobile
    ? 'Team Members'
    : `Allow team members to access and manage the mailbox. A monthly fee of ${inboxHelpers.formatPrice(
        TEAM_MEMBER_MONTHLY_FEE,
      )}/member applies.`;

  const getRowSpacing = useGetRowSpacing();
  const getRowHeight = useGetRowHeight(isMobile);

  return (
    <GridCardContainer>
      <CardHeader subTitle={subHeaderText} title="Manage Your Team">
        {!isMobile && <Box ml={2.5}>{inviteButton}</Box>}
      </CardHeader>

      <CardContent minHeight="100%" flexGrow={1}>
        <DataGrid
          className={!data?.length ? NO_ROWS_CLASS : undefined}
          disableColumnResize
          disableColumnMenu
          autoHeight
          hideFooter={!isMobile || isFetching || isLoading}
          hideColumns={isMobile}
          disableRowSelectionOnClick
          getRowHeight={getRowHeight}
          rows={data || []}
          slots={slots}
          columns={teamMemberColumns}
          loading={isFetching || isLoading}
          getRowSpacing={getRowSpacing}
        />
      </CardContent>
    </GridCardContainer>
  );
}

export default TeamMembersPage;
