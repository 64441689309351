import React, { useCallback, useState } from 'react';

import type { PDFDocumentProxy } from 'pdfjs-dist';
import { Document, Page, pdfjs } from 'react-pdf';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { CircularProgress, Link, styled, Typography, useTheme } from '@mui/material';
import { FlexBox } from '@usgm/shared-ui';

import { useResizeObserver } from '../../hooks/useResizeObserver';
import { webSiteUrl } from '../../../../helpers/urlHelper';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

const ViewerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: `${theme.spacing(2)} auto`,
  padding: theme.spacing(2),
  height: '600px',
  maxWidth: '536px',
}));

const ViewerDocumentContainer = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 'calc(100% - 2em)',
  margin: '1em 0',
  '& .react-pdf__Document': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '& .react-pdf__Page': {
    marginBottom: '1em',
  },
  '& .react-pdf__message.react-pdf__message--error': {
    backgroundColor: theme.palette.error.main,
  },
  '& .react-pdf__message': {
    padding: theme.spacing(2.5),
    color: theme.palette.mode === 'light' ? theme.palette.common.black : theme.palette.common.white,
  },
}));

const resizeObserverOptions = {};

const maxWidth = 960;

export type PdfViewerProps = {
  blobFileUrl?: string | null;
  loading?: boolean;
};

export function PdfViewerError() {
  const theme = useTheme();
  return (
    <ViewerContainer>
      <FlexBox flexDirection="column" height="100%" width="100%" justifyContent="center">
        <Typography color={theme.customColors.dark[400]} textAlign="center" variant="h6">
          Unable to load the PDF.
        </Typography>
        <Typography color={theme.customColors.dark[400]} textAlign="center" variant="h6">
          Please{' '}
          <Link href={webSiteUrl('contact-us')} target="_blank">
            contact support
          </Link>{' '}
          for assistance.
        </Typography>
      </FlexBox>
    </ViewerContainer>
  );
}

export function PdfViewer({ blobFileUrl, loading = true }: PdfViewerProps) {
  const [progress, setProgress] = useState<number>(0);
  const [error, setError] = useState(false);

  const [numPages, setNumPages] = useState<number>();
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();

  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
    setNumPages(nextNumPages);
  }

  const handleLoadProgress = (progressData: { loaded: number; total: number }) => {
    setProgress((progressData.loaded / progressData.total) * 100);
  };
  const handlerError = () => {
    setError(true);
  };

  return (
    <ViewerContainer>
      {loading && (
        <FlexBox minHeight="inherit" justifyContent="center">
          <CircularProgress />
        </FlexBox>
      )}
      {error ? (
        <PdfViewerError />
      ) : (
        blobFileUrl && (
          <ViewerDocumentContainer onError={handlerError} ref={setContainerRef}>
            <Document
              loading={<FlexBox justifyContent="center">{progress < 100 && <CircularProgress />}</FlexBox>}
              onLoadProgress={handleLoadProgress}
              file={blobFileUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (_, index) => (
                <Page
                  scale={1}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                />
              ))}
            </Document>
          </ViewerDocumentContainer>
        )
      )}
    </ViewerContainer>
  );
}

export default React.memo(PdfViewer);
