import { Box, Typography } from '@mui/material';

import { UserUuidParam } from '@usgm/inbox-api-types';
import { inboxHelpers } from '@usgm/utils';
import { FlexBox, Toast, Preloader } from '@usgm/shared-ui';

import { useGetReferralInfoByUserIdQuery, useGetReferralsListByUserIdQuery } from '../api';
import { ReferralsList } from './ReferralsList';

import ShareButtonsList from './ShareButtonsList';
import CopyCode from '../../../components/CopyCode';

export type ReferralInfoProps = UserUuidParam;

export function ReferralInfo({ userId }: ReferralInfoProps) {
  const infoQuery = useGetReferralInfoByUserIdQuery({ userId });
  const listQuery = useGetReferralsListByUserIdQuery({ userId });
  const error = infoQuery.error || listQuery.error;
  if (infoQuery.isLoading || listQuery.isLoading) {
    return <Preloader />;
  }
  const shareUrl = `${window.location.origin}/${inboxHelpers.MAIN_PATHS.SELECT_PLAN}`;
  const shareTitle = `US Global Mail makes your postal mail virtual and accessible online 24/7! \n
  Sign up for US Global Mail's Smart Mailbox today and we both will get a complimentary month of service. Use this code at checkout: ${infoQuery.data?.referrer.referralCode} \n
  Get started: `;
  return (
    <>
      <Typography>
        Get a <strong>free month</strong> per person that starts using our service with your referral code - no limit!
      </Typography>
      {infoQuery.data?.referrer && (
        <>
          <Box mt={4}>
            <CopyCode code={infoQuery.data?.referrer.referralCode} label="Share your referral code" />
          </Box>
          <FlexBox mt={2} justifyContent="flex-start" gap={2}>
            <ShareButtonsList url={shareUrl} title={shareTitle} />
          </FlexBox>
        </>
      )}

      {listQuery.data?.referrers && <ReferralsList referrals={listQuery.data.referrers} title="Referrals" />}
      {error && <Toast title={error.message} severity="error" />}
    </>
  );
}

export default ReferralInfo;
