import { Typography, useTheme } from '@mui/material';
import { FlexBox } from '@usgm/shared-ui';

import React from 'react';
import DocumentPlaceholderIcon from '../../assets/documentPlaceholder.svg?react';

export type DropZoneDefaultPlaceholderProps = {
  title?: string;
};

export function DefaultDropZonePlaceholder({ title }: DropZoneDefaultPlaceholderProps) {
  const theme = useTheme();
  return (
    <FlexBox flexDirection="column">
      <DocumentPlaceholderIcon />
      {title && (
        <Typography color={theme.customColors.dark[400]} mb={0.5} mt={1.5}>
          {title}
        </Typography>
      )}
      <Typography>
        <Typography fontWeight="bold" component={'span'} color="primary.main">
          Upload file
        </Typography>{' '}
        or drop file here
      </Typography>
    </FlexBox>
  );
}

export default React.memo(DefaultDropZonePlaceholder);
