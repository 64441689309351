import {
  DEFAULT_TIMEZONE,
  SHIPMENT_AVAILABLE_BEFORE_HOUR,
  SHIPMENT_EXPEDITE_AVAILABLE_AFTER_HOUR,
} from '@inbox/constants';
import { DateTime } from 'luxon';

const ERROR_PERIOD = { minutes: 5 };

export function canExpediteShipmentNow(requestedShipmentDate?: DateTime<boolean>): boolean {
  if (!requestedShipmentDate) {
    return false;
  }
  const convertedRequestedShipmentDate = requestedShipmentDate.setZone(DEFAULT_TIMEZONE);
  const currentHoustonDateTime = DateTime.now().setZone(DEFAULT_TIMEZONE);

  const requestedDayStart = convertedRequestedShipmentDate.startOf('day');
  const currentDayStart = currentHoustonDateTime.startOf('day');

  if (requestedDayStart.toISO() === currentDayStart.toISO()) {
    currentHoustonDateTime.plus(ERROR_PERIOD);
    return (
      currentHoustonDateTime.hour >= SHIPMENT_EXPEDITE_AVAILABLE_AFTER_HOUR &&
      currentHoustonDateTime.hour < SHIPMENT_AVAILABLE_BEFORE_HOUR
    );
  }

  return false;
}
