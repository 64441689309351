import { Box, styled } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { SyntheticEvent } from 'react';

import { useMobileMode } from '@usgm/shared-ui';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectPlace, setPlace, setSelectedLocation } from '../../addressPickerSlice';
import { convertResultToPrediction } from '../../helper';
import AutocompleteInput from './AutocompleteInput';
import AutocompletePlace from './AutocompletePlace';
import AutocompletePopper from './AutocompletePopper';
import MyLocationButton from './MyLocationButton';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root': {
    paddingRight: theme.spacing(2),
  },
})) as typeof Autocomplete;

export type PlaceSearchAutocompleteProps = {
  placeholder?: string;
  onChange?: (value: google.maps.places.AutocompletePrediction | null) => void;
};

export default function PlaceSearchAutocomplete({ placeholder, onChange }: PlaceSearchAutocompleteProps) {
  const dispatch = useAppDispatch();
  const isMobile = useMobileMode();
  const value = useAppSelector(selectPlace);

  const {
    value: inputValue,
    setValue: setInputValue,
    suggestions: { data: options, status },
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const clearInput = () => {
    dispatch(setPlace(null));
    dispatch(setSelectedLocation(null));
    setInputValue('');
    onChange?.(null);
  };
  const handleChange = async (__: SyntheticEvent, newValue: google.maps.places.AutocompletePrediction | null) => {
    onChange?.(newValue);

    if (newValue) {
      const [coordinates] = await getGeocode({ placeId: newValue.place_id });

      const location = getLatLng(coordinates);
      dispatch(setPlace(convertResultToPrediction(coordinates)));
      dispatch(setSelectedLocation(location));
    } else {
      dispatch(setSelectedLocation(null));
    }
  };

  return (
    <>
      <StyledAutocomplete
        id="warehouse-search-autocomplete"
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        isOptionEqualToValue={(option, value) => option.place_id === value?.place_id}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        inputValue={inputValue}
        noOptionsText={
          !inputValue || status !== 'ZERO_RESULTS'
            ? 'Start typing to search for a location.'
            : 'No matching locations found. Please refine your search.'
        }
        onChange={handleChange}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        PopperComponent={AutocompletePopper}
        renderInput={(params) => (
          <AutocompleteInput {...params} onClearInput={clearInput} filled={!!value} placeholder={placeholder} />
        )}
        renderOption={(props, option) => <AutocompletePlace attributes={props} option={option} key={option.place_id} />}
      />
      {isMobile && (
        <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
          <MyLocationButton />
        </Box>
      )}
    </>
  );
}
