import { BaseMail, isUnboxingMailType, MailStatus } from '@usgm/inbox-api-types';
import { useGetMailByIdQuery, useGetNotesByMailIdQuery } from '../../api';

import { Box, styled, Typography } from '@mui/material';
import { ImageCell, Preloader } from '@usgm/shared-ui';
import { ContentContainer } from '../../../../components/DynamicSidebar/ContentContainer';

import { TopActionsContainer } from '../../../../components/DynamicSidebar/TopActionsContainer';
import NoteItem, { NoteItemProps } from '../../notes/NoteItem';
import DiscardActions from './DiscardActions';
import DiscardDetails from './DiscardDetails';
import MailActions from './MailActions';
import MailDetails from './MailDetails';
import ShippingDeclarationsManager from './ShippingDeclarationsManager';

const ViewMore = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.primary.main,
}));

export type MailInfoProps = {
  id: BaseMail['id'];
  onViewMoreNotes?: () => void;
} & Pick<NoteItemProps, 'onNoteDelete' | 'onNoteEdit'>;

function MailInfo({ id, onViewMoreNotes, onNoteDelete, onNoteEdit }: MailInfoProps) {
  const { data, isLoading } = useGetMailByIdQuery({ id });
  const { data: mailNotes } = useGetNotesByMailIdQuery({ id });
  const showViewMore = mailNotes?.data && mailNotes?.data.length > 1 && !!onViewMoreNotes;

  const isDiscarded = data?.mailStatus === MailStatus.DiscardRequest;

  const handleViewMoreClick = () => {
    onViewMoreNotes?.();
  };
  return (
    <Box minHeight="100%">
      {isLoading && <Preloader />}
      {data && (
        <>
          <TopActionsContainer>
            {isDiscarded ? (
              <DiscardActions mails={[{ row: data, id }]} />
            ) : (
              <MailActions
                isQuarantine={data.mailStatus === MailStatus.Quarantine}
                singleItem
                ids={[data.id]}
                rows={[{ row: data, id }]}
              />
            )}
          </TopActionsContainer>
          <ContentContainer>
            <Typography mb={1} variant="h5">
              Item #{id}
            </Typography>
            <ImageCell enablePreview height={452} imageUrl={data.imageUrl} />
            {!isDiscarded ? (
              <>
                {isUnboxingMailType(data.mailType) && (
                  <Box mt={4}>
                    <ShippingDeclarationsManager mailId={data.id} declarations={data.shippingDeclarations} />
                  </Box>
                )}
                <Box mt={5}>
                  <MailDetails data={data} variant="full" />
                </Box>
                {mailNotes?.data && mailNotes?.data.length > 0 && (
                  <Box mt={6}>
                    <NoteItem
                      {...mailNotes?.data[0]}
                      onNoteDelete={onNoteDelete}
                      onNoteEdit={onNoteEdit}
                      actions={showViewMore && <ViewMore onClick={handleViewMoreClick}>View More</ViewMore>}
                    />
                  </Box>
                )}
              </>
            ) : (
              <Box mt={5}>
                <DiscardDetails data={data} />
              </Box>
            )}
          </ContentContainer>
        </>
      )}
    </Box>
  );
}

export default MailInfo;
