import { Box, styled } from '@mui/material';

const FooterTop = styled(Box)(({ theme }) => ({
  padding: `0 10.4% ${theme.spacing(4.5)} 10.4%`,
  [theme.breakpoints.down('lg')]: {
    padding: `${theme.spacing(0, 4, 2.5, 4)}`,
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 2.5, 2.5, 2.5),
    flexDirection: 'column',
  },
}));

export default FooterTop;
