import { Box, Typography, useTheme } from '@mui/material';

import { BaseMail } from '@usgm/inbox-api-types';
import { FlexBox, Preloader } from '@usgm/shared-ui';

import { useGetNotesByMailIdQuery } from '../api';

import NoteItem, { NoteItemProps } from './NoteItem';
import { ContentContainer } from '../../../components/DynamicSidebar/ContentContainer';

export type NotesListProps = {
  id: BaseMail['id'];
} & Pick<NoteItemProps, 'onNoteDelete' | 'onNoteEdit'>;
function NotesList({ id, onNoteDelete, onNoteEdit }: NotesListProps) {
  const theme = useTheme();
  const { data: mailNotes, isLoading } = useGetNotesByMailIdQuery({ id });

  return (
    <Box minHeight="100%">
      <ContentContainer>
        {isLoading && <Preloader />}
        {mailNotes?.data.length === 0 ? (
          <Typography color={theme.customColors.dark[400]} fontWeight="600" textAlign="center">
            No Notes
          </Typography>
        ) : (
          <FlexBox width="100%" flexDirection="column" gap={2}>
            {mailNotes?.data?.map((note) => (
              <NoteItem onNoteDelete={onNoteDelete} onNoteEdit={onNoteEdit} key={note.id} {...note} />
            ))}
          </FlexBox>
        )}
      </ContentContainer>
    </Box>
  );
}

export default NotesList;
