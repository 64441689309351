import { Box, Popover, Typography, styled } from '@mui/material';
import React, { useCallback, useId, useMemo, useRef, useState } from 'react';

import { OrgUserRole, OrgUserStatus } from '@usgm/inbox-api-types';
import { ConfirmationDialog, MoreMenu, useDialog } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import { useAppDispatch } from '../../../../../../store';
import { apiMessagesSlice } from '../../../../../apiMessages/apiMessagesSlice';

import Preloader from '../../../../components/DynamicSidebar/Preloader';
import { useFolderManagement } from '../../../mails/mailFolders/useFolderManagement';
import {
  useDeleteTeamMemberByIdMutation,
  useGetTeamMemberFoldersQuery,
  useResendTeamMemberInvitationMutation,
  useSetTeamMemberFoldersMutation,
  useUpdateTeamMemberRoleByIdMutation,
} from '../../api';

import TeamMemberFoldersManager from './TeamMemberFoldersManager';

export type TeamMemberActionsProps = {
  teamMemberId: string;
  role: OrgUserRole;
  orgStatus: OrgUserStatus;
};

const TFolderManagerContainer = styled(Box)(({ theme }) => ({
  boxShadow: theme.shadows[1],
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${theme.palette.divider}`,
  width: 300,
}));

const StyledMoreButtonCell = styled(Box)(({ theme }) => ({
  textAlign: 'right',
  width: '100%',
  marginRight: theme.spacing(8),
}));

export function TeamMemberActions({ teamMemberId, role, orgStatus }: TeamMemberActionsProps) {
  const moreButton = useRef<HTMLHtmlElement>();
  const dispatch = useAppDispatch();
  const [isFolderMangerOpened, setIsFolderManagerOpened] = useState(false);

  const getTeamMemberFoldersQuery = useGetTeamMemberFoldersQuery({ teamMemberId }, { skip: !isFolderMangerOpened });
  const [setTeamMemberFolders, setTeamMemberFoldersMutationQuery] = useSetTeamMemberFoldersMutation();

  const { createFolder, dialogs } = useFolderManagement();

  const popperId = useId();

  // Dialogs
  const grantManagerDialog = useDialog();
  const revokeManagerDialog = useDialog();
  const deleteDialog = useDialog();

  const [updateTeamMemberRole, updateTeamMemberRoleQuery] = useUpdateTeamMemberRoleByIdMutation();
  const [deleteTeamMember, deleteTeamMemberQuery] = useDeleteTeamMemberByIdMutation();
  const [resendInvitationData, resendInvitationQuery] = useResendTeamMemberInvitationMutation();

  const toggleOpenFolderManager = useCallback(() => {
    setIsFolderManagerOpened((prev) => !prev);
  }, []);

  const handleFolderAddition = useCallback(() => {
    toggleOpenFolderManager();
    createFolder();
  }, [createFolder, toggleOpenFolderManager]);

  const handleResendInvitation = useCallback(async () => {
    if (resendInvitationQuery.isLoading) {
      return;
    }
    const response = await resendInvitationData({ teamMemberId });
    const hasError = 'error' in response;
    dispatch(
      apiMessagesSlice.actions.createMessage({
        severity: hasError ? 'error' : 'success',
        text: hasError
          ? response.error?.message || 'Failed to resend invitation email. Please try again later.'
          : response.data?.message || 'Invitation email sent',
      }),
    );
  }, [resendInvitationData, resendInvitationQuery.isLoading, teamMemberId, dispatch]);

  const handleGrantManager = async () => {
    if (updateTeamMemberRoleQuery.isLoading) {
      return;
    }
    const response = await updateTeamMemberRole({ teamMemberId, role: OrgUserRole.Manager });
    const hasError = 'error' in response;
    dispatch(
      apiMessagesSlice.actions.createMessage({
        severity: hasError ? 'error' : 'success',
        text: hasError
          ? response.error?.message || 'Failed to grant Manager role to the team member. Please try again later.'
          : response.data?.message || 'Manager role granted to the team member',
      }),
    );
  };

  const handleRevokeManager = async () => {
    if (updateTeamMemberRoleQuery.isLoading) {
      return;
    }
    const response = await updateTeamMemberRole({ teamMemberId, role: OrgUserRole.Regular });
    const hasError = 'error' in response;
    dispatch(
      apiMessagesSlice.actions.createMessage({
        severity: hasError ? 'error' : 'success',
        text: hasError
          ? response.error?.message || 'Failed to revoke Manager role on the team member. Please try again later.'
          : response.data?.message || 'Manager role revoked on the team member',
      }),
    );
  };

  const handleDeleteTeamMember = async () => {
    if (deleteTeamMemberQuery.isLoading) {
      return;
    }
    const response = await deleteTeamMember({ teamMemberId });
    const hasError = 'error' in response;
    dispatch(
      apiMessagesSlice.actions.createMessage({
        severity: hasError ? 'error' : 'success',
        text: hasError
          ? response.error?.message || 'Failed to delete team member. Please try again later.'
          : response.data?.message || 'Team Member successfully deleted',
      }),
    );
  };

  const allActions = useMemo(() => {
    const actions: { [Key in inboxHelpers.TTeamMemberAction]: () => void } = {
      setMailFolders: () => toggleOpenFolderManager(),
      grantManager: () => grantManagerDialog.openDialog(),
      revokeManager: () => revokeManagerDialog.openDialog(),
      resendInvitation: () => handleResendInvitation(),
      delete: () => deleteDialog.openDialog(),
    };

    return actions;
  }, [toggleOpenFolderManager, grantManagerDialog, revokeManagerDialog, handleResendInvitation, deleteDialog]);

  const actionsList = useMemo(() => {
    return (inboxHelpers.ORG_ROLE_TO_ACTION_MAP[role] || [])
      .filter((action) => {
        return action === 'resendInvitation' ? orgStatus === OrgUserStatus.Invited : !!allActions[action];
      })
      .map((action) => ({
        label: inboxHelpers.TEAM_MEMBERS_ACTIONS[action].label,
        onClick: allActions[action],
        dangerous: inboxHelpers.TEAM_MEMBERS_ACTIONS[action].color === 'dangerous',
      }));
  }, [allActions, orgStatus, role]);

  const assignedFoldersIds = useMemo(
    () => getTeamMemberFoldersQuery.data?.data?.map(({ id }) => id) || [],
    [getTeamMemberFoldersQuery.data?.data],
  );

  const handleSetFolderIds = useCallback(
    async (mailFolderIds: string[]) => {
      const response = await setTeamMemberFolders({ teamMemberId, mailFolderIds });
      toggleOpenFolderManager();
      const hasError = 'error' in response;
      dispatch(
        apiMessagesSlice.actions.createMessage({
          severity: hasError ? 'error' : 'success',
          text: hasError
            ? response.error?.message || 'Failed to assign mail folders. Please try again later.'
            : response.data?.message || 'Mail folders assigned to team member',
        }),
      );
    },
    [setTeamMemberFolders, teamMemberId, toggleOpenFolderManager, dispatch],
  );

  return (
    <>
      <StyledMoreButtonCell>
        <Box ref={moreButton}>
          <MoreMenu containerProps={{ 'aria-describedby': popperId }} items={actionsList} />
        </Box>
        <Popover
          id={popperId}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          onClose={toggleOpenFolderManager}
          anchorEl={moreButton.current}
          open={isFolderMangerOpened}
        >
          <TFolderManagerContainer>
            {getTeamMemberFoldersQuery.isLoading ? (
              <Preloader />
            ) : (
              getTeamMemberFoldersQuery?.data && (
                <TeamMemberFoldersManager
                  isUpdating={setTeamMemberFoldersMutationQuery.isLoading}
                  onSubmit={handleSetFolderIds}
                  assignedFoldersIds={assignedFoldersIds}
                  onAddFolderClick={handleFolderAddition}
                  onClose={toggleOpenFolderManager}
                />
              )
            )}
          </TFolderManagerContainer>
        </Popover>
      </StyledMoreButtonCell>
      <ConfirmationDialog
        onClose={grantManagerDialog.closeDialog}
        onConfirm={handleGrantManager}
        confirmText="Yes, Grant!"
        cancelText="No"
        open={grantManagerDialog.open}
        content={
          <Typography textAlign="center" variant="h5">
            Are you sure you want to grant the Manager role to this user? The user will have expanded access to perform
            a wider range of tasks. Do you wish to proceed?
          </Typography>
        }
      />

      <ConfirmationDialog
        onClose={revokeManagerDialog.closeDialog}
        onConfirm={handleRevokeManager}
        confirmText="Yes, Revoke!"
        cancelText="No"
        open={revokeManagerDialog.open}
        content={
          <Typography textAlign="center" variant="h5">
            Are you sure you want to revoke the Manager role from this user? The user will have limited access and be
            able to perform mail-related tasks only. Do you wish to proceed?
          </Typography>
        }
      />

      <ConfirmationDialog
        onClose={deleteDialog.closeDialog}
        onConfirm={handleDeleteTeamMember}
        confirmText="Yes, Delete!"
        cancelText="No"
        open={deleteDialog.open}
        content={
          <Typography textAlign="center" variant="h5">
            Are you sure you want to delete this user? This action is permanent and cannot be undone. The deleted user
            will lose all access to the system and any associated data. Do you wish to proceed?
          </Typography>
        }
      />
      {dialogs}
    </>
  );
}

export default React.memo(TeamMemberActions);
