import { useCallback } from 'react';

export function useScrollToElement(
  elementId: string,
  options: ScrollIntoViewOptions = { behavior: 'smooth', block: 'start' },
) {
  const scrollToElement = useCallback(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView(options);
    }
  }, [elementId, options]);

  return scrollToElement;
}
