const numberFormatter = new Intl.NumberFormat('en-US', {
  minimumSignificantDigits: 2,
});

const integerFormatter = new Intl.NumberFormat('en-US', {
  minimumSignificantDigits: 1,
});

export const formatNumber = (number: unknown, includeDecimals = true): string => {
  const convertedNumber = Number(number);
  if (!isNaN(convertedNumber)) {
    return (includeDecimals ? numberFormatter : integerFormatter).format(convertedNumber);
  }

  return String(number);
};
