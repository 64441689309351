import { debounce } from 'lodash-es';
import { useCallback, useState } from 'react';

export const useDebouncedValue = <T>({
  value: initialValue,
  delay = 300,
  onChange,
}: {
  value: T;
  delay?: number;
  onChange?: (v: T) => void;
}) => {
  const [value, setValue] = useState<T>(initialValue);
  const [currentValue, setCurrentValue] = useState<T>(initialValue);

  const reset = useCallback(() => {
    setValue(initialValue);
    setCurrentValue(initialValue);
  }, [initialValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandler = useCallback(
    debounce((val) => {
      setValue(val);
      onChange?.(val);
    }, delay),
    [delay, onChange],
  );

  const handleChange = useCallback(
    (val: T) => {
      setCurrentValue(val);
      debouncedHandler.cancel();
      debouncedHandler(val);
    },
    [debouncedHandler],
  );

  return { value, handleChange, currentValue, reset };
};
