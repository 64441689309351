import { Box, styled } from '@mui/material';
import { ReactNode, useState } from 'react';
import TruncateMarkup from 'react-truncate-markup';

export type TruncateTextProps = {
  controlled?: boolean;
  lines?: number;
  showMore?: ReactNode;
  showLess?: ReactNode;
  ellipsis?: string;
  children: ReactNode;
};

const ToggleButton = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.875rem',
  cursor: 'pointer',
  fontWeight: 600,
}));

export function TruncateText({
  controlled = false,
  lines = 2,
  ellipsis = '...',
  showMore = 'Show More',
  showLess = 'Show Less',
  children,
}: TruncateTextProps) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => setExpanded((prev) => !prev);

  return (
    <Box maxWidth="100%">
      {expanded && controlled ? (
        <>
          {children}
          <ToggleButton onClick={toggleExpand}>{showLess}</ToggleButton>
        </>
      ) : (
        <TruncateMarkup
          lines={lines}
          ellipsis={
            <span>
              {ellipsis} {controlled && <ToggleButton onClick={toggleExpand}>{showMore}</ToggleButton>}
            </span>
          }
        >
          {children}
        </TruncateMarkup>
      )}
    </Box>
  );
}
