import { DialogContent, styled } from '@mui/material';
import { createScrollingStyles } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  ...createScrollingStyles({ theme, thumbSize: 6 }),
  padding: theme.spacing(0, 3, 3, 3),
  overflowY: 'scroll',
  [`@media ${inboxHelpers.DOWN_MOBILE_LANDSCAPE}`]: {
    padding: theme.spacing(0, 2.5, 2.5, 2.5),
  },
}));
