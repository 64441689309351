import { Box, styled } from '@mui/material';

const createScannerAngleTemplate =
  ({ color, size }: { size: number; color: string }) =>
  (toDirection: string) => {
    return `linear-gradient(to ${toDirection}, ${color} ${size}px, transparent ${size}px)`;
  };

export const DocumentScanner = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color?: string }>(({ theme, color }) => {
  const shapeColor = color || theme.palette.secondary.main;
  const template = createScannerAngleTemplate({ color: shapeColor, size: 7.5 });
  return {
    zIndex: 1,
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: `${template('right')} 0 0, ${template('right')} 0 100%, ${template('left')}  100% 0, ${template(
      'left',
    )}  100% 100%, ${template('bottom')}  0 0, ${template('bottom')} 100% 0, ${template('top')} 0 100%, ${template(
      'top',
    )} 100% 100%`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: theme.spacing(5, 5),
    '@keyframes horizontal ': {
      to: {
        transform: 'translateX(-100%)',
        left: '100%',
      },
    },

    '@keyframes vertical': {
      '33%': {
        clipPath: 'inset(0 0 0 -100px)',
      },
      '50%': {
        clipPath: 'inset(0 0 0 0)',
      },
      '83%': {
        clipPath: 'inset(0 -100px 0 0)',
      },
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '10px',
      background: shapeColor,
      boxShadow: `0 0 70px 20px ${shapeColor}`,
      clipPath: 'inset(0)',
      animation: 'horizontal 0.5s ease-in-out infinite alternate, vertical 1s ease-in-out infinite',
    },
  };
});
