import { TablePagination as MuiTablePagination, styled } from '@mui/material';

export const TablePagination = styled(MuiTablePagination)(({ theme }) => ({
  borderBottom: 'none',
  '& .MuiTablePagination-input': {
    borderRadius: theme.shape.borderRadius,
    height: theme.spacing(5),
    border: `1px solid ${theme.palette.divider}`,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2),
    order: 2,
  },
  '.MuiTablePagination-displayedRows': {
    color: theme.customColors.dark[300],
    fontWeight: theme.typography.fontWeightBold,
    order: 1,
  },
  '.MuiIconButton-root': {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
  '.MuiTablePagination-actions': {
    marginLeft: 0,
    display: 'flex',
    gap: theme.spacing(1),
    order: 3,
  },
}));
