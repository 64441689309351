import { TextFieldProps } from '@mui/material';
import { TextInput } from '@usgm/shared-ui';
import { Control, Controller, ControllerRenderProps, FieldErrors, FieldPath, FieldValues } from 'react-hook-form';

type HookFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  control: Control<TFieldValues>;
  name: TName;
  errors: FieldErrors<TFieldValues>;
  renderOptions?: (field: ControllerRenderProps<TFieldValues, TName>) => React.ReactNode;
};

const DEFAULT_INPUT_LABEL_PROPS: TextFieldProps['InputLabelProps'] = {
  shrink: true,
};

function ControlledInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  children,
  renderOptions,
  errors,
  label,
  name,
  select,
  required = true,
  control,
  fullWidth = true,
  InputLabelProps,
  ...texFieldProps
}: HookFormProps<TFieldValues, TName> & TextFieldProps) {
  return (
    <Controller
      render={({ field }) => (
        <TextInput
          {...texFieldProps}
          {...field}
          select={select || !!renderOptions}
          error={!!errors[name]}
          helperText={errors?.[name]?.message as string}
          InputLabelProps={{
            ...DEFAULT_INPUT_LABEL_PROPS,
            ...InputLabelProps,
          }}
          fullWidth={fullWidth}
          required={required}
          label={label}
        >
          {renderOptions ? renderOptions(field) : children}
        </TextInput>
      )}
      name={name}
      control={control}
    />
  );
}

export default ControlledInput;
