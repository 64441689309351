import {
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
  menuClasses,
} from '@mui/material';

import { useId } from 'react';

export type SelectBoxProps<TOption> = {
  value: string | number | undefined | null;
  options: Array<TOption>;
  onChange: (value: never) => void;

  getLabel?: (option: TOption) => string;
  getValue?: (option: TOption) => string | number;
  label?: string;
};

function defaultGetValue<TOption>(option: TOption) {
  if (typeof option === 'string' || typeof option === 'number') {
    return option;
  }
  if (typeof option === 'object' && option !== null && 'value' in option && typeof option.value === 'string') {
    return option.value;
  }
  console.warn('SelectBox: getValue is not provided and option is not a string or number');
  return '';
}

const defaultStyles: SxProps<Theme> = {
  [`& .${menuClasses.paper}`]: (theme) => {
    return {
      borderRadius: `${theme.shape.borderRadius * 2}px`,
      boxShadow: theme.shadows[1],
      marginTop: theme.spacing(0.5),
    };
  },
  [`& .${menuClasses.list}`]: (theme) => ({
    borderRadius: `${theme.shape.borderRadius * 2}px`,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,

    '& li': {
      padding: theme.spacing(1.25, 1.75),
    },
    '& li:hover': {
      background: theme.palette.mode === 'dark' ? theme.palette.action.hover : theme.palette.background.default,
    },
    '& li.Mui-selected': {
      color: theme.palette.common.white,
      background: theme.palette.primary.light,
    },
    '& li.Mui-selected:hover': {
      background: theme.palette.primary.main,
    },
  }),
};

function defaultGetLabel<TOption>(option: TOption) {
  if (typeof option === 'string' || typeof option === 'number') {
    return String(option);
  }
  if (typeof option === 'object' && option !== null && 'label' in option && typeof option.label === 'string') {
    return option.label;
  }
  console.warn('SelectBox: getLabel is not provided and option is not a string or number');
  return '';
}

function SelectBox<TOption>({
  options,
  value,
  label,
  onChange,
  getLabel = defaultGetLabel,
  getValue = defaultGetValue,
}: SelectBoxProps<TOption>) {
  const selectId = useId();

  const handleChange = (event: SelectChangeEvent<never>) => {
    const value = event.target.value as never;
    onChange(value);
  };

  return (
    <>
      {label && (
        <InputLabel sx={{ background: (theme) => theme.palette.background.paper }} id={`${selectId}-label`}>
          {label}
        </InputLabel>
      )}
      <Select
        labelId={`${selectId}-label`}
        id={selectId}
        value={value === null || value === undefined ? undefined : value}
        onChange={handleChange}
        input={<OutlinedInput sx={{ height: 56 }} />}
        MenuProps={{
          sx: defaultStyles,
        }}
      >
        {options.map((option, index) => {
          return (
            <MenuItem key={index} value={getValue(option)}>
              {getLabel(option)}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
}

export default SelectBox;
