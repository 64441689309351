import { CaptchaType } from '@inbox/constants';
import { Box } from '@mui/material';
import { Toast } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReCaptchaV2 from '../../../captcha/ReCaptchaV2';
import { LoadingCard } from '../../../components/LoadingCard';
import { useRecaptchaRequest } from '../../../hooks/useRecaptchaRequest';
import { useAppDispatch } from '../../../store';
import { useLoginMutation } from '../api';
import { authSlice } from '../authSlice';

type LoginAsUserPageProps = {
  email: string;
  token: string;
};

export default function LoginAsUserPage({ email, token }: LoginAsUserPageProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loginMutation] = useLoginMutation();
  const { error, makeRequest, requestId } = useRecaptchaRequest<unknown, ReturnType<typeof loginMutation>>({
    action: CaptchaType.Login,
    apiRequest: loginMutation,
  });

  const autoLogin = useCallback(async () => {
    dispatch(authSlice.actions.logout());
    const results = await makeRequest({
      email,
      password: token,
    });

    if (!results || 'error' in results) return;
    dispatch(authSlice.actions.setAuth(results.data));
    navigate(inboxHelpers.MAIN_PATHS.INBOX);
  }, [dispatch, email, makeRequest, navigate, token]);

  useEffect(() => {
    if (requestId) {
      autoLogin();
    }
  }, [autoLogin, requestId]);

  return (
    <>
      <Box sx={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <LoadingCard title={`Logging in as user ${email}`} />
        <ReCaptchaV2 requestId={requestId} />
      </Box>
      {error && <Toast title={error.message} severity="error" autoHideDuration={10000} />}
    </>
  );
}
