import { BaseMail, MAIL_ITEM_TYPE_MAPPER } from '@usgm/inbox-api-types';
import { EntityCell, EntityCellProps, cellHelpers, FlexBox } from '@usgm/shared-ui';
import FolderLink from './FolderLink';
import ScanInfoIndicator from './ScanInfoIndicator';

export type MailInfoCellProps = {
  data: BaseMail;
};

const constructMailInfo = (data: BaseMail): EntityCellProps['infoList'] => {
  return [
    {
      title: '#',
      value: `#${data.id}`,
    },
    {
      title: 'Type:',
      value: MAIL_ITEM_TYPE_MAPPER[data.mailType],
    },
    {
      title: 'Arrived:',
      value: cellHelpers.formatDateString(data.arrivalDate),
    },
    {
      title: 'Size:',
      value: cellHelpers.formatMeasurement<BaseMail['measurement']>(data.measurement),
    },
    {
      title: 'Weight:',
      value: cellHelpers.formatWeight(data.weight),
    },
    {
      title: 'Folder:',
      value: data.mailFolder ? <FolderLink folder={data.mailFolder} /> : 'N/A',
    },
    {
      title: 'Tracking:',
      value: data.trackingNumber || 'N/A',
    },
  ];
};

const renderBadges = (data: BaseMail) => {
  if (!data.scanInfo) {
    return null;
  }
  return (
    <FlexBox mt={1}>
      <ScanInfoIndicator scanInfo={data.scanInfo} />
    </FlexBox>
  );
};

function MailInfoCell({ data }: MailInfoCellProps) {
  return <EntityCell imageUrl={data.imageUrl} infoList={constructMailInfo(data)} badgeContent={renderBadges(data)} />;
}

export default MailInfoCell;
