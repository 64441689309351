import { Badge, Button, ButtonProps, styled } from '@mui/material';
import { FlexBox } from '@usgm/shared-ui';
import NavigationItem from '../layout/Navigation/NavigationItem';

const StyledButton = styled(Button)(({ theme }) => ({
  padding: 0,
  color: theme.palette.text.primary,
  minWidth: theme.spacing(5),
  height: theme.spacing(5),
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ButtonContainer = styled(FlexBox)<{ selected: boolean }>(({ theme, selected }) => ({
  alignSelf: 'stretch',
  position: 'relative',

  ...(selected && {
    '&:after': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '3px',
      position: 'absolute',
      left: 0,
      bottom: 0,
      backgroundColor: theme.palette.primary.main,
    },
  }),
}));

export type THeaderActionButton = {
  asNavigationItem?: boolean;
};

export type HeaderActionButtonProps = ButtonProps &
  THeaderActionButton & {
    selected?: boolean;
    onClick?: () => void;
    label: string;
    icon?: React.ReactNode;
    badgeContent?: number;
  };

export function HeaderActionButton({
  children,
  icon,
  label,
  asNavigationItem,
  badgeContent,
  selected,
  ...buttonProps
}: HeaderActionButtonProps) {
  return asNavigationItem ? (
    <NavigationItem onClick={buttonProps.onClick} badgeContent={badgeContent} icon={icon} label={label} />
  ) : (
    <ButtonContainer selected={!!selected}>
      <StyledButton {...buttonProps} aria-label={label}>
        <Badge badgeContent={badgeContent} color="secondary">
          {icon}
        </Badge>
      </StyledButton>
    </ButtonContainer>
  );
}

export default HeaderActionButton;
