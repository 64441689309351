import { Box, styled } from '@mui/material';

export const SelectionFilterButton = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  cursor: 'pointer',
  width: theme.spacing(3),
  height: theme.spacing(3),
  svg: {
    color: theme.palette.primary.main,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));
