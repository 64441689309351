import { Link, List, ListItem, styled } from '@mui/material';
import SocialLink from '../../types/TSocialLink';

type SocialLinksStyleProps = {
  variant?: 'rounded' | 'squared' | 'normal';
};

type SocialLinksProps = { items: SocialLink[] } & SocialLinksStyleProps;

const StyledList = styled(List, {
  shouldForwardProp: (propName) => propName !== 'variant',
})<SocialLinksStyleProps>(({ theme, variant }) => ({
  display: 'inline-flex',
  gap: theme.spacing(2),
  padding: 0,
  '& li': {
    padding: 0,
  },
  '& svg': {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

export default function SocialLinks({ items, variant = 'rounded' }: SocialLinksProps) {
  if (items.length === 0) {
    return null;
  }
  return (
    <StyledList variant={variant} sx={{ display: 'inline-flex' }}>
      {items.map(({ href, icon, label }, index) => (
        <ListItem sx={{ padding: 0 }} key={index}>
          <Link target="_blank" href={href} aria-label={label}>
            {icon}
          </Link>
        </ListItem>
      ))}
    </StyledList>
  );
}
