import React from 'react';
import { styled } from '@mui/material';
import { forwardRef } from 'react';

type StyledProps = {
  color?: string;
  backgroundColor?: string;
  borderRadius?: string;
  padding?: string;
  iconPosition?: 'left' | 'right';
  clickable?: boolean;
  iconSize?: string;
};

const Root = styled('div')<StyledProps>(
  ({ theme, color, backgroundColor, borderRadius, padding, iconPosition = 'left', clickable, iconSize = '0.5em' }) => ({
    color: color || theme.palette.primary.main,
    backgroundColor: backgroundColor || theme.palette.grey[200],
    borderRadius: borderRadius || '1000px',
    padding: padding || theme.spacing(0.5, 1),
    display: 'inline-flex',
    flexDirection: iconPosition === 'left' ? 'row-reverse' : 'row',
    alignItems: 'center',
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightBold,
    cursor: clickable ? 'pointer' : 'default',
    '& svg': {
      width: iconSize,
      height: iconSize,
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      marginLeft: iconPosition === 'right' ? theme.spacing(0.5) : 0,
      marginRight: iconPosition === 'left' ? theme.spacing(0.5) : 0,
    },
  }),
);

export type LabelBadgeProps = StyledProps & {
  label: string;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  onClick?: () => void;
};

export const LabelBadge = forwardRef<HTMLDivElement, LabelBadgeProps>(
  ({ onClick, label, icon, ...styleProps }, ref) => {
    const iconElement = icon ? <span>{icon}</span> : null;

    return (
      <Root onClick={onClick} clickable={!!onClick} ref={ref} {...styleProps}>
        {label}
        {iconElement}
      </Root>
    );
  },
);
