import { type BaseMail } from '@usgm/inbox-api-types';
import { EntityCell } from '@usgm/shared-ui';
import { inboxHelpers } from '@usgm/utils';

const constructDiscardInfoList = (data: BaseMail) => {
  const days = inboxHelpers.calculateDaysUntilDiscard({ statusTransition: data.statusTransition });
  const deletesAfter = days ? `${days} day${days ? 's' : ''}` : 'N/A';

  return [
    {
      title: 'Item Number',
      value: `#${data.id}`,
    },
    {
      title: 'Item Type',
      value: data.mailTypeLabel,
    },
    {
      title: 'Permanently deleted after',
      value: deletesAfter,
    },
  ];
};

export type DiscardInfoCellProps = {
  data: BaseMail;
};
function DiscardInfoCell({ data }: DiscardInfoCellProps) {
  return <EntityCell imageUrl={data.imageUrl} infoList={constructDiscardInfoList(data)} />;
}

export default DiscardInfoCell;
