import { ShipmentCategory } from '@usgm/inbox-api-types';
import CategoryFilter from '../../../components/filters/CategoryFilter';
import React from 'react';

export const SHIPMENT_TYPES_OPTIONS = new Map<string, string>([
  ['all', 'All'],
  [ShipmentCategory.ShipmentRequest, 'Shipment'],
  [ShipmentCategory.CheckDepositRequest, 'Check'],
  [ShipmentCategory.ReturnToSenderRequest, 'Return'],
  [ShipmentCategory.CustomerPickupRequest, 'Pickup'],
]);

export type ShipmentsCategoryFilterProps = {
  label?: string;
};
export function ShipmentsCategoryFilter({ label = 'Type:' }: ShipmentsCategoryFilterProps) {
  return <CategoryFilter label={label} filtersMap={SHIPMENT_TYPES_OPTIONS} />;
}

export default React.memo(ShipmentsCategoryFilter);
