import { DtoDocument, RecordStatus, UserType } from '@usgm/inbox-api-types';
import { AccountNamesMap } from './hooks/useAccountNamesMap';

export function getCardStatus(userId: string, accountNamesMap: AccountNamesMap): 'Missing' | RecordStatus | null {
  const status = 'Missing';
  const data = accountNamesMap.get(userId);
  if (!data) {
    return null;
  }
  const primaryDocument = data?.documents.get(UserType.Primary);
  const secondaryDocument = data?.documents.get(UserType.Secondary);
  const documents: DtoDocument[] = [];
  if (primaryDocument) {
    documents.push(primaryDocument);
  }
  if (secondaryDocument) {
    documents.push(secondaryDocument);
  }
  if (!primaryDocument || !secondaryDocument) {
    return status;
  }

  const hasRejected = documents.some((doc) => doc.status === RecordStatus.Rejected);
  if (hasRejected) {
    return RecordStatus.Rejected;
  }
  return documents[0].status;
}

export function hasMissingDocuments(userId: string, accountNamesMap: AccountNamesMap, skipIds: string[] = []) {
  const status = getCardStatus(userId, accountNamesMap);

  return status !== RecordStatus.Approved && status !== RecordStatus.InReview && !skipIds.includes(userId);
}

export function normalizeFullName(docAIResponse: {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  full_name?: string;
}) {
  let name = docAIResponse.full_name || '';
  if (docAIResponse.first_name && docAIResponse.last_name) {
    name = `${docAIResponse.first_name}${docAIResponse.middle_name ? ' ' + docAIResponse.middle_name : ''}${
      docAIResponse.last_name ? ' ' + docAIResponse.last_name : ''
    }`;
  }
  return name;
}

export function denormalizeFullName(fullName: string): { firstName?: string; middleName?: string; lastName?: string } {
  if (!fullName) {
    return {};
  }
  const nameParts = fullName.split(' ');
  const lastNameStartIndex = nameParts.length > 2 ? 2 : 1;
  return {
    firstName: nameParts[0],
    middleName: nameParts.length > 2 ? nameParts[1] : '',
    lastName: nameParts.slice(lastNameStartIndex).join(),
  };
}
