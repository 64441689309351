import * as React from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from './Button';
import { Menu, MenuItem, styled } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { ButtonPropsVariantOverrides } from '@mui/material/Button/Button';

type ActionButtonProps = {
  variant?: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>;
  moreButtonItems?: { handler?: () => void; title?: string }[];
  isDisabled?: boolean;
};

const MenuButton = styled(Button)(({ theme, variant, disabled }) => ({
  '&.MuiButton-root': {
    borderLeft: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,

    '&:has(+ .MuiButton-root)': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
}));

export const ActionButton = ({ moreButtonItems, variant, isDisabled = false }: ActionButtonProps) => {
  const [moreAnchorEl, setMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMore = Boolean(moreAnchorEl);
  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreAnchorEl(event.currentTarget);
  };
  const handleMoreClose = () => {
    setMoreAnchorEl(null);
  };
  return (
    <>
      <MenuButton
        sx={{ p: 0, minWidth: 40 }}
        variant={variant}
        id="basic-button"
        aria-controls={openMore ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMore ? 'true' : undefined}
        onClick={handleMoreClick}
        disabled={isDisabled}
      >
        <KeyboardArrowUpIcon sx={{ transform: !openMore ? 'rotate(180deg)' : '' }} />
      </MenuButton>
      <Menu
        id="basic-menu"
        elevation={1}
        anchorEl={moreAnchorEl}
        open={openMore}
        onClose={handleMoreClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {moreButtonItems?.map((item, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              handleMoreClose();
              item.handler?.();
            }}
            sx={{ cursor: 'pointer' }}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
