import { LinearProgress } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridSlots } from '@mui/x-data-grid';
import { DtoExportItem } from '@usgm/inbox-api-types';
import {
  cellHelpers,
  DataGrid,
  dataTableConfig,
  GridCardContainer,
  NO_ROWS_CLASS,
  NoRowPlaceholder,
  TextCell,
  useMobileMode,
} from '@usgm/shared-ui';
import { useMemo } from 'react';
import NoBillingIcon from '../../../../assets/noBilling.svg?react';
import { useDefaultGridConfig } from '../../../../hooks/useDefaultGridConfig';
import { useGetScanExportListQuery } from '../../api';
import { CardContent, CardHeader } from '../../components/Card';
import ExportActionCell from '../../components/ExportActionCell';
import ExportInfoCell from '../../components/Exports/tableCells/ExportInfoCell';
import { useGetRowHeight } from '../../hooks/useGetRowHeight';
import { useGetRowSpacing } from '../../hooks/useGetRowSpacing';

const DESKTOP_COLUMNS: GridColDef[] = [
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'statusLabel',
    headerName: 'Download',
    renderCell: (params: GridRenderCellParams<DtoExportItem>) => <ExportActionCell row={params.row} />,
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'createdOn',
    headerName: 'Created On',
    renderCell: (params: GridRenderCellParams<DtoExportItem>) => {
      return <TextCell>{cellHelpers.formatDateString(params.row.createdOn, true)}</TextCell>;
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'expiresOn',
    headerName: 'Expires On',
    renderCell: (params: GridRenderCellParams<DtoExportItem>) => {
      return (
        <TextCell>
          {/*@TODO: Send expiration date from the backend and calculate human date in the frontend*/}
          {params.row.expiresOn?.humanDate || ''}
        </TextCell>
      );
    },
  },
];

const MOBILE_COLUMNS: GridColDef[] = [
  {
    field: 'exportInfo',
    headerName: 'Info',
    flex: 1,
    renderCell: (params: GridRenderCellParams<DtoExportItem>) => {
      return <ExportInfoCell data={params.row} />;
    },
  },
];

function ExportsPage() {
  const { data, isLoading } = useGetScanExportListQuery();
  const gridConfig = useDefaultGridConfig();
  const isMobile = useMobileMode();

  const slots = useMemo(
    () => ({
      loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
      noRowsOverlay: () => (
        <NoRowPlaceholder
          // @TODO: Replace with exports icon
          icon={<NoBillingIcon />}
          text={`No exported items yet.`}
        />
      ),
    }),
    [],
  );

  const exportColumns = useMemo(() => {
    if (isMobile) {
      return MOBILE_COLUMNS;
    }
    return DESKTOP_COLUMNS;
  }, [isMobile]);

  const headerTitle = isMobile
    ? 'Exports are available to download for 14 days.'
    : 'All completed exports will be accessible for a period of 14 days, after which they will be automatically purged from the system.';

  const getRowSpacing = useGetRowSpacing();
  const getRowHeight = useGetRowHeight(isMobile);

  return (
    <GridCardContainer>
      <CardHeader title="Download Exports" subTitle={headerTitle} />

      <CardContent>
        <DataGrid
          {...gridConfig}
          className={!data?.length ? NO_ROWS_CLASS : undefined}
          autoHeight
          rows={data ?? []}
          slots={slots}
          columns={exportColumns}
          loading={isLoading}
          hideColumns={isMobile}
          getRowHeight={getRowHeight}
          getRowSpacing={getRowSpacing}
          sx={{ width: '100%', height: '100%' }}
        />
      </CardContent>
    </GridCardContainer>
  );
}

export default ExportsPage;
