import { useCallback } from 'react';
import { useAppDispatch } from '../../../store';
import { setWarehouse as setStoreWarehouse } from '../addressPickerSlice';
import { WarehouseCardProps } from '../components/WarehouseCard/WarehouseInnerCard';
import { useNavigate } from 'react-router-dom';
import { inboxHelpers } from '@usgm/utils';

export const useSetWarehouse = (handleSelection?: () => void) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const callbackHandler = useCallback(
    (id: number) => {
      if (typeof handleSelection === 'function') {
        handleSelection();
      } else {
        // Default behavior
        navigate(inboxHelpers.MAIN_PATHS.SELECT_PLAN_BY_WAREHOUSE_ID(id));
      }
    },
    [handleSelection, navigate],
  );
  const onWarehouseSelect: WarehouseCardProps['onSelect'] = (id) => {
    dispatch(
      setStoreWarehouse({
        id,
        callback: () => callbackHandler(id),
      }),
    );
  };

  return useCallback(onWarehouseSelect, [callbackHandler, dispatch]);
};
