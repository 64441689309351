import { EntityResults } from '@usgm/inbox-api-types';

export function updateIsReadStatus<T extends { id?: number | string; uuid?: string }>({
  ids,
  data,
  idPath,
  isRead = false,
}: {
  data: EntityResults<T>;
  ids: Array<string | number>;
  idPath?: 'id' | 'uuid';
  isRead?: boolean;
}): T[] {
  return data.list.map((item) => {
    const identifier = idPath ? item[idPath] : item.id || item.uuid;
    if (identifier && ids.includes(identifier)) {
      return {
        ...item,
        isRead,
      };
    }
    return item;
  });
}
