import { inboxHelpers } from '@usgm/utils';

const ROOT_PATH = `${inboxHelpers.MAIN_PATHS.APP}/${inboxHelpers.APP_PATHS.VERIFY_ACCOUNT}`;

export const ONBOARDING_ROUTES = {
  ADD_IDS_ROUTE: 'add-ids',
  ADD_NAMES_ROUTE: 'add-names',
  GET_NOTARIZED_ROUTE: 'get-notarized',
};

export const ONBOARDING_PATHS = {
  MAIN: `${ROOT_PATH}`,
  ADD_IDS: `${ROOT_PATH}/${ONBOARDING_ROUTES.ADD_IDS_ROUTE}`,
  ADD_NAMES: `${ROOT_PATH}/${ONBOARDING_ROUTES.ADD_NAMES_ROUTE}`,
  GET_NOTARIZED: `${ROOT_PATH}/${ONBOARDING_ROUTES.GET_NOTARIZED_ROUTE}`,
};
