import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { type RouterTabsProps, Preloader } from '@usgm/shared-ui';
import { useAuth } from '../../auth/hooks/useAuth';

import DynamicSidebar from '../components/DynamicSidebar';
import { TabsLayout } from '../components/layout/TabsLayout';

import MailInfo from '../features/mails/components/MailInfo';
import QuarantineHelp from '../features/mails/components/QuarantineHelp';
import EditMailNoteBox from '../features/mails/notes/EditMailNoteBox';
import NotesList from '../features/mails/notes/NotesList';

import { DtoNote } from '@usgm/inbox-api-types';
import { useAppDispatch, useAppSelector } from '../../../store';

import { useGetAppSettingsQuery } from '../../../app/api';

import { useDeleteMailNoteByIdMutation } from '../features/mails/api';
import MailsOpenRequest from '../features/mails/components/mailsActionRequests/MailsOpenRequest';
import MailsScanRequest from '../features/mails/components/mailsActionRequests/MailsScanRequest';
import ShipmentDrawer from '../features/mails/components/mailsActionRequests/shipment/ShipmentDrawer';
import { useMailRequestActionParam } from '../features/mails/hooks/useMailRequestActionParam';
import { mailsSlice, selectSelectedMails } from '../features/mails/mailsSlice';
import {
  DEFAULT_MAIL_TAB,
  MAIL_TABS,
  MailActionRequest,
  MailsPathParams,
  createMailsPath,
} from '../features/mails/paths';
import { useNavigatePreservingParams } from '../hooks/useNavigatePreservingParams';
import { useGetUnreadMailsInfoQuery } from '../inboxAccountsApi';

type TMailInfoView = 'mail' | 'notes';

export default function InboxPage() {
  const navigate = useNavigatePreservingParams();
  const dispatch = useAppDispatch();
  const { isRegularTeamMember } = useAuth();

  const [noteToEdit, setNoteToEdit] = useState<{ isOpen: boolean; note?: DtoNote }>({ isOpen: false });
  const [detailsView, setDetailsView] = useState<TMailInfoView>('mail');
  const selectedMails = useAppSelector(selectSelectedMails);

  const { folderId, itemId, tab } = useParams<MailsPathParams>();
  const { cancelRequest, request } = useMailRequestActionParam();

  const { data: unreadCountsInfo } = useGetUnreadMailsInfoQuery(null);
  const [deleteNoteMutation] = useDeleteMailNoteByIdMutation();

  const { data: appSettings, isLoading: isAppSettingsLoading } = useGetAppSettingsQuery();

  const handleNoteDeletion = useCallback(
    (note: DtoNote) => {
      deleteNoteMutation({ id: note.id, mailId: note.mailId });
    },
    [deleteNoteMutation],
  );

  const toggleNoteEditMode = useCallback(() => {
    setNoteToEdit((prev) => {
      if (prev.isOpen) {
        return {
          isOpen: false,
        };
      } else {
        return {
          isOpen: true,
          note: prev.note,
        };
      }
    });
  }, []);

  const handleNoteEdit = useCallback((note: DtoNote) => {
    setNoteToEdit({
      isOpen: true,
      note,
    });
  }, []);

  const tabs: RouterTabsProps['tabs'] = useMemo(() => {
    const folderUnreadCountData = unreadCountsInfo?.find(
      ({ folderId: id }) => id === Number(folderId) || (id === null && !folderId),
    );

    const unreadCount = folderUnreadCountData?.unreadCount || '';
    const unreadQuarantineCount = folderUnreadCountData?.unreadQuarantineCount || '';
    return MAIL_TABS.map(({ label, value, supportsTeamMembers, supportsRegularTeamMember }) => {
      return {
        path: createMailsPath({ tab: value, folderId, itemId }),
        label: label,
        tooltip: value === 'mails' ? undefined : <QuarantineHelp />,
        badge: isRegularTeamMember ? undefined : value === 'mails' ? unreadCount : unreadQuarantineCount,
        supportsTeamMembers,
        supportsRegularTeamMember,
      };
    });
  }, [folderId, itemId, unreadCountsInfo, isRegularTeamMember]);

  const handleViewMoreNotes = useCallback(() => {
    setDetailsView('notes');
  }, []);

  const handleClose = useCallback(() => {
    setDetailsView('mail');
    navigate(createMailsPath({ tab: tab || DEFAULT_MAIL_TAB, folderId }));
  }, [folderId, navigate, tab]);

  const handleCloseSelectedMailsSidebar = useCallback(() => {
    cancelRequest();
    dispatch(mailsSlice.actions.clearSelectedMails());
  }, [cancelRequest, dispatch]);

  const mailId = Number(itemId);

  return (
    <>
      <TabsLayout tabs={tabs} />

      <DynamicSidebar
        disableSpacings
        open={selectedMails.length > 0 && request === MailActionRequest.Open}
        handleClose={handleCloseSelectedMailsSidebar}
        title={`Open`}
      >
        {isAppSettingsLoading ? <Preloader /> : <MailsOpenRequest allowExpedite={appSettings?.enableExpediteScan} />}
      </DynamicSidebar>

      <DynamicSidebar
        disableSpacings
        open={selectedMails.length > 0 && request === MailActionRequest.Scan}
        handleClose={handleCloseSelectedMailsSidebar}
        title={`Scan`}
      >
        {isAppSettingsLoading ? <Preloader /> : <MailsScanRequest allowExpedite={appSettings?.enableExpediteScan} />}
      </DynamicSidebar>

      <ShipmentDrawer />

      <DynamicSidebar disableSpacings open={!!itemId} handleClose={handleClose} title={`Item Details`}>
        {mailId && (
          <>
            {detailsView === 'mail' && (
              <MailInfo
                onNoteDelete={handleNoteDeletion}
                onNoteEdit={handleNoteEdit}
                onViewMoreNotes={handleViewMoreNotes}
                id={mailId}
              />
            )}
            {detailsView === 'notes' && (
              <NotesList onNoteDelete={handleNoteDeletion} onNoteEdit={handleNoteEdit} id={mailId} />
            )}
            <EditMailNoteBox
              noteData={noteToEdit.note}
              isOpen={noteToEdit.isOpen}
              toggleMode={toggleNoteEditMode}
              id={mailId}
            />
          </>
        )}
      </DynamicSidebar>
    </>
  );
}
