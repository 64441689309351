import { TablePagination } from './styled/TablePagination';
import { usePaginationParams } from './usePaginationParams';

export type DataTablePaginationProps = {
  count: number;
};

export function DataTablePagination({ count }: DataTablePaginationProps) {
  const { limit, skip, setPaginationParams } = usePaginationParams();

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPaginationParams({ skip: newPage * limit, limit });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPaginationParams({ limit: Number(event.target.value), skip: 0 });
  };

  return (
    <table>
      <tbody>
        <tr>
          <TablePagination
            labelDisplayedRows={({ from, to, count }) => (count > 0 ? `${from}-${to} of ${count} items` : '')}
            labelRowsPerPage=""
            count={count}
            page={Math.floor(skip / limit)}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </tr>
      </tbody>
    </table>
  );
}
