import {
  DataTable,
  ImageCell,
  RenderBatchActionsParams,
  TextCell,
  UI_CONSTANTS,
  dataTableConfig,
  useGetImageRowHeight,
  useMobileMode,
  usePaginationParams,
} from '@usgm/shared-ui';

import NoDiscardsIcon from '../../../assets/noDiscards.svg?react';

import { GridColDef, GridEventListener, GridRenderCellParams } from '@mui/x-data-grid';

import { Link } from 'react-router-dom';

import { BaseMail } from '@usgm/inbox-api-types';
import { inboxHelpers } from '@usgm/utils';
import { useGetDiscardedMailsListQuery } from '../api';

import { useCallback } from 'react';
import { useNavigatePreservingParams } from '../../../hooks/useNavigatePreservingParams';
import { createDiscardPath } from '../paths';
import DiscardInfoCell from './DiscardInfoCell';
import DiscardActions from './MailInfo/DiscardActions';

export function SingleMailItemLink({ id, label }: { id: BaseMail['id']; label?: string }) {
  return <Link to={`${inboxHelpers.APP_PATHS.DISCARDS}/${id}`}>{label || id}</Link>;
}

const MAILS_COLUMNS: GridColDef[] = [
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    ...dataTableConfig.DEFAULT_IMAGE_CELL_PARAMS,
    field: 'imageUrl',
    headerName: 'Item Image',
    renderCell: (params: GridRenderCellParams<BaseMail>) => {
      return <ImageCell enablePreview imageUrl={params.row.imageUrl} />;
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'id',
    headerName: 'Item Number',
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'mailTypeLabel',
    headerName: 'Item Type',
  },

  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'statusTransition',
    headerName: 'Permanently deleted after',
    valueGetter: (value: BaseMail['statusTransition']) => {
      const days = inboxHelpers.calculateDaysUntilDiscard({ statusTransition: value });
      if (!days) return 'N/A';
      return `${days} day${days ? 's' : ''}`;
    },
  },
  {
    ...dataTableConfig.DEFAULT_COL_DEF,
    field: 'actions',
    headerName: '',
    renderCell: (params: GridRenderCellParams<BaseMail>) => {
      return (
        <TextCell>
          <DiscardActions listView mails={[params]} />
        </TextCell>
      );
    },
  },
] as const;

const MAILS_MOBILE_COLUMNS: GridColDef[] = [
  {
    field: 'mailInfo',
    headerName: 'info',
    flex: 1,
    renderCell: (params: GridRenderCellParams<BaseMail>) => {
      return <DiscardInfoCell data={params.row} />;
    },
  },
];

function DiscardsList() {
  const navigate = useNavigatePreservingParams();
  const isMobile = useMobileMode();

  const { limit, skip } = usePaginationParams();
  const { data, isLoading, isFetching, refetch } = useGetDiscardedMailsListQuery({
    limit,
    skip,
  });

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    navigate(createDiscardPath(params.row.id));
  };

  const renderBatchActions = useCallback(({ rows, clearSelection }: RenderBatchActionsParams<BaseMail>) => {
    return rows.length > 0 ? <DiscardActions mails={rows} listView clearSelection={clearSelection} /> : null;
  }, []);

  const getRowHeight = useGetImageRowHeight(isMobile);

  return (
    <DataTable
      stickyHeaderTopOffset={UI_CONSTANTS.APP_BAR_HEIGHT}
      multiSelect
      getRowHeight={getRowHeight}
      renderBatchActions={renderBatchActions}
      onRowClick={handleRowClick}
      onRefresh={refetch}
      entityName="discards"
      placeholderIcon={<NoDiscardsIcon />}
      totalRows={data?.count}
      loading={isFetching}
      columns={isMobile ? MAILS_MOBILE_COLUMNS : MAILS_COLUMNS}
      rows={data?.list || []}
      isLoading={isLoading}
      disableHeaderCheckbox
    />
  );
}

export default DiscardsList;
