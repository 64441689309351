export enum NavigationEntityKey {
  VerifyAccount = 'verify-account',
  Mailbox = 'mailbox',
  MailFolder = 'mail-folder',
  ScanFolder = 'scan-folder',
  Scan = 'scan',
  Shipment = 'shipment',
  Billing = 'billing',
  Discard = 'discard',
  Setting = 'setting',
}
