import { styled, Typography, useTheme } from '@mui/material';
import { FlexBox, IOSSwitch, TextInput, useDebouncedValue } from '@usgm/shared-ui';
import { mailsShipmentUtils } from '@usgm/utils';
import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../../store';
import {
  mailsShipmentSlice,
  mailsToShipSelectors,
  selectInsuranceAmount,
  selectInsureShipment,
} from '../../mailsShipmentSlice';
import { DetailsHeader } from './styled/DetailsHeader';

const Root = styled(FlexBox)(({ theme }) => ({
  gap: theme.spacing(2),
  justifyContent: 'flex-start',
  display: 'inline-flex',
  alignItems: 'center',
  minHeight: theme.spacing(7),
}));

function InsureShipment() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const insureShipment = useAppSelector(selectInsureShipment);
  const insuredAmount = useAppSelector(selectInsuranceAmount);
  const mailsToShip = useAppSelector(mailsToShipSelectors.selectAll);

  const defaultInsuredAmount = useMemo(() => {
    return insuredAmount || mailsShipmentUtils.calculateTotalInsuredAmount({ mails: mailsToShip });
  }, [insuredAmount, mailsToShip]);

  const { value, handleChange, currentValue } = useDebouncedValue<string>({ value: `${defaultInsuredAmount || ''}` });

  const handleInsureShipmentChange = useCallback(() => {
    const setEnabled = !insureShipment;
    dispatch(mailsShipmentSlice.actions.setInsureShipment(setEnabled));
    const insuranceAmount = setEnabled ? defaultInsuredAmount : 0;
    dispatch(mailsShipmentSlice.actions.setInsuranceAmount(insuranceAmount));
  }, [dispatch, insureShipment, defaultInsuredAmount]);

  const handleInsureAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e.target.value);
  };

  useEffect(() => {
    dispatch(mailsShipmentSlice.actions.setInsuranceAmount(+value));
  }, [value, dispatch]);

  return (
    <>
      <DetailsHeader>Insure your shipment</DetailsHeader>
      <Root>
        <IOSSwitch variant={'small'} onChange={handleInsureShipmentChange} checked={insureShipment} />
        <Typography fontSize="0.875rem" fontWeight={theme.typography.fontWeightBold}>
          Insure Shipment
        </Typography>
        {insureShipment && (
          <TextInput
            onChange={handleInsureAmountChange}
            value={currentValue}
            label="Insure amount (USD)"
            required
            type="number"
            preserveSpacing={false}
            maxWidth={144}
          />
        )}
      </Root>
    </>
  );
}

export default InsureShipment;
