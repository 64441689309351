import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { InstructionDetailsProps } from '../components/AddIsInstructions/IdInstructions';

export const ID_INSTRUCTIONS: Record<'primary' | 'secondary', InstructionDetailsProps> = {
  primary: {
    title: 'For Primary ID',
    description: 'The ID must be non-expired and include a clear photograph of the individual bearer',
    items: [
      {
        icon: <CheckIcon />,
        text: 'Government-issued photo ID',
        color: 'success',
      },
      {
        icon: <CloseIcon />,
        text: 'Social Security Cards, or Birth Certificates',
        color: 'error',
      },
      {
        icon: <CloseIcon />,
        text: 'Credit Cards',
        color: 'error',
      },
    ],
  },
  secondary: {
    title: 'For Secondary ID',
    description: 'Must contain the Address',
    items: [
      {
        icon: <CheckIcon />,
        text: 'Government-issued Photo ID ',
        color: 'success',
      },
      {
        icon: <CheckIcon />,
        text: 'Current Lease',
        color: 'success',
      },
      {
        icon: <CheckIcon />,
        text: 'Voter Card',
        color: 'success',
      },
      {
        icon: <CheckIcon />,
        text: 'Vehicle Registration Card',
        color: 'success',
      },
    ],
  },
};
