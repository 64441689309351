export const USA_TERRITORIES = [
  'VI',
  'GU',
  'AS',
  'MP',
  'PR',
  'UM',
  'US-VI',
  'US_GU',
  'US-AS',
  'US-MP',
  'US-PR',
  'US-UM',
  'VIRGIN ISLANDS',
  'UNITED STATES VIRGIN ISLANDS',
  'GUAM',
  'AMERICAN SAMOA',
  'NORTHERN MARIANA ISLANDS',
  'PUERTO RICO',
  'UINTED STATES MINOR OUTLYING ISLANDS',
  'MINOR OUTLYING ISLANDS',
] as const;
